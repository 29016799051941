import { BarsIcon, TimesIcon } from "../Common/SVGBank"

import BarsSubTypeMenuItem from "./BarsSubTypeMenuItem"
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const BarsSubType = connect((state) => ({
    subtypes: state.Feature.feature.subtypes,
    is_mobile: state.Setting.is_mobile,

    switchFeatureContainer: state.FormBank.FeatureFormat,

}), {
})(({
    switchFeatureContainer,
    is_mobile,
    subtypes,
    open,
    handleClose,
    handleToggle,
    anchorRef,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "3",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainerColorLight
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        outline: {
            '& .MuiList-root .MuiList-padding': {
                outline: "0px",
            }
        },
    }))

    const classesBars = barsStyles()

    return (
        <div style={{
            paddingTop: "20px", zIndex: "1", marginRight: "5px", position: "relative",
        }}>
            <div

                ref={anchorRef}
                style={{
                    display: "inline-flex",
                }}
                onClick={handleToggle}
                aria-haspopup="true">
                <BarsIcon fill={is_mobile? "#fff":"#707070"}/>
            </div>
            {open && <Grow
                in={open}
                style={{ transformOrigin: '0 0 0' }}
                {...(open ? { timeout: 1000 } : {})}
            ><div style={{ position: "absolute", zIndex: "4", top: "60px", backgroundColor: "var(--mainWhite)", padding: "10px 0", }}>
                    <div style={{ display: "flex", width: "270px", paddingLeft: "10px", paddingRight: "10px", outline: "0", }} >
                        <div style={{ flexGrow: "1", outline: "0", font: "18px/18px Futura Hv BT", }}>Filters</div>
                        <div onClick={handleClose}>{is_mobile ? <TimesIcon width={13} height={13} /> : <TimesIcon width={20} height={20} />}</div>
                    </div>
                </div></Grow>}
            <Popper open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                className={classesBars.popper}
                placement={"bottom-start"}
                transition
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                }}
                disablePortal
                style={{ overflowY: "scroll", marginBottom: "8px", maxHeight: "500px" }}
            >
                <Grow
                    in={open}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(open ? { timeout: 500 } : {})}
                >
                    <Paper>
                        <MenuList style={{ outline: "0px", width: "270px", height: "100%", marginTop: "30px" }}>
                            {subtypes !== undefined
                                && subtypes.length > 0
                                && subtypes.map((subtypeGroup, subtype_group_index) => {
                                    return (
                                        <BarsSubTypeMenuItem subtypeGroup={subtypeGroup} key={subtype_group_index} />
                                    )
                                })}
                        </MenuList>
                    </Paper>
                </Grow>
            </Popper>
        </div >

    )
})
export default BarsSubType
