import * as Actions from '../../../redux/actions'

import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { TimesIcon } from "../Common/SVGBank"
import { connect } from 'react-redux'
import { features_router, member_area_router, URL_REPLACE } from "../Common/RegExValidate"
import { generalSearchStyles } from "../Common/ComponentsStylingBank"
import { useHistory } from 'react-router-dom';
import { hexToRGB } from '../../../utility/utility';
import { encryptID } from '../HotelPage/hotelServices'

const SearchBarDestinationAutocomplete_ = ({ setSwitchContainer,switchFeatureContainer, destinationList, handleSelectedAutocompleteFeature, activeFeatureId, is_mobile, anchorRef, open, handleClose }) => {
    const classes = generalSearchStyles()
    const history = useHistory();
    const handleClick = (item) => {
        if (activeFeatureId === features_router.places.featureId) {
            setSwitchContainer(member_area_router.establishment)
            const city = item.establishmentCity !== "" ? "-" + item.establishmentCity.replace(URL_REPLACE, "-") : ""
            const state = item.establishmentState !== "" ? "-" + item.establishmentState.replace(URL_REPLACE, "-") : ""
            const country = item.establishmentCountry !== "" ? "-" + item.establishmentCountry.replace(URL_REPLACE, "-") : ""
            const url = "/establishment/" + item.establishmentName.replace(URL_REPLACE, "-") + city + state + country + "/" + item.establishmentID;
            history.push(url.toLowerCase())
        }
        if (activeFeatureId === features_router.trips.featureId) {
            setSwitchContainer(member_area_router.publicTrip)
            const encryptedId = encryptID(item.tripID)

            const url = "/trip/" + item.tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
            history.push(url.toLowerCase())
        }
        if (activeFeatureId === features_router.bucket_list.featureId) {
            setSwitchContainer(member_area_router.bucketList)
            const url = "/bucketlist/" + item.bucketListName.replace(URL_REPLACE, "-") + "/" + item.bucketListID;
            history.push(url.toLowerCase())
        }

        handleSelectedAutocompleteFeature(item.name)
    }

    return (
        <div className={classes.root} style={{ textAlign: "left" }}>
            <Popper anchorEl={anchorRef.current} open={open} role={undefined} className={classes.dropdown}
                placement={"bottom"}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                }} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                    >
                        <Paper>
                            <MenuList autoFocusItem={open} id="menu-list-sort-trips" >
                                {activeFeatureId === features_router.places.featureId && destinationList !== undefined && destinationList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}>{features_router.places.text}</div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        <Divider light style={{ margin: "0px" }} />
                                        {destinationList.map((element, element_idx) => {
                                            return (
                                                <div key={element_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.establishmentName}</MenuItem>}
                                                    {is_mobile && element_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.establishmentName}</MenuItem>}
                                                </div>
                                            )
                                        })}
                                    </div>}
                                {activeFeatureId === features_router.trips.featureId && destinationList !== undefined && destinationList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}>{features_router.trips.text}</div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        <Divider light style={{ margin: "0px" }} />
                                        {destinationList.map((element, element_idx) => {
                                            return (
                                                <div key={element_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.tripName}</MenuItem>}
                                                    {is_mobile && element_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.tripName}</MenuItem>}
                                                </div>
                                            )
                                        })}
                                    </div>}

                                {activeFeatureId === features_router.bucket_list.featureId && destinationList !== undefined && destinationList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}>{features_router.bucket_list.text}</div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        <Divider light style={{ margin: "0px" }} />
                                        {destinationList.map((element, element_idx) => {
                                            return (
                                                <div key={element_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.bucketListName}</MenuItem>}
                                                    {is_mobile && element_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.bucketListName}</MenuItem>}
                                                </div>
                                            )
                                        })}
                                    </div>}

                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <style dangerouslySetInnerHTML={{
                __html: `
                    .member_menu_top_btn:hover {
                        background-color: ${hexToRGB(switchFeatureContainer.color,'0.2')} !important;

                     }
                `}} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        activeFeatureId: state.FormBank.FeatureFormat.featureId,
        destinationList: state.Destination.destination.destinationSearchList,
        is_mobile: state.Setting.is_mobile,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}
const mapDispatchToProps = {
    handleSelectedAutocompleteFeature: Actions.handleSelectedAutocompleteFeature,
    setSwitchContainer: Actions.setSwitchContainer,

}

const SearchBarDestinationAutocomplete = connect(mapStateToProps, mapDispatchToProps)(SearchBarDestinationAutocomplete_)
export default SearchBarDestinationAutocomplete

