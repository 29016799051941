import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { GetAiResponse, GetAiSuggestions } from './AiChatBox.services';
import Typewriter from './TypeWriter';
import moment from 'moment';
import AISuggest from './AiSuggest';
import AiSuggestText from './AiSuggestText';
import AiResponses from './AiResponses';
import { createTripThunk } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { URL_REPLACE } from '../RegExValidate';
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';

const AiChatBox_ = ({ timelineStatus, lang, getMemberAreaMuverInfo, uuid, activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    React.useEffect(() => {
        if (uuid) {
            getMemberAreaMuverInfo(uuid)
        }
    }, [uuid])



    const history = useHistory()
    const [chatResponses, setChatResponses] = React.useState([]);
    const [places, setPlaces] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [searching, setSearching] = React.useState(false);
    const listRef = useRef()
    const [showResults, setResults] = React.useState(false);
    const member = useSelector(state => state.Member);
    const avatarUrl = member?.memberArea?.memberMuver?.muverAvatar?.fullUrl;
    const [sessionId, setSessionId] = React.useState("");
    const [typeIndex, setTypeIndex] = React.useState(0)
    const [tripDest, setDestination] = React.useState()
    const [creating, setCreating] = React.useState(false)
    const dispatch = useDispatch()

    const handleMessage = React.useCallback((event) => {
        const val = event.target.value
        setMessage(val)
    }, []);


    React.useEffect(() => {
        setSessionId(Date.now())
        getLocalMessages()//
        return (() => {
            setSessionId("")
        })
    }, [])



    const saveToLocal = (responses) => {
        const stringVal = JSON.stringify(responses)
        localStorage.setItem("chatMessages",
            stringVal
        )
    }

    const getLocalMessages = () => {
        const val = localStorage.getItem("chatMessages")
        if (val) {
            const jsonVal = JSON.parse(val)

            setChatResponses(jsonVal)
        }
    }



    const sendMessage = async (event) => {
        if (event?.key === 'Enter' && message?.length > 0) {
            setSearching(true)

            let newMessages = [];
            let newchats = []
            let newResponses = [];
            let combineResponses = [...chatResponses];

            combineResponses.push([{
                sender: avatarUrl,
                text: message,
                isUser: true

            }])
            setChatResponses(combineResponses);

            if (!showResults) {
                setResults(true)
            }
            ////push user message first


            //push suggestion
            const dataMessage = message;
            const result = await GetAiSuggestions({ message: dataMessage, member_id: uuid, prompt_session_id: sessionId });
            const greeting = result?.result?.greetings?.greeting && result?.result?.greetings?.greeting?.length > 0 ? result?.result?.greetings?.greeting[0] : "";
            const messageResponse = result?.result?.message;
            const greetingText = result?.result?.greetings?.text || result?.result?.greetings?.greeting_text || greeting;
            const general_greetings = result?.result?.greetings?.general_greetings;
            const flight_greeting = result?.result?.greetings?.flight_greeting;
            const activities_greetings = result?.result?.greetings?.activities_greetings;
            const accommodations_greetings = result?.result?.greetings?.accommodations_greetings;
            const tripDestination = result?.result?.trip_destination;
            setDestination(tripDestination ?? null);

            const places = result?.result?.places_json;
            const hotels = result?.result?.accommodations_json;
            const flights = result?.result?.flights_json;
            const noResults = !result?.result?.greetings && (!places?.length > 0) && (!hotels?.length > 0) && !(flights?.length > 0 || flights?.url);

            // console.log(result, greetingText,33)


            // if(greetings){
            //         Object.keys(greetings).map((greeting,index)=> console.log(greeting) )
            // }

            if (messageResponse || noResults) { ///fallback message
                newMessages.push({
                    bot: true,
                    text: messageResponse ?? "Sorry i couldn't find a suggestion",

                })
                newchats.push(messageResponse ?? "Sorry i couldn't find a suggestion")
            }

            if (general_greetings?.length > 0) {
                newMessages.push({
                    bot: true,
                    text: general_greetings,

                })
                newchats.push(general_greetings)
            }

            if (places && places?.length > 0) {
                let formatPlaces = places?.map((place) => {
                    if (place?.muvEstab) {
                        return {
                            ...place?.muvEstab,
                            description: place?.openAiEstab?.description ?? "",
                            name: place?.muvEstab?.establishmentName || place?.openAiEstab?.name
                        }
                    }
                    return place?.openAiEstab;
                })
                formatPlaces = formatPlaces?.filter((data) => data)

                if (formatPlaces?.length > 0) {

                    if (activities_greetings?.length > 0) {
                        newMessages.push({
                            bot: true,
                            text: activities_greetings,
                            greeting: true

                        })
                        newchats.push(activities_greetings)
                    }

                    formatPlaces.forEach((place) => {
                        if (place?.name) {
                            const placeData = {
                                fullJson: place,
                                places: true,
                                url: place?.url,
                                name: place?.name,
                                description: place?.description,
                                // bot: true,

                            }
                            newMessages.push(placeData)

                            const placeText = `${placeData?.name}: ${placeData?.description}`
                            newchats.push(placeText)
                        }
                    })

                    // newMessages.push({
                    //     places: true,
                    //     data: formatPlaces,
                    //     bot: true

                    // })
                }
            }

            //hotel

            if (accommodations_greetings?.length > 0) {
                newMessages.push({
                    bot: true,
                    text: accommodations_greetings,
                    greeting: true

                })
                newchats.push(accommodations_greetings)
            }


            if (hotels && hotels?.length > 0) {
                let formatHotels = hotels?.map((hotel) => {
                    if (hotel?.muvAccommodation) {
                        return {
                            ...hotel?.muvAccommodation,
                            description: hotel?.property_description ?? hotel?.openAiAccommodation?.description ?? "",
                            url: hotel?.muvAccommodation?.booking_url,
                            name: hotel?.muvAccommodation?.hotel_name || hotel?.openAiAccommodation?.name
                        }
                    }
                    return hotel?.openAiAccommodation;
                })
                formatHotels = formatHotels?.filter((data) => data)

                if (formatHotels?.length > 0) {
                    formatHotels.forEach((hotel) => {
                        if (hotel?.name) {

                            const hotelPrice = hotel?.hotelCost?.rate_ammount ? ` starting at ${hotel?.hotelCost?.rate_currency} ${hotel?.hotelCost?.rate_ammount}` : null
                            const hotelData = {
                                fullJson: hotel,
                                hotels: true,
                                url: hotel?.url,
                                price: hotelPrice,
                                name: hotel?.name,
                                description: hotel?.description,
                                guest_rating: hotel?.review_rating ?? "",
                                star_rating: hotel?.star_rating ?? "",

                                // bot: true,
                            }
                            newMessages.push(hotelData)
                            const hotelText = `${hotelData?.name}: ${hotelData?.price ? "Starting at" + hotelData?.price : ""} ${hotelData?.description}`
                            newchats.push(hotelText)
                        }
                    })
                }


                // if (formatHotels?.length > 0) {

                //     newMessages.push({
                //         hotels: true,
                //         data: formatHotels,
                //         bot: true,
                //         destination: result?.result?.trip_destination

                //     })
                // }
            }



            //flights
            if (flight_greeting?.length > 0) {
                newMessages.push({
                    bot: true,
                    text: flight_greeting,
                    greeting: true

                })
                newchats.push(flight_greeting)
            }


            if (flights && flights?.url) {
                const flightPrice = flights?.flightCost && flights?.flightCost?.cost ? `${flights?.flightCost?.currency_symbol}${flights?.flightCost?.cost}` : null
                const flightText = `${flights?.origin_city}-${flights?.destination_city} on ${moment(flights?.flight_date).format('MMM Do')} ${flightPrice ? " at " + flightPrice : ''}`
                newMessages.push({
                    flights: true,
                    data: flights,
                    // bot: true
                })
                newchats.push(flightText)

            }










            ///for responses
            combineResponses.push(newMessages)
            saveToLocal(combineResponses)
            setChatResponses(combineResponses)



            setSearching(false)
            setMessage("")
        }

    }


    const scrollToBottom = () => {
        try {
            listRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
        catch {

        }
    }


    const toggleResults = () => {
        setResults(!showResults)
    }




    const createTrip = React.useCallback(async () => {
        const destination = tripDest?.muv_destination ? [{
            ...tripDest?.muv_destination,
            duration: tripDest?.trip_duration || 3
        }] : null;

        if (destination) {
            setCreating(true)
            const trip = {
                destinations: destination,
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: ""

            }
            const newTrip = await dispatch(createTripThunk(trip));
            const addedTrip = newTrip.payload;
            if (addedTrip) {
                const encryptedId = encryptID(addedTrip?.tripID)


                const url = "/trip/" + addedTrip?.tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
                history.push(url.toLowerCase())



                setCreating(false)
            }
            else {
                setCreating(false)
            }

            setTimeout(() => {
                setCreating(false)
            }, 8000);
        }

    }, [tripDest])


    const AIReponsesMemo = React.useCallback(() => {
        return (
            <AiResponses createTrip={createTrip}
                creating={creating} chatResponses={chatResponses} searching={searching} tripDestination={tripDest}
                showResults={showResults} scrollToBottom={scrollToBottom} is_mobile={is_mobile} listRef={listRef} />
        )
    }, [searching, creating, tripDest, showResults, is_mobile, listRef])




    React.useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 50);
    }, [chatResponses])


    const handleArrowClick = () => {
        toggleResults()
        sendMessage()

        if (showResults) {

            let combineResponses = [...chatResponses];

            combineResponses.push([{
                text: "",

            }])
            setChatResponses(combineResponses);

        }//fake end animation


        // if(showResults){ //clear messages
        //     setChatMessages([])
        //     setChatResponses([])
        //     setChatTexts([])
        // }
    }

    return (

        <div style={{ width: is_mobile ? "95%" : "100%", position: "fixed", bottom: 18, zIndex: 200 }}>

            {/* <AISuggestionsTexts /> */}
            {/* <AISuggestions/> */}
            <AIReponsesMemo />


            <div style={{}}>
                <img src='https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'
                    alt="logo" style={{ zIndex: 1, marginLeft: is_mobile ? "7%" : timelineStatus ? "31%" : "17%", height: 35, width: 35, borderRadius: "50%", position: "absolute", marginTop: 8, display: "inline-block" }} />
                <TextField
                    onKeyPress={sendMessage}
                    disabled={searching}
                    value={message}
                    style={{
                        background: "white",
                        borderRadius: 50,
                        width: is_mobile ? "90%" : timelineStatus ? "60%" : "70%",
                        marginLeft: is_mobile ? "5%" : timelineStatus ? "30%" : "16%",
                        padding: 10,
                        paddingLeft: 70,
                        paddingRight: 70,
                        border: '1px solid lightgrey',
                        font: "18px/20px Futura Lt BT",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    placeholder={'How can we help today?'}
                    id='aiTextBox'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleMessage}
                />
                <div onClick={handleArrowClick} style={{ marginLeft: -55, cursor: "pointer", position: "relative", marginTop: 10, display: "inline-block" }}>
                    <FontAwesomeIcon size="2x" icon={showResults ? faChevronDown : faChevronUp} style={{ color: 'lightgrey', marginRight: "5px" }} />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    logo_width: state.Setting.appMenu.logo.width,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    timelineStatus: state.FormBank.TimelineWizard.status,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    homepageSliderImage: state.Home.homepage_slider_image,
    homepageGeneralInfo: state.Home.homepage_general_info,
    is_mobile: state.Setting.is_mobile,
    activeTab: state.FormBank.BookingForm.activeTab,
})

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const AiChatBox = connect(mapStateToProps, mapDispatchToProps)(AiChatBox_)
export default React.memo(AiChatBox)
