import * as Actions from '../../../../redux/actions'

import React from 'react'
import {
    SyncIcon,
} from "../SVGBank"
import { connect } from 'react-redux'

const ReCalculateTripCostTimelineWizard_ = ({ activeTrip, switchFeatureContainer, uuid, syncTripCostTimelineWizard }) => {
    return (
        <div>
            <div
                style={{
                    cursor: activeTrip.tripCost.fetching ? "pointer" : ""
                }}
                onClick={
                    activeTrip.tripCost.fetching ? () => { } :
                        () => syncTripCostTimelineWizard(activeTrip, uuid)}>
                <SyncIcon width={15} height={15} fill={
                    activeTrip.tripCost.fetching ? "#FFFFFF" : "var(--mainGreen)"
                } />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switchFeatureContainer: state.FormBank.FeatureFormat,

})

const mapDispatchToProps = {
    syncTripCostTimelineWizard: Actions.syncTripCostTimelineWizard,
}

const ReCalculateTripCostTimelineWizard = connect(mapStateToProps, mapDispatchToProps)(ReCalculateTripCostTimelineWizard_)
export default ReCalculateTripCostTimelineWizard
