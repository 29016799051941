import * as Actions from '../../../redux/actions'

import { InfoWindow, Marker } from "react-google-maps"
import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing, list_container_mode, URL_REPLACE } from './RegExValidate'
import { useHistory } from 'react-router-dom';
import { PlusSignIcon } from './SVGBank'
import { environment } from '../../../Environments/environment'
import Axios from 'axios'

const MARKERSIZE = {
    "Nature": {
        icon: 36,
        anchor: 18,
        color: "rgba(30, 107, 92, 1)",
        stroke: "rgba(30, 107, 92, 0.4)",
        zoom:16
    },
    "Small": {
        icon: 36,
        anchor: 18,
        color: 'rgba(216, 82, 29, 1)',
        stroke: 'rgba(216, 82, 29, 0.4)',
        zoom:16
    },
    "Medium": {
        icon: 50,
        anchor: 25,
        color: 'rgba(216, 82, 29, 1)',
        stroke: 'rgba(216, 82, 29, 0.4)',
        zoom:14
    },
    "Large": {
        icon: 66,
        anchor: 33,
        color: 'rgba(216, 82, 29, 1)',
        stroke: 'rgba(216, 82, 29, 0.4)',
        zoom:13
    },
    "Itinerary": {
        icon: 66,
        anchor: 33,
        color: 'rgba(25, 188, 155, 1)',
        stroke: 'rgba(25, 188, 155, 0.4)'
    },


}
export const DestinationMarkers_ = ({ isItinerary,setHoverImage, hoverImage, doubleClick, hideHover, closePopups, mapProps, setSwitchFeatureListContainer, setTripDisplayMode, setPageYOffset, isRegularTrip,
    activeTrip, tripId = activeTrip.tripId, addTripDestinatinInTrip, destinations = activeTrip.tripDestinations,
    destination, lengthDestinations, switchFeatureContainer, index_destination, activeDestinationId, uuid_auth, lang, activeTripDestinationId, activeTripActivities, addActivityToTripDestinationInTrip, activeTripDay, color,
    textColor, pushTimer, clearTimers,
    pushDestWindow, clearDestWindow, checkDestOpen,dblClickDestination }) => {
    // info window
    const [isOpenInfoWindow, setIsOpenInfoWindow] = React.useState(false)
    const [destImage, setDestImage] = React.useState()
    const [isHoverInfoWindow, setIsHoverInfoWindow] = React.useState(false)
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"

    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination

    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    var timer;
    const id = destination?.destinationId || destination?.destId || destination?.muvDestinationId

     

    const defaultSize = destination?.size ? MARKERSIZE[destination?.size]?.icon : 50;
    const defaultClickZoom = destination?.size ? MARKERSIZE[destination?.size]?.zoom : 13;
    const defaultAnchor = destination?.size ? MARKERSIZE[destination?.size]?.anchor : 24;
    const defaultColor =isItinerary ?  MARKERSIZE["Itinerary"]?.color : destination?.size ?  MARKERSIZE[destination?.size]?.color  : 'rgba(216, 82, 29, 1)';
    const defaultStroke = isItinerary ?  MARKERSIZE["Itinerary"]?.stroke: destination?.size ? MARKERSIZE[destination?.size]?.stroke  : 'rgba(216, 82, 29, 0.4)';

    const isNature = destination?.size && destination?.size === "Nature";
    const isSmall = destination?.size && destination?.size === "Small";
    const [iconSize, setIconSize] = React.useState(null)
    const [iconAnchor, setIconAnchor] = React.useState(null)


    React.useEffect(() => {
        setIconSize(defaultSize)
        setIconAnchor(defaultAnchor)
    }, [destination])


    const getDestImage = async () => {
        if(id){
          const url = environment.apiUrl +'destination/getSingleImage/'+id
          const response = await Axios.get(url)
          const data = response.data?.results;
  
          if(data){
              const imageLink = image_boxing(data, "260", "130");
              setDestImage(imageLink)
          }
  
        }
            
  
      }



    const onToggleOpenInfoWindow = (isOpenInfoWindow) => {
        if (!checkDestOpen(id)) {
    
            if(id ){
               
                timer = setTimeout(() => {
                    getDestImage()
                    pushDestWindow(id)

                }, 500);
                // pushPlacesWindow(establishment.id)

            } 
            ///triggers hightling map slider

            if (setHoverImage) {
                setHoverImage(id)
            }

        }
        else {

        }

        // setIsOpenInfoWindow(!isOpenInfoWindow)
        // setIsHoverInfoWindow(false)

    }


    const removeInfoWindow = () => {
        clearTimeout(timer);
        clearDestWindow()
      
    }


    const onHoverEnlarge = () => {
        setIconSize(iconSize + 5)
        setIconAnchor(iconSize / 1.8)
    }


    
    const history = useHistory()

    React.useEffect(() => {
        // setIsOpenInfoWindow(false)
        // setIsHoverInfoWindow(false)
    }, [activeTrip.activities])

    const goToDestination = () => {
        const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + destination.destinationId;
        //window.open(url.toLowerCase());
        window.open(url, '_blank').focus();
        // history.push(url)

    }

    const handleSwitchContainer = () => {
        setSwitchFeatureListContainer(list_container_mode.list)
        setTripDisplayMode(list_container_mode.timeline)
        setPageYOffset(0)
    }

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, activeDestinationId, activeTripDestinationIds) => {
        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, activeDestinationId, activeTripDestinationIds)
        document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const hasImage = (destination) => {
        const image = destination?.destinationImage?.imageName;
        return image && !image.includes("grayBackgroundCamera");
    }
    const [showMore, setShowMore] = React.useState(false);


    const addDestinationToTrip = () => {
        addTripDestinatinInTrip(lang, uuid, destination, tripId, 1)
    }

    let destinationExist = false;
    let canAddToTrip = true;
    if (destinations && destinations.length > 0) {
        destinationExist = destinations.find((des) => des?.destId === destination?.destinationId)
        canAddToTrip = destinationExist ? false : true;
    }

    const removeHover = () => {
        setIconSize(defaultSize)
        setIconAnchor(defaultAnchor)

        clearTimeout(timer);
        clearDestWindow()
        if(clearTimers){
            clearTimers()
        }


    }

    const dbclickDest = ()=>{
        if(doubleClick){
            doubleClick(destination)
        }

        if(dblClickDestination){
            const dest = {
                lat: parseFloat(destination.latitude),
                lng: parseFloat(destination.longitude)
            }
        
            dblClickDestination(dest,defaultClickZoom)
        }
        
    }

    ///trigger enlarge from slider
    React.useEffect(() => {
        if (hoverImage && hoverImage === id) {
            onHoverEnlarge()
        } else {
            removeHover()
        }
    }, [hoverImage])


    const imageSrc =  destImage ||  image_boxing(destination.destinationImage, "260", "130");


    return (
        <div   onMouseLeave={()=> removeInfoWindow()}>
        <Marker

            zIndex={3}
            position={{
                lat: parseFloat(destination.latitude),
                lng: parseFloat(destination.longitude)
            }}
            onMouseOver={() => onToggleOpenInfoWindow()}
            onDblClick={dbclickDest}
            onMouseOut={()=> clearTimeout(timer)}
            onClick={() => onToggleOpenInfoWindow()}
            icon={{
                url:
                    (isOpenInfoWindow || checkDestOpen(id)) ?
                        index_destination < 9 ?
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="${defaultStroke}" stroke-width="20" fill="${defaultColor}" />
                                    ${iconSize >= 66 && `<circle width="46" height="46" cx="22.5" cy="22.5" r="21" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="${defaultStroke}"/>`}
                                </g>
                            </svg>`
                            :
                            `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="${defaultStroke}" stroke-width="20" fill="${defaultColor}" /> 
                                    ${iconSize >= 66 && `<circle width="46" height="46" cx="22.5" cy="22.5" r="21" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="${defaultStroke}"/>`}
                                </g>
                            </svg>`
                        :

                        `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                        <g fill-rule="evenodd">    
                            <circle cx="22.5" cy="22.5" r="12" fill="${defaultColor}" />
                          
                         
                            ${!isNature && iconSize < 66 && !isSmall &&
                        `<circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none"  stroke-width="2" stroke="${defaultStroke}"/> `}
                       
                                ${!isNature && iconSize >= 66 && !isSmall &&
                        `<circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="${defaultStroke}"/>`}

                                
                        </g>
                        </svg>`,
                size: { width: iconSize, height: iconSize },
                anchor: { x: iconAnchor, y: iconAnchor },
                scaledSize: { width: iconSize, height: iconSize }
            }}

        >
            {(isOpenInfoWindow || checkDestOpen(id)) &&
                <InfoWindow
                    options={{ disableAutoPan: true,zIndex:200,
                        pixelOffset: new window.google.maps.Size(-4, 5)}}
                    onCloseClick={() => { }}
                    style={{padding:0}}
                >
                    <div style={{

                    }}>
                        {imageSrc && <Image className="img_fit_cover"
                            src={imageSrc}
                            alt="activity" width={260} height={130} />}
                        <div style={{ display: "block", flexWrap: "wrap", textAlign: "left", width: "260px", padding: "10px" }}>
                            <div style={{ width: "100%", display: "block" }}>
                                <div style={{ display: "block", }} onClick={goToDestination}>
                                    <div style={{ display: "block", cursor: 'pointer', font: "14px/12px Futura Hv BT", textAlign: "left", color: textColor ? textColor : color.fill, padding: "0px 2px", marginRight: "10px", lineHeight: 1.2 }}>
                                        {destination.name}<span
                                            style={{ flexGrow: "1", font: "14px/12px Futura Md BT", textAlign: "left", color: "#B1B1B1", padding: "0px 2px", marginRight: "10px", }}> {destination?.stateName}{destination?.countryName && ", " + destination?.countryName} </span>
                                    </div>
                                </div>
                                {destination && destination.about && <div style={{ marginTop: 3, marginBottom: showMore ? 0 : 0, height: showMore ? 95 : 95, overflow: showMore ? "auto" : "hidden" }} >
                                    <div id="destinationHtml" dangerouslySetInnerHTML={{ __html: showMore ? destination.about : `${destination.about.substring(0, 120)}` }}
                                        style={{ flexGrow: "1", font: "14px/12px Futura LT BT", textAlign: "left", color: "#B1B1B1", padding: showMore ? "12px 10px 20px 2px" : "10px 2px", lineHeight: 1.2 }}>
                                    </div>
                                    <div className="btn" style={{
                                        display: "block", marginTop: -10, cursor: 'pointer', font: "12px/10px Futura Hv BT",
                                        textAlign: "right", color: textColor ? textColor : color.fill
                                    }}
                                        onClick={() => setShowMore(!showMore)}>{showMore ? "less" : "more"}</div>
                                </div>}

                                {activeTripId !== "" && canAddToTrip &&
                                    <div style={{ marginTop: "-7px", marginBottom: -10, textAlign: "right" }}>
                                        <div onClick={() => addDestinationToTrip()}
                                            style={{ cursor: "pointer", color: "var(--mainGreen)", font: "10px/13px Futura Hv BT", padding: "10px 10px", display: "inline-block" }}>
                                            <PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                    </div>}

                            </div>

                        </div>
                    </div>
                </InfoWindow>}
        </Marker >
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    switchFeatureContainer: state.FormBank.FeatureFormat,



})

const mapDispatchToProps = {
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    setSwitchFeatureListContainer: Actions.setSwitchFeatureListContainer,
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    addTripDestinatinInTrip: Actions.addTripDestinatinInTrip,

}

const DestinationMarkers = connect(mapStateToProps, mapDispatchToProps)(DestinationMarkers_)
export default DestinationMarkers
