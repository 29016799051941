import * as Actions from '../../../redux/actions'


import React from 'react'
import { connect } from 'react-redux'

import { useHistory } from "react-router-dom"
import { LeftArrowChevronIcon, LeftArrowIcon } from '../Common/SVGBank';
import moment from 'moment';
import { Check, Edit } from '@material-ui/icons';
import { URL_REPLACE } from '../Common/RegExValidate';
import { Authorization } from '../Common/Authorization';
import { encryptID } from './hotelServices';

const MobileHotelHeader_ = ({ setHotelSearchForm, toggleHotelForm, hotelSearchForm, establishment, useTransparent, tokenLogin, stars, settAppMenuBackGroundColorMobile, appMenuBookingButtonStatus, setterAppMenuTopBarTextColor, logo_width, logo_height, textColor, backGroundColorMobile, bodyClientWidth, authModal, bg_color, text_color }) => {
    const getColor = () => {
        return "#19BC9B";
    }
    const history = useHistory()
    const isLoggedIn = () => {
        return authModal?.uuid && authModal?.uuid.length > 0 ? true : false
    }

    const hotelName = establishment.generalInfo !== undefined && establishment.generalInfo !== null ? establishment.generalInfo.name : ""

    const [header, setHeader] = React.useState(false)

    // function component did mount
    React.useEffect(() => {
        settAppMenuBackGroundColorMobile(bg_color)
        setterAppMenuTopBarTextColor(text_color)
        if (!isLoggedIn()) {
            tokenLogin()
        }
    }, [])

    const listenScrollEvent = (event) => {
        if (window.scrollY < 550) {
            return setHeader(false)
        } else {
            return setHeader(true)
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    const checkIn = moment(hotelSearchForm.check_in)?.format("MMM DD")
    const checkOut = moment(hotelSearchForm.check_out)?.format("MMM DD")
    const numGuests = hotelSearchForm?.adults;
    const numRooms = hotelSearchForm?.rooms;


    const handleHotelSearchForm = () => {
        let hotel_form_state = { ...hotelSearchForm }
        // if query and dates are entered, do search

        const rooms = (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
        const guests = (hotel_form_state.adults ? hotel_form_state.adults : 2)
        const obj = {
            rooms: hotel_form_state.rooms,
            adults: hotel_form_state.adults,
            isNew: true,
            isSearching: true,
        }


        hotel_form_state = Object.assign(hotel_form_state, obj)


        if (hotel_form_state.city_id !== "" && hotel_form_state.check_in !== "" && hotel_form_state.check_out !== "") {
            //console.log({hotel_form_state,embedForm,bookingForm});

            setHotelSearchForm(hotel_form_state);

            // open new page with params
            //setSwitchContainer(member_area_router.destination)
            const encryptedId =  encryptID(hotel_form_state.city_id)
            history.push("/hotel-search?city_id=" + encryptedId
                + "&check_in=" + hotel_form_state.check_in
                + "&check_out=" + hotel_form_state.check_out
                + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
                + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 2)
                + "&city_lat=" + (hotel_form_state.city_latitude)
                + "&city_lng=" + (hotel_form_state.city_longitude)
                + "&hotels=" + hotel_form_state?.hotelid_ppn
                + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))


        }

    }


    const edit = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        toggleHotelForm()
    }

    return (
        <div style={{ padding: 20, position: "fixed", width: "100%", top: 0, zIndex: "10", background: "white" }} >
            <div style={{ display: "none" }} >
                <Authorization fontColor={textColor} font={"8px/28px Futura Lt BT"} />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>

                <div onClick={handleHotelSearchForm}>
                    <LeftArrowChevronIcon fill={'#191919'} height={25} width={25} style={{ marginRight: 10 }} />
                </div>


                <div style={{ font: "14px/14px Futura Md BT", textAlign: "center" }} >
                    {hotelName}
                    <div style={{ font: "14px/14px Futura Md BT", marginTop: 3 }} >
                        {checkIn} - {checkOut}, {numRooms} rooms, {numGuests} guests
                    </div>
                </div>

                <Edit onClick={edit} style={{ height: 25, width: 25, marginRight: 5, color: '#191919' }} width={25} height={25} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        logo_width: state.Setting.appMenu.logo.width,
        logo_height: state.Setting.appMenu.logo.height,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        textColor: state.Setting.appMenu.textColor,
        backGroundColorMobile: state.Setting.appMenu.backGroundColorMobile,
        appMenuBookingButtonStatus: state.Setting.appMenu.appMenuBookingButtonStatus,
        establishment: state.FormBank.HotelSearch.establishment,
        isTop: state.Setting.screenVertical.isTop,
        booking_form_status: state.FormBank.BookingForm.status,
        stars: state.MemberActions.fetchStarSets.stars,
        authModal: state.Member.authModal,
    }
};
const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setHotelSearchForm: Actions.setHotelSearchForm,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    settAppMenuBackGroundColorMobile: Actions.settAppMenuBackGroundColorMobile,
    setterAppMenuTopBarTextColor: Actions.setterAppMenuTopBarTextColor,
    tokenLogin: Actions.tokenLogin,
};
export const MobileHotelHeader = connect(mapStateToProps, mapDispatchToProps)(MobileHotelHeader_)