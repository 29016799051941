import "./ProfileBar.css";

import * as Actions from '../../../../redux/actions'

import { Button, Card, Image } from 'react-bootstrap';
import {
    CameraRetroIcon,
    FacebookIcon,
    InstagramIcon,
    PinterestIcon,
    SnapchatIcon,
    TwitterIcon,
} from "../SVGBank"
import { member_area_router, profile_router } from "../RegExValidate"

import { HandleTextField, SignatureTextField } from "../FormComponentsBank"
import { PropTypes } from "prop-types"
import React, { useRef } from 'react'
import SocialPageLink from "../../Common/Profile/SocialPageLink"
import { connect, useDispatch } from 'react-redux'
import { image_cloud_aliases } from "../RegExValidate"
import {
    registerHandleUseStyles,
    registerSignatureUseStyles,
} from "../ComponentsStylingBank"
import { Check, Edit } from "@material-ui/icons";
import { SendFriendRequest,getPendingFriendRequests } from "../../SocialPage/social.slice";
import { ALERT_BARS_HANDLE } from "../../../../redux/interfaces/types";
import { environment } from "../../../../Environments/environment";

/* profile component */
const Profile_ = ({member_uuid,authModal, pendingRequests,muverSocial, muverMemebr, firstNameAuth, lastNameAuth, setSwitchContainer, setSwitchProfileContainer, app_menu_height, handleTextField, signatureTextField, followersSets, switch_container, uuid, token, setOpenAuthModal, alertBar, modifyFollowing, setProfilePhotoAvatar, screenSize, photoAvatar, lang }) => {

    const dispatch =  useDispatch()
 
    React.useEffect(() => {
        if(uuid){
        dispatch(getPendingFriendRequests(uuid))
        }
    }, [uuid])
    
    const pendingRequest = muverSocial?.muverUID  && pendingRequests && pendingRequests.length > 0? pendingRequests.find((person)=> person.sentFriendUID === muverSocial?.muverUID ) : false;

    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    const handleSocialIcon = (social_media, handle) => {
        switch (social_media) {
            case "Facebook":
                window.open(`https://www.facebook.com/${handle}`)
                return
            case "Instagram":
                window.open(`https://www.instagram.com/${handle}`)
                return
            case "Pinterest":
                window.open(`https://www.pinterest.ca/${handle}`)
                return
            case "Snapchat":
                window.open(`https://www.snapchat.com/add/${handle}`)
                return
            case "Twitter":
                window.open(`https://twitter.com/${handle}`)
                return
            default:
                return ""
        }
    }
 
    const handleProfile = () => {
        setSwitchContainer(member_area_router.personalInfo)
        setSwitchProfileContainer(profile_router.profile)
    }
    const handlerFollower = (muver_id, action) => {
        if (uuid !== undefined && uuid !== "") {
            modifyFollowing(uuid, muver_id, action)
        } else {
            setOpenAuthModal(true)
            alertBar("warning", "Please Sign In / Sign Up")
        }
    }

    const firstName = switch_container !== member_area_router.social ? firstNameAuth : muverSocial.muverFirstName
    const lastName = switch_container !== member_area_router.social ? lastNameAuth : muverSocial.muverLastName
    let avatarFileInput = ""
    let camera_size = 30

    let avatar_height = 140
    let camera_top = "50px"
    let screen_at_1200 = 1200
    let avatar_container_width = 140
    if (!isNaN(screenSize)) {
        camera_size = screenSize < screen_at_1200 ? 20 : 30
        camera_top = screenSize < screen_at_1200 ? "30px" : "50px"
        avatar_height = screenSize < screen_at_1200 ? 90 : 140
        avatar_container_width = screenSize < screen_at_1200 ? 90 : 140

    }
    const muver = switch_container === member_area_router.social ? muverSocial : muverMemebr
    const classHandle = registerHandleUseStyles();
    const classSignature = registerSignatureUseStyles();

    const [canEditProfile, setEditProfile] = React.useState(false);
    let handleRef = useRef(null);
     

    let top_H = 180 + -Math.ceil(-app_menu_height / 2) * 2;
    let srcAvatar = ""



    if (muver !== undefined && muver !== null) {
       
        if (photoAvatar.isSelected) { srcAvatar = photoAvatar.selectedFilePath }
        else if (muver.muverAvatar !== undefined && muver.muverAvatar !== "") {
            let key = muver.muverAvatar.bucketName + "_" + muver.muverAvatar.folderName
            srcAvatar = "https://amhqajbken.cloudimg.io/v7/" + image_cloud_aliases[key].alias + "/" + muver.muverAvatar.imageName + "?func=crop&w=" + avatar_height + "&h=" + avatar_height
        } else {
            srcAvatar = "https://amhqajbken.cloudimg.io/v7/" + image_cloud_aliases._.alias + "/muvimages/user_avatar/armando.jpg?func=crop&w=" + avatar_height + "&h=" + avatar_height
        }

        if (muver !== undefined && isEmpty(muver)) {
            return ("No data found from the API, goes to login page")
        }
    }   
    
    const sendFriendRequest = () =>{
        const data = {
            member_id: muverSocial?.muverUID,
            uuid:uuid,
            member_name: muverSocial?.muverFirstName

        }
        dispatch(SendFriendRequest(data))
    }

    const requestError = () =>{
         dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "warning",
          message: `Friend request already sent`
      })
    }

   

  
        return (
            <div className="profile_section_common" style={{ position: "fixed", top: top_H, marginLeft: "8.5%", width: "340px", height: "595px" }}>
                {muver !== undefined
                    && !isEmpty(muver) &&
                    <Card className="bg-black text-white" >
                        <Card.Body style={{ border: "1px solid #19BC9B" }}>
                            <div className="text-center" style={{ position: "relative" }}>
                                {switch_container === member_area_router.social && <Image className="rounded-circle" src={srcAvatar} alt="Avatar" />}

                                {switch_container !== member_area_router.social && <div className="position-relative margin_top_20 text-center d-inline-block" style={{ width: avatar_container_width, zIndex: "1" }}>
                                    <div
                                        className="img_fit_cover rounded-circle d-inline-block"
                                        style={{
                                            width: avatar_height,
                                            height: avatar_height,
                                            backgroundImage: "url('" + srcAvatar + "')", backgroundRepeat: "no-repeat",
                                            backgroundSize: "100% 100%",
                                        }}>
                                    </div>
                                    <div className="rounded-circle"
                                        style={{
                                            width: avatar_height,
                                            height: avatar_height,
                                            top: "0",
                                            left: "0",
                                            right: "0",
                                            position: "absolute",
                                            backgroundColor: "rgba(25, 25, 25, 0.2)",
                                            zIndex: "2",
                                        }}>
                                    </div>
                                    <div className="position-absolute text-center" style={{ zIndex: "3", left: "0px", right: "0px", top: camera_top }}>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            accept="image/*"
                                            onChange={event => {
                                                event.target.files !== undefined
                                                    && event.target.files.length > 0
                                                    && event.target.files[0] !== undefined
                                                    && setProfilePhotoAvatar((window.URL || window.webkitURL).createObjectURL(event.target.files[0]), photoAvatar.isSelected, photoAvatar.selectedFilePath, event.target.files[0], token)
                                            }}
                                            ref={fileInput => avatarFileInput = fileInput}
                                        />

                                        <div className="d-inline-block" onClick={() => avatarFileInput.click()}>
                                            <CameraRetroIcon width={camera_size} height={camera_size} fill={"#E8E8E8"} />
                                        </div>
                                    </div>

                                </div>}
                                <div className="mb-2 mt-2 avatar_fullname">{(firstName !== "" && lastName !== "") ? firstName.charAt().toUpperCase() + firstName.substring(1) + ' ' + lastName.charAt().toUpperCase() + '.' : ""}</div>
                                {switch_container !== member_area_router.social && <div style={{ position: "absolute", right: "0px", top: "0px", font: "14px/22px Futura LT BT", color: "#EFEFEF", cursor: "pointer", }} onClick={() => handleProfile()}>edit profile</div>}
                                {switch_container === member_area_router.social && <div className="mb-2 mt-2 avatar_username">@{muver.muverHandle !== "" ? muver.muverHandle : ""}</div>}
                                
                               
                                {switch_container !== member_area_router.social && <div style={{ position: "relative" }}>
                                    <div className={classHandle.root} style={{ position: "relative", width:"100%", textAlign:"center"}}>     
                                    

                                        <HandleTextField
                                            //label="@Handle"
                                            label=""
                                            uuid={uuid}
                                            required={true}
                                            // defaultValue={handleTextField.handle !== "" ? "@" + handleTextField.handle : (muver.muverHandle !== "" ? "@"+ muver.muverHandle : "")}
                                            value={handleTextField.handle !== "" &&  handleTextField.handle? "@" + handleTextField.handle : (muver.muverHandle !== "" ? "@"+ muver.muverHandle : "")}
                                            id="handle"
                                            token={token}
                                            inputRef={handleRef}
                                            autofocus={true}
                                        /> 
                                      
                                    </div>
                                    {canEditProfile && <div 
                                    style={{marginTop: 3,font: "12px/22px Futura LT BT", width:"90%", marginLeft: 8,  marginBottom: 5}}>
                                    Warning: if you change your handle, other müvers might not be able to reach you anymore
                                        </div>}
                                   
                                    <div className={classSignature.root} style={{ position: "relative", width:"100%", textAlign:"center" }}>   
                                        <SignatureTextField
                                                //label="@Signature"
                                                label=""
                                                en={lang}
                                                uuid={uuid}
                                                defaultValue={muverMemebr.muverBanner || "enter your signature"}
                                                uuid={uuid}
                                                lang={lang}
                                            />
                                        </div>
                                    </div>
    
                                }
                                {/*<div className="mb-4 mt-2 avatar_banner">{muver.muverBanner !== "" ? muver.muverBanner : "how are you "}</div>*/}
                                <div className="avatar_btn_container">
                                    {switch_container === member_area_router.social &&
                                        <React.Fragment>
                                            <Button variant="outline-success" onClick={() => !pendingRequest? sendFriendRequest(): requestError()} style={{ boxShadow: "0 0 0 0" }} className="profile-btn">Friend Request</Button>
                                            {followersSets !== undefined &&
                                                followersSets.filter(follower => follower.memberID === muver.muverUID).length > 0 ?
                                                <Button variant="outline-success" style={{ boxShadow: "0 0 0 0" }} className="profile-btn" onClick={() => handlerFollower(muver.muverUID, "unfollow")}>Unfollow</Button>
                                                :
                                                <Button variant="outline-success" style={{ boxShadow: "0 0 0 0" }} className="profile-btn" onClick={() => handlerFollower(muver.muverUID, "follow")}>Follow</Button>
                                            }

                                        </React.Fragment>
                                    }
                                    {switch_container !== member_area_router.social &&
                                        <React.Fragment>
                                            <Button variant="outline-success" style={{ boxShadow: "0 0 0 0" }} className="profile_btn_invite">Invite Friends </Button>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="margin_top_10 margin_bottom_20 d-flex justify-content-center">
                                    {muver.muverFacebook !== "" && muver.muverFacebook !== null ? <div onClick={() => handleSocialIcon("Facebook", muver.muverFacebook)}><FacebookIcon className="m-1" /></div> : ""}
                                    {muver.muverInstagram !== "" && muver.muverInstagram !== null ? <div onClick={() => handleSocialIcon("Instagram", muver.muverInstagram)}><InstagramIcon className="m-1" /></div> : ""}
                                    {muver.muverPinterest !== "" && muver.muverPinterest !== null ? <div onClick={() => handleSocialIcon("Pinterest", muver.muverPinterest)}><PinterestIcon className="m-1" /></div> : ""}
                                    {muver.muverSnapchat !== "" && muver.muverSnapchat !== null ? <div onClick={() => handleSocialIcon("Snapchat", muver.muverSnapchat)}><SnapchatIcon className="m-1" /></div> : ""}
                                    {muver.muverTwitter !== "" && muver.muverTwitter !== null ? <div onClick={() => handleSocialIcon("Twitter", muver.muverTwitter)}><TwitterIcon className="m-1" /></div> : ""}
                                </div>
                                <div className="number-content">
                                    <div>
                                        <Image src="/img/add-friend.svg" alt="Add Friend" />
                                        <div className="avatar_followers mt-2">{muver.muverFollowersCount !== "" ? muver.muverFollowersCount : ""}</div>
                                        <div className="avatar_followers_text mt-2">Followers</div>
                                    </div>
                                    <div>
                                        <Image src="/img/airplane-shape.svg" alt="Airplane" />
                                        <div className="avatar_followers mt-2">{muver.muverTripsCount !== undefined ? muver.muverTripsCount : ""}</div>
                                        <div className="avatar_followers_text mt-2">Trips</div>
                                    </div>
                                    <div>
                                        <Image src="/img/trophy.svg" alt="Trophy" />
                                        <div className="avatar_followers mt-2">MUV</div>
                                        <div className="avatar_followers_text mt-2">Muse</div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>}
            </div>
        )
}
Profile_.propTypes = {
    muverSocial: PropTypes.object.isRequired,
    muverMemebr: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
    return {
        app_menu_height: state.Setting.appMenu.app_menu_height,
        muverSocial: state.Member.socialMember.muver,
        muverMemebr: state.Member.memberArea.memberMuver,
        member_uuid: state.Member.socialMember.member_uuid,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        switch_container: state.Member.switch_container,
        uuid: state.Member.authModal.uuid,
        authModal: state.Member.authModal,
        firstNameAuth: state.Member.authModal.firstName,
        lastNameAuth: state.Member.authModal.lastName,
        token: state.Member.authModal.token,
        followersSets: state.MemberActions.fetchFollowers.followersSets,
        photoAvatar: state.FormBank.photoAvatar,
        handleTextField: state.FormBank.handleTextField,
        lang: state.Setting.lang,
        signatureTextField: state.FormBank.signatureTextField,
        pendingRequests: state.SocialPage.pendingRequests,
    }
};

const mapDispatchToProps = {
    alertBar: Actions.alertBar,
    setOpenAuthModal: Actions.setOpenAuthModal,
    modifyFollowing: Actions.modifyFollowing,
    setProfilePhotoAvatar: Actions.setProfilePhotoAvatar,
    setSwitchContainer: Actions.setSwitchContainer,


    setSwitchProfileContainer: Actions.setSwitchProfileContainer,

}

const Profile = connect(mapStateToProps, mapDispatchToProps)(Profile_)

export default Profile;