import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { DurationLayover, secondsToHms } from "../../../../utility/utility"
import { convertObjArray } from "./HotelManagementList"


export const HotelPaymentDetails = ({ lookUpData,is_mobile }) => {
   const trip_number = lookUpData?.itinerary?.id;
   const rooms = lookUpData?.itinerary_details?.room_title;
   const price_details = lookUpData?.price_details;
   const itinerary_details = lookUpData?.itinerary_details;
   const display_taxes = price_details?.display_taxes_and_fees ||price_details?.display_taxes;
   const display_price = price_details?.display_price;
   const nights = itinerary_details?.num_nights;
   const display_symbol = price_details?.display_symbol;
   const display_sub_total = price_details?.display_sub_total;
   const display_total = price_details?.display_total;
   const display_currency = price_details?.display_currency;
   const mandatoryFee = price_details?.mandatory_fee_details;
   const customer = itinerary_details?.customer;

   return (
      <div style={{ width: "100%" }}>
         <div style={{ padding: "10px 12%", marginBottom: 15 }}>
            <div style={{ color: '#FF0000', font: "22px/22px Futura Md BT", marginBottom: is_mobile?10: 30, lineHeight: "40px" }}>
               Price Summary
               {/* <div style={{ color: '#000000', font: "18px/18px Futura Lt BT", }}>
                  Trip Number: {trip_number}
               </div> */}
            </div>

            <div style={{ paddingBottom:is_mobile?40: 20 }} >

               <div style={{ color: '#707070', font: "18px/18px Futura Lt BT", float: "left" }}>
                  {rooms}
               </div>
               <div style={{ color: '#707070', font: "18px/18px Futura Lt BT", float: "right" }}>
                  {nights} nights x {display_symbol}{Number(display_price)?.toFixed(2)}
               </div>
            </div>
            <hr style={{ clear: "both" }} />

            <div style={{ marginBottom: 22, clear: "both" }} >

               <div style={{ color: '#707070', font: "18px/18px Futura Md BT", float: "left" }}>
                  Subtotal
               </div>
               <div style={{ color: '#707070', font: "18px/18px Futura Md BT", float: "right" }}>
                  {display_symbol}{Number(display_sub_total)?.toFixed(2)}
               </div>
            </div>

            <div style={{ marginBottom: 22, clear: "both" }} >

               <div style={{ color: '#707070', font: "18px/18px Futura Md BT", float: "left" }}>
                  Taxes & Fees
               </div>
               <div style={{ color: '#707070', font: "18px/18px Futura Md BT", float: "right" }}>
                  {display_symbol}{Number(display_taxes)?.toFixed(2)}
               </div>
            </div>

            <div style={{ marginBottom: is_mobile?30:10,  marginTop: 50 }} >

               <div style={{ color: '#0D3A7D', font: "22px/22px Futura Md BT", float: "left" }}>
                  Amount Paid
               </div>
               <div style={{ color: '#0D3A7D', font: "18px/18px Futura Md BT", float: "right" }}>
                  {display_currency} {Number(display_total)?.toFixed(2)}
               </div>
            </div>

            {mandatoryFee && <div style={{ marginBottom: -42, clear: "both",marginTop:is_mobile?100: 120 }} >

               <div style={{ color: '#707070', font: "22px/18px Futura Md BT"}}>
                  Fees due at the property:
               </div>

               <div style={{ color: '#707070', font: "18px/18px Futura Lt BT", marginTop:20 }}>
               Resort fee: {display_symbol}{Number(mandatoryFee?.display_total)?.toFixed(2)} (payable at the property)
                                
               </div>
            </div>}


            <div style={{ marginBottom: 22, clear: "both", marginTop:is_mobile?90: 120 }} >

               <div style={{ color: '#000000', font: "20px/20px Futura Md BT", float: "left" }}>
                  Payment method
               </div>
               <div style={{
                  color: '#000000', textAlign: "right", font: "18px/18px Futura Lt BT", float:is_mobile?"left": "right",
                  textAlign:is_mobile?"left":"right",
                  marginTop:is_mobile?10:0,
                  lineHeight: "25px",
               }}>
                  {customer?.card_type} xxxxxxxxxx{customer?.card_last_four}
                  <br />
                  {customer?.name}
                  <br />
                  {customer?.address?.zip}, {customer?.address?.city_name}, {customer?.address?.country_code}

               </div>
            </div>



         </div>
      </div>
   )
}