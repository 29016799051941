import {
    ALERT_BARS_HANDLE,
    COPY_TRIP_DREAM_TRIPS,
    COPY_TRIP_FUTURE_TRIPS,
    COPY_TRIP_MODIFY_PROFILE,
    COPY_TRIP_PAST_TRIPS,
    DELETE_TRIP_LIST,
    DELETE_TRIP_MODIFY_PROFILE,
    FETCH_ACTIVE_ACTIVITIES_IN_TRIP,
    FETCH_API_ERROR_MEMBER_ACTIONS,
    FETCH_FOLLOWERS_SETS,
    FETCH_MEMBER_DREAM_TRIPS,
    FETCH_MEMBER_DREAM_TRIPS_ERROR,
    FETCH_MEMBER_FUTURE_TRIPS,
    FETCH_MEMBER_FUTURE_TRIPS_ERROR,
    FETCH_MEMBER_MUVER,
    FETCH_MEMBER_MUVER_ERROR,
    FETCH_MEMBER_PAST_TRIPS,
    FETCH_MEMBER_PAST_TRIPS_ERROR,
    FETCH_STAR_SETS_MEMBER_ACTIONS,
    GET_SELECTED_TRIP_INFORMATION,
    CLEAR_SELECTED_TRIP_INFORMATION,
    CLEAR_ALL_TRIP_INFORMATION,
    GetAsyncHelper,
    IS_FETCHED_FOLLOWERS_SETS,
    MODIFY_TRIP_DATE_LISTING,
    MODIFY_TRIP_NAME_LISTING,
    PostAsyncHelper,
    PostAsyncHelperImage,
    SET_ACTIVE_DAY_IN_TRIP,
    SET_ACTIVE_TRIP_DESTINATION,
    SET_AUTH_FIRST_NAME,
    SET_AUTH_HANDLE,
    SET_AUTH_LAST_NAME,
    SET_AUTH_UUID,
    SET_CONTAINER_ROUTER,
    SET_COPY_TRIP,
    SET_DESTINATION_ID_DESTINATION,
    SET_FIRST_NAME,
    SET_LAST_NAME,
    SET_LEAVING_FROM_AIRPORT_SELECTED,
    SET_LIST_DISPLAY_FORMAT_MEMBER,
    SET_LOADER,
    SET_MODAL_COOKIES_EXIST_AUTH,
    SET_MODAL_CURRENCY_AUTH,
    SET_MODAL_LANGUAGE_AUTH,
    SET_MODAL_MEMBER_STATUS_AUTH,
    SET_MODAL_OPEN_AUTH,
    SET_MODAL_ROUTE_AUTH,
    SET_MODAL_TOKEN_AUTH,
    SET_PRIVATE_AREA,
    SET_RETURNING_TO_AIRPORT_SELECTED,
    SET_SELECTED_TRIP_IMAGE_MEMBER_AREA,
    SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS,
    SET_TIMELINE_ACTIVE_TRIP_COST,
    SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
    SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION,
    SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS,
    SET_TIMELINE_ACTIVE_TRIP_DURATION,
    SET_TIMELINE_ACTIVE_TRIP_ID,
    SET_TIMELINE_ACTIVE_TRIP_IMAGE,
    SET_TIMELINE_ACTIVE_TRIP_NAME,
    SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
    SET_TIMELINE_ACTIVE_TRIP_START_DATE,
    SET_TIMELINE_WIZARD_DELETE_ALART_STATUS,
    SET_TIMELINE_WIZARD_STATUS,
    SET_TRIP_DISPLAY_MODE,
    SET_TRIP_TYPE_SELECTED,
    SORT_TRIP_COLLECTION_BY_DATE_ASCENDING,
    SORT_TRIP_COLLECTION_BY_DATE_DESCENDING,
    SWITCH_PROFILE_CONTAINER,
    TOGGLE_ELLIPSIS_CLOSE_DISABLE,
    TOGGLE_ELLIPSIS_CLOSE_ENABLE,
    UPDATE_SELECTED_HEADER_IMAGE,
    UPDATE_TRIP_IMAGE,
    RESET_MEMBER_ACCOUNT,
} from "../interfaces/types"

import Axios from 'axios';
import Cookies from 'js-cookie'
import {
    member_area_router
} from "../../Components/WebContent/Common/RegExValidate"
import moment from "moment"
import qs from 'qs'
import { getTimelineFlights, getTimelineHotels, updateActiveTrip } from "../../Components/WebContent/Common/Trip/ActiveTrip.slice";
import { environment } from "../../Environments/environment";
import { closeTripTimelineWizard } from "./FormBankAction";

export const setSwitchContainer = (switch_container) => dispatch => {
    dispatch({
        type: SET_CONTAINER_ROUTER,
        switch_container: switch_container,
    })

    /* set body bgcolor */
    document.body.style.backgroundColor = (switch_container === member_area_router.trips ||
        switch_container === member_area_router.bucketList ||
        switch_container === member_area_router.favorites ||
        switch_container === member_area_router.muvTribe ||
        switch_container === member_area_router.personalInfo ||
        switch_container === member_area_router.memberAreaTrip) ? "var(--bgBlack)" : "#FFFFFF"
}

/* set menu trip display */
export const setTripDisplayMode = (trip_mode) => dispatch => {
    dispatch({
        type: SET_TRIP_DISPLAY_MODE,
        trip_mode: trip_mode,
    })
}
export const setSwitchListContainerMember = (container_format) => dispatch => {
    dispatch({
        type: SET_LIST_DISPLAY_FORMAT_MEMBER,
        container_format: container_format
    })
}
/* get member area */

export const getMemberAreaMuverInfo = (uuid, lang) => async dispatch => {
    /* get member info  */
    GetAsyncHelper(environment.apiUrl +'memberinfo/' + uuid).then(response => {
        if (response.statusText === "OK") {
            dispatch({
                type: FETCH_MEMBER_MUVER,
                member_muver: response.data.muver
            })
            dispatch({
                type: FETCH_MEMBER_MUVER_ERROR,
                error: false,
            })
        } else {
            dispatch({
                type: FETCH_MEMBER_MUVER_ERROR,
                error: true,
            })
        }
    })
}
export const getMemberAreaPastTrips = (uuid, lang) => async dispatch => {

    /* get memebr past trips */
    GetAsyncHelper(environment.apiUrl +'memberpasttrips/' + uuid + '/' + lang + '?&lightOnly=1').then(response => {
        if (response.statusText === "OK") {
            dispatch({
                type: FETCH_MEMBER_PAST_TRIPS,
                member_past_trips: response.data.trips
            })
            dispatch({
                type: FETCH_MEMBER_PAST_TRIPS_ERROR,
                error: false,
            })
        } else {
            dispatch({
                type: FETCH_MEMBER_PAST_TRIPS_ERROR,
                error: true,
            })
        }
    })
}
export const getMemberAreaFutureTrips = (uuid, lang) => async dispatch => {
    /* get memebre futrue trips */
    GetAsyncHelper(environment.apiUrl +'memberfuturetrips/' + uuid + '/' + lang + '?&lightOnly=1').then(response => {
        if (response.statusText === "OK") {
            dispatch({
                type: FETCH_MEMBER_FUTURE_TRIPS,
                member_future_trips: response.data.trips
            })
            dispatch({
                type: FETCH_MEMBER_FUTURE_TRIPS_ERROR,
                error: false,
            })
        } else {
            dispatch({
                type: FETCH_MEMBER_FUTURE_TRIPS_ERROR,
                error: true,
            })
        }
    })

}
export const getMemberAreaDreamTrips = (uuid, lang) => async dispatch => {
    /* get member dream trips */
    GetAsyncHelper(environment.apiUrl +'memberdreamtrips/' + uuid + '/' + lang + '?&lightOnly=1').then(response => {
        if (response.statusText === "OK") {
            dispatch({
                type: FETCH_MEMBER_DREAM_TRIPS,
                member_dream_trips: response.data.trips
            })
            dispatch({
                type: FETCH_MEMBER_DREAM_TRIPS_ERROR,
                error: false,
            })
        } else {
            dispatch({
                type: FETCH_MEMBER_DREAM_TRIPS_ERROR,
                error: true,
            })
        }
    })
}


export const setPrivateArea = (isPrivate) => dispatch => {
    dispatch({
        type: SET_PRIVATE_AREA,
        is_private: isPrivate,
    })
}

export const setTripToTimeline =  (tripId, uuid,hideTimeline) => async (dispatch) => {
    const url_active_trip_get = environment.apiUrl +'timeline/getTripInfo/en/' + uuid + '?tripId=' + tripId + "&getTripInfo"
    const responseTrip = await  GetAsyncHelper(url_active_trip_get);

        if (responseTrip.statusText === "OK") {
            const trip = responseTrip.data.result;
            dispatch(updateActiveTrip(trip));
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_ID,
                trip_id: trip.tripID
            })

            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_NAME,
                trip_name: trip.tripName
            })

            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_START_DATE,
                trip_start_date: trip.noDate === "0" ? trip.tripStartDate : "0000-00-00"
            })

            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
                trip_no_date: trip.noDate
            })

            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_DURATION,
                trip_duration: trip.tripDaysCount
            })
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION,
                trip_description: trip.tripDescription
            })
            dispatch({
                type: SET_TRIP_TYPE_SELECTED,
                type_id: trip.typeId
            })
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS,
                trip_auth_status: trip.isPublic
            })
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_IMAGE,
                trip_image: trip.headerImage
            })
            dispatch({
                type: SET_LEAVING_FROM_AIRPORT_SELECTED,
                airport_selected: trip.leavingFromAirport,
            })
            dispatch({
                type: SET_RETURNING_TO_AIRPORT_SELECTED,
                airport_selected: trip.returningToAirport,
            })
            // dispatch({
            //     type: SET_TIMELINE_WIZARD_STATUS,
            //     status: true
            // })
            /* set to cookies active trip */
            dispatch(getTimelineHotels({
                uuid: uuid,
                tripId: tripId
            }))
            dispatch(getTimelineFlights({
                uuid: uuid,
                tripId: tripId
            }))
            Cookies.set('muv_active_trip', trip.tripID, {
                expires: 365
            })
           
        } else {
            console.log("no permission")
        }


    const url_active_trip_get_cost = environment.apiUrl +"timeline/getTripCost/en/" + uuid + "?tripId=" + tripId + "&getTripCost"
    dispatch({
        type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
        status: true
    })
   
   
    const responseTripCost =await GetAsyncHelper(url_active_trip_get_cost)
        if (responseTripCost.statusText === "OK") {
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_COST,
                trip_cost: responseTripCost.data.result
            })
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
                status: false
            })

        } else {
            console.log("no permission")
        }
    
    const url_active_trip_get_destinations = environment.apiUrl +"timeline/getTripDestinations/en/" + uuid + "?tripId=" + tripId + "&getTripDestinations"
    const responseTripDestinations = await GetAsyncHelper(url_active_trip_get_destinations);
        if (responseTripDestinations.statusText === "OK") {
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS,
                trip_destinations: responseTripDestinations.data
            })
            responseTripDestinations.data.length > 0 && responseTripDestinations.data[0] !== undefined &&
                dispatch({
                    type: SET_ACTIVE_TRIP_DESTINATION,
                    trip_destination_id: responseTripDestinations.data[0].td_id,
                    destination_id: responseTripDestinations.data[0].id,
                })
            dispatch({
                type: SET_DESTINATION_ID_DESTINATION,
                active_id: responseTripDestinations.data[0].id
            })

            responseTripDestinations.data.length > 0 && responseTripDestinations.data[0] !== undefined &&
                dispatch({
                    type: SET_ACTIVE_DAY_IN_TRIP,
                    trip_destination_id: responseTripDestinations.data[0].td_id,
                    day_in_trip: "1",
                    day_in_trip_destination: "1",
                })


        } else {
            console.log("no permission")
        }

    const fetch_active_trip_activities_api_url = environment.apiUrl +"timeline/getTripActivities/en/" + uuid + "?tripId=" + tripId + "&getTripActivities"
    const responseTripActivities = await GetAsyncHelper(fetch_active_trip_activities_api_url)
        if (responseTripActivities.statusText === "OK") {
            dispatch({
                type: FETCH_ACTIVE_ACTIVITIES_IN_TRIP,
                activities: responseTripActivities.data.result
            })
        } else {
            console.log("no permission")
        }



  
}


/* set selected trip  */
export const setSelectedTripInformation = (trip) => async dispatch => {

    dispatch({
        type: GET_SELECTED_TRIP_INFORMATION,
        trip: trip,
    })
}
export const getTripInfoById= async (uuid, trip_id)  => {
    try {

        const url_active_trip_get = environment.apiUrl +'timeline/getTripInfo/en/' + uuid + '?tripId=' + trip_id + "&getTripInfo"
        const response = await Axios.get(url_active_trip_get)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        if(response.data?.result){
            return response.data?.result
        }
        return null;
    } catch (error) {
        console.error(error.message)
    }
}

export const getSelectedTripInformation = (uuid, trip_id) => async dispatch => {
    try {

        const response = await Axios.get(environment.apiUrl +"triplight/" + trip_id + "/en?uuid=" + uuid)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: GET_SELECTED_TRIP_INFORMATION,
            trip: response.data,
        })
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        return
    } catch (error) {
        console.error(error.message)
    }
}

export const clearSelectedTripInformation = () => async dispatch => {
    dispatch({
        type: CLEAR_SELECTED_TRIP_INFORMATION
    })
}

/* delete trip */
export const deleteTrip = (tripID, uuid, tripName) => async dispatch => {
    try {
        const response = await Axios.post(environment.apiUrl +'memberarea/trip/deletetrip/en/' + uuid + '?tripId=' + tripID)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: DELETE_TRIP_MODIFY_PROFILE,
        })
        dispatch({
            type: DELETE_TRIP_LIST,
            tripID: tripID,
        })
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Trip deleted"
        })
        Cookies.remove('muv_active_trip')
        dispatch(closeTripTimelineWizard())
        
    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed to delete " + tripName
        })
    }
}


/* update timeline trip name */
export const updateTimelineTripName = (tripID, tripName, uuid) => async dispatch => {
    try {
        const response = await Axios({
            method: 'post',
            url: environment.apiUrl +'memberarea/trip/renametrip/en/' + uuid,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                tripId: tripID,
                tripName: tripName
            })
        });
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }

        dispatch({
            type: MODIFY_TRIP_NAME_LISTING,
            trip_id: tripID,
            trip_name: tripName,
        })
        dispatch({
            type: SET_TIMELINE_ACTIVE_TRIP_NAME,
            trip_name: tripName,
        })
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: tripName + " saved"
        })
    } catch (error) {
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed."
        })
    }
}


/* update trip date */
export const updateTimelineTripDate = (tripId, tripDate, uuid) => async dispatch => {
    try {
        const response = await Axios({
            method: 'post',
            url: environment.apiUrl +'memberarea/trip/settripdate/en/' + uuid,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                tripId: tripId,
                tripDate: tripDate
            })
        });
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: SET_TIMELINE_ACTIVE_TRIP_START_DATE,
            trip_start_date: tripDate
        })
        dispatch({
            type: SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
            trip_no_date: "0"
        })
        dispatch({
            type: MODIFY_TRIP_DATE_LISTING,
            trip_id: tripId,
            no_date: "0",
            trip_date: tripDate,
        })

        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Saved"
        })

    } catch (error) {
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed"
        })
    }
}


/* unset trip date */
export const unsetTimelineTripDate = (tripId, uuid, tripStartDate, tripNoDate) => async dispatch => {
    if (tripNoDate === "0") {
        try {
            const response = await Axios({
                method: 'post',
                url: environment.apiUrl +'memberarea/trip/unsettripdate/en/' + uuid,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify({
                    tripId: tripId,
                })
            });
            if (response.statusText !== "OK") {
                throw Error(response.statusText);
            }
            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_START_DATE,
                trip_start_date: "0000-00-00"
            })

            dispatch({
                type: SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
                trip_no_date: "1"
            })


            dispatch({
                type: MODIFY_TRIP_DATE_LISTING,
                trip_id: tripId,
                no_date: "1",
                trip_date: "0000-00-00",
            })
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "success",
                message: "Saved."
            })
        } catch (error) {
            console.error(error.message)
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Failed"
            })
        }
    }
}

/* copy trip  */
export const copyTrip = (tripID, tripNameCopy, tripOrigin, uuid) => async dispatch => {
    try {
        const response = await Axios({
            method: 'post',
            url: environment.apiUrl +'memberarea/trip/copytrip/en/' + uuid,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                tripId: tripID,
                tripName: tripNameCopy,
            })
        });
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        tripOrigin.noDate === "1" ?
            dispatch({
                type: COPY_TRIP_DREAM_TRIPS,
                trip_copied: {
                    ...tripOrigin,
                    tripID: response.data.newTripId,
                    tripName: tripNameCopy,
                }
            }) :
            (moment().diff(tripOrigin.tripStartDate, 'days') > 0 ?
                dispatch({
                    type: COPY_TRIP_PAST_TRIPS,
                    trip_copied: {
                        ...tripOrigin,
                        tripID: response.data.newTripId,
                        tripName: tripNameCopy,
                    }
                }) : dispatch({
                    type: COPY_TRIP_FUTURE_TRIPS,
                    trip_copied: {
                        ...tripOrigin,
                        tripID: response.data.newTripId,
                        tripName: tripNameCopy,
                    }
                }))

        dispatch({
            type: COPY_TRIP_MODIFY_PROFILE,
        })
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: tripOrigin.tripName + " copied"
        })
    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed to copy trip " + tripOrigin.tripName
        })
    }
}

/*  share trip */
export const shareTrip = (tripID, tripName, emails, uuid) => async dispatch => {
    try {
        const response = await Axios({
            method: 'post',
            url: environment.apiUrl +'memberarea/trip/sharetrip/en/' + uuid,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                tripId: tripID,
                emails: emails,
            })
        });
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }

        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: tripName + " shared"
        })
    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed to share trip " + tripName
        })
    }
}



/* set toggle ellipsis close */
export const setToggleEllipsisCloseDisable = () => dispatch => {
    dispatch({
        type: TOGGLE_ELLIPSIS_CLOSE_DISABLE,
    })
}

/* set toggle ellipsis close */
export const setToggleEllipsisCloseEnable = () => dispatch => {
    dispatch({
        type: TOGGLE_ELLIPSIS_CLOSE_ENABLE,
    })
}

/* sort trips list by date descending */
export const sortTripsListByDateDescending = () => dispatch => {
    dispatch({
        type: SORT_TRIP_COLLECTION_BY_DATE_DESCENDING,
    })
}

/* sort trip list by dare ascending */
export const sortTripsListByDateAscending = () => dispatch => {
    dispatch({
        type: SORT_TRIP_COLLECTION_BY_DATE_ASCENDING,
    })
}


/* copy trip  */
export const copyPublicTrip = (tripID, tripName, uuid) => async dispatch => {
    try {
        const response = await Axios({
            method: 'post',
            url: environment.apiUrl +'memberarea/trip/copytrip/en/' + uuid,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                tripId: tripID,
                tripName: tripName,
            })
        });
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: SET_COPY_TRIP,
            copy_trip_id: String(response.data.newTripId),
        })
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Saved."
        })

    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: "Failed"
        })
    }
}


export const setCopyTrip = (tripId) => dispatch => {
    dispatch({
        type: SET_COPY_TRIP,
        copy_trip_id: String(tripId),
    })
}
export const uploadTripImage = (localImageUrl, tripId, previousSelectedFilePath, imageFile, uuid, lang) => async dispatch => {

    dispatch({
        type: SET_SELECTED_TRIP_IMAGE_MEMBER_AREA,
        trip_id: tripId,
        selected_file_path: localImageUrl,
    })
    previousSelectedFilePath !== "" && window.URL.revokeObjectURL(previousSelectedFilePath)
    /* updating, trip image */
    if (uuid !== undefined && uuid !== "") {
        const api_url_upload_image = environment.apiUrl +'media/uploadPhoto'
        const formData = new FormData();
        formData.set('uuid', uuid);
        formData.set('tripId', tripId);

        Array.prototype.forEach.call(imageFile, (file, index_file) => {
            formData.append("photos[" + index_file + "]", file);
        });
     
        PostAsyncHelperImage(api_url_upload_image, formData).then(responseUploadTripImages => {
            if (responseUploadTripImages !== undefined && responseUploadTripImages.statusText === "OK") {
                const api_url_assign_trip_image = environment.apiUrl +"memberarea/trip/updateTripHeaderImage/" + lang + "/" + uuid + "?assignToTrip"
                const assign_trip_image = {
                    tripId: tripId,
                    userUploadedImageId: responseUploadTripImages.data.result.id
                }

                PostAsyncHelper(api_url_assign_trip_image, assign_trip_image).then(responseAssignTripImage => {

                    if (responseAssignTripImage !== undefined && responseAssignTripImage.statusText === "OK") {
                        dispatch({
                            type: UPDATE_TRIP_IMAGE,
                            trip_id: tripId,
                            trip_image: responseAssignTripImage.data.results
                        })
                        dispatch({
                            type: UPDATE_SELECTED_HEADER_IMAGE,
                            header_image: responseAssignTripImage.data.results
                        })
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_IMAGE,
                            trip_image: responseAssignTripImage.data.results
                        })
                        dispatch({
                            type: ALERT_BARS_HANDLE,
                            message_type: "success",
                            message: "Saved!"
                        })
                    }
                })
            } else {
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "warning",
                    message: "Failed!"
                })
            }
        })
    }
}

/* modal bank auth */


/* open modal */
export const setOpenAuthModal = (open) => dispatch => {
    dispatch({
            type: SET_MODAL_OPEN_AUTH,
            open: open,
        })
        /* login first */
        !open &&
        dispatch({
            type: SET_MODAL_ROUTE_AUTH,
            route: "Login",
        })
}
/*  set current */
export const setRouteAuthModal = (route) => dispatch => {
    dispatch({
        type: SET_MODAL_ROUTE_AUTH,
        route: route,
    })
}

/*  register a member account */

export const registerMemberAccount = (email, first_name, last_name, password, handleDefault = "", imageFile = null,airport=null,hometown=null,
hideNotification) => async dispatch => {


    let api_url = environment.apiUrl +"memberregister"
    try {
        const options = {
            arrayFormat: 'indices',
            format: 'RFC3986',
            charset: 'utf-8',
        }
        let obj = {
            email: email,
            firstname: first_name,
            lastname: last_name,
            password: password,
            ...hometown,
            airport:airport
        }
        const response = await Axios({
            method: 'post',
            url: api_url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(obj, options)
        });
        if (response.statusText !== "OK") {
            throw Error(response.error);
        }
        if (response.data.status !== "success") {
            throw Error(response.data.message);
        }
        const member = response.data.message
        const uuid = member.memberRestUUID
        const handle = member.memberHandle
        const firstName = member.memberFirstName
        const lastName = member.memberLastName
        const currency = member.currency
        const language = member.language === undefined && member.language === null ? {
            short_code: "en",
            long_code: "eng",
            englishName: "EN",
            nativeName: "English"
        } : member.language
        const token = member.userToken
        const memberStatus = member.userStatus

        dispatch({
            type: SET_AUTH_UUID,
            uuid: uuid
        })
        dispatch({
            type: SET_AUTH_HANDLE,
            handle: handle
        })
        dispatch({
            type: SET_AUTH_FIRST_NAME,
            first_name: firstName
        })
        dispatch({
            type: SET_AUTH_LAST_NAME,
            last_name: lastName
        })
        dispatch({
            type: SET_MODAL_CURRENCY_AUTH,
            currency: currency
        })
        dispatch({
            type: SET_MODAL_LANGUAGE_AUTH,
            language: language
        })
        dispatch({
            type: SET_MODAL_MEMBER_STATUS_AUTH,
            member_status: memberStatus
        })
        dispatch({
            type: SET_MODAL_TOKEN_AUTH,
            token: token
        })
        /* set cookies */
        Cookies.set('muv_token', token, {
            expires: 365
        })
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "The new member account " + email
        })
        dispatch({
            type: SET_MODAL_OPEN_AUTH,
            open: false,
        })
        /* upload member action */
        const member_actions_cookies = Cookies.get('muv_member_actions')
        const member_action_object = Object.values(qs.parse(member_actions_cookies))
        if (uuid !== undefined && uuid !== "" && member_actions_cookies !== undefined && member_actions_cookies !== "") {
            let obj = {
                uuid: uuid,
                memberaction: member_action_object,
            }

            let api_url_post_local_member_actions = environment.apiUrl +"useraction"
            let api_url_get_stars = environment.apiUrl +"getMemberActions/" + uuid + "/en?actionType=star"
            PostAsyncHelper(api_url_post_local_member_actions, obj).then(response => {
                GetAsyncHelper(api_url_get_stars).then(responseStars => {
                    if (responseStars.statusText === "OK") {
                        dispatch({
                            type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                            stars: responseStars.data
                        })
                        /* remove cookies */
                        Cookies.remove('muv_member_actions')
                    } else {
                        dispatch({
                            type: FETCH_API_ERROR_MEMBER_ACTIONS,
                            api_error: true
                        })
                    }
                })
            })
        }

        const language_cookies = Cookies.get('muv_language')

        if (language_cookies !== undefined && language_cookies !== "") {
            const language_cookies_object = qs.parse(language_cookies)
            const obj = {
                short_code: language_cookies_object.short_code,
                long_code: language_cookies_object.long_code,
                englishName: language_cookies_object.englishName,
                nativeName: language_cookies_object.nativeName,
            }
            const obj_post = {
                token: token,
                language: obj,
            }
            const api_url = environment.apiUrl +"memberUpdate"

            PostAsyncHelper(api_url, obj_post)
        }

        const currency_cookies = Cookies.get('muv_currency')
        if (currency_cookies !== undefined && currency_cookies !== "") {
            const currency_cookies_object = qs.parse(currency_cookies)
            const obj = {
                short_code: currency_cookies_object.short_code,
                symbol: currency_cookies_object.symbol,
                name: currency_cookies_object.name,
            }
            let obj_post = {
                token: token,
                currency: obj,
            }

            let api_url = environment.apiUrl +"memberUpdate"

            PostAsyncHelper(api_url, obj_post)
        }
        /* updating handler */
        if (handleDefault !== "") {
            let api_url = environment.apiUrl +'memberUpdate?user_handle=' + handleDefault + '&token=' + token
            PostAsyncHelper(api_url)
        }
        /* updating, avatar image */
        if (imageFile !== null) {
            let api_url = environment.apiUrl +'muverUpdateMemberAvatar'
            const formData = new FormData();
            formData.set('token', token);
            formData.append('avatar', imageFile);

            PostAsyncHelperImage(api_url, formData)
        }
    } catch (error) {
        console.error(error.message)
        if(!hideNotification){
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: error.message
            })
        }
       
    }
}

/*  register a member account */

export const loginMemberAccount = (username, password,hideNavigate=false) => async dispatch => {


    let api_url = environment.apiUrl +"memberlogin?username=" + username + "&password=" + password
    try {
        const response = await Axios({
            method: 'post',
            url: api_url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        });
        if (response.statusText !== "OK") {
            throw Error(response.error);
        }
        if (response.data.status !== "success") {
            throw Error(response.data.message);
        }
        const member = response.data.message
        const uuid = member.memberRestUUID
        const handle = member.memberHandle
        const firstName = member.memberFirstName
        const lastName = member.memberLastName
        const currency = member.currency
        const language = member.language === undefined && member.language === null ? {
            short_code: "en",
            long_code: "eng",
            englishName: "EN",
            nativeName: "English"
        } : member.language
        const token = member.userToken
        const memberStatus = member.userStatus

        dispatch({
            type: SET_AUTH_UUID,
            uuid: uuid
        })
        dispatch({
            type: SET_AUTH_HANDLE,
            handle: handle
        })
        dispatch({
            type: SET_AUTH_FIRST_NAME,
            first_name: firstName
        })
        dispatch({
            type: SET_AUTH_LAST_NAME,
            last_name: lastName
        })

        dispatch({
            type: SET_LAST_NAME,
            last_name: lastName,
        })
        dispatch({
            type: SET_FIRST_NAME,
            first_name: firstName,
        })
        dispatch({
            type: SET_MODAL_CURRENCY_AUTH,
            currency: currency
        })
        dispatch({
            type: SET_MODAL_LANGUAGE_AUTH,
            language: language
        })
        dispatch({
            type: SET_MODAL_MEMBER_STATUS_AUTH,
            member_status: memberStatus
        })
        dispatch({
            type: SET_MODAL_TOKEN_AUTH,
            token: token
        })

        /* set cookies */
        Cookies.set('muv_token', token, {
            expires: 365
        })

        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: "Login successfully  "
        })
        dispatch({
            type: SET_MODAL_OPEN_AUTH,
            open: false,
        })
        /* upload member action */
        const member_actions_cookies = Cookies.get('muv_member_actions')
        const member_action_object = Object.values(qs.parse(member_actions_cookies))
        if (uuid !== undefined && uuid !== "" && member_actions_cookies !== undefined && member_actions_cookies !== "") {
            let obj = {
                uuid: uuid,
                memberaction: member_action_object,
            }

            let api_url_post_local_member_actions = environment.apiUrl +"useraction"
            let api_url_get_stars = environment.apiUrl +"getMemberActions/" + uuid + "/en?actionType=star"
            PostAsyncHelper(api_url_post_local_member_actions, obj).then(response => {
                GetAsyncHelper(api_url_get_stars).then(responseStars => {
                    if (responseStars.statusText === "OK") {
                        dispatch({
                            type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                            stars: responseStars.data
                        })
                        /* remove cookies */
                        Cookies.remove('muv_member_actions')
                    } else {
                        dispatch({
                            type: FETCH_API_ERROR_MEMBER_ACTIONS,
                            api_error: true
                        })
                    }
                })
            })
        }
        if (uuid !== undefined && uuid !== "") {
            let api_url_get_followers = environment.apiUrl +"getMuverFollow/" + uuid + "?followType=following"
            GetAsyncHelper(api_url_get_followers).then(responseFollowers => {
                if (responseFollowers.statusText === "OK") {
                    dispatch({
                        type: FETCH_FOLLOWERS_SETS,
                        followers: responseFollowers.data.result
                    })
                    dispatch({
                        type: IS_FETCHED_FOLLOWERS_SETS,
                        is_fetched: true
                    })
                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        }
        const language_cookies = Cookies.get('muv_language')

        if (language_cookies !== undefined && language_cookies !== "") {
            const language_cookies_object = qs.parse(language_cookies)
            const obj = {
                short_code: language_cookies_object.short_code,
                long_code: language_cookies_object.long_code,
                englishName: language_cookies_object.englishName,
                nativeName: language_cookies_object.nativeName,
            }
            const obj_post = {
                token: token,
                language: obj,
            }
            const api_url = environment.apiUrl +"memberUpdate"

            PostAsyncHelper(api_url, obj_post)
        }

        const currency_cookies = Cookies.get('muv_currency')
        if (currency_cookies !== undefined && currency_cookies !== "") {
            const currency_cookies_object = qs.parse(currency_cookies)
            const obj = {
                short_code: currency_cookies_object.short_code,
                symbol: currency_cookies_object.symbol,
                name: currency_cookies_object.name,
            }
            let obj_post = {
                token: token,
                currency: obj,
            }

            let api_url = environment.apiUrl +"memberUpdate"

            PostAsyncHelper(api_url, obj_post)
        }
        /*  landing page  */

        if(!hideNavigate){
            dispatch({
                type: SWITCH_PROFILE_CONTAINER,
                switch_container: 'Interests',
            })
        }
       
        const muv_active_trip = Cookies.get('muv_active_trip')
        let obj_post_url_assign_member_to_trip = {
            tripId: muv_active_trip,
        }
        const same_login_username = Cookies.get('username') === username
        if (!same_login_username) {
            Cookies.remove('muv_active_trip')
        }
        const url_assign_member_to_trip = environment.apiUrl +'timeline/assignMemberToTrip/en/' + uuid
        const url_active_trip_get = environment.apiUrl +'timeline/getTripInfo/en/' + uuid + '?tripId=' + muv_active_trip + "&getTripInfo"
        if (same_login_username && muv_active_trip !== undefined && muv_active_trip !== "") {
            PostAsyncHelper(url_assign_member_to_trip, obj_post_url_assign_member_to_trip).then(responseAssignMemberToTrip => {
                GetAsyncHelper(url_active_trip_get).then(responseTrip => {
                    if (responseTrip.statusText === "OK") {
                        const trip = responseTrip.data.result;
                        dispatch(updateActiveTrip(trip));
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_ID,
                            trip_id: trip.tripID
                        })

                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_NAME,
                            trip_name: trip.tripName
                        })

                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_START_DATE,
                            trip_start_date: trip.noDate === "0" ? trip.tripStartDate : "0000-00-00"
                        })

                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
                            trip_no_date: trip.noDate
                        })

                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_DURATION,
                            trip_duration: trip.tripDaysCount
                        })
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION,
                            trip_description: trip.tripDescription
                        })
                        dispatch({
                            type: SET_TRIP_TYPE_SELECTED,
                            type_id: trip.typeId
                        })
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS,
                            trip_auth_status: trip.isPublic
                        })
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_IMAGE,
                            trip_image: trip.headerImage
                        })
                        dispatch({
                            type: SET_LEAVING_FROM_AIRPORT_SELECTED,
                            airport_selected: trip.leavingFromAirport,
                        })
                        dispatch({
                            type: SET_RETURNING_TO_AIRPORT_SELECTED,
                            airport_selected: trip.returningToAirport,
                        })
                        dispatch({
                            type: SET_TIMELINE_WIZARD_STATUS,
                            status: true
                        })

                    } else {
                        console.log("no permission")
                    }
                })
                const url_active_trip_get_cost = environment.apiUrl +"timeline/getTripCost/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripCost"
                dispatch({
                    type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
                    status: true
                })
                GetAsyncHelper(url_active_trip_get_cost).then(responseTripCost => {
                    if (responseTripCost.statusText === "OK") {
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_COST,
                            trip_cost: responseTripCost.data.result
                        })
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
                            status: false
                        })

                    } else {
                        console.log("no permission")
                    }
                })
                const url_active_trip_get_destinations = environment.apiUrl +"timeline/getTripDestinations/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripDestinations"
                GetAsyncHelper(url_active_trip_get_destinations).then(responseTripDestinations => {
                    if (responseTripDestinations.statusText === "OK") {
                        dispatch({
                            type: SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS,
                            trip_destinations: responseTripDestinations.data
                        })
                    } else {
                        console.log("no permission")
                    }
                })
                const fetch_active_trip_activities_api_url = environment.apiUrl +"timeline/getTripActivities/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripActivities"
                GetAsyncHelper(fetch_active_trip_activities_api_url).then(responseTripActivities => {
                    if (responseTripActivities.statusText === "OK") {
                        dispatch({
                            type: FETCH_ACTIVE_ACTIVITIES_IN_TRIP,
                            activities: responseTripActivities.data.result
                        })
                    } else {
                        console.log("no permission")
                    }
                })
            })
        }
        /* close the modal  */
        dispatch({
            type: SET_TIMELINE_WIZARD_DELETE_ALART_STATUS,
            status: false,
        })
    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: error.message
        })
    }

    Cookies.set('username', username, {
           expires: 365
        })
}

/* login by token */
export const tokenLogin = (token) => async dispatch => {
    let token_cookies = Cookies.get('muv_token')
    dispatch({
        type: SET_MODAL_COOKIES_EXIST_AUTH,
        cookiesExsit: true
    })
    if (token_cookies !== undefined) {

        let api_url = environment.apiUrl +"membertoken?token=" + token_cookies
        try {
            const response = await Axios({
                method: 'post',
                url: api_url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            });
            if (response.statusText !== "OK") {
                throw Error(response.error);
            }
            if (response.data.status !== "success") {
                throw Error(response.data.message);
            }
            const member = response.data.message
            const uuid = member.memberRestUUID
            const handle = member.memberHandle
            const firstName = member.memberFirstName
            const lastName = member.memberLastName
            const currency = member.currency
            const language = member.language === undefined && member.language === null ? {
                short_code: "en",
                long_code: "eng",
                englishName: "EN",
                nativeName: "English"
            } : member.language
            const token = member.userToken
            const memberStatus = member.userStatus

            dispatch({
                type: SET_AUTH_UUID,
                uuid: uuid
            })
            dispatch({
                type: SET_AUTH_HANDLE,
                handle: handle
            })
            dispatch({
                type: SET_AUTH_FIRST_NAME,
                first_name: firstName
            })
            dispatch({
                type: SET_AUTH_LAST_NAME,
                last_name: lastName
            })
            dispatch({
                type: SET_FIRST_NAME,
                first_name: firstName,
            })
            dispatch({
                type: SET_LAST_NAME,
                last_name: lastName,
            })

            dispatch({
                type: SET_MODAL_CURRENCY_AUTH,
                currency: currency
            })
            dispatch({
                type: SET_MODAL_LANGUAGE_AUTH,
                language: language
            })
            dispatch({
                type: SET_MODAL_MEMBER_STATUS_AUTH,
                member_status: memberStatus
            })
            dispatch({
                type: SET_MODAL_TOKEN_AUTH,
                token: token
            })

            dispatch({
                type: SET_MODAL_OPEN_AUTH,
                open: false,
            })
            if (uuid !== undefined && uuid !== "") {
                let api_url_get_followers = environment.apiUrl +"getMuverFollow/" + uuid + "?followType=following"
                GetAsyncHelper(api_url_get_followers).then(responseFollowers => {
                    if (responseFollowers.statusText === "OK") {
                        dispatch({
                            type: FETCH_FOLLOWERS_SETS,
                            followers: responseFollowers.data.result
                        })
                        dispatch({
                            type: IS_FETCHED_FOLLOWERS_SETS,
                            is_fetched: true
                        })
                    } else {
                        dispatch({
                            type: FETCH_API_ERROR_MEMBER_ACTIONS,
                            api_error: true
                        })
                    }
                })
            }

        } catch (error) {
            console.error(error.message)

        }
    }
}


export const loginWithToken = (authToken) => async dispatch => {
    dispatch({
        type: SET_MODAL_COOKIES_EXIST_AUTH,
        cookiesExsit: true
    })
 
    if (authToken !== undefined) {

        let api_url = environment.apiUrl +"membertoken?token=" + authToken
        try {
            const response = await Axios({
                method: 'post',
                url: api_url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            });
            if (response.statusText !== "OK") {
                throw Error(response.error);
            }
            if (response.data.status !== "success") {
                throw Error(response.data.message);
            }
            const member = response.data.message
            const uuid = member.memberRestUUID
            const handle = member.memberHandle
            const firstName = member.memberFirstName
            const lastName = member.memberLastName
            const currency = member.currency
            const language = member.language === undefined && member.language === null ? {
                short_code: "en",
                long_code: "eng",
                englishName: "EN",
                nativeName: "English"
            } : member.language
            const token = member.userToken
            const memberStatus = member.userStatus
            let username = member?.memberEmail;
            dispatch({
                type: SET_AUTH_UUID,
                uuid: uuid
            })
            dispatch({
                type: SET_AUTH_HANDLE,
                handle: handle
            })
            dispatch({
                type: SET_AUTH_FIRST_NAME,
                first_name: firstName
            })
            dispatch({
                type: SET_AUTH_LAST_NAME,
                last_name: lastName
            })
    
            dispatch({
                type: SET_LAST_NAME,
                last_name: lastName,
            })
            dispatch({
                type: SET_FIRST_NAME,
                first_name: firstName,
            })
            dispatch({
                type: SET_MODAL_CURRENCY_AUTH,
                currency: currency
            })
            dispatch({
                type: SET_MODAL_LANGUAGE_AUTH,
                language: language
            })
            dispatch({
                type: SET_MODAL_MEMBER_STATUS_AUTH,
                member_status: memberStatus
            })
            dispatch({
                type: SET_MODAL_TOKEN_AUTH,
                token: token
            })
    
            /* set cookies */
            Cookies.set('muv_token', token, {
                expires: 365
            })
    
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "success",
                message: "Login successfully  "
            })
            dispatch({
                type: SET_MODAL_OPEN_AUTH,
                open: false,
            })
            /* upload member action */
            
            
            const member_actions_cookies = Cookies.get('muv_member_actions')
            const member_action_object = Object.values(qs.parse(member_actions_cookies))
            if (uuid !== undefined && uuid !== "" && member_actions_cookies !== undefined && member_actions_cookies !== "") {
                let obj = {
                    uuid: uuid,
                    memberaction: member_action_object,
                }
    
                let api_url_post_local_member_actions = environment.apiUrl +"useraction"
                let api_url_get_stars = environment.apiUrl +"getMemberActions/" + uuid + "/en?actionType=star"
                PostAsyncHelper(api_url_post_local_member_actions, obj).then(response => {
                    GetAsyncHelper(api_url_get_stars).then(responseStars => {
                        if (responseStars.statusText === "OK") {
                            dispatch({
                                type: FETCH_STAR_SETS_MEMBER_ACTIONS,
                                stars: responseStars.data
                            })
                            /* remove cookies */
                            Cookies.remove('muv_member_actions')
                        } else {
                            dispatch({
                                type: FETCH_API_ERROR_MEMBER_ACTIONS,
                                api_error: true
                            })
                        }
                    })
                })
            }
            if (uuid !== undefined && uuid !== "") {
                let api_url_get_followers = environment.apiUrl +"getMuverFollow/" + uuid + "?followType=following"
                GetAsyncHelper(api_url_get_followers).then(responseFollowers => {
                    if (responseFollowers.statusText === "OK") {
                        dispatch({
                            type: FETCH_FOLLOWERS_SETS,
                            followers: responseFollowers.data.result
                        })
                        dispatch({
                            type: IS_FETCHED_FOLLOWERS_SETS,
                            is_fetched: true
                        })
                    } else {
                        dispatch({
                            type: FETCH_API_ERROR_MEMBER_ACTIONS,
                            api_error: true
                        })
                    }
                })
            }
            const language_cookies = Cookies.get('muv_language')
    
            if (language_cookies !== undefined && language_cookies !== "") {
                const language_cookies_object = qs.parse(language_cookies)
                const obj = {
                    short_code: language_cookies_object.short_code,
                    long_code: language_cookies_object.long_code,
                    englishName: language_cookies_object.englishName,
                    nativeName: language_cookies_object.nativeName,
                }
                const obj_post = {
                    token: token,
                    language: obj,
                }
                const api_url = environment.apiUrl +"memberUpdate"
    
                PostAsyncHelper(api_url, obj_post)
            }
    
            const currency_cookies = Cookies.get('muv_currency')
            if (currency_cookies !== undefined && currency_cookies !== "") {
                const currency_cookies_object = qs.parse(currency_cookies)
                const obj = {
                    short_code: currency_cookies_object.short_code,
                    symbol: currency_cookies_object.symbol,
                    name: currency_cookies_object.name,
                }
                let obj_post = {
                    token: token,
                    currency: obj,
                }
    
                let api_url = environment.apiUrl +"memberUpdate"
    
                PostAsyncHelper(api_url, obj_post)
            }
 
            const muv_active_trip = Cookies.get('muv_active_trip')
     
            let obj_post_url_assign_member_to_trip = {
                tripId: muv_active_trip,
            }
            const same_login_username = Cookies.get('username') === username
            if (!same_login_username) {
                Cookies.remove('muv_active_trip')
            }
            const url_assign_member_to_trip = environment.apiUrl +'timeline/assignMemberToTrip/en/' + uuid
            const url_active_trip_get = environment.apiUrl +'timeline/getTripInfo/en/' + uuid + '?tripId=' + muv_active_trip + "&getTripInfo"
            if (same_login_username && muv_active_trip !== undefined && muv_active_trip !== "") {
                PostAsyncHelper(url_assign_member_to_trip, obj_post_url_assign_member_to_trip).then(responseAssignMemberToTrip => {
                    GetAsyncHelper(url_active_trip_get).then(responseTrip => {
                        if (responseTrip.statusText === "OK") {
                            const trip = responseTrip.data.result;
                            dispatch(updateActiveTrip(trip));
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_ID,
                                trip_id: trip.tripID
                            })
    
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_NAME,
                                trip_name: trip.tripName
                            })
    
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_START_DATE,
                                trip_start_date: trip.noDate === "0" ? trip.tripStartDate : "0000-00-00"
                            })
    
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
                                trip_no_date: trip.noDate
                            })
    
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_DURATION,
                                trip_duration: trip.tripDaysCount
                            })
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION,
                                trip_description: trip.tripDescription
                            })
                            dispatch({
                                type: SET_TRIP_TYPE_SELECTED,
                                type_id: trip.typeId
                            })
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS,
                                trip_auth_status: trip.isPublic
                            })
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_IMAGE,
                                trip_image: trip.headerImage
                            })
                            dispatch({
                                type: SET_LEAVING_FROM_AIRPORT_SELECTED,
                                airport_selected: trip.leavingFromAirport,
                            })
                            dispatch({
                                type: SET_RETURNING_TO_AIRPORT_SELECTED,
                                airport_selected: trip.returningToAirport,
                            })
                            dispatch({
                                type: SET_TIMELINE_WIZARD_STATUS,
                                status: true
                            })
    
                        } else {
                            console.log("no permission")
                        }
                    })
                    const url_active_trip_get_cost = environment.apiUrl +"timeline/getTripCost/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripCost"
                    dispatch({
                        type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
                        status: true
                    })
                    GetAsyncHelper(url_active_trip_get_cost).then(responseTripCost => {
                        if (responseTripCost.statusText === "OK") {
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_COST,
                                trip_cost: responseTripCost.data.result
                            })
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
                                status: false
                            })
    
                        } else {
                            console.log("no permission")
                        }
                    })
                    const url_active_trip_get_destinations = environment.apiUrl +"timeline/getTripDestinations/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripDestinations"
                    GetAsyncHelper(url_active_trip_get_destinations).then(responseTripDestinations => {
                        if (responseTripDestinations.statusText === "OK") {
                            dispatch({
                                type: SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS,
                                trip_destinations: responseTripDestinations.data
                            })
                        } else {
                            console.log("no permission")
                        }
                    })
                    const fetch_active_trip_activities_api_url = environment.apiUrl +"timeline/getTripActivities/en/" + uuid + "?tripId=" + muv_active_trip + "&getTripActivities"
                    GetAsyncHelper(fetch_active_trip_activities_api_url).then(responseTripActivities => {
                        if (responseTripActivities.statusText === "OK") {
                            dispatch({
                                type: FETCH_ACTIVE_ACTIVITIES_IN_TRIP,
                                activities: responseTripActivities.data.result
                            })
                        } else {
                            console.log("no permission")
                        }
                    })
                })
            }
            /* close the modal  */
            dispatch({
                type: SET_TIMELINE_WIZARD_DELETE_ALART_STATUS,
                status: false,
            })
        }  catch (error) {
            console.error(error.message)
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: error.message
            })
        }
    }
}


export const logoutMemberAccount = () => dispatch => {
   dispatch({
        type: SET_AUTH_UUID,
        uuid: ""
    })
    dispatch({
        type: SET_AUTH_HANDLE,
        handle: ""
    })
    dispatch({
        type: SET_AUTH_FIRST_NAME,
        first_name: ""
    })
    dispatch({
        type: SET_AUTH_LAST_NAME,
        last_name: ""
    })
    dispatch({
        type: SET_MODAL_CURRENCY_AUTH,
        currency: ""
    })
    dispatch({
        type: SET_MODAL_LANGUAGE_AUTH,
        language: ""
    })
    dispatch({
        type: SET_MODAL_MEMBER_STATUS_AUTH,
        member_status: ""
    })
    dispatch({
        type: SET_MODAL_TOKEN_AUTH,
        token: ""
    })

    //dispatch({
    //    type: RESET_MEMBER_ACCOUNT,
    //})

    /* remove  cookies */
    Cookies.remove('muv_token')

   dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "success",
        message: "Sign out successfully  "
    })
    dispatch({
        type: SET_MODAL_OPEN_AUTH,
        open: false,
    })

    dispatch({
        type: SET_TIMELINE_WIZARD_STATUS,
        status: false
    })

    dispatch({
        type: CLEAR_ALL_TRIP_INFORMATION,
    })
}


export const modifyFollowing = (uuid_viewer, muver_id, action) => async dispatch => {


    let api_url = environment.apiUrl +"memberFriends/" + action
    try {
        const options = {
            arrayFormat: 'indices',
            format: 'RFC3986',
            charset: 'utf-8',
        }
        let obj = {
            uuid: uuid_viewer,
            followID: muver_id,
        }
        const response = await Axios({
            method: 'post',
            url: api_url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(obj, options)
        });
        if (response.statusText !== "OK") {
            throw Error(response.error);
        }
        if (uuid_viewer !== undefined && uuid_viewer !== "") {
            let api_url_get_followers = environment.apiUrl +"getMuverFollow/" + uuid_viewer + "?followType=following"
            GetAsyncHelper(api_url_get_followers).then(responseFollowers => {
                if (responseFollowers.statusText === "OK") {
                    dispatch({
                        type: FETCH_FOLLOWERS_SETS,
                        followers: responseFollowers.data.result
                    })
                    dispatch({
                        type: IS_FETCHED_FOLLOWERS_SETS,
                        is_fetched: true
                    })
                } else {
                    dispatch({
                        type: FETCH_API_ERROR_MEMBER_ACTIONS,
                        api_error: true
                    })
                }
            })
        }
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "success",
            message: action + "ed!"
        })
    } catch (error) {
        console.error(error.message)
        dispatch({
            type: ALERT_BARS_HANDLE,
            message_type: "error",
            message: error.message
        })
    }
}

export const isAuthenticated = async () =>   {
    let token_cookies = Cookies.get('muv_token')
    if (token_cookies !== undefined) {

        let api_url = environment.apiUrl +"membertoken?token=" + token_cookies
        try {
            const response = await Axios({
                method: 'post',
                url: api_url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            });
            const member = response.data.message
            const uuid = member.memberRestUUID

            if(uuid){
                return true;
            }
            return false;
        } catch (error) {
            return false;

        }
    }
}



export const getHomeDetails = async () =>   {
    let token_cookies = Cookies.get('muv_token')
    if (token_cookies !== undefined) {

        let api_url = environment.apiUrl +"membertoken?token=" + token_cookies
        try {
            const response = await Axios({
                method: 'post',
                url: api_url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
            });
            const member = response.data.message

            const userHome = member.user_home

            if(userHome){
                return userHome;
            }
            return null;
        } catch (error) {
            return false;

        }
    }
}