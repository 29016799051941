import { TextField } from '@material-ui/core'
import React from 'react'
import { flightTextField,flightTextArea } from '../FlightsPage/flightStyles';



export const GuestInfo = ({is_mobile, setGuestList, guestList, promoCode, setPromoCode,setSpecialRequest, specialRequest,roomText,setContactInfo }) => {



    const classesAutocomplete = flightTextField()
    const textAreaStyles = flightTextArea()
    const [addRequest, setRequest] = React.useState(false);
    
    const addSpecialRequest = () => {
        setRequest(!addRequest)
    }

    return (
        <div>

            {guestList.map((guest, index) => {

                const guestCopy = { ...guest };


                const updateValue = (field, value) => {
                    setValues(field, value)
                };

                const setValues = (field, value) => {
                    guestCopy[field] = value;
                    guestList[index] = guestCopy;
                    setGuestList(guestList)

                    if(index === 0 && guestCopy){
                              const contact  = {
                                     name_last: guestCopy?.guest_name_last,
                                     name_first: guestCopy?.guest_name_first
                              }
                               setContactInfo(contact)  
                    }
                }

                return (

                    <div key={index}>
                        {guestList && guestList.length > 1 && <p style={{ font: '20px/20px Futura Hv BT', marginTop: 30 }}> Room {index + 1}: {roomText} </p>}




                        <div className="row">
                            <div className={is_mobile?'col-12':'col-6'} style={{
                                marginTop: "20px", paddingRight: 0
                            }}>

                                <div className={classesAutocomplete.root}>
                                    <TextField
                                        label='Last Name'
                                        defaultValue={guest.guest_name_last}
                                        required={true}
                                        id={'Last Name' + index}
                                        onChange={(event) => updateValue('guest_name_last', event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={is_mobile?'col-12':'col-6'}  style={{
                                marginTop: "20px",
                            }}>

                                <div className={classesAutocomplete.root}>
                                    <TextField
                                        label='First Name'
                                        defaultValue={guest.guest_name_first}
                                        required={true}
                                        id={'First Name' + index}
                                        onChange={(event) => updateValue('guest_name_first', event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                )
            })}

            <div onClick={addSpecialRequest} style={{ background: 'transparent', cursor: 'pointer', color: '#19BC9B', font: '14px/14px Futura Hv BT', marginTop: 20, border: 'none' }}>
                {!addRequest ? '+' : '-'} any special request?
            </div>

           {addRequest && <div style={{ font: '16px/16px Futura Lt BT', lineHeight: 1.5, marginTop: 20, justifyContent: 'center' }}>
                Please include detail of your special requests (e.g. roll away beds, late check in). After booking the hotel will be notified of your requests to confirm availability. If confirmed by the hotel, you will receive a confirmation email. If you do not receive a confirmation email within 24 hours of the day that you made your booking, please contact the hotel directly to confirm or to help make alternative arrangements. Special requests are not guaranteed. Special requests may incur additional charges.

    
                        <div className={textAreaStyles.root}  style={{marginTop:20}}>
                            <TextField
                                placeholder=""
                                id='specialRequests'
                                multiline
                                defaultValue={specialRequest}
                                onChange={(event) => setSpecialRequest(event.target.value)}
                            />
                        </div>
            </div>}

            <p style={{ font: '20px/20px Futura Hv BT', marginTop: 30 }}> Promo code </p>

            <div className="row">
                <div className={is_mobile?'col-12':'col-6'}  style={{
                    marginTop: "20px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Promo code'
                            placeholder='Please Enter Promo code'
                            id='Promo code'
                            defaultValue={promoCode}
                            onChange={(event) => setPromoCode(event.target.value)}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}