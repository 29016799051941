import {
    StarIcon,
} from "../SVGBank"

import React from 'react'
import { connect, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import moment from "moment"
import { EstablishmentDescription } from "../EstablishmentDescription"
import { getPriceLineHotels } from "../../../../redux/actions"
import { URL_REPLACE } from "../RegExValidate"
import { useHistory, useLocation } from "react-router-dom"
import { encryptID } from "../../HotelPage/hotelServices"

const TimelineTripHotel_ = ({hotelSearchForm, hotel,lazyLoading, switchFeatureContainer }) => {
    const checkIn = hotel.check_in ? moment(hotel.check_in).format('ddd MMM DD') : "";
    const checkOut = hotel.check_out ? moment(hotel.check_out).format('ddd MMM DD') : "";
    const imageUrl = hotel?.images && hotel?.images.length > 0 ? hotel?.images[0]?.full_url : null;
    const bookingId = hotel?.booking_trip_number;
    const email = hotel?.booking_email;
    const canViewDetails = email && bookingId && imageUrl;
    const history = useHistory()
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";

    const goToBooking = () => {

        if (canViewDetails) {
            const url = `/hotel/booking_details?booking_id=${bookingId}&email=${email}&imageUrl=${imageUrl}`;
            window.open(url);
        }
    }

    const bookNow = async () => {
        ///setTimeLineStatus(true);
        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        const city_id = hotel?.pricelineCityId;
        const check_in = hotel?.check_in;
        const check_out = hotel?.check_out;
        const rooms = 1;
        const adults = 1;
        const featuredHotels = hotel?.pricelineHotelId;
        const city_latitude = hotel?.latitude;
        const city_longitude = hotel?.longitude;
        const isSearching = hotelSearchForm.isSearching !== undefined ? hotelSearchForm.isSearching : true;
        
        const getData = await getPriceLineHotels({ city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude, isSearching }, lazyLoading, fullCurrency, featuredHotels)     // FormBankAction  [fetchPricelineHotels]

        const params = {
            check_in: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            check_out: hotel?.activityDuration ? moment(new Date(), 'YYYY-MM-DD').add(parseInt(hotel?.activityDuration, 10), 'days').format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            lat: hotel?.activityLatitude,
            lng: hotel?.activityLongitude,
        }

        let url = "";
        const validCheckin =  moment(new Date(hotel?.check_in), 'YYYY-MM-DD').format('YYYY-MM-DD');
        if (hotel?.pricelineCityId) {
            const encryptedId =  encryptID( hotel?.pricelineHotelId)
            url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                + "?city_id=" + hotel?.pricelineCityId
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))

            if (hotel?.ppn_bundle) {
                url += "&ppn_bundle=" + hotel?.ppn_bundle
            }
        }
        else {

            url = "/hotel-search?city_id=" + null
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))
        }


        history.push(url.toLowerCase())
    }

    return (
        <div style={{
            marginTop: "20px",
            marginLeft: "0px",
            marginRight: "8px",
            marginBottom: "12px",
            backgroundColor: "var(--mainWhite)",
            position: "relative",
            width: 380,
            border: "1px solid #E8E8E8"
        }}>
            <div style={{
                background: "var(--frameBlack)"
            }}>
                {hotel && hotel.images && hotel.images.length > 0 && <img height={156} style={{
                    width: "100%",
                    opacity: 0.6,
                    objectFit: "cover"
                }} src={hotel.images[0]?.full_url} alt="hotelImage"
                    onError={({ currentTarget }) => {
                        const thumbnailImg = hotel?.room_json?.thumbnail ? "http://" + hotel?.room_json?.thumbnail?.slice(2)?.replace("150", "300") : "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = thumbnailImg;
                    }} />}
            </div>
            <Grid container spacing={3} style={{
                paddingTop: 10, marginBottom: 10,
                font: "16px/21px Futura Md BT", color: "white", position: "absolute", marginTop: -50, marginLeft: 0
            }}>
                <Grid item xs={9} md={9} lg={9} xl={9} style={{
                    paddingLeft: 20, textAlign: "left", maxHeight: 30, whiteSpace: "nowrap",
                    overflow: "hidden", textOverflow: "ellipsis"
                }} >
                    {hotel.name}
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} style={{ textAlign: "left", padding: 0, paddingTop: 12, paddingLeft: 20 }} >
                    <span style={{ color: "#19BC9B", font: "16px/21px Futura Hv BT", paddingLeft: 5 }}> {hotel.starRating}  <StarIcon width={15} height={15} color="#19BC9B" style={{ marginTop: -5 }} /> </span>
                </Grid>
            </Grid>

            <div className="" style={{
                font: "14px/16px Futura Md BT",
                marginTop: 16,
                textAlign: "left",
                paddingLeft: 8
            }}>

                <div className="d-flex padding_left_10 padding_right_10 btn_rounded" style={{ paddingTop: 0 }}>
                    <div className="" style={{ width: "60%",height:33,overflowY:"hidden" }}>
                        {hotel?.neighborhood ? JSON.parse(hotel?.neighborhood)?.name : ""}

                        {hotel?.distance && <span className="" style={{
                            font: "12px/14px Futura Lt BT",
                            color: "var(--mainGrey)",
                            marginTop: 20,
                            marginBottom: 1,
                            marginLeft: 2
                        }}>
                            ({hotel?.distance ? Number(hotel?.distance).toFixed(1) : ''} km from city center)
                        </span>}
                    </div>
                    {hotel?.guestScore && <div style={{ width: "40%" }}>
                        <div className="padding_left_10 padding_right_10" style={{ textAlign: "right", font: "12px/15px Futura Lt BT", color: "var(--mainBlack)" }} >Guest rating: <div style={{ font: "16px/15px Futura Md BT", display: "inline-block" }}>{hotel?.guestScore ? Number(hotel?.guestScore)?.toFixed(1) : ''}</div></div>
                    </div>}
                </div>


            </div>


            {hotel?.description !== undefined &&
                <div className={"padding_left_10 padding_right_10"} style={{
                    marginTop: 15
                }}>
                    <EstablishmentDescription description={hotel?.description}
                        boxDescription={"box_3"}
                        fontDescription={"14px/19px Futura Lt BT"}
                        colorDescription={"var(--mainGrey)"}
                        textLength={280}
                        height={'100px'}
                        bottom={"20px"}
                        maxHeight={'100px'} />
                </div>}

            <div>
                    <div className=" " style={{
                        font: "14px/14px Futura Lt BT",
                        color: "var(--mainBlack)",
                        padding:"10px 24px",
                        textAlign:"right"
                    }}>from {hotel?.display_currency}
                  <span style={{ font: "18px/14px Futura Md BT", }}> {hotel?.display_symbol}{Math.round(hotel?.cost)?.toFixed(2)}</span>
                </div>

                <div onClick={bookNow}
                                        style={{
                                            color: "#19BC9B",
                                            cursor: "pointer",
                                            textAlign:"center",
                                            marginTop: 5,
                                            padding:10,
                                            border: "1px solid #E8E8E8",
                                            font: "14px/15px Futura Md BT",
                                        }}>
                                        Book this stay
                                    </div>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
}

const TimelineTripHotel = connect(mapStateToProps, mapDispatchToProps)(TimelineTripHotel_)
export default TimelineTripHotel
