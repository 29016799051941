import React from 'react'
import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { Menu } from 'semantic-ui-react';

import { registerMemberAccount, setOpenAuthModal } from '../../../redux/actions';
import { PaymentMethodForm } from './PaymentMethodForm';
import { GuestInfo } from './GuestForm';
import { GuestContactForm } from './ContactForm';
import { LeftArrowIcon, RightArrowIcon } from '../Common/SVGBank';
import { PersonalizedModalUseStyles } from '../FeaturePage/personalizedTrip/personalizedTripStyles';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { validate, validateCardNumber, validate_fields } from '../Common/RegExValidate';
import { environment } from '../../../Environments/environment';
import { checkEmailisAvailable } from '../BookingPage/PaymentPage/Payment.services';
import { updateBookingInfo } from '../BookingPage/PaymentPage/payment.slice';
import { bookHotelThunk } from '../BookingPage/PaymentPage/payment.thunk';
import { createCheckout } from '../BookingPage/PaymentPage/cart.thunk';


export const HotelPaymentForm = ({ is_mobile,selectedType,setType }) => {
    const history = useHistory();
    const authModal = useSelector(state => state.Member.authModal);
    // useSelector(state => state.FormBank.BookingPriceline) ;
    const pricelineBooking = useSelector(state => state.FormBank.BookingPriceline);
    const airportTextField = useSelector(state => state.FormBank.homeTownAirportTextField);
    const passwordField = useSelector(state => state.FormBank.passwordTextField);
    const uuid = useSelector(state => state.Member.authModal.uuid);
    const { booking_info, booking_bundles } = pricelineBooking;
    const hotelBookingInfo = booking_bundles && booking_bundles.length > 0 ? booking_bundles[booking_bundles.length - 1] : null;

    const roomText = booking_info && booking_info.room_text ? booking_info.room_text : null;
    const rate = booking_info?.room_data?.rate_data && booking_info?.room_data?.rate_data?.length > 0 ? booking_info?.room_data?.rate_data[0] : null
    const postPaid = rate?.payment_type === "POSTPAID"

    const guests = booking_info && booking_info.num_rooms ? Number(booking_info.num_rooms) : 1;
    let [booking, setBooking] = React.useState(false);
    const cartData = useSelector(state => state.HotelBooking.cart.data)
    const classes = PersonalizedModalUseStyles();
    const FormTypes = ['Guests Info', 'Contact Registration', 'Payment Method'];
    const dispatch = useDispatch()
    let [guestList, setGuestList] = React.useState(Array(guests).fill({}));
    let [contactInfo, setContactInfo] = React.useState({});
    let [paymentInfo, setPaymentInfo] = React.useState({});
    let [promoCode, setPromoCode] = React.useState();
    let [specialRequest, setSpecialRequest] = React.useState();
    let [emailAvailable, setEmailAvailable] = React.useState(true);
    let [stripeUrl, setStripeUrl] = React.useState();

    const selectType = (value) => {
        if(value === 3){
            startCheckout()
        }
        else{
            setType(value)
        }
    }

    const signIn = () => {
        dispatch(setOpenAuthModal(true));
    }
   
    React.useEffect(()=>{
       if(uuid&& cartData){
        const getStripeUrl = async()=>{
            setBooking(true)
            const response = await dispatch(createCheckout({
                uuid:uuid,
                cart_uuid:cartData?.cart_uuid
            }))
        
            const stripeInfo = response?.payload?.url;
            setStripeUrl(stripeInfo)
            setBooking(false)
        }
        getStripeUrl()
       }

    },[uuid,cartData])


    const startCheckout = async()=>{

        if(stripeUrl){
            window.open(stripeUrl, '_blank').focus();
        }
    
    }


    const previousStep = () => {
        if (selectedType === 1) {
            history.goBack()
        }
        else {
            selectedType === 2 ? setType(1) : setType(2)
        }
    }

    const nextStep = async () => {
        if(selectedType === 3){
            setBooking(true)
            const paymentData = {
                guestList: guestList,
                contactInfo: contactInfo,
                paymentInfo: paymentInfo,
                bookingInfo: hotelBookingInfo,
                promoCode: promoCode,
                specialRequest: specialRequest,
                adults: guests
            }
            dispatch(updateBookingInfo(contactInfo)) //used for 
            if (paymentData?.bookingInfo?.ppn_book_bundle && !disableNext) {
                const response = await dispatch(bookHotelThunk(paymentData))
                if (response?.payload?.status === "Success") {
                    history.push("/hotel/confirmation")
                }

            }
            setBooking(false)


        }
        if (selectedType === 1) {
            setType(2)
        
        }
        if (selectedType === 2 ) {
            startCheckout()
            // if(!environment.production && !uuid){
            //     if(contactInfo?.email){
            //         const emailAvailable = await checkEmailisAvailable(contactInfo?.email)

            //         if(emailAvailable){
            //             handleRegister() ///register user
            //             setType(3)
            //         }
            //         setEmailAvailable(emailAvailable);
            //     }
          
            // }
            // else{
            //     setType(3)
            // }
     
        }
     
    }

    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;

    const disableNext = selectedType === 2 ? !(contactInfo?.name_first && contactInfo?.name_last && validate(contactInfo?.phone_number, validate_fields.phoneNumber)
        && validate(contactInfo?.email, validate_fields.email))
        : selectedType === 1 ? !(contactInfo?.name_first && contactInfo?.name_last) :
            !(contactInfo?.name_first && contactInfo?.name_last && validate(paymentInfo?.cvc_code, validate_fields.cvv)
                && validateCardNumber(paymentInfo?.card_number)
                && paymentInfo?.address_postal_code && paymentInfo?.country_code &&
             validate(contactInfo?.email, validate_fields.email) &&
                paymentInfo?.address_city && paymentInfo?.card_type 
                && paymentInfo?.card_holder &&
                paymentInfo?.address_line_one);


    const handleRegister = () => {
        dispatch(registerMemberAccount(contactInfo?.email, contactInfo?.name_first, contactInfo?.name_last, passwordField?.password, null, null, airportTextField?.airportSelected, null,true))

    }


    React.useEffect(()=>{
        const email = contactInfo?.email;
        let timer;
        if(email){
           
            const checkEmailExists = async()=>{
                const emailAvailable = await checkEmailisAvailable(email)
                setEmailAvailable(emailAvailable);

            }
            timer = setTimeout(() => {
                checkEmailExists()
            }, 400);
     
        
        }
      
        return () => {
            clearTimeout(timer);
          };
    
    },[contactInfo?.email])



    return (
        <div>

            <Backdrop className={classes.backdrop} open={booking} >
                <div style={{
                    font: "22px/18px Futura Lt BT",
                    color: "white",
                    paddingLeft: '0px',
                    marginBottom: '20px',
                    paddingTop: '20px',
                    display: 'inline-grid'
                }}>
                </div>
                <CircularProgress color="inherit" style={{ marginLeft: 40 }} />


            </Backdrop>

            <Menu pointing secondary style={{ marginTop: 20, paddingBottom: 35, borderBottom: "10px solid var(--lightGrayF)" }}>
                {FormTypes.map((type, index) => {
                    return (
                        <div key={index}
                            style={{
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                opacity: "1",
                                textAlign: "left",
                                height: is_mobile ? 'auto' : 45,
                                paddingBottom: is_mobile ? 10 : 0,
                                width: "100%",
                                paddingTop: "0px",
                                paddingLeft: index * 20,
                                paddingRight: "15px",
                                color: selectedType === index + 1 ? "#19BC9B" : '#B1B1B1',
                                borderBottom: selectedType === index + 1 ? '10px solid #19BC9B' : '10px solid var(--lightGrayF)',
                                marginBottom: -48,
                                cursor: "pointer",
                            }}
                            onClick={() => selectType(index + 1)}>
                            <p style={{ textAlign: 'left', font: mobile ? "14px/14px Futura Md BT" : "18px/18px Futura Md BT" }}> {index + 1}. {type}</p>
                        </div>
                    )
                })}
            </Menu>

            {selectedType === 1 &&
                <GuestInfo is_mobile={is_mobile} roomText={roomText} setSpecialRequest={setSpecialRequest} specialRequest={specialRequest}
                    setGuestList={setGuestList} guestList={guestList} promoCode={promoCode} setPromoCode={setPromoCode} contactInfo={contactInfo} setContactInfo={setContactInfo} />
            }
            {selectedType === 2 && <GuestContactForm is_mobile={is_mobile}
                emailAvailable={emailAvailable}
                loggedIn={uuid}
                contactInfo={contactInfo} setContactInfo={setContactInfo} guestList={guestList} />}
            {selectedType === 3 && <PaymentMethodForm is_mobile={is_mobile}
                paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />}


            {selectedType === 1 && <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>
                Note: please ensure the ID is vaild for at least 6 months after the date of departure
            </div>}

            {selectedType !== 1 && <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, borderLeft: '3px solid #19BC9B', background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>

                {selectedType === 2 ? <p style={{ lineHeight: 1.5 }}> We will send your booking info to this email address. You will also be able to retrieve it, find and book flights or generate an AI-personalized itinerary in the trips section of your member area. </p> :
                    <p style={{ lineHeight: 1.5 }}> SAFE AND SECURE BILLING  <br />{postPaid ? "Your card won't be charged, we only need your card details to guarantee your booking." : "Your credit card transaction is protect by an secure SSL encrypted transaction"}  </p>}
            </div>}




            <div>
                <div style={{ marginBottom: 20 }}>
                    <button className='recapButtons' style={{ background: mobile ? 'transparent' : '#DFDFDF', width: mobile ? "48%" : "200px" }} onClick={previousStep}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  {selectedType !== 1 ? 'Previous step' : 'Back to search results'}
                    </button>


                    <div style={{ float: "right" }}>
                        <button disabled={disableNext} className='recapButtons' style={{ opacity: disableNext ? 0.4 : 1, background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT', width: mobile ? "180px" : "200px" }} onClick={nextStep}>
                            {selectedType !== 3 ? 'Next step' : 'Book'} <RightArrowIcon height={14} width={14} style={{ marginLeft: mobile ? 40 : 90 }} />
                        </button>
                    </div>

                </div>

            </div>

        </div>
    )
}