import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { FlightRecapList } from './FlightRecapList';
import { FlightTotalComponent } from './FlightTotalComponent';
import { CongratulationsSavedFlight, PackageFooter } from './FlightSharedComponents';
import { addBundleToHotelBooking, batchAddBundleToHotelBooking, setOpenAuthModal } from '../../../../redux/actions';
import { FlightPaymentForm } from '../PaymentPage/PaymentForm';
import Loader from 'react-loader-spinner'

import { URL_REPLACE } from "../../Common/RegExValidate";
import { ALERT_BARS_HANDLE, SET_TIMELINE_WIZARD_STATUS } from '../../../../redux/interfaces/types';
import { HotelFlightSaveModal } from './FlightSaveModal';
import { FlightSearchComponent } from '../../FlightsPage/FlightsSearch';
import { FlightExpired } from '../../FlightsPage/flightExpired';
import { setExpiredFlight, updateDepartureFlight, updateFlightMode, updateReturnFlight } from '../../FlightsPage/services/Flights.slice';
import { getFlightContractThunk, getNearByDestinationCode, saveFlightTimeline } from '../../FlightsPage/services/Flights.thunks';
import { formatFlight } from '../../FlightsPage/services/flights.thunk.reducer';
import BookingItems from '../../BookingPage/BookingItems';
import BookingBasicInfo from '../../BookingPage/BookingBasicInfo'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const PackageRecapContainer_ = ({ lang, booking_info, booking_bundles, uuid, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus, activeTrip }) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [contractLoading, setLoading] = React.useState(false);
    const ppnBundle = params.get('bundleId');
    const hotelBundle = params.get('hotelBundle');
    const classes = useStyles();
    const history = useHistory();
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);
    const numberAdult = useSelector(state => state.Flights.flightData.numberAdult);
    const numberChild = useSelector(state => state.Flights.flightData.numberChild);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    let arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].arrival.airport.code : '';
    if (selectedFlightMode === 'round_trip') {
        arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight.slice_data[selectedFlight.slice_data.length - 1].departure.airport.code : '';
    }
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const selectedCurrencySybmol = useSelector(state => state.Setting.currency.selected.symbol);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const flightContract = useSelector(state => state.Flights.flightContract);
    const activeTripId = activeTrip?.tripId;
    let width;
    let marginLeft;



    React.useEffect(() => {

        if (arrivalCode) {
            dispatch(getNearByDestinationCode(arrivalCode));
        }
        if (ppnBundle) {
            setLoading(true)
            dispatch(getFlightContractThunk(ppnBundle))
        }
        if (hotelBundle) {
            const bundles = hotelBundle?.split(",");

            dispatch(batchAddBundleToHotelBooking({booking_bundles: bundles }))



        }


    }, [ppnBundle])


    ////update selected flight
    React.useEffect(() => {
        if (flightContract && flightContract?.itinerary_data) {
            let updateDeparture = JSON.parse(JSON.stringify(flightContract?.itinerary_data));
            const formatted = formatFlight(updateDeparture)

            if (formatted) {
                const mode = formatted?.search_type === "RoundTrip" ? "round_trip" : formatted?.search_type === "OneWay" ? "one_way" : "multi_destination"
                dispatch(updateFlightMode(mode))
                let departureFormat = JSON.parse(JSON.stringify(formatted));

                departureFormat.slice_data = departureFormat?.slice_data && departureFormat.slice_data?.length > 0 ? [departureFormat.slice_data[0]] : null;
                if (departureFormat?.slice_data) {
                    dispatch(updateDepartureFlight(departureFormat))
                }



                let returnFormat = JSON.parse(JSON.stringify(formatted));
                returnFormat.slice_data = returnFormat?.slice_data && returnFormat.slice_data?.length > 1 ? [returnFormat.slice_data[1]] : null;

                if (returnFormat?.slice_data) {
                    dispatch(updateReturnFlight(returnFormat))
                }


            }
            setLoading(false)
        }

        setTimeout(() => {
            setLoading(false)
        }, 3000);

    }, [flightContract])

    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '100%' : '67%';
        marginLeft = is_mobile ? '0%' : timelineWizardStatus ? '31%' : '15.5%';
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
    const dispatch = useDispatch()
    const loading = useSelector(state => state.Flights.loading);
    const [saveToTrip, setSaveToTrip] = React.useState(false);
    const [bookingFlight, setBookFlight] = React.useState(false);
    const flightListData = useSelector(state => state.Flights.flightListData);

    const [showSaveModal, setSaveModal] = React.useState(false);

    const authModal = useSelector(state => state.Member.authModal);
    const Flights = useSelector(state => state.Flights);
    const priceDetails = flightContract?.itinerary_data?.price_details;


    const flightTotal = priceDetails?.display_total_fare.toFixed(2);
    const hotelTotal = booking_bundles && booking_bundles?.length > 0 ? booking_bundles?.reduce((acc, val) => {
        return val?.rate_total ? acc + val?.rate_total : 0;
    }, 0) : 0
    const totalCart = flightTotal && hotelTotal ? (Number(flightTotal) + Number(hotelTotal))?.toFixed(2) : 0;


    const isLoggedIn = () => {
        return authModal.uuid && authModal?.uuid?.length > 0;
    }

    const goToDestination = () => {
        if (nearByDestination) {
            const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
            history.push(url.toLowerCase())
            ///window.open(url.toLowerCase());
        }
    }


    const toggleSaveModal = () => {
        setSaveModal(!showSaveModal)

        if (showSaveModal) {
            setBookFlight(false)
        }
    }


    const saveBooking = () => {
        if (isLoggedIn()) {
            if (activeTripId && timelineWizardStatus) { ///timeline open
                addToTimeline(activeTripId)
            }
            if (activeTripId && !timelineWizardStatus) {  //timeline closed
                toggleSaveModal()
            }
            else {
                addToTimeline(null)
            }

        } else {
            dispatch(setOpenAuthModal(true));
        }
    }


    React.useEffect(() => {
        if (!isLoggedIn()) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "warning",
                message: "Please login to save this trip"
            })
        }
    }, [authModal.uuid])


    const addToTimeline = async (tripId) => {
        const { selectedDepartureFlight, selectedReturnFlight, selectedFlightMode } = Flights;
        const selected = selectedFlightMode === 'round_trip' && selectedReturnFlight ? selectedReturnFlight : selectedDepartureFlight ? selectedDepartureFlight : null;

        const saveData = {
            postData: {
                tripId: tripId,
                booking_trip_number: "",
                booking_status: "",
                booking_status_code: "",
                airports: Flights?.savedFlight || [],
                displaySymbol: selected?.price_details?.display_symbol,
                displayCurrency: selected?.price_details?.display_currency,
                displayTotalFare: selected?.price_details?.display_total_fare,
                adults: numberAdult,
                children: numberChild
            },
            uuid: uuid || null
        }

        let responseContract;

        //only for booking
        if (bookingFlight) {
            responseContract = await dispatch(getFlightContractThunk(ppnBundle));

            if (responseContract) {
                if (responseContract?.meta?.requestStatus === "fulfilled") {
                    history.push('/flight/payment')
                    await dispatch(saveFlightTimeline(saveData));
                    // const destinationID = response?.payload?.result?.destinationId;
                    // const destinationName = response?.payload?.result?.destinationName;
                }
                else {
                    dispatch(setExpiredFlight(true));
                }
                setBookFlight(false);
            }
            else {
                dispatch(setExpiredFlight(true));
            }
        }
        else {
            ///only for saving
            const response = await dispatch(saveFlightTimeline(saveData));
            dispatch({
                type: SET_TIMELINE_WIZARD_STATUS,
                status: true
            })


            // const tripDestinations = response?.payload?.result?.tripDestinations;
            // const destName = tripDestinations && tripDestinations?.length > 0? tripDestinations[tripDestinations?.length-1]?.name:"";
            // const destId = tripDestinations && tripDestinations?.length > 0? tripDestinations[tripDestinations?.length-1]?.destId:"";
            // if (destName && destId) {
            //     setTimeout(() => {
            //      const url = "/destination/" + destName + "/" + 1 + "/" + destId;
            //      history.push(url)
            //     }, 1000);
            //  }
            setBookFlight(false);

            setTimeout(() => {
                goToDestination()
            }, 1000);

        }





        // navigateBooking(destinationName, destinationID)
        setBookFlight(false)
        return (responseContract)
    }

    //handle book clicked
    React.useEffect(() => {
        if (bookingFlight) {
            if (isLoggedIn()) {

                if (activeTripId && timelineWizardStatus) { ///timeline open
                    addToTimeline(activeTripId)
                }
                if (activeTripId && !timelineWizardStatus) { //timeline closed
                    toggleSaveModal()

                }
                else { ///no timeline
                    addToTimeline(null)
                }

            }
            else {
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "error",
                    message: "You must log in to save this"
                })
            }
        }
    }, [bookingFlight])

    const book = async () => {
        history.push('/package/payment')
        // setBookFlight(true);
    }


    const navigateBooking = (destinationName, destinationID) => {
        if (destinationName && destinationID) {
            const url = "/destination/" + destinationName + "/" + 1 + "/" + destinationID;
            history.push(url)
        }
    }

    const hotelBookingInfo = booking_bundles && booking_bundles.length > 0 ? booking_bundles : null;


    return (
        <div>
            {contractLoading && <Loader
                type="Rings"
                color="#19BC9B"
                className="se-pre-con"
                height={150}
                width={150}
                style={{
                    backgroundColor: 'white'
                }} />}

            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
                paddingBottom: 50
            }}>
                {/* <FlightSearchComponent bodyClientWidth={bodyClientWidth} /> */}
                <div id="back_to_top_flights"></div>

                {/* {(flightListData.length > 0) && <div style={{
                    font: "16px/16px Futura Md BT", color: "#E63155", textAlign: "center", padding: '20px 0px', margin: '20px 0px', border: '1px solid #DFDFDF'
                }}> Free cancellation within 24hrs of booking</div>} */}

                <Grid container className="flightRecap">

                    <Grid item md={8} sm={12} xs={12} className="flightRecapBody" >
                        {booking_info !== undefined && booking_info !== null &&
                            booking_info.name !== undefined && booking_info.name !== null && booking_info.name !== "" &&
                            booking_bundles !== undefined && booking_bundles !== null && booking_bundles !== [] &&
                            <div>
                                <div style={{ font: "22px/16px Futura Md BT", padding: 25, background: "#F5F7F6" }} >YOUR STAY</div>
                                <div style={{
                                    marginRight: -26,
                                    marginBottom: 20,
                                }}>

                                    {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                                        booking_bundles.map((bundle, bidx) => {
                                            if (bundle?.booking_info) {
                                                return (
                                                    <div style={{ marginBottom: 15 }} key={bidx} >
                                                        <BookingBasicInfo booking_bundles={booking_bundles} booking_info={bundle?.booking_info} hideButtons />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                </div>
                            </div>}
                        {flightContract && <div style={{ font: "22px/16px Futura Md BT", padding: 25, background: "#F5F7F6" }} >YOUR FLIGHT</div>}
                        {flightContract && <FlightRecapList />}
                    </Grid>

                    {/* spacing */}
                    <Grid item xs={false} sm={false} md={1} style={{ maxWidth: 40 }}>
                    </Grid>


                    <Grid item md sm={12} xs={12} >
                        {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                            <div>
                                <div style={{ font: "18px/16px Futura Md BT", padding: "15px 30px", background: "#F5F7F6" }} >STAY</div>
                                <div style={{
                                    width: "100%",
                                    margin: is_mobile ? "0 auto" : "0",
                                    display: is_mobile ? "block" : "inline-block",
                                }}>
                                     {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                                        booking_bundles.map((bundle, bidx) => {
                                            if (bundle) {
                                                return (
                                                    <div style={{ marginBottom: 15 }} key={bidx+"cart"}  >
                                                       <BookingItems booking_bundles={booking_bundles}
                                                       booking_bundle={bundle} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}

                                    
                                </div></div>}
                        {flightContract &&

                            <div>
                                <div style={{ font: "18px/16px Futura Md BT", padding: "15px 30px", background: "#F5F7F6" }} >FLIGHT</div>
                                <FlightTotalComponent bookFlight={book} /></div>}

                        {totalCart ? <div style={{ padding: "30px 30px", color: "#f8f8f8", background: "#2c2c2c", marginTop: -10 }}>
                            <p style={{ font: "14px/0px Futura Md BT", float: 'left', }}>   TOTAL TRIP COST </p>
                            <p style={{ font: "14px/0px Futura Md BT", float: 'right', }}>
                                {fullCurrency}{selectedCurrencySybmol} {totalCart}
                                {/* .<sup style={{ fontSize: '50%', top: '-0.7em' }}>{111}</sup> */}
                            </p>
                        </div> : ""}

                    </Grid>


                </Grid>


                {/* footers */}
                <Grid item md={8} sm={12} xs={12} className="flightRecapBody" >
                    <PackageFooter saveBooking={saveBooking} bookFlight={book}
                        showSave={false} />

                </Grid>


                <FlightExpired />
                <HotelFlightSaveModal navigateBooking={navigateBooking} showModal={showSaveModal} bookingFlight={bookingFlight} toggleModal={toggleSaveModal} addToTimeline={addToTimeline}
                    type={"flight"} />
                <Backdrop className={classes.backdrop} open={bookingFlight} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>



            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        uuid: state.Member.authModal.uuid,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
        booking_info: state.FormBank.BookingPriceline.booking_info,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const PackageRecapContainer = connect(mapStateToProps, mapDispatchToProps)(PackageRecapContainer_)
export default PackageRecapContainer
