import { FormControl, InputLabel, Select, TextField } from '@material-ui/core'
import React from 'react'
import { creditCardType } from '../../../../utility/utility'
import { validate_fields, validate, validateCardNumber, isValidPostalCode } from '../../Common/RegExValidate'

import { DurationUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles'

import { flightTextField } from '../../FlightsPage/flightStyles'
import { cardTypes, countriesList, monthList } from '../../FlightsPage/PaymentPage/DataBank'



export const PaymentMethodForm = ({ is_mobile, paymentInfo, setPaymentInfo }) => {


    const classesAutocomplete = flightTextField()
    let payment = { ...paymentInfo };

    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        payment[field] = value;
        setPaymentInfo(payment)
    }

    const [stateList, setStates] = React.useState();

    const durationClass = DurationUseStyles()

    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const yearList = range(currentYear, currentYear + 10, +1);

    const validCard = validateCardNumber(payment.card_number)
    const validPostal = isValidPostalCode(payment.address_postal_code, payment?.country_code);

    const detectCardType = (cardNumber) => {
        const value = creditCardType(cardNumber)
        if (value) {
            updateValue('card_type', value)
        }

    }
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (payment?.card_number) {
                detectCardType(payment?.card_number)
            }
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [payment?.card_number, 500]);

    return (
        <div >

            <div className="row">
                <div className='col-12' style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Cardholder Name'
                            required={true}
                            id='Cardholder  Name'
                            defaultValue={payment.card_holder}
                            onChange={(event) => updateValue('card_holder', event.target.value)}
                        />
                    </div>
                </div>

            </div>

            <div className="row">


                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Credit/Debit Card Number'
                            required={true}
                            id='Credit/Debit Card Number'
                            error={payment?.card_number?.length > 0 ? !validCard : false}
                            helperText={!validCard && payment?.card_number?.length > 0 ? "Please enter a valid credit/debit card number" : ""}
                            value={payment.card_number}
                            onChange={(event) => {
                                updateValue('card_number', event.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={is_mobile ? 'col-12' : 'col-3'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Credit/Debit Card Type">
                            Credit/Debit Card Type
                        </InputLabel>
                        <Select
                            native
                            required={true}
                            value={payment.card_type}
                            onChange={(event) => {
                                const value = event.target.value;
                                updateValue('card_type', value)
                            }
                            }
                            inputProps={{
                                name: 'Credit/Debit Card Type',
                                id: 'Credit/Debit Card Type',
                            }}
                        >
                            <option aria-label="None" value={null} />

                            {cardTypes.map((val, index) => {
                                return (<option key={val?.value} value={val?.value}>{val?.label}</option>)
                            })}

                        </Select>
                    </FormControl>


                </div>

                <div className={is_mobile ? 'col-12' : 'col-3'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Security Code'
                            required={true}
                            id='Security Code'
                            helperText={!validate(payment.cvc_code, validate_fields.cvv) && payment?.cvc_code?.length > 0 ? "Please enter a valid ccv number" : ""}
                            error={payment?.cvc_code?.length > 0 ? !validate(payment.cvc_code, validate_fields.cvv) : false}
                            value={payment.cvc_code}
                            onChange={(event) => updateValue('cvc_code', event.target.value)}
                        />
                    </div>
                </div>
            </div>



            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>
                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Expiration Month">
                            Expiration Month
                        </InputLabel>
                        <Select
                            native
                            required={true}
                            defaultValue={payment.cc_exp_mo}
                            onChange={(event) => {
                                const month = event.target.value ? Number(event.target.value) : null;
                                const padded = month < 10 ? "0" + String(month) : String(month);
                                updateValue('cc_exp_mo', padded)
                            }
                            }
                            inputProps={{
                                name: 'Expiration Month',
                                id: 'Expiration Month',
                            }}
                        >
                            <option aria-label="None" value="" />

                            {monthList.map((val, index) => {
                                return (<option key={index} value={index + 1}>{val}</option>)
                            })}

                        </Select>
                    </FormControl>

                </div>
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Expiration Year">
                            Expiration Year
                        </InputLabel>
                        <Select
                            native
                            required={true}
                            defaultValue={payment.cc_exp_yr}
                            onChange={(event) => updateValue('cc_exp_yr', event.target.value)}
                            inputProps={{
                                name: 'Expiration Year',
                                id: 'Expiration Year',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {yearList && yearList.map((val, index) => {
                                return (<option key={index} value={val}>{val}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>



            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Billing Address 1'
                            required={true}
                            id='Billing Address 1'
                            defaultValue={payment.address_line_one}
                            onChange={(event) => updateValue('address_line_one', event.target.value)}
                        />
                    </div>
                </div>
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Billing Address 2'
                            id='Billing Address 2'
                            defaultValue={payment.address_line_two}
                            onChange={(event) => updateValue('address_line_two', event.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <FormControl required className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="Country">
                            Country
                        </InputLabel>
                        <Select
                            native
                            required={true}
                            defaultValue={payment.country_code}
                            onChange={(event) => {
                                const value = event.target.value ?? "";
                                const country = JSON?.parse(value);
                                if (country) {
                                    updateValue('country_code', country.code);
                                    setStates(country.province_codes)
                                }
                            }
                            }
                            inputProps={{
                                name: 'Country',
                                id: 'Country',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {countriesList.map((val, index) => {
                                return (<option key={index} value={JSON.stringify(val)}>{val.name}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>
                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="state">
                            State/Province
                        </InputLabel>
                        <Select
                            native
                            defaultValue={payment.address_state_code}
                            onChange={(event) => updateValue('address_state_code', event.target.value)}
                            inputProps={{
                                name: 'state',
                                id: 'state',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {stateList && Object.keys(stateList).map((keyName, index) => {
                                return (<option key={index} value={stateList[keyName]}>{keyName}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>


            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='City'
                            required={true}
                            id='City'
                            value={payment.address_city}
                            onChange={(event) => updateValue('address_city', event.target.value)}
                        />
                    </div>
                </div>
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Postal Code'
                            required={true}
                            id='Postal Code'
                            error={payment?.address_postal_code?.length > 0 ? !validPostal : false}
                            helperText={!validPostal && payment?.address_postal_code?.length > 0 ? "Please enter a valid postal code" : ""}
                            value={payment.address_postal_code}
                            onChange={(event) => updateValue('address_postal_code', event.target.value?.toUpperCase())}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}