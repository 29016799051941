import { DirectionsRenderer, GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"
import React from 'react'
import mapStyles from "./mapStyles"

import { useDispatch, useSelector } from 'react-redux'
import { getSuggestedDestinationsMap } from "../Common/Trip/ActiveTrip.slice"
import DestinationMarkers from "../Common/destinationMarkers"
import { generateMapAPIKey } from "../../../utility/utility"


/* wrap map high order component */

export const SuggestedDestinationsMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate() {

        },
        componentDidMount() {

        }
    })
)((props) => {

    React.useEffect(() => {
        props.fetchDestinations();
        return () => {
        };
    }, []);

    const suggestedDestinationsMap = useSelector(state => state.ActiveTrip.suggestedDestinationsMap);

    const search = new URLSearchParams(window.location.search)
    const latitude = search.get('latitude')
    const longitude = search.get('longitude')
    const level = search.get('level')
    const dispatch = useDispatch();

    const [trackInfoArray, setTrackInfoArray] = React.useState([]);
    const [trackDestArray, setTrackDestArray] = React.useState([]);
    const [trackTimerArray, setTimerArray] = React.useState([]);


    const pushPlacesWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackInfoArray(tempArray);
    }

    const pushDestWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackDestArray(tempArray);
    }

    const pushTimer = (timer) => {
        const tempArray = [];
        tempArray.push(timer)
        setTimerArray(tempArray);
    }

    const clearDestWindow = () => {
        setTrackDestArray([]);
    }

    const clearTimers = () => {
        for (var i=0; i<trackTimerArray.length; i++) {
            clearTimeout(trackTimerArray[i]);
          }
        setTimerArray([]);
    }

    const checkDestOpen = (id) => {
        const showWindow = trackDestArray.find(element => element === id);
        return showWindow ? true : false;
    }


    const clearPlacesWindow = () => {
        setTrackInfoArray([]);
    }

    const checkWindowOpen = (id) => {
        const showWindow = trackInfoArray.find(element => element === id);
        return showWindow ? true : false;
    }

    const mapClick = () => {
        clearPlacesWindow()
        clearDestWindow()

        /// clear hover on map
        if( props.setHoverImage){
            props.setHoverImage(null)
        }
    
    }

    const handleDestinationCenterChanged = (map) => {
    }

    const handleDestinationZoomChanged = (map) => {
        // fetchEstablishments()
    }

    const defaultZoom = level ? (Number(level)) === 1 ? 4.5 : (Number(level)) ** (4.6 / Number(level)) : 5
    const defaultCenter ={ lat: latitude ? Number(latitude) : 49.000, lng: longitude ? Number(longitude) : 9.0000 }
    return (
        <div style={{
            width: "100%",
            position: "absolute",
            top: "0",
        }} className="content_container margin_bottom_20 inline-block" id="google_map_wrap">
            <GoogleMap
                defaultZoom={props?.zoom ||defaultZoom}
                defaultCenter={props?.center || defaultCenter}
                defaultOptions={{
                    controlSize: 20,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    maxZoom: 17,
                    minZoom: 4,
                    styles: mapStyles
                }}
                onCenterChanged={props.handleCenterChanged}
                onZoomChanged={handleDestinationZoomChanged}
                ref={props.refMap}
                onClick={mapClick}
                {...(props?.zoom ? {zoom: props?.zoom} : {})}
                {...(props?.center ? {center: props?.center} : {})}
            >


                {suggestedDestinationsMap && suggestedDestinationsMap?.length > 0 && suggestedDestinationsMap.map((destination, index_destination) => {
                    const lengthDestinations = suggestedDestinationsMap.length;
                    return (
                        <DestinationMarkers key={index_destination} lengthDestinations={lengthDestinations} destination={destination} color={{ fill: 'rgba(216, 82, 29, 1)', stroke: 'rgba(216, 82, 29, 0.4)' }}
                            index_destination={index_destination} mapProps={props} isRegularTrip={false} textColor={"rgba(25, 188, 155, 1)"}
                            pushDestWindow={pushDestWindow}
                            clearDestWindow={clearDestWindow}
                            hideHover={true}
                            doubleClick={props?.doubleClick}
                            hoverImage={props?.hoverImage}
                            setHoverImage={props?.setHoverImage}
                            checkDestOpen={checkDestOpen} 
                            clearTimers={clearTimers}
                            pushTimer={pushTimer}/>
                    )
                })}





            </GoogleMap>
        </div>)

}
)
