
import * as Actions from '../../../redux/actions'

import {
    TripNameTextField,
    TripStartDateTextField
} from '../Common/FormComponentsBank'
import { image_boxing, loadingImage } from "../Common/RegExValidate"
import { Image } from "react-bootstrap"
import PropTypes from "prop-types"
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import  moment  from 'moment';
import { encryptID } from '../HotelPage/hotelServices'

/* top bar component */
const PrintImageBar_ = ({ getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, setTripToTimeline, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const goesTripPage = (tripId, tripName) => {
                const encryptedId = encryptID(tripId)
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
        history.push(url.toLowerCase())
        getPublicTrip(tripId, lang)
    }

    /*  set selected trip */
    const tripMemberLinkHandler = (uuid, id) => {
        setTripToTimeline(id, uuid)
        getSelectedTripInformation(uuid, id)
    }
    const [imageFileInput, setImageFileInput] = React.useState(null)

    let topInnerImageHeight = ""
    let topImageHeight = ""
    if (!isNaN(screenSize)) {
        topInnerImageHeight = screenSize / 9 + 'px'
        topImageHeight = screenSize < 500 ? screenSize / 1.8 : screenSize / 6.5
    }
    const trip = from === "trip_private" ? trip_private : trip_public
    const uuid = from === "trip_private" ? uuidPrivate : uuidPublic
    let containerH = 500
    let imageH = 10
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
        imageH = topInnerImageHeight
    }

  
    const tripHeaderImage = from === "trip_private" ? activeTrip.tripImage : trip_public.tripHeaderImage
    const srcTripImage = tripHeaderImage !== undefined && tripHeaderImage !== null ? image_boxing(tripHeaderImage, screenSize, topImageHeight) : loadingImage.src
    const beginDays = activeTrip &&   activeTrip.tripDestinations.length > 0 && activeTrip.tripDestinations[0].spanningDays !== undefined && activeTrip.tripDestinations[0].spanningDays.split(',')
    const lengthBeginDays = beginDays.length
    const endDays = activeTrip &&   activeTrip.tripDestinations.length > 0 && activeTrip.tripDestinations[activeTrip.tripDestinations.length-1].spanningDays !== undefined && activeTrip.tripDestinations[activeTrip.tripDestinations.length-1].spanningDays.split(',')
    const lengthEndDays = endDays.length
    const lastDay = endDays[lengthEndDays-1];
    const startDate = activeTrip.tripStartDate ? moment(activeTrip.tripStartDate) : null;
    const beginDate = startDate && activeTrip.tripDestinations && lengthBeginDays > 1 ? moment(activeTrip.tripStartDate).format('MMM DD') :moment(activeTrip.tripStartDate).format('MMM DD, YYYY') 
   
    const endDate =  activeTrip.tripStartDate && activeTrip.tripDestinations && moment(activeTrip.tripStartDate).add(lastDay-1, 'day').format('MMM DD, YYYY') 
    return (
        <div className="position-relative z_index_1" style={{backgroundColor: "black"}}>
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <Image
                src={srcTripImage}
                alt="trip image" width={screenSize} height={topImageHeight} style={{opacity: '0.8'}} />}
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <div className="mask_block" style={{ height: containerH }}></div>}
           
           
                <React.Fragment>
                    <div style={{
                        top: "43%",
                        left: "8.5%",
                        position: "absolute",
                        zIndex: "4",
                    }}>
                        <div style={{
                            marginLeft: logo_width + "px",
                        }}>
                            {activeTrip !== undefined && activeTrip.tripName !== undefined
                                && <div style={{
                                    font: "45px/45px Futura Md BT",
                                    color: "#FFFFFF",
                                    width: "500px",
                                }}>
                                    {activeTrip.tripName}
                                    </div>}
                            {activeTrip !== undefined && activeTrip.tripStartDate !== undefined
                                && <div style={{
                                    font: "30px/56px Futura Lt BT",
                                    color: "#FFFFFF",
                                    width: "500px",
                                }}>
                                    {beginDate} {endDate && <span> - {endDate}</span>}
                                    </div>}
                            
                        </div>
                    </div>
                   
                </React.Fragment>
          

            
        </div>
    )
}

PrintImageBar_.propTypes = {
    trip_public: PropTypes.object.isRequired,
    trip_private: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,

        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const PrintImageBar = connect(mapStateToProps, mapDispatchToProps)(PrintImageBar_)
