import './TripSection.css';

import * as Actions from '../../../../redux/actions'

import { ArrowLeftIcon, ArrowRightIcon } from "../SVGBank"

import { Image } from "react-bootstrap"
import { PropTypes } from "prop-types"
import React from 'react'
import { ReformatTripRangeDate } from '../DateTimeFormatHandler'
import Slider from 'react-slick';
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { image_boxing } from "../RegExValidate"
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';

const SliderArrowRight = ({ className, to, onClick, color }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to} >
        <ArrowRightIcon className="icon" icon={to} fill={color} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)',
        }} />
    </button>
)
const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-66px)',

        }} />
    </button>
)
/* past trip component */
const TripSection_ = ({ from, pastTrips, suggestedTrips, generalInfo, lang, screenSize, isMobile,
    fatchPastTripsSlider, uuid, pastTripsLimit, pastTripsOffset, pastTripsIsFetch, timelineStatus, switchFeatureContainer }) => {
    const history = useHistory()
    const goesTripPage = (tripId, tripName) => {
        const encryptedId = encryptID(tripId)

        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
        history.push(url.toLowerCase())
    }
    const resource = (from === "social") ? pastTrips : (from === "establishment" ? suggestedTrips : [])
    const heightContainer = (from === "social") ? "280px" : (from === "establishment" ? (timelineStatus ? "250px" : "310px") : "auto")
    let picHeight = 240
    let fontSizeName = "18"
    if (!isNaN(isMobile)) {
        picHeight = isMobile ? 170 : ((from === "social") ? 212 : (timelineStatus ? 180 : 240))
        fontSizeName = isMobile ? "11" : (timelineStatus ? "14" : "18")
    }
    const display_element_length = screenSize > 1724 ? 5 :
        screenSize > 1500 ? 4 :
            screenSize > 1100 ? 3 :
                screenSize >= 1024 ? 2 :
                    screenSize >= 768 ? 3 :
                        screenSize > 500 ? 1 : 2
    const left_element = resource && resource.length < display_element_length ? "left_element" : ""
    const arrowsValue = resource && resource.length > display_element_length ? true : false

    const setting = {
        touchMove: false,
        dots: false,
        infinite: true,
        arrows: arrowsValue,
        speed: 500,
        lazyLoad: false,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        pauseOnHover: true,
        centerMode: false,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1724,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    touchMove: true,
                    slidesToShow: (from === "social") ? 2 : 4,
                    slidesToScroll: (from === "social") ? 1 : 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    touchMove: true,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false,

                }
            },
            {
                breakpoint: 500,
                settings: {
                    touchMove: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
        ]
    }

    return (
        <div
            style={{
                paddingLeft: from === "social" ? "0.7rem" : "",
            }}>
            {resource.length > 0 && <div className="past_trip_container padding_125rem_lr_mobile">
                {from === "social" && <div className="futura_md_bt_24_26_white p-2">Past Trips</div>}
                {from === "establishment" && <div style={{ font: "18px/18px Futura Hv BT", color: "var(--bgBlack)", padding: ".5rem" }}>{generalInfo !== undefined && generalInfo !== null && generalInfo.name + " Itineraries"}</div>}
            </div>}
            <Slider {...setting} className={left_element} style={{ height: heightContainer }}>
                {resource.length > 0 && resource.map((trip, trip_index) => {
                    let range_date = ""
                    if (trip.noDate !== undefined
                        && trip.noDate === "0"
                        && trip.tripStartDate !== undefined
                        && trip.tripDaysCount !== undefined
                        && trip.tripStartDate !== ""
                        && trip.tripStartDate !== "0000-00-00"
                        && trip.tripDaysCount !== "") {
                        range_date = ReformatTripRangeDate(trip.tripStartDate, trip.tripDaysCount)
                    }
                    return (
                        <div key={trip_index}>
                            {from === "social" &&
                                <div className="padding_left">
                                    <div className=" p-2">
                                        <div onClick={() => goesTripPage(trip.tripID, trip.tripName)} style={{ cursor: "pointer" }}>
                                            <Image className="min_width_100 grow img_fit_cover"
                                                src={trip.tripHeaderImage !== undefined ? image_boxing(trip.tripHeaderImage, picHeight, picHeight) : ""} />
                                        </div>

                                        <div className="box_3">
                                            <div className="margin_top_10 margin_bottom_10 d-inline-block" style={{ font: "18px/20px Futura Md BT", color: "var(--mainWhite)", cursor: "pointer" }} onClick={() => goesTripPage(trip.tripID, trip.tripName)}>{trip.tripName}</div>
                                            <div className="futura_md_bt_14_16_grey">{range_date}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {from === "establishment" &&
                                <div className="padding_left">
                                    <div className=" p-2">
                                        <div onClick={() => goesTripPage(trip.tripID, trip.tripName)} style={{ cursor: "pointer" }}>
                                            <Image className="min_width_100 grow img_fit_cover"
                                                src={trip.tripPicture !== undefined ? image_boxing(trip.tripPicture, picHeight, picHeight) : ""}
                                                alt="inflencer" />
                                        </div>

                                        <div className="box_3">
                                            <div className="margin_top_10 margin_bottom_10 d-inline-block" style={{ font: fontSizeName + "px/20px Futura Md BT", color: "var(--lightGray)", cursor: "pointer" }} onClick={() => goesTripPage(trip.tripID, trip.tripName)}>{trip.tripName}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </Slider>
            {from === "establishment" && <div style={{
                zIndex: "1",
                backgroundColor: "var(--mainWhite)",
            }} ><div style={{
                border: "1px solid #E8E8E8",
                opacity: "1",
            }} ></div>
                <div style={{
                    border: "3px solid #E8E8E829",
                    opacity: "1",
                }}></div></div>
            }
        </div >
    );

}

TripSection_.propTypes = {
    pastTrips: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => {
    return {
        pastTrips: state.Member.socialMember.pastTrips,
        pastTripsOffset: state.Member.socialMember.pastTripsOffset,
        pastTripsLimit: state.Member.socialMember.pastTripsLimit,
        pastTripsIsFetch: state.Member.socialMember.pastTripsIsFetch,
        uuid: state.Social.member_uuid,
        lang: state.Setting.lang,

        suggestedTrips: state.Destination.destination.suggestedTrips,
        generalInfo: state.Destination.destination.generalInfo,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        isMobile: state.Setting.is_mobile,

        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineStatus: state.FormBank.TimelineWizard.status,
    }
};
const mapDispatchToProps = {
    fatchPastTripsSlider: Actions.fatchPastTripsSlider,
};
const TripSection = connect(mapStateToProps, mapDispatchToProps)(TripSection_)
export default TripSection;