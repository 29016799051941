import BarsSubType from './BarsSubType'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Clips from './Clips'
import React from 'react'
import { connect } from 'react-redux'

const SubTypes_ = ({ subtypes,is_mobile }) => {
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])
    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div
                className="d-flex" style={{ flexGrow: "1",marginLeft:is_mobile?15:0, marginRight: "15px" }}>
                {subtypes !== undefined && subtypes.length > 0 && <BarsSubType open={open} handleClose={handleClose} handleToggle={handleToggle} anchorRef={anchorRef} />}
                {!is_mobile && subtypes !== undefined && subtypes.length > 0 && <Clips />}
            </div>
        </ClickAwayListener>
    )
}

const mapStateToProps = (state) => {
    return{
        subtypes: state.Feature.feature.subtypes,
        is_mobile: state.Setting.is_mobile,
    }
   
}

const mapDispatchToProps = {

}

const SubTypes = connect(mapStateToProps, mapDispatchToProps)(SubTypes_)
export default SubTypes
