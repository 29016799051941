import './TrendingCard.css'

import { RightArrowIcon, StarIcon } from '../../Common/SVGBank'

import { Image } from "react-bootstrap"
import React from 'react'
import { Star } from '../../Common/MemberActions/Star'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { features_router } from "../../Common/RegExValidate"
import { image_boxing } from "../../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices'

const TrendingCard_ = ({ trendingItem, is_mobile, goesFeatureTripPage }) => {
  const history = useHistory()

  const tagTitle = trendingItem && trendingItem.tag ?  trendingItem.tag.refTitle : null;
  const tagCode = trendingItem && trendingItem.tag ?  trendingItem.tag.tagCode: null;
  const refId = trendingItem && trendingItem.tag ?  trendingItem.tag.refId: null;
  
  const goesTripPage = (tripId, tripName) => {
    const encryptedId = encryptID(tripId)
    const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
    history.push(url.toLowerCase())
  }


  let font_see_more = ""
  if (!isNaN(is_mobile)) {
    font_see_more = is_mobile ? "11px/30px Futura Md BT" : "12px/30px Futura Md BT"
  }


  const goesTrendingPage = () => {
    if(tagTitle &&tagCode && refId ){
      const path =  `/trending-${tagTitle.replace(URL_REPLACE, "-")}/${features_router.trips.featureId}&${tagCode}=${refId}`;
      history.push(path.toLowerCase())
    }
    
  }
  return (
    <div className="card">
      <div onClick={() => goesTripPage(trendingItem.tripID, trendingItem.tripName)} style={{ cursor: "pointer" }}>
        <Image
          className="card-img-top"
          src={image_boxing(trendingItem.tripImage, 377, 220)}
          alt="trending trip" />
      </div>
      <div style={{
        top: "0",
        left: "0",
        right: "0",
        height: "10%",
        position: "absolute",
        alignItems: "center",
      }}>
        <div style={{ height: "100%", textAlign: "right", padding: "10px", }}>
          <Star
            unitID={trendingItem.tripID}
            feature={features_router.trips}
            fill={"var(--mainWhite)"} />
        </div>
      </div>
      <div className="col-md-12 card-body ">
        {!is_mobile && <div className="d-flex">
          <div style={{ color: "#202020", font: "14px/18px Futura Md BT" }}>{trendingItem.tripDestName}</div>
          <div className="rating_home flex_grow_1 text-right">4.8  <StarIcon fill="#19BC9B" width="15" height="15" style={{ marginLeft: "10px" }} /></div>
        </div>}

        <div className="card-tittle" style={{ height: is_mobile ? "45px" : "110px" }}>
          <p className="box_2" style={{ font: is_mobile ? "10px/14px Futura Hv BT" : "20px/28px Futura Hv BT", margin: "0 0 6px", cursor: "pointer",color: "#202020" }} onClick={() => goesTripPage(trendingItem.tripID, trendingItem.tripName)}>{trendingItem.tripName}</p>
          <p className="card-text box-3" style={{ font: is_mobile ? "6px/8px Futura Lt BT" : "16px/22px Futura Lt BT", margin: "0 0 6px",color: "#202020" }}>{trendingItem.tripDescription}</p>
        </div>

        {!is_mobile && <div onClick={() => {
          goesTrendingPage()
        }
      } style={{ color: "#19BC9B", font: font_see_more, textAlign: "right", cursor: "pointer" }}>See more {tagTitle} trips <RightArrowIcon fill={'#19BC9B'} width="12px" /> </div>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
  lang: state.Setting.lang,
  homepage: state.Home.homepage,
  is_mobile: state.Setting.is_mobile,
})

const mapDispatchToProps = {

}

const TrendingCard = connect(mapStateToProps, mapDispatchToProps)(TrendingCard_)
export default TrendingCard