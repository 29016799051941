import * as Actions from '../../../redux/actions';

import React, { Component } from 'react'

import { DeleteClipIcon } from '../Common/SVGBank'
import { connect } from 'react-redux'

const ClipFilterType = connect((state) => ({
    selectedSubtypes: state.FormBank.HotelSearch.selectedSubtypes,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
}), {
    deleteSelectedSubTypeHotels: Actions.deleteSelectedSubTypeHotels
})(({
    element, 
    switchFeatureContainer, 
    deleteSelectedSubTypeHotels, 
    selectedSubtypes,
    establishmentsToFilter,
    lazyLoading,
    hotel_form_state,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
        return (
            <div
                style={{
                    color: switchFeatureContainer.color,
                    border: "none",
                    cursor: "default",
                    height: "32px",
                    display: "inline-flex",
                    outline: "0",
                    padding: "0",
                    fontSize: "0.8125rem",
                    boxSizing: "border-box",
                    transition: "background - color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box - shadow 300ms cubic - bezier(0.4, 0, 0.2, 1) 0ms",
                    alignItems: "center",
                    font: "14px/18px Futura Md BT",
                    whiteSpace: "nowrap",
                    borderRadius: "16px",
                    verticalAlign: "middle",
                    justifyContent: "center",
                    textDecoration: "none",
                    backgroundColor: switchFeatureContainerColorLight,
                    margin: "3px",
                    maxWidth: "calc(100% - 6px)",
                }}>
                <span style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    textOverflow: "ellipsis",
                }}>{element?.title}</span>
                <div onClick={() => deleteSelectedSubTypeHotels(element, selectedSubtypes, establishmentsToFilter, hotel_form_state, lazyLoading)}><DeleteClipIcon fill={switchFeatureContainer.color} /></div>
            </div >
        )
})

export default ClipFilterType