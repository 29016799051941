import "./areYouMuver.css"

import { ArrowLeftIcon, ArrowRightIcon, RightArrowIcon } from "../../Common/SVGBank"

import React from 'react'
import Slider from 'react-slick'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import { TrackEvent } from "../../../../utility/utility"

const SliderArrowLeft = ({ className, to, onClick, is_mobile }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowLeftIcon className="icon" icon={to} style={{
      height: is_mobile ? "45px" : "60px",
      width: is_mobile ? "45px" : "60px",
      transform: is_mobile ? 'translate(30px,-75px)' : 'translateY(-40px)',

    }} />
  </button>
)
const SliderArrowRight = ({ className, to, onClick, is_mobile }) => (
  <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
    <ArrowRightIcon className="icon" icon={to} style={{
      height: is_mobile ? "45px" : "60px",
      width: is_mobile ? "45px" : "60px",
      transform: is_mobile ? 'translate(-20px,-75px)' : 'translateY(-40px)'
    }} />
  </button>
)
const AreYouMuver_ = ({ homepageMuverStyle, lang, is_mobile }) => {

  const history = useHistory()

  const goesLandingPage = () => {
    TrackEvent('HOME', "Click become a muver", "landing")
    history.push("/landing")
  }


  let font_title = "50px/50px Futura Hv BT"
  if (!isNaN(is_mobile)) {
    font_title = is_mobile ? "20px/25px Futura Hv BT" : "40px/50px Futura Hv BT"
  }
  const areYouMuver = homepageMuverStyle.result
  let heightContainer = "240"
  const display_element_length = 1
  const left_element = areYouMuver && areYouMuver.length < display_element_length ? "left_element" : ""
  const arrowsValue = areYouMuver && areYouMuver.length > display_element_length ? true : false
  const setting = {
    touchMove: false,
    dots: false,
    infinite: false,
    arrows: arrowsValue,
    speed: 500,
    lazyLoad: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    centerMode: false,
    prevArrow: <SliderArrowLeft to="prev" is_mobile={is_mobile} />,
    nextArrow: <SliderArrowRight is_mobile={is_mobile} />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          touchMove: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,

        }
      }
    ]
  }
  return (
    <div className="areYouMuver">

      <div className="muvers-heading">Are you a müver?</div>
      <Slider {...setting} className={left_element} style={{ height: heightContainer }}>
        {areYouMuver !== undefined && areYouMuver.length > 0 && areYouMuver.map((item, i) => <div className="item text-center" key={i}>
          <div style={{ font: font_title, paddingLeft: "60px", paddingRight: "60px", }}> {item}</div>
        </div>)
        }
      </Slider>
      <div className="inspired-link " style={{
        font: '16px/30px Futura Lt BT', width: 220, textAlign: 'left',
        margin: "auto 0 auto auto"
      }}> Inspire and get inspired
      <div className="dicover-landscape"
          onClick={goesLandingPage}
          style={{
            padding: 15, marginTop: 5, cursor: "pointer", font: "16px/20px Futura Md BT", color: "var(--mainGreen)",
            border: '2px solid var(--mainGreen)',
          }}>
          Join the müvment <RightArrowIcon fill="var(--mainGreen)" style={{ marginLeft: "15px " }} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
  lang: state.Setting.lang,
  homepage: state.Home.homepage,
  is_mobile: state.Setting.is_mobile,

  homepageMuverStyle: state.Home.homepage_muver_style,
})

const mapDispatchToProps = {

}

const AreYouMuver = connect(mapStateToProps, mapDispatchToProps)(AreYouMuver_)
export default AreYouMuver