import * as Actions from '../../../../redux/actions'

import { CalendarAltIcon, ListIcon, MapDuotoneIcon, RoadTripIcon, TimelineIcon } from "../../Common/SVGBank"
import { DownArrowIcon, UpArrowIcon } from "../../Common/SVGBank"
import {
    LeavingFromAirportTextField,
    ReturningToAirportTextField,
} from "../../Common/FormComponentsBank"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fab from '@material-ui/core/Fab'
import { Menu } from 'semantic-ui-react'
import MenuMember from "../../Common/MenuMember.jsx"
import { OtherTrips } from "../../TripPage/OtherTrips.jsx"
import React from 'react'
import ScrollToTop from "../../Common/ScrollToTop"
import TripAuthCheckBox from '../../Common/Trip/TripAuthCheckBox'
import TripCalendar from '../../Common/Trip/TripCalendar'
import TripCompanion from '../../Common/Trip/TripCompanion'
import PublicTripLink from '../../Common/Trip/PublicTripLink'
import {
    TripDescriptionTextField,
} from "../../Common/FormComponentsBank"
import TripEllipsis from '../../Common/Trip/TripEllipsis.jsx'
import TripEllipsisTimeline from '../../Common/Trip/TripEllipsisTimeline.jsx'
import TripSchedule from '../../Common/Trip/TripSchedule'
import TripType from '../../Common/Trip/TripType'
import {
    airportAutocompleteUseStyles,
} from "../../Common/ComponentsStylingBank"
import { connect, useDispatch } from 'react-redux'
import { check_box_trip_status, list_container_mode, URL_REPLACE } from '../../Common/RegExValidate'
import { member_area_router } from "../../Common/RegExValidate"
import {
    tripDescriptionMultiLineTextFieldUseStyles,
} from "../../Common/ComponentsStylingBank"
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { setTripToTimeline } from '../../../../redux/actions'

/* member trip sun container component */
const MemberTripSubContainer_ = ({ setTimeLineStatus,uuidPrivate, activeTrip, activities, tripId, tripAuthStatus, screenSize, trip, tripMode, app_menu_height, is_mobile, logo_width, logo_height, setTripDisplayMode, tripNoDate, switch_container }) => {
    const classesMultiLineTextField = tripDescriptionMultiLineTextFieldUseStyles();
    const [open, setOpen] = React.useState(false)
    const classesAutocomplete = airportAutocompleteUseStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const handleClose = event => {
        setOpen(false);
        document.getElementById("trip_description_bar").scrollIntoView({ behavior: 'smooth', block: 'start' })
    };

    const switchDisplayMode = (mode) => {
        const url = `/muver/trip/${mode}/${activeTrip.tripId}`;
        history.push(url.toLowerCase())
    }

    const goToTimeline = () => {

        const destination = activeTrip && activeTrip?.tripDestinations && activeTrip?.tripDestinations.length > 0 ? activeTrip.tripDestinations[0] : null;
        if (destination && activeTrip) {
            const tripName = activeTrip?.tripName ? activeTrip?.tripName.replace(URL_REPLACE, "-") : "";
            const url = "/destination/" + (destination?.partialUri ? destination.partialUri : tripName) + "/" + 1 + "/" + destination.destId+ `/${activeTrip.tripId? activeTrip.tripId:""}` ;

            history.push(url.toLowerCase())
            dispatch(setTripToTimeline(activeTrip.tripId,uuidPrivate))

            setTimeout(() => {
                setTimeLineStatus(true)
            }, 2000);
        }
    }

    const goToTripPage = ()=>{
        const url = "/muver/trippage/" +tripId ;

        history.push(url.toLowerCase())
    }

    let position = "sticky"
    let logoH = logo_width
    let w1 = 10
    let w2 = 60
    let w3 = 70
    let border_size = 8
    let margin_right = 0
    let margin_left = 0
    let margin_top = 30
    if (!isNaN(screenSize)) {
        margin_left = logoH
        margin_right = logoH
        if (screenSize <= 1024) {
            w1 = 10
            w2 = 60
            w3 = 70
            margin_top = 15
        }
        if (screenSize < 500) {
            w1 = 25
            w2 = 0

            border_size = 4
            margin_right = 5
            margin_left = 5
            margin_top = 0
        }
    }

    const isRoadTrip = trip !== null && trip.tripTravelCategory?.code === "road";

    //console.log (`MemberTripSubContainer switch_container : ${switch_container}`)
    //console.log (`MemberTripSubContainer tripMode : ${tripMode}`)
    //console.log (`MemberTripSubContainer open : ${open}`)

    return (
        <React.Fragment>
            {isRoadTrip && <div style={{
                font: "14px/16px Futura Md BT", color: '#19BC9B', textAlign: "center", background: 'white', padding: 5, width: 136,
                border: '0.5px solid #19BC9B',
            }}>
                ROAD TRIP
            </div>}
            {switch_container === member_area_router.memberAreaTrip ?
                <div id={"trip_description_bar"} style={{
                    backgroundColor: "#191919",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    marginRight: tripMode === list_container_mode.schedule ? margin_right : "0px"
                }} >


                    {!open ? <div style={{ position: "relative" }} >
                        <div style={{
                            backgroundColor: "#191919",
                            position: "absolute",
                            top: "-175px",
                            width: tripMode === list_container_mode.schedule ? "calc(100% - " + Math.floor(logo_width + 40) + "px)" : "100%",
                            left: tripMode === list_container_mode.schedule ? Math.floor(logo_width + 40) + "px" : "0px",
                            zIndex: "32", textAlign: "center",
                        }}>
                            <div style={{
                                padding: "30px 40px 10px",
                                display: "flex",
                            }}>
                                <div style={{ font: "23px/24px Futura Md BT", color: "#FFFFFF", flexGrow: "1", textAlign: "left" }}>Trip Description</div>
                                <TripAuthCheckBox text={"Keep this trip private"} />
                            </div>
                            <div className={classesMultiLineTextField.root} style={{
                                padding: "0px 250px 50px 40px",
                                height: "65px",
                            }}>
                                <TripDescriptionTextField label=""
                                    required={false}
                                    id="trip_description" lineNum={"2"} />
                            </div>
                            <div style={{ display: "inline-block" }} onClick={() => setOpen(true)}>
                                <DownArrowIcon fill={"#19BC9B"} style={{}} />
                                <div style={{ font: "15px/28px Futura Md BT", color: "#19BC9B" }}>Show details</div>
                            </div>

                        </div>
                        <div style={{ position: "absolute", top: "-165px", right: "-10px" }} ><TripEllipsis trip={trip} /></div>
                    </div>
                        :
                        <ClickAwayListener onClickAway={handleClose}>
                            <div style={{ position: "relative" }} >
                                <div style={{
                                    backgroundColor: "#191919",
                                    position: "absolute",
                                    top: "-175px",
                                    width: tripMode === list_container_mode.schedule ? "calc(100% - " + Math.floor(logo_width + 40) + "px)" : "100%",
                                    left: tripMode === list_container_mode.schedule ? Math.floor(logo_width + 40) + "px" : "0px",
                                    zIndex: "32", textAlign: "center",
                                }}>
                                    <div style={{
                                        padding: "30px 40px 10px",
                                        display: "flex",
                                    }}>
                                        <div style={{ font: "23px/24px Futura Md BT", color: "#FFFFFF", flexGrow: "1", textAlign: "left" }}>Trip Description</div>
                                        <TripAuthCheckBox text={"Keep this trip private"} />
                                    </div>
                                    <div className={classesMultiLineTextField.root} style={{
                                        padding: "0px 250px 100px 40px",
                                        height: "85px",
                                    }}>
                                        <TripDescriptionTextField label=""
                                            required={false}
                                            id="trip_description" lineNum={"3"} />
                                    </div>
                                    <div style={{
                                        display: "flex", padding: "0px 250px 0px 40px",
                                    }}>
                                        <div style={{ font: "18px/25px Futura Md BT", color: "#FFFFFF", width: "50%", textAlign: "left" }}>Leaving from</div>
                                        <div style={{ font: "18px/25px Futura Md BT", color: "#FFFFFF", width: "50%", textAlign: "left", paddingLeft: "5px" }}>Returning to</div>
                                    </div>

                                    <div style={{ display: "flex", padding: "0px 250px 20px 30px", }}>
                                        <div className={classesAutocomplete.root}>
                                            <LeavingFromAirportTextField
                                                label=""
                                                required={false}
                                                id="leving_from_airport"
                                            />
                                        </div>
                                        <div className={classesAutocomplete.root}>
                                            < ReturningToAirportTextField
                                                label=""
                                                required={false}
                                                id="returning_to_airport"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ padding: "20px 250px 10px 40px", }}>
                                        <TripType />
                                    </div>
                                    <div style={{ padding: "20px 250px 10px 40px", }}>
                                        <TripCompanion />
                                    </div>
                                    <div style={{ display: "inline-block" }} onClick={() => handleClose()}>
                                        <UpArrowIcon fill={"#19BC9B"} style={{}} />
                                        <div style={{ font: "15px/28px Futura Md BT", color: "#19BC9B" }}>Hide details</div>
                                    </div>

                                </div>
                                <div style={{ position: "absolute", top: "-165px", right: "-10px" }} ><TripEllipsis trip={trip} /></div>
                            </div>
                        </ClickAwayListener>}


                    <div style={{
                        position: position,
                        zIndex: "31",
                        marginLeft: tripMode === list_container_mode.schedule ? logo_width + "px" : "0px",
                        top: is_mobile ? 0 : (-Math.ceil(-app_menu_height / 2))
                    }}>
                        <div style={{ backgroundColor: "#2C2C2C", padding: "30px 30px 0px", marginBottom: -20, height: 100 }}>
                            <div style={{

                                zIndex: "32",
                            }}><MenuMember />

                                {activities.length > 0 && tripAuthStatus === check_box_trip_status.public && <div style={{ marginTop: -50, textAlign: 'end', zIndex: "5" }} >
                                    <PublicTripLink
                                        tripName={"Preview trip public page"}
                                        tripId={tripId}
                                        titleClass={"box_1"} titleStyle={{
                                            font: "18px/25px Futura Hv BT", color: "#19BC9B", cursor: "pointer",
                                        }} />
                                </div>}
                            </div>
                        </div>
                        <Menu pointing secondary style={{ backgroundColor: "#2C2C2C", marginBottom: "0", borderBottomWidth: "0" }}>
                            <div style={{ width: tripNoDate === "0" ? w2 + "%" : w3 + "%", borderBottom: border_size + "px solid var(--mainWhite)" }} ></div>
                            <div className="menu_link_title_text_member_trip" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.itinerary ? border_size + "px solid var(--mainGreen)" : border_size + "px solid var(--mainWhite)" }} 
                            onClick={goToTripPage}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: 'white',
                                    border: "2px solid #19BC9B"
                                }}>
                                    <FontAwesomeIcon icon={faRoute} fill={"#19BC9B"}
                                        width={20} height={23} style={{ color:"#19BC9B", height:23}} />
                                </Fab><p>{list_container_mode.itinerary}</p></div>

                            <div className="menu_link_title_text_member_trip" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.schedule ? border_size + "px solid var(--mainGreen)" : border_size + "px solid var(--mainWhite)" }} onClick={() => switchDisplayMode(list_container_mode.schedule)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#274674",
                                }}>
                                    <ListIcon width={20} height={20} fill={"var(--mainWhite)"} />
                                </Fab><p>{list_container_mode.schedule}</p></div>
                            {tripNoDate === "0" && <div className="menu_link_title_text_member_trip" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.calendar ? border_size + "px solid var(--mainGreen)" : border_size + "px solid var(--mainWhite)" }} onClick={() => switchDisplayMode(list_container_mode.calendar)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#2C2C2C",
                                    border: "2px var(--mainGreen) solid"
                                }}>
                                    <CalendarAltIcon width={20} height={23} fill={"var(--mainGreen)"} />
                                </Fab><p>{list_container_mode.calendar}</p></div>}
                            <div className="menu_link_title_text_member_trip" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.map ? border_size + "px solid var(--mainGreen)" : border_size + "px solid var(--mainWhite)" }} onClick={() => switchDisplayMode(list_container_mode.map)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#FFFFFF29",
                                }}>
                                    <MapDuotoneIcon width={25} height={25} fill={"#0D3A7D"} />
                                </Fab><p>{list_container_mode.map}</p></div>

                            <div className="menu_link_title_text_member_trip" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.map ? border_size + "px solid var(--mainGreen)" : border_size + "px solid var(--mainWhite)" }} onClick={() => goToTimeline()}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#2C2C2C00",
                                    border: "2px #274674 solid"
                                }}>
                                    <TimelineIcon width={20} height={20} />
                                </Fab><p>{list_container_mode.timeline}</p></div>
                        </Menu>

                    </div>
                    {tripMode === list_container_mode.schedule &&
                        <div style={{ backgroundColor: "#2C2C2C" }}>
                            <TripSchedule />
                        </div>
                    }
                    {tripMode === list_container_mode.calendar &&
                        <div style={{ backgroundColor: "#2C2C2C" }}>
                            <TripCalendar />
                        </div>
                    }
                    {
                        trip.otherTrips !== undefined && trip.otherTrips.length > 0 &&
                        <div style={{ backgroundColor: "#2C2C2C", zIndex: "30", position: "relative", }}>
                            {tripMode === list_container_mode.calendar && <div style={{ height: "30px" }}></div>}
                            <hr className="line_separator_frame_black m-0" />
                            <div className="futura_hv_bt_30_32 pl-2 margin_left_20 margin_top_20 margin_bottom_20 text_main_white">Other trips</div>
                            <OtherTrips from="trip_private" textColor={"text_main_white"} />
                        </div>
                    }

                    <ScrollToTop />
                </div >
                :
                <div id={"trip_description_bar"} style={{
                    backgroundColor: "#C7C7C7",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    marginRight: tripMode === list_container_mode.schedule ? margin_right : "0px"
                }} >

                    {!open ? <div style={{ position: "relative" }} >
                        <div style={{
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #C7C7C7",
                            position: "absolute",
                            top: "-175px",
                            width: tripMode === list_container_mode.schedule ? "calc(100% - " + Math.floor(logo_width + 40) + "px)" : "100%",
                            left: tripMode === list_container_mode.schedule ? Math.floor(logo_width + 40) + "px" : "0px",
                            zIndex: "32", textAlign: "center",
                        }}>
                            <div style={{
                                padding: "30px 40px 10px",
                                display: "flex",
                            }}>
                                <div style={{ font: "23px/24px Futura Md BT", color: "#191919", flexGrow: "1", textAlign: "left" }}>Trip Description</div>
                                <TripAuthCheckBox text={"Keep this trip private"} />
                            </div>
                            <div className={classesMultiLineTextField.root} style={{
                                padding: "0px 250px 50px 40px",
                                height: "65px",
                            }}>
                                <TripDescriptionTextField label=""
                                    required={false}
                                    id="trip_description" lineNum={"2"} />
                            </div>
                            <div style={{ display: "inline-block" }} onClick={() => setOpen(true)}>
                                <DownArrowIcon fill={"#19BC9B"} style={{}} />
                                <div style={{ font: "15px/28px Futura Md BT", color: "#19BC9B" }}>Show details</div>
                            </div>

                        </div>
                        <div style={{ position: "absolute", top: "-165px", right: "15px", zIndex: "32" }} ><TripEllipsisTimeline /></div>
                    </div>
                        :
                        <ClickAwayListener onClickAway={handleClose}>
                            <div style={{ position: "relative" }} >
                                <div style={{
                                    backgroundColor: "#FFFFFF",
                                    border: "1px solid #C7C7C7",
                                    position: "absolute",
                                    top: "-175px",
                                    width: tripMode === list_container_mode.schedule ? "calc(100% - " + Math.floor(logo_width + 40) + "px)" : "100%",
                                    left: tripMode === list_container_mode.schedule ? Math.floor(logo_width + 40) + "px" : "0px",
                                    zIndex: "32", textAlign: "center",
                                }}>
                                    <div style={{
                                        padding: "30px 40px 10px",
                                        display: "flex",
                                    }}>
                                        <div style={{ font: "23px/24px Futura Md BT", color: "#191919", flexGrow: "1", textAlign: "left" }}>Trip Description</div>
                                        <TripAuthCheckBox text={"Keep this trip private"} />
                                    </div>
                                    <div className={classesMultiLineTextField.root} style={{
                                        padding: "0px 250px 100px 40px",
                                        height: "85px",
                                    }}>
                                        <TripDescriptionTextField label=""
                                            required={false}
                                            id="trip_description" lineNum={"3"} />
                                    </div>
                                    <div style={{
                                        display: "flex", padding: "0px 250px 0px 40px",
                                    }}>
                                        <div style={{ font: "18px/25px Futura Md BT", color: "#191919", width: "50%", textAlign: "left" }}>Leaving from</div>
                                        <div style={{ font: "18px/25px Futura Md BT", color: "#191919", width: "50%", textAlign: "left", paddingLeft: "5px" }}>Returning to</div>
                                    </div>

                                    <div style={{ display: "flex", padding: "0px 250px 20px 30px", }}>
                                        <div className={classesAutocomplete.root}>
                                            <LeavingFromAirportTextField
                                                label=""
                                                required={false}
                                                id="leving_from_airport"
                                            />
                                        </div>
                                        <div className={classesAutocomplete.root}>
                                            < ReturningToAirportTextField
                                                label=""
                                                required={false}
                                                id="returning_to_airport"
                                            />
                                        </div>
                                    </div>
                                    <div style={{ padding: "20px 250px 10px 40px", }}>
                                        <TripType />
                                    </div>
                                    <div style={{ padding: "20px 250px 10px 40px", }}>
                                        <TripCompanion />
                                    </div>
                                    <div style={{ display: "inline-block" }} onClick={() => handleClose()}>
                                        <UpArrowIcon fill={"#19BC9B"} style={{}} />
                                        <div style={{ font: "15px/28px Futura Md BT", color: "#19BC9B" }}>Hide details</div>
                                    </div>

                                </div>
                                <div style={{ position: "absolute", top: "-165px", right: "15px", zIndex: "32" }} ><TripEllipsisTimeline /></div>
                            </div>
                        </ClickAwayListener>}
                    <div style={{
                        position: position,
                        zIndex: "31",
                        marginLeft: tripMode === list_container_mode.schedule ? logo_width + "px" : "0px",
                        top: is_mobile ? logo_height : (-Math.ceil(-app_menu_height / 2))
                    }}>
                        <Menu pointing secondary style={{ backgroundColor: "#FFFFFF", marginBottom: "0", borderBottomWidth: "0" }}>
                            <div style={{ width: tripNoDate === "0" ? w2 + "%" : w3 + "%", borderBottom: border_size + "px solid #C7C7C7 " }} ></div>
                            <div className="menu_link_title_text_member_trip_black" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.schedule ? border_size + "px solid var(--mainGreen)" : border_size + "px solid #C7C7C7 " }} onClick={() => switchDisplayMode(list_container_mode.schedule)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#0d3a7d",
                                }}>
                                    <ListIcon width={20} height={20} fill={"#FFFFFF"} />
                                </Fab><p>{list_container_mode.schedule}</p></div>
                            {tripNoDate === "0" && <div className="menu_link_title_text_member_trip_black" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.calendar ? border_size + "px solid var(--mainGreen)" : border_size + "px solid #C7C7C7 " }} onClick={() => switchDisplayMode(list_container_mode.calendar)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#FFFFFF",
                                    border: "2px var(--mainGreen) solid"
                                }}>
                                    <CalendarAltIcon width={20} height={23} fill={"var(--mainGreen)"} />
                                </Fab><p>{list_container_mode.calendar}</p></div>}
                            <div className="menu_link_title_text_member_trip_black" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.map ? border_size + "px solid var(--mainGreen)" : border_size + "px solid #C7C7C7 " }} onClick={() => switchDisplayMode(list_container_mode.map)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#FFFFFF29",
                                }}>
                                    <MapDuotoneIcon width={25} height={25} fill={"#0D3A7D"} />
                                </Fab><p>{list_container_mode.map}</p></div>
                            <div className="menu_link_title_text_member_trip_black" style={{ width: w1 + "%", borderBottom: tripMode === list_container_mode.map ? border_size + "px solid var(--mainGreen)" : border_size + "px solid #C7C7C7 " }} onClick={() => switchDisplayMode(list_container_mode.timeline)}>
                                <Fab size="small" disabled={false} style={{
                                    outline: "0px",
                                    backgroundColor: "#2C2C2C00",
                                    border: "2px #274674 solid"
                                }}>
                                    <TimelineIcon width={20} height={20} />
                                </Fab><p>{list_container_mode.timeline}</p></div>
                        </Menu>

                    </div>
                    {tripMode === list_container_mode.schedule &&
                        <div style={{ backgroundColor: "#FFFFFF" }}>
                            <TripSchedule />
                        </div>
                    }
                    {tripMode === list_container_mode.calendar &&
                        <div style={{ backgroundColor: "#E8E8E8" }}>
                            <TripCalendar />
                        </div>
                    }


                    <ScrollToTop />
                </div >}
        </React.Fragment >
    )
}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        logo_width: state.Setting.appMenu.logo.width,
        trip: state.Member.memberArea.selectedTrip,
        tripMode: state.Member.tripMode,
        logo_height: state.Setting.appMenu.logo.height,
        uuidPrivate: state.Member.authModal.uuid,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        tripAuthStatus: state.FormBank.TimelineWizard.activeTrip.tripAuthStatus,
        tripId: state.FormBank.TimelineWizard.activeTrip.tripId,
        activities: state.FormBank.TimelineWizard.activeTrip.activities,
        tripNoDate: state.FormBank.TimelineWizard.activeTrip.tripNoDate,
        switch_container: state.Member.switch_container,
    }
}
const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setTimeLineStatus: Actions.setTimeLineStatus,
}

const MemberTripSubContainer = connect(mapStateToProps, mapDispatchToProps)(MemberTripSubContainer_)
export default MemberTripSubContainer
