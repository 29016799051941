import * as Actions from '../../../../redux/actions'

import {
    CheckedBoxReglarIcon,
    LeftArrowIcon,
    RightArrowIcon,
    UnCheckBoxReglarIcon
} from '../SVGBank'
import { landing_router, types_router, URL_REPLACE } from "../RegExValidate"

import MenuPersType from './MenuPersType'
import PersonalizationGroupBars from './PersonalizationGroupBars'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { member_area_router } from "../../Common/RegExValidate"
import { Backdrop, CircularProgress, Link } from '@material-ui/core'
import { clearTimelineTrip, getHomeDetails } from '../../../../redux/actions'
import { useHistory } from 'react-router-dom';
import { createTripThunk, toggleAiTripLoading, updateSavedTrip, updateStayTripData } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice'
import Loader from 'react-loader-spinner'
import { PersonalizedModalUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles'
import { saveHotelTimeline } from '../../BookingPage/PaymentPage/payment.thunk'

const PersonalizationForm_ = ({
    screenSize,
    setSwitchProfileContainer,
    authModal,
    checkBoxes,
    bars,
    setSwitchPersTypeContainer,
    // token = authModal.token,
    uuid = authModal.uuid,
    lang,
    // app_menu_height,
    is_mobile,
    switch_container,
    member,
    switch_container_type,
    setMuverPersCategoriesScores,
    fetchPersonalization,
    switch_container_profile,
    fetchProfileSelectedSets
}) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [homeDetails, setHome] = React.useState(null);
    const savedPersonalTrip = useSelector(state => state.PersonalizedTrip.aiTrip.saved)
    const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
    const stayTripData = useSelector(state => state.PersonalizedTrip.stayTripData)
    const [creating, setCreating] = React.useState(false)
    const isAiPage = history?.location?.pathname?.includes("ai")
    React.useEffect(() => {
        getHomeDetails().then(data => {
            setHome(data);
        })
        fetchPersonalization(uuid, lang)
    }, [uuid])


    let screen_at_1200 = 1200
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "230px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left_next = "15"
    let arrow_container_padding_left = "10"
    let checkboxMerge = "15"
    let sliderPadding = "15"
    let checkbox_title_font = "18px/20px Futura Md BT"
    let notification_font = "14px/21px Futura Lt BT"
    let notification_margin_top = "140px"
    const classes = PersonalizedModalUseStyles();
    if (!isNaN(screenSize)) {
        arrow_container_padding_left = screenSize > screen_at_1200 ? "35" : "10"

        arrow_container_padding_left_next = screenSize > screen_at_1200 ? "15" : "7"
        sliderPadding = screenSize > screen_at_1200 ? "15" : "7"
        checkboxMerge = screenSize > screen_at_1200 ? "15" : "7"
        arrow_icon_size = screenSize > screen_at_1200 ? 19 : 10
        arrow_font = screenSize > screen_at_1200 ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
        arrow_container_width = screenSize > screen_at_1200 ? "230px" : "120px"
        arrow_container_height = screenSize > screen_at_1200 ? "60px" : "30px"
        checkbox_title_font = screenSize > screen_at_1200 ? "18px/20px Futura Md BT" : "13px/18px Futura Md BT"
        notification_font = screenSize > screen_at_1200 ? "14px/21px Futura Lt BT" : "10px/15px Futura Lt BT"
        notification_margin_top = screenSize > screen_at_1200 ? "140px" : "80px"
    }

    const autoGenerateMuver = () => {
        setSwitchPersTypeContainer(uuid, lang, "8");

    }

    const goToNext = () => {
        if (switch_container_type) {
            const index = parseInt(switch_container_type) + 1;
            if (index < 9) {
                const indexString = index.toString()
                setSwitchPersTypeContainer(uuid, lang, indexString)
            }
            if (switch_container_type == 8) {
                savedPersonalTrip ? generateTrip() : goToDestination();
            }
        }
    }

    const goToPrevious = () => {
        if (switch_container_type) {
            const index = parseInt(switch_container_type) - 1;
            if (index < 9) {
                const indexString = index.toString()
                setSwitchPersTypeContainer(uuid, lang, indexString)
            }
            if (switch_container_type == 1) {
                setSwitchProfileContainer(isAiPage? landing_router.interests: landing_router.profile)
            }
        }

    }

    const goToDestination = () => {
        let url = "/destination/" + "New York City".replace(URL_REPLACE, "-") + "-" + "New York".replace(URL_REPLACE, "-") + "-" + "USA".replace(URL_REPLACE, "-") + "/" + 1 + "/656";
        if (homeDetails) {
            url = "/destination/" + homeDetails?.homeDestinationTitle.replace(URL_REPLACE, "-") + "-" + homeDetails?.homeAirport?.airportStateCode.replace(URL_REPLACE, "-") + "/" + 1 + "/" + homeDetails?.homeDestinationID;
        }

        history.push(url.toLowerCase())
    }

    const generateTrip = async () => {

        if (uuid) {
            setCreating(true)
            dispatch(toggleAiTripLoading(true))
            dispatch(clearTimelineTrip()) ///remove any active trip

            if (savedPersonalTrip?.destinations && savedPersonalTrip?.destinations?.length > 0) {
                let destinations = [...savedPersonalTrip?.destinations];
                destinations = destinations?.map((dest) => {
                    return {
                        id: dest?.id,
                        duration: dest?.duration,

                    }
                })

                const tripPayload = {
                    ...savedPersonalTrip,
                    destinations: destinations,
                    uuid: uuid
                }
                const newTrip = await dispatch(createTripThunk(tripPayload));
                const addedTrip = newTrip.payload;

                const newTripId = addedTrip?.tripID || addedTrip?.id;

                if (addedTrip && newTripId) {
                    const tripDestinationId = addedTrip?.tripDestinations &&
                        addedTrip?.tripDestinations?.length > 0 ? addedTrip?.tripDestinations[0]?.id : null;

                    if (tripDestinationId) {
                        // await generateFlight(tripDestinationId)
                    }
                    // setCreating(false)

                    dispatch(updateSavedTrip(null))
                    setTimeout(() => {
                        setCreating(false)
                        dispatch(toggleAiTripLoading(false))
                        const url = "/muver/trippage/" + newTripId
                        history.push(url.toLowerCase())
                    }, 2000);


                }
                else {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }

                setTimeout(() => {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }, 10000);
            }

        }
    }

    const generateStayTrip = async () => {

        if (stayTrip && stayTripData) {
            setCreating(true)
            dispatch(toggleAiTripLoading(true))
            dispatch(clearTimelineTrip()) ///remove any active trip
            const updatedStayData = {
                ...stayTripData,
                uuid:uuid
            }
            const response = await dispatch(saveHotelTimeline(updatedStayData));

            dispatch(updateStayTripData(null))
            const destination = response?.payload?.result?.targetDestination;
            const destName = destination?.partialUri || destination?.nameInline;
            const destId = destination?.destinationId;

            const trip = response?.payload?.result;
         

            let tripObj = {
                density: 5,
                destinations: [{},{
                    id:trip?.targetDestination?.destinationId,
                    duration:3
                }],
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: trip?.tripID || "",
                tripDate: trip?.tripStartDate,
                isRoadTrip: false,
            }


            const newTrip = await dispatch(createTripThunk(tripObj)); ///update new trip with personalized data
        
            if (destName && destId) {
                setCreating(false)
                dispatch(toggleAiTripLoading(false))
                setTimeout(() => {
                    const url = "/destination/" + destName + "/" + 1 + "/" + destId;
                    history.push(url)
                }, 1000);
            }

            setTimeout(() => {
                setCreating(false)
                dispatch(toggleAiTripLoading(false))
            }, 10000);


        }
    }

    return (
        <div className="ml-0 mr-0 margin_top_20">


            <div className={screenSize > screen_at_1200 ? "justify-content-center ml-0 mr-0" : "justify-content-center ml-0 mr-0 text-center"} >
                {(switch_container === member_area_router.personalInfo
                    || switch_container_profile === landing_router.tastes) && <div style={{ position: "relative", marginBottom: "15px", marginTop: "15px" }}>
                        <MenuPersType />
                    </div>}
                <div style={{
                    marginBottom: "20px",
                    backgroundColor: switch_container === member_area_router.personalInfo ? "#191919" : "var(--lightGrayF)",
                    border: checkBoxes !== undefined && checkBoxes.length > 0 ? (switch_container === member_area_router.personalInfo ? "1px solid #EFEFEF" : "0px solid #FFFFFF") : "",

                    textAlign: "left",
                }}>
                    {checkBoxes !== undefined && checkBoxes.length > 0 && checkBoxes.map((group, group_idx) => {
                        return (
                            <div className="d-inline-block" key={group_idx}>
                                {group.length > 0 && group.map((checkbox, checkbox_idx) => {
                                    return (
                                        <div className="d-inline-block" key={checkbox_idx} style={{ margin: checkboxMerge + "px" }}>
                                            {checkbox.score === "5" ?
                                                <div className="d-inline-block" style={{
                                                    font: checkbox_title_font, cursor: "pointer",
                                                    color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                                                }} onClick={() => setMuverPersCategoriesScores(uuid, checkbox.id, "0", lang, "check_box")}><CheckedBoxReglarIcon style={{ marginRight: "5px", marginBottom: "3px" }} />{checkbox.title}</div>
                                                :
                                                <div className="d-inline-block" style={{
                                                    font: checkbox_title_font, cursor: "pointer",
                                                    color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#000000",
                                                }} onClick={() => setMuverPersCategoriesScores(uuid, checkbox.id, "5", lang, "check_box")}><UnCheckBoxReglarIcon style={{ marginRight: "5px", marginBottom: "3px" }} />{checkbox.title}</div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                    }
                </div>

                <div style={{
                    marginBottom: "20px",
                    border: "1px solid var(--mainGrey)",
                    padding: sliderPadding + "px",
                    textAlign: "left",
                }}>
                    {switch_container_type === types_router.see.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.see.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.shop.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.shop.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.eat.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.eat.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.stay.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.stay.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.drink.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.drink.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.play.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.play.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.relax.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.relax.typeId} groupBars={group} />
                        )
                    })
                    }
                    {switch_container_type === types_router.watch.typeId && bars !== undefined && bars.length > 0 && bars.map((group, group_idx) => {
                        return (
                            <PersonalizationGroupBars key={group_idx + "_" + types_router.watch.typeId} groupBars={group} />
                        )
                    })
                    }
                </div>

            </div>



            <div className="position-relative margin_top_20">
                {<div onClick={() => switch_container === member_area_router.personalInfo ? setSwitchProfileContainer(landing_router.profile) : goToPrevious()}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#E8E8E8",
                        paddingLeft: arrow_container_padding_left + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        left: "0px",
                        // padding-left: 5px;
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "#707070",
                    }}>
                        <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: "10px" }} />   Previous Step
                    </div>
                </div>}
                <div onClick={() => !creating && goToNext()}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "var(--mainGreen)",
                        paddingLeft: arrow_container_padding_left_next + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        right: "0px",
                        // padding-left: 5px;
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "var(--mainWhite)",
                    }}>
                        {creating ? "Generating trip..." :
                            switch_container_type !== "8" ? "Next Step" : savedPersonalTrip ? "Generate my trip" : "Home Destination"} <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: switch_container_type !== "8" ? "40px" : "10px" }} />
                    </div>

                </div>
                {switch_container_type !== "8" && <div style={{
                    cursor: "pointer",
                    paddingLeft: arrow_container_padding_left_next + "px",
                    marginTop: is_mobile ? "50px" : "80px",
                    display: "block",
                    position: "absolute",
                    color: "var(--mainGreen)",
                    border: "0",
                    right: "0px",
                    // padding-left: 5px;
                }}>
                    {savedPersonalTrip ?
                        <p href="#" onClick={generateTrip}>{"Skip to my suggested trip"}</p> : stayTrip ?
                            <p href="#" onClick={generateStayTrip}>{"Skip to trip generation"}</p> :
                            <p href="#" onClick={goToDestination}>Skip to your home destination</p>
                    }
                </div>

                }

            </div>
            <div style={{
                height: "50px",
                marginTop: is_mobile ? "100px" : notification_margin_top,
                marginButtom: "10px",
                background: "rgba(25, 188, 155,.14)",
                position: "relative",
            }}>
                <div style={{
                    color: "#19BC9B",
                    font: notification_font,
                    margin: "0",
                    position: "absolute",
                    left: "1%",
                    top: "50%",
                    msTransform: "translateY(-50%)",
                    transform: "translateY(-50%)",
                    opacity: "1.0",
                }}>
                    SAFE &amp; SECURE: Your information will only be used to match you with like-minded people and personalized experiences
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        authModal: state.Member.authModal,
        checkBoxes: state.FormBank.personalization.checkBoxesSets,
        bars: state.FormBank.personalization.barSets,
        switch_container_type: state.FormBank.personalization.switch_container,
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        member: state.Member.socialMember.member,
        uuid: state.Member.authModal.uuid,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        switch_container: state.Member.switch_container,
        switch_container_profile: state.Member.profile_container,
    }
}

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    setMuverPersCategoriesScores: Actions.setMuverPersCategoriesScores,
    setSwitchPersTypeContainer: Actions.setSwitchPersTypeContainer,
    fetchPersonalization: Actions.fetchPersonalization,
    fetchProfileSelectedSets: Actions.fetchProfileSelectedSets,
}

const PersonalizationForm = connect(mapStateToProps, mapDispatchToProps)(PersonalizationForm_)
export default PersonalizationForm

