import {
    ALERT_BARS_HANDLE,
    CURRENT_BODY_CLIENT_WIDTH,
    CURRENT_LANGUAGE,
    CURRENT_PAGE_Y_OFFSET,
    CURRENT_SCREEN_SIZE,
    CURRENT_SCREEN_VERTICAL,
    CURRENT_SCREEN_VERTICAL_IS_TOP,
    FETCH_API_CURRENCY_SETS,
    FETCH_API_CURRENCY_SETS_ERROR,
    FETCH_API_CURRENCY_SETS_FETCHED,
    FETCH_API_LANGUAGE_SETS,
    FETCH_API_LANGUAGE_SETS_ERROR,
    FETCH_API_LANGUAGE_SETS_FETCHED,
    GetAsyncHelper,
    PostAsyncHelper,
    SET_APP_MENU,
    SET_APP_MENU_BACK_GROUND_COLOR,
    SET_APP_MENU_BACK_GROUND_COLOR_MOBILE,
    SET_APP_MENU_BOOKING_FORM_STATUS,
    SET_APP_MENU_TEXT_COLOR,
    SET_CURRENT_CURRENCY,
    SET_CURRENT_LANGUAGE,
    SET_LOADER,
    SET_MODAL_CURRENCY_AUTH,
    SET_MODAL_LANGUAGE_AUTH,
} from "../interfaces/types"

import Cookies from 'js-cookie'
import qs from 'qs'
import { environment } from "../../Environments/environment";

export const setLoader = (preload) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: preload
        })
    }
};
export const fetchCurrencySets = () => async dispatch => {
    dispatch({
        type: FETCH_API_CURRENCY_SETS_FETCHED,
        is_fetched: true,
    })
    const api_url_fetch_currency = environment.apiUrl +"muvsearch/getAllCurrencies"
    GetAsyncHelper(api_url_fetch_currency).then(responseFetchCurrency => {
        if (responseFetchCurrency.statusText === "OK") {
            dispatch({
                type: FETCH_API_CURRENCY_SETS,
                currency_sets: responseFetchCurrency.data
            })

        } else {
            dispatch({
                type: FETCH_API_CURRENCY_SETS_ERROR,
                api_error: true
            })
        }
    })


    const currency_cookies = Cookies.get('muv_currency')
    if (currency_cookies !== undefined && currency_cookies !== "") {
        const currency_cookies_object = qs.parse(currency_cookies)
        dispatch({
            type: SET_CURRENT_CURRENCY,
            short_code: currency_cookies_object.short_code,
            symbol: currency_cookies_object.symbol,
            name: currency_cookies_object.name,
        })
    }
}

export const fetchLanguageSets = () => async dispatch => {
    dispatch({
        type: FETCH_API_LANGUAGE_SETS_FETCHED,
        is_fetched: true,
    })
    const api_url_fetch_currency = environment.apiUrl +"muvsearch/getAllLanguages"
    GetAsyncHelper(api_url_fetch_currency).then(responseFetchLanguage => {
        if (responseFetchLanguage.statusText === "OK") {
            dispatch({
                type: FETCH_API_LANGUAGE_SETS,
                language_sets: responseFetchLanguage.data
            })

        } else {
            dispatch({
                type: FETCH_API_LANGUAGE_SETS_ERROR,
                api_error: true
            })
        }
    })

    const language_cookies = Cookies.get('muv_language')

    if (language_cookies !== undefined && language_cookies !== "") {
        const language_cookies_object = qs.parse(language_cookies)
        dispatch({
            type: SET_CURRENT_LANGUAGE,
            short_code: language_cookies_object.short_code,
            long_code: language_cookies_object.long_code,
            english_name: language_cookies_object.englishName,
            native_name: language_cookies_object.nativeName,
        })
    }
}

export const setCurrentLanguage = (obj, token) => dispatch => {

    dispatch({
        type: SET_CURRENT_LANGUAGE,
        short_code: obj.short_code,
        long_code: obj.long_code,
        english_name: obj.englishName,
        native_name: obj.nativeName,
    })
    if (token !== undefined && token !== "") {
        let obj_post = {
            token: token,
            language: obj,
        }

        let api_url = environment.apiUrl +"memberUpdate"

        PostAsyncHelper(api_url, obj_post)
        dispatch({
            type: SET_MODAL_LANGUAGE_AUTH,
            language: obj
        })
    }
    const languageStringify = qs.stringify(obj)
    Cookies.set('muv_language', languageStringify, {
        expires: 7
    })
}
export const setCurrentCurrency = (obj, token) => dispatch => {
    dispatch({
        type: SET_CURRENT_CURRENCY,
        short_code: obj.short_code,
        symbol: obj.symbol,
        name: obj.name,
    })
    if (token !== undefined && token !== "") {
        let obj_post = {
            token: token,
            language: obj,
        }

        let api_url = environment.apiUrl +"memberUpdate"

        PostAsyncHelper(api_url, obj_post)
        dispatch({
            type: SET_MODAL_CURRENCY_AUTH,
            currency: obj
        })
    }
    const currencyStringify = qs.stringify(obj)
    Cookies.set('muv_currency', currencyStringify, {
        expires: 7
    })
}
export const setScreenSize = (width, height) => dispatch => {
    dispatch({
        type: CURRENT_SCREEN_SIZE,
        width: width,
        height: height,
    })
}
/* set screen size */
export const setBodyClientSize = (width, height) => dispatch => {
    dispatch({
        type: CURRENT_BODY_CLIENT_WIDTH,
        client_width: width,
        client_height: height,
    })
}
/* set screen vertical */
export const setScreenVertical = (veticalObj) => dispatch => {
    dispatch({
        type: CURRENT_SCREEN_VERTICAL,
        vertical_obj: veticalObj
    })
}
export const setScreenVerticalIsTop = (is_top) => dispatch => {
    dispatch({
        type: CURRENT_SCREEN_VERTICAL_IS_TOP,
        is_top: is_top
    })
}
export const setPageYOffset = (pageYOffset) => dispatch => {
    dispatch({
        type: CURRENT_PAGE_Y_OFFSET,
        page_y_offest: pageYOffset
    })
}

/* set language */
export const setLanguage = (lang) => dispatch => {
    dispatch({
        type: CURRENT_LANGUAGE,
        lang: lang,
    })
}

/* set menu */
export const setAppMenu = (current_screen_size) => dispatch => {
    let maxMobileWidth = 1200
    let is_mobile = current_screen_size < maxMobileWidth ? true : false
    let base_laptop = 1920
    let menu_height_laptop = current_screen_size < base_laptop ? Math.floor(117 / base_laptop * current_screen_size) : 117
    let logo_width_laptop = current_screen_size < base_laptop ? Math.floor(136 / base_laptop * current_screen_size) : 136
    let logo_height_laptop = current_screen_size < base_laptop ? Math.floor(136 / base_laptop * current_screen_size) : 136

    let menu_height_mobile = 25
    let logo_width_mobile = 51
    let logo_height_mobile = 57
    let menu_height = is_mobile ? menu_height_mobile : menu_height_laptop
    let logo_width = is_mobile ? logo_width_mobile : logo_width_laptop
    let logo_height = is_mobile ? logo_height_mobile : logo_height_laptop
    dispatch({
        type: SET_APP_MENU,
        app_menu_height: menu_height,
        logo_width: logo_width,
        logo_height: logo_height,
        is_mobile: is_mobile,
    })
}

export const settAppMenuBackGroundColor = (color) => dispatch => {
    
    dispatch({
        type: SET_APP_MENU_BACK_GROUND_COLOR,
        color: color,
    })
}
export const setAppMenuBookingButtonStatus = (status) => dispatch => {
    
    dispatch({
        type: SET_APP_MENU_BOOKING_FORM_STATUS,
        status: status,
    })
}
export const settAppMenuBackGroundColorMobile = (color) => dispatch => {
    dispatch({
        type: SET_APP_MENU_BACK_GROUND_COLOR_MOBILE,
        color: color,
    })
}
export const setterAppMenuTopBarTextColor = (color) => dispatch => {
    dispatch({
        type: SET_APP_MENU_TEXT_COLOR,
        color: color,
    })
}
export const alertBar = (type, messgeBody) => dispatch => {
    dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: type,
        message: messgeBody
    })
}