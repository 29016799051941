import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'axios';
import Cookies from 'js-cookie';
import { environment } from '../../../../Environments/environment';
import { setTripToTimeline } from '../../../../redux/actions';
import { ALERT_BARS_HANDLE, GetAsyncHelper, PostAsyncHelper, SET_TIMELINE_WIZARD_STATUS } from '../../../../redux/interfaces/types';
import { getSessionId } from '../../../../utility/utility';
import { multiCityData } from './flightTestData';



export const getAirportsListThunk = createAsyncThunk(
    'Flights/getAirports',
    async (query, thunkAPI) => {
        const response = await Axios.request({
            //url: PRICELINE_SERVER+'/hotel/getAutoSuggestV2' ,
            url: `${environment.apiUrl}proxy/dispatchRequest`,
            method: 'get',
            params: {
                "proxyData[string]": query,
                "proxyData[airports]": true,
                "proxyData[cities]": true,
                "proxyData[platform_version]": 2,
                proxyApiMethod: "GET",
                proxyApiProperty: "air",
                proxyApiEndpoint: "getAutoComplete"
            },
        })


        if (response.data.ProxyResult.getAirAutoComplete) {
            const airports = response.data.ProxyResult.getAirAutoComplete.results.getSolr.results.data.airport_data;
            const cities = response.data.ProxyResult.getAirAutoComplete.results.getSolr.results.data.city_data;
            let airportsArry = [];
            let citiesArry = [];
            let cityAirportMerge = [];
      

            if (cities) {
                Object.keys(cities).forEach((key, index) => {
                    let city = cities[key];
                    if (city.city_code) {
                        city['iata'] = city.city_code;
                        city['airport'] = "All Airports";
                        city['type'] = "main";
                        citiesArry.push(city)
                    }
                });
            }
            if (airports) {
                Object.keys(airports).forEach((key, index) => {
                    let airport = airports[key];
                    airportsArry.push(airport)
                }
                );
                airportsArry = airportsArry.filter((v, i, a) => a.findIndex(t => (t.airport === v.airport)) === i) ///remove duplicates
            }

            //join airports and cities
            let airportsArrayCopy = airportsArry;
   

            if (citiesArry && citiesArry.length > 0) {
                citiesArry.forEach((city, cityIndex) => {
                    cityAirportMerge.push(city);
                    const subList = airportsArrayCopy.filter((airport) => {
                        const isSub = airport.city === city.city && airport.country_code === city.country_code;
                        if (isSub) {
                            airport['type'] = "sub"
                        }
                        return isSub;
                    });
                    cityAirportMerge = cityAirportMerge.concat(subList);
                    airportsArry = airportsArrayCopy.filter((item) => item.city !== city.city);
                })
            } else {
                cityAirportMerge = airportsArrayCopy;
            }


            const results = cityAirportMerge
            return results;
        }
    }
)

export const getFlightsListThunk = createAsyncThunk(
    'Flights/getFlights',
    async (query, thunkAPI) => {
        const { Flights } = thunkAPI.getState();
        const flightData = Flights.flightData;
        const currency = flightData.currency;
        const limit = query?.limit || 300;
        const page = Flights.page;
        try {
            let departureAirportCodes = []
            let destinationAirportCodes = []
            let departureDates = []

            departureAirportCodes = flightData.flights.map((data) => data.airportFrom.iata);
            destinationAirportCodes = flightData.flights.map((data) => data.airportTo.iata);
            departureDates = flightData.flights.map((data) => data.departureDateFormat);

            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[origin_airport_code]": departureAirportCodes,
                    "proxyData[destination_airport_code]": destinationAirportCodes,
                    "proxyData[departure_date]": departureDates,
                    "proxyData[adults]": flightData.numberAdult,
                    "proxyData[children]": flightData.numberChild,
                    "proxyData[cabin_class]": flightData.cabinClass,
                    "proxyData[results_per_page]": limit,
                    "proxyData[page]": 1,
                    "proxyData[requested_currency]": currency,
                    "proxyData[platform_version]": 2,
                    // "proxyData[sid]": getSessionId(),
                    proxyApiMethod: "GET",
                    proxyApiProperty: "air",
                    proxyApiEndpoint: "getFlightDepartures"
                },
            })


            if (response.data.ProxyResult.getAirFlightDepartures) {
                const data = response.data.ProxyResult.getAirFlightDepartures.results.result;
                return data;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }



    }
)

export const lookUpFlightThunk = createAsyncThunk(
    'Flights/lookUpFlightThunk',
    async (query, thunkAPI) => {
        const { Flights } = thunkAPI.getState();
        const flightData = Flights.flightData;
        const email = query.email;
        const booking_id = query.booking_id;
        try {
            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[booking_id]": booking_id,
                    "proxyData[email]": email,
                    "proxyData[platform_version]": 2,
                    proxyApiMethod: "POST",
                    proxyApiProperty: "air",
                    proxyApiEndpoint: "getFlightLookUp"
                },
            })


            if (response.data.ProxyResult.getAirFlightLookUp) {
                const data = response.data.ProxyResult.getAirFlightLookUp.results.results;
                return data;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)




export const deleteFlightTimeline = createAsyncThunk(
    'Flights/deleteFlightTimeline',
    async (query, thunkAPI) => {
        const uuid = query?.uuid;
        const tripId = query?.tripId
        const waitForSecond = query?.wait;
        const dispatch = thunkAPI.dispatch;
        try {
            let apiURL = environment.apiUrl + "/timeline/removeFlight/" + uuid;
            const postData = query?.postData;

            const response = await PostAsyncHelper(apiURL, postData);
            if (response && response.data) {


                if (!waitForSecond) {
                    dispatch(setTripToTimeline(tripId, uuid))
                }


                // dispatch({
                //     type: SET_TIMELINE_WIZARD_STATUS,
                //     status: false
                // })

                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your flight was removed"
                })
                return response.data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Your flight was not removed"
            })

        }
        catch {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Your flight was not removed"
            })
            return null;
        }
    }
)

export const CancelFlightThunk = createAsyncThunk(
    'Flights/CancelFlightThunk',
    async (query, thunkAPI) => {
        const { Flights } = thunkAPI.getState();
        const flightData = Flights.flightData;
        const email = query.email;
        const booking_id = query.booking_id;
        const dispatch = thunkAPI.dispatch;
        try {
            let apiURL = environment.apiUrl + `/bookingData/priceline/flight/cancelFlight?booking_trip_number=${booking_id}&bookedEmail=${email}`;
            const postData = {
                booking_trip_number: booking_id,
                bookedEmail: email
            };

            const response = await PostAsyncHelper(apiURL, postData);

            if (response && response.data) {
                const data = response.data;

                if (data?.result) {
                    dispatch({
                        type: ALERT_BARS_HANDLE,
                        message_type: "success",
                        message: "Your booking was cancelled"
                    })
                    //    setTimeout(() => {
                    //     window.open("/flight/search","_self")
                    //    }, 3000);
                }
                return data;
            }
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Your booking was not cancelled"
            })

            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)



export const getReturnFlightThunk = createAsyncThunk(
    'Flights/getReturnFlight',
    async (query, thunkAPI) => {
        const { Flights } = thunkAPI.getState();
        const flightData = Flights.flightData;
        const currency = flightData.currency;
        try {
            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[ppn_bundle]": query.ppn_return_bundle,
                    "proxyData[requested_currency]": currency,
                    // "proxyData[sid]": getSessionId(),
                    "proxyData[platform_version]": 2,
                    proxyApiMethod: "GET",
                    proxyApiProperty: "air",
                    proxyApiEndpoint: "getFlightReturns"
                },
            })


            if (response.data.ProxyResult.getAirFlightReturns) {
                const data = response.data.ProxyResult.getAirFlightReturns.results.result;
                return data;
            }
        }
        catch (err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)



export const getFlightContractThunk = createAsyncThunk(
    'Flights/getFlightContract',
    async (query, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        try {

            const { Flights, Setting } = thunkAPI.getState();

            const flightData = Flights.flightData;
            const currency = flightData.currency || Setting?.currency?.name;
            const selectedFlight = Flights.selectedFlight;
            const ppnBundle = query ? query : selectedFlight.ppn_contract_bundle;

            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[ppn_bundle]": ppnBundle,
                    "proxyData[requested_currency]": currency,
                    // "proxyData[sid]": getSessionId(),
                    "proxyData[platform_version]": 2,
                    proxyApiMethod: "GET",
                    proxyApiProperty: "air",
                    proxyApiEndpoint: "getFlightContract"
                },
            })

            if (response.data.ProxyResult.getAirFlightContract) {
                const data = response.data.ProxyResult.getAirFlightContract.results.result;
                return data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: response?.data?.ProxyResult?.status || "Flight(s) no longer available. Please relaunch search"
            })
            return thunkAPI.rejectWithValue(false)
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: err?.response?.status || "Flight(s) no longer available. Please relaunch search"
            })
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)

export const checkFlightAvailable = async (ppnBundle, currency, dispatch) => {
    const response = await Axios.request({
        url: `${environment.apiUrl}proxy/dispatchRequest`,
        method: 'get',
        params: {
            "proxyData[ppn_bundle]": ppnBundle,
            "proxyData[requested_currency]": currency,
            // "proxyData[sid]": getSessionId(),
            "proxyData[platform_version]": 2,
            proxyApiMethod: "GET",
            proxyApiProperty: "air",
            proxyApiEndpoint: "getFlightContract"
        },
    })
    if (response.data.ProxyResult.getAirFlightContract) {
        const data = response.data.ProxyResult.getAirFlightContract.results.result;
        return data;
    }
    if (dispatch) {
        // dispatch({
        //     type: ALERT_BARS_HANDLE,
        //     message_type: "error",
        //     message: "Flight(s) no longer available. Please relaunch search"
        // })
    }
    return false;
}


export const bookFlightThunk = createAsyncThunk(
    'Flights/getFlightBook',
    async (query, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;
        let errorMessage = ""
        try {

            const { Flights, Member, FormBank, PersonalizedTrip } = thunkAPI.getState();
            const { uuid } = Member.authModal || null;
            const flightContract = Flights.flightContract;
            const ppnBundle = flightContract ? flightContract.itinerary_data.ppn_book_bundle : null;
            const contactInfo = query.contactInfo;
            const passenger = query.passenger;
            const paymentInfo = query.paymentInfo;

            if (contactInfo && passenger && paymentInfo) {

                var passengerParams = {};
                passenger.forEach((val, index) => {
                    Object.keys(val).forEach(key => {
                        passengerParams[`proxyData[passenger][${index}][${key}]`] = val[key];
                    })
                })



                const response = await Axios.request({
                    url: `${environment.apiUrl}proxy/dispatchRequest`,
                    method: 'get',
                    params: {
                        "proxyData[ppn_bundle]": ppnBundle,
                        "proxyData[phone_number]": contactInfo.phone_number,
                        "proxyData[email]": contactInfo.email,
                        "proxyData[address]": paymentInfo.address,
                        "proxyData[city]": paymentInfo.city,
                        "proxyData[country_code]": paymentInfo.country_code,
                        "proxyData[postal_code]": paymentInfo.postal_code,
                        "proxyData[cc_name]": paymentInfo.cc_name,
                        "proxyData[cc_type]": paymentInfo.cc_type,
                        "proxyData[cc_number]": paymentInfo.cc_number,
                        "proxyData[cc_exp_mo]": paymentInfo.cc_exp_mo,
                        "proxyData[cc_exp_yr]": paymentInfo.cc_exp_yr,
                        "proxyData[cc_code]": paymentInfo.cc_code,
                        "proxyData[platform_version]": 2,
                        // "proxyData[sid]": getSessionId(),
                        proxyApiMethod: "POST",
                        proxyApiProperty: "air",
                        proxyApiEndpoint: "getFlightBook",
                        ...passengerParams
                    },
                })

                const errorStatus = response?.data?.ProxyResult?.status;
                const errorStatusMessage = response?.data?.ProxyResult?.status_message;

                errorMessage = errorStatus ? formatMessage(errorStatus) : formatMessage(errorStatusMessage);
                const data = response.data.ProxyResult["getAirFlightBook"]?.results?.result || null;

                const { selectedDepartureFlight, selectedReturnFlight, selectedFlightMode } = Flights;
                const selected = selectedFlightMode === 'round_trip' && selectedReturnFlight ? selectedReturnFlight : selectedDepartureFlight ? selectedDepartureFlight : null;

                const activeTripId = FormBank.TimelineWizard.activeTrip?.tripId;
                const aiTripId = PersonalizedTrip?.oneclick?.tripId;

                if (data) {
                    const saveData = {
                        postData: {
                            tripId: aiTripId ? aiTripId : activeTripId,
                            booking_trip_number: data.trip_number || "",
                            booking_status: data.booking_status || "",
                            booking_status_code: data.booking_status || "",
                            airports: Flights?.savedFlight || [],
                            bookedEmail: contactInfo.email,
                            displaySymbol: selected?.price_details?.display_symbol,
                            displayCurrency: selected?.price_details?.display_currency,
                            displayTotalFare: selected?.price_details?.display_total_fare
                        },
                        uuid: uuid || null
                    }
                    dispatch({
                        type: ALERT_BARS_HANDLE,
                        message_type: "success",
                        message: "Your booking was successful"
                    })
                    dispatch(saveFlightTimeline(saveData))
                    return data;
                }
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "error",
                    message: errorMessage,
                    duration:8000
                })
                return thunkAPI.rejectWithValue(errorMessage)
            }
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: errorMessage,
                duration:8000
            })
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)

const formatMessage = (str) => {
    if (str) {
        return str.substr(str.indexOf(":") + 2)
    }
    return "Booking failed, Please try again"
}

export const getNearByDestinationCode = createAsyncThunk(
    'Flights/getNearByDestinationCode',
    async (query, thunkAPI) => {
        try {

            const code = query;
            // "destination/getSubDestinations/en?destinationId=2&limit=5&offset=0";
            let apiURL = environment.apiUrl + `muvsearch/getDestinationByIata?iata=${code}`;

            const response = await GetAsyncHelper(apiURL);

            if (response && response.statusText === "OK") {
                return response?.data?.result;
            }
        }
        catch {
            return null;
        }
    }
)

export const getNearByPricelineCode = createAsyncThunk(
    'Flights/getNearByPricelineCode',
    async (query, thunkAPI) => {
        try {

            const code = query;
            // "destination/getSubDestinations/en?destinationId=2&limit=5&offset=0";
            let apiURL = environment.apiUrl + `muvsearch/nearestPricelineCityByIataSearch?iata=${code}`;

            const response = await GetAsyncHelper(apiURL);

            if (response && response.statusText === "OK") {
                return response?.data?.result;
            }
        }
        catch {
            return null;
        }
    }
)

export const saveFlightTimeline = createAsyncThunk(
    'Flights/saveFlightTimeline',
    async (query, thunkAPI) => {
        const uuid = query?.uuid;
        const dispatch = thunkAPI.dispatch;
        try {
            let apiURL = environment.apiUrl + "bookingData/priceline/flight/processPricelineFlight/" + uuid;
            const postData = query?.postData;

            const response = await PostAsyncHelper(apiURL, postData);
            if (response && response.data) {

                const tripID = response.data?.result?.tripID;
                dispatch(setTripToTimeline(tripID, uuid))

                dispatch({
                    type: SET_TIMELINE_WIZARD_STATUS,
                    status: false
                })

                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your flight was saved"
                })
                return response.data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your flight was not saved" : "You must log in to save this"
            })

        }
        catch {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your flight was not saved" : "You must log in to save this"
            })
            return null;
        }
    }
)




export const saveFlightTrip = createAsyncThunk(
    'Flights/saveFlightTrip',
    async (query, thunkAPI) => {
        const uuid = query?.uuid;
        const dispatch = thunkAPI.dispatch;
        try {
            let apiURL = environment.apiUrl + "bookingData/priceline/flight/saveFlightForGeneratedTripDestination/" + uuid;
            const postData = query;

            const response = await PostAsyncHelper(apiURL, postData);
            if (response && response.data) {

                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your flight was saved"
                })
                return response.data;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your flight was not saved" : "You must log in to save this"
            })

        }
        catch {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your flight was not saved" : "You must log in to save this"
            })
            return null;
        }
    }
)


export const saveHotelTrip = createAsyncThunk(
    'Flights/saveHotelTrip',
    async (query, thunkAPI) => {
        const uuid = query?.uuid;
        const dispatch = thunkAPI.dispatch;
        try {
            let apiURL = environment.apiUrl + "bookingData/priceline/hotel/saveHotelForGeneratedTripDestination/" + uuid;
            const postData = query;

            const response = await PostAsyncHelper(apiURL, postData);
            if (response && (response.data || response.result)) {

                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "success",
                    message: "Your hotel was saved"
                })
                return response.result;
            }
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your hotel was not saved" : "You must log in to save this"
            })

        }
        catch {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: uuid ? "Your hotel was not saved" : "You must log in to save this"
            })
            return null;
        }
    }
)
