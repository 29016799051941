import * as Actions from '../../../../redux/actions'
import "./booking.css";
import {
    AddressMapIcon,
    CalendarDuotoneIcon,
    TimesIcon,
} from "../SVGBank"

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React from 'react'
import { connect } from 'react-redux'
import { ArrowBack, ArrowForward, ArrowRight } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { capitalizeWord } from '../../../../utility/utility';

const GenerateTripFormDestinaitonElement_ = ({ flipped, changeLeavingFrom, leavingFrom, flipDirection, index, error, switch_container, setStartingPoint, destination, setDestinationsData, removeAddedDestination, lengthDestiantions, is_mobile, lang, getDestinationListTopBar, searchListDestinations, activeTrip, isRoadTrip }) => {
    const [startDate, setStartDate] = React.useState(destination.startDate)
    const [selectedDestination, setSelected] = React.useState(destination)
    const [fromAddress, setFrom] = React.useState(leavingFrom)


    const handleChangeDuration = (event) => {
        setDestinationsData(destination.destinationId, destination.startDate, event.target.value, destination.positionId, destination.availableSelectDate, selectedDestination)
    }
    const handleChangeStartDate = (date) => {
        setStartDate(date)
        setDestinationsData(destination.destinationId, date, destination.duration, destination.positionId, destination.availableSelectDate, selectedDestination)
    }
    const handleChangeDestiantion = (destinations_autocomplete, leavingFrom) => {
        const destination_id = destinations_autocomplete[0] !== undefined ? destinations_autocomplete[0].destinationId : "";
        const selected = destinations_autocomplete && destinations_autocomplete?.length > 0 ? destinations_autocomplete[0] : null;

        if (leavingFrom) {
            changeLeavingFrom(destination_id, destination.startDate, destination.duration, destination.positionId, destination.availableSelectDate, selected)
            // setStartingPoint(destinations_autocomplete[0]);
        }
        else {

            setDestinationsData(destination_id, destination.startDate, destination.duration, destination.positionId, destination.availableSelectDate, selected)
        }

    }

    const flip = ()=>{
        setFrom(selectedDestination)
        setSelected(fromAddress);
       flipDirection()
    }

    const handleSearchDestinations = (query) => {
        //console.log(`PlanTripFormDestinationElement handleSearchDestinations isRoadTrip =${isRoadTrip}`)
        getDestinationListTopBar(query, lang, isRoadTrip)
    }

    //update from address
    React.useEffect(() => {
        if(leavingFrom?.destinationId){
            setFrom(leavingFrom)
        }
    }, [leavingFrom])

    React.useEffect(() => {
        setStartDate(destination.startDate)
    }, [destination.startDate])

    React.useEffect(() => {
        setSelected(destination)
    }, [destination])
    
    var indexArray = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];


    let placeholder = capitalizeWord(`${indexArray[index]} destination`);

    //console.log(`PlanTripFormDestinationElement switch_container = ${switch_container}`)
    //console.log(`PlanTripFormDestinationElement tripMode = ${tripMode}`)
    //console.log(`PlanTripFormDestinationElement isRoadTrip = ${isRoadTrip}`)





    return (
        <div className="row m-0 p-0 mt-3 w-100" style={{ background: index === 0 ? "white" : "transparent", borderRadius: 15 }}>
            {index === 0 && <div className={"col-6 col-md-4  p-0 position-relative"}>
                {/* <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: "1",
                        top: "18px",
                        left: "-20px",
                    }}
                    onClick={() => removeAddedDestination(destination)}><TimesIcon width={'15'} height={'15'} />
                </div> */}

                <div className="position-relative">
                    <AsyncTypeahead
                        id="planTrip-typeahead"
                        labelKey={(option) =>
                            option.stateName !== undefined && option.stateName !== "" ?
                                `${option.name}, ${option.stateName}, ${option.countryName}`
                                : `${option.name}, ${option.countryName}`}
                        onChange={val => {
                            setFrom(val[0])
                            handleChangeDestiantion(val, true)
                        }}
                        options={searchListDestinations}
                        placeholder={"Leaving from"}
                        filterBy={() => true}
                        shouldSelect={true}
                        className={index === 0 ? "hideBorder" : null}
                        minLength={1}
                        selected={fromAddress?.destinationId? [fromAddress]:[]}
                        onSearch={query => handleSearchDestinations(query)}
                        renderMenuItemChildren={(option) => (
                            option.stateName !== undefined && option.stateName !== "" ?
                                <div key={option.id} user={option} >{option.name}, {option.stateName}, {option.countryName}</div> :
                                <div key={option.id} user={option} >{option.name}, {option.countryName}</div>
                        )}
                        isLoading={false}
                        size={'large'}
                        delay={100}
                        clearButton={true}
                        style={isRoadTrip && lengthDestiantions === 1 && error ? { border: '1px solid red', borderRadius: 8 } : null}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <AddressMapIcon width={15} height={15} />
                    </div>
                </div>
            </div>}
            <div className={lengthDestiantions > 1 && index !== 0 ? "col-6 col-md-8  p-0 position-relative" : "col-6 col-md-4  p-0 position-relative"}>
                {lengthDestiantions > 1 && index !== 0 && <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: "1",
                        top: is_mobile ? "4px":"18px",
                        left: "-20px",
                    }}
                    onClick={() => removeAddedDestination(destination)}><TimesIcon width={'15'} height={'15'} />
                </div>}

                <div className="position-relative">


                    <AsyncTypeahead
                        id="planTrip-typeahead"
                        labelKey={(option) =>
                            option.stateName !== undefined && option.stateName !== "" ?
                                `${option.name}, ${option.stateName}, ${option.countryName}`
                                : `${option.name}, ${option.countryName}`}
                        onChange={val => {
                            setSelected(val[0])
                            handleChangeDestiantion(val)
                        }}
                        filterBy={() => true}
                        options={searchListDestinations}
                        placeholder={placeholder}
                        shouldSelect={true}
                        selected={selectedDestination?.destinationId? [selectedDestination]:[]}
                        className={index === 0 ? "hideBorder" : null}
                        minLength={1}
                        onSearch={query => handleSearchDestinations(query)}
                        renderMenuItemChildren={(option) => (
                            option.stateName !== undefined && option.stateName !== "" ?
                                <div key={option.id} user={option} >{option.name}, {option.stateName}, {option.countryName}</div> :
                                <div key={option.id} user={option} >{option.name}, {option.countryName}</div>
                        )}
                        isLoading={false}
                        size={'large'}
                        delay={100}
                        clearButton={true}
                        style={isRoadTrip && lengthDestiantions === 1 && error ? { border: '1px solid red', borderRadius: 8 } : null}
                    />


                    {index === 0 ? <div className="position-absolute" style={{
                        top: is_mobile ? "10px" : "12px", left: -10,
                     
                        borderRadius: '50%', width: 35, border: '1px solid #202020 '
                    }} onClick={flip}>
                        <div style={{  padding: 1,cursor:"pointer",   textAlign:"center" }}>
                        <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{color: '#202020'}} />
                        </div>
                    </div> :
                        <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                            <AddressMapIcon width={15} height={15} />
                        </div>}
                </div>
            </div>
            {<div className={index === 0? "col-6 col-md-2  p-0":"col-3 col-md-2  p-0"}>
                <div className="position-relative"
                    style={isRoadTrip && lengthDestiantions === 1 && error ? { border: '1px solid red', borderRadius: 8 } : null}>
                    <DatePicker
                        selected={startDate}
                        onChange={date => handleChangeStartDate(date)}
                        placeholderText="Start"
                        dateFormat="yyyy/MM/dd"
                        minDate={destination.availableSelectDate}
                        className="form-control"
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>}
            {<div className={index === 0? "col-6 col-md-2 p-0" :"col-3 col-md-2 p-0"}>
                <select className="form-control pl-3 duration" value={destination.duration} onChange={event => handleChangeDuration(event)}>
                    <option value="undefined" >Duration</option>
                    <option value="1" >Stop</option>
                    <option value="2" >1 night</option>
                    {
                      new Array(58).fill("").map((val,index)=>{
                        const value = index + 3;
                        return (
                            <option key={index + "night"} value={(value).toString()} >{index + 2} nights</option>
                        )
                      })  
                    }
                </select>
            </div>}

        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
    searchListDestinations: state.Home.search_list_destinations,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switch_container: state.Member.switch_container,
    //tripMode: state.Member.tripMode,
})

const mapDispatchToProps = {
    getDestinationListTopBar: Actions.getDestinationListTopBar,
}

const GenerateTripFormDestinaitonElement = connect(mapStateToProps, mapDispatchToProps)(GenerateTripFormDestinaitonElement_)
export default GenerateTripFormDestinaitonElement
