import Axios from "axios";
import { environment } from "../../../Environments/environment";
import {
  GetAsyncHelper,
  PostAsyncHelper,
} from "../../../redux/interfaces/types";

export const getHotelMapImage = async (establishmentPricelineID) => {
  const ppnId = establishmentPricelineID;
  let obj = {
    hotelid_ppn: ppnId,
  };

  let api_url = `${environment.apiUrl}muvhelpers/getHotelStaticMap`;
  const response = await PostAsyncHelper(api_url, obj);
  const results = response?.data;
  const imageUrl = results?.result?.full_uri;

  return imageUrl ?? null;
};

export const getHotelPlannerID = async (ppnId) => {
  try {
    let api_url = `${environment.apiUrl}muv-hp/getHpByPpnId?prHotelIdPpn=${ppnId}`;
    const response = await GetAsyncHelper(api_url);
    const data = response?.data;
    const hotelId = data?.result?.hotelplanner_id;
    getHotelPlanner(hotelId);
    return hotelId;
  } catch (err) {}
};

export const getHotelPlanner = async (hotelId) => {
  try {
    const ipAddress = await getIPFromAmazon();
    const userAgent = window.navigator.userAgent;
    const response = await Axios.request({
      url: `${environment.apiUrl}muv-hp/dispatchRequest`,
      method: "post",
      //   params: {
      //     "proxyData[hotelId]":hotelId,
      //     proxyApiMethod: "getProfile",
      //     httpMethod: "POST",
      //   },

      params: {
        "proxyData[hotelId]": hotelId,
        "proxyData[checkIn]": "09/17/2024",
        "proxyData[checkOut]": "09/18/2024",
        "proxyData[roomCount]": "1",
        "proxyData[adultCount]": "1",
        "proxyData[customerIPAddress]": ipAddress,
        "proxyData[customerUserAgent]": userAgent,

        proxyApiMethod: "propertyAvailability",
        httpMethod: "POST",
      },
    });
  } catch (err) {}
};

export async function getIPFromAmazon() {
  try {
    // const data = await fetch("https://checkip.amazonaws.com/");
    // const ip = await data?.text();

    const address = await GetAsyncHelper("https://ipapi.co/json/");

    const data = address?.data;

    let apiURL =
      environment.apiUrl +
      `muvsearch/nearestairport?latitude=${data?.latitude}&longitude=${data?.longitude}`;

    const response = await GetAsyncHelper(apiURL);

    const airport = response?.data?.[0];

    return airport;
  } catch {}
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const ENKEY = "muvtravel";
export function encryptID(text) {
 try{
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(ENKEY).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
 }
 catch{
  
 }
}

export function decryptID(text) {
 try{
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(ENKEY).reduce((a, b) => a ^ b, code);
  return text
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
 }
 catch{}
}
