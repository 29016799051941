import * as Actions from '../../../../redux/actions'
import { ClockIcon, EllipsisVIcon, TimesIcon } from "../SVGBank"
import './ActivitiesForm.css'
import Activities from './Activities'
import DeleteActivityDay from './DeleteActivityDay'
import {
    MAX_ACTIVITY_IN_DAY, URL_REPLACE,
} from "../RegExValidate"
import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select'
import TimeToReach from './TimeToReach'
import { SearchPlaceTextField, SearchAddressTextField } from '../FormComponentsBank'
import PlaceDuration from "./PlaceDuration"
import throttle from 'lodash/throttle';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { InputAdornment } from '@material-ui/core'

const ActivitiesForm_ = ({
    searchAddress,
    savedTrip,
    activity,
    cancelEdit,
    destination,
    setSearchAddressSelected,
    deleteActivityToDayOfTripDestinationInTrip,
    addressSelected = searchAddress.addressSelected,
    setActiveTripDestinationId, dragDropActivityInActivitiesOfTripDestinationInTrip,
    activitiesInDestinationDay, draggingActivity, dayInTripDestination, lengthActivitiesInDestinationDay,
    lastActivity, activitiesInDestinationDayEstablishmentIds, last_establishmentId, dayInTrip, startingDate,
    activitiesInDestinationDayIds, startingDateInTrip, lengthDestinationDays,
    addActivityToDayOfTripDestinationInTrip, addCustomActivityToDayOfTripDestinationInTrip,
    updateTimelineActivity,
    activeTrip, activeDay, uuid_auth, activeDestinationId,
    draggingEstablishment, lang, switchFeatureContainer }) => {



    const destinationDuration = destination?.duration ? Number(destination?.duration) : 1;


    const [establishmentPlaceHolder, setEstablishmentPlaceHolder] = React.useState(false)
    const [activityPlaceHolder, setActivityPlaceHolder] = React.useState(false)
    const history = useHistory()

    const tripId = activeTrip.tripId
    const destinations = activeTrip.tripDestinations
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"

    const fetchData = (id, lang, destination, dayInTrip, dayInTripDestination) => {
        // if ((destinations.length === 1 && activeDestinationId !== listDestinationId) ||
        //     (destinations.length > 1 && activeDestinationId !== id)) {
        //     setDestinationId(id)
        //     fetchDestination(id, lang)
        //     fetchDestinationPhotoGallery(id, lang)
        //     fetchEstablishmentSuggestedTrips(id, lang)
        //     fetchDestinationFeaturesAndTypes(id, lang)
        //     fetchEstablishmentSortingOptions(lang)
        //     fetchEstablishmentInfluencers(id, lang)
        // }
        setActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)
        // const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + id;
        // history.push(url.toLowerCase())
    }

    const [openPlace, setOpenPlace] = React.useState(true);
    const [placeName, setPlaceName] = React.useState("")
    const [selectedPlace, setSelectedPlace] = React.useState()
    const [isCustom, setCustom] = React.useState(false)

    const AddPlace = () => {
        //console.log(`ActivitiesDay Adding a place to visit in Day ${dayInTrip}`)

        setOpenSearch("OPEN");
        setOpenPlace(!openPlace)
    }

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newTime = activity?.activityNoTime === "1" ? "0:00" : activity?.activityStartTime || "12:55 PM";


    const [startTime, setStartTime] = React.useState(newTime)

    const handleStartTime = event => {
        let starttime = event.target.value
        setStartTime(starttime)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };

    const [address, setAddress] = React.useState(activity?.activityAddress || "")

    const handleChangeAddress = event => {
        let addy = event.target.value
        setAddress(addy)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };

    const [place, setPlace] = React.useState(activity?.activityName || "")

    const handleChangePlace = event => {
        let placename = event.target.value
        setPlace(placename)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };


    const [dayInDestination, setDestinationDay] = React.useState(activity?.activityDayInDestination || "")

    const handleChangeDay = event => {
        let placename = event.target.value
        setDestinationDay(placename)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };

    const [duration, setDuration] = React.useState(activity?.activityDuration || "")

    const handleChangeDuration = event => {

        setDuration(event)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };





    // const geocoder = new window.google.maps.Geocoder();
    // const fetch = React.useMemo(
    //     () =>
    //         throttle((request, callback) => {
    //             geocoder.geocode(request, callback);
    //         }, 300),
    //     [],
    // );





    const handleSubmit = () => {


      
        let tripDestination = activeTrip.tripDestinations
        let destinationId = destination.destId
        const activeTripDestination = tripDestination.find(td => parseInt(td.destId) === parseInt(destinationId));
        //console.log(activeTripDestination)
        const activeTripDestinationId = activeTripDestination?.td_id
        //console.log(activeTripDestinationId)

        setOpenSearch("CLOSE");

        const customName = place;
        let geocoder = new window.google.maps.Geocoder();


        if(address){
            address && geocoder.geocode({
                'address': address
            }, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0] !== undefined && results[0].geometry.location.lat()
                    let longitude = results[0] !== undefined && results[0].geometry.location.lng()
    
                    try {
    
                        const address = results[0] !== undefined && results[0]?.formatted_address;
                        const addressSelected = results[0] !== undefined && results[0];
    
                        updateTimelineActivity(
                            duration,
                            customName.trim(),
                            addressSelected?.address_components[2] || customName.trim(),
                            addressSelected?.place_id,
                            latitude,
                            longitude,
                            addressSelected?.address_components[4],
                            addressSelected?.address_components[5],
                            addressSelected?.address_components[6],
                            address,
                            lang,
                            uuid,
                            parseInt(activity?.activityID),
                            parseInt(dayInDestination),
                            parseInt(tripId),
                            startTime
                        )
    
    
                        cancelEdit()
    
                    }
                    catch(error) {
                    }
    
                    setPlaceName("")
                }
            })
        }
        else{
            updateTimelineActivity(
                duration,
                customName.trim(),
                addressSelected?.address_components[2] || customName.trim(),
                addressSelected?.place_id,
                "",
                "",
                addressSelected?.address_components[4],
                addressSelected?.address_components[5],
                addressSelected?.address_components[6],
                address,
                lang,
                uuid,
                parseInt(activity?.activityID),
                parseInt(dayInDestination),
                parseInt(tripId),
                startTime
            )


            cancelEdit()  
            setPlaceName("")
        }
        

    };



    const handleDelete = () => {
        deleteActivityToDayOfTripDestinationInTrip(lang, uuid, activity?.tripDestinationId, activity?.activityID, tripId)
    }


    //const [selectedDate, setSelectedDate] = React.useState(String (new Date('2020-01-01 00:30')))
    //const handleDateChange = date => {
    //     const new_date = date === null ? new Date('2020-01-01 00:30') : date
    //     setSelectedDate(new_date)
    // }

    /*const [selectedValue, setSelectedValue] = React.useState('');
    const handleSelect = (event) => {
        const name = event.target.name;
        console.log(`handleSelect ${name}`)
    }*/


    const [checked, setChecked] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState("CLOSE")
    const [openPlaceSearch, setOpenPlaceSearch] = React.useState("CLOSE")
    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };
    const [startDragging, setDragging] = React.useState(null)

    const addressRef = React.createRef()

    return (
        <React.Fragment>
            <div style={{
                backgroundColor: "var(--mainWhite)",
            }} className="activitiesForm">

                <div style={{ border: "1px solid #E8E8E8", background: '#F8F8F8', margin: "0px", marginTop: "20px", marginBottom: "20px" }}>
                    <div onClick={cancelEdit} style={{ height: "20px", textAlign: "right", font: "15px/18px Futura Md BT", color: "#000000", marginTop: "10px", marginBottom: "40px", paddingRight: "10px" }}>
                        <TimesIcon style={{ marginTop: "15px" }} width={'15'} height={'15'} /></div>


                    <div style={{ height: "100px", border: "1px solid #E8E8E8", background: 'white', margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                        <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Place</p>

                        {/* <SearchPlaceTextField label="Search Place"
                            required={false}
                            id="search_place"
                            addressRef={addressRef}
                            setOpenSearch={setOpenPlaceSearch} placeName={activity?.activityName} setPlaceName={setPlaceName} setCustom={setCustom}
                            setSelectedPlace={setSelectedPlace} /> */}

                        <TextField
                            id="placeName"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            placeholder="Place"
                            onChange={handleChangePlace}
                            value={place}
                            fullWidth
                        />


                    </div>
                    <div style={{ height: "100px", border: "1px solid #E8E8E8", background: 'white', margin: "auto", marginTop: "20px", padding: "20px", textAlign: "left" }}>
                        <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Address</p>


                        {/* <SearchAddressTextField label="Search Address"
                            required={false}
                            id="search_destination"
                            setOpenSearch={setOpenSearch}
                            value={activity?.activityAddress}
                            addressRef={addressRef} /> */}

                        <TextField
                            id="address"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            placeholder="Address"
                            onChange={handleChangeAddress}
                            value={address}
                            fullWidth
                        />


                    </div>

                    <div style={{ height: "100px", background: 'white', border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                        <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Day in Destination</p>


                        <Select
                            native
                            fullWidth
                            value={dayInDestination}
                            onChange={handleChangeDay}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                name: 'duration',
                                id: 'duration',
                                disableUnderline: true,
                            }}
                            disableUnderline
                        >
                            <option aria-label="None" value={null} />
                            {destinationDuration && Array.apply(null, { length: destinationDuration }).map((element, index) => {
                                const value = index + 1;
                                return (
                                    <option value={value} key={index}>{value} </option>
                                )
                            })}

                        </Select>

                    </div>

                    <div style={{ height: "100px", background: 'white', border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                        <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Start time</p>
                        <TextField
                            id="time"
                            type="time"
                            InputProps={{
                                disableUnderline: true,
                                step: 300, // 5 min
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //     <ClockIcon width={16} height={16} style={{ marginBottom: "3px", marginRight: "10px" }} />
                                //     </InputAdornment>

                                // )

                            }}
                            InputAdornmentProps={{ style: { display: 'block' } }}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            placeholder="h:mm"
                            onChange={handleStartTime}
                            value={startTime}
                            fullWidth
                        />
                    </div>

                    <div style={{ height: "100px", border: "1px solid #E8E8E8", background: 'white', margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                        {/* <PlaceDuration value={activity?.activityDuration} setPlaceDuration={setPlaceDuration} /> */}


                        <PlaceDuration value={duration} setPlaceDuration={setDuration} />
                    </div>
                    <div style={{
                        font: "14px Futura Md BT", color: "var(--mainWhite)", textAlign: "center", paddingTop: "15px", height: "45px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px",
                        backgroundColor: "var(--mainGreen)", cursor: 'pointer',
                    }}
                        onClick={handleSubmit}>
                        Submit
                    </div>

                    <div style={{
                        cursor: 'pointer', font: "14px Futura Md BT", color: "var(--mainWhite)", textAlign: "center", paddingTop: "15px", height: "45px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px",
                        backgroundColor: "#7b1a1a"
                    }}
                        onClick={handleDelete}>
                        Delete Place
                    </div>

                    <div style={{ display: "flex", flexDirection: "horizontal", justifyContent: "flex-end", paddingTop: "5px", height: "40px", border: "1px solid #E8E8E8", margin: "auto" }}>
                        <div style={{ font: "14px/16px Futura Lt BT", color: "#707070", marginTop: "5px" }}>Suggest this place to Muv</div>
                        <div style={{ marginLeft: "10px" }}>
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckChange}
                                color="default"
                                inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {

        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        lang: state.Setting.lang,
        uuid_auth: state.Member.authModal.uuid,
        savedTrip: state.ActiveTrip,
        activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
        draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
        draggingEstablishment: state.FormBank.TimelineWizard.draggingEstablishment,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        searchAddress: state.FormBank.TimelineWizard.searchAddress,
        listDestinationId: state.Destination.destination.activeId,
    }
}

const mapDispatchToProps = {
    dragDropActivityInActivitiesOfTripDestinationInTrip: Actions.dragDropActivityInActivitiesOfTripDestinationInTrip,
    addActivityToDayOfTripDestinationInTrip: Actions.addActivityToDayOfTripDestinationInTrip,
    addCustomActivityToDayOfTripDestinationInTrip: Actions.addCustomActivityToDayOfTripDestinationInTrip,
    updateTimelineActivity: Actions.updateTimelineActivity,
    setDestinationId: Actions.setDestinationId,
    setSearchAddressSelected: Actions.setSearchAddressSelected,
    fetchDestination: Actions.fetchDestination,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
}

const ActivitiesForm = connect(mapStateToProps, mapDispatchToProps)(ActivitiesForm_)
export default ActivitiesForm
