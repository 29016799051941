export const monthList = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];


export const cardTypes= [
    {
        value:"VI",
        label:"Visa"
    },
    {
        value:"AX",
        label:"American Express"
    },
    {
        value:"MC",
        label:"Master Card"
    },
    {
        value:"DS",
        label:"Discover"
    }
]
export const countriesList = [{
    "name": "Canada",
    "code": "CA",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"],
    "province_labels": {
        "Alberta": "Alberta",
        "British Columbia": "British Columbia",
        "Manitoba": "Manitoba",
        "New Brunswick": "New Brunswick",
        "Newfoundland": "Newfoundland and Labrador",
        "Northwest Territories": "Northwest Territories",
        "Nova Scotia": "Nova Scotia",
        "Nunavut": "Nunavut",
        "Ontario": "Ontario",
        "Prince Edward Island": "Prince Edward Island",
        "Quebec": "Quebec",
        "Saskatchewan": "Saskatchewan",
        "Yukon": "Yukon"
    },
    "province_codes": {
        "Alberta": "AB",
        "British Columbia": "BC",
        "Manitoba": "MB",
        "New Brunswick": "NB",
        "Newfoundland": "NL",
        "Northwest Territories": "NT",
        "Nova Scotia": "NS",
        "Nunavut": "NU",
        "Ontario": "ON",
        "Prince Edward Island": "PE",
        "Quebec": "QC",
        "Saskatchewan": "SK",
        "Yukon": "YT"
    },
    "province_alternate_names": {
        "Alberta": null,
        "British Columbia": null,
        "Manitoba": null,
        "New Brunswick": null,
        "Newfoundland": ["Newfoundland and Labrador"],
        "Northwest Territories": null,
        "Nova Scotia": null,
        "Nunavut": null,
        "Ontario": null,
        "Prince Edward Island": null,
        "Quebec": ["Québec"],
        "Saskatchewan": null,
        "Yukon": null
    }
},
{
    "name": "United States",
    "code": "US",
    "label": "State",
    "zip_label": "ZIP code",
    "zip_required": true,
    "province_label": "State",
    "provinces": ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District of Columbia", "Federated States of Micronesia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Marshall Islands", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", "Oklahoma", "Oregon", "Palau", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming", "Virgin Islands", "Armed Forces Americas", "Armed Forces Europe", "Armed Forces Pacific"],
    "province_labels": {
        "Alabama": "Alabama",
        "Alaska": "Alaska",
        "American Samoa": "American Samoa",
        "Arizona": "Arizona",
        "Arkansas": "Arkansas",
        "California": "California",
        "Colorado": "Colorado",
        "Connecticut": "Connecticut",
        "Delaware": "Delaware",
        "District of Columbia": "District of Columbia",
        "Federated States of Micronesia": "Federated States of Micronesia",
        "Florida": "Florida",
        "Georgia": "Georgia",
        "Guam": "Guam",
        "Hawaii": "Hawaii",
        "Idaho": "Idaho",
        "Illinois": "Illinois",
        "Indiana": "Indiana",
        "Iowa": "Iowa",
        "Kansas": "Kansas",
        "Kentucky": "Kentucky",
        "Louisiana": "Louisiana",
        "Maine": "Maine",
        "Marshall Islands": "Marshall Islands",
        "Maryland": "Maryland",
        "Massachusetts": "Massachusetts",
        "Michigan": "Michigan",
        "Minnesota": "Minnesota",
        "Mississippi": "Mississippi",
        "Missouri": "Missouri",
        "Montana": "Montana",
        "Nebraska": "Nebraska",
        "Nevada": "Nevada",
        "New Hampshire": "New Hampshire",
        "New Jersey": "New Jersey",
        "New Mexico": "New Mexico",
        "New York": "New York",
        "North Carolina": "North Carolina",
        "North Dakota": "North Dakota",
        "Northern Mariana Islands": "Northern Mariana Islands",
        "Ohio": "Ohio",
        "Oklahoma": "Oklahoma",
        "Oregon": "Oregon",
        "Palau": "Palau",
        "Pennsylvania": "Pennsylvania",
        "Puerto Rico": "Puerto Rico",
        "Rhode Island": "Rhode Island",
        "South Carolina": "South Carolina",
        "South Dakota": "South Dakota",
        "Tennessee": "Tennessee",
        "Texas": "Texas",
        "Utah": "Utah",
        "Vermont": "Vermont",
        "Virgin Islands": "Virgin Islands",
        "Virginia": "Virginia",
        "Washington": "Washington",
        "West Virginia": "West Virginia",
        "Wisconsin": "Wisconsin",
        "Wyoming": "Wyoming",
        "Armed Forces Americas": "Armed Forces Americas",
        "Armed Forces Europe": "Armed Forces Europe",
        "Armed Forces Pacific": "Armed Forces Pacific"
    },
    "province_codes": {
        "Alabama": "AL",
        "Alaska": "AK",
        "American Samoa": "AS",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "District of Columbia": "DC",
        "Federated States of Micronesia": "FM",
        "Florida": "FL",
        "Georgia": "GA",
        "Guam": "GU",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Marshall Islands": "MH",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Northern Mariana Islands": "MP",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Palau": "PW",
        "Pennsylvania": "PA",
        "Puerto Rico": "PR",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY",
        "Virgin Islands": "VI",
        "Armed Forces Americas": "AA",
        "Armed Forces Europe": "AE",
        "Armed Forces Pacific": "AP"
    },
    "province_alternate_names": {
        "Alabama": null,
        "Alaska": null,
        "American Samoa": null,
        "Arizona": null,
        "Arkansas": null,
        "California": null,
        "Colorado": null,
        "Connecticut": null,
        "Delaware": null,
        "District of Columbia": null,
        "Federated States of Micronesia": null,
        "Florida": null,
        "Georgia": null,
        "Guam": null,
        "Hawaii": null,
        "Idaho": null,
        "Illinois": null,
        "Indiana": null,
        "Iowa": null,
        "Kansas": null,
        "Kentucky": null,
        "Louisiana": null,
        "Maine": null,
        "Marshall Islands": null,
        "Maryland": null,
        "Massachusetts": null,
        "Michigan": null,
        "Minnesota": null,
        "Mississippi": null,
        "Missouri": null,
        "Montana": null,
        "Nebraska": null,
        "Nevada": null,
        "New Hampshire": null,
        "New Jersey": null,
        "New Mexico": null,
        "New York": null,
        "North Carolina": null,
        "North Dakota": null,
        "Northern Mariana Islands": null,
        "Ohio": null,
        "Oklahoma": null,
        "Oregon": null,
        "Palau": null,
        "Pennsylvania": null,
        "Puerto Rico": null,
        "Rhode Island": null,
        "South Carolina": null,
        "South Dakota": null,
        "Tennessee": null,
        "Texas": null,
        "Utah": null,
        "Vermont": null,
        "Virginia": null,
        "Washington": null,
        "West Virginia": null,
        "Wisconsin": null,
        "Wyoming": null,
        "Virgin Islands": null,
        "Armed Forces Americas": null,
        "Armed Forces Europe": null,
        "Armed Forces Pacific": null
    }
},
{
    "name": "Afghanistan",
    "code": "AF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Aland Islands",
    "code": "AX",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Albania",
    "code": "AL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Algeria",
    "code": "DZ",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Andorra",
    "code": "AD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Angola",
    "code": "AO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Anguilla",
    "code": "AI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Antigua And Barbuda",
    "code": "AG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Argentina",
    "code": "AR",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Buenos Aires", "Catamarca", "Chaco", "Chubut", "Ciudad Autónoma de Buenos Aires", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago Del Estero", "Tierra Del Fuego", "Tucumán"],
    "province_labels": {
        "Buenos Aires": "Buenos Aires",
        "Catamarca": "Catamarca",
        "Chaco": "Chaco",
        "Chubut": "Chubut",
        "Ciudad Autónoma de Buenos Aires": "Ciudad Autónoma de Buenos Aires",
        "Córdoba": "Córdoba",
        "Corrientes": "Corrientes",
        "Entre Ríos": "Entre Ríos",
        "Formosa": "Formosa",
        "Jujuy": "Jujuy",
        "La Pampa": "La Pampa",
        "La Rioja": "La Rioja",
        "Mendoza": "Mendoza",
        "Misiones": "Misiones",
        "Neuquén": "Neuquén",
        "Río Negro": "Río Negro",
        "Salta": "Salta",
        "San Juan": "San Juan",
        "San Luis": "San Luis",
        "Santa Cruz": "Santa Cruz",
        "Santa Fe": "Santa Fe",
        "Santiago Del Estero": "Santiago Del Estero",
        "Tierra Del Fuego": "Tierra Del Fuego",
        "Tucumán": "Tucumán"
    },
    "province_codes": {
        "Buenos Aires": "B",
        "Catamarca": "K",
        "Chaco": "H",
        "Chubut": "U",
        "Ciudad Autónoma de Buenos Aires": "C",
        "Córdoba": "X",
        "Corrientes": "W",
        "Entre Ríos": "E",
        "Formosa": "P",
        "Jujuy": "Y",
        "La Pampa": "L",
        "La Rioja": "F",
        "Mendoza": "M",
        "Misiones": "N",
        "Neuquén": "Q",
        "Río Negro": "R",
        "Salta": "A",
        "San Juan": "J",
        "San Luis": "D",
        "Santa Cruz": "Z",
        "Santa Fe": "S",
        "Santiago Del Estero": "G",
        "Tierra Del Fuego": "V",
        "Tucumán": "T"
    },
    "province_alternate_names": {
        "Buenos Aires": null,
        "Catamarca": null,
        "Chaco": null,
        "Chubut": null,
        "Ciudad Autónoma de Buenos Aires": ["Ciudad Autonoma de Buenos Aires"],
        "Córdoba": ["Cordoba"],
        "Corrientes": null,
        "Entre Ríos": ["Entre Rios"],
        "Formosa": null,
        "Jujuy": null,
        "La Pampa": null,
        "La Rioja": null,
        "Mendoza": null,
        "Misiones": null,
        "Neuquén": ["Neuquen"],
        "Río Negro": ["Rio Negro"],
        "Salta": null,
        "San Juan": null,
        "San Luis": null,
        "Santa Cruz": null,
        "Santa Fe": null,
        "Santiago Del Estero": null,
        "Tierra Del Fuego": null,
        "Tucumán": ["Tucuman"]
    }
},
{
    "name": "Armenia",
    "code": "AM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Aruba",
    "code": "AW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Australia",
    "code": "AU",
    "label": "State\/territory",
    "zip_label": "Postcode",
    "zip_required": true,
    "province_label": "State\/territory",
    "provinces": ["Australian Capital Territory", "New South Wales", "Northern Territory", "Queensland", "South Australia", "Tasmania", "Victoria", "Western Australia"],
    "province_labels": {
        "Australian Capital Territory": "Australian Capital Territory",
        "New South Wales": "New South Wales",
        "Northern Territory": "Northern Territory",
        "Queensland": "Queensland",
        "South Australia": "South Australia",
        "Tasmania": "Tasmania",
        "Victoria": "Victoria",
        "Western Australia": "Western Australia"
    },
    "province_codes": {
        "Australian Capital Territory": "ACT",
        "New South Wales": "NSW",
        "Northern Territory": "NT",
        "Queensland": "QLD",
        "South Australia": "SA",
        "Tasmania": "TAS",
        "Victoria": "VIC",
        "Western Australia": "WA"
    },
    "province_alternate_names": {
        "Australian Capital Territory": null,
        "New South Wales": null,
        "Northern Territory": null,
        "Queensland": null,
        "South Australia": null,
        "Tasmania": null,
        "Victoria": null,
        "Western Australia": null
    }
},
{
    "name": "Austria",
    "code": "AT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Azerbaijan",
    "code": "AZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bahamas",
    "code": "BS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bahrain",
    "code": "BH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bangladesh",
    "code": "BD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Barbados",
    "code": "BB",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Belarus",
    "code": "BY",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Belgium",
    "code": "BE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Belize",
    "code": "BZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Benin",
    "code": "BJ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bermuda",
    "code": "BM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bhutan",
    "code": "BT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bolivia",
    "code": "BO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bonaire, Sint Eustatius and Saba",
    "code": "BQ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bosnia And Herzegovina",
    "code": "BA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Botswana",
    "code": "BW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bouvet Island",
    "code": "BV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Brazil",
    "code": "BR",
    "label": "State",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "State",
    "provinces": ["Acre", "Alagoas", "Amapá", "Amazonas", "Bahia", "Ceará", "Distrito Federal", "Espírito Santo", "Goiás", "Maranhão", "Mato Grosso", "Mato Grosso do Sul", "Minas Gerais", "Pará", "Paraíba", "Paraná", "Pernambuco", "Piauí", "Rio Grande do Norte", "Rio Grande do Sul", "Rio de Janeiro", "Rondônia", "Roraima", "Santa Catarina", "São Paulo", "Sergipe", "Tocantins"],
    "province_labels": {
        "Acre": "Acre",
        "Alagoas": "Alagoas",
        "Amapá": "Amapá",
        "Amazonas": "Amazonas",
        "Bahia": "Bahia",
        "Ceará": "Ceará",
        "Distrito Federal": "Distrito Federal",
        "Espírito Santo": "Espírito Santo",
        "Goiás": "Goiás",
        "Maranhão": "Maranhão",
        "Mato Grosso": "Mato Grosso",
        "Mato Grosso do Sul": "Mato Grosso do Sul",
        "Minas Gerais": "Minas Gerais",
        "Pará": "Pará",
        "Paraíba": "Paraíba",
        "Paraná": "Paraná",
        "Pernambuco": "Pernambuco",
        "Piauí": "Piauí",
        "Rio Grande do Norte": "Rio Grande do Norte",
        "Rio Grande do Sul": "Rio Grande do Sul",
        "Rio de Janeiro": "Rio de Janeiro",
        "Rondônia": "Rondônia",
        "Roraima": "Roraima",
        "Santa Catarina": "Santa Catarina",
        "São Paulo": "São Paulo",
        "Sergipe": "Sergipe",
        "Tocantins": "Tocantins"
    },
    "province_codes": {
        "Acre": "AC",
        "Alagoas": "AL",
        "Amapá": "AP",
        "Amazonas": "AM",
        "Bahia": "BA",
        "Ceará": "CE",
        "Distrito Federal": "DF",
        "Espírito Santo": "ES",
        "Goiás": "GO",
        "Maranhão": "MA",
        "Mato Grosso": "MT",
        "Mato Grosso do Sul": "MS",
        "Minas Gerais": "MG",
        "Pará": "PA",
        "Paraíba": "PB",
        "Paraná": "PR",
        "Pernambuco": "PE",
        "Piauí": "PI",
        "Rio Grande do Norte": "RN",
        "Rio Grande do Sul": "RS",
        "Rio de Janeiro": "RJ",
        "Rondônia": "RO",
        "Roraima": "RR",
        "Santa Catarina": "SC",
        "São Paulo": "SP",
        "Sergipe": "SE",
        "Tocantins": "TO"
    },
    "province_alternate_names": {
        "Acre": null,
        "Alagoas": null,
        "Amapá": ["Amapa"],
        "Amazonas": null,
        "Bahia": null,
        "Ceará": ["Ceara"],
        "Distrito Federal": null,
        "Espírito Santo": ["Espirito Santo"],
        "Goiás": ["Goias"],
        "Maranhão": ["Maranhao"],
        "Mato Grosso": null,
        "Mato Grosso do Sul": null,
        "Minas Gerais": null,
        "Pará": ["Para"],
        "Paraíba": ["Paraiba"],
        "Paraná": ["Parana"],
        "Pernambuco": null,
        "Piauí": ["Piaui"],
        "Rio Grande do Norte": null,
        "Rio Grande do Sul": null,
        "Rio de Janeiro": null,
        "Rondônia": ["Rondonia"],
        "Roraima": null,
        "Santa Catarina": null,
        "São Paulo": ["Sao Paulo"],
        "Sergipe": null,
        "Tocantins": null
    }
},
{
    "name": "British Indian Ocean Territory",
    "code": "IO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Brunei",
    "code": "BN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Bulgaria",
    "code": "BG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Burkina Faso",
    "code": "BF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Burundi",
    "code": "BI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cambodia",
    "code": "KH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Canada",
    "code": "CA",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"],
    "province_labels": {
        "Alberta": "Alberta",
        "British Columbia": "British Columbia",
        "Manitoba": "Manitoba",
        "New Brunswick": "New Brunswick",
        "Newfoundland": "Newfoundland and Labrador",
        "Northwest Territories": "Northwest Territories",
        "Nova Scotia": "Nova Scotia",
        "Nunavut": "Nunavut",
        "Ontario": "Ontario",
        "Prince Edward Island": "Prince Edward Island",
        "Quebec": "Quebec",
        "Saskatchewan": "Saskatchewan",
        "Yukon": "Yukon"
    },
    "province_codes": {
        "Alberta": "AB",
        "British Columbia": "BC",
        "Manitoba": "MB",
        "New Brunswick": "NB",
        "Newfoundland": "NL",
        "Northwest Territories": "NT",
        "Nova Scotia": "NS",
        "Nunavut": "NU",
        "Ontario": "ON",
        "Prince Edward Island": "PE",
        "Quebec": "QC",
        "Saskatchewan": "SK",
        "Yukon": "YT"
    },
    "province_alternate_names": {
        "Alberta": null,
        "British Columbia": null,
        "Manitoba": null,
        "New Brunswick": null,
        "Newfoundland": ["Newfoundland and Labrador"],
        "Northwest Territories": null,
        "Nova Scotia": null,
        "Nunavut": null,
        "Ontario": null,
        "Prince Edward Island": null,
        "Quebec": ["Québec"],
        "Saskatchewan": null,
        "Yukon": null
    }
},
{
    "name": "Cape Verde",
    "code": "CV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cayman Islands",
    "code": "KY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Central African Republic",
    "code": "CF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Chad",
    "code": "TD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Chile",
    "code": "CL",
    "label": "State",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "State",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "China",
    "code": "CN",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Anhui", "Beijing", "Chongqing", "Fujian", "Gansu", "Guangdong", "Guangxi", "Guizhou", "Hainan", "Hebei", "Heilongjiang", "Henan", "Hubei", "Hunan", "Inner Mongolia", "Jiangsu", "Jiangxi", "Jilin", "Liaoning", "Ningxia", "Qinghai", "Shaanxi", "Shandong", "Shanghai", "Shanxi", "Sichuan", "Tianjin", "Xinjiang", "Xizang", "Yunnan", "Zhejiang"],
    "province_labels": {
        "Anhui": "Anhui",
        "Beijing": "Beijing",
        "Chongqing": "Chongqing",
        "Fujian": "Fujian",
        "Gansu": "Gansu",
        "Guangdong": "Guangdong",
        "Guangxi": "Guangxi",
        "Guizhou": "Guizhou",
        "Hainan": "Hainan",
        "Hebei": "Hebei",
        "Heilongjiang": "Heilongjiang",
        "Henan": "Henan",
        "Hubei": "Hubei",
        "Hunan": "Hunan",
        "Inner Mongolia": "Inner Mongolia",
        "Jiangsu": "Jiangsu",
        "Jiangxi": "Jiangxi",
        "Jilin": "Jilin",
        "Liaoning": "Liaoning",
        "Ningxia": "Ningxia",
        "Qinghai": "Qinghai",
        "Shaanxi": "Shaanxi",
        "Shandong": "Shandong",
        "Shanghai": "Shanghai",
        "Shanxi": "Shanxi",
        "Sichuan": "Sichuan",
        "Tianjin": "Tianjin",
        "Xinjiang": "Xinjiang",
        "Xizang": "Xizang",
        "Yunnan": "Yunnan",
        "Zhejiang": "Zhejiang"
    },
    "province_codes": {
        "Anhui": "AH",
        "Beijing": "BJ",
        "Chongqing": "CQ",
        "Fujian": "FJ",
        "Gansu": "GS",
        "Guangdong": "GD",
        "Guangxi": "GX",
        "Guizhou": "GZ",
        "Hainan": "HI",
        "Hebei": "HE",
        "Heilongjiang": "HL",
        "Henan": "HA",
        "Hubei": "HB",
        "Hunan": "HN",
        "Inner Mongolia": "NM",
        "Jiangsu": "JS",
        "Jiangxi": "JX",
        "Jilin": "JL",
        "Liaoning": "LN",
        "Ningxia": "NX",
        "Qinghai": "QH",
        "Shaanxi": "SN",
        "Shandong": "SD",
        "Shanghai": "SH",
        "Shanxi": "SX",
        "Sichuan": "SC",
        "Tianjin": "TJ",
        "Xinjiang": "XJ",
        "Xizang": "YZ",
        "Yunnan": "YN",
        "Zhejiang": "ZJ"
    },
    "province_alternate_names": {
        "Anhui": ["安徽"],
        "Beijing": ["北京"],
        "Chongqing": ["重庆"],
        "Fujian": ["福建"],
        "Gansu": ["甘肃"],
        "Guangdong": ["广东"],
        "Guangxi": ["广西"],
        "Guizhou": ["贵州"],
        "Hainan": ["海南"],
        "Hebei": ["河北"],
        "Heilongjiang": ["黑龙江"],
        "Henan": ["河南"],
        "Hubei": ["湖北"],
        "Hunan": ["湖南"],
        "Inner Mongolia": ["内蒙古", "Nei Mongol"],
        "Jiangsu": ["江苏"],
        "Jiangxi": ["江西"],
        "Jilin": ["吉林"],
        "Liaoning": ["辽宁"],
        "Ningxia": ["宁夏"],
        "Qinghai": ["青海"],
        "Shaanxi": ["陕西"],
        "Shandong": ["山东"],
        "Shanghai": ["上海"],
        "Shanxi": ["山西"],
        "Sichuan": ["四川"],
        "Tianjin": ["天津"],
        "Xinjiang": ["新疆"],
        "Xizang": ["西藏", "Tibet"],
        "Yunnan": ["云南"],
        "Zhejiang": ["浙江"]
    }
},
{
    "name": "Christmas Island",
    "code": "CX",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cocos (Keeling) Islands",
    "code": "CC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Colombia",
    "code": "CO",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Amazonas", "Antioquia", "Arauca", "Atlántico", "Bogotá, D.C.", "Bolívar", "Boyacá", "Caldas", "Caquetá", "Casanare", "Cauca", "Cesar", "Chocó", "Córdoba", "Cundinamarca", "Guainía", "Guaviare", "Huila", "La Guajira", "Magdalena", "Meta", "Nariño", "Norte de Santander", "Putumayo", "Quindío", "Risaralda", "San Andrés, Providencia y Santa Catalina", "Santander", "Sucre", "Tolima", "Valle del Cauca", "Vaupés", "Vichada"],
    "province_labels": {
        "Bogotá, D.C.": "Bogotá, D.C.",
        "Amazonas": "Amazonas",
        "Antioquia": "Antioquia",
        "Arauca": "Arauca",
        "Atlántico": "Atlántico",
        "Bolívar": "Bolívar",
        "Boyacá": "Boyacá",
        "Caldas": "Caldas",
        "Caquetá": "Caquetá",
        "Casanare": "Casanare",
        "Cauca": "Cauca",
        "Cesar": "Cesar",
        "Chocó": "Chocó",
        "Córdoba": "Córdoba",
        "Cundinamarca": "Cundinamarca",
        "Guainía": "Guainía",
        "Guaviare": "Guaviare",
        "Huila": "Huila",
        "La Guajira": "La Guajira",
        "Magdalena": "Magdalena",
        "Meta": "Meta",
        "Nariño": "Nariño",
        "Norte de Santander": "Norte de Santander",
        "Putumayo": "Putumayo",
        "Quindío": "Quindío",
        "Risaralda": "Risaralda",
        "San Andrés, Providencia y Santa Catalina": "San Andrés, Providencia y Santa Catalina",
        "Santander": "Santander",
        "Sucre": "Sucre",
        "Tolima": "Tolima",
        "Valle del Cauca": "Valle del Cauca",
        "Vaupés": "Vaupés",
        "Vichada": "Vichada"
    },
    "province_codes": {
        "Amazonas": "AMA",
        "Antioquia": "ANT",
        "Arauca": "ARA",
        "Atlántico": "ATL",
        "Bogotá, D.C.": "DC",
        "Bolívar": "BOL",
        "Boyacá": "BOY",
        "Caldas": "CAL",
        "Caquetá": "CAQ",
        "Casanare": "CAS",
        "Cauca": "CAU",
        "Cesar": "CES",
        "Chocó": "CHO",
        "Córdoba": "COR",
        "Cundinamarca": "CUN",
        "Guainía": "GUA",
        "Guaviare": "GUV",
        "Huila": "HUI",
        "La Guajira": "LAG",
        "Magdalena": "MAG",
        "Meta": "MET",
        "Nariño": "NAR",
        "Norte de Santander": "NSA",
        "Putumayo": "PUT",
        "Quindío": "QUI",
        "Risaralda": "RIS",
        "San Andrés, Providencia y Santa Catalina": "SAP",
        "Santander": "SAN",
        "Sucre": "SUC",
        "Tolima": "TOL",
        "Valle del Cauca": "VAC",
        "Vaupés": "VAU",
        "Vichada": "VID"
    },
    "province_alternate_names": {
        "Amazonas": null,
        "Antioquia": null,
        "Arauca": null,
        "Atlántico": ["Atlantico"],
        "Bogotá, D.C.": ["Bogota, D.C.", "Bogotá", "Bogota", "Capital District", "Distrito Capital de Bogotá", "Distrito Capital de Bogota"],
        "Bolívar": ["Bolivar"],
        "Boyacá": ["Boyaca"],
        "Caldas": null,
        "Caquetá": ["Caqueta"],
        "Casanare": null,
        "Cauca": null,
        "Cesar": null,
        "Chocó": ["Choco"],
        "Córdoba": ["Cordoba"],
        "Cundinamarca": null,
        "Guainía": ["Guainia"],
        "Guaviare": null,
        "Huila": null,
        "La Guajira": null,
        "Magdalena": null,
        "Meta": null,
        "Nariño": ["Narino"],
        "Norte de Santander": null,
        "Putumayo": null,
        "Quindío": ["Quindio"],
        "Risaralda": null,
        "San Andrés, Providencia y Santa Catalina": ["San Andres, Providencia y Santa Catalina", "San Andrés y Providencia", "San Andres y Providencia"],
        "Santander": null,
        "Sucre": null,
        "Tolima": null,
        "Valle del Cauca": null,
        "Vaupés": ["Vaupes"],
        "Vichada": null
    }
},
{
    "name": "Comoros",
    "code": "KM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Congo",
    "code": "CG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Congo, The Democratic Republic Of The",
    "code": "CD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cook Islands",
    "code": "CK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Costa Rica",
    "code": "CR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Croatia",
    "code": "HR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cuba",
    "code": "CU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Curaçao",
    "code": "CW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Cyprus",
    "code": "CY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Czech Republic",
    "code": "CZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Côte d'Ivoire",
    "code": "CI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Denmark",
    "code": "DK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Djibouti",
    "code": "DJ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Dominica",
    "code": "DM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Dominican Republic",
    "code": "DO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Ecuador",
    "code": "EC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Egypt",
    "code": "EG",
    "label": "Governorate",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Governorate",
    "provinces": ["6th of October", "Al Sharqia", "Alexandria", "Aswan", "Asyut", "Beheira", "Beni Suef", "Cairo", "Dakahlia", "Damietta", "Faiyum", "Gharbia", "Giza", "Helwan", "Ismailia", "Kafr el-Sheikh", "Luxor", "Matrouh", "Minya", "Monufia", "New Valley", "North Sinai", "Port Said", "Qalyubia", "Qena", "Red Sea", "Sohag", "South Sinai", "Suez"],
    "province_labels": {
        "6th of October": "6th of October",
        "Al Sharqia": "Al Sharqia",
        "Alexandria": "Alexandria",
        "Aswan": "Aswan",
        "Asyut": "Asyut",
        "Beheira": "Beheira",
        "Beni Suef": "Beni Suef",
        "Cairo": "Cairo",
        "Dakahlia": "Dakahlia",
        "Damietta": "Damietta",
        "Faiyum": "Faiyum",
        "Gharbia": "Gharbia",
        "Giza": "Giza",
        "Helwan": "Helwan",
        "Ismailia": "Ismailia",
        "Kafr el-Sheikh": "Kafr el-Sheikh",
        "Luxor": "Luxor",
        "Matrouh": "Matrouh",
        "Minya": "Minya",
        "Monufia": "Monufia",
        "New Valley": "New Valley",
        "North Sinai": "North Sinai",
        "Port Said": "Port Said",
        "Qalyubia": "Qalyubia",
        "Qena": "Qena",
        "Red Sea": "Red Sea",
        "Sohag": "Sohag",
        "South Sinai": "South Sinai",
        "Suez": "Suez"
    },
    "province_codes": {
        "6th of October": "SU",
        "Al Sharqia": "SHR",
        "Alexandria": "ALX",
        "Aswan": "ASN",
        "Asyut": "AST",
        "Beheira": "BH",
        "Beni Suef": "BNS",
        "Cairo": "C",
        "Dakahlia": "DK",
        "Damietta": "DT",
        "Faiyum": "FYM",
        "Gharbia": "GH",
        "Giza": "GZ",
        "Helwan": "HU",
        "Ismailia": "IS",
        "Kafr el-Sheikh": "KFS",
        "Luxor": "LX",
        "Matrouh": "MT",
        "Minya": "MN",
        "Monufia": "MNF",
        "New Valley": "WAD",
        "North Sinai": "SIN",
        "Port Said": "PTS",
        "Qalyubia": "KB",
        "Qena": "KN",
        "Red Sea": "BA",
        "Sohag": "SHG",
        "South Sinai": "JS",
        "Suez": "SUZ"
    },
    "province_alternate_names": {
        "6th of October": ["As Sādis min Uktūbar", "As Sadis min Uktubar"],
        "Al Sharqia": ["Ash Sharqīyah", "Ash Sharqiyah"],
        "Alexandria": ["Al Iskandarīyah", "Al Iskandariyah"],
        "Aswan": ["Aswān"],
        "Asyut": ["Asyūţ"],
        "Beheira": ["Al Buḩayrah", "Al Buayrah"],
        "Beni Suef": ["Banī Suwayf", "Bani Suwayf"],
        "Cairo": ["Al Qāhirah", "Al Qahirah"],
        "Dakahlia": ["Ad Daqahlīyah", "Ad Daqahliyah"],
        "Damietta": ["Dumyāţ", "Dumyat"],
        "Faiyum": ["Al Fayyūm", "Al Fayyum"],
        "Gharbia": ["Al Gharbīyah", "Al Gharbiyah"],
        "Giza": ["Al Jīzah", "Al Jizah"],
        "Helwan": ["Ḩulwān", "ulwan"],
        "Ismailia": ["Al Ismāٰīlīyah", "Al Ismailiyah"],
        "Kafr el-Sheikh": ["Kafr ash Shaykh"],
        "Luxor": ["Al Uqşur", "Al Uqsur"],
        "Matrouh": ["Maţrūḩ", "Matru"],
        "Minya": ["Al Minyā", "Al Minya"],
        "Monufia": ["Al Minūfīyah", "Al Minufiyah"],
        "New Valley": ["Al Wādī al Jadīd", "Al Wadi al Jadid"],
        "North Sinai": ["Shamāl Sīnā", "Shamal Sina"],
        "Port Said": ["Būr Saٰīd", "Bur Said"],
        "Qalyubia": ["Al Qalyūbīyah", "Al Qalyubiyah"],
        "Qena": ["Qinā", "Qina"],
        "Red Sea": ["Al Baḩr al Aḩmar", "Al Bar al Amar"],
        "Sohag": ["Sūhāj", "Suhaj"],
        "South Sinai": ["Janūb Sīnā", "Janub Sina"],
        "Suez": ["As Suways"]
    }
},
{
    "name": "El Salvador",
    "code": "SV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Equatorial Guinea",
    "code": "GQ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Eritrea",
    "code": "ER",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Estonia",
    "code": "EE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Ethiopia",
    "code": "ET",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Falkland Islands (Malvinas)",
    "code": "FK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Faroe Islands",
    "code": "FO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Fiji",
    "code": "FJ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Finland",
    "code": "FI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "France",
    "code": "FR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "French Guiana",
    "code": "GF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "French Polynesia",
    "code": "PF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "French Southern Territories",
    "code": "TF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Gabon",
    "code": "GA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Gambia",
    "code": "GM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Georgia",
    "code": "GE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Germany",
    "code": "DE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Ghana",
    "code": "GH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Gibraltar",
    "code": "GI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Greece",
    "code": "GR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Greenland",
    "code": "GL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Grenada",
    "code": "GD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Guadeloupe",
    "code": "GP",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Guatemala",
    "code": "GT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": ["Alta Verapaz", "Baja Verapaz", "Chimaltenango", "Chiquimula", "El Progreso", "Escuintla", "Guatemala", "Huehuetenango", "Izabal", "Jalapa", "Jutiapa", "Petén", "Quetzaltenango", "Quiché", "Retalhuleu", "Sacatepéquez", "San Marcos", "Santa Rosa", "Sololá", "Suchitepéquez", "Totonicapán", "Zacapa"],
    "province_labels": {
        "Alta Verapaz": "Alta Verapaz",
        "Baja Verapaz": "Baja Verapaz",
        "Chimaltenango": "Chimaltenango",
        "Chiquimula": "Chiquimula",
        "El Progreso": "El Progreso",
        "Escuintla": "Escuintla",
        "Guatemala": "Guatemala",
        "Huehuetenango": "Huehuetenango",
        "Izabal": "Izabal",
        "Jalapa": "Jalapa",
        "Jutiapa": "Jutiapa",
        "Petén": "Petén",
        "Quetzaltenango": "Quetzaltenango",
        "Quiché": "Quiché",
        "Retalhuleu": "Retalhuleu",
        "Sacatepéquez": "Sacatepéquez",
        "San Marcos": "San Marcos",
        "Santa Rosa": "Santa Rosa",
        "Sololá": "Sololá",
        "Suchitepéquez": "Suchitepéquez",
        "Totonicapán": "Totonicapán",
        "Zacapa": "Zacapa"
    },
    "province_codes": {
        "Alta Verapaz": "AVE",
        "Baja Verapaz": "BVE",
        "Chimaltenango": "CMT",
        "Chiquimula": "CQM",
        "El Progreso": "EPR",
        "Escuintla": "ESC",
        "Guatemala": "GUA",
        "Huehuetenango": "HUE",
        "Izabal": "IZA",
        "Jalapa": "JAL",
        "Jutiapa": "JUT",
        "Petén": "PET",
        "Quetzaltenango": "QUE",
        "Quiché": "QUI",
        "Retalhuleu": "RET",
        "Sacatepéquez": "SAC",
        "San Marcos": "SMA",
        "Santa Rosa": "SRO",
        "Sololá": "SOL",
        "Suchitepéquez": "SUC",
        "Totonicapán": "TOT",
        "Zacapa": "ZAC"
    },
    "province_alternate_names": {
        "Alta Verapaz": null,
        "Baja Verapaz": null,
        "Chimaltenango": null,
        "Chiquimula": null,
        "El Progreso": null,
        "Escuintla": null,
        "Guatemala": null,
        "Huehuetenango": null,
        "Izabal": null,
        "Jalapa": null,
        "Jutiapa": null,
        "Petén": ["Peten"],
        "Quetzaltenango": null,
        "Quiché": ["Quiche"],
        "Retalhuleu": null,
        "Sacatepéquez": ["Sacatepequez"],
        "San Marcos": null,
        "Santa Rosa": null,
        "Sololá": ["Solola"],
        "Suchitepéquez": ["Suchitepequez"],
        "Totonicapán": ["Totonicapan"],
        "Zacapa": null
    }
},
{
    "name": "Guernsey",
    "code": "GG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Guinea",
    "code": "GN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Guinea Bissau",
    "code": "GW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Guyana",
    "code": "GY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Haiti",
    "code": "HT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Heard Island And Mcdonald Islands",
    "code": "HM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Holy See (Vatican City State)",
    "code": "VA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Honduras",
    "code": "HN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Hong Kong",
    "code": "HK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": ["Hong Kong Island", "Kowloon", "New Territories"],
    "province_labels": {
        "Hong Kong Island": "Hong Kong Island",
        "Kowloon": "Kowloon",
        "New Territories": "New Territories"
    },
    "province_codes": {
        "Hong Kong Island": "HK",
        "Kowloon": "KL",
        "New Territories": "NT"
    },
    "province_alternate_names": {
        "Hong Kong Island": ["Hong Kong Province", "Hong Kong", "香港"],
        "Kowloon": ["九龍"],
        "New Territories": ["新界"]
    }
},
{
    "name": "Hungary",
    "code": "HU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Iceland",
    "code": "IS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "India",
    "code": "IN",
    "label": "State",
    "zip_label": "Pincode",
    "zip_required": true,
    "province_label": "State",
    "provinces": ["Andaman and Nicobar", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Orissa", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"],
    "province_labels": {
        "Andaman and Nicobar": "Andaman and Nicobar",
        "Andhra Pradesh": "Andhra Pradesh",
        "Arunachal Pradesh": "Arunachal Pradesh",
        "Assam": "Assam",
        "Bihar": "Bihar",
        "Chandigarh": "Chandigarh",
        "Chattisgarh": "Chattisgarh",
        "Dadra and Nagar Haveli": "Dadra and Nagar Haveli",
        "Daman and Diu": "Daman and Diu",
        "Delhi": "Delhi",
        "Goa": "Goa",
        "Gujarat": "Gujarat",
        "Haryana": "Haryana",
        "Himachal Pradesh": "Himachal Pradesh",
        "Jammu and Kashmir": "Jammu and Kashmir",
        "Jharkhand": "Jharkhand",
        "Karnataka": "Karnataka",
        "Kerala": "Kerala",
        "Lakshadweep": "Lakshadweep",
        "Madhya Pradesh": "Madhya Pradesh",
        "Maharashtra": "Maharashtra",
        "Manipur": "Manipur",
        "Meghalaya": "Meghalaya",
        "Mizoram": "Mizoram",
        "Nagaland": "Nagaland",
        "Orissa": "Orissa",
        "Puducherry": "Puducherry",
        "Punjab": "Punjab",
        "Rajasthan": "Rajasthan",
        "Sikkim": "Sikkim",
        "Tamil Nadu": "Tamil Nadu",
        "Telangana": "Telangana",
        "Tripura": "Tripura",
        "Uttar Pradesh": "Uttar Pradesh",
        "Uttarakhand": "Uttarakhand",
        "West Bengal": "West Bengal"
    },
    "province_codes": {
        "Andaman and Nicobar": "AN",
        "Andhra Pradesh": "AP",
        "Arunachal Pradesh": "AR",
        "Assam": "AS",
        "Bihar": "BR",
        "Chandigarh": "CH",
        "Chattisgarh": "CG",
        "Dadra and Nagar Haveli": "DN",
        "Daman and Diu": "DD",
        "Delhi": "DL",
        "Goa": "GA",
        "Gujarat": "GJ",
        "Haryana": "HR",
        "Himachal Pradesh": "HP",
        "Jammu and Kashmir": "JK",
        "Jharkhand": "JH",
        "Karnataka": "KA",
        "Kerala": "KL",
        "Lakshadweep": "LD",
        "Madhya Pradesh": "MP",
        "Maharashtra": "MH",
        "Manipur": "MN",
        "Meghalaya": "ML",
        "Mizoram": "MZ",
        "Nagaland": "NL",
        "Orissa": "OR",
        "Puducherry": "PY",
        "Punjab": "PB",
        "Rajasthan": "RJ",
        "Sikkim": "SK",
        "Tamil Nadu": "TN",
        "Telangana": "TS",
        "Tripura": "TR",
        "Uttar Pradesh": "UP",
        "Uttarakhand": "UK",
        "West Bengal": "WB"
    },
    "province_alternate_names": {
        "Andaman and Nicobar": null,
        "Andhra Pradesh": null,
        "Arunachal Pradesh": null,
        "Assam": null,
        "Bihar": null,
        "Chandigarh": null,
        "Chattisgarh": null,
        "Dadra and Nagar Haveli": null,
        "Daman and Diu": null,
        "Delhi": null,
        "Goa": null,
        "Gujarat": null,
        "Haryana": null,
        "Himachal Pradesh": null,
        "Jammu and Kashmir": null,
        "Jharkhand": null,
        "Karnataka": null,
        "Kerala": null,
        "Lakshadweep": null,
        "Madhya Pradesh": null,
        "Maharashtra": null,
        "Manipur": null,
        "Meghalaya": null,
        "Mizoram": null,
        "Nagaland": null,
        "Orissa": null,
        "Puducherry": null,
        "Punjab": null,
        "Rajasthan": null,
        "Sikkim": null,
        "Tamil Nadu": null,
        "Telangana": null,
        "Tripura": null,
        "Uttar Pradesh": null,
        "Uttarakhand": null,
        "West Bengal": null
    }
},
{
    "name": "Indonesia",
    "code": "ID",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Aceh", "Bali", "Bangka Belitung", "Banten", "Bengkulu", "Gorontalo", "Jakarta", "Jambi", "Jawa Barat", "Jawa Tengah", "Jawa Timur", "Kalimantan Barat", "Kalimantan Selatan", "Kalimantan Tengah", "Kalimantan Timur", "Kalimantan Utara", "Kepulauan Riau", "Lampung", "Maluku", "Maluku Utara", "Nusa Tenggara Barat", "Nusa Tenggara Timur", "Papua", "Papua Barat", "Riau", "Sulawesi Barat", "Sulawesi Selatan", "Sulawesi Tengah", "Sulawesi Tenggara", "Sulawesi Utara", "Sumatra Barat", "Sumatra Selatan", "Sumatra Utara", "Yogyakarta"],
    "province_labels": {
        "Aceh": "Aceh",
        "Bali": "Bali",
        "Bangka Belitung": "Bangka Belitung",
        "Banten": "Banten",
        "Bengkulu": "Bengkulu",
        "Gorontalo": "Gorontalo",
        "Jakarta": "Jakarta",
        "Jambi": "Jambi",
        "Jawa Barat": "Jawa Barat",
        "Jawa Tengah": "Jawa Tengah",
        "Jawa Timur": "Jawa Timur",
        "Kalimantan Barat": "Kalimantan Barat",
        "Kalimantan Selatan": "Kalimantan Selatan",
        "Kalimantan Tengah": "Kalimantan Tengah",
        "Kalimantan Timur": "Kalimantan Timur",
        "Kalimantan Utara": "Kalimantan Utara",
        "Kepulauan Riau": "Kepulauan Riau",
        "Lampung": "Lampung",
        "Maluku": "Maluku",
        "Maluku Utara": "Maluku Utara",
        "Nusa Tenggara Barat": "Nusa Tenggara Barat",
        "Nusa Tenggara Timur": "Nusa Tenggara Timur",
        "Papua": "Papua",
        "Papua Barat": "Papua Barat",
        "Riau": "Riau",
        "Sulawesi Barat": "Sulawesi Barat",
        "Sulawesi Selatan": "Sulawesi Selatan",
        "Sulawesi Tengah": "Sulawesi Tengah",
        "Sulawesi Tenggara": "Sulawesi Tenggara",
        "Sulawesi Utara": "Sulawesi Utara",
        "Sumatra Barat": "Sumatra Barat",
        "Sumatra Selatan": "Sumatra Selatan",
        "Sumatra Utara": "Sumatra Utara",
        "Yogyakarta": "Yogyakarta"
    },
    "province_codes": {
        "Aceh": "AC",
        "Bali": "BA",
        "Bangka Belitung": "BB",
        "Banten": "BT",
        "Bengkulu": "BE",
        "Gorontalo": "GO",
        "Jakarta": "JK",
        "Jambi": "JA",
        "Jawa Barat": "JB",
        "Jawa Tengah": "JT",
        "Jawa Timur": "JI",
        "Kalimantan Barat": "KB",
        "Kalimantan Selatan": "KS",
        "Kalimantan Tengah": "KT",
        "Kalimantan Timur": "KI",
        "Kalimantan Utara": "KU",
        "Kepulauan Riau": "KR",
        "Lampung": "LA",
        "Maluku": "MA",
        "Maluku Utara": "MU",
        "Nusa Tenggara Barat": "NB",
        "Nusa Tenggara Timur": "NT",
        "Papua": "PA",
        "Papua Barat": "PB",
        "Riau": "RI",
        "Sulawesi Barat": "SR",
        "Sulawesi Selatan": "SN",
        "Sulawesi Tengah": "ST",
        "Sulawesi Tenggara": "SG",
        "Sulawesi Utara": "SA",
        "Sumatra Barat": "SB",
        "Sumatra Selatan": "SS",
        "Sumatra Utara": "SU",
        "Yogyakarta": "YO"
    },
    "province_alternate_names": {
        "Aceh": null,
        "Bali": null,
        "Bangka Belitung": null,
        "Banten": null,
        "Bengkulu": null,
        "Gorontalo": null,
        "Jakarta": null,
        "Jambi": null,
        "Jawa Barat": null,
        "Jawa Tengah": null,
        "Jawa Timur": null,
        "Kalimantan Barat": null,
        "Kalimantan Selatan": null,
        "Kalimantan Tengah": null,
        "Kalimantan Timur": null,
        "Kalimantan Utara": null,
        "Kepulauan Riau": null,
        "Lampung": null,
        "Maluku": null,
        "Maluku Utara": null,
        "Nusa Tenggara Barat": null,
        "Nusa Tenggara Timur": null,
        "Papua": null,
        "Papua Barat": null,
        "Riau": null,
        "Sulawesi Barat": null,
        "Sulawesi Selatan": null,
        "Sulawesi Tengah": null,
        "Sulawesi Tenggara": null,
        "Sulawesi Utara": null,
        "Sumatra Barat": null,
        "Sumatra Selatan": null,
        "Sumatra Utara": null,
        "Yogyakarta": null
    }
},
{
    "name": "Iran, Islamic Republic Of",
    "code": "IR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Iraq",
    "code": "IQ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Ireland",
    "code": "IE",
    "label": "County",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "County",
    "provinces": ["Carlow", "Cavan", "Clare", "Cork", "Donegal", "Dublin", "Galway", "Kerry", "Kildare", "Kilkenny", "Laois", "Leitrim", "Limerick", "Longford", "Louth", "Mayo", "Meath", "Monaghan", "Offaly", "Roscommon", "Sligo", "Tipperary", "Waterford", "Westmeath", "Wexford", "Wicklow"],
    "province_labels": {
        "Carlow": "Carlow",
        "Cavan": "Cavan",
        "Clare": "Clare",
        "Cork": "Cork",
        "Donegal": "Donegal",
        "Dublin": "Dublin",
        "Galway": "Galway",
        "Kerry": "Kerry",
        "Kildare": "Kildare",
        "Kilkenny": "Kilkenny",
        "Laois": "Laois",
        "Leitrim": "Leitrim",
        "Limerick": "Limerick",
        "Longford": "Longford",
        "Louth": "Louth",
        "Mayo": "Mayo",
        "Meath": "Meath",
        "Monaghan": "Monaghan",
        "Offaly": "Offaly",
        "Roscommon": "Roscommon",
        "Sligo": "Sligo",
        "Tipperary": "Tipperary",
        "Waterford": "Waterford",
        "Westmeath": "Westmeath",
        "Wexford": "Wexford",
        "Wicklow": "Wicklow"
    },
    "province_codes": {
        "Carlow": "CW",
        "Cavan": "CN",
        "Clare": "CE",
        "Cork": "CO",
        "Donegal": "DL",
        "Dublin": "D",
        "Galway": "G",
        "Kerry": "KY",
        "Kildare": "KE",
        "Kilkenny": "KK",
        "Laois": "LS",
        "Leitrim": "LM",
        "Limerick": "LK",
        "Longford": "LD",
        "Louth": "LH",
        "Mayo": "MO",
        "Meath": "MH",
        "Monaghan": "MN",
        "Offaly": "OY",
        "Roscommon": "RN",
        "Sligo": "SO",
        "Tipperary": "TA",
        "Waterford": "WD",
        "Westmeath": "WH",
        "Wexford": "WX",
        "Wicklow": "WW"
    },
    "province_alternate_names": {
        "Carlow": ["Ceatharlach", "Contae Cheatharlach", "County Carlow", "Co. Carlow"],
        "Cavan": ["An Cabhán", "An Cabhan", "Contae an Chabháin", "Contae an Chabhain", "County Cavan", "Co. Cavan"],
        "Clare": ["An Clár", "An Clar", "Contae an Chláir", "Contae an Chlair", "County Clare", "Co. Clare"],
        "Cork": ["Corcaigh", "Contae Chorcaí", "Contae Chorcai", "County Cork", "Co. Cork"],
        "Donegal": ["Dún na nGall", "Dun na nGall", "Contae Dhún na nGall", "Contae Dhun na nGall", "County Donegal", "Co. Donegal"],
        "Dublin": ["Baile Átha Cliath", "Baile Atha Cliath", "County Dublin", "Co. Dublin"],
        "Galway": ["Gaillimh", "Contae na Gaillimhe", "County Galway", "Co. Galway"],
        "Kerry": ["Ciarraí", "Ciarrai", "Contae Chiarraí", "Contae Chiarrai", "County Kerry", "Co. Kerry"],
        "Kildare": ["Cill Dara", "Contae Chill Dara", "County Kildare", "Co. Kildare"],
        "Kilkenny": ["Cill Chainnigh", "Contae Chill Chainnigh", "County Kilkenny", "Co. Kilkenny"],
        "Laois": ["Contae Laoise", "County Laois", "Co. Laois"],
        "Leitrim": ["Liatroim", "Contae Liatroma", "County Leitrim", "Co. Leitrim"],
        "Limerick": ["Luimneach", "Contae Luimnigh", "County Limerick", "Co. Limerick"],
        "Longford": ["An Longfort", "Contae an Longfoirt", "County Longford", "Co. Longford"],
        "Louth": ["Lú", "Lu", "Contae Lú", "Contae Lu", "County Louth", "Co. Louth"],
        "Mayo": ["Maigh Eo", "Contae Mhaigh Eo", "County Mayo", "Co. Mayo"],
        "Meath": ["An Mhí", "An Mhi", "Contae na Mí", "Contae na Mi", "County Meath", "Co. Meath"],
        "Monaghan": ["Muineachán", "Muineachan", "Contae Mhuineacháin", "Contae Mhuineachain", "County Monaghan", "Co. Monaghan"],
        "Offaly": ["Uíbh Fhailí", "Uibh Fhaili", "Contae Uíbh Fhailí", "Contae Uibh Fhaili", "County Offaly", "Co. Offaly"],
        "Roscommon": ["Ros Comáin", "Ros Comain", "Contae Ros Comáin", "Contae Ros Comain", "County Roscommon", "Co. Roscommon"],
        "Sligo": ["Sligeach", "Contae Shligigh", "County Sligo", "Co. Sligo"],
        "Tipperary": ["Tiobraid Árann", "Tiobraid Arann", "Contae Thiobraid Árann", "Contae Thiobraid Arann", "County Tipperary", "Co. Tipperary"],
        "Waterford": ["Port Láirge", "Port Lairge", "Contae Phort Láirge", "Contae Phort Lairge", "County Waterford", "Co. Waterford"],
        "Westmeath": ["An Iarmhí", "An Iarmhi", "Contae na hIarmhí", "Contae na hIarmhi", "County Westmeath", "Co. Westmeath"],
        "Wexford": ["Loch Garman", "Contae Loch Garman", "County Wexford", "Co. Wexford"],
        "Wicklow": ["Cill Mhantáin", "Cill Mhantain", "Contae Chill Mhantáin", "Contae Chill Mhantain", "County Wicklow", "Co. Wicklow"]
    }
},
{
    "name": "Isle Of Man",
    "code": "IM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Israel",
    "code": "IL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Italy",
    "code": "IT",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", "Asti", "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", "Benevento", "Bergamo", "Biella", "Bologna", "Bolzano", "Brescia", "Brindisi", "Cagliari", "Caltanissetta", "Campobasso", "Carbonia-Iglesias", "Caserta", "Catania", "Catanzaro", "Chieti", "Como", "Cosenza", "Cremona", "Crotone", "Cuneo", "Enna", "Fermo", "Ferrara", "Firenze", "Foggia", "Forlì-Cesena", "Frosinone", "Genova", "Gorizia", "Grosseto", "Imperia", "Isernia", "L'Aquila", "La Spezia", "Latina", "Lecce", "Lecco", "Livorno", "Lodi", "Lucca", "Macerata", "Mantova", "Massa-Carrara", "Matera", "Medio Campidano", "Messina", "Milano", "Modena", "Monza e Brianza", "Napoli", "Novara", "Nuoro", "Ogliastra", "Olbia-Tempio", "Oristano", "Padova", "Palermo", "Parma", "Pavia", "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", "Pisa", "Pistoia", "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", "Reggio Calabria", "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", "Salerno", "Sassari", "Savona", "Siena", "Siracusa", "Sondrio", "Taranto", "Teramo", "Terni", "Torino", "Trapani", "Trento", "Treviso", "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola", "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"],
    "province_labels": {
        "Agrigento": "Agrigento",
        "Alessandria": "Alessandria",
        "Ancona": "Ancona",
        "Aosta": "Aosta",
        "Arezzo": "Arezzo",
        "Ascoli Piceno": "Ascoli Piceno",
        "Asti": "Asti",
        "Avellino": "Avellino",
        "Bari": "Bari",
        "Barletta-Andria-Trani": "Barletta-Andria-Trani",
        "Belluno": "Belluno",
        "Benevento": "Benevento",
        "Bergamo": "Bergamo",
        "Biella": "Biella",
        "Bologna": "Bologna",
        "Bolzano": "Bolzano",
        "Brescia": "Brescia",
        "Brindisi": "Brindisi",
        "Cagliari": "Cagliari",
        "Caltanissetta": "Caltanissetta",
        "Campobasso": "Campobasso",
        "Carbonia-Iglesias": "Carbonia-Iglesias",
        "Caserta": "Caserta",
        "Catania": "Catania",
        "Catanzaro": "Catanzaro",
        "Chieti": "Chieti",
        "Como": "Como",
        "Cosenza": "Cosenza",
        "Cremona": "Cremona",
        "Crotone": "Crotone",
        "Cuneo": "Cuneo",
        "Enna": "Enna",
        "Fermo": "Fermo",
        "Ferrara": "Ferrara",
        "Firenze": "Firenze",
        "Foggia": "Foggia",
        "Forlì-Cesena": "Forlì-Cesena",
        "Frosinone": "Frosinone",
        "Genova": "Genova",
        "Gorizia": "Gorizia",
        "Grosseto": "Grosseto",
        "Imperia": "Imperia",
        "Isernia": "Isernia",
        "L'Aquila": "L'Aquila",
        "La Spezia": "La Spezia",
        "Latina": "Latina",
        "Lecce": "Lecce",
        "Lecco": "Lecco",
        "Livorno": "Livorno",
        "Lodi": "Lodi",
        "Lucca": "Lucca",
        "Macerata": "Macerata",
        "Mantova": "Mantova",
        "Massa-Carrara": "Massa-Carrara",
        "Matera": "Matera",
        "Medio Campidano": "Medio Campidano",
        "Messina": "Messina",
        "Milano": "Milano",
        "Modena": "Modena",
        "Monza e Brianza": "Monza e Brianza",
        "Napoli": "Napoli",
        "Novara": "Novara",
        "Nuoro": "Nuoro",
        "Ogliastra": "Ogliastra",
        "Olbia-Tempio": "Olbia-Tempio",
        "Oristano": "Oristano",
        "Padova": "Padova",
        "Palermo": "Palermo",
        "Parma": "Parma",
        "Pavia": "Pavia",
        "Perugia": "Perugia",
        "Pesaro e Urbino": "Pesaro e Urbino",
        "Pescara": "Pescara",
        "Piacenza": "Piacenza",
        "Pisa": "Pisa",
        "Pistoia": "Pistoia",
        "Pordenone": "Pordenone",
        "Potenza": "Potenza",
        "Prato": "Prato",
        "Ragusa": "Ragusa",
        "Ravenna": "Ravenna",
        "Reggio Calabria": "Reggio Calabria",
        "Reggio Emilia": "Reggio Emilia",
        "Rieti": "Rieti",
        "Rimini": "Rimini",
        "Roma": "Roma",
        "Rovigo": "Rovigo",
        "Salerno": "Salerno",
        "Sassari": "Sassari",
        "Savona": "Savona",
        "Siena": "Siena",
        "Siracusa": "Siracusa",
        "Sondrio": "Sondrio",
        "Taranto": "Taranto",
        "Teramo": "Teramo",
        "Terni": "Terni",
        "Torino": "Torino",
        "Trapani": "Trapani",
        "Trento": "Trento",
        "Treviso": "Treviso",
        "Trieste": "Trieste",
        "Udine": "Udine",
        "Varese": "Varese",
        "Venezia": "Venezia",
        "Verbano-Cusio-Ossola": "Verbano-Cusio-Ossola",
        "Vercelli": "Vercelli",
        "Verona": "Verona",
        "Vibo Valentia": "Vibo Valentia",
        "Vicenza": "Vicenza",
        "Viterbo": "Viterbo"
    },
    "province_codes": {
        "Agrigento": "AG",
        "Alessandria": "AL",
        "Ancona": "AN",
        "Aosta": "AO",
        "Arezzo": "AR",
        "Ascoli Piceno": "AP",
        "Asti": "AT",
        "Avellino": "AV",
        "Bari": "BA",
        "Barletta-Andria-Trani": "BT",
        "Belluno": "BL",
        "Benevento": "BN",
        "Bergamo": "BG",
        "Biella": "BI",
        "Bologna": "BO",
        "Bolzano": "BZ",
        "Brescia": "BS",
        "Brindisi": "BR",
        "Cagliari": "CA",
        "Caltanissetta": "CL",
        "Campobasso": "CB",
        "Carbonia-Iglesias": "CI",
        "Caserta": "CE",
        "Catania": "CT",
        "Catanzaro": "CZ",
        "Chieti": "CH",
        "Como": "CO",
        "Cosenza": "CS",
        "Cremona": "CR",
        "Crotone": "KR",
        "Cuneo": "CN",
        "Enna": "EN",
        "Fermo": "FM",
        "Ferrara": "FE",
        "Firenze": "FI",
        "Foggia": "FG",
        "Forlì-Cesena": "FC",
        "Frosinone": "FR",
        "Genova": "GE",
        "Gorizia": "GO",
        "Grosseto": "GR",
        "Imperia": "IM",
        "Isernia": "IS",
        "L'Aquila": "AQ",
        "La Spezia": "SP",
        "Latina": "LT",
        "Lecce": "LE",
        "Lecco": "LC",
        "Livorno": "LI",
        "Lodi": "LO",
        "Lucca": "LU",
        "Macerata": "MC",
        "Mantova": "MN",
        "Massa-Carrara": "MS",
        "Matera": "MT",
        "Medio Campidano": "VS",
        "Messina": "ME",
        "Milano": "MI",
        "Modena": "MO",
        "Monza e Brianza": "MB",
        "Napoli": "NA",
        "Novara": "NO",
        "Nuoro": "NU",
        "Ogliastra": "OG",
        "Olbia-Tempio": "OT",
        "Oristano": "OR",
        "Padova": "PD",
        "Palermo": "PA",
        "Parma": "PR",
        "Pavia": "PV",
        "Perugia": "PG",
        "Pesaro e Urbino": "PU",
        "Pescara": "PE",
        "Piacenza": "PC",
        "Pisa": "PI",
        "Pistoia": "PT",
        "Pordenone": "PN",
        "Potenza": "PZ",
        "Prato": "PO",
        "Ragusa": "RG",
        "Ravenna": "RA",
        "Reggio Calabria": "RC",
        "Reggio Emilia": "RE",
        "Rieti": "RI",
        "Rimini": "RN",
        "Roma": "RM",
        "Rovigo": "RO",
        "Salerno": "SA",
        "Sassari": "SS",
        "Savona": "SV",
        "Siena": "SI",
        "Siracusa": "SR",
        "Sondrio": "SO",
        "Taranto": "TA",
        "Teramo": "TE",
        "Terni": "TR",
        "Torino": "TO",
        "Trapani": "TP",
        "Trento": "TN",
        "Treviso": "TV",
        "Trieste": "TS",
        "Udine": "UD",
        "Varese": "VA",
        "Venezia": "VE",
        "Verbano-Cusio-Ossola": "VB",
        "Vercelli": "VC",
        "Verona": "VR",
        "Vibo Valentia": "VV",
        "Vicenza": "VI",
        "Viterbo": "VT"
    },
    "province_alternate_names": {
        "Agrigento": null,
        "Alessandria": null,
        "Ancona": null,
        "Aosta": null,
        "Arezzo": null,
        "Ascoli Piceno": null,
        "Asti": null,
        "Avellino": null,
        "Bari": null,
        "Barletta-Andria-Trani": null,
        "Belluno": null,
        "Benevento": null,
        "Bergamo": null,
        "Biella": null,
        "Bologna": null,
        "Bolzano": null,
        "Brescia": null,
        "Brindisi": null,
        "Cagliari": null,
        "Caltanissetta": null,
        "Campobasso": null,
        "Carbonia-Iglesias": null,
        "Caserta": null,
        "Catania": null,
        "Catanzaro": null,
        "Chieti": null,
        "Como": null,
        "Cosenza": null,
        "Cremona": null,
        "Crotone": null,
        "Cuneo": null,
        "Enna": null,
        "Fermo": null,
        "Ferrara": null,
        "Firenze": null,
        "Foggia": null,
        "Forlì-Cesena": ["Forli-Cesena"],
        "Frosinone": null,
        "Genova": null,
        "Gorizia": null,
        "Grosseto": null,
        "Imperia": null,
        "Isernia": null,
        "L'Aquila": null,
        "La Spezia": null,
        "Latina": null,
        "Lecce": null,
        "Lecco": null,
        "Livorno": null,
        "Lodi": null,
        "Lucca": null,
        "Macerata": null,
        "Mantova": null,
        "Massa-Carrara": null,
        "Matera": null,
        "Medio Campidano": null,
        "Messina": null,
        "Milano": null,
        "Modena": null,
        "Monza e Brianza": null,
        "Napoli": null,
        "Novara": null,
        "Nuoro": null,
        "Ogliastra": null,
        "Olbia-Tempio": null,
        "Oristano": null,
        "Padova": null,
        "Palermo": null,
        "Parma": null,
        "Pavia": null,
        "Perugia": null,
        "Pesaro e Urbino": null,
        "Pescara": null,
        "Piacenza": null,
        "Pisa": null,
        "Pistoia": null,
        "Pordenone": null,
        "Potenza": null,
        "Prato": null,
        "Ragusa": null,
        "Ravenna": null,
        "Reggio Calabria": null,
        "Reggio Emilia": null,
        "Rieti": null,
        "Rimini": null,
        "Roma": null,
        "Rovigo": null,
        "Salerno": null,
        "Sassari": null,
        "Savona": null,
        "Siena": null,
        "Siracusa": null,
        "Sondrio": null,
        "Taranto": null,
        "Teramo": null,
        "Terni": null,
        "Torino": null,
        "Trapani": null,
        "Trento": null,
        "Treviso": null,
        "Trieste": null,
        "Udine": null,
        "Varese": null,
        "Venezia": null,
        "Verbano-Cusio-Ossola": null,
        "Vercelli": null,
        "Verona": null,
        "Vibo Valentia": null,
        "Vicenza": null,
        "Viterbo": null
    }
},
{
    "name": "Jamaica",
    "code": "JM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Japan",
    "code": "JP",
    "label": "Prefecture",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Prefecture",
    "provinces": ["Hokkaidō", "Aomori", "Iwate", "Miyagi", "Akita", "Yamagata", "Fukushima", "Ibaraki", "Tochigi", "Gunma", "Saitama", "Chiba", "Tōkyō", "Kanagawa", "Niigata", "Toyama", "Ishikawa", "Fukui", "Yamanashi", "Nagano", "Gifu", "Shizuoka", "Aichi", "Mie", "Shiga", "Kyōto", "Ōsaka", "Hyōgo", "Nara", "Wakayama", "Tottori", "Shimane", "Okayama", "Hiroshima", "Yamaguchi", "Tokushima", "Kagawa", "Ehime", "Kōchi", "Fukuoka", "Saga", "Nagasaki", "Kumamoto", "Ōita", "Miyazaki", "Kagoshima", "Okinawa"],
    "province_labels": {
        "Hokkaidō": "Hokkaidō",
        "Aomori": "Aomori",
        "Iwate": "Iwate",
        "Miyagi": "Miyagi",
        "Akita": "Akita",
        "Yamagata": "Yamagata",
        "Fukushima": "Fukushima",
        "Ibaraki": "Ibaraki",
        "Tochigi": "Tochigi",
        "Gunma": "Gunma",
        "Saitama": "Saitama",
        "Chiba": "Chiba",
        "Tōkyō": "Tōkyō",
        "Kanagawa": "Kanagawa",
        "Niigata": "Niigata",
        "Toyama": "Toyama",
        "Ishikawa": "Ishikawa",
        "Fukui": "Fukui",
        "Yamanashi": "Yamanashi",
        "Nagano": "Nagano",
        "Gifu": "Gifu",
        "Shizuoka": "Shizuoka",
        "Aichi": "Aichi",
        "Mie": "Mie",
        "Shiga": "Shiga",
        "Kyōto": "Kyōto",
        "Ōsaka": "Ōsaka",
        "Hyōgo": "Hyōgo",
        "Nara": "Nara",
        "Wakayama": "Wakayama",
        "Tottori": "Tottori",
        "Shimane": "Shimane",
        "Okayama": "Okayama",
        "Hiroshima": "Hiroshima",
        "Yamaguchi": "Yamaguchi",
        "Tokushima": "Tokushima",
        "Kagawa": "Kagawa",
        "Ehime": "Ehime",
        "Kōchi": "Kōchi",
        "Fukuoka": "Fukuoka",
        "Saga": "Saga",
        "Nagasaki": "Nagasaki",
        "Kumamoto": "Kumamoto",
        "Ōita": "Ōita",
        "Miyazaki": "Miyazaki",
        "Kagoshima": "Kagoshima",
        "Okinawa": "Okinawa"
    },
    "province_codes": {
        "Hokkaidō": "JP-01",
        "Aomori": "JP-02",
        "Iwate": "JP-03",
        "Miyagi": "JP-04",
        "Akita": "JP-05",
        "Yamagata": "JP-06",
        "Fukushima": "JP-07",
        "Ibaraki": "JP-08",
        "Tochigi": "JP-09",
        "Gunma": "JP-10",
        "Saitama": "JP-11",
        "Chiba": "JP-12",
        "Tōkyō": "JP-13",
        "Kanagawa": "JP-14",
        "Niigata": "JP-15",
        "Toyama": "JP-16",
        "Ishikawa": "JP-17",
        "Fukui": "JP-18",
        "Yamanashi": "JP-19",
        "Nagano": "JP-20",
        "Gifu": "JP-21",
        "Shizuoka": "JP-22",
        "Aichi": "JP-23",
        "Mie": "JP-24",
        "Shiga": "JP-25",
        "Kyōto": "JP-26",
        "Ōsaka": "JP-27",
        "Hyōgo": "JP-28",
        "Nara": "JP-29",
        "Wakayama": "JP-30",
        "Tottori": "JP-31",
        "Shimane": "JP-32",
        "Okayama": "JP-33",
        "Hiroshima": "JP-34",
        "Yamaguchi": "JP-35",
        "Tokushima": "JP-36",
        "Kagawa": "JP-37",
        "Ehime": "JP-38",
        "Kōchi": "JP-39",
        "Fukuoka": "JP-40",
        "Saga": "JP-41",
        "Nagasaki": "JP-42",
        "Kumamoto": "JP-43",
        "Ōita": "JP-44",
        "Miyazaki": "JP-45",
        "Kagoshima": "JP-46",
        "Okinawa": "JP-47"
    },
    "province_alternate_names": {
        "Hokkaidō": ["Hokkaido", "Hokkaido Prefecture", "北海道"],
        "Aomori": ["Aomori Prefecture", "Aomori-ken", "青森県", "青森"],
        "Iwate": ["Iwate Prefecture", "Iwate-ken", "岩手県", "岩手"],
        "Miyagi": ["Miyagi Prefecture", "Miyagi-ken", "宮城県", "宮城"],
        "Akita": ["Akita Prefecture", "Akita-ken", "秋田県", "秋田"],
        "Yamagata": ["Yamagata Prefecture", "Yamagata-ken", "山形県", "山形"],
        "Fukushima": ["Fukushima Prefecture", "Fukushima-ken", "福島県", "福島"],
        "Ibaraki": ["Ibaraki Prefecture", "Ibaraki-ken", "茨城県", "茨城"],
        "Tochigi": ["Tochigi Prefecture", "Tochigi-ken", "栃木県", "栃木"],
        "Gunma": ["Gunma Prefecture", "Gunma-ken", "群馬県", "群馬"],
        "Saitama": ["Saitama Prefecture", "Saitama-ken", "埼玉県", "埼玉"],
        "Chiba": ["Chiba Prefecture", "Chiba-ken", "千葉県", "千葉"],
        "Tōkyō": ["Tokyo", "Tokyo Prefecture", "Tōkyō-to", "Tokyo-to", "東京都", "東京"],
        "Kanagawa": ["Kanagawa Prefecture", "Kanagawa-ken", "神奈川県", "神奈川"],
        "Niigata": ["Niigata Prefecture", "Niigata-ken", "新潟県", "新潟"],
        "Toyama": ["Toyama Prefecture", "Toyama-ken", "富山県", "富山"],
        "Ishikawa": ["Ishikawa Prefecture", "Ishikawa-ken", "石川県", "石川"],
        "Fukui": ["Fukui Prefecture", "Fukui-ken", "福井県", "福井"],
        "Yamanashi": ["Yamanashi Prefecture", "Yamanashi-ken", "山梨県", "山梨"],
        "Nagano": ["Nagano Prefecture", "Nagano-ken", "長野県", "長野"],
        "Gifu": ["Gifu Prefecture", "Gifu-ken", "岐阜県", "岐阜"],
        "Shizuoka": ["Shizuoka Prefecture", "Shizuoka-ken", "静岡県", "静岡"],
        "Aichi": ["Aichi Prefecture", "Aichi-ken", "愛知県", "愛知"],
        "Mie": ["Mie Prefecture", "Mie-ken", "三重県", "三重"],
        "Shiga": ["Shiga Prefecture", "Shiga-ken", "滋賀県", "滋賀"],
        "Kyōto": ["Kyoto", "Kyoto Prefecture", "Kyōto-fu", "Kyoto-fu", "京都府", "京都"],
        "Ōsaka": ["Osaka", "Osaka Prefecture", "Ōsaka-fu", "Osaka-fu", "大阪府", "大阪"],
        "Hyōgo": ["Hyogo", "Hyogo Prefecture", "Hyōgo-ken", "Hyogo-ken", "兵庫県", "兵庫"],
        "Nara": ["Nara Prefecture", "Nara-ken", "奈良県", "奈良"],
        "Wakayama": ["Wakayama Prefecture", "Wakayama-ken", "和歌山県", "和歌山"],
        "Tottori": ["Tottori Prefecture", "Tottori-ken", "鳥取県", "鳥取"],
        "Shimane": ["Shimane Prefecture", "Shimane-ken", "島根県", "島根"],
        "Okayama": ["Okayama Prefecture", "Okayama-ken", "岡山県", "岡山"],
        "Hiroshima": ["Hiroshima Prefecture", "Hiroshima-ken", "広島県", "広島"],
        "Yamaguchi": ["Yamaguchi Prefecture", "Yamaguchi-ken", "山口県", "山口"],
        "Tokushima": ["Tokushima Prefecture", "Tokushima-ken", "徳島県", "徳島"],
        "Kagawa": ["Kagawa Prefecture", "Kagawa-ken", "香川県", "香川"],
        "Ehime": ["Ehime Prefecture", "Ehime-ken", "愛媛県", "愛媛"],
        "Kōchi": ["Kochi", "Kochi Prefecture", "Kōchi-ken", "Kochi-ken", "高知県", "高知"],
        "Fukuoka": ["Fukuoka Prefecture", "Fukuoka-ken", "福岡県", "福岡"],
        "Saga": ["Saga Prefecture", "Saga-ken", "佐賀県", "佐賀"],
        "Nagasaki": ["Nagasaki Prefecture", "Nagasaki-ken", "長崎県", "長崎"],
        "Kumamoto": ["Kumamoto Prefecture", "Kumamoto-ken", "熊本県", "熊本"],
        "Ōita": ["Oita", "Oita Prefecture", "Ōita-ken", "Oita-ken", "大分県", "大分"],
        "Miyazaki": ["Miyazaki Prefecture", "Miyazaki-ken", "宮崎県", "宮崎"],
        "Kagoshima": ["Kagoshima Prefecture", "Kagoshima-ken", "鹿児島県", "鹿児島"],
        "Okinawa": ["Okinawa Prefecture", "Okinawa-ken", "沖縄県", "沖縄"]
    }
},
{
    "name": "Jersey",
    "code": "JE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Jordan",
    "code": "JO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kazakhstan",
    "code": "KZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kenya",
    "code": "KE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kiribati",
    "code": "KI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Korea, Democratic People's Republic Of",
    "code": "KP",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kosovo",
    "code": "XK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kuwait",
    "code": "KW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Kyrgyzstan",
    "code": "KG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Lao People's Democratic Republic",
    "code": "LA",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Latvia",
    "code": "LV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Lebanon",
    "code": "LB",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Lesotho",
    "code": "LS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Liberia",
    "code": "LR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Libyan Arab Jamahiriya",
    "code": "LY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Liechtenstein",
    "code": "LI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Lithuania",
    "code": "LT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Luxembourg",
    "code": "LU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Macao",
    "code": "MO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Macedonia, Republic Of",
    "code": "MK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Madagascar",
    "code": "MG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Malawi",
    "code": "MW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Malaysia",
    "code": "MY",
    "label": "State\/territory",
    "zip_label": "Postcode",
    "zip_required": true,
    "province_label": "State\/territory",
    "provinces": ["Johor", "Kedah", "Kelantan", "Kuala Lumpur", "Labuan", "Melaka", "Negeri Sembilan", "Pahang", "Perak", "Perlis", "Pulau Pinang", "Putrajaya", "Sabah", "Sarawak", "Selangor", "Terengganu"],
    "province_labels": {
        "Johor": "Johor",
        "Kedah": "Kedah",
        "Kelantan": "Kelantan",
        "Kuala Lumpur": "Kuala Lumpur",
        "Labuan": "Labuan",
        "Melaka": "Melaka",
        "Negeri Sembilan": "Negeri Sembilan",
        "Pahang": "Pahang",
        "Perak": "Perak",
        "Perlis": "Perlis",
        "Pulau Pinang": "Pulau Pinang",
        "Putrajaya": "Putrajaya",
        "Sabah": "Sabah",
        "Sarawak": "Sarawak",
        "Selangor": "Selangor",
        "Terengganu": "Terengganu"
    },
    "province_codes": {
        "Johor": "JHR",
        "Kedah": "KDH",
        "Kelantan": "KTN",
        "Kuala Lumpur": "KUL",
        "Labuan": "LBN",
        "Melaka": "MLK",
        "Negeri Sembilan": "NSN",
        "Pahang": "PHG",
        "Perak": "PRK",
        "Perlis": "PLS",
        "Pulau Pinang": "PNG",
        "Putrajaya": "PJY",
        "Sabah": "SBH",
        "Sarawak": "SWK",
        "Selangor": "SGR",
        "Terengganu": "TRG"
    },
    "province_alternate_names": {
        "Johor": null,
        "Kedah": null,
        "Kelantan": null,
        "Kuala Lumpur": null,
        "Labuan": null,
        "Melaka": null,
        "Negeri Sembilan": null,
        "Pahang": null,
        "Perak": null,
        "Perlis": null,
        "Pulau Pinang": null,
        "Putrajaya": null,
        "Sabah": null,
        "Sarawak": null,
        "Selangor": null,
        "Terengganu": null
    }
},
{
    "name": "Mali",
    "code": "MV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mali",
    "code": "ML",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Malta",
    "code": "MT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Martinique",
    "code": "MQ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mauritania",
    "code": "MR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mauritius",
    "code": "MU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mayotte",
    "code": "YT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mexico",
    "code": "MX",
    "label": "State",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "State",
    "provinces": ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Chiapas", "Chihuahua", "Ciudad de México", "Coahuila", "Colima", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "México", "Michoacán", "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"],
    "province_labels": {
        "Aguascalientes": "Aguascalientes",
        "Baja California": "Baja California",
        "Baja California Sur": "Baja California Sur",
        "Campeche": "Campeche",
        "Chiapas": "Chiapas",
        "Chihuahua": "Chihuahua",
        "Ciudad de México": "Ciudad de México",
        "Coahuila": "Coahuila",
        "Colima": "Colima",
        "Durango": "Durango",
        "Guanajuato": "Guanajuato",
        "Guerrero": "Guerrero",
        "Hidalgo": "Hidalgo",
        "Jalisco": "Jalisco",
        "México": "México",
        "Michoacán": "Michoacán",
        "Morelos": "Morelos",
        "Nayarit": "Nayarit",
        "Nuevo León": "Nuevo León",
        "Oaxaca": "Oaxaca",
        "Puebla": "Puebla",
        "Querétaro": "Querétaro",
        "Quintana Roo": "Quintana Roo",
        "San Luis Potosí": "San Luis Potosí",
        "Sinaloa": "Sinaloa",
        "Sonora": "Sonora",
        "Tabasco": "Tabasco",
        "Tamaulipas": "Tamaulipas",
        "Tlaxcala": "Tlaxcala",
        "Veracruz": "Veracruz",
        "Yucatán": "Yucatán",
        "Zacatecas": "Zacatecas"
    },
    "province_codes": {
        "Aguascalientes": "AGS",
        "Baja California": "BC",
        "Baja California Sur": "BCS",
        "Campeche": "CAMP",
        "Chiapas": "CHIS",
        "Chihuahua": "CHIH",
        "Ciudad de México": "DF",
        "Coahuila": "COAH",
        "Colima": "COL",
        "Durango": "DGO",
        "Guanajuato": "GTO",
        "Guerrero": "GRO",
        "Hidalgo": "HGO",
        "Jalisco": "JAL",
        "México": "MEX",
        "Michoacán": "MICH",
        "Morelos": "MOR",
        "Nayarit": "NAY",
        "Nuevo León": "NL",
        "Oaxaca": "OAX",
        "Puebla": "PUE",
        "Querétaro": "QRO",
        "Quintana Roo": "Q ROO",
        "San Luis Potosí": "SLP",
        "Sinaloa": "SIN",
        "Sonora": "SON",
        "Tabasco": "TAB",
        "Tamaulipas": "TAMPS",
        "Tlaxcala": "TLAX",
        "Veracruz": "VER",
        "Yucatán": "YUC",
        "Zacatecas": "ZAC"
    },
    "province_alternate_names": {
        "Aguascalientes": null,
        "Baja California": null,
        "Baja California Sur": null,
        "Campeche": null,
        "Chiapas": null,
        "Chihuahua": null,
        "Ciudad de México": ["Ciudad de Mexico"],
        "Coahuila": null,
        "Colima": null,
        "Durango": null,
        "Guanajuato": null,
        "Guerrero": null,
        "Hidalgo": null,
        "Jalisco": null,
        "México": ["Mexico"],
        "Michoacán": ["Michoacan"],
        "Morelos": null,
        "Nayarit": null,
        "Nuevo León": ["Nuevo Leon"],
        "Oaxaca": null,
        "Puebla": null,
        "Querétaro": ["Queretaro"],
        "Quintana Roo": null,
        "San Luis Potosí": ["San Luis Potosi"],
        "Sinaloa": null,
        "Sonora": null,
        "Tabasco": null,
        "Tamaulipas": null,
        "Tlaxcala": null,
        "Veracruz": null,
        "Yucatán": ["Yucatan"],
        "Zacatecas": null
    }
},
{
    "name": "Moldova, Republic of",
    "code": "MD",
    "label": null,
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Monaco",
    "code": "MC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mongolia",
    "code": "MN",
    "label": null,
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Montenegro",
    "code": "ME",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Montserrat",
    "code": "MS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Morocco",
    "code": "MA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Mozambique",
    "code": "MZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Myanmar",
    "code": "MM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Namibia",
    "code": "NA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Nauru",
    "code": "NR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Nepal",
    "code": "NP",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Netherlands",
    "code": "NL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Netherlands Antilles",
    "code": "AN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "New Caledonia",
    "code": "NC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "New Zealand",
    "code": "NZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": ["Auckland", "Bay of Plenty", "Canterbury", "Gisborne", "Hawke's Bay", "Manawatu-Wanganui", "Marlborough", "Nelson", "Northland", "Otago", "Southland", "Taranaki", "Tasman", "Waikato", "Wellington", "West Coast"],
    "province_labels": {
        "Auckland": "Auckland",
        "Bay of Plenty": "Bay of Plenty",
        "Canterbury": "Canterbury",
        "Gisborne": "Gisborne",
        "Hawke's Bay": "Hawke's Bay",
        "Manawatu-Wanganui": "Manawatu-Wanganui",
        "Marlborough": "Marlborough",
        "Nelson": "Nelson",
        "Northland": "Northland",
        "Otago": "Otago",
        "Southland": "Southland",
        "Taranaki": "Taranaki",
        "Tasman": "Tasman",
        "Waikato": "Waikato",
        "Wellington": "Wellington",
        "West Coast": "West Coast"
    },
    "province_codes": {
        "Auckland": "AUK",
        "Bay of Plenty": "BOP",
        "Canterbury": "CAN",
        "Gisborne": "GIS",
        "Hawke's Bay": "HKB",
        "Manawatu-Wanganui": "MWT",
        "Marlborough": "MBH",
        "Nelson": "NSN",
        "Northland": "NTL",
        "Otago": "OTA",
        "Southland": "STL",
        "Taranaki": "TKI",
        "Tasman": "TAS",
        "Waikato": "WKO",
        "Wellington": "WGN",
        "West Coast": "WTC"
    },
    "province_alternate_names": {
        "Auckland": null,
        "Bay of Plenty": null,
        "Canterbury": null,
        "Gisborne": null,
        "Hawke's Bay": null,
        "Manawatu-Wanganui": null,
        "Marlborough": null,
        "Nelson": null,
        "Northland": null,
        "Otago": null,
        "Southland": null,
        "Taranaki": null,
        "Tasman": null,
        "Waikato": null,
        "Wellington": null,
        "West Coast": null
    }
},
{
    "name": "Nicaragua",
    "code": "NI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Niger",
    "code": "NE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Nigeria",
    "code": "NG",
    "label": "State",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "State",
    "provinces": ["Abia", "Abuja Federal Capital Territory", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno", "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa", "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger", "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara"],
    "province_labels": {
        "Abia": "Abia",
        "Abuja Federal Capital Territory": "Abuja Federal Capital Territory",
        "Adamawa": "Adamawa",
        "Akwa Ibom": "Akwa Ibom",
        "Anambra": "Anambra",
        "Bauchi": "Bauchi",
        "Bayelsa": "Bayelsa",
        "Benue": "Benue",
        "Borno": "Borno",
        "Cross River": "Cross River",
        "Delta": "Delta",
        "Ebonyi": "Ebonyi",
        "Edo": "Edo",
        "Ekiti": "Ekiti",
        "Enugu": "Enugu",
        "Gombe": "Gombe",
        "Imo": "Imo",
        "Jigawa": "Jigawa",
        "Kaduna": "Kaduna",
        "Kano": "Kano",
        "Katsina": "Katsina",
        "Kebbi": "Kebbi",
        "Kogi": "Kogi",
        "Kwara": "Kwara",
        "Lagos": "Lagos",
        "Nasarawa": "Nasarawa",
        "Niger": "Niger",
        "Ogun": "Ogun",
        "Ondo": "Ondo",
        "Osun": "Osun",
        "Oyo": "Oyo",
        "Plateau": "Plateau",
        "Rivers": "Rivers",
        "Sokoto": "Sokoto",
        "Taraba": "Taraba",
        "Yobe": "Yobe",
        "Zamfara": "Zamfara"
    },
    "province_codes": {
        "Abia": "AB",
        "Abuja Federal Capital Territory": "FC",
        "Adamawa": "AD",
        "Akwa Ibom": "AK",
        "Anambra": "AN",
        "Bauchi": "BA",
        "Bayelsa": "BY",
        "Benue": "BE",
        "Borno": "BO",
        "Cross River": "CR",
        "Delta": "DE",
        "Ebonyi": "EB",
        "Edo": "ED",
        "Ekiti": "EK",
        "Enugu": "EN",
        "Gombe": "GO",
        "Imo": "IM",
        "Jigawa": "JI",
        "Kaduna": "KD",
        "Kano": "KN",
        "Katsina": "KT",
        "Kebbi": "KE",
        "Kogi": "KO",
        "Kwara": "KW",
        "Lagos": "LA",
        "Nasarawa": "NA",
        "Niger": "NI",
        "Ogun": "OG",
        "Ondo": "ON",
        "Osun": "OS",
        "Oyo": "OY",
        "Plateau": "PL",
        "Rivers": "RI",
        "Sokoto": "SO",
        "Taraba": "TA",
        "Yobe": "YO",
        "Zamfara": "ZA"
    },
    "province_alternate_names": {
        "Abia": null,
        "Abuja Federal Capital Territory": null,
        "Adamawa": null,
        "Akwa Ibom": null,
        "Anambra": null,
        "Bauchi": null,
        "Bayelsa": null,
        "Benue": null,
        "Borno": null,
        "Cross River": null,
        "Delta": null,
        "Ebonyi": null,
        "Edo": null,
        "Ekiti": null,
        "Enugu": null,
        "Gombe": null,
        "Imo": null,
        "Jigawa": null,
        "Kaduna": null,
        "Kano": null,
        "Katsina": null,
        "Kebbi": null,
        "Kogi": null,
        "Kwara": null,
        "Lagos": null,
        "Nasarawa": null,
        "Niger": null,
        "Ogun": null,
        "Ondo": null,
        "Osun": null,
        "Oyo": null,
        "Plateau": null,
        "Rivers": null,
        "Sokoto": null,
        "Taraba": null,
        "Yobe": null,
        "Zamfara": null
    }
},
{
    "name": "Niue",
    "code": "NU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Norfolk Island",
    "code": "NF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Norway",
    "code": "NO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Oman",
    "code": "OM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Pakistan",
    "code": "PK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Palestinian Territory, Occupied",
    "code": "PS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Panama",
    "code": "PA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": ["Bocas del Toro", "Chiriquí", "Coclé", "Colón", "Darién", "Emberá", "Herrera", "Kuna Yala", "Los Santos", "Ngöbe-Buglé", "Panamá", "Panamá Oeste", "Veraguas"],
    "province_labels": {
        "Bocas del Toro": "Bocas del Toro",
        "Chiriquí": "Chiriquí",
        "Coclé": "Coclé",
        "Colón": "Colón",
        "Darién": "Darién",
        "Emberá": "Emberá",
        "Herrera": "Herrera",
        "Kuna Yala": "Kuna Yala",
        "Los Santos": "Los Santos",
        "Ngöbe-Buglé": "Ngöbe-Buglé",
        "Panamá": "Panamá",
        "Panamá Oeste": "Panamá Oeste",
        "Veraguas": "Veraguas"
    },
    "province_codes": {
        "Bocas del Toro": "PA-1",
        "Chiriquí": "PA-4",
        "Coclé": "PA-2",
        "Colón": "PA-3",
        "Darién": "PA-5",
        "Emberá": "PA-EM",
        "Herrera": "PA-6",
        "Kuna Yala": "PA-KY",
        "Los Santos": "PA-7",
        "Ngöbe-Buglé": "PA-NB",
        "Panamá": "PA-8",
        "Panamá Oeste": "PA-10",
        "Veraguas": "PA-9"
    },
    "province_alternate_names": {
        "Bocas del Toro": null,
        "Chiriquí": ["Chiriqui"],
        "Coclé": ["Cocle"],
        "Colón": ["Colon"],
        "Darién": ["Darien"],
        "Emberá": ["Embera"],
        "Herrera": null,
        "Kuna Yala": null,
        "Los Santos": null,
        "Ngöbe-Buglé": ["Ngobe-Bugle"],
        "Panamá": ["Panama"],
        "Panamá Oeste": ["Panama Oeste"],
        "Veraguas": null
    }
},
{
    "name": "Papua New Guinea",
    "code": "PG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Paraguay",
    "code": "PY",
    "label": null,
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Peru",
    "code": "PE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": ["Amazonas", "Áncash", "Apurímac", "Arequipa", "Ayacucho", "Cajamarca", "Callao", "Cuzco", "Huancavelica", "Huánuco", "Ica", "Junín", "La Libertad", "Lambayeque", "Lima (departamento)", "Lima (provincia)", "Loreto", "Madre de Dios", "Moquegua", "Pasco", "Piura", "Puno", "San Martín", "Tacna", "Tumbes", "Ucayali"],
    "province_labels": {
        "Amazonas": "Amazonas",
        "Áncash": "Áncash",
        "Apurímac": "Apurímac",
        "Arequipa": "Arequipa",
        "Ayacucho": "Ayacucho",
        "Cajamarca": "Cajamarca",
        "Callao": "Callao",
        "Cuzco": "Cuzco",
        "Huancavelica": "Huancavelica",
        "Huánuco": "Huánuco",
        "Ica": "Ica",
        "Junín": "Junín",
        "La Libertad": "La Libertad",
        "Lambayeque": "Lambayeque",
        "Lima (departamento)": "Lima (departamento)",
        "Lima (provincia)": "Lima (provincia)",
        "Loreto": "Loreto",
        "Madre de Dios": "Madre de Dios",
        "Moquegua": "Moquegua",
        "Pasco": "Pasco",
        "Piura": "Piura",
        "Puno": "Puno",
        "San Martín": "San Martín",
        "Tacna": "Tacna",
        "Tumbes": "Tumbes",
        "Ucayali": "Ucayali"
    },
    "province_codes": {
        "Amazonas": "PE-AMA",
        "Áncash": "PE-ANC",
        "Apurímac": "PE-APU",
        "Arequipa": "PE-ARE",
        "Ayacucho": "PE-AYA",
        "Cajamarca": "PE-CAJ",
        "Callao": "PE-CAL",
        "Cuzco": "PE-CUS",
        "Huancavelica": "PE-HUV",
        "Huánuco": "PE-HUC",
        "Ica": "PE-ICA",
        "Junín": "PE-JUN",
        "La Libertad": "PE-LAL",
        "Lambayeque": "PE-LAM",
        "Lima (departamento)": "PE-LIM",
        "Lima (provincia)": "PE-LMA",
        "Loreto": "PE-LOR",
        "Madre de Dios": "PE-MDD",
        "Moquegua": "PE-MOQ",
        "Pasco": "PE-PAS",
        "Piura": "PE-PIU",
        "Puno": "PE-PUN",
        "San Martín": "PE-SAM",
        "Tacna": "PE-TAC",
        "Tumbes": "PE-TUM",
        "Ucayali": "PE-UCA"
    },
    "province_alternate_names": {
        "Amazonas": null,
        "Áncash": ["Ancash", "Ancash"],
        "Apurímac": ["Apurimac", "Apurimac"],
        "Arequipa": null,
        "Ayacucho": null,
        "Cajamarca": null,
        "Callao": null,
        "Cuzco": null,
        "Huancavelica": null,
        "Huánuco": ["Huanuco", "Huanuco"],
        "Ica": null,
        "Junín": ["Junin", "Junin"],
        "La Libertad": null,
        "Lambayeque": null,
        "Lima (departamento)": null,
        "Lima (provincia)": null,
        "Loreto": null,
        "Madre de Dios": null,
        "Moquegua": null,
        "Pasco": null,
        "Piura": null,
        "Puno": null,
        "San Martín": ["San Martin", "San Martin"],
        "Tacna": null,
        "Tumbes": null,
        "Ucayali": null
    }
},
{
    "name": "Philippines",
    "code": "PH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Pitcairn",
    "code": "PN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Poland",
    "code": "PL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Portugal",
    "code": "PT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": ["Açores", "Aveiro", "Beja", "Braga", "Bragança", "Castelo Branco", "Coimbra", "Évora", "Faro", "Guarda", "Leiria", "Lisboa", "Madeira", "Portalegre", "Porto", "Santarém", "Setúbal", "Viana do Castelo", "Vila Real", "Viseu"],
    "province_labels": {
        "Açores": "Açores",
        "Aveiro": "Aveiro",
        "Beja": "Beja",
        "Braga": "Braga",
        "Bragança": "Bragança",
        "Castelo Branco": "Castelo Branco",
        "Coimbra": "Coimbra",
        "Évora": "Évora",
        "Faro": "Faro",
        "Guarda": "Guarda",
        "Leiria": "Leiria",
        "Lisboa": "Lisboa",
        "Madeira": "Madeira",
        "Portalegre": "Portalegre",
        "Porto": "Porto",
        "Santarém": "Santarém",
        "Setúbal": "Setúbal",
        "Viana do Castelo": "Viana do Castelo",
        "Vila Real": "Vila Real",
        "Viseu": "Viseu"
    },
    "province_codes": {
        "Açores": "PT-20",
        "Aveiro": "PT-01",
        "Beja": "PT-02",
        "Braga": "PT-03",
        "Bragança": "PT-04",
        "Castelo Branco": "PT-05",
        "Coimbra": "PT-06",
        "Évora": "PT-07",
        "Faro": "PT-08",
        "Guarda": "PT-09",
        "Leiria": "PT-10",
        "Lisboa": "PT-11",
        "Madeira": "PT-30",
        "Portalegre": "PT-12",
        "Porto": "PT-13",
        "Santarém": "PT-14",
        "Setúbal": "PT-15",
        "Viana do Castelo": "PT-16",
        "Vila Real": "PT-17",
        "Viseu": "PT-18"
    },
    "province_alternate_names": {
        "Açores": ["Acores"],
        "Aveiro": null,
        "Beja": null,
        "Braga": null,
        "Bragança": ["Braganca"],
        "Castelo Branco": null,
        "Coimbra": null,
        "Évora": ["Evora"],
        "Faro": null,
        "Guarda": null,
        "Leiria": null,
        "Lisboa": null,
        "Madeira": null,
        "Portalegre": null,
        "Porto": null,
        "Santarém": ["Santarem"],
        "Setúbal": ["Setubal"],
        "Viana do Castelo": null,
        "Vila Real": null,
        "Viseu": null
    }
},
{
    "name": "Qatar",
    "code": "QA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Republic of Cameroon",
    "code": "CM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Reunion",
    "code": "RE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Romania",
    "code": "RO",
    "label": "County",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "County",
    "provinces": ["Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila", "București", "Buzău", "Călărași", "Caraș-Severin", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Iași", "Ialomița", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Sălaj", "Satu Mare", "Sibiu", "Suceava", "Teleorman", "Timiș", "Tulcea", "Vâlcea", "Vaslui", "Vrancea"],
    "province_labels": {
        "Alba": "Alba",
        "Arad": "Arad",
        "Argeș": "Argeș",
        "Bacău": "Bacău",
        "Bihor": "Bihor",
        "Bistrița-Năsăud": "Bistrița-Năsăud",
        "Botoșani": "Botoșani",
        "Brăila": "Brăila",
        "Brașov": "Brașov",
        "București": "București",
        "Buzău": "Buzău",
        "Caraș-Severin": "Caraș-Severin",
        "Cluj": "Cluj",
        "Constanța": "Constanța",
        "Covasna": "Covasna",
        "Călărași": "Călărași",
        "Dolj": "Dolj",
        "Dâmbovița": "Dâmbovița",
        "Galați": "Galați",
        "Giurgiu": "Giurgiu",
        "Gorj": "Gorj",
        "Harghita": "Harghita",
        "Hunedoara": "Hunedoara",
        "Ialomița": "Ialomița",
        "Iași": "Iași",
        "Ilfov": "Ilfov",
        "Maramureș": "Maramureș",
        "Mehedinți": "Mehedinți",
        "Mureș": "Mureș",
        "Neamț": "Neamț",
        "Olt": "Olt",
        "Prahova": "Prahova",
        "Sălaj": "Sălaj",
        "Satu Mare": "Satu Mare",
        "Sibiu": "Sibiu",
        "Suceava": "Suceava",
        "Teleorman": "Teleorman",
        "Timiș": "Timiș",
        "Tulcea": "Tulcea",
        "Vâlcea": "Vâlcea",
        "Vaslui": "Vaslui",
        "Vrancea": "Vrancea"
    },
    "province_codes": {
        "Alba": "AB",
        "Arad": "AR",
        "Argeș": "AG",
        "Bacău": "BC",
        "Bihor": "BH",
        "Bistrița-Năsăud": "BN",
        "Botoșani": "BT",
        "Brașov": "BV",
        "Brăila": "BR",
        "București": "B",
        "Buzău": "BZ",
        "Călărași": "CL",
        "Caraș-Severin": "CS",
        "Cluj": "CJ",
        "Constanța": "CT",
        "Covasna": "CV",
        "Dâmbovița": "DB",
        "Dolj": "DJ",
        "Galați": "GL",
        "Giurgiu": "GR",
        "Gorj": "GJ",
        "Harghita": "HR",
        "Hunedoara": "HD",
        "Iași": "IS",
        "Ialomița": "IL",
        "Ilfov": "IF",
        "Maramureș": "MM",
        "Mehedinți": "MH",
        "Mureș": "MS",
        "Neamț": "NT",
        "Olt": "OT",
        "Prahova": "PH",
        "Sălaj": "SJ",
        "Satu Mare": "SM",
        "Sibiu": "SB",
        "Suceava": "SV",
        "Teleorman": "TR",
        "Timiș": "TM",
        "Tulcea": "TL",
        "Vâlcea": "VL",
        "Vaslui": "VS",
        "Vrancea": "VN"
    },
    "province_alternate_names": {
        "Alba": null,
        "Arad": null,
        "Argeș": ["Arge?"],
        "Bacău": ["Bacau"],
        "Bihor": null,
        "Bistrița-Năsăud": ["Bistri?a-Nasaud"],
        "Botoșani": ["Boto?ani"],
        "Brașov": ["Bra?ov"],
        "Brăila": ["Braila"],
        "București": ["Bucure?ti"],
        "Buzău": ["Buzau"],
        "Călărași": ["Calara?i"],
        "Caraș-Severin": ["Cara?-Severin"],
        "Cluj": null,
        "Constanța": ["Constan?a"],
        "Covasna": null,
        "Dâmbovița": ["Dambovi?a"],
        "Dolj": null,
        "Galați": ["Gala?i"],
        "Giurgiu": null,
        "Gorj": null,
        "Harghita": null,
        "Hunedoara": null,
        "Iași": ["Ia?i"],
        "Ialomița": ["Ialomi?a"],
        "Ilfov": null,
        "Maramureș": ["Maramure?"],
        "Mehedinți": ["Mehedin?i"],
        "Mureș": ["Mure?"],
        "Neamț": ["Neam?"],
        "Olt": null,
        "Prahova": null,
        "Sălaj": ["Salaj"],
        "Satu Mare": null,
        "Sibiu": null,
        "Suceava": null,
        "Teleorman": null,
        "Timiș": ["Timi?"],
        "Tulcea": null,
        "Vâlcea": ["Valcea"],
        "Vaslui": null,
        "Vrancea": null
    }
},
{
    "name": "Russia",
    "code": "RU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": ["Republic of Adygeya", "Altai Republic", "Altai Krai", "Amur Oblast", "Arkhangelsk Oblast", "Astrakhan Oblast", "Republic of Bashkortostan", "Belgorod Oblast", "Bryansk Oblast", "Republic of Buryatia", "Chechen Republic", "Chelyabinsk Oblast", "Chukotka Autonomous Okrug", "Chuvash Republic", "Republic of Dagestan", "Republic of Ingushetia", "Irkutsk Oblast", "Ivanovo Oblast", "Jewish Autonomous Oblast", "Kabardino-Balkarian Republic", "Kaliningrad Oblast", "Republic of Kalmykia", "Kaluga Oblast", "Kamchatka Krai", "Karachay–Cherkess Republic", "Republic of Karelia", "Kemerovo Oblast", "Khabarovsk Krai", "Republic of Khakassia", "Khanty-Mansi Autonomous Okrug", "Kirov Oblast", "Komi Republic", "Kostroma Oblast", "Krasnodar Krai", "Krasnoyarsk Krai", "Kurgan Oblast", "Kursk Oblast", "Leningrad Oblast", "Lipetsk Oblast", "Magadan Oblast", "Mari El Republic", "Republic of Mordovia", "Moscow", "Moscow Oblast", "Murmansk Oblast", "Nizhny Novgorod Oblast", "Novgorod Oblast", "Novosibirsk Oblast", "Omsk Oblast", "Orenburg Oblast", "Oryol Oblast", "Penza Oblast", "Perm Krai", "Primorsky Krai", "Pskov Oblast", "Rostov Oblast", "Ryazan Oblast", "Saint Petersburg", "Sakha Republic (Yakutia)", "Sakhalin Oblast", "Samara Oblast", "Saratov Oblast", "Republic of North Ossetia–Alania", "Smolensk Oblast", "Stavropol Krai", "Sverdlovsk Oblast", "Tambov Oblast", "Republic of Tatarstan", "Tomsk Oblast", "Tula Oblast", "Tver Oblast", "Tyumen Oblast", "Tyva Republic", "Udmurtia", "Ulyanovsk Oblast", "Vladimir Oblast", "Volgograd Oblast", "Vologda Oblast", "Voronezh Oblast", "Yamalo-Nenets Autonomous Okrug", "Yaroslavl Oblast", "Zabaykalsky Krai"],
    "province_labels": {
        "Altai Krai": "Altai Krai",
        "Altai Republic": "Altai Republic",
        "Amur Oblast": "Amur Oblast",
        "Arkhangelsk Oblast": "Arkhangelsk Oblast",
        "Astrakhan Oblast": "Astrakhan Oblast",
        "Belgorod Oblast": "Belgorod Oblast",
        "Bryansk Oblast": "Bryansk Oblast",
        "Chechen Republic": "Chechen Republic",
        "Chelyabinsk Oblast": "Chelyabinsk Oblast",
        "Chukotka Autonomous Okrug": "Chukotka Autonomous Okrug",
        "Chuvash Republic": "Chuvash Republic",
        "Irkutsk Oblast": "Irkutsk Oblast",
        "Ivanovo Oblast": "Ivanovo Oblast",
        "Jewish Autonomous Oblast": "Jewish Autonomous Oblast",
        "Kabardino-Balkarian Republic": "Kabardino-Balkarian Republic",
        "Kaliningrad Oblast": "Kaliningrad Oblast",
        "Kaluga Oblast": "Kaluga Oblast",
        "Kamchatka Krai": "Kamchatka Krai",
        "Karachay–Cherkess Republic": "Karachay–Cherkess Republic",
        "Kemerovo Oblast": "Kemerovo Oblast",
        "Khabarovsk Krai": "Khabarovsk Krai",
        "Khanty-Mansi Autonomous Okrug": "Khanty-Mansi Autonomous Okrug",
        "Kirov Oblast": "Kirov Oblast",
        "Komi Republic": "Komi Republic",
        "Kostroma Oblast": "Kostroma Oblast",
        "Krasnodar Krai": "Krasnodar Krai",
        "Krasnoyarsk Krai": "Krasnoyarsk Krai",
        "Kurgan Oblast": "Kurgan Oblast",
        "Kursk Oblast": "Kursk Oblast",
        "Leningrad Oblast": "Leningrad Oblast",
        "Lipetsk Oblast": "Lipetsk Oblast",
        "Magadan Oblast": "Magadan Oblast",
        "Mari El Republic": "Mari El Republic",
        "Moscow": "Moscow",
        "Moscow Oblast": "Moscow Oblast",
        "Murmansk Oblast": "Murmansk Oblast",
        "Nizhny Novgorod Oblast": "Nizhny Novgorod Oblast",
        "Novgorod Oblast": "Novgorod Oblast",
        "Novosibirsk Oblast": "Novosibirsk Oblast",
        "Omsk Oblast": "Omsk Oblast",
        "Orenburg Oblast": "Orenburg Oblast",
        "Oryol Oblast": "Oryol Oblast",
        "Penza Oblast": "Penza Oblast",
        "Perm Krai": "Perm Krai",
        "Primorsky Krai": "Primorsky Krai",
        "Pskov Oblast": "Pskov Oblast",
        "Republic of Adygeya": "Republic of Adygeya",
        "Republic of Bashkortostan": "Republic of Bashkortostan",
        "Republic of Buryatia": "Republic of Buryatia",
        "Republic of Dagestan": "Republic of Dagestan",
        "Republic of Ingushetia": "Republic of Ingushetia",
        "Republic of Kalmykia": "Republic of Kalmykia",
        "Republic of Karelia": "Republic of Karelia",
        "Republic of Khakassia": "Republic of Khakassia",
        "Republic of Mordovia": "Republic of Mordovia",
        "Republic of North Ossetia–Alania": "Republic of North Ossetia–Alania",
        "Republic of Tatarstan": "Republic of Tatarstan",
        "Rostov Oblast": "Rostov Oblast",
        "Ryazan Oblast": "Ryazan Oblast",
        "Saint Petersburg": "Saint Petersburg",
        "Sakha Republic (Yakutia)": "Sakha Republic (Yakutia)",
        "Sakhalin Oblast": "Sakhalin Oblast",
        "Samara Oblast": "Samara Oblast",
        "Saratov Oblast": "Saratov Oblast",
        "Smolensk Oblast": "Smolensk Oblast",
        "Stavropol Krai": "Stavropol Krai",
        "Sverdlovsk Oblast": "Sverdlovsk Oblast",
        "Tambov Oblast": "Tambov Oblast",
        "Tomsk Oblast": "Tomsk Oblast",
        "Tula Oblast": "Tula Oblast",
        "Tver Oblast": "Tver Oblast",
        "Tyumen Oblast": "Tyumen Oblast",
        "Tyva Republic": "Tyva Republic",
        "Udmurtia": "Udmurtia",
        "Ulyanovsk Oblast": "Ulyanovsk Oblast",
        "Vladimir Oblast": "Vladimir Oblast",
        "Volgograd Oblast": "Volgograd Oblast",
        "Vologda Oblast": "Vologda Oblast",
        "Voronezh Oblast": "Voronezh Oblast",
        "Yamalo-Nenets Autonomous Okrug": "Yamalo-Nenets Autonomous Okrug",
        "Yaroslavl Oblast": "Yaroslavl Oblast",
        "Zabaykalsky Krai": "Zabaykalsky Krai"
    },
    "province_codes": {
        "Republic of Adygeya": "AD",
        "Altai Republic": "AL",
        "Altai Krai": "ALT",
        "Amur Oblast": "AMU",
        "Arkhangelsk Oblast": "ARK",
        "Astrakhan Oblast": "AST",
        "Republic of Bashkortostan": "BA",
        "Belgorod Oblast": "BEL",
        "Bryansk Oblast": "BRY",
        "Republic of Buryatia": "BU",
        "Chechen Republic": "CE",
        "Chelyabinsk Oblast": "CHE",
        "Chukotka Autonomous Okrug": "CHU",
        "Chuvash Republic": "CU",
        "Republic of Dagestan": "DA",
        "Republic of Ingushetia": "IN",
        "Irkutsk Oblast": "IRK",
        "Ivanovo Oblast": "IVA",
        "Jewish Autonomous Oblast": "YEV",
        "Kabardino-Balkarian Republic": "KB",
        "Kaliningrad Oblast": "KGD",
        "Republic of Kalmykia": "KL",
        "Kaluga Oblast": "KLU",
        "Kamchatka Krai": "KAM",
        "Karachay–Cherkess Republic": "KC",
        "Republic of Karelia": "KR",
        "Kemerovo Oblast": "KEM",
        "Khabarovsk Krai": "KHA",
        "Republic of Khakassia": "KK",
        "Khanty-Mansi Autonomous Okrug": "KHM",
        "Kirov Oblast": "KIR",
        "Komi Republic": "KO",
        "Kostroma Oblast": "KOS",
        "Krasnodar Krai": "KDA",
        "Krasnoyarsk Krai": "KYA",
        "Kurgan Oblast": "KGN",
        "Kursk Oblast": "KRS",
        "Leningrad Oblast": "LEN",
        "Lipetsk Oblast": "LIP",
        "Magadan Oblast": "MAG",
        "Mari El Republic": "ME",
        "Republic of Mordovia": "MO",
        "Moscow": "MOW",
        "Moscow Oblast": "MOS",
        "Murmansk Oblast": "MUR",
        "Nizhny Novgorod Oblast": "NIZ",
        "Novgorod Oblast": "NGR",
        "Novosibirsk Oblast": "NVS",
        "Omsk Oblast": "OMS",
        "Orenburg Oblast": "ORE",
        "Oryol Oblast": "ORL",
        "Penza Oblast": "PNZ",
        "Perm Krai": "PER",
        "Primorsky Krai": "PRI",
        "Pskov Oblast": "PSK",
        "Rostov Oblast": "ROS",
        "Ryazan Oblast": "RYA",
        "Saint Petersburg": "SPE",
        "Sakha Republic (Yakutia)": "SA",
        "Sakhalin Oblast": "SAK",
        "Samara Oblast": "SAM",
        "Saratov Oblast": "SAR",
        "Republic of North Ossetia–Alania": "SE",
        "Smolensk Oblast": "SMO",
        "Stavropol Krai": "STA",
        "Sverdlovsk Oblast": "SVE",
        "Tambov Oblast": "TAM",
        "Republic of Tatarstan": "TA",
        "Tomsk Oblast": "TOM",
        "Tula Oblast": "TUL",
        "Tver Oblast": "TVE",
        "Tyumen Oblast": "TYU",
        "Tyva Republic": "TY",
        "Udmurtia": "UD",
        "Ulyanovsk Oblast": "ULY",
        "Vladimir Oblast": "VLA",
        "Volgograd Oblast": "VGG",
        "Vologda Oblast": "VLG",
        "Voronezh Oblast": "VOR",
        "Yamalo-Nenets Autonomous Okrug": "YAN",
        "Yaroslavl Oblast": "YAR",
        "Zabaykalsky Krai": "ZAB"
    },
    "province_alternate_names": {
        "Republic of Adygeya": null,
        "Altai Republic": null,
        "Altai Krai": null,
        "Amur Oblast": null,
        "Arkhangelsk Oblast": null,
        "Astrakhan Oblast": null,
        "Republic of Bashkortostan": null,
        "Belgorod Oblast": null,
        "Bryansk Oblast": null,
        "Republic of Buryatia": null,
        "Chechen Republic": null,
        "Chelyabinsk Oblast": null,
        "Chukotka Autonomous Okrug": null,
        "Chuvash Republic": null,
        "Republic of Dagestan": null,
        "Republic of Ingushetia": null,
        "Irkutsk Oblast": null,
        "Ivanovo Oblast": null,
        "Jewish Autonomous Oblast": null,
        "Kabardino-Balkarian Republic": null,
        "Kaliningrad Oblast": null,
        "Republic of Kalmykia": null,
        "Kaluga Oblast": null,
        "Kamchatka Krai": null,
        "Karachay–Cherkess Republic": ["Karachay?Cherkess Republic"],
        "Republic of Karelia": null,
        "Kemerovo Oblast": null,
        "Khabarovsk Krai": null,
        "Republic of Khakassia": null,
        "Khanty-Mansi Autonomous Okrug": null,
        "Kirov Oblast": null,
        "Komi Republic": null,
        "Kostroma Oblast": null,
        "Krasnodar Krai": null,
        "Krasnoyarsk Krai": null,
        "Kurgan Oblast": null,
        "Kursk Oblast": null,
        "Leningrad Oblast": null,
        "Lipetsk Oblast": null,
        "Magadan Oblast": null,
        "Mari El Republic": null,
        "Republic of Mordovia": null,
        "Moscow": null,
        "Moscow Oblast": null,
        "Murmansk Oblast": null,
        "Nizhny Novgorod Oblast": null,
        "Novgorod Oblast": null,
        "Novosibirsk Oblast": null,
        "Omsk Oblast": null,
        "Orenburg Oblast": null,
        "Oryol Oblast": null,
        "Penza Oblast": null,
        "Perm Krai": null,
        "Primorsky Krai": null,
        "Pskov Oblast": null,
        "Rostov Oblast": null,
        "Ryazan Oblast": null,
        "Saint Petersburg": null,
        "Sakha Republic (Yakutia)": null,
        "Sakhalin Oblast": null,
        "Samara Oblast": null,
        "Saratov Oblast": null,
        "Republic of North Ossetia–Alania": ["Republic of North Ossetia?Alania"],
        "Smolensk Oblast": null,
        "Stavropol Krai": null,
        "Sverdlovsk Oblast": null,
        "Tambov Oblast": null,
        "Republic of Tatarstan": null,
        "Tomsk Oblast": null,
        "Tula Oblast": null,
        "Tver Oblast": null,
        "Tyumen Oblast": null,
        "Tyva Republic": null,
        "Udmurtia": null,
        "Ulyanovsk Oblast": null,
        "Vladimir Oblast": null,
        "Volgograd Oblast": null,
        "Vologda Oblast": null,
        "Voronezh Oblast": null,
        "Yamalo-Nenets Autonomous Okrug": null,
        "Yaroslavl Oblast": null,
        "Zabaykalsky Krai": null
    }
},
{
    "name": "Rwanda",
    "code": "RW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Barthélemy",
    "code": "BL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Helena",
    "code": "SH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Kitts And Nevis",
    "code": "KN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Lucia",
    "code": "LC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Martin",
    "code": "MF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saint Pierre And Miquelon",
    "code": "PM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Samoa",
    "code": "WS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "San Marino",
    "code": "SM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Sao Tome And Principe",
    "code": "ST",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Saudi Arabia",
    "code": "SA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Senegal",
    "code": "SN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Serbia",
    "code": "RS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Seychelles",
    "code": "SC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Sierra Leone",
    "code": "SL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Singapore",
    "code": "SG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Sint Maarten",
    "code": "SX",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Slovakia",
    "code": "SK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Slovenia",
    "code": "SI",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Solomon Islands",
    "code": "SB",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Somalia",
    "code": "SO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "South Africa",
    "code": "ZA",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Eastern Cape", "Free State", "Gauteng", "KwaZulu-Natal", "Limpopo", "Mpumalanga", "North West", "Northern Cape", "Western Cape"],
    "province_labels": {
        "Eastern Cape": "Eastern Cape",
        "Free State": "Free State",
        "Gauteng": "Gauteng",
        "KwaZulu-Natal": "KwaZulu-Natal",
        "Limpopo": "Limpopo",
        "Mpumalanga": "Mpumalanga",
        "North West": "North West",
        "Northern Cape": "Northern Cape",
        "Western Cape": "Western Cape"
    },
    "province_codes": {
        "Eastern Cape": "EC",
        "Free State": "FS",
        "Gauteng": "GT",
        "KwaZulu-Natal": "NL",
        "Limpopo": "LP",
        "Mpumalanga": "MP",
        "North West": "NW",
        "Northern Cape": "NC",
        "Western Cape": "WC"
    },
    "province_alternate_names": {
        "Eastern Cape": null,
        "Free State": null,
        "Gauteng": null,
        "KwaZulu-Natal": null,
        "Limpopo": null,
        "Mpumalanga": null,
        "North West": null,
        "Northern Cape": null,
        "Western Cape": null
    }
},
{
    "name": "South Georgia And The South Sandwich Islands",
    "code": "GS",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "South Korea",
    "code": "KR",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Busan", "Chungbuk", "Chungnam", "Daegu", "Daejeon", "Gangwon", "Gwangju", "Gyeongbuk", "Gyeonggi", "Gyeongnam", "Incheon", "Jeju", "Jeonbuk", "Jeonnam", "Sejong", "Seoul", "Ulsan"],
    "province_labels": {
        "Busan": "Busan",
        "Chungbuk": "Chungbuk",
        "Chungnam": "Chungnam",
        "Daegu": "Daegu",
        "Daejeon": "Daejeon",
        "Gangwon": "Gangwon",
        "Gwangju": "Gwangju",
        "Gyeongbuk": "Gyeongbuk",
        "Gyeonggi": "Gyeonggi",
        "Gyeongnam": "Gyeongnam",
        "Incheon": "Incheon",
        "Jeju": "Jeju",
        "Jeonbuk": "Jeonbuk",
        "Jeonnam": "Jeonnam",
        "Sejong": "Sejong",
        "Seoul": "Seoul",
        "Ulsan": "Ulsan"
    },
    "province_codes": {
        "Busan": "KR-26",
        "Chungbuk": "KR-43",
        "Chungnam": "KR-44",
        "Daegu": "KR-27",
        "Daejeon": "KR-30",
        "Gangwon": "KR-42",
        "Gwangju": "KR-29",
        "Gyeongbuk": "KR-47",
        "Gyeonggi": "KR-41",
        "Gyeongnam": "KR-48",
        "Incheon": "KR-28",
        "Jeju": "KR-49",
        "Jeonbuk": "KR-45",
        "Jeonnam": "KR-46",
        "Sejong": "KR-50",
        "Seoul": "KR-11",
        "Ulsan": "KR-31"
    },
    "province_alternate_names": {
        "Busan": null,
        "Chungbuk": null,
        "Chungnam": null,
        "Daegu": null,
        "Daejeon": null,
        "Gangwon": null,
        "Gwangju": null,
        "Gyeongbuk": null,
        "Gyeonggi": null,
        "Gyeongnam": null,
        "Incheon": null,
        "Jeju": null,
        "Jeonbuk": null,
        "Jeonnam": null,
        "Sejong": null,
        "Seoul": null,
        "Ulsan": null
    }
},
{
    "name": "South Sudan",
    "code": "SS",
    "label": null,
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Spain",
    "code": "ES",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["A Coruña", "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Ávila", "Badajoz", "Balears", "Barcelona", "Burgos", "Cáceres", "Cádiz", "Cantabria", "Castellón", "Ceuta", "Ciudad Real", "Córdoba", "Cuenca", "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca", "Jaén", "La Rioja", "Las Palmas", "León", "Lleida", "Lugo", "Madrid", "Málaga", "Melilla", "Murcia", "Navarra", "Ourense", "Palencia", "Pontevedra", "Salamanca", "Santa Cruz de Tenerife", "Segovia", "Sevilla", "Soria", "Tarragona", "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya", "Zamora", "Zaragoza"],
    "province_labels": {
        "A Coruña": "A Coruña",
        "Álava": "Álava",
        "Albacete": "Albacete",
        "Alicante": "Alicante",
        "Almería": "Almería",
        "Asturias": "Asturias",
        "Ávila": "Ávila",
        "Badajoz": "Badajoz",
        "Balears": "Balears",
        "Barcelona": "Barcelona",
        "Burgos": "Burgos",
        "Cáceres": "Cáceres",
        "Cádiz": "Cádiz",
        "Cantabria": "Cantabria",
        "Castellón": "Castellón",
        "Ceuta": "Ceuta",
        "Ciudad Real": "Ciudad Real",
        "Córdoba": "Córdoba",
        "Cuenca": "Cuenca",
        "Girona": "Girona",
        "Granada": "Granada",
        "Guadalajara": "Guadalajara",
        "Guipúzcoa": "Guipúzcoa",
        "Huelva": "Huelva",
        "Huesca": "Huesca",
        "Jaén": "Jaén",
        "La Rioja": "La Rioja",
        "Las Palmas": "Las Palmas",
        "León": "León",
        "Lleida": "Lleida",
        "Lugo": "Lugo",
        "Madrid": "Madrid",
        "Málaga": "Málaga",
        "Melilla": "Melilla",
        "Murcia": "Murcia",
        "Navarra": "Navarra",
        "Ourense": "Ourense",
        "Palencia": "Palencia",
        "Pontevedra": "Pontevedra",
        "Salamanca": "Salamanca",
        "Santa Cruz de Tenerife": "Santa Cruz de Tenerife",
        "Segovia": "Segovia",
        "Sevilla": "Sevilla",
        "Soria": "Soria",
        "Tarragona": "Tarragona",
        "Teruel": "Teruel",
        "Toledo": "Toledo",
        "Valencia": "Valencia",
        "Valladolid": "Valladolid",
        "Vizcaya": "Vizcaya",
        "Zamora": "Zamora",
        "Zaragoza": "Zaragoza"
    },
    "province_codes": {
        "A Coruña": "C",
        "Álava": "VI",
        "Albacete": "AB",
        "Alicante": "A",
        "Almería": "AL",
        "Asturias": "O",
        "Ávila": "AV",
        "Badajoz": "BA",
        "Balears": "PM",
        "Barcelona": "B",
        "Burgos": "BU",
        "Cáceres": "CC",
        "Cádiz": "CA",
        "Cantabria": "S",
        "Castellón": "CS",
        "Ceuta": "CE",
        "Ciudad Real": "CR",
        "Córdoba": "CO",
        "Cuenca": "CU",
        "Girona": "GI",
        "Granada": "GR",
        "Guadalajara": "GU",
        "Guipúzcoa": "SS",
        "Huelva": "H",
        "Huesca": "HU",
        "Jaén": "J",
        "La Rioja": "LO",
        "Las Palmas": "GC",
        "León": "LE",
        "Lleida": "L",
        "Lugo": "LU",
        "Madrid": "M",
        "Málaga": "MA",
        "Melilla": "ML",
        "Murcia": "MU",
        "Navarra": "NA",
        "Ourense": "OR",
        "Palencia": "P",
        "Pontevedra": "PO",
        "Salamanca": "SA",
        "Santa Cruz de Tenerife": "TF",
        "Segovia": "SG",
        "Sevilla": "SE",
        "Soria": "SO",
        "Tarragona": "T",
        "Teruel": "TE",
        "Toledo": "TO",
        "Valencia": "V",
        "Valladolid": "VA",
        "Vizcaya": "BI",
        "Zamora": "ZA",
        "Zaragoza": "Z"
    },
    "province_alternate_names": {
        "A Coruña": ["A Coruna"],
        "Álava": ["Alava"],
        "Albacete": null,
        "Alicante": null,
        "Almería": ["Almeria"],
        "Asturias": null,
        "Ávila": ["Avila"],
        "Badajoz": null,
        "Balears": ["Baleares"],
        "Barcelona": null,
        "Burgos": null,
        "Cáceres": ["Caceres"],
        "Cádiz": ["Cadiz"],
        "Cantabria": null,
        "Castellón": ["Castellon", "Castelló", "Castello"],
        "Ceuta": null,
        "Ciudad Real": null,
        "Córdoba": ["Cordoba", "Cordova"],
        "Cuenca": null,
        "Girona": ["Gerona"],
        "Granada": null,
        "Guadalajara": null,
        "Guipúzcoa": ["Guipuzcoa", "Gipuzkoa"],
        "Huelva": null,
        "Huesca": ["Uesca", "Osca"],
        "Jaén": ["Jaen"],
        "La Rioja": null,
        "Las Palmas": null,
        "León": ["Leon"],
        "Lleida": ["Lérida", "Lerida"],
        "Lugo": null,
        "Madrid": null,
        "Málaga": ["Malaga"],
        "Melilla": null,
        "Murcia": null,
        "Navarra": ["Nafarroa", "Navarre"],
        "Ourense": null,
        "Palencia": null,
        "Pontevedra": null,
        "Salamanca": null,
        "Santa Cruz de Tenerife": ["Santa Cruz"],
        "Segovia": null,
        "Sevilla": ["Seville"],
        "Soria": null,
        "Tarragona": null,
        "Teruel": null,
        "Toledo": null,
        "Valencia": ["València"],
        "Valladolid": null,
        "Vizcaya": ["Biscay", "Bizkaia"],
        "Zamora": null,
        "Zaragoza": ["Saragossa"]
    }
},
{
    "name": "Sri Lanka",
    "code": "LK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "St. Vincent",
    "code": "VC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Sudan",
    "code": "SD",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Suriname",
    "code": "SR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Svalbard And Jan Mayen",
    "code": "SJ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Swaziland",
    "code": "SZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Sweden",
    "code": "SE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Switzerland",
    "code": "CH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Syria",
    "code": "SY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Taiwan",
    "code": "TW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tajikistan",
    "code": "TJ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tanzania, United Republic Of",
    "code": "TZ",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Thailand",
    "code": "TH",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": ["Amnat Charoen", "Ang Thong", "Bangkok", "Bueng Kan", "Buriram", "Chachoengsao", "Chai Nat", "Chaiyaphum", "Chanthaburi", "Chiang Mai", "Chiang Rai", "Chon Buri", "Chumphon", "Kalasin", "Kamphaeng Phet", "Kanchanaburi", "Khon Kaen", "Krabi", "Lampang", "Lamphun", "Loei", "Lopburi", "Mae Hong Son", "Maha Sarakham", "Mukdahan", "Nakhon Nayok", "Nakhon Pathom", "Nakhon Phanom", "Nakhon Ratchasima", "Nakhon Sawan", "Nakhon Si Thammarat", "Nan", "Narathiwat", "Nong Bua Lam Phu", "Nong Khai", "Nonthaburi", "Pathum Thani", "Pattani", "Pattaya", "Phangnga", "Phatthalung", "Phayao", "Phetchabun", "Phetchaburi", "Phichit", "Phitsanulok", "Phra Nakhon Si Ayutthaya", "Phrae", "Phuket", "Prachin Buri", "Prachuap Khiri Khan", "Ranong", "Ratchaburi", "Rayong", "Roi Et", "Sa Kaeo", "Sakon Nakhon", "Samut Prakan", "Samut Sakhon", "Samut Songkhram", "Saraburi", "Satun", "Sing Buri", "Sisaket", "Songkhla", "Sukhothai", "Suphan Buri", "Surat Thani", "Surin", "Tak", "Trang", "Trat", "Ubon Ratchathani", "Udon Thani", "Uthai Thani", "Uttaradit", "Yala", "Yasothon"],
    "province_labels": {
        "Amnat Charoen": "Amnat Charoen",
        "Ang Thong": "Ang Thong",
        "Bangkok": "Bangkok",
        "Bueng Kan": "Bueng Kan",
        "Buriram": "Buriram",
        "Chachoengsao": "Chachoengsao",
        "Chai Nat": "Chai Nat",
        "Chaiyaphum": "Chaiyaphum",
        "Chanthaburi": "Chanthaburi",
        "Chiang Mai": "Chiang Mai",
        "Chiang Rai": "Chiang Rai",
        "Chon Buri": "Chon Buri",
        "Chumphon": "Chumphon",
        "Kalasin": "Kalasin",
        "Kamphaeng Phet": "Kamphaeng Phet",
        "Kanchanaburi": "Kanchanaburi",
        "Khon Kaen": "Khon Kaen",
        "Krabi": "Krabi",
        "Lampang": "Lampang",
        "Lamphun": "Lamphun",
        "Loei": "Loei",
        "Lopburi": "Lopburi",
        "Mae Hong Son": "Mae Hong Son",
        "Maha Sarakham": "Maha Sarakham",
        "Mukdahan": "Mukdahan",
        "Nakhon Nayok": "Nakhon Nayok",
        "Nakhon Pathom": "Nakhon Pathom",
        "Nakhon Phanom": "Nakhon Phanom",
        "Nakhon Ratchasima": "Nakhon Ratchasima",
        "Nakhon Sawan": "Nakhon Sawan",
        "Nakhon Si Thammarat": "Nakhon Si Thammarat",
        "Nan": "Nan",
        "Narathiwat": "Narathiwat",
        "Nong Bua Lam Phu": "Nong Bua Lam Phu",
        "Nong Khai": "Nong Khai",
        "Nonthaburi": "Nonthaburi",
        "Pathum Thani": "Pathum Thani",
        "Pattani": "Pattani",
        "Pattaya": "Pattaya",
        "Phangnga": "Phangnga",
        "Phatthalung": "Phatthalung",
        "Phayao": "Phayao",
        "Phetchabun": "Phetchabun",
        "Phetchaburi": "Phetchaburi",
        "Phichit": "Phichit",
        "Phitsanulok": "Phitsanulok",
        "Phra Nakhon Si Ayutthaya": "Phra Nakhon Si Ayutthaya",
        "Phrae": "Phrae",
        "Phuket": "Phuket",
        "Prachin Buri": "Prachin Buri",
        "Prachuap Khiri Khan": "Prachuap Khiri Khan",
        "Ranong": "Ranong",
        "Ratchaburi": "Ratchaburi",
        "Rayong": "Rayong",
        "Roi Et": "Roi Et",
        "Sa Kaeo": "Sa Kaeo",
        "Sakon Nakhon": "Sakon Nakhon",
        "Samut Prakan": "Samut Prakan",
        "Samut Sakhon": "Samut Sakhon",
        "Samut Songkhram": "Samut Songkhram",
        "Saraburi": "Saraburi",
        "Satun": "Satun",
        "Sing Buri": "Sing Buri",
        "Sisaket": "Sisaket",
        "Songkhla": "Songkhla",
        "Sukhothai": "Sukhothai",
        "Suphan Buri": "Suphan Buri",
        "Surat Thani": "Surat Thani",
        "Surin": "Surin",
        "Tak": "Tak",
        "Trang": "Trang",
        "Trat": "Trat",
        "Ubon Ratchathani": "Ubon Ratchathani",
        "Udon Thani": "Udon Thani",
        "Uthai Thani": "Uthai Thani",
        "Uttaradit": "Uttaradit",
        "Yala": "Yala",
        "Yasothon": "Yasothon"
    },
    "province_codes": {
        "Amnat Charoen": "TH-37",
        "Ang Thong": "TH-15",
        "Bangkok": "TH-10",
        "Bueng Kan": "TH-38",
        "Buriram": "TH-31",
        "Chachoengsao": "TH-24",
        "Chai Nat": "TH-18",
        "Chaiyaphum": "TH-36",
        "Chanthaburi": "TH-22",
        "Chiang Mai": "TH-50",
        "Chiang Rai": "TH-57",
        "Chon Buri": "TH-20",
        "Chumphon": "TH-86",
        "Kalasin": "TH-46",
        "Kamphaeng Phet": "TH-62",
        "Kanchanaburi": "TH-71",
        "Khon Kaen": "TH-40",
        "Krabi": "TH-81",
        "Lampang": "TH-52",
        "Lamphun": "TH-51",
        "Loei": "TH-42",
        "Lopburi": "TH-16",
        "Mae Hong Son": "TH-58",
        "Maha Sarakham": "TH-44",
        "Mukdahan": "TH-49",
        "Nakhon Nayok": "TH-26",
        "Nakhon Pathom": "TH-73",
        "Nakhon Phanom": "TH-48",
        "Nakhon Ratchasima": "TH-30",
        "Nakhon Sawan": "TH-60",
        "Nakhon Si Thammarat": "TH-80",
        "Nan": "TH-55",
        "Narathiwat": "TH-96",
        "Nong Bua Lam Phu": "TH-39",
        "Nong Khai": "TH-43",
        "Nonthaburi": "TH-12",
        "Pathum Thani": "TH-13",
        "Pattani": "TH-94",
        "Pattaya": "TH-S",
        "Phangnga": "TH-82",
        "Phatthalung": "TH-93",
        "Phayao": "TH-56",
        "Phetchabun": "TH-67",
        "Phetchaburi": "TH-76",
        "Phichit": "TH-66",
        "Phitsanulok": "TH-65",
        "Phra Nakhon Si Ayutthaya": "TH-14",
        "Phrae": "TH-54",
        "Phuket": "TH-83",
        "Prachin Buri": "TH-25",
        "Prachuap Khiri Khan": "TH-77",
        "Ranong": "TH-85",
        "Ratchaburi": "TH-70",
        "Rayong": "TH-21",
        "Roi Et": "TH-45",
        "Sa Kaeo": "TH-27",
        "Sakon Nakhon": "TH-47",
        "Samut Prakan": "TH-11",
        "Samut Sakhon": "TH-74",
        "Samut Songkhram": "TH-75",
        "Saraburi": "TH-19",
        "Satun": "TH-91",
        "Sing Buri": "TH-17",
        "Sisaket": "TH-33",
        "Songkhla": "TH-90",
        "Sukhothai": "TH-64",
        "Suphan Buri": "TH-72",
        "Surat Thani": "TH-84",
        "Surin": "TH-32",
        "Tak": "TH-63",
        "Trang": "TH-92",
        "Trat": "TH-23",
        "Ubon Ratchathani": "TH-34",
        "Udon Thani": "TH-41",
        "Uthai Thani": "TH-61",
        "Uttaradit": "TH-53",
        "Yala": "TH-95",
        "Yasothon": "TH-35"
    },
    "province_alternate_names": {
        "Amnat Charoen": null,
        "Ang Thong": null,
        "Bangkok": ["Krung Thep Maha Nakhon"],
        "Bueng Kan": null,
        "Buriram": ["Buri Ram"],
        "Chachoengsao": null,
        "Chai Nat": null,
        "Chaiyaphum": null,
        "Chanthaburi": null,
        "Chiang Mai": null,
        "Chiang Rai": null,
        "Chon Buri": null,
        "Chumphon": null,
        "Kalasin": null,
        "Kamphaeng Phet": null,
        "Kanchanaburi": null,
        "Khon Kaen": null,
        "Krabi": null,
        "Lampang": null,
        "Lamphun": null,
        "Loei": null,
        "Lopburi": ["Lop Buri"],
        "Mae Hong Son": null,
        "Maha Sarakham": null,
        "Mukdahan": null,
        "Nakhon Nayok": null,
        "Nakhon Pathom": null,
        "Nakhon Phanom": null,
        "Nakhon Ratchasima": null,
        "Nakhon Sawan": null,
        "Nakhon Si Thammarat": null,
        "Nan": null,
        "Narathiwat": null,
        "Nong Bua Lam Phu": null,
        "Nong Khai": null,
        "Nonthaburi": null,
        "Pathum Thani": null,
        "Pattani": null,
        "Pattaya": null,
        "Phangnga": null,
        "Phatthalung": null,
        "Phayao": null,
        "Phetchabun": null,
        "Phetchaburi": null,
        "Phichit": null,
        "Phitsanulok": null,
        "Phra Nakhon Si Ayutthaya": null,
        "Phrae": null,
        "Phuket": null,
        "Prachin Buri": null,
        "Prachuap Khiri Khan": null,
        "Ranong": null,
        "Ratchaburi": null,
        "Rayong": null,
        "Roi Et": null,
        "Sa Kaeo": null,
        "Sakon Nakhon": null,
        "Samut Prakan": null,
        "Samut Sakhon": null,
        "Samut Songkhram": null,
        "Saraburi": null,
        "Satun": null,
        "Sing Buri": null,
        "Sisaket": ["Si Sa Ket"],
        "Songkhla": null,
        "Sukhothai": null,
        "Suphan Buri": null,
        "Surat Thani": null,
        "Surin": null,
        "Tak": null,
        "Trang": null,
        "Trat": null,
        "Ubon Ratchathani": null,
        "Udon Thani": null,
        "Uthai Thani": null,
        "Uttaradit": null,
        "Yala": null,
        "Yasothon": null
    }
},
{
    "name": "Timor Leste",
    "code": "TL",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Togo",
    "code": "TG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tokelau",
    "code": "TK",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tonga",
    "code": "TO",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Trinidad and Tobago",
    "code": "TT",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tunisia",
    "code": "TN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Turkey",
    "code": "TR",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Turkmenistan",
    "code": "TM",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Turks and Caicos Islands",
    "code": "TC",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Tuvalu",
    "code": "TV",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Uganda",
    "code": "UG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Ukraine",
    "code": "UA",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "United Arab Emirates",
    "code": "AE",
    "label": "Emirate",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Emirate",
    "provinces": ["Abu Dhabi", "Ajman", "Dubai", "Fujairah", "Ras al-Khaimah", "Sharjah", "Umm al-Quwain"],
    "province_labels": {
        "Abu Dhabi": "Abu Dhabi",
        "Ajman": "Ajman",
        "Dubai": "Dubai",
        "Fujairah": "Fujairah",
        "Ras al-Khaimah": "Ras al-Khaimah",
        "Sharjah": "Sharjah",
        "Umm al-Quwain": "Umm al-Quwain"
    },
    "province_codes": {
        "Abu Dhabi": "AZ",
        "Ajman": "AJ",
        "Dubai": "DU",
        "Fujairah": "FU",
        "Ras al-Khaimah": "RK",
        "Sharjah": "SH",
        "Umm al-Quwain": "UQ"
    },
    "province_alternate_names": {
        "Abu Dhabi": null,
        "Ajman": null,
        "Dubai": null,
        "Fujairah": null,
        "Ras al-Khaimah": null,
        "Sharjah": null,
        "Umm al-Quwain": null
    }
},
{
    "name": "United Kingdom",
    "code": "GB",
    "label": "Region",
    "zip_label": "Postcode",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},

{
    "name": "United States Minor Outlying Islands",
    "code": "UM",
    "label": "State",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "State",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Uruguay",
    "code": "UY",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Uzbekistan",
    "code": "UZ",
    "label": "Province",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Province",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Vanuatu",
    "code": "VU",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Venezuela",
    "code": "VE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Vietnam",
    "code": "VN",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Virgin Islands, British",
    "code": "VG",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Wallis And Futuna",
    "code": "WF",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Western Sahara",
    "code": "EH",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Yemen",
    "code": "YE",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Zambia",
    "code": "ZM",
    "label": null,
    "zip_label": "Postal code",
    "zip_required": true,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
},
{
    "name": "Zimbabwe",
    "code": "ZW",
    "label": "Region",
    "zip_label": "Postal code",
    "zip_required": false,
    "province_label": "Region",
    "provinces": null,
    "province_labels": null,
    "province_codes": {},
    "province_alternate_names": {}
}
]