import { environment } from "../../../Environments/environment";
import { PostAsyncHelper } from "../../../redux/interfaces/types";


export const GenerateOTP = async (email) => {
  let obj = {
    email: email ?? "",
  };

  let api_url = `${environment.apiUrl}muv-otp/generateOtp`;
  const response = await PostAsyncHelper(api_url, obj);
  const results = response?.data;
  return results || null;
};

export const VerifyOTP = async (data) => {
  let obj = {
    otp_unique_session_id: data?.sessionID,
    otp_code: data?.otp,
    first_name: data?.first_name,
    last_name: data?.last_name,
    phone: data?.phone,
  };

  let api_url = `${environment.apiUrl}muv-otp/assertOtp`;
  const response = await PostAsyncHelper(api_url, obj);
  const results = response?.data;

  return results || null;
};
