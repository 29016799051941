import './terms.css';

import React from 'react'
import { useHistory  } from 'react-router-dom';

export const PrivacyPolicyComponent = () => {
    let padding_l = "15.7"
    let padding_r = "15.7"
    let font_size = "30"

    const history = useHistory()
    return (
        <div style={{
            paddingLeft: padding_l + "%",
            paddingRight: padding_r + "%",
            paddingBottom: 50
        }}>
            <div className="padding_top_50 padding_125rem_lr_mobile"
                style={{
                    color: "#000000",
                    font: font_size + "px/72px Futura Hv BT",
                   
                }}
            >Privacy Policy
        </div>
            <div 
                className="termsBody">
                <p>m&uuml;v is committed to protecting and respecting your privacy and we make it our highest priority to safeguard the confidentiality of any and all personal information you provide to us.</p>

<p>This policy, together with our&nbsp;<a href="/terms-and-conditions">Terms of Service</a>, sets out the approach that we, m&uuml;v Inc, take to the collection and handling of any such information and applies to all websites, mobile apps and other services operated by us such as newsletters and price alerts (the &ldquo;m&uuml;v Services&rdquo;).</p>

<p>By using the m&uuml;v Services you are deemed to agree to our use of your information in accordance with this policy. We encourage you take a few minutes to read through and&nbsp;<a href="/contact-us">contact us</a>&nbsp;if you have any queries.</p>

<p>In the event of any changes to our approach, we will update this policy and post the latest version on this page &ndash; please check back regularly to ensure you remain up to date.</p>

<h3>What kind of information does m&uuml;v collect?</h3>

<p>Most information that we collect from you is linked to non-personal identifiers such as anonymous User IDs or device IDs, and does not enable you to be identified as an individual in the offline world (&ldquo;Non-Personal Information&rdquo;). Such Non-Personal Information includes things like your browser type, the type of device you are using, access times, the website you arrived at m&uuml;v from, the features or functionality you selected whilst using the m&uuml;v Services and your general geographic location.</p>

<p>Where necessary, we will also collect information from you that does enable you to be identified as an individual in the offline world, such as your name, email address, passport number or payment details (&ldquo;Personal Information&rdquo;). In order to minimise the impact on your privacy, we will never knowingly collect more Personal Data than is strictly necessary to perform the activities described in this policy.</p>

<h3>How and when does m&uuml;v collect this information?</h3>

<p>We collect information that you voluntarily provide to us during your use of the m&uuml;v Services. This can range from Non-Personal Information, such as the dates and desired destination you ask us to run a flight search against, to the Personal Information you are required to submit where you wish to book a flight with a travel supplier via the m&uuml;v Services or the demographic and lifestyle information you accept to give m&uuml;v to allow us to personalise our suggestions. Whether or not you provide us with this kind information is always entirely under your control.</p>

<p>In addition to information that you voluntarily provide to us, we also collect certain Non-Personal Information directly from you as you use the m&uuml;v Services, as well as indirectly where it is provided to us automatically by the third party application, social networking site (such as Facebook or Twitter) or other website (such as a promotion partner) that you connect to the m&uuml;v Services from.</p>

<h3>How does m&uuml;v use this information?</h3>

<p><em>To deliver the m&uuml;v Services</em></p>

<p>We use your information to deliver the m&uuml;v Services and the specific features and functionality you request. This includes things like generating the search results that most accurately match your search criteria, storing your Personal Information to enable you to pre-fill future booking forms, facilitating the completion of any bookings with a third party supplier via the m&uuml;v Services, assisting you with post-booking issues, or emailing you newsletters or price alerts that you have selected to receive.</p>

<p><em>To optimise &amp; personalise the m&uuml;v Services</em></p>

<p>In addition to using your information to deliver the essential aspects of the m&uuml;v Services, we also use it to optimise and personalise your experience of the m&uuml;v Services. For example, we will use cookies to remember your language and currency preferences and may also personalise your experience by highlighting or recommending certain information or travel options that we believe is relevant to you based on your search history and use of the m&uuml;v Services.</p>

<p><em>To personalize our suggestions</em></p>

<p>The lifestyle and likes and dislikes information you accept to give us allows us to match you with accommodation, attractions, restaurants or activities that will fit your tastes. We do not sell, share or make this information available to anyone except when you explicitly indicate that any of the said information can be made public for identification and profiling purposes.</p>

<p>&nbsp;</p>

<p><em>To deliver appropriate advertising</em></p>

<p>We use Non-Personal Information, such as what you have searched for on the m&uuml;v Services or your general geographic location, to present you with relevant advertisements. For example, we may use third party advertisement retargeting services to send you an advertisement related to destination you have searched for. We will never use your Personal Information to do this unless you explicitly authorise us.</p>

<p><em>To better understand, improve and protect the m&uuml;v Services</em></p>

<p>We use information we collect about how users interact with the m&uuml;v Services in order to identify and correct faults, improve our services and inform business decisions, as well as for security and fraud-prevention purposes.</p>

<p><em>To generate aggregated datasets that inform our business and the travel industry</em></p>

<p>We use information we collect about users to generate datasets of Non-Personal Information. These datasets allow us to generate helpful product insights. For example, we can monitor demand for destinations to make sure the fares and the travel options our users want are available on the m&uuml;v Services.</p>

<p><em>To contact you</em></p>

<p>We use your information to contact you with confirmations, notifications or alerts you have requested, such as email newsletters or price alerts, or which are otherwise necessary for us to send to you in order to provide the m&uuml;v Services you have selected, such as details of any bookings you have made on the m&uuml;v platform. We may also notify you about any changes or updates to the m&uuml;v Services or to this policy, our our&nbsp;<a href="/terms-and-conditions">Terms of Service</a>, or to respond to communications you have sent us, such as via our customer service&nbsp;<a href="/contact-us">help centre</a>&nbsp;or on social media.</p>

<h3>Do any third parties collect information about me when I use the m&uuml;v Services?</h3>

<p>Yes, where you make a booking with a third party travel provider from within the m&uuml;v platform, any information you submit as part of the booking process may be collected by that third party in order to process the booking. Your information will be used by those third parties in accordance with their own terms and conditions and privacy policy, a link to which will be provided to you during the booking process.</p>

<p>In addition, third party advertisement services may collect Non-Personal Information from you as you use the m&uuml;v Services via cookies and other similar technologies. This information enables you to be served with relevant advertisements, both on the m&uuml;v Services and on third party websites and applications around the web. Further information about the use of such technologies, and how to stop them, is set out in the&nbsp;<em>&lsquo;How does m&uuml;v use cookies and other tracking technologies?&rsquo;</em>&nbsp;section below.</p>

<h3>When will m&uuml;v share your information with third parties?</h3>

<p><em>Sharing Personal Information with third parties who process it only under m&uuml;v&rsquo;s instruction</em></p>

<p>We share information relating to our users with selected third parties who perform services on our behalf and under our instruction (&ldquo;Third Party Processors&rdquo;). We ensure that any Third Party Processor which handles information on our behalf does so pursuant to contractual terms which require that the information is kept secure and, where that information contains any Personal Information, is processed in accordance with applicable data protection laws and used only as we have instructed and not for that Third Party Processor&rsquo;s own purposes (unless you have explicitly consented to them doing so).</p>

<p>Third Party Processors may be located in, or process your information, outside of the country in which you are based. Where our use of a Third Party Processor involves the transfer of Personal Data from within Europe to a location outside of the European Economic Area, we will put in place appropriate measures to ensure that the Personal Data is adequately protected in that location, most often by applying European Commission-approved standard contractual clauses and robust security checks.</p>

<p><em>Sharing information within the m&uuml;v group</em></p>

<p>Elements of the m&uuml;v Services are supported by group companies that are wholly owned subsidiaries of ours (&ldquo;m&uuml;v Group Companies&rdquo;). Accordingly, your information may from time to time be shared with such m&uuml;v Group Companies for processing on our behalf. Where this happens, we ensure that detailed agreements are put in place that require such m&uuml;v Group Companies to use it only in accordance with this policy and the purposes set out above.</p>

<p><em>Sharing your information with third parties for processing outside m&uuml;v&rsquo;s control</em></p>

<p>We will share your Personal Information with third parties for processing outside m&uuml;v&rsquo;s control and for their own purposes in the following two scenarios: (1) where you make a booking with a third party travel provider from within the m&uuml;v platform, any Personal Information you submit as part of the booking process will be shared with that third party in order to enable the booking to be processed; and (2) where you expressly authorise us to, such as where we run a promotion in conjunction with a partner and you instruct us to share your email address with that third party for the purpose of receiving promotional emails.</p>

<p>If you are purchasing travel on the m&uuml;v Services&rsquo; booking platform, we may share information (including Personal Information) with the travel provider for their standard verification and fraud-detection purposes, or to a third party contracted to provide those services on the travel provider&rsquo;s behalf.</p>

<p>Non-Personal Information, such as fully anonymised datasets containing aggregated m&uuml;v Services&rsquo; usage information or trend-level travel insights, may be shared by us with third parties. As described above, third party advertising businesses may collect Non-Personal Information from you via cookies and other tracking tools whilst using the m&uuml;v Services.</p>

<p><em>Disclosing information for legal and other reasons</em></p>

<p>We may disclose your information where necessary to enforce our&nbsp;<a href="/terms-and-conditions">Terms of Service</a>&nbsp;or other agreements, or to a prospective buyer or ultimate buyer if m&uuml;v itself (or part of our business) is sold. We may also disclose your information if necessary to prevent, detect or prosecute illegal or suspected illegal activities or to prevent other damage or in response to a legal action against us or to enforce our rights and claims.</p>

<h3>How does m&uuml;v keep my Personal Information safe and secure?</h3>

<p>We make the confidentiality and security of your Personal Information our highest priority and we limit access to it to only those m&uuml;v employees who need to come into contact with it in order to fulfil their role and enable the m&uuml;v Services to be provided to you.</p>

<p>Although we do our best to protect your Personal Information, the transmission of information via the internet can never be completely secure and we are therefore unable to guarantee the security of the information which you share with us at your own risk.</p>

<h3>Does m&uuml;v use cookies or other tracking tools?</h3>

<p>We use a combination of cookies and similar tracking technologies (for example, on mobile devices we use non-persistent device ID techniques like IDFA and Android Advertising) to collect, in line with this policy, Non-Personal Information from you relating to how you are using the m&uuml;v Services.</p>

<p>As explained above, third party advertisers will also collect Non-Personal Information from you using cookies and similar technologies for the purposes of serving you relevant adverts both on the m&uuml;v Services and on other third party platforms as you move around the web, including on different devices. You may be able to control how these technologies are applied to you via the settings in your browser or on your mobile&rsquo;s operating system.</p>

<h3>Do third party platforms comply with this policy?</h3>

<p>The m&uuml;v Services contain links to third party websites and applications and may sometimes give you the option to export information to those platforms. Please be aware that we are not responsible for the privacy practices of such third party websites. When you leave or otherwise cease using the m&uuml;v Services, or transfer your information from the m&uuml;v Services to a third party platform, we encourage you to read the applicable privacy policies of each and every third party platform that you allow to collect information from you.</p>

<h3>Does m&uuml;v collect information from children?</h3>

<p>The m&uuml;v Services are not intended for children under 13 years of age, and no one under the age of 13 may provide any information to, on or via the m&uuml;v Services. We do not knowingly collect Personal Information from children under 13, and will delete any that we learn we have collected or received that was not provided by, or with express consent on behalf of, the child&rsquo;s parent or legal guardian.</p>

<h3>How can I access or manage my Personal Information?</h3>

<p>If you have created an account with m&uuml;v you can manage some of your Personal Information and subscription preferences from within your profile settings.</p>

<p>You also have the right to ask us not to process your Personal Information for marketing purposes at any time. You can exercise this right by&nbsp;<a href="/contact-us">contacting us</a>&nbsp;at any time. You can also opt-out of receiving any non-essential emails, including marketing emails, by clicking the unsubscribe link contained at the bottom of such emails.</p>

<p>We are happy to provide you with details of the Personal Information we are holding about you at any point in time. Where you wish to make such a request, please submit it in writing, along with proof of your identity, to m&uuml;v, 1350 Mazurette, Suite 307, Montreal, QC H4N 1H2 marked for the attention of &lsquo;Chief Legal Officer&rsquo;.</p>

<h3>Contacting Us</h3>

<p>If you have any questions about this policy, please&nbsp;<a href="/contact-us">contact us here</a></p>
            </div>
        </div>
    )
}