import * as Actions from '../../../../redux/actions'

import { GoogleMap, InfoWindow, Marker, withGoogleMap } from "react-google-maps"
import { compose, withProps } from "recompose"

import React from 'react'
import { connect } from "react-redux"
import mapStyles from "./mapStyles"
import { generateMapAPIKey } from '../../../../utility/utility'

// import { GOOGLE_API_KEY } from "../RegExValidate"




/* wrap map component */
const WrappedMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
)((props) =>
{
    const defaultCenter ={ lat: 49.000, lng:  9.0000 }

   return ( <GoogleMap
        zoom={props.zoom}
        defaultZoom={5}
        defaultCenter={defaultCenter}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: mapStyles,
            scrollwheel: false,
            draggable: false,
            drawingControl: false,
            zoomControl: true,
            streetViewControl: false,
            clickableIcons: false,
            minZoom: 4,
            maxZoom: 21,
        }}
    >
        {
            props.destinations.map((destination, destination_idx) => {
                return (

                    <Marker
                        key={destination_idx}
                        position={{ lat: parseFloat(destination.latitude), lng: parseFloat(destination.longitude) }}
                        onClick={props.onToggleOpen}
                        icon={{
                            url: destination_idx < 9 ?
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                    <text font-size="15" font-weight="bold" fill="white">
                                        <tspan x="19" y="27">`+ (++destination_idx) + `</tspan>
                                    </text>    
                                </g>
                                </svg>`
                                :
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fill-rule="evenodd">    
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                    <text font-size="13" font-weight="bold" fill="white">
                                        <tspan x="16" y="27">`+ (++destination_idx) + `</tspan>
                                    </text>    
                                </g>
                                </svg>`,
                            size: { width: 50, height: 50 },
                            scaledSize: { width: 50, height: 50 }
                        }}
                    >
                        <InfoWindow onCloseClick={props.onToggleOpen}>
                            <div>
                                {destination.name}
                            </div>
                        </InfoWindow>
                    </Marker>
                )
            })}
        {!props.loadGoogleUrl && props.setDestinationVisitedLoadGoogleUrl(true)}

    </GoogleMap>)
    }
)
const WrappedMapNoScript = compose(
    withProps({
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
)((props) =>

{

    const defaultCenter ={ lat: 49.000, lng:  9.0000 }

    return(
    <GoogleMap
        defaultZoom={5}
        defaultCenter={defaultCenter}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: mapStyles,
            scrollwheel: false,
            draggable: true,
            drawingControl: false,
            zoomControl: true,
            streetViewControl: false,

            minZoom: 4,
            maxZoom: 21,
        }}
    >
        {
            props.destinations.map((destination, destination_idx) => {
                return (

                    <Marker
                        key={destination_idx}
                        position={{ lat: parseFloat(destination.latitude), lng: parseFloat(destination.longitude) }}
                        onClick={props.onToggleOpen}
                        icon={{
                            url: destination_idx < 9 ?
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                        <g fill-rule="evenodd">    
                                            <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                            <text font-size="15" font-weight="bold" fill="white">
                                                <tspan x="19" y="27">`+ (++destination_idx) + `</tspan>
                                            </text>    
                                        </g>
                                        </svg>`
                                :
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                        <g fill-rule="evenodd">    
                                            <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                            <text font-size="13" font-weight="bold" fill="white">
                                                <tspan x="16" y="27">`+ (++destination_idx) + `</tspan>
                                            </text>    
                                        </g>
                                        </svg>`,
                            size: { width: 50, height: 50 },
                            scaledSize: { width: 50, height: 50 }
                        }}
                    >
                        <InfoWindow onCloseClick={props.onToggleOpen}>
                            <div>
                                {destination.name}
                            </div>
                        </InfoWindow>
                    </Marker>
                )
            })}

    </GoogleMap>)
    }
)
export const Map = connect((state) => ({
    destinationVisitedSearch: state.FormBank.destinationVisitedSearch,
    hometownTextField: state.FormBank.hometownTextField,
}), {
    setDestinationVisitedLoadGoogleUrl: Actions.setDestinationVisitedLoadGoogleUrl,
},
)(({
    destinationVisitedSearch,
    setDestinationVisitedLoadGoogleUrl,
    hometownTextField,
    selectedDestinationSets = destinationVisitedSearch.selectedDestinationSets,
}) => {




    return (
        <div style={{ marginTop: "15px", height: "360px" }} id="google_map_wrap">

            {(typeof window !== 'undefined' && !destinationVisitedSearch.loadGoogleUrl && !hometownTextField.loadGoogleUrl) ?
                <WrappedMap
                    destinations={selectedDestinationSets}
                    zoom={1}
                    setDestinationVisitedLoadGoogleUrl={setDestinationVisitedLoadGoogleUrl}
                    loadGoogleUrl={destinationVisitedSearch.loadGoogleUrl}
                />
                :
                <WrappedMapNoScript
                    destinations={selectedDestinationSets}
                    zoom={1}
                />
            }
        </div>
    );
})
