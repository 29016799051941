
import { environment } from "../../../../Environments/environment";
import { PostAsyncHelper } from "../../../../redux/interfaces/types";


export const checkEmailisAvailable = async (email)=>{
 try{
    const url =  environment.apiUrl +'memberemailisavailable';
    const response = await PostAsyncHelper(url, {email:email});

     if(response?.data?.status === "success"){
         return true;
     }
     return false;
 }
 catch{
    return false;
   

 }
}