import ReactGA from 'react-ga';

export const hexToRGB = (hex, alpha) => {

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

export const capitalizeWord = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}

export const encodeID = (type, value) => {
  var ALPHA = `ABCDEFGHIJKLMNOPQRSTUVWXYZ${type}abcdefghijklmnopqrstuvwxyz0123456789+/`;
  if (typeof (value) !== 'number') {
  }

  var result = '', mod;
  do {
    mod = value % 64;
    result = ALPHA.charAt(mod) + result;
    value = Math.floor(value / 64);
  } while (value > 0);

  return result;
}


export const decodeID = (type, value) => {
  var ALPHA = `ABCDEFGHIJKLMNOPQRSTUVWXYZ${type}abcdefghijklmnopqrstuvwxyz0123456789+/`;
  var result = 0;
  for (var i = 0, len = value.length; i < len; i++) {
    result *= 64;
    result += ALPHA.indexOf(value[i]);
  }

  return result;
}

export const generateSessionId = () => {
  sessionStorage.setItem('sid', Date.now())
}


export const getSessionId = () => {
  return sessionStorage.getItem('sid');
}

function pad(n) {
  return (n < 10) ? ("0" + n) : n;
}

export function timeToHM(time) {
  try {
    const [h, m] = time.split(":");

    return `${h ? h + 'h' : ''}${m ? m + 'm' : ''}`;

  }
  catch {

  }
}
export function secondsToHms(timeString) {
  const arr = timeString?.split(":"); // splitting the string by colon
  let seconds = (arr[0] ? Number(arr[0]) * 86400 : 0) + (arr[1] ? Number(arr[1]) * 3600 : 0) + (arr[2] ? Number(arr[2]) * 60 : 0); // converting
  seconds = Number(seconds);


  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  var mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;

  var dDisplay = days > 0 ? pad(days) + "d" : "";
  var hDisplay = hrs > 0 ? pad(hrs) + "h" : "";
  var mDisplay = mnts > 0 ? pad(mnts) + "m" : "";

  return dDisplay + hDisplay + mDisplay;
}

export function TimeStringToSeconds(timeString) {
  const arr = timeString.split(":"); // splitting the string by colon
  let seconds = arr[0] * 86400 + arr[1] * 3600 + (+arr[2] * 60); // converting
  seconds = Number(seconds);

  return seconds;
}

export function DayTimeStringToSeconds(timeString) {
  const arr = timeString.split(":"); // splitting the string by colon
  let seconds = arr[0] * 3600 + arr[1] * 60; // converting
  seconds = Number(seconds) * 60;
  return seconds;
}

export function timeToDecimal(t) {
  var arr = t?.split(':');
  var dec = parseInt((arr[1] / 6) * 10, 10);

  return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export function DecimalToTime(decimal) {
  var n = new Date(0, 0);
  n.setSeconds(+decimal * 60 * 60);
  return n.toTimeString().slice(0, 5)
}


export function toHHMMSS(seconds) {
  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  var mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;

  var dDisplay = days > 0 ? pad(days) + "d" : "";
  var hDisplay = hrs > 0 ? pad(hrs) + "h" : "";
  var mDisplay = mnts > 0 ? pad(mnts) + "m" : "00";

  return dDisplay + hDisplay + mDisplay;
}

export function DurationLayover(date1, date2) {
  var timeStart = new Date(date1).getTime();
  var timeEnd = new Date(date2).getTime();
  var hourDiff = timeEnd - timeStart; //in ms
  var secDiff = hourDiff / 1000; //in s
  return secDiff ? toHHMMSS(secDiff) : null;
}

export function creditCardType(cc) {
  let amex = new RegExp('^3[47][0-9]{13}$');
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

  let mastercard = new RegExp('^5[1-5][0-9]{14}$');
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

  let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
  let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
  let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');



  if (visa.test(cc)) {
    return 'VI';
  }
  if (amex.test(cc)) {
    return 'AX';
  }
  if (mastercard.test(cc) || mastercard2.test(cc)) {
    return 'MC';
  }
  if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
    return 'DS';
  }
  return null;
}

export function tConv24(time24) {
  var ts = time24 || null;
  if (ts) {
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
  }
  return ts;
};


/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const TrackEvent = (category, action, label, data) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    ...data,
  });
};


export const getRoomData = (roomData, type) => {
  let rate = ""
  let smopRate;
  let regularRate;

  try {

    if (roomData && roomData?.length > 0) {
      rate = roomData[0];

      roomData.forEach((room)=>{
        room.rate_data.forEach((rm)=>{
          if(rm?.rate_type  !== "SMOP"){
            regularRate = room;
          }
        })
      })


      if (regularRate) {
        rate = regularRate;
      }
      if (type === "SMOP") {
        roomData.forEach((room)=>{
          room.rate_data.forEach((rm)=>{
            if(rm?.rate_type  === "SMOP"){
              smopRate = room;
            }
          })
        })

        rate = smopRate ? smopRate : rate ;
      }

   
  
  
    }

  }
  catch {

  }

  return rate;
}


export const getHotelRate = (rateData, type) => {
  let rate = ""
  let smopRate;
  let regularRate;

  try {

    if (rateData && rateData?.length > 0) {
      rate = rateData[0];
      regularRate = rateData?.find((room) => room?.rate_type !== "SMOP")

      if (regularRate) {
        rate = regularRate;
      }
      if (type === "SMOP") {
        smopRate = rateData?.find((room) => room?.rate_type === "SMOP")
        rate = smopRate && !regularRate ? smopRate : rate //only show smop if no regular rate
      }
  
    }

  }
  catch {

  }

  return rate;
}

export const getSmopRate = (rateData) => {
  let rate = null
  try {

    if (rateData && rateData?.length > 0) {
      rate = rateData?.find((room) => room?.rate_type === "SMOP")

    }

  }
  catch {

  }

  return rate;
}


export const generateMapAPIKey = () => {
  const apiKeys = ["AIzaSyDEjqqq3ZOfgmEALF1QArzGtdfv3kyClUM", "AIzaSyCS6LL6WD0yVm7frIFkXfSqmsmpqVJBpkQ", "AIzaSyAS9_BmpJxdTExXnVNEJ3yb0BRT_s08GUg"]
  const randomElement = apiKeys[Math.floor(Math.random() * apiKeys.length)];
  return randomElement;
}