import { ALERT_BARS_HANDLE } from "../interfaces/types";

const initialState = {
  messageType: "",
  message: "",
  duration: 3000,
};

/* alert bar reducer */
export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT_BARS_HANDLE:
      return {
        ...state,
        messageType: action.message_type,
        message: action.message,
        duration: action?.duration,
      };

    default:
      return {
        ...state,
      };
  }
};
