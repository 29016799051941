import { environment } from "../../../Environments/environment";
import { PostAsyncHelper } from "../../../redux/interfaces/types";


export const GetICSUrl = async (email,uuid,tripId)=>{
 try{
    const url =  environment.apiUrl +'memberarea/trip/generateMemberIcsCalendar';
    const obj= {
        uuid:uuid,
        email:email,
        tripId:tripId
    }
    const response = await PostAsyncHelper(url,obj);


     if(response?.data?.status === "success"){
         return response?.data?.result?.google_storage_uri;
     }
     return false;
 }
 catch{
    return false;
   

 }
}