import "./FooterWhite.css"

import { GoogleFooterIcon, ThumbesUpIcon, TwitterFooterIcon } from "../Common/SVGBank"

import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

/* footer white component */
const FooterWhite_ = ({ lang }) => {
    const history = useHistory()
    const goesLandingPage = () => {
        history.push("/landing")
    }
    return (
        <div>
            <footer className="footer_bottom">
                <div className="row m-0 justify-content-center" >
                    {/* <div className="col-md-2">
                        <p className="b_right" onClick={() => goesLandingPage()} style={{
                            font: "16px/22px Futura Md BT",
                            color: "var(--mainWhite)",
                            cursor: "pointer",
                        }}>FAQ</p>
                    </div> */}
                    <div className="col-md-2">
                        <p className="" onClick={() => window.open('/contact-us')} style={{
                            font: "16px/22px Futura Md BT",
                            color: "var(--mainWhite)",
                            cursor: "pointer",
                        }}>Contact Us</p>
                    </div>
                    {/* <div className="col-md-4">
                        <ul className="social_icons">
                            <li onClick={() => window.open('https://www.facebook.com/muvtravelcom/')} style={{
                                font: "16px/22px Futura Md BT",
                                color: "var(--mainWhite)",
                                cursor: "pointer",
                            }}><div style={{
                                marginRight: "10px",
                                background: "#4367b2",
                                padding: "1px 0 3px 0",
                            }}><ThumbesUpIcon fill={"var(--mainWhite)"} style={{ paddingLeft: "3px", paddingRight: "3px", }} /><span className="futura_md_bt_14_24_white margin_right_5 padding_right_5">Like</span></div></li>
                            <li className="twitterLink" onClick={() => window.open('https://twitter.com/muvtravel')} style={{
                                font: "16px/22px Futura Md BT",
                                color: "var(--mainWhite)",
                                cursor: "pointer",
                            }}><div style={{
                                marginRight: "10px",
                                padding: "1px 0 3px 0",
                                marginLeft: "10px",
                                color: "var(--mainWhite)",
                                backgroundColor: "#1a95e0",
                            }}> <TwitterFooterIcon fill={"var(--mainWhite)"} style={{ paddingLeft: "3px", paddingRight: "3px", }} /><span className="futura_md_bt_14_24_white margin_right_5  margin_left_5">Twitter</span></div></li>
                            <li className="googlePlusLink" onClick={() => window.open('https://plus.google.com/104511358001425879924')} style={{
                                font: "16px/22px Futura Md BT",
                                color: "var(--mainWhite)",
                                cursor: "pointer",
                            }}><GoogleFooterIcon fill={"var(--danger)"} style={{ paddingLeft: "3px", paddingRight: "3px", }} /></li>
                        </ul>
                    </div> */}
                    <div className="col-md-2">
                        <p className="b_left b_right"
                            onClick={() => window.open('/terms-and-conditions')} style={{
                                font: "16px/22px Futura Md BT",
                                color: "var(--mainWhite)",
                                cursor: "pointer",
                            }}
                        >Terms and conditions</p>
                    </div>
                    <div className="col-md-2">
                        <p onClick={() => window.open('/privacy-policy')} style={{
                            font: "16px/22px Futura Md BT",
                            color: "var(--mainWhite)",
                            cursor: "pointer",
                        }}>Privacy policy</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.language.selected.shortCode,
})

const mapDispatchToProps = {

}

export const FooterWhite = connect(mapStateToProps, mapDispatchToProps)(FooterWhite_)
