import * as Actions from '../../../redux/actions'
import Rating from '@material-ui/lab/Rating';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux'
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { Image } from 'react-bootstrap';

const BookingItems_ = ({formIndex, booking_bundle, booking_bundles, showMultiple = true, booking_info, establishment, is_mobile, removeBundleFromHotelBooking }) => {
    const { generalInfo, subtypes } = establishment

    let [, setState] = useState();
    let [ppn_bundles, setBundles] = useState([...booking_bundles]);

    const useFormIndex = formIndex?formIndex === 1:true; 
    React.useEffect(() => {
        setBundles(booking_bundles)
    }, [booking_bundles])

    const handleRemoveRoomBooking = (booking_bundle_idx) => {
        ppn_bundles.splice(booking_bundle_idx, 1)

        removeBundleFromHotelBooking(booking_bundle_idx, ppn_bundles)
        setState({})
    }
    const prepaidFees = booking_info?.price_details?.mandatory_fee_details?.breakdown?.prepaid?.breakdown ?? null;
    const prepaidTotal = booking_info?.price_details?.mandatory_fee_details?.breakdown?.prepaid?.display_total || 0
    const priceDetails = booking_info?.price_details;
    const postPaidFees = booking_info?.price_details?.mandatory_fee_details?.breakdown?.postpaid?.breakdown ?? null;
    const rate = booking_info?.room_data?.rate_data && booking_info?.room_data?.rate_data?.length > 0 ? booking_info?.room_data?.rate_data[0] : null
    const prepaid = rate?.payment_type === "PREPAID"
    const postPaid = rate?.payment_type === "POSTPAID"
    const postPaidTotal = booking_info?.price_details?.mandatory_fee_details?.breakdown?.postpaid?.display_total || 0;
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("rate");
    const useSmop = promoCode && promoCode?.toLowerCase() === "smop";
    const isBookingPage = document?.location?.pathname?.includes("booking");
    const cachedHotel = useSelector(state => state.HotelBooking.cachedHotel?.data);
    const useCached =   (useSmop && isBookingPage) && cachedHotel; //disable
    const prepaidCached =useCached && cachedHotel?.payment_type === "prepaid";
    const postpaidCached = useCached && cachedHotel?.payment_type !== "prepaid";
    const cachedSubtotal = cachedHotel && useCached ? cachedHotel?.display_sub_total : null;
    const prepaidCachedTotal = cachedHotel && prepaidCached? Number(cachedHotel?.display_sub_total||0) + Number(cachedHotel?.prepaid_display_total||0) : 0;
    const cachedTotal = cachedHotel  ? cachedHotel?.prepaid_display_total ? Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.prepaid_display_total) : Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.postpaid_display_total) : null;
    const isPaymentPage = document.location?.pathname?.includes("payment");




    const BundleItems = ({ booking_bundle, bidx }) => {
        return (<React.Fragment>
            <table style={{ border: "1px solid #E8E8E8", marginBottom: 15, width: "100%" }} ><tbody>

                <tr >
                    <td colSpan="2">
                        {
                            showMultiple && ppn_bundles !== undefined && ppn_bundles !== null && ppn_bundles.length > 1 && <div onClick={() => handleRemoveRoomBooking(bidx)}
                                style={{ cursor: "pointer", paddingRight: "1rem", textAlign: "right", font: "20px/30px Futura Md BT", color: "#dadada", }}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>}
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <div style={{ paddingLeft: "2rem", font: "18px/22px Futura Hv BT", color: "#000000", marginBottom: "5px", marginTop: 15 }}>{booking_bundle.name}</div>
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Hv BT" : "14px/16px Futura Hv BT", color: "#707070", marginBottom: "10px", }}>{booking_bundle.room_text}</div>
                        <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Lt BT" : "12px/16px Futura Lt BT", color: "#707070", marginBottom: "15px", }}>
                            {booking_bundle.is_cancellable == 1 && <span>Cancellable, </span>}
                            {booking_bundle.is_cancellable == 0 && <span>Non-Cancellable, </span>}
                            {booking_bundle.details_summary.freeRefundableFlag == 1 && <span>Refundable</span>}
                            {booking_bundle.details_summary.freeRefundableFlag == 0 && <span>Non-Refundable</span>}
                        </div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem" }} >Check-in:</div>
                    </td>
                    <td >
                        <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", textAlign: "right", color: "#000000", }}>{moment(booking_bundle.check_in).format('MMMM Do, yyyy')}</div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 5, paddingBottom: 12 }} >Check-out:</div>
                    </td>
                    <td >
                        <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", paddingTop: 5, paddingBottom: 12, textAlign: "right", color: "#000000", }}>{moment(booking_bundle.check_out).format('MMMM Do, yyyy')}</div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#000000", backgroundColor: "#F5F7F6", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Room price:</div>
                    </td>
                    <td >
                        <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(cachedSubtotal ? cachedSubtotal : booking_bundle.rate_avg).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/night</div>
                    </td>
                </tr>


                <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingBottom: 10, paddingLeft: "2rem", paddingTop: 10 }} >{booking_bundle?.booking_info?.num_rooms} room(s) x {booking_bundle?.booking_info?.num_nights} night(s):</div>
                    </td>
                    <td >
                        <div style={{ paddingBottom: 10, paddingRight: "2rem", paddingTop: 10, textAlign: "right", color: "#000000", }}>{booking_bundle?.booking_info?.rate_symbol}{Number(cachedSubtotal ? cachedSubtotal : priceDetails?.display_sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </td>
                </tr>

                {Number(prepaidTotal) > 0 && !useCached && <React.Fragment>
                    {(prepaidFees?.length === 1) ? <React.Fragment>

                        {prepaidFees?.map((fee, index) => {
                            return (
                                <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", marginBottom: 10 }} key={index + "fee"}>
                                    <td >
                                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >{fee?.name}:</div>
                                    </td>
                                    <td >
                                        <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_info?.rate_symbol}{Number(fee?.display_total).toFixed(2)}</div>
                                    </td>
                                </tr>

                            )

                        })}

                    </React.Fragment> :
                        <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                            <td >
                                <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Taxes and fees:</div>
                            </td>
                            <td >
                                <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(prepaidTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </td>
                        </tr>}
                </React.Fragment>
                }

                {(useCached  && prepaidCached && Number(cachedHotel?.prepaid_display_total) > 0) &&  <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                            <td >
                                <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Taxes and fees:</div>
                            </td>
                            <td >
                                <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(cachedHotel?.prepaid_display_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </td>
                </tr>}


                {(useCached  && postpaidCached && Number(cachedHotel?.postpaid_display_total) > 0) &&  <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                            <td >
                                <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Taxes and fees:</div>
                            </td>
                            <td >
                                <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(cachedHotel?.postpaid_display_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </td>
                </tr>}





                {Number(postPaidTotal) > 0 && postPaid && !useCached && <React.Fragment>
                    {(postPaidFees?.length === 1) ? <React.Fragment>

                        {postPaidFees?.map((fee, index) => {
                            return (
                                <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", marginBottom: 10 }} key={index + "fee"}>
                                    <td >
                                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >{fee?.name}:</div>
                                    </td>
                                    <td >
                                        <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_info?.rate_symbol}{Number(fee?.display_total).toFixed(2)}</div>
                                    </td>
                                </tr>

                            )

                        })}

                    </React.Fragment> :
                        <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                            <td >
                                <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Taxes and fees:</div>
                            </td>
                            <td >
                                <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{booking_bundle.rate_symbol}{Number(postPaidTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </td>
                        </tr>}
                </React.Fragment>
                }



                <tr style={{ font: is_mobile ? "14px/18px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#000000", backgroundColor: "#F5F7F6", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >TOTAL <span style={{ font: "16px/16px Futura Lt BT", }}> {(prepaid ||prepaidCached) ? `(Pay now)` : '(Pay later)'}:</span></div>
                    </td>
                    <td >
                        <div style={{ paddingRight: "2rem", textAlign: "right", color: "#000000", }}>{booking_bundle.rate_currency} {booking_bundle.rate_symbol}{Number(prepaidCached? prepaidCachedTotal:postpaidCached?cachedTotal : prepaid ? priceDetails?.display_total : priceDetails?.display_all_in_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </td>
                </tr>



            </tbody></table>

            { useFormIndex && isPaymentPage  && Number(cachedTotal)>0 &&
            Number(cachedTotal) !== Number(prepaid ? priceDetails?.display_total : priceDetails?.display_all_in_total)  && <div style={{ marginTop: -15 }}>
                {Number(cachedTotal) < Number(prepaid ? priceDetails?.display_total : priceDetails?.display_all_in_total) && <div style={{ font: is_mobile ? "14px/18px Futura Lt BT" : "14px/24px Futura Lt BT", color: "white", backgroundColor: "var(--mainBlue)", }}>
                    <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} > Sorry, the price has changed</div>
                </div>}
                {Number(cachedTotal) > Number(prepaid ? priceDetails?.display_total : priceDetails?.display_all_in_total) && <div style={{ font: is_mobile ? "14px/18px Futura Lt BT" : "14px/24px Futura Lt BT", color: "white", backgroundColor: "var(--mainGreen)", }}>

                    <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} > You are in luck, the price has dropped</div>


                </div>}
            </div>}
        </React.Fragment>)
    }

    return (
        <div style={{ verticalAlign: "top" }}>
            <BundleItems booking_bundle={booking_bundle} />
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
    booking_info: state.FormBank.BookingPriceline.booking_info,
    //booking_bundles: state.FormBank.BookingPriceline.booking_bundles
})

const mapDispatchToProps = {
    removeBundleFromHotelBooking: Actions.removeBundleFromHotelBooking
}

const BookingItems = connect(mapStateToProps, mapDispatchToProps)(BookingItems_)
export default BookingItems
