import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from "@material-ui/core"
import moment from "moment"


import './TripSection.css';
import { AirplaneComponent, FlightDetailComponent, MidAirport } from '../FlightsPage/flightsComponents'
import { DurationLayover, secondsToHms, tConv24 } from '../../../utility/utility';
import { resetFlightPage, updateFlightData } from '../FlightsPage/services/Flights.slice';
import { checkFlightAvailable, getFlightsListThunk } from '../FlightsPage/services/Flights.thunks';
import { useHistory, useLocation } from "react-router-dom"
import PlayAirline from '../../../assets/images/playLogo.png'
import Airline from '../../../assets/images/gairline.png'


const MuverTripFlightItem_ = ({ destinationFlights,sliceValue, tripDestinations, currentDestIndex, flight, isMobile, bodyClientWidth, logo_width }) => {
    const departureDate = flight?.departure_date ? moment(flight.departure_date).format('ddd MMM D') : "";
    const arrivalDate = flight?.arrival_date ? moment(flight.arrival_date).format('MMM Do') : "";
    const booked = flight?.booked_booking_status === "ACCEPTED" || flight?.booked_booking_status_code === "ACCEPTED";
    const email = flight?.booked_email;
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const { uuid } = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const bookingId = flight?.priceline_trip_number || flight?.booking_id;
    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2
    const marginLeft = isMobile ? 0 : 150;
    const history = useHistory()
    const dispatch = useDispatch()
    const [flightAvailable, setAvailable] = React.useState(true)
    const [checkingFlight, setChecking] = React.useState(false)
    const [initialCheck, setInitialCheck] = React.useState(false)


    const flightSlice = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? flight?.flight_json?.itinerary_data[0]?.slice_data : null;

    React.useEffect(() => {


        if (flight) {
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;

            const isAvailable = async () => {
                const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
                setAvailable(res)
                setInitialCheck(true)
            }
            if (ppnId) {
                isAvailable()
            }


        }
    }, [flight, fullCurrency])




    const goToflightDetails = () => {
        history.push(`/flight/booking_details?booking_id=${bookingId}&email=${email}`)
    }


    const isAvailable = async () => {
        const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
            flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;


        setChecking(true)
        const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
        setAvailable(res)
        setChecking(false)
    }


    const searchFlight = async (newSearch) => {
        let available = false;

        if (!flightAvailable && initialCheck) {
            available = await isAvailable();
        }

        const nextDate  = moment()?.add(1,"day");

        if (available || (flightAvailable && initialCheck)) {
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;
            history.push(`/package?bundleId=${ppnId}`)

        }
        else {
            let flights = []

            if (flight?.search_type === "round_trip" && destinationFlights && destinationFlights?.length > 1) {
                destinationFlights.forEach((flight, index) => {
                    flights.push({
                        "airportFrom": {
                            "city": flight?.departure_city,
                            "latitude": "",
                            "city_code": flight?.departure_iata,
                            "type": "main",
                            "iata": flight?.departure_iata,
                            "airport": "All Airports"
                        },
                        "airportTo": {
                            "city": flight?.arrival_city,
                            "city_code": flight?.arrival_iata,
                            "type": "main",
                            "iata": flight?.arrival_iata,
                            "airport": "All Airports"
                        },
                        "departureDateFormat": flight?.departure_date ? new Date(moment(newSearch ? nextDate: flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "departureDate": flight?.departure_date ? new Date(moment(newSearch ? nextDate:flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "availableSelectDate": new Date().toDateString(),
                        "positionId": "d-" + index
                    })
                })
            }
            else {
                flights = [
                    {
                        "airportFrom": {
                            "city": flight?.departure_city,
                            "latitude": "",
                            "city_code": flight?.departure_iata,
                            "type": "main",
                            "iata": flight?.departure_iata,
                            "airport": "All Airports"
                        },
                        "airportTo": {
                            "city": flight?.arrival_city,
                            "city_code": flight?.arrival_iata,
                            "type": "main",
                            "iata": flight?.arrival_iata,
                            "airport": "All Airports"
                        },
                        "departureDateFormat": flight?.departure_date ? new Date(moment(newSearch ? nextDate:flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "departureDate": flight?.departure_date ? new Date(moment(newSearch ?  nextDate:flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "availableSelectDate": new Date().toDateString(),
                        "positionId": "d-0"
                    }]

            }

            const flightMode = flight?.search_type && destinationFlights?.length === 1 ? "round_trip" : 'one_way';
            const numPassengers = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.price_details?.number_of_passengers;
            const dataToBookingFlights = {
                flightMode: flightMode,
                cabinClass: 'economy',
                numberAdult: numPassengers,
                numberChild: '0',
                flights: flights,
                currency: fullCurrency

            }

            const flightDates = [...flights]?.map((flight) => {
                const formatDate = moment.parseZone(flight?.departureDate)?.format("YYYY-MM-DD")?.toString()?.split("-");
                const dateString = `${formatDate[2]}${formatDate[0] < 10 ? "0" + formatDate[0] : formatDate[0]}${formatDate[1] < 10 ? "0" + formatDate[1] : formatDate[1]}`
                return `${flight?.airportFrom?.iata}-${flight?.airportTo?.iata}-${formatDate?.join('') ?? ""}`
            })


            let flightUrl = `/flight/search?flightMode=${flightMode}&cabinClass=${flight?.cabinClass || 'economy'}&numberAdult=${numPassengers}&numberChild=${flight?.numberChild || 0}&flights=${flightDates?.toString()}`;
            // dispatch(updateFlightData(dataToBookingFlights))
            dispatch(resetFlightPage())
            // dispatch(getFlightsListThunk(dataToBookingFlights))

            history.push(flightUrl)
        }

    }


    const stopsFlight = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? Number(flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.info.connection_count) : 0;
    let notesArray = [];
    const stopNotes = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.flight_data?.map((flight) => flight?.info?.notes) : null
    const notesData = stopNotes && stopNotes?.length > 0 ? stopNotes.forEach((notes) => {
        notesArray = notesArray?.concat(notes)
    }) : null;

    notesArray = notesArray?.map((note) => secondsToHms(note?.duration));
    notesArray = notesArray.filter(n => n);
    const durationText = notesArray && notesArray?.length > 0 ? notesArray?.length > 1 ? notesArray?.join(" & ") : notesArray : null
    const priceDifference = flight?.price_display_total_fare && flightAvailable?.itinerary_data?.price_details?.display_total_fare ? Number(flightAvailable?.itinerary_data?.price_details?.display_total_fare - flight?.price_display_total_fare)?.toFixed(2) : null;

    const flightPerTicket = flight?.flight_json?.itinerary_data ? flight?.flight_json.itinerary_data[0]?.price_details?.display_total_fare_per_ticket : null;

    const confirmationNumber = flight?.booking_number || flight?.priceline_trip_number;

    const days = (date_1, date_2) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(date_1.getFullYear(), date_1.getMonth(), date_1.getDate());
        const utc2 = Date.UTC(date_2.getFullYear(), date_2.getMonth(), date_2.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;


    const flightCopy = flightSlice?.length> 0? [...flightSlice]:null
    const flightArray = flightCopy ? (sliceValue === 1? flightCopy?.slice(1):flightCopy?.slice(0,1)):null
  

    return (
        <Grid container style={{ marginBottom: 0 }} >

            <Grid item xs={12} sm={12} md={12}  >
                {flightArray && flightArray?.map((slice, sliceIndex) => {                 
                 
                    const isOG = slice?.airline?.code === "OG" && !slice?.airline?.logo;
                    const logo = isOG ? PlayAirline : slice.airline.logo;
                    const flightSliceData = slice.flight_data;
                    let operated;
                    const notes = slice?.info?.notes;
                    let airlines = "";
                    Object.keys(flightSliceData).forEach(key => {
                        const val = flightSliceData[key]
                        const opr = val?.info?.marketing_airline;
                        const notInclude = !airlines.includes(opr);
                        if (opr && opr.length > 0 && notInclude) {
                            airlines = airlines.concat(opr, '/');
                        }
                    });
                    airlines = airlines.slice(0, -1)

                    operated = slice?.airline?.name ?
                        ` - Operated by ${airlines}  - Total flight duration: ${secondsToHms(slice.info.duration)} ` : ``;



                    return (
                        <Grid container key={sliceIndex} style={{}}>
                            <div style={{ width: "100%" }}>
                                <img src={logo}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = Airline;
                                    }}
                                    height={25} alt="logo" style={{
                                        paddingLeft: 0, marginBottom: 10,
                                        background: "transparent"
                                    }} />

                                <div
                                    style={{

                                        float: "right",
                                        cursor: "pointer",
                                        marginBottom: 30,
                                        textAlign: "right",
                                    }}>


                                    {flightPerTicket && <React.Fragment>
                                        {<span style={{
                                            font: "14px/15px Futura Lt BT",
                                        }}>
                                            {flight?.search_type === "round_trip" ? "Round Trip" : ""} Price: {flight?.price_display_currency} </span>}<span
                                                style={{
                                                    font: "16px/15px Futura Md BT",
                                                }}>{flight?.price_display_symbol}{Number(flightPerTicket)?.toFixed(0)}.</span>
                                        <sup style={{ font: "12px/12px Futura Md BT", }}>{String(flightPerTicket)?.split(".")[1]}</sup>
                                    </React.Fragment>}
                                    {/* {flightAvailable && priceDifference && Math.round(priceDifference) !== 0 && <div
                                        style={{
                                            color: "var(--mainGrey)",
                                            marginTop: 5,
                                            paddingBottom: 10,
                                            font: "14px/15px Futura Lt BT",
                                        }}>
                                        <span style={{ color: "red" }}>{"$" + priceDifference} </span>  <span> since {moment(new Date()).format('MMMM Do, yyyy')}</span>
                                    </div>} */}
                                    {/* {flight?.price_display_total_fare && !booked && <div style={{
                                        paddingTop: 6,
                                        font: "18px/15px Futura Lt BT",
                                    }}>
                                        <span style={{
                                            font: "16px/15px Futura Lt BT",
                                        }}>Total:</span> {flight?.price_display_symbol}{Number(flight?.price_display_total_fare)?.toFixed(0)}.
                                        <sup style={{ font: "14px/15px Futura Lt BT", }}>{String(flight?.price_display_total_fare)?.split(".")[1]}</sup>
                                    </div>} */}

                                    {!flightAvailable && !checkingFlight && !booked && <div
                                        style={{
                                            color: "red",
                                            textAlign: "right",
                                            width: "100%",
                                            font: "14px/15px Futura Lt BT",
                                            marginTop: "5px",
                                            marginBottom: "5px",
                                        }} >
                                        This flight is not available anymore
                                    </div>}

                                    {booked && <div onClick={goToflightDetails}
                                        style={{

                                            display: "inline-block",
                                            float: "right",
                                            cursor: "pointer",
                                            textAlign: "right",
                                            width: "100%",
                                            font: "14px/15px Futura Lt BT",
                                        }}>
                                        Booking ID: <span style={{
                                            font: "14px/15px Futura Hv BT",
                                            color: "var(--mainGreen)",
                                        }}>{confirmationNumber}</span>
                                    </div>}
                                    {checkingFlight && !booked && <CircularProgress disableShrink size={20}
                                        style={{
                                            color: "#6A9EEC",
                                            marginLeft: "5px"
                                        }} />}

                                </div>
                            </div>


                            <Grid container spacing={0} className="flightTimeline">
                                <div className='line'></div>



                                {flightSliceData && flightSliceData.map((flight, index) => {

                                    let DataComponent = [];



                                    const date1 = new Date(flightSliceData[0]?.departure.datetime.date_time);
                                    const date2 = new Date(flightSliceData[flightSliceData.length - 1]?.arrival.datetime.date_time)
                                    const diffDays = days(date1, date2);

                                    if (mobile) {

                                        if (index === 0) {
                                            const date = moment(flight.departure.datetime.date).format('MMMM Do');
                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'depart'} style={{ maxWidth: "38%" }}  >
                                                    <div style={{ textAlign: 'left', background: 'transparent' }}>
                                                        <FlightDetailComponent flightText={'Depart From'} airportCode={flight.departure.airport.code}
                                                            padding="0px 0px"
                                                            country={flight.departure.airport.city} date={date} time={flight.departure.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )

                                            DataComponent.push(
                                                <Grid item md key={index + "airplane"} >
                                                    <div style={{ marginTop: 49 }}>
                                                        <AirplaneComponent bg={"#0D3A7D1A"} time={flightSliceData.length === 1 ? secondsToHms(flight.info.duration) : "..."} />
                                                    </div>
                                                </Grid>
                                            )
                                        }



                                        if (index === flightSliceData.length - 1) {

                                            const date = moment(flight.arrival.datetime.date).format('MMMM Do');

                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'arrive'} style={{ maxWidth: "38%" }}>
                                                    <div style={{ textAlign: 'right', background: 'transparent' }}>
                                                        <FlightDetailComponent flightText={'Arrive at'} airportCode={flight.arrival.airport.code} diffDays={diffDays}
                                                            padding="0px 0px"
                                                            country={flight.arrival.airport.city} date={date} time={flight.arrival.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                    }
                                    else {
                                        if (index === 0) {
                                            const date = moment(flight.departure.datetime.date).format('MMMM Do');
                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'depart'}  >
                                                    <div style={{ textAlign: 'left', background: 'transparent' }}>
                                                        <FlightDetailComponent flightText={'Depart From'} airportCode={flight.departure.airport.code}
                                                            padding="0px 0px"
                                                            country={flight.departure.airport.city} date={date} time={flight.departure.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                        DataComponent.push(
                                            <Grid item md key={index + "airplane"} >
                                                <div style={{ marginTop: 49 }}>
                                                    <AirplaneComponent bg={"#0D3A7D1A"} time={secondsToHms(flight.info.duration)} />
                                                </div>
                                            </Grid>
                                        )

                                        if (index === flightSliceData.length - 1) {

                                            const date = moment(flight.arrival.datetime.date).format('MMMM Do');

                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'arrive'} style={{ marginLeft: 6 }}>
                                                    <div style={{ textAlign: 'right', background: 'transparent' }}>
                                                        <FlightDetailComponent flightText={'Arrive at'} airportCode={flight.arrival.airport.code} diffDays={diffDays}
                                                            padding="0px 0px"
                                                            country={flight.arrival.airport.city} date={date} time={flight.arrival.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                        else {
                                            const next = index + 1 <= flightSliceData.length - 1 ? flightSliceData[index + 1] : null;
                                            const time = next ? DurationLayover(flight.arrival.datetime.date_time, next.departure.datetime.date_time) : '';

                                            DataComponent.push(
                                                <Grid item md key={index + 'connect'}  >
                                                    <div style={{ margin: '0 auto', marginTop: 49, maxWidth: 130 }}>
                                                        <MidAirport bg={"transparent"} time={time} airportCode={flight.arrival.airport.code} city={flight.arrival.airport.city} />
                                                    </div>
                                                </Grid>
                                            )

                                        }

                                    }


                                    return DataComponent;
                                })
                                }



                            </Grid>
                            <div style={{ width: "100%" }}>
                                <p style={{ marginTop: 20, marginLeft: 0, marginBottom: 0, width: "100%", font: "14px/14px Futura Md BT" }}> {slice?.airline?.name}<span style={{ color: '#6b6b6b', font: "14px/14px Futura Md BT" }}>{operated} </span></p>


                                <div
                                    style={{
                                        font: "24px/15px Futura Md BT",
                                        textAlign: "right",
                                        width: "100%",
                                        display: "flex"
                                    }}>
                                    {!booked  && <div onClick={()=>{searchFlight(true)}}
                                        style={{
                                            color: "#0D3A7D",
                                            cursor: "pointer",
                                            paddingTop: 20,
                                            paddingRight: 20,
                                            width: "100%",
                                            font: "14px/15px Futura Lt BT",
                                        }}>
                                        see other flights
                                    </div>}

                                    {booked && <div style={{
                                        paddingTop: 6,
                                        font: "14px/15px Futura Lt BT",
                                    }}>

                                        <div onClick={goToflightDetails} style={{
                                            font: "14px/16px Futura Hv BT", cursor: "pointer", padding: 10, marginTop: 10,
                                            borderRadius: 35, color: "#19BC9B", background: "white",
                                            border: "2px solid #19BC9B", textAlign: "center",
                                            width: 150,
                                            marginLeft: "auto"
                                        }}>
                                            Flight details
                                        </div>


                                    </div>}


                                    {!booked &&
                                        <div onClick={()=>{searchFlight(false)}} style={{
                                            font: "14px/16px Futura Md BT", cursor: "pointer", padding: 10, marginTop: 10,
                                            borderRadius: 35, background: "#19BC9B", color: "white",
                                            textAlign: "center",
                                            width: 150,
                                            marginLeft: "auto"
                                        }}>
                                            Book this flight
                                        </div>
                                    }
                                </div>

                            </div>

                            {/* {flightSliceData &&
                                <div style={{ paddingTop: 15, width: '80%' }}>
                                    {Object.keys(flightSliceData).map((key, index) => {
                                        const val = flightSliceData[key]
                                        const notes = val?.info?.notes;
                                        return (<div style={{ width: '100%', }}>
                                            {notes && Object.keys(notes).map(key => {
                                                const note = notes[key]

                                                return (
                                                    <p style={{ marginTop: 3, marginBottom: 3, width: "100%", marginLeft: 50, lineHeight: 1.3, font: "14px/14px Futura  Lt BT", color: '#E63155', }}> {note?.value}</p>
                                                )
                                            })}
                                        </div>)
                                    })}
                                </div>} */}


                        </Grid>
                    )
})
                
                }


            </Grid>



        </Grid>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}

const MuverTripFlightItem = connect(mapStateToProps, mapDispatchToProps)(MuverTripFlightItem_)
export default MuverTripFlightItem
