import {
    FlightShapeIcon,
    PlusSignIcon,
    TimesIcon,
} from "../SVGBank"

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { resetFlightPage, updateFlightData } from "../../FlightsPage/services/Flights.slice"
import moment from "moment"
import { getHomeDetails } from "../../../../redux/actions"

const NeedFlights_ = ({uuid, activeTrip,setBookingFormStatus,setBookingFormActiveTab,openDefault, destination, switchFeatureContainer }) => {
    const [open, setOpen] = React.useState(openDefault);
    const [flightList, setFlightList] = React.useState([])
    const [home, setHome] = React.useState();
    
    const searchFlight = () =>{
      try{
        setBookingFormStatus(true)
        setBookingFormActiveTab('flight')
        openFlightBooking()
      }
      catch{

      }
    
    }
    const dispatch = useDispatch()
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";

    const tripDestinations = activeTrip?.tripDestinations;

    React.useEffect(() => {
        getHomeDetails().then(data => {
            setHome(data)
        })
    }, [uuid])


    const openFlightBooking = () => {
        
        try{
            const validStartDate  = moment(activeTrip?.tripStartDate)?.isValid();

            if(tripDestinations && tripDestinations?.length > 0){
                let destinations = [...tripDestinations]
                let prevDestination;
                const homeAirport = home?.homeAirport; 
                let flights = []
                let flightMode = "one_way"
           
                if(destinations?.length === 1 || destinations[0]?.id === destination?.id){ //first destination or only destination
                    flightMode = destinations?.length === 1 ? "round_trip":"one_way";
                    flights = [
                        {
                            "airportFrom": {
                                "city":homeAirport?.airportCity ??"",
                                "latitude":homeAirport?.airportLatitude ??"",
                                "city_code": homeAirport?.airportCode ??"",
                                "type": "main",
                                "iata": homeAirport?.airportCode ??"",
                                "airport": "All Airports"
                            },
                            "airportTo": {
                                "city":destination?.name + " "+ destination?.countryName ??"",
                                "latitude":destination?.latitude ??"",
                                "city_code": destination?.iata ??"",
                                "type": "main",
                                "iata": destination?.iata ??"",
                                "airport": "All Airports"
                            },
                            "departureDateFormat": activeTrip?.tripStartDate  && validStartDate ? new Date(moment(activeTrip?.tripStartDate )) :  new Date(moment()),
                            "departureDate": activeTrip?.tripStartDate && validStartDate? new Date(moment(activeTrip?.tripStartDate )) :  new Date(moment()),
                            "availableSelectDate": new Date().toDateString(),
                            "positionId": "d-0"
                        }]
        
                        if(flightMode === "round_trip"){
                            flights.push({
                                "airportFrom": {
                                    "city":destination?.name + " "+ destination?.countryName ??"",
                                    "latitude":destination?.latitude ??"",
                                    "city_code": destination?.iata ??"",
                                    "type": "main",
                                    "iata": destination?.iata ??"",
                                    "airport": "All Airports"
                                },
                                "airportTo": {
                                    "city":homeAirport?.airportCity ??"",
                                    "latitude":homeAirport?.airportLatitude ??"",
                                    "city_code": homeAirport?.airportCode ??"",
                                    "type": "main",
                                    "iata": homeAirport?.airportCode ??"",
                                    "airport": "All Airports"
                                },
                                "departureDateFormat": activeTrip?.tripStartDate  && validStartDate ? new Date(moment(activeTrip?.tripStartDate).add(Number(activeTrip?.tripDuration)-1, "days")):  new Date(moment()),
                                "departureDate": activeTrip?.tripStartDate && validStartDate? new Date(moment(activeTrip?.tripStartDate).add(Number(activeTrip?.tripDuration)-1, "days")):  new Date(moment()),
                                "availableSelectDate": new Date().toDateString(),
                                "positionId": "d-1"
                            })
                        }
              
                }
                else{
                    flightMode = "one_way";
                    destinations.forEach((dest,index)=>{   
                        if(dest?.id === destination?.id){
                            prevDestination = destinations[index -1];
                        }
                    })
                    if(prevDestination){
                        flights = [
                            {
                                "airportFrom": {
                                    "city":prevDestination?.name + " "+ prevDestination?.countryName ??"",
                                    "latitude":prevDestination?.latitude ??"",
                                    "city_code": prevDestination?.iata ??"",
                                    "type": "main",
                                    "iata": prevDestination?.iata ??"",
                                    "airport": "All Airports"
                                },
                                "airportTo": {
                                    "city":destination?.name + " "+ destination?.countryName ??"",
                                    "latitude":destination?.latitude ??"",
                                    "city_code": destination?.iata ??"",
                                    "type": "main",
                                    "iata": destination?.iata ??"",
                                    "airport": "All Airports"
                                },
                                "departureDateFormat":  validStartDate && destination?.startingDayInTrip?  
                                new Date(moment(activeTrip?.tripStartDate).add(Number(destination?.startingDayInTrip), "days")): new Date(moment()),
                                "departureDate":  validStartDate && destination?.startingDayInTrip?    new Date(moment(activeTrip?.tripStartDate).add(Number(destination?.startingDayInTrip), "days").format("YYYY-MM-DD")): new Date(moment().format("YYYY-MM-DD 00:00")),
                                "availableSelectDate": new Date().toDateString(),
                                "positionId": "d-0"
                            }]
    
                            
                    }
                }  
    
                let dataToBookingFlights = {
                    flightMode: flightMode,
                    cabinClass: 'economy',
                    numberAdult: 1,
                    numberChild: '0',
                    flights: flights,
                    currency: fullCurrency
        
                }
    
            dispatch(updateFlightData(dataToBookingFlights))
            }
        }
        catch(err){
            console.log(err)
        }
    }


    return (
        <React.Fragment>
            {open ? <div style={{
                marginTop: "12px",
                marginLeft: "12px",
                marginRight: "0px",
                marginBottom: "12px",
                backgroundColor: "var(--mainWhite)",
                textAlign: "center",
                cursor: "pointer"   ,     
                position: "relative",
                                                             
            }} >
                       <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={() => { setOpen(false);  }} >
                        <TimesIcon width={15} height={15} /></div>
                  <div >  
                <div onClick={searchFlight} style={{ paddingBottom: "15px", paddingTop: "30px",cursor: "pointer" }}><FlightShapeIcon width={31} height={31} fill={"var(--mainGreen)"} /></div>
                <div onClick={searchFlight} style={{ font: "18px/21px Futura Hv BT", color: "var(--frameBlack)" }}>Need a flight ?</div>
                <div onClick={searchFlight} style={{ font: "16px/19px Futura Md BT", color: "#B1B1B1", paddingTop: "15px", paddingBottom: "30px", }}>Search for best flights deals for your trip</div>
                <hr  onClick={searchFlight} style={{ border: "1px solid #E8E8E8", height: "0px", margin: "0px", }} />
                <div onClick={searchFlight} style={{  cursor: "pointer",font: "14px/16px Futura Hv BT", color: "var(--mainGreen)", paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={"var(--mainGreen)"} />{"find a flight"}</div>
                </div>
            </div>
                : <div style={{
                    marginTop: "12px",
                    marginLeft: "12px",
                    marginRight: "0px",
                    marginBottom: "12px",
                    backgroundColor: "var(--mainWhite)",
                    textAlign: "center",
                    top: "30px",
                    zIndex: "4",    
                    cursor: "pointer"
                }}
                    onClick={searchFlight}>
                    <div style={{ font: "14px/16px Futura Hv BT", color: "var(--mainGreen)", paddingTop: "12px", paddingBottom: "12px", }}><PlusSignIcon width={9} height={10} style={{ marginRight: "5px" }} fill={"var(--mainGreen)"} />{"find a flight "}<FlightShapeIcon width={17} height={17} fill={"var(--mainGreen)"} /></div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    switchFeatureContainer: state.FormBank.FeatureFormat,
    uuid: state.Member.authModal.uuid,
})

const mapDispatchToProps = {
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    setBookingFormStatus: Actions.setBookingFormStatus,
}

const NeedFlights = connect(mapStateToProps, mapDispatchToProps)(NeedFlights_)
export default NeedFlights
