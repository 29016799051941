import 'date-fns';

import * as Actions from '../../../redux/actions'

import {
    DELAY_HALF_SECOND,
    DELAY_ONE_SECOND,
    DELAY_ZERO_SECOND,
    member_area_router,
    PASSWORD_TEXT_FILED_TYPE_DISPLAY,
    PASSWORD_TEXT_FILED_TYPE_HIDDEN,
    validate,
    validate_fields,
} from "./RegExValidate"
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { EyeClosePasswordIcon, EyeOpenPasswordIcon, TimesIcon } from './SVGBank'
import { ReformatTimelineTripRangeDate, ReformatTripRangeDate } from '../Common/DateTimeFormatHandler'
import { hotelTextFieldUseStyles } from "./ComponentsStylingBank"
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { TripDescription } from './Trip/TripDescription';
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles'
import moment from "moment"
import throttle from 'lodash/throttle';
import { useState } from "react";
import Popper from '@material-ui/core/Popper';
import { Check, Edit } from '@material-ui/icons';
import { environment } from '../../../Environments/environment';
import { getAirportsListThunk } from '../FlightsPage/services/Flights.thunks';
import { getIPFromAmazon } from '../HotelPage/hotelServices';

const Entities = require('html-entities').XmlEntities

/* handle text field */
export const HandleTextField = connect((state) => ({
    handleTextField: state.FormBank.handleTextField,
    muverSocial: state.Member.socialMember.muver,
    muverMemebr: state.Member.memberArea.memberMuver,
    member_uuid: state.Member.socialMember.member_uuid,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    switch_container: state.Member.switch_container,
}), {
    checkHandle: Actions.checkHandle,
    invalidHandle: Actions.invalidHandle,
    setHandle: Actions.setHandle
},
)(({
    label = "",
    required = "",
    muverSocial,
    muverMemebr,
    switch_container,
    id = "",
    uuid,
    placeholder = null,
    checkHandle, setHandle,
    invalidHandle,
    handleTextField,
    defaultValue = handleTextField.handle,
    token = "",
    inputRef,
    value, verifyOnly = false,
    useFlex = false
}) => {

    const muver = switch_container === member_area_router.social ? muverSocial : muverMemebr
    const prevHandle = handleTextField.handle !== "" ? handleTextField.handle : muver.muverHandle;

    const prevFullHandle = handleTextField.handle !== "" && handleTextField.handle ? !handleTextField.handle?.includes('@') ? "@" + handleTextField.handle :
        handleTextField.handle
        : (muver.muverHandle !== "" && muver.muverHandle ? !muver.muverHandle?.includes('@') ? "@" + muver.muverHandle : muver.muverHandle : "");
    const [handle, setHandleName] = React.useState(prevFullHandle);
    const [canEditProfile, setEditProfile] = React.useState(false);
    const [hasError, setError] = React.useState(handleTextField.error);
    const [defaultPlaceHolder, setPlaceHolder] = React.useState(placeholder);

    const updateState = (handle, error) => {
        setHandleName(handle)
        setError(error)
        if (error) {
            invalidHandle()
        } else {


        }
    }
    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeHandleTextField = event => {
        let handle = event.target.value

        const textHandle = handle && !handle?.includes("@") ? "@" +
            handle?.toLowerCase() : handle?.toLowerCase();

        let removeSign = textHandle.replace("@", "");
        updateState(textHandle, !validate(removeSign, validate_fields.handle))


    };


    const save = () => {

        if (handle && handle?.length > 0) {

            let removeSign = handle.replace("@", "");

            if (removeSign !== prevHandle) {
                checkHandle(removeSign, uuid, verifyOnly)
                setHandle(handle)
            }

        }
        setEditProfile(false)
    }
    return (
        <React.Fragment>
            <div  >

                <div
                    style={{ display: useFlex ? 'flex' : 'block' }}>
                    <TextField
                        helperText={hasError ?
                            handleTextField.textHelper : ""}
                        error={hasError}
                        label={label}
                        placeholder={defaultPlaceHolder}
                        value={handle}
                        required={required}
                        id={id}
                        disabled={!canEditProfile}
                        inputRef={inputRef}
                        onChange={handleChangeHandleTextField}
                        onFocus={() => {
                            setHandleName("")
                            setPlaceHolder("")
                        }}
                        onClick={() => {
                            if (!canEditProfile && handle) {
                                window.open(`${environment.prodUrl}/${handle}`)
                            }
                        }}
                        InputProps={{
                            endAdornment:
                                <div
                                    style={{
                                        cursor: "pointer",
                                        borderRadius: '50%',
                                        padding: 0,
                                        background: '#E8E8E8',
                                        // height: 18,
                                        width: 20,
                                        textAlign: 'center',
                                        marginRight: 10,
                                        display: canEditProfile ? 'block' : 'none'
                                    }}
                                    onClick={() => {

                                        setEditProfile(false);
                                        setHandleName(prevFullHandle)
                                        setPlaceHolder(placeholder)
                                    }}><TimesIcon
                                        fill="#fff"
                                        style={{ marginTop: 0 }} width={'8'} height={'8'} /></div>,
                        }}
                    />

                    {canEditProfile ? <Check onClick={save} style={{ fill: "#19BC9B", fontWeight: 600, marginTop: 2 }} />

                        : <Edit onClick={() => setEditProfile(true)} />}
                </div>


            </div>



        </React.Fragment>

    )
})

/* signature text field */
export const SignatureTextField = connect((state) => ({
    signatureTextField: state.FormBank.signatureTextField,
}), {
    setUserSignature: Actions.setUserSignature,
    //invalidHandle: Actions.invalidHandle,
},
)(({
    label = "",
    setUserSignature,
    //invalidHandle,
    signatureTextField,
    defaultValue = signatureTextField.signature,
    uuid,
    lang
}) => {
    //console.log("uuid")
    //console.log(uuid)
    //console.log("lang")
    //console.log(lang)
    const [err, setErr] = useState(false)
    const updateState = (signature, error) => {
        if (error) {
            //console.log("FormComponentBank error")
            //console.log(error)
            //invalidHandle()
            setErr(true)
        } else {
            //console.log("FormComponentBank setUserSignature(signature, uuid, lang)")
            //console.log(signature)
            setErr(false)
            setUserSignature(signature, uuid, lang)
        }
    }
    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeHandleTextField = event => {
        let signature = event.target.value
        //console.log("FormComponentBank :")
        //console.log(signature)
        delay(function () {
            updateState(signature, !validate(String(signature).toLowerCase(), validate_fields.signature))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            label={label}
            defaultValue={defaultValue}
            helperText="text of 1-20 character"
            error={err}
            onChange={handleChangeHandleTextField}
        />
    )
})

/* email address text field */
export const EmailAddressTextField = connect((state) => ({
    emailAddressTextField: state.FormBank.emailAddressTextField,
}), {
    checkEmailExsit: Actions.checkEmailExsit,
    invalidEmailAddress: Actions.invalidEmailAddress,
})(({
    label = "Email Address",
    required = "",
    id = "",
    checkEmailExsit,
    invalidEmailAddress,
    emailAddressTextField,
    defaultValue = emailAddressTextField.emailAddress,
    disabled,
}) => {
    const updateState = (email, error) => {
        if (error) {
            invalidEmailAddress()
        } else {
            checkEmailExsit(email)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    })();
    const handleChangeEmailAddressTextField = event => {
        let email = event.target.value
        delay(function () {
            updateState(email, !validate(String(email).toLowerCase(), validate_fields.email))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={emailAddressTextField.textHelper}
            error={emailAddressTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeEmailAddressTextField}
            disabled={disabled}
        />
    )
})

/*  first name text field  */
export const FirstNameTextField = connect((state) => ({
    firstNameTextField: state.FormBank.firstNameTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setFirstName: Actions.setFirstName,
    invalidFirstName: Actions.invalidFirstName,
})(({
    lang,
    uuid,
    label = "Last Name",
    required = "",
    id = "",
    setFirstName,
    invalidFirstName,
    firstNameTextField,
    defaultValue = firstNameTextField.firstName,
    disabled,
}) => {
    const updateState = (first_name, error) => {
        if (error) {
            invalidFirstName()
        } else {
            setFirstName(first_name, uuid, lang)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let first_name = event.target.value
        delay(function () {
            updateState(first_name, !validate(String(first_name).toLowerCase(), validate_fields.first_name))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={firstNameTextField.textHelper}
            error={firstNameTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})

/*  last name text field  */
export const LastNameTextField = connect((state) => ({
    lastNameTextField: state.FormBank.lastNameTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setLastName: Actions.setLastName,
    invalidLastName: Actions.invalidLastName,
})(({
    lang, uuid,
    label = "Last Name",
    required = "",
    id = "",
    setLastName,
    invalidLastName,
    lastNameTextField,
    defaultValue = lastNameTextField.lastName,
    disabled,
}) => {
    const updateState = (last_name, error) => {
        if (error) {
            invalidLastName()
        } else {
            setLastName(last_name, uuid, lang)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let last_name = event.target.value
        delay(function () {
            updateState(last_name, !validate(String(last_name).toLowerCase(), validate_fields.last_name))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={lastNameTextField.textHelper}
            error={lastNameTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})



/*  password text field  */
export const PasswordTextField = connect((state) => ({
    passwordTextField: state.FormBank.passwordTextField,
}), {
    setPassword: Actions.setPassword,
    invalidPassword: Actions.invalidPassword,
    validPasswordConfirm: Actions.validPasswordConfirm,
    unmatchPasswordConfirm: Actions.unmatchPasswordConfirm,
})(({
    label = "Password",
    required = "",
    id = "",
    setPassword,
    invalidPassword,
    passwordTextField,
    unmatchPasswordConfirm,
    validPasswordConfirm,
    defaultValue = passwordTextField.password,
    disabled,
}) => {
    const updateState = (password, error) => {
        if (error) {
            invalidPassword()
        } else {
            setPassword(password)
        }
        if (String(passwordTextField.passwordConfirm) !== password) {
            unmatchPasswordConfirm(passwordTextField.passwordConfirm)
        } else {
            validPasswordConfirm(passwordTextField.passwordConfirm)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let password = event.target.value
        delay(function () {
            updateState(password, !validate(String(password).toLowerCase(), validate_fields.password))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            type={passwordTextField.fieldType}
            helperText={passwordTextField.textHelper}
            error={passwordTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})


export const PasswordEye = connect((state) => ({
    passwordTextField: state.FormBank.passwordTextField,
}), {
    setPasswordTextFieldType: Actions.setPasswordTextFieldType,
})(({
    passwordTextField,
    setPasswordTextFieldType,
}) => {
    return (
        <div>
            {passwordTextField.fieldType === PASSWORD_TEXT_FILED_TYPE_HIDDEN && <div onClick={() => setPasswordTextFieldType(PASSWORD_TEXT_FILED_TYPE_DISPLAY)} ><EyeOpenPasswordIcon /></div>}
            {passwordTextField.fieldType === PASSWORD_TEXT_FILED_TYPE_DISPLAY && <div onClick={() => setPasswordTextFieldType(PASSWORD_TEXT_FILED_TYPE_HIDDEN)} ><EyeClosePasswordIcon /></div>}
        </div>

    )
})

/*  password confirm text field  */
export const PasswordConfirmTextField = connect((state) => ({
    passwordTextField: state.FormBank.passwordTextField,
}), {
    validPasswordConfirm: Actions.validPasswordConfirm,
    unmatchPasswordConfirm: Actions.unmatchPasswordConfirm,
    invalidPasswordConfirm: Actions.invalidPasswordConfirm,
})(({
    label = "Confirm",
    required = "",
    id = "",
    unmatchPasswordConfirm,
    validPasswordConfirm,
    invalidPasswordConfirm,
    passwordTextField,
    defaultValue = passwordTextField.passwordConfirm,
    disabled,
}) => {
    const updateState = (passwordConfirm, error) => {
        if (error) {
            invalidPasswordConfirm(passwordConfirm)
        } else {
            if (String(passwordConfirm) !== passwordTextField.password) {
                unmatchPasswordConfirm(passwordConfirm)
            } else {
                validPasswordConfirm(passwordConfirm)
            }
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let passwordConfirm = event.target.value
        delay(function () {
            updateState(passwordConfirm, !validate(String(passwordConfirm).toLowerCase(), validate_fields.password))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            type={passwordTextField.fieldType}
            helperText={passwordTextField.passwordConfirmedhelperText}
            error={passwordTextField.passwordConfirmedError}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})

/* hometonw components */
const hometownAutocompleteService = { current: null };

export const HometownTextField = connect((state) => ({
    hometownTextField: state.FormBank.hometownTextField,
    destinationVisitedSearch: state.FormBank.destinationVisitedSearch,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setHometownSelected: Actions.setHometownSelected,
    invalidHometownQuery: Actions.invalidHometownQuery,
    setHometownSearchQuery: Actions.setHometownSearchQuery,
    setHometownLoadGoogleUrl: Actions.setHometownLoadGoogleUrl,
    setHometownOptions: Actions.setHometownOptions,
    resetHometownOptions: Actions.resetHometownOptions,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setHometownSelected,
    setHometownSearchQuery,
    setHometownLoadGoogleUrl,
    setHometownOptions,
    hometownTextField,
    resetHometownOptions,
    invalidHometownQuery,
    destinationVisitedSearch,
    value = hometownTextField.hometownSelected,
}) => {


    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                hometownAutocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );
    React.useEffect(() => {

        if (!hometownAutocompleteService.current && window.google) {
            hometownAutocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!hometownAutocompleteService.current) {
            return undefined;
        }

        if (hometownTextField.query === '') {
            resetHometownOptions([]);
            return undefined;
        }

        fetch({
            input: hometownTextField.query,
            types: ['(cities)'],
        }, results => {
            if (hometownTextField.googleServiceActive) {
                setHometownOptions(results || []);
            } if (results === undefined || results === null) {
                resetHometownOptions([])
            }
        });

    }, [hometownTextField.query, hometownTextField.googleServiceActive, fetch, setHometownOptions, resetHometownOptions]);

    const updateState = (hometown_query, error) => {
        if (error) {
            invalidHometownQuery()
        } else {
            setHometownSearchQuery(hometown_query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let hometown_query = event.target.value
        delay(function () {
            updateState(hometown_query, !validate(String(hometown_query).trim(), validate_fields.hometown_query))
        }, DELAY_HALF_SECOND);
    };
    const handleChangeAutocomplete = (event, value) => {
        setHometownSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            open={hometownTextField.open}
            options={hometownTextField.options}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={hometownTextField.textHelper}
                    error={hometownTextField.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})

/* airport components */
export const HometownAirportTextField = connect((state) => ({
    airportTextField: state.FormBank.homeTownAirportTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchHometownAirportTextField: Actions.fetchHometownAirportTextField,
    setHometownAirportSelected: Actions.setHometownAirportSelected,
    invalidHometownAirportQuery: Actions.invalidHometownAirportQuery,
},
)(({
    label = "",
    required = "",
    id = "",
    fetchHometownAirportTextField,
    setHometownAirportSelected,
    airportTextField,
    invalidHometownAirportQuery,
    value = airportTextField.airportSelected,
    uuid,
    lang,
}) => {
    const updateState = (airport_query, error) => {
        if (error) {
            invalidHometownAirportQuery()
        } else {
            fetchHometownAirportTextField(airport_query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let airport_query = event.target.value
        delay(function () {
            updateState(airport_query, !validate(String(airport_query).trim(), validate_fields.airport_query))
        }, DELAY_ZERO_SECOND);
    };
    const handleChangeAutocomplete = (event, value) => {
        setHometownAirportSelected(value, uuid, lang)
    };


    React.useEffect(() => {
        const getAddress = async () => {
            const airport = await getIPFromAmazon();
            if (airport) { //airportFrom not set
                setHometownAirportSelected(airport, uuid, lang)
            }
        }
        getAddress()


    }, []
    )


    return (
        <Autocomplete
            id={id}
            open={airportTextField.open}
            options={airportTextField.airportResource}
            getOptionLabel={option => option.iata + " (" + option.city_name + " " + option.airport + ")"}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={airportTextField.textHelper}
                    error={airportTextField.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})


/* gender components */
export const GenderTextField = connect((state) => ({
    genderTextField: state.FormBank.genderTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchGenderTextField: Actions.fetchGenderTextField,
    setGenderSelected: Actions.setGenderSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchGenderTextField,
    setGenderSelected,
    genderTextField,
    value = genderTextField.genderSelected,
    fetch = !genderTextField.isLoad && fetchGenderTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setGenderSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            options={genderTextField.genderResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={genderTextField.textHelper}
                    error={genderTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})

/* orientation components */
export const OrientationTextField = connect((state) => ({
    orientationTextField: state.FormBank.orientationTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchOrientationTextField: Actions.fetchOrientationTextField,
    setOrientationSelected: Actions.setOrientationSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchOrientationTextField,
    setOrientationSelected,
    orientationTextField,
    value = orientationTextField.orientationSelected,
    fetch = !orientationTextField.isLoad && fetchOrientationTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setOrientationSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            options={orientationTextField.orientationResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={orientationTextField.textHelper}
                    error={orientationTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})

/* religion components */
export const ReligionTextField = connect((state) => ({
    religionTextField: state.FormBank.religionTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchReligionTextField: Actions.fetchReligionTextField,
    setReligionSelected: Actions.setReligionSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchReligionTextField,
    setReligionSelected,
    religionTextField,
    value = religionTextField.religionSelected,
    fetch = !religionTextField.isLoad && fetchReligionTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setReligionSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            options={religionTextField.religionResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={religionTextField.textHelper}
                    error={religionTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})

/* profession components */
export const ProfessionTextField = connect((state) => ({
    professionTextField: state.FormBank.professionTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchProfessionTextField: Actions.fetchProfessionTextField,
    setProfessionSelected: Actions.setProfessionSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchProfessionTextField,
    setProfessionSelected,
    professionTextField,
    value = professionTextField.professionSelected,
    fetch = !professionTextField.isLoad && fetchProfessionTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setProfessionSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            multiple
            id={id}
            options={professionTextField.professionResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    placeholder="more"
                    helperText={professionTextField.textHelper}
                    error={professionTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})

/* education components */
export const EducationTextField = connect((state) => ({
    educationTextField: state.FormBank.educationTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchEducationTextField: Actions.fetchEducationTextField,
    setEducationSelected: Actions.setEducationSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchEducationTextField,
    setEducationSelected,
    educationTextField,
    value = educationTextField.educationSelected,
    fetch = !educationTextField.isLoad && fetchEducationTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setEducationSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            options={educationTextField.educationResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={educationTextField.textHelper}
                    error={educationTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})


/* culture components */
export const CultureTextField = connect((state) => ({
    cultureTextField: state.FormBank.cultureTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchCultureTextField: Actions.fetchCultureTextField,
    setCultureSelected: Actions.setCultureSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchCultureTextField,
    setCultureSelected,
    cultureTextField,
    value = cultureTextField.cultureSelected,
    fetch = !cultureTextField.isLoad && fetchCultureTextField()
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setCultureSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            multiple
            id={id}
            options={cultureTextField.cultureResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    placeholder="more"
                    helperText={cultureTextField.textHelper}
                    error={cultureTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})


/*  birthday text field  */
export const BirthdayDatePiker = connect((state) => ({
    birthdayDatePiker: state.FormBank.birthdayDatePiker,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setBirthday: Actions.setBirthday,
    setErrorBirthday: Actions.setErrorBirthday,
    clearErrorBirthday: Actions.clearErrorBirthday,
    postBirthday: Actions.postBirthday,
})(({
    uuid,
    lang,
    label = "Birthday",
    id = "",
    setBirthday,
    birthdayDatePiker,
    setErrorBirthday,
    clearErrorBirthday,
    postBirthday,
    error = birthdayDatePiker.error,
    helperText = birthdayDatePiker.textHelper,
    value = birthdayDatePiker.birthday !== null ? birthdayDatePiker.birthday.year + "/" + birthdayDatePiker.birthday.month + "/" + birthdayDatePiker.birthday.day : null
}) => {
    const handleDateChange = (date) => {
        if (date !== undefined
            && date !== "Invalid Date"
            && date !== null
            && moment().diff(date, 'days') > 0
        ) {
            let date_moment = moment(date, 'YYYY/MM/DD')
            let day = date_moment.format("D")
            let month = date_moment.format("MM")
            let year = date_moment.format("YYYY")
            let obj = {
                year: year,
                month: month,
                day: day,
            }
            setBirthday(obj)
            postBirthday(obj, uuid, lang)
            clearErrorBirthday()
        } else {
            postBirthday(null, uuid, lang)
            setErrorBirthday()
        }
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                autoOk={true}
                disableToolbar={true}
                disableFuture={true}
                variant="inline"
                orientation="landscape"
                format="yyyy/MM/dd"
                views={["year", "month", "date"]}
                id={id}
                openTo="year"
                label={label}
                value={value}
                helperText={helperText}
                error={error}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
            />
        </MuiPickersUtilsProvider>
    )
})


/* language components */
export const LanguageTextField = connect((state) => ({
    languageTextField: state.FormBank.languageTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

}), {
    fetchLanguageTextField: Actions.fetchLanguageTextField,
    setLanguageSelected: Actions.setLanguageSelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchLanguageTextField,
    setLanguageSelected,
    languageTextField,
    valueLanguage = languageTextField.languageSelected,
    fetchLanguage = !languageTextField.isLoad && fetchLanguageTextField(),
}) => {
    const handleChangeAutocomplete = (event, value) => {
        setLanguageSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            options={languageTextField.languageResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={valueLanguage}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={languageTextField.textHelper}
                    error={languageTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})


/* fluency components */
export const FluencyTextField = connect((state) => ({
    languageTextField: state.FormBank.languageTextField,
    fluencyTextField: state.FormBank.fluencyTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchFluencyTextField: Actions.fetchFluencyTextField,
    setFluencySelected: Actions.setFluencySelected,
},
)(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    fetchFluencyTextField,
    setFluencySelected,
    fluencyTextField,
    value = fluencyTextField.fluencySelected,
    fetch = !fluencyTextField.isLoad && fetchFluencyTextField(),

    languageTextField,
    languageAppend = languageTextField.languageSelected
}) => {

    const handleChangeAutocomplete = (event, value) => {
        setFluencySelected(value, languageAppend, uuid, lang)
        value = null
    };
    return (
        <Autocomplete
            id={id}
            options={fluencyTextField.fluencyResource}
            getOptionLabel={option => option.title}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={fluencyTextField.textHelper}
                    error={fluencyTextField.error}
                    required={required}
                    id={id}
                />}
        />
    )
})


/*  about me text field  */
export const AboutMeTextField = connect((state) => ({
    aboutMeTextField: state.FormBank.aboutMeTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setAboutMe: Actions.setAboutMe,
    fetchAboutMe: Actions.fetchAboutMe,
})(({
    uuid,
    lang,
    label = "About me",
    required = "",
    id = "",
    setAboutMe,
    fetchAboutMe,
    aboutMeTextField,
    value = aboutMeTextField.aboutMe,
    entities = new Entities(),
}) => {
    const [fetchedAboutMe, setFetchAboutMe] = React.useState(false)
    if (!fetchedAboutMe) {
        setFetchAboutMe(true)
        fetchAboutMe(uuid, lang)
    }
    const handleChangeTextField = event => {
        setAboutMe(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={aboutMeTextField.textHelper}
            error={aboutMeTextField.error}
            label={label}
            multiline
            rows="5"
            required={required}
            id={id}
            onChange={handleChangeTextField}
        />
    )
})

/*  facebook text field  */
export const FacebookTextField = connect((state) => ({
    facebookTextField: state.FormBank.facebookTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setFacebook: Actions.setFacebook,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setFacebook,
    facebookTextField,
    value = facebookTextField.facebook,
    entities = new Entities(),
}) => {
    const handleChangeTextField = event => {
        setFacebook(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={facebookTextField.textHelper}
            error={facebookTextField.error}
            label={label}
            required={required}
            id={id}
            placeholder={'www.facebook.com/'}
            onChange={handleChangeTextField}
        />
    )
})

/*  pinterest text field  */
export const PinterestTextField = connect((state) => ({
    pinterestTextField: state.FormBank.pinterestTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setPinterest: Actions.setPinterest,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setPinterest,
    pinterestTextField,
    value = pinterestTextField.pinterest,
    entities = new Entities(),
}) => {
    const handleChangeTextField = event => {
        setPinterest(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={pinterestTextField.textHelper}
            error={pinterestTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            placeholder={'www.pinterest.com/'}
        />
    )
})

/*  instagram text field  */
export const InstagramTextField = connect((state) => ({
    instagramTextField: state.FormBank.instagramTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setInstagram: Actions.setInstagram,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setInstagram,
    instagramTextField,
    value = instagramTextField.instagram,
    entities = new Entities(),
}) => {
    const handleChangeTextField = event => {
        setInstagram(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={instagramTextField.textHelper}
            error={instagramTextField.error}
            label={label}
            required={required}
            id={id}
            placeholder={'www.instagram.com/'}
            onChange={handleChangeTextField}
        />
    )
})

/*  twitter text field  */
export const TwitterTextField = connect((state) => ({
    twitterTextField: state.FormBank.twitterTextField,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setTwitter: Actions.setTwitter,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setTwitter,
    twitterTextField,
    value = twitterTextField.twitter,
    entities = new Entities(),
}) => {
    const handleChangeTextField = event => {
        setTwitter(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={twitterTextField.textHelper}
            error={twitterTextField.error}
            label={label}
            required={required}
            id={id}
            placeholder={'www.twitter.com/'}
            onChange={handleChangeTextField}
        />
    )
})

/*  snapchat text field  */
export const SnapchatTextField = connect((state) => ({
    snapchatTextField: state.FormBank.snapchatTextField,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setSnapchat: Actions.setSnapchat,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    setSnapchat,
    snapchatTextField,
    value = snapchatTextField.snapchat,
    entities = new Entities(),
}) => {
    const handleChangeTextField = event => {
        setSnapchat(entities.encode(event.target.value), uuid, lang)
    }
    return (
        <TextField
            value={entities.decode(value)}
            helperText={snapchatTextField.textHelper}
            error={snapchatTextField.error}
            label={label}
            required={required}
            id={id}
            placeholder={'www.snapchat.com/'}
            onChange={handleChangeTextField}
        />
    )
})


/* email address text field login */
export const EmailAddressLoginTextField = connect((state) => ({
    emailAddressLoginTextField: state.FormBank.emailAddressLoginTextField,

}), {
    checkEmailExsit: Actions.checkEmailExsit,
    invalidEmailAddress: Actions.invalidEmailAddress,
    setEmailAddressLogin: Actions.setEmailAddressLogin,
})(({
    label = "Email Address",
    required = "",
    id = "",
    emailAddressLoginTextField,
    setEmailAddressLogin,
    defaultValue = emailAddressLoginTextField.emailAddressOrUsername,
    disabled,
}) => {

    const handleChangeTextField = event => {
        let email = event.target.value
        setEmailAddressLogin(String(email))
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={emailAddressLoginTextField.textHelper}
            error={emailAddressLoginTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})



/*  password text field login */
export const PasswordLoginTextField = connect((state) => ({
    passwordLoginTextField: state.FormBank.passwordLoginTextField,
}), {
    setPasswordLogin: Actions.setPasswordLogin,
})(({
    label = "Password",
    required = "",
    id = "",
    setPasswordLogin,
    passwordLoginTextField,
    defaultValue = passwordLoginTextField.password,
    disabled,
}) => {
    const handleChangeTextField = event => {
        let password = event.target.value
        setPasswordLogin(String(password))
    };
    return (
        <TextField
            defaultValue={defaultValue}
            type={passwordLoginTextField.fieldType}
            helperText={passwordLoginTextField.textHelper}
            error={passwordLoginTextField.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})


export const PasswordEyeLogin = connect((state) => ({
    passwordLoginTextField: state.FormBank.passwordLoginTextField,
}), {
    setPasswordLoginTextFieldType: Actions.setPasswordLoginTextFieldType,
})(({
    passwordLoginTextField,
    setPasswordLoginTextFieldType,
}) => {
    return (
        <div>
            {passwordLoginTextField.fieldType === PASSWORD_TEXT_FILED_TYPE_HIDDEN && <div onClick={() => setPasswordLoginTextFieldType(PASSWORD_TEXT_FILED_TYPE_DISPLAY)} ><EyeOpenPasswordIcon /></div>}
            {passwordLoginTextField.fieldType === PASSWORD_TEXT_FILED_TYPE_DISPLAY && <div onClick={() => setPasswordLoginTextFieldType(PASSWORD_TEXT_FILED_TYPE_HIDDEN)} ><EyeClosePasswordIcon /></div>}
        </div>

    )
})



/* search trip destination components */
export const SearchDestinationTextField = connect((state) => ({
    searchDestination: state.FormBank.TimelineWizard.searchDestination,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
}), {
    fetchSearchDestinationTextField: Actions.fetchSearchDestinationTextField,
    setSearchDestinationSelected: Actions.setSearchDestinationSelected,
    invalidSearchDestinationQuery: Actions.invalidSearchDestinationQuery,

},
)(({
    label = "",
    required = "",
    id = "",
    fetchSearchDestinationTextField,
    setSearchDestinationSelected,
    searchDestination,
    invalidSearchDestinationQuery,
    value = searchDestination.destinationSelected,
    setOpenSearch,

}) => {
    const updateState = (query, error) => {
        if (error) {
            invalidSearchDestinationQuery()
        } else {
            fetchSearchDestinationTextField(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        setSearchDestinationSelected(value)
        setOpenSearch(value === null ? "OPEN" : "COMPLETE")
    }
    return (
        <Autocomplete
            id={id}
            open={searchDestination.open}
            options={searchDestination.searchDestinationSet}
            getOptionLabel={option => option.stateName === "" ?
                option.name :
                option.name + " ( " + option.stateName + " " + option.countryName + " )"}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={searchDestination.textHelper}
                    error={searchDestination.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})


const timelineAutocompleteStyle = makeStyles({
    groupLabel: {
        font: "italic 16px/16px Futura Lt BT",
        color: "#8F8F8F",
        marginTop: "10px",
        marginBotton: "20px",
        marginLeft: "5px",
        textDecoration: "underline"
    },
    option: {
        color: "#8F8F8F",
        marginTop: "10px",
        padding: "0px",
        color: "#8F8F8F",
        '& span': {
            font: "16px/16px Futura Md BT"
        },
        '& div': {
            font: "16px/16px Futura Lt BT"
        }
    }
})

/* search trip timeline destination components */
export const SearchTimelineDestinationTextField = connect((state) => ({
    searchDestination: state.FormBank.TimelineWizard.searchDestination,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
}), {
    fetchSearchDestinationTextField: Actions.fetchSearchDestinationTextField,
    setSearchDestinationSelected: Actions.setSearchDestinationSelected,
    invalidSearchDestinationQuery: Actions.invalidSearchDestinationQuery,

},
)(({
    label = "",
    required = "",
    id = "",
    fetchSearchDestinationTextField,
    setSearchDestinationSelected,
    searchDestination,
    activeTrip,
    invalidSearchDestinationQuery,
    value = searchDestination.destinationSelected,
    setOpenSearch,

}) => {
    const [destinationQuery, setDestinationQuery] = React.useState("")
    const [googleResults, setGoogleResults] = React.useState([])
    const timelineDestinationAutocompleteService = { current: null };

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if (timelineDestinationAutocompleteService.current) {
                    timelineDestinationAutocompleteService.current.getPlacePredictions(request, callback);
                }
            }, 200),
        [timelineDestinationAutocompleteService],
    );
    React.useEffect(() => {
        if (!timelineDestinationAutocompleteService.current && window.google) {
            timelineDestinationAutocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!timelineDestinationAutocompleteService.current) {
            return undefined;
        }

        if (!destinationQuery || destinationQuery === '') {
            return undefined;
        }
        fetch({
            input: destinationQuery,
            types: ['(cities)'],
        }, results => {

            let dbCities = searchDestination.searchDestinationSet;
            if (results && results.length > 0) {
                let googles = results.map(result => {
                    let locationArray = result.description.split(",")
                    let city = ""
                    let state = ""
                    let country = ""
                    if (locationArray.length >= 3) {
                        city = locationArray[0]
                        state = locationArray[1]
                        country = locationArray[2]
                    } else if (locationArray.length === 2) {
                        city = locationArray[0]
                        country = locationArray[1]
                    } else {
                        city = locationArray[0]
                    }

                    let doubleLocation = false;
                    if (dbCities && dbCities.length > 0) {
                        dbCities.forEach(element => {
                            let location = element.name.split(",")
                            let dbCity = location[0].replace(/\s+/g, '');;
                            let dbCountry = element.countryName;
                            const searchedCountry = country.replace(/\s+/g, '');
                            const searchedCity = city.replace(/\s+/g, '');
                            if ((dbCity && dbCountry && searchedCountry === dbCountry && dbCity === searchedCity) || element.name === result.description) {
                                doubleLocation = true;
                            }
                        });

                    }

                    if (doubleLocation) {
                        return null;
                    }

                    return {
                        name: result.description,
                        source: "Other destinations",
                        description: result.description,
                        place_id: result.place_id,
                        reference: result.reference,
                        googlePlaceId: result.place_id,
                        googlePlaceName: city + ", " + state,
                        googlePlaceCity: city,
                        googlePlaceState: state,
                        googlePlaceCountry: country,
                        tripDuration: activeTrip.tripDuration,
                        tripId: activeTrip.tripId,
                    }
                }).filter(Boolean);
                setGoogleResults(googles)
            }
        });

    }, [destinationQuery, fetch]);

    const updateState = (query, error) => {
        if (error) {
            invalidSearchDestinationQuery()
        } else {
            //console.log(`fetchSearchDestinationTextField  =  ${query}`)
            let fromTimeline = true
            fetchSearchDestinationTextField(query, fromTimeline)
            setDestinationQuery(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        //console.log(`handleChangeTextField query  =  ${query}`)
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        //console.log("handleChangeAutocomplete() value = ")
        //console.log (value)
        setSearchDestinationSelected(value)
        setOpenSearch(value === null ? "OPEN" : "COMPLETE")
    }

    let options = searchDestination?.searchDestinationSet || [];


    if (options) {
        options = options?.concat(googleResults)
    }

    //let open = options.length > 0 

    const classes = timelineAutocompleteStyle();
    //console.log("SearchTimelineDestinationTextField") 
    //console.log("searchDestination : ")
    //console.log(searchDestination)
    //console.log("activeTrip")
    /* tripDuration: "7"
       tripId: "8055" */
    //console.log(activeTrip)

    return (
        <Autocomplete
            id={id}
            classes={{
                groupLabel: classes.groupLabel,
                option: classes.option,
            }}
            open={searchDestination.open}
            options={options}
            groupBy={(option) => option?.source}
            getOptionLabel={option => option?.name}
            renderOption={(option) => {
                if (option) {
                    if (option?.source === "Other destinations") return <div> {option.name}</div>
                    else return <span>{option.name}</span>
                }
            }}

            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={searchDestination.textHelper}
                    error={searchDestination.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})



/* search trip destination components */
export const SearchEstablishmentTextField = connect((state) => ({
    searchEstablishment: state.FormBank.TimelineWizard.searchEstablishment,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

    lang: state.Setting.lang,
}), {
    fetchSearchEstablishmentTextField: Actions.fetchSearchEstablishmentTextField,
    setSearchEstablishmentSelected: Actions.setSearchEstablishmentSelected,
    invalidSearchEstablishmentQuery: Actions.invalidSearchEstablishmentQuery,

},
)(({
    lang,
    label = "",
    required = "",
    id = "",
    fetchSearchEstablishmentTextField,
    setSearchEstablishmentSelected,
    searchEstablishment,
    invalidSearchEstablishmentQuery,
    value = searchEstablishment.establishmentSelected,
    setOpenSearch,
    destinationId,
}) => {
    const updateState = (query, error) => {
        if (error) {
            invalidSearchEstablishmentQuery()
        } else {
            fetchSearchEstablishmentTextField(query, lang, destinationId) // type see
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.establishment_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        setSearchEstablishmentSelected(value)
        setOpenSearch(value === null ? "OPEN" : "COMPLETE")
    }
    return (
        <Autocomplete
            id={id}
            open={searchEstablishment.open}
            options={searchEstablishment.searchEstablishmentSet}
            getOptionLabel={option => option.countryName === "" ?
                option.name :
                option.name + " ( " + option.countryName + " )"}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={searchEstablishment.textHelper}
                    error={searchEstablishment.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})



/* inline edit trip name field */
export const TripNameTextField = connect((state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
}), {
    updateTimelineTripName: Actions.updateTimelineTripName,
    alertBar: Actions.alertBar,
},
)(({
    tripInList = null,
    label = "",
    required = false,
    activeTrip,
    tripName = tripInList === null ? activeTrip.tripName : tripInList.tripName,
    tripId = tripInList === null ? activeTrip.tripId : tripInList.tripID,
    id = tripId + "_trip_name",
    defaultTripName = tripName,
    updateTimelineTripName,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
    alertBar,
    inputStyle = {
        font: "24px/24px Futura Md BT",
        color: "var(--mainWhite)",
        width: "300px",
    }
}) => {
    const updateState = (tripName, error) => {
        if (!error) {
            updateTimelineTripName(tripId, tripName, uuid)
        } else {
            alertBar("warning", "Trip Name is invalid")
        }
    }
    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeHandleTextField = event => {
        const tripName = event.target.value
        setInputValue(tripName)
    };
    const onBlurHandler = () => {
        updateState(inputValue, !validate(String(inputValue), validate_fields.trip_name))
    }
    const tripNameStylingTextField = makeStyles(theme => ({
        rootInline: {
            '& .MuiInput-input': inputStyle,
            '& .MuiInput-underline:before': {
                borderBottom: "0px",
            },
            '& .MuiInput-underline:after': {
                borderBottom: "2px solid var(--mainGreen)",
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: "2px solid rgba(25, 188, 155, 0.45)",
            },
        },
    }))
    const tripNameStyling = tripNameStylingTextField()
    const [inputValue, setInputValue] = React.useState(defaultTripName)

    React.useEffect(() => {
        // delay(function () {
        setInputValue(tripName)
        // }, DELAY_THERE_SECOND);
    }, [tripName, tripId, activeTrip])

    return (
        <div className={tripNameStyling.rootInline}>
            <TextField
                value={inputValue}
                label={label}
                required={required}
                id={id}
                onBlur={onBlurHandler}
                className={"tripNameField"}
                onChange={handleChangeHandleTextField}
            />
        </div>
    )
})


/* trip start date updating */
export const TripStartDateTextField = connect((state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    savedTrip: state.ActiveTrip,
}), {
    updateTimelineTripDate: Actions.updateTimelineTripDate,
    unsetTimelineTripDate: Actions.unsetTimelineTripDate,
},
)(({
    label = "",
    activeTrip,
    tripInList = null,
    tripId = tripInList === null ? activeTrip.tripId : tripInList.tripID,
    tripNoDate = tripInList === null ? activeTrip.tripNoDate : tripInList.noDate,
    tripStartDate = tripInList === null ? activeTrip.tripStartDate : tripInList.tripStartDate,
    tripDuration = tripInList === null ? activeTrip.tripDuration : tripInList.tripDaysCount,
    id = tripId + "_trip_date",
    value = tripNoDate === "0" ? (tripStartDate + " 01:00") : (moment().format('YYYY-MM-DD') + " 01:00"),
    updateTimelineTripDate,
    unsetTimelineTripDate,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
    isShortFormat = false,
    savedTrip,
    inputStyle = {
        font: "16px/19px Futura Lt BT",
        color: "#6A9EEC",
        width: "300px",
        textAlign: "center",
    }
}) => {

    const isRoadTrip = savedTrip !== null && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";
    if (isRoadTrip) {
        inputStyle.color = 'white';
    }

    const isTripinListRoadTrip = tripInList !== null && tripInList.tripTravelCategory?.code === "road";

    const handleDateChange = (date) => {
        if (date !== undefined
            && date !== "Invalid Date"
            && date !== null
        ) {
            let date_moment = moment(date, 'YYYY/MM/DD')
            let day = date_moment.format("DD")
            let month = date_moment.format("MM")
            let year = date_moment.format("YYYY")

            updateTimelineTripDate(tripId, year + "-" + month + "-" + day, uuid, tripStartDate, tripNoDate)
        } else {
            unsetTimelineTripDate(tripId, uuid, tripStartDate, tripNoDate)
        }
    }
    const tripDateStylingTextField = makeStyles(theme => ({
        rootInline: {
            width: "100%",
            '& .MuiInput-input': inputStyle,
            '& .MuiTextField-root': {
                width: "100%",
            },
            '& .MuiInput-underline:before': {
                borderBottom: "0px",
            },
            '& .MuiInput-underline:after': {
                borderBottom: "2px solid var(--mainGreen)",
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: "2px solid rgba(25, 188, 155, 0.45)",
            },

        },

    }))


    const tripDateStyling = tripDateStylingTextField()
    const inputformatLabel = () => {
        return tripNoDate === "1" ?
            (tripDuration === "1" ? tripDuration + ` Day ${isTripinListRoadTrip ? 'Road ' : ''}Trip` : tripDuration + ` Days ${isTripinListRoadTrip ? 'Road ' : ''}Trip`)
            : (isShortFormat ? ReformatTripRangeDate(tripStartDate, tripDuration) : ReformatTimelineTripRangeDate(tripStartDate, (tripDuration - 1)))
    }
    


    return (

        <div className={tripDateStyling.rootInline}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    variant="dialog"
                    orientation="landscape"
                    id={id}
                    label={label}
                    value={value}
                    error={false}
                    helperText={""}
                    cancelLabel={"ClOSE"}
                    clearLabel={"RESET START DATE"}
                    clearable={true}
                    onChange={handleDateChange}
                    labelFunc={inputformatLabel}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
})


/*  about me text field  */
export const TripDescriptionTextField = connect((state) => ({
    activeTrip: state.FormBank.TimelineWizard.activeTrip,

    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setTripDescription: Actions.setTripDescription,
})(({
    uuid,
    lang,
    label = "",
    required = "",
    id = "",
    activeTrip,
    tripDescription = activeTrip.tripDescription,
    tripId = activeTrip.tripId,
    setTripDescription,
    lineNum,
    entities = new Entities(),
}) => {
    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms);
        }
    })()
    const handleChangeTextField = event => {
        const description = event.target.value
        delay(function () {
            setTripDescription(lang, uuid, tripId, entities.encode(description))
        }, DELAY_ONE_SECOND)
    }

    return (
        <TextField
            value={entities.decode(tripDescription)}
            helperText={""}
            error={false}
            label={label}
            multiline
            rows={lineNum}
            required={required}
            id={id}
            onChange={handleChangeTextField}
        />
    )
})


/* airport leaveing from components */
export const LeavingFromAirportTextField = connect((state) => ({
    hometownAirportTextField: state.FormBank.homeTownAirportTextField.airportSelected,
    airportTextField: state.FormBank.TimelineWizard.activeTrip.leavingFromAirport,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchLeavingFromAirportTextField: Actions.fetchLeavingFromAirportTextField,
    setLeavingFromAirportSelected: Actions.setLeavingFromAirportSelected,
    invalidLeavingFromAirportQuery: Actions.invalidLeavingFromAirportQuery,
},
)(({
    label = "",
    required = "",
    id = "",
    fetchLeavingFromAirportTextField,
    setLeavingFromAirportSelected,
    airportTextField,
    invalidLeavingFromAirportQuery,
    value = airportTextField.airportSelected,
    uuid,
    lang,
}) => {
    const updateState = (airport_query, error) => {
        if (error) {
            invalidLeavingFromAirportQuery()
        } else {
            fetchLeavingFromAirportTextField(airport_query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let airport_query = event.target.value
        delay(function () {
            updateState(airport_query, !validate(String(airport_query).trim(), validate_fields.airport_query))
        }, DELAY_ZERO_SECOND);
    };
    const handleChangeAutocomplete = (event, value) => {
        setLeavingFromAirportSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            open={airportTextField.open}
            options={airportTextField.airportResource}
            getOptionLabel={option => option.iata + " " + option.city_name + " (" + option.airport + ")"}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={airportTextField.textHelper}
                    error={airportTextField.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})

/* airport returning to components */
export const ReturningToAirportTextField = connect((state) => ({
    hometownAirportTextField: state.FormBank.homeTownAirportTextField,
    airportTextField: state.FormBank.TimelineWizard.activeTrip.returningToAirport,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    fetchReturningToAirportTextField: Actions.fetchReturningToAirportTextField,
    setReturningToAirportSelected: Actions.setReturningToAirportSelected,
    invalidReturningToAirportQuery: Actions.invalidReturningToAirportQuery,
},
)(({
    label = "",
    required = "",
    id = "",
    fetchReturningToAirportTextField,
    setReturningToAirportSelected,
    airportTextField,
    invalidReturningToAirportQuery,
    value = airportTextField.airportSelected,
    uuid,
    lang,
}) => {
    const updateState = (airport_query, error) => {
        if (error) {
            invalidReturningToAirportQuery()
        } else {
            fetchReturningToAirportTextField(airport_query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let airport_query = event.target.value
        delay(function () {
            updateState(airport_query, !validate(String(airport_query).trim(), validate_fields.airport_query))
        }, DELAY_ZERO_SECOND);
    };
    const handleChangeAutocomplete = (event, value) => {
        setReturningToAirportSelected(value, uuid, lang)
    };
    return (
        <Autocomplete
            id={id}
            open={airportTextField.open}
            options={airportTextField.airportResource}
            getOptionLabel={option => option.iata + " " + option.city_name + " (" + option.airport + ")"}
            onChange={handleChangeAutocomplete}
            value={value}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={airportTextField.textHelper}
                    error={airportTextField.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})



export const EmailAddressTextFieldCompanion = connect((state) => ({
    emailAddressTextFieldCompanion: state.FormBank.emailAddressTextFieldCompanion,
}), {
    checkEmailExsitCompanion: Actions.checkEmailExsitCompanion,
    invalidEmailAddressCompanion: Actions.invalidEmailAddressCompanion,
})(({
    label = "Email Address",
    required = "",
    id = "",
    checkEmailExsitCompanion,
    invalidEmailAddressCompanion,
    emailAddressTextFieldCompanion,
    defaultValue = emailAddressTextFieldCompanion.emailAddress,
    disabled,
}) => {
    const updateState = (email, error) => {
        if (error) {
            invalidEmailAddressCompanion()
        } else {
            checkEmailExsitCompanion(email)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    })();
    const handleChangeEmailAddressTextField = event => {
        let email = event.target.value
        delay(function () {
            updateState(email, !validate(String(email).toLowerCase(), validate_fields.email))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={emailAddressTextFieldCompanion.textHelper}
            error={emailAddressTextFieldCompanion.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeEmailAddressTextField}
            disabled={disabled}
        />
    )
})


/*  first name text field  */
export const FirstNameTextFieldCompanion = connect((state) => ({
    firstNameTextFieldCompanion: state.FormBank.firstNameTextFieldCompanion,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setFirstNameCompanion: Actions.setFirstNameCompanion,
    invalidFirstNameCompanion: Actions.invalidFirstNameCompanion,
})(({
    lang,
    uuid,
    label = "Last Name",
    required = "",
    id = "",
    setFirstNameCompanion,
    invalidFirstNameCompanion,
    firstNameTextFieldCompanion,
    defaultValue = firstNameTextFieldCompanion.firstName,
    disabled,
}) => {
    const updateState = (first_name, error) => {
        if (error) {
            invalidFirstNameCompanion()
        } else {
            setFirstNameCompanion(first_name, uuid, lang)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let first_name = event.target.value
        delay(function () {
            updateState(first_name, !validate(String(first_name).toLowerCase(), validate_fields.first_name))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={firstNameTextFieldCompanion.textHelper}
            error={firstNameTextFieldCompanion.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})

/*  last name text field  */
export const LastNameTextFieldCompanion = connect((state) => ({
    lastNameTextFieldCompanion: state.FormBank.lastNameTextFieldCompanion,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
}), {
    setLastNameCompanion: Actions.setLastNameCompanion,
    invalidLastNameCompanion: Actions.invalidLastNameCompanion,
})(({
    lang, uuid,
    label = "Last Name",
    required = "",
    id = "",
    setLastNameCompanion,
    invalidLastNameCompanion,
    lastNameTextFieldCompanion,
    defaultValue = lastNameTextFieldCompanion.lastName,
    disabled,
}) => {
    const updateState = (last_name, error) => {
        if (error) {
            invalidLastNameCompanion()
        } else {
            setLastNameCompanion(last_name, uuid, lang)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const handleChangeTextField = event => {
        let last_name = event.target.value
        delay(function () {
            updateState(last_name, !validate(String(last_name).toLowerCase(), validate_fields.last_name))
        }, DELAY_ONE_SECOND);
    };
    return (
        <TextField
            defaultValue={defaultValue}
            helperText={lastNameTextFieldCompanion.textHelper}
            error={lastNameTextFieldCompanion.error}
            label={label}
            required={required}
            id={id}
            onChange={handleChangeTextField}
            disabled={disabled}
        />
    )
})


/* Hotel Address text field */
export const HotelAddressTextField = connect((state) => ({}), {})(({ setHotelName }) => {
    const classes = hotelTextFieldUseStyles()
    const handleChangeTextField = event => {
        let hotel = event.target.value
        setHotelName(hotel)
    };
    return (
        <TextField
            className={classes.root}
            defaultValue={" "}
            helperText={""}
            label={"Place"}
            id={"HotelAddressLoginTextField"}
            onChange={handleChangeTextField}
        />
    )
})

/* Hotel Address text field */
export const PlaceAddressTextField = connect((state) => ({}), {})(({ setPlaceName }) => {
    const classes = hotelTextFieldUseStyles()
    const handleChangeTextField = event => {
        let placeName = event.target.value
        setPlaceName(placeName)
    };
    return (
        <TextField
            className={classes.root}
            defaultValue={" "}
            helperText={""}
            label={"Place"}
            id={"PlaceAddressLoginTextField"}
            onChange={handleChangeTextField}
        />
    )
})


const placeAutocompleteStyle = makeStyles({
    groupLabel: {
        font: "italic 16px/16px Futura Lt BT",
        color: "#8F8F8F",
        marginTop: "10px",
        marginBotton: "20px",
        marginLeft: "5px",
        textDecoration: "underline"
    },
    option: {
        color: "#8F8F8F",
        marginTop: "10px",
        padding: "0px",
        color: "#8F8F8F",
        '& span': {
            font: "16px/16px Futura Md BT"
        },
        '& div': {
            font: "16px/16px Futura Lt BT"
        }
    },
})

export const SearchPlaceTextField = connect((state) => {
    return {
        searchPlace: state.FormBank.TimelineWizard.searchPlace,
        addressSelected: state.FormBank.TimelineWizard.searchAddress.addressSelected,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        lang: state.Setting.lang,
        uuid_auth: state.Member.authModal.uuid,
    }
}, {
    fetchSearchPlaceTextField: Actions.fetchSearchPlaceTextField,
    setSearchPlaceSelected: Actions.setSearchPlaceSelected,
    invalidSearchPlaceQuery: Actions.invalidSearchPlaceQuery,
    closeSearchPlaceQuery: Actions.closeSearchPlaceQuery

},
)(({
    label = "",
    required = "",
    id = "",
    fetchSearchPlaceTextField,
    setSearchPlaceSelected,
    lang,
    searchPlace,
    activeTrip,
    invalidSearchPlaceQuery,
    value = searchPlace.placeSelected,
    addressSelected,
    setOpenSearch,
    setPlaceName,
    placeName,
    activeDay,
    addressRef,
    closeSearchPlaceQuery,
    setCustom,
    setSelectedPlace,

}) => {

    const placeNameStylingTextField = makeStyles(theme => ({
        rootInline: {
            '& .MuiInput-underline:before': {
                borderBottom: "0px",
            },
            '& .MuiInput-underline:after': {
                borderBottom: "none",
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: "none",
            },
        },
    }))
    const placeNameStyling = placeNameStylingTextField()
    const [destinationQuery, setDestinationQuery] = React.useState("")
    const [googleResults, setGoogleResults] = React.useState([])
    const timelineDestinationAutocompleteService = { current: null };

    const activeDestination = activeTrip.tripDestinations && activeDay && activeTrip.tripDestinations.filter(destination => {
        // find all with
        return destination.td_id === activeDay.tripDestinationId;
    })

    const lng = activeDestination && activeDestination[0]?.longitude;
    const lat = activeDestination && activeDestination[0]?.latitude;


    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                timelineDestinationAutocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );
    React.useEffect(() => {

        if (!timelineDestinationAutocompleteService.current && window.google) {
            timelineDestinationAutocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!timelineDestinationAutocompleteService.current) {
            return undefined;
        }

        if (!destinationQuery || destinationQuery === '') {
            return undefined;
        }


        var defaultPlace = new window.google.maps.LatLng(lat, lng);
        fetch({
            input: destinationQuery,
            location: defaultPlace,
            radius: 25000,
            types: ['establishment'],
            strictbounds: true,
        }, results => {


            if (results && results.length > 0) {
                let googles = results.map(result => {
                    let locationArray = result.description.split(",")
                    let city = ""
                    let state = ""
                    let country = ""
                    if (result.terms && result.terms.length <= 3) {
                        city = result.terms[1] && result.terms[1].value || "";
                        country = result.terms[2] && result.terms[2].value || "";
                    }
                    if (result.terms && result.terms.length === 4) {
                        city = result.terms[1] && result.terms[1].value || "";
                        state = result.terms[2] && result.terms[2].value || "";
                        country = result.terms[3] && result.terms[3].value || "";
                    }
                    if (result.terms && result.terms.length === 5) {
                        city = result.terms[2] && result.terms[2].value || "";
                        state = result.terms[3] && result.terms[3].value || "";
                        country = result.terms[4] && result.terms[4].value || "";
                    }

                    if (result.terms && result.terms.length === 6) {
                        city = result.terms[3] && result.terms[3].value || "";
                        state = result.terms[4] && result.terms[4].value || "";
                        country = result.terms[5] && result.terms[5].value || "";
                    }

                    return {
                        name: result && result.structured_formatting.main_text ? result.structured_formatting.main_text : result.description,
                        source: "Other places",
                        description: result.description,
                        place_id: result.place_id,
                        reference: result.reference,
                        googlePlaceId: result.place_id,
                        googlePlaceName: result.description,
                        googlePlaceCity: city,
                        googlePlaceState: state,
                        googlePlaceCountry: country,
                        tripDuration: activeTrip.tripDuration,
                        tripId: activeTrip.tripId,
                    }
                })
                setGoogleResults(googles)
            }
        });


    }, [destinationQuery, fetch]);

    const updateState = (query, error) => {
        if (error) {
            invalidSearchPlaceQuery()
        } else {
            //console.log(`fetchSearchDestinationTextField  =  ${query}`)
            let fromTimeline = true

            fetchSearchPlaceTextField(query, fromTimeline, lang, lat, lng)

            setDestinationQuery(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        setCustom(false)
        setPlaceName(query)

        //console.log(`handleChangeTextField query  =  ${query}`)
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        //console.log("handleChangeAutocomplete() value = ")
        setCustom(true)
        setSearchPlaceSelected(value)
        if (value && value.name) {
            setPlaceName(value.name)
            setSelectedPlace(value)
            closeSearchPlaceQuery()
        }
        else {
            closeSearchPlaceQuery()
        }
        setOpenSearch(value === null ? "OPEN" : "COMPLETE")
    }

    let options = searchPlace.searchPlaceSet
    options = options?.concat(googleResults)
    //let open = options.length > 0 

    const classes = placeAutocompleteStyle();
    //console.log("SearchTimelineDestinationTextField") 
    //console.log("searchDestination : ")
    //console.log(searchDestination)
    //console.log("activeTrip")
    /* tripDuration: "7"
       tripId: "8055" */
    //console.log(activeTrip)

    const handleClickAway = () => {
        closeSearchPlaceQuery()
    };
    const PopperMy = function (props) {
        return (<Popper {...props} placement='bottom' />)
    }

    const handleKeyPress = (e) => {
        const event = e;
        if (event.key === "Enter") {
            addressRef.current.focus()
            closeSearchPlaceQuery()
            event.preventDefault();
        }

    }
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Autocomplete
                id={id}
                classes={{
                    groupLabel: classes.groupLabel,
                    option: classes.option,
                }}
                clearOnBlur={false}
                PopperComponent={PopperMy}
                onInputChange={(_, value, reason) => {
                    if (reason === 'clear') {
                        invalidSearchPlaceQuery()
                    }
                }}

                open={searchPlace.open}
                options={options}
                ListboxProps={{ style: { maxHeight: '100%' } }}
                groupBy={(option) => option.source}
                getOptionLabel={option => option.name}
                renderOption={(option) => {
                    if (option.source === "Other places") return <div> {option.description}</div>
                    else return <span>{option.name}</span>
                }}

                onChange={handleChangeAutocomplete}
                value={value}
                renderInput={params =>
                    <TextField
                        {...params}
                        placeholder={label}
                        helperText={searchPlace.textHelper}
                        error={searchPlace.error}
                        InputProps={{
                            ...params.InputProps, disableUnderline: true,
                            onKeyPress: handleKeyPress
                        }}
                        required={required}
                        onChange={handleChangeTextField}
                        id={id}
                    />}
            />
        </ClickAwayListener>
    )
})



export const SearchAddressTextField = connect((state) => ({
    searchAddress: state.FormBank.TimelineWizard.searchAddress,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeDay: state.FormBank.TimelineWizard.activeDay,
    lang: state.Setting.lang,
    searchPlace: state.FormBank.TimelineWizard.searchPlace,
    uuid_auth: state.Member.authModal.uuid,
}), {
    fetchSearchAddressTextField: Actions.fetchSearchAddressTextField,
    setSearchAddressSelected: Actions.setSearchAddressSelected,
    invalidSearchAddressQuery: Actions.invalidSearchAddressQuery,

},
)(({
    label = "",
    required = "",
    id = "",
    fetchSearchAddressTextField,
    setSearchAddressSelected,
    searchAddress,
    activeTrip,
    invalidSearchAddressQuery,
    setOpenSearch,
    activeDay,
    searchPlace,
    addressRef,
}) => {
    const placeNameStylingTextField = makeStyles(theme => ({
        rootInline: {
            '& .MuiInput-underline:before': {
                borderBottom: "0px",
            },
            '& .MuiInput-underline:after': {
                borderBottom: "none",
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: "none",
            },
        },
    }))


    const [value, setSelected] = React.useState(searchAddress.addressSelected);
    const placeNameStyling = placeNameStylingTextField()
    const [destinationQuery, setDestinationQuery] = React.useState("")
    const [googleResults, setGoogleResults] = React.useState([])
    const timelineDestinationAutocompleteService = { current: null };

    React.useEffect(() => {
        if (searchAddress) {
            setSelected(searchAddress.addressSelected)
        }
    }, [searchAddress])

    React.useEffect(() => {
        try {
            if (searchPlace && searchPlace?.placeSelected?.address) {
                const searchedPlace = searchPlace?.placeSelected?.address;
                var defaultPlace = new window.google.maps.LatLng(lat, lng);
                fetch({
                    input: searchedPlace,
                    strictbounds: true,
                    location: defaultPlace,
                    radius: 25000,
                    types: ['address'],
                }, results => {

                    if (results && results.length > 0) {
                        let googles = results.map(result => {
                            let locationArray = result.description.split(",")
                            let city = ""
                            let state = ""
                            let country = ""
                            if (result.terms && result.terms.length <= 3) {
                                city = result.terms[1] && result.terms[1].value || "";
                                country = result.terms[2] && result.terms[2].value || "";
                            }
                            if (result.terms && result.terms.length === 4) {
                                city = result.terms[1] && result.terms[1].value || "";
                                state = result.terms[2] && result.terms[2].value || "";
                                country = result.terms[3] && result.terms[3].value || "";
                            }
                            if (result.terms && result.terms.length === 5) {
                                city = result.terms[2] && result.terms[2].value || "";
                                state = result.terms[3] && result.terms[3].value || "";
                                country = result.terms[4] && result.terms[4].value || "";
                            }

                            if (result.terms && result.terms.length === 6) {
                                city = result.terms[3] && result.terms[3].value || "";
                                state = result.terms[4] && result.terms[4].value || "";
                                country = result.terms[5] && result.terms[5].value || "";
                            }

                            return {
                                name: result.description,
                                source: "Other addresses",
                                description: result.description,
                                place_id: result.place_id,
                                reference: result.reference,
                                googlePlaceId: result.place_id,
                                googlePlaceName: result.description,
                                googlePlaceCity: city,
                                googlePlaceState: state,
                                googlePlaceCountry: country,
                                tripDuration: activeTrip.tripDuration,
                                tripId: activeTrip.tripId,
                            }
                        })

                        setSearchAddressSelected(googles[0]) ///for prefilling place search
                    }
                });

            }
        }
        catch {

        }
    }, [searchPlace?.placeSelected?.address])   ///update address of place search




    const activeDestination = activeTrip.tripDestinations && activeDay && activeTrip.tripDestinations.filter(destination => {
        // find all with
        return destination.td_id === activeDay.tripDestinationId;
    })

    const lng = activeDestination && activeDestination[0]?.longitude;
    const lat = activeDestination && activeDestination[0]?.latitude;

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                timelineDestinationAutocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );
    React.useEffect(() => {
        if (!timelineDestinationAutocompleteService.current && window.google) {
            timelineDestinationAutocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!timelineDestinationAutocompleteService.current) {
            return undefined;
        }

        if (!destinationQuery || destinationQuery === '') {
            return undefined;
        }


        var defaultPlace = new window.google.maps.LatLng(lat, lng);

        fetch({
            input: destinationQuery,
            strictbounds: true,
            location: defaultPlace,
            radius: 25000,
            types: ['address'],
        }, results => {

            if (results && results.length > 0) {
                let googles = results.map(result => {
                    let locationArray = result.description.split(",")
                    let city = ""
                    let state = ""
                    let country = ""
                    if (result.terms && result.terms.length <= 3) {
                        city = result.terms[1] && result.terms[1].value || "";
                        country = result.terms[2] && result.terms[2].value || "";
                    }
                    if (result.terms && result.terms.length === 4) {
                        city = result.terms[1] && result.terms[1].value || "";
                        state = result.terms[2] && result.terms[2].value || "";
                        country = result.terms[3] && result.terms[3].value || "";
                    }
                    if (result.terms && result.terms.length === 5) {
                        city = result.terms[2] && result.terms[2].value || "";
                        state = result.terms[3] && result.terms[3].value || "";
                        country = result.terms[4] && result.terms[4].value || "";
                    }

                    if (result.terms && result.terms.length === 6) {
                        city = result.terms[3] && result.terms[3].value || "";
                        state = result.terms[4] && result.terms[4].value || "";
                        country = result.terms[5] && result.terms[5].value || "";
                    }

                    return {
                        name: result.description,
                        source: "Other addresses",
                        description: result.description,
                        place_id: result.place_id,
                        reference: result.reference,
                        googlePlaceId: result.place_id,
                        googlePlaceName: result.description,
                        googlePlaceCity: city,
                        googlePlaceState: state,
                        googlePlaceCountry: country,
                        tripDuration: activeTrip.tripDuration,
                        tripId: activeTrip.tripId,
                    }
                })

                setGoogleResults(googles)
            }
        });


    }, [destinationQuery, fetch, searchPlace?.placeSelected]);

    const updateState = (query, error) => {
        if (error) {
            invalidSearchAddressQuery()
        } else {
            //console.log(`fetchSearchDestinationTextField  =  ${query}`)
            let fromTimeline = true
            fetchSearchAddressTextField(query, fromTimeline)
            setDestinationQuery(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        //console.log(`handleChangeTextField query  =  ${query}`)
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        //console.log("handleChangeAutocomplete() value = ")
        setSearchAddressSelected(value)
        setOpenSearch(value === null ? "OPEN" : "COMPLETE")
    }

    let options = []
    // let options = searchAddress.searchAddressSet
    options = options?.concat(googleResults)
    //let open = options.length > 0 

    const classes = timelineAutocompleteStyle();
    //console.log("SearchTimelineDestinationTextField") 
    //console.log("searchDestination : ")
    //console.log(searchDestination)
    //console.log("activeTrip")
    /* tripDuration: "7"
       tripId: "8055" */
    //console.log(activeTrip)

    const handleClickAway = () => {
        invalidSearchAddressQuery()
    };

    const PopperMy = function (props) {
        return (<Popper {...props} placement='bottom' />)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Autocomplete
                id={id}
                classes={{
                    groupLabel: classes.groupLabel,
                    option: classes.option,
                }}
                clearOnBlur={false}
                onInputChange={(_, value, reason) => {
                    if (reason === 'clear') {
                        invalidSearchAddressQuery()
                    }
                }}
                open={searchAddress.open}
                options={options}
                groupBy={(option) => { }}
                getOptionLabel={option => option.name}
                renderOption={(option) => {
                    if (option.source === "Other addresses") return <span> {option.name}</span>
                    else return <span>{option.name}</span>
                }}
                PopperComponent={PopperMy}
                onChange={handleChangeAutocomplete}
                value={value}
                renderInput={params =>
                    <TextField

                        {...params}
                        placeholder={label}
                        helperText={searchAddress.textHelper}
                        error={searchAddress.error}
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                        required={required}
                        onChange={handleChangeTextField}
                        id={id}
                        inputRef={addressRef}
                    />}
            />
        </ClickAwayListener>
    )
})

/* search personalized trip destination components */
export const PersonalizedTripDestination = connect((state) => ({
    searchDestination: state.FormBank.TimelineWizard.searchDestination,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
}), {
    fetchSearchDestinationTextField: Actions.fetchSearchDestinationTextField,
    invalidSearchDestinationQuery: Actions.invalidSearchDestinationQuery,

},
)(({
    label = "",
    required = "",
    id = "",
    fetchSearchDestinationTextField,
    setDestination,
    searchDestination,
    activeTrip,
    defaultSelected,
    invalidSearchDestinationQuery,

}) => {
    const [destinationQuery, setDestinationQuery] = React.useState("")
    const [selectedDestination, setSelectedDestination] = React.useState(defaultSelected)
    const [googleResults, setGoogleResults] = React.useState([])
    const timelineDestinationAutocompleteService = { current: null };

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if (timelineDestinationAutocompleteService.current) {
                    timelineDestinationAutocompleteService.current.getPlacePredictions(request, callback);
                }
            }, 200),
        [timelineDestinationAutocompleteService],
    );

    React.useEffect(() => {
        if (defaultSelected) {
            setDestination(prevState => {
                return { ...prevState, id: defaultSelected.destinationId }
            })
        }
    }, [])


    const updateState = (query, error) => {
        if (error) {
            invalidSearchDestinationQuery()
        } else {
            let fromTimeline = true
            fetchSearchDestinationTextField(query, fromTimeline)
            setDestinationQuery(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        //console.log(`handleChangeTextField query  =  ${query}`)
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        //console.log("handleChangeAutocomplete() value = ")
        //console.log (value)
        if (value && value.destinationId) {
            setDestination(prevState => {
                return { ...prevState, id: value.destinationId }
            })
        }

        setSelectedDestination(value)
    }

    let options = searchDestination.searchDestinationSet;
    // options = options.concat(googleResults)
    const classes = timelineAutocompleteStyle();
    // open={searchDestination.open}
    return (
        <Autocomplete
            id={id}
            classes={{
                groupLabel: classes.groupLabel,
                option: classes.option,
            }}
            clearOnBlur
            options={options}
            groupBy={(option) => option.source}
            getOptionLabel={option => option.name}
            renderOption={(option) => {
                if (option.source === "Other destinations") return <div> {option.name}</div>
                else return <span>{option.name}</span>
            }}

            onChange={handleChangeAutocomplete}
            value={selectedDestination}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={searchDestination.textHelper}
                    error={searchDestination.error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
})

export const AirportDestination = ({
    label = "",
    required = "",
    id = "",
    error = false,
    helperText = "",
    defaultSelected,
    setAirport
}) => {
    const [destinationQuery, setDestinationQuery] = React.useState("")
    const [selectedDestination, setSelectedDestination] = React.useState(defaultSelected)
    const [googleResults, setGoogleResults] = React.useState([])
    const timelineDestinationAutocompleteService = { current: null };
    const airportsList = useSelector(state => state.Flights.airportsList)

    const dispatch = useDispatch()

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if (timelineDestinationAutocompleteService.current) {
                    timelineDestinationAutocompleteService.current.getPlacePredictions(request, callback);
                }
            }, 200),
        [timelineDestinationAutocompleteService],
    );

    React.useEffect(() => {
        if (defaultSelected) {
            setAirport(defaultSelected)
        }
    }, [])


    const updateState = (query, error) => {
        if (error) {

        } else {
            dispatch(getAirportsListThunk(query))
            setDestinationQuery(query)
        }
    }

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })()
    const handleChangeTextField = event => {
        let query = event.target.value
        //console.log(`handleChangeTextField query  =  ${query}`)
        delay(function () {
            updateState(query, !validate(String(query).trim(), validate_fields.destination_query))
        }, DELAY_ZERO_SECOND);
    }
    const handleChangeAutocomplete = (event, value) => {
        //console.log("handleChangeAutocomplete() value = ")
        //console.log (value)
        if (value) {
            setAirport(value)
        }

        setSelectedDestination(value)
    }


    // options = options.concat(googleResults)
    const classes = timelineAutocompleteStyle();
    // open={searchDestination.open}
    return (
        <Autocomplete
            id={id}
            classes={{
                groupLabel: classes.groupLabel,
                option: classes.option,
            }}
            clearOnBlur
            options={airportsList}
            // groupBy={(option) => option.source}
            getOptionLabel={(option) => {
                return (option.city ? `${option.city} (${option.iata} ${option.airport ? '- ' + option.airport : ''})` :
                    `${option.iata} ${option.airport ? '- ' + option.airport : ''}`)
            }}
            renderOption={(option) => {
                return (<div key={option.id} user={option} style={{
                    font: option.type === "main" ? "16px/30px Futura Md BT" : "",
                    paddingLeft: option.type === "sub" ? 20 : 0
                }}>

                    {option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                        `${option.iata} - ${option.airport}`
                    }
                </div>)
            }}

            onChange={handleChangeAutocomplete}
            value={selectedDestination}
            renderInput={params =>
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    error={error}
                    required={required}
                    onChange={handleChangeTextField}
                    id={id}
                />}
        />
    )
}
