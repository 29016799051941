import { makeStyles } from '@material-ui/core/styles'
export const searchDestinationStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: "100%",
      border: "2px solid #E8E8E8",
      font: "14px/24px Futura Md BT",
      padding: "10px 10px 10px 10px",
      height: "80px",
      margin: "0px",
    },
    '& .MuiFormLabel-root': {
      font: "14px/24px Futura Md BT",
      color: "#B1B1B1",
      padding: "10px",
    },
    '& .MuiInputBase-input': {
      font: "18px/24px Futura Md BT",
      color: "#000000",
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#B1B1B1",
    },
    '& .MuiInput-underline:before': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },

  },
}))

export const PersonalizedModalUseStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 620,
    height:"auto",
    maxHeight: 800,
    overflow:"auto",
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "-webkit-focus-ring-color auto 0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
},
}))
export const AddCalendarModalUseStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 900,
    height:"auto",
    maxHeight: 800,
    overflow:"auto",
    backgroundColor: theme.palette.background.paper,
    border: '0px',
    boxShadow: theme.shadows[5],
    borderRadius:"20px",
    padding: theme.spacing(2, 4, 3),
    outline: "-webkit-focus-ring-color auto 0px",
  },
  [theme.breakpoints.down('md')]: {
    paper: {
      width:"100%"
    }
},
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
},
}))

export const DurationUseStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    display: 'block',
    '& .MuiInputBase-root': {
      width: "100%",
      border: "2px solid #E8E8E8",
      font: "14px/24px Futura Md BT",
      padding: "10px 10px 10px 10px",
      height: "80px",
      margin: "0px",
    },
    '& .MuiFormLabel-root': {
      font: "14px/24px Futura Md BT",
      color: "#B1B1B1",
      padding: "10px",
    },
    '& .MuiInputBase-input': {
      font: "18px/24px Futura Md BT !important",
      color: "#707070",
      height:"auto",
      marginTop:"10px"
    },
    '& .MuiSelect-icon':{
      top: '32px',
      right: '10px',
      color: "#000000",
    },
    '& .MuiSelect-select:focus':{
     background: 'transparent'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#B1B1B1",
      background: 'white'
    },
    '& .MuiInput-underline:before': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
}));


export const TripStartDateStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: "100%",
      border: "2px solid #E8E8E8",
      font: "14px/24px Futura Md BT",
      padding: "10px 10px 10px 10px",
      height: "80px",
      margin: "0px",
    },
    '& .MuiFormLabel-root': {
      font: "14px/24px Futura Md BT",
      color: "#B1B1B1",
      padding: "10px",
    },
    '& .MuiInputBase-input': {
      font: "18px/24px Futura Md BT",
      color: "#000000",
    },
    '& .MuiIconButton-root':{
      padding: '3px',
      color: "#000000",
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: "#B1B1B1"
    },
    '& .MuiInput-underline:before': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      transition: 0,
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },

  },
}))