import * as Actions from '../../../../redux/actions'

import BookingForm from './BookingForm'
import { Carousel } from 'react-bootstrap'
import {
    Image,
} from 'react-bootstrap'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing } from '../RegExValidate'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import AIBookingForm from './AIBookingForm'
import StayBookingForm from './StayBookingForm'

const StayFormSliderContainer_ = ({ activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    const handleSelect = (index, indicator) => {
        const booking_options = ["plan a trip", "hotel", "flight", "train", "bus"]
        setBookingFormActiveTab(booking_options[index])
    }
    let topImageHeight = ""
    if (!isNaN(is_mobile)) {
        topImageHeight = is_mobile ? Math.ceil(bodyClientWidth / 1.8) : 1100
    }
    return (
        <div className="position-relative" style={{ top: -app_menu_height + "px" }} >
            <div className="background-img-row m-0"  >
                <div className="carousel" >
                    <Carousel controls={false} indicators={false} interval={null} fade={true} onSelect={(index, indicator) => handleSelect(index, indicator)}>
                        {homepageSliderImage && homepageSliderImage.length > 0 && homepageSliderImage.map((item, idx) => {
                            const backImage = image_boxing(item, bodyClientWidth, topImageHeight);
                            return (
                                <Carousel.Item key={idx} >
                                    <div   >
                                        <img className="d-block w-100"
                                            src={backImage}
                                            alt="top carousel"
                                        />

                                        <div className="overlay"></div>
                                    </div>
                                </Carousel.Item>
                            )
                        }
                        )}
                    </Carousel>

                    <Carousel.Caption style={{ zIndex: "1" }}>
                        <div style={{ textAlign: "left", marginTop: is_mobile ? "40px" : "77px", marginBottom: "40px", marginLeft: is_mobile ? "0px" : "14px", marginRight: is_mobile ? "0px" : "14px" }}>
                            {/* <div className="hidden_1366" style={{ font: "36px/40px Futura Hv BT", textAlign: "left", marginBottom: "15px" }} >{homepageGeneralInfo.generalInfo && homepageGeneralInfo.generalInfo.inspirationalText}</div> */}
                           
                           <div style={{ marginLeft: is_mobile?15:0}}>
                            <div className="" style={{ width: is_mobile ? "100%" : "60%", font: is_mobile ? "18px/30px Futura Md BT" : "30px/50px Futura Md BT", textAlign: "left", marginBottom: "15px", letterSpacing: "0.5px", color: "var(--mainWhite)", }}>
                                Where to?
                            </div>

                            <div style={{ width: is_mobile ? "90%" : "100%", font: is_mobile ? "16px/22px Futura Md BT" : "35px/50px Futura Hv BT", textAlign: "left", marginBottom: "15px", letterSpacing: "0.5px", color: "var(--mainWhite)", }}>
                                Pick a place and we'll find the perfect stay for you at a great price!
                            </div>

                            <div style={{ padding: is_mobile ? "0px" : 0, marginBottom:is_mobile?0:30, width: is_mobile ? "90%" : "80%", font:is_mobile? "12px/20px Futura Lt BT":"18px/20px Futura Lt BT", textAlign:  "left", letterSpacing: "0.5px", color: "var(--mainWhite)", lineHeight: 1.5 }}>
                            BONUS: Let our powerful Travel AI engine make a plan for you.
                            <br/>
                            Get a free AI-personalized itineray tailor-made to your tastes & interests with every booking.

                            </div> 
                            </div> 

                            <StayBookingForm />


                        </div>
                    </Carousel.Caption>
                </div>
            </div>

        </div >
    )

}

const mapStateToProps = (state) => ({

    logo_width: state.Setting.appMenu.logo.width,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    homepageSliderImage: state.Home.homepage_slider_image,
    homepageGeneralInfo: state.Home.homepage_general_info,
    is_mobile: state.Setting.is_mobile,
    activeTab: state.FormBank.BookingForm.activeTab,
})

const mapDispatchToProps = {

    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const StayFormSliderContainer = connect(mapStateToProps, mapDispatchToProps)(StayFormSliderContainer_)
export default StayFormSliderContainer
