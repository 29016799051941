import Cookies from "js-cookie";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./redux/actions";
import * as Actions from './redux/actions'

const PrivateRoute_ = (props) => {
    const [authenticated, setAuthenticated] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        const isLoggedIn = async() =>{
            const loggedIn = await isAuthenticated() 
             setAuthenticated(loggedIn)

             if(!loggedIn){
                openLogin()
             }
             setLoaded(true)
        }
        isLoggedIn()
    }, [])
    

    const openLogin = ()=>{
		props.setOpenAuthModal(true)
	}
   
    
    if(!loaded){
        document.body.style.backgroundColor =  "var(--bgBlack)"
        return null;
    }
    
    

    // let token_cookies = Cookies.get('muv_token')
    // return authenticated ? (
    //         <Route path={props.path} exact={props.exact} component={props.component} />
    //         ) :
    //     (<Redirect to="/home" />);
        return (
            <Route path={props.path} exact={props.exact} component={props.component} />
            )
};

const mapStateToProps = (state) => {
    return {
		uuid: state.Member.authModal.uuid,
    }
}

const mapDispatchToProps = {
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRoute_)
export default PrivateRoute;