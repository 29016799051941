import "./AIHomePage.css"

import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { member_area_router, validate, validate_fields } from "../Common/RegExValidate"
import Banner from '../Common/Banner.jsx'
import Header from '../Header/Header.jsx'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "../SocialPage/MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import Profile from '../Common/Profile/ProfileBar.jsx'
import { PropTypes } from "prop-types"
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import AIHomeContainer from "./AIHomeContainer"

class AIHomePage_ extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		const lang = this.props.lang
		this.props.getHomePageSliderImage(lang)

		window.addEventListener("resize", this.updateDimensions)
		window.history.pushState(null, document.title, window.location.href);
		window.addEventListener('popstate', this.popClicked)
		this.updateDimensions()
		const { preload,suggestedDestinations,homeImages } = this.props;

		if(preload || suggestedDestinations === undefined){
			this.props.getHomePageData(lang) ///this causes refresh
		}

		setTimeout(() => {
			this.setState({loading: false})
		}, 1000);

		this.props.getHomeBucketList(lang)
		this.props.getHomePageMuverStyle(lang)
		this.props.getHomePageMuverPress(lang)
		this.props.getHomePageGeneralInfo(lang)
		this.props.setSwitchContainer(member_area_router.home)

	
	}

	/* update screen size */
	updateDimensions = () => {
		this.props.setScreenSize(window.screen.width, window.screen.height)
		this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
		this.props.setAppMenu(document.body.clientWidth)
	}

	/* handler of backward or forward buttons */
	popClicked = (event) => {
		const switch_container = this.props.switch_container
		const tripMode = this.props.tripMode
		//console.log(`HomePage popclicked switch_container : ${switch_container}`)
		//console.log(`HomePage popclicked tripMode: ${tripMode}`)	
		if (switch_container === member_area_router.memberAreaTrip && tripMode === list_container_mode.schedule ) {
			//console.log("1st onpop listened")
			this.props.clearSelectedTripInformation()
			this.props.setSwitchContainer(member_area_router.trips)
			this.props.setTripDisplayMode(list_container_mode.schedule)
			window.history.pushState(null, document.title, window.location.href);
		} else if (switch_container === member_area_router.home && tripMode === "") {
			//console.log("2nd onpop listened")
			this.props.clearSelectedTripInformation()
			window.history.pushState(null, document.title, window.location.href);

		}
	}

	/* destory component */
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions)
	}
	render() {
		const lang = this.props.lang
		const { preload, switch_container, tripMode } = this.props
		const {loading} = this.state;
	    //console.log(`HomePage switch_container : ${switch_container}`)
		//console.log(`HomePage tripMode: ${tripMode}`)
		return (
			validate(lang, validate_fields.languages) ?
				<div style={{
					height: "auto",
				}}>
					{  loading && <Loader
						type="Rings"
						color="#19BC9B"
						className="se-pre-con"
						height={150}
						width={150}
						style={{
							backgroundColor: "#FFFFFF"
						}} />}
						
						{<React.Fragment>
							{switch_container === member_area_router.home &&
						<React.Fragment>
							<AIHomeContainer lang={lang} />
						</React.Fragment>
							}

					{switch_container === member_area_router.memberAreaTrip &&
						<React.Fragment>
							{tripMode === list_container_mode.map ?
								<React.Fragment>
									<Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
									<TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
									{window.google !== undefined && <ItineraryMap />}
								</React.Fragment> :
								(tripMode === list_container_mode.timeline ?
									<React.Fragment>
										<Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
										<TimelineWizardContainer listDisplayFormat={list_container_mode.timeline} />
										<PrivateTripEstablishment />
									</React.Fragment> :
									<TripComtainer />)}
						</React.Fragment>}
					{(switch_container === member_area_router.trips
						|| switch_container === member_area_router.bucketList
						|| switch_container === member_area_router.favorites
						|| switch_container === member_area_router.personalInfo
						|| switch_container === member_area_router.muvTribe)
						&&
						<React.Fragment>
							<Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
							<Banner />
							<Profile />
							<MemberAreaContainer />
						</React.Fragment>}

					<MemberActionsInitial />
							</React.Fragment>}
						
					
				</div >
				:
				<Redirect to={"error"} component={PageNotFound} />
		)
	}
}

AIHomePage_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
	error: PropTypes.string.isRequired,
	uuid: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
	return {
		error: state.Social.error,
		uuid: state.Social.member_uuid,
		lang: state.Setting.lang,
		preload: state.Setting.preload,
		suggestedDestinations: state.Home.homepage.suggestedDestinations,
		switch_container: state.Member.switch_container,
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		tripMode: state.Member.tripMode,
		activeTrip: state.FormBank.TimelineWizard.activeTrip,
		homeImages: state.Home.homepage_slider_image
		
	}

}

const mapDispatchToProps = {
	getHomePageData: Actions.getHomePage,
	getHomeBucketList: Actions.getHomeBucketList,
	getHomePageMuverStyle: Actions.getHomePageMuverStyle,
	getHomePageMuverPress: Actions.getHomePageMuverPress,
	getHomePageGeneralInfo: Actions.getHomePageGeneralInfo,
	getHomePageSliderImage: Actions.getHomePageSliderImage,
	setSwitchContainer: Actions.setSwitchContainer,
	setScreenSize: Actions.setScreenSize,
	setBodyClientSize: Actions.setBodyClientSize,
	setAppMenu: Actions.setAppMenu,
	setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
	clearSelectedTripInformation: Actions.clearSelectedTripInformation,
	setSwitchContainer: Actions.setSwitchContainer,
	setTripDisplayMode: Actions.setTripDisplayMode,
}
const AIHomePage = connect(mapStateToProps, mapDispatchToProps)(AIHomePage_)

export default AIHomePage