import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { member_area_router, validate, validate_fields } from "../Common/RegExValidate"

import Banner from '../Common/Banner.jsx'
import EstablishmentContainer from './EstablishmentContainer'
import Header from '../Header/Header'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "../SocialPage/MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound'
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import Profile from '../Common/Profile/ProfileBar.jsx'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { HeaderMobile } from '../Header/HeaderMobile'

class EstablishmentPage_ extends Component {
    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.updateDimensions()

        this.props.setPageYOffset(0)
        this.props.setSwitchContainer(member_area_router.hotelSearch)
        this.props.setAppMenuBookingButtonStatus(true)
        this.props.setTripDisplayMode(list_container_mode.timeline)
        this.props.fetchActiveTripFromCookies("", this.props.lang, false)
    }
    /* destroy component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }
    render() {
        const lang = this.props.lang
        const { preload, is_mobile, switch_container, tripMode, destinaionListDisplayFormat } = this.props

        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>
                    {preload ? <Loader
                        type="Rings"
                        color="#19BC9B"
                        className="se-pre-con"
                        height={150}
                        width={150}
                        style={{
                            backgroundColor: "#FFFFFF"
                        }} /> : ""}

                    {switch_container === member_area_router.hotelSearch &&
                        <React.Fragment>
                            {tripMode === list_container_mode.schedule && <TripComtainer />}
                            {tripMode === list_container_mode.calendar && <TripComtainer />}

                            {tripMode === list_container_mode.map && <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />}
                            {tripMode === list_container_mode.map && <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />}
                            {tripMode === list_container_mode.map && window.google !== undefined && <ItineraryMap />}

                            <>

                                {/* {!is_mobile ? <Header hideStars bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} /> : <HeaderMobile bg_color={"#191919"} text_color={"var(--mainWhite)"} />} */}
                                {!is_mobile && <Header hideStars bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} /> }
                            </>
                            {tripMode === list_container_mode.timeline && <TimelineWizardContainer listDisplayFormat={member_area_router.booking} />}
                            {tripMode === list_container_mode.timeline && <EstablishmentContainer props={this.props} />}

                        </React.Fragment>
                    }

                    <MemberActionsInitial />
                </div>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,
        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        is_mobile: state.Setting.is_mobile,

    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setPageYOffset: Actions.setPageYOffset,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
}

const EstablishmentPage = connect(mapStateToProps, mapDispatchToProps)(EstablishmentPage_)
export default EstablishmentPage
