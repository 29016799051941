import * as Actions from '../../../../redux/actions'

import PlanTripFormDestinaiton from "./PlanTripFormDestinaitonElement"
import React, { useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { list_container_mode, member_area_router } from "../RegExValidate"
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory, useLocation } from "react-router-dom"
import { updateRoadTrip } from '../Trip/ActiveTrip.slice'
import { TrackEvent } from '../../../../utility/utility'
import { AiPersonalizedTripComponent } from './AiPersonalizedTrip/AiPersonalizedTrip'
import { toggleAiModal } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice'
import GenerateTripFormDestinaitonElement from './GenerateTripFormDestinaitonElement'
import { environment } from '../../../../Environments/environment'
import { GetAsyncHelper } from '../../../../redux/interfaces/types'
import { clearTimelineTrip } from '../../../../redux/actions'




export const GenerateTripForm_ = ({ isHeader, authModal, handleRemoveDestinations, handleSetDestinations, newTrip, setTripDisplayMode, setPageYOffset, lang, uuid, is_mobile, setSwitchContainer, setBookingFormStatus, activeTrip, createMultipleDestinationTrip, switch_container }) => {

    const history = useHistory()
    const prevTripIdRef = React.useRef()
    const dispatch = useDispatch();
    const search = useLocation().search;
    const query = new URLSearchParams(search);

    var searchParams = new URLSearchParams(document.location.search.substring(1));

    const showModal = useSelector(state => state.PersonalizedTrip.aiTrip.modalOpen)
    const isAiPage = history?.location?.pathname?.includes("ai")
    const handleMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }





    const [travellers, setTravellers] = React.useState("2")
    const [error, setError] = React.useState(false)
    const [planMyOwn, setMyOwn] = React.useState(false)
    const [generating, setGenerating] = React.useState(false)


    React.useEffect(() => {
        if (newTrip.trip && newTrip.trip.tripID && planMyOwn) {
            if (destinations && destinations[0].destinationId) {
                const url = `/destination/plan-trip/` + 1 + "/" + destinations[0].destinationId;
                history.push(url.toLowerCase())

            }
            setGenerating(false)
            setMyOwn(false)

        }
    }, [newTrip, planMyOwn])


    const [destinations, setDestinations] = React.useState([{
        destinationId: "",
        startDateFormat: "",
        duration: "3",
        startDate: "",
        availableSelectDate: "",
        positionId: "d-0",
    }])
    const [leavingFrom, setLeavingFrom] = React.useState({
        destinationId: "",
        destination: null,
    })

    const [isRoadTrip, setIsRoadTrip] = React.useState(false)
    const [startingPoint, setStartingPoint] = React.useState()

    const [flipped, setFlipped] = React.useState(false)


    const getMuverProfile = async (uuid) => {
        const url = environment.apiUrl + "muver/getmuverprofileinfo/" + uuid + "/en";

        const response = await GetAsyncHelper(url)
        if (response.statusText === "OK") {
            const result = response.data.result;

            if (result?.homeDestination) {
                const preselect = {
                    destinationId: result?.homeDestination?.destinationId,
                    id: result?.homeDestination?.destinationId,
                    startDateFormat: "",
                    duration: "3",
                    startDate: "",
                    availableSelectDate: "",
                    positionId: "d-0",
                    cityIdPpn: result?.homeDestination?.cityIdPpn,
                    iata: result?.homeDestination?.iata,
                    destinationName: result?.homeDestination?.destinationName,
                    travellers: travellers,
                    name: result?.homeDestination?.destinationName,
                    countryName: result?.homeDestination?.country,
                    stateName: result?.homeDestination?.state
                }
                setLeavingFrom(preselect)
            }

        }

    }
    React.useEffect(() => {
        if (uuid) {
            getMuverProfile(uuid)
        }
    }, [uuid])


    React.useEffect(() => {
        setDefaultURLData()
    }, []) ///first load set destination info

    const formatUrlDate = (date) => {
        return date ? date.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3") : "";
    }

    const setDefaultURLData = () => {
        try {
            const numTravellers = searchParams.get("travellers")
            const isRoadtrip = searchParams.get("isRoadtrip");
            const destinations = searchParams.get("destinations");
            const leavingFrom = searchParams.get("leavingFrom");

            if (destinations) {
                setIsRoadTrip(isRoadtrip === "1");
                setTravellers(numTravellers)


                if (leavingFrom) {
                    const destInfo = leavingFrom?.split(" ")
                    const destinationData = destInfo[0]?.split("_");
                    const destName = destinationData[0] ?? "";
                    const stateName = destinationData[1] ?? "";
                    const countryName = destinationData[2] ?? "";
                    const destinationId = destInfo[1];

                    const preselect = {
                        destinationId: destinationId ?? "",
                        id: destinationId ?? "",
                        startDateFormat: "",
                        duration: "3",
                        startDate: "",
                        availableSelectDate: "",
                        positionId: "d-0",
                        cityIdPpn: "",
                        iata: "",
                        destinationName: destName,
                        travellers: travellers,
                        name: destName,
                        countryName: countryName,
                        stateName: stateName
                    }
                    setLeavingFrom(preselect)
                }
                let destArray = destinations.split(',');

                if (destArray?.length > 0) {
                    destArray = destArray?.map((destination, index) => {

                        if (destination) {
                            const destInfo = destination?.split(" ")
                            const destinationData = destInfo[0]?.split("_");
                            const destName = destinationData[0] ?? "";
                            const stateName = destinationData[1] ?? "";
                            const countryName = destinationData[2] ?? "";
                            const destinationId = destInfo[1];
                            const startDate = destInfo[2];
                            const duration = destInfo[3];
                            const formatDeparture = startDate ? formatUrlDate(startDate) : "";

                            const formattedFlight = {
                                countryName: countryName,
                                destinationId: destinationId,
                                id: destinationId,
                                name: destName,
                                destination: {
                                    countryName: countryName,
                                    name: destName,
                                    stateName: stateName,
                                    destinationId: destinationId,
                                    nameInline: `${destName ? destName + ", " : ""}${stateName ? stateName + ", " : ""}${countryName ? countryName : ""}`
                                },
                                stateName: stateName,
                                duration: duration,
                                destinationName: destName,
                                startDateFormat: formatDeparture && moment(formatDeparture).format('YYYY-MM-DD 00:00') === "Invalid date" ? "" : moment(formatDeparture).format('YYYY-MM-DD 00:00'),
                                startDate: formatDeparture && moment(formatDeparture) === "Invalid date" ? "" : new Date(moment(formatDeparture)),
                                availableSelectDate: new Date().toDateString(),
                                positionId: "d-" + index
                            }
                            return formattedFlight;
                        }
                        return null;

                    })




                    setDestinations(destArray)

                }

            }
        }
        catch {

        }



    }




    const handleCheckChange = () => {
        setIsRoadTrip(!isRoadTrip)
        dispatch(updateRoadTrip(!isRoadTrip))
        // if (!isRoadTrip) {
        //     handleAddaDestination(true)
        // }
    }



    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' }));
        return <div ref={elementRef} />;
    };




    const handleAddaDestination = (roadTrip) => {
        const pid_set = ["d-0", "d-1", "d-2", "d-3", "d-4", "d-5", "d-6", "d-7", "d-8", "d-9", "d-10"]
        const used_pid = Array.from(destinations.map(d => d.positionId))
        const intersetions = pid_set.filter(pid => used_pid.indexOf(pid) === -1)

        handleSetDestinations();
        setDestinations(prevState =>
            [...prevState,
            {
                destinationId: "",
                startDateFormat: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") : "",

                duration: roadTrip || isRoadTrip ? '1' : '3',
                startDate: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? new Date(moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") + " 00:00") : "",
                availableSelectDate: prevState !== undefined
                    && prevState[prevState.length - 1] !== undefined
                    && prevState[prevState.length - 1].startDateFormat !== ""
                    ? new Date(moment(prevState[prevState.length - 1].startDateFormat)
                        .add(Number(prevState[prevState.length - 1].duration) - 1, "days").format("YYYY-MM-DD") + " 00:00") : "",
                positionId: intersetions[0] !== undefined ? intersetions[0] : "",
            }
            ]
        )
    }

    const changeLeavingFrom = (destination_id, date, duration, pid, available_select_date, destination) => {
        let start_date = date !== null ? date : ""
        let start_date_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')
        let destination_duration = duration > 0 ? duration : "0"
        let selected_destination = {
            destinationId: destination_id,
            id: destination_id,
            startDateFormat: start_date_format,
            duration: destination_duration,
            startDate: start_date,
            availableSelectDate: available_select_date,
            positionId: pid,
            destinationName: destination?.name,
            travellers: travellers,
            name: destination?.name,
            countryName: destination?.countryName,
            stateName: destination?.stateName,
            destination: destination
        }
        setLeavingFrom(selected_destination)
    }

    const flipDirection = () => {
        const destinationsCopy = [...destinations];
        setLeavingFrom(destinationsCopy[0])
        destinationsCopy[0] = leavingFrom;
        setDestinations(destinationsCopy);
        setFlipped(!flipped)

    }


    const setDestinationsData = (destination_id, date, duration, pid, available_select_date, destination) => {
        let start_date = date !== null ? date : ""
        let start_date_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')
        let destination_duration = duration > 0 ? duration : "0"
        let sort_destinations_id = []
        let sort_destinations_is_push = false
        destinations.map(d => {
            if (d.positionId === pid) { sort_destinations_is_push = true }
            return (
                sort_destinations_is_push ? sort_destinations_id.push(d.positionId) : ""
            )
        })

        let selected_destination = {
            destinationId: destination_id,
            id: destination_id,
            startDateFormat: start_date_format,
            duration: destination_duration,
            startDate: start_date,
            availableSelectDate: available_select_date,
            positionId: pid,
            destinationName: destination?.name,
            travellers: travellers,
            name: destination?.name,
            countryName: destination?.countryName,
            stateName: destination?.stateName,
            destination: destination
        }

        setDestinations(prevState =>
            [...prevState.map((d) => {
                if (d.positionId === pid) {
                    return selected_destination
                } else if (d.positionId !== pid && sort_destinations_id.find(sd => sd === d.positionId) !== undefined) {
                    start_date = start_date_format === "" ? "" : new Date(moment(start_date_format)
                        .add(Number(destination_duration - 1), "days").format("YYYY-MM-DD 00:00"))
                    start_date_format = start_date_format === "" ? "" : moment(start_date_format)
                        .add(Number(destination_duration - 1), "days").format("YYYY-MM-DD")
                    destination_duration = d.duration
                    return {
                        destinationId: d.destinationId,
                        id: d.destinationId,
                        startDateFormat: start_date_format,
                        duration: d.duration,
                        startDate: start_date,
                        availableSelectDate: start_date,
                        positionId: d.positionId,
                        destinationName: d?.destinationName,
                        travellers: d?.travellers,
                        name: d?.name,
                        countryName: d?.countryName,
                        stateName: d?.stateName,
                        destination: d?.destination
                    }
                } else {
                    return d
                }
            })]
        )
    }

    const removeAddedDestination = (destination) => {
        handleRemoveDestinations()
        let isFirstDestination = false
        if (destinations !== undefined && destinations.length > 0 && destinations[0] !== undefined
            && destinations[0].positionId === destination.positionId && destinations[1] !== undefined) {
            isFirstDestination = true
        }
        const subtract_duration = destination.duration > 0 ? destination.duration : "0"
        let sort_destinations_id = []
        let sort_destinations_is_push = false
        destinations.map(d => {
            if (d.positionId === destination.positionId) { sort_destinations_is_push = true }
            return (
                sort_destinations_is_push ? sort_destinations_id.push(d.positionId) : ""
            )
        })

        setDestinations(destinations.filter(d => d.positionId !== destination.positionId))
        setDestinations(prevState =>
            [...prevState.map((d) => {
                if (d.positionId === destination.positionId) {
                    return d
                } else if (d.positionId !== destination.positionId && sort_destinations_id.find(sd => sd === d.positionId) !== undefined) {
                    const start_date = d.startDateFormat === "" ? "" : new Date(moment(d.startDateFormat)
                        .subtract(Number(subtract_duration - 1), 'days')
                        .format("YYYY-MM-DD 00:00"))
                    const start_date_format = d.startDate === "" ? "" : moment(d.startDate)
                        .subtract(Number(subtract_duration - 1), 'days')
                        .format("YYYY-MM-DD")
                    return {
                        destinationId: d.destinationId,
                        startDateFormat: start_date_format,
                        duration: d.duration,
                        startDate: start_date,
                        availableSelectDate: isFirstDestination ? "" : start_date,
                        positionId: d.positionId,
                    }
                } else {
                    return d
                }
            })]
        )
    }
    const handlePlanTripForm = () => {
        setError(true)
        setGenerating(true)

        let destinationsCopy = [...destinations];

        destinationsCopy.unshift(leavingFrom);



        const destinations_selected = [...destinationsCopy.filter(d => d.destinationId !== "").map(d => {
            return {
                destinationId: d.destinationId,
                startDateFormat: d.startDateFormat,
                duration: d.duration,
                startDate: d.startDate,
            }
        })]

        if (destinations_selected.length > 1) {
            dispatch(clearTimelineTrip()) ///remove any active trip

            setMyOwn(true)
            // if (destinations_selected.length === 1) {
            //     const url = `/destination/plan-trip/` + 1 + "/" + destinations_selected[0].destinationId;
            //     history.push(url.toLowerCase())
            // }
            // else {

            const dataToCreateTrip = {
                destinations: destinations_selected,
                isRoadTrip: isRoadTrip,
            }



            // if (isRoadTrip) {  ///not include starting point 
            //     if (destinations_selected.length > 1) {
            //         setError(false)
            //         destinations_selected.shift();
            //         createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId, startingPoint)
            //     }
            // } else {

            // }



            setError(false)
            createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId, startingPoint)
            TrackEvent('SEARCH', "Plan a trip", JSON.stringify(dataToCreateTrip))

            // }


        }
        else {
            setGenerating(false)
        }

    }


    const aiGenerate = () => {
        setError(true)
        if (destinations && destinations.length > 0) {
            let destinationsListCopy = [...destinations];

            if (leavingFrom) {
                destinationsListCopy.unshift(leavingFrom);
            }



            let destinationsCopy = [...destinations.map(d => d?.destinationName)]
            if (destinationsCopy.length > 1) {
                setError(false)
            }

            const destinationsList = destinationsCopy && destinationsCopy?.length > 1 ? destinationsCopy.join(', ').replace(/,(?!.*,)/gmi, ' &') : destinationsCopy[0] || '';
            const startDate = destinations[0]?.startDateFormat;
            const totalDuration = destinations.reduce(function (tot, obj) {
                const val = obj.duration ? Number(obj.duration) : 0;
                return tot + val;
            }, 0);


            const payload = {
                destinations: destinationsListCopy,
                destinationText: destinationsList,
                startDate: startDate,
                duration: totalDuration,
                travellers: travellers,
                startDateFormat: startDate ? moment(startDate).format('MMMM Do, yyyy') : "",
                leavingFrom: leavingFrom,
                isRoadTrip: isRoadTrip
            }
            dispatch(toggleAiModal(payload))
        }

    }

    const allowGenerate = destinations && destinations?.length > 0 && destinations[0]?.destinationId;

    return (
        <React.Fragment>
            <div className="row m-0 p-0" style={{ marginTop: "30px" }}>

                {/* {switch_container === "Starred" &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isRoadTrip}
                                onChange={handleCheckChange}
                                name="road_trip_checkbox_popup"
                                color="default"
                            />
                        }
                        label="Road Trip"
                        style={{ marginLeft: "auto", marginTop: "-30px", marginRight: 0, marginBottom: "-10px" }}
                    />} */}
                {
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isRoadTrip}
                                onChange={handleCheckChange}
                                name="road_trip_checkbox_homepage"
                                style={{
                                    color:
                                        "var(--mainGreen)"
                                }}
                            />
                        }
                        label="Road Trip"
                        style={{ marginLeft: "auto", marginTop: "-25px", marginRight: 10, marginBottom: "-10px", color: isHeader ? "var(--mainGrey)" : "#fff" }}
                    />}

                <div style={{ width: '105%', marginTop: 18, marginRight: -10, maxHeight: destinations.length > 4 ? 235 : 'none', overflowY: destinations.length > 4 ? 'scroll' : 'revert', paddingLeft: 30, marginLeft: -30 }}>
                    {destinations.length > 0 && destinations.map((destination, index) => {
                        return (
                            <div key={destination.positionId} >


                                <GenerateTripFormDestinaitonElement index={index} flipDirection={flipDirection}
                                    leavingFrom={leavingFrom} flipped={flipped}
                                    changeLeavingFrom={changeLeavingFrom} setStartingPoint={setStartingPoint} destination={destination} setDestinationsData={setDestinationsData} error={error} lengthDestiantions={destinations.length} removeAddedDestination={removeAddedDestination} isRoadTrip={isRoadTrip} />

                            </div>
                        )
                    })}
                    <AlwaysScrollToBottom />
                </div>
                <div className={isAiPage ? "col-12 col-md-10 p-0 mt-3" : "col-12 col-md-8 p-0 mt-3"} style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                    <button className="btn btn-default col-12 addDestinationBtn p-0" style={{ color: "#19bc9b" }} onClick={() => handleAddaDestination(false)} disabled={false}>
                        + Add a Destination
                    </button>
                </div>
                {
                    <div className="d-md-none d-block col-md-2 col-12 p-0 mt-3">
                        <select className="form-control pl-3" placeholder="Travelers" defaultValue={"2"} style={{ cursor: "pointer" }}
                            onChange={e => setTravellers(e.target.value)}>
                            <option value={"1"}>1 traveler</option>
                            <option value={"2"}>2 travelers</option>
                            <option value={"3"}>3 travelers</option>
                            <option value={"4"}>4 travelers</option>
                            <option value={"5"}>5 travelers</option>
                            <option value={"6"}>6 travelers</option>
                            <option value={"7"}>7 travelers</option>
                            <option value={"8"}>8 travelers</option>
                            <option value={"9"}>9 travelers</option>
                        </select>
                    </div>}
                <div className="col-12 col-md-2 p-0">
                    <button type="submit" disabled={!allowGenerate} className="btn btn-default submitBtn col-12 mt-3 p-0" onClick={aiGenerate}
                        style={{ background: '#E52256' }}>
                        {/* { destinations?.length > 1? "Plan a trip": } */}
                        {isAiPage ? "Generate Now" :"AI Personalized"}
                        {!isAiPage &&<div style={{ font: "13px/10px Futura Lt BT", color: "var(--mainWhite)", lineHeight: 1.5, marginTop: -5 }}>
                            itinerary
                        </div>}
                    </button>
                </div>
                {!isAiPage && <div className="col-12 col-md-2 p-0">
                    <button type="submit" disabled={!allowGenerate || generating} className="btn btn-default submitBtn col-12 mt-3 p-0" onClick={() => handlePlanTripForm()}>
                        {/* { destinations?.length > 1? "Plan a trip": } */}
                        {generating ? "Generating..." : "Plan My Own"}
                        <div style={{ font: "13px/10px Futura Lt BT", color: "var(--mainWhite)", lineHeight: 1.5, marginTop: -5 }}>
                            itinerary
                        </div>
                    </button>
                </div>}
            </div>
            {<div className="d-md-block d-none  row m-0 p-0">
                <div className="col-md-2 p-0 mt-3">
                    <select className="form-control pl-3" placeholder="Travelers" value={travellers} style={{ cursor: "pointer" }}
                        onChange={e => setTravellers(e.target.value)}>
                        <option value={"1"}>1 traveler</option>
                        <option value={"2"}>2 travelers</option>
                        <option value={"3"}>3 travelers</option>
                        <option value={"4"}>4 travelers</option>
                        <option value={"5"}>5 travelers</option>
                        <option value={"6"}>6 travelers</option>
                        <option value={"7"}>7 travelers</option>
                        <option value={"8"}>8 travelers</option>
                        <option value={"9"}>9 travelers</option>
                    </select>
                </div>
            </div>}

            {showModal && <AiPersonalizedTripComponent />}
        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,
        authModal: state.Member.authModal,
        newTrip: state.ActiveTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        switch_container: state.Member.switch_container,
        is_mobile: state.Setting.is_mobile,
    }
}

const mapDispatchToProps = {
    createMultipleDestinationTrip: Actions.createMultipleDestinationTrip,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,
    setSwitchContainer: Actions.setSwitchContainer,

}

const GenerateTripForm = connect(mapStateToProps, mapDispatchToProps)(GenerateTripForm_)
export default GenerateTripForm
