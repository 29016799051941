import * as Actions from '../../../redux/actions'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import "./ReviewContainer.css"
import ReviewsUser from "./ReviewsUser"
import HotelEstablishmentReviews from '../Common/Establishment/HotelEstablishmentReviews'
import MuvHotelEstablishmentReviews from '../Common/Establishment/MuvHotelEstablishmentReviews'

const ReviewContainer_ = ({ props, sliderImages, setEstablishmentId, is_mobile, establishment, logo_height, app_menu_height, lang, fetchHotelEstablishment, fetchPhotoGalleryEstablishment, fetchExperiencesEstablishment, fetchNeighborhoodEstablishment, fetchGoogleAndYelpReviewsEstablishment }) => {

    const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const muvReviews = establishment?.generalInfo?.room_data?.review_data
    const reviewData = hotelReviews?.results || muvReviews;
    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90
    const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
    const neighborhoodExisted = establishment && establishment.neighborhood


    return (
        <React.Fragment>
            <div className="reviewContainer">
                {/* <div className="title">
                      REVIEWS
                </div> */}
                <div style={{ font: "24px/36px Futura Hv BT", color: "#191919", marginBottom: "0px" }}>
                    Reviews
                </div>

                {!reviewData && <div style={{ font: "18px/18px Futura Lt BT", color: "#191919",
            marginTop:20 }}>
                    No Reviews found
            </div>}
                <div className="reviews">
                    <div className="user">
                        <ReviewsUser />
                    </div>
                    <div className="cloud">
                        <MuvHotelEstablishmentReviews />
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.Destination.establishment.sliderImages,
        lang: state.Setting.lang,

        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        establishment: state.FormBank.HotelSearch.establishment,
    }
}

const mapDispatchToProps = {
    fetchHotelEstablishment: Actions.fetchHotelEstablishment,
    fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
    fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
    fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,
    //fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,


    setEstablishmentId: Actions.setEstablishmentId,
}

const ReviewContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewContainer_)
export default ReviewContainer
