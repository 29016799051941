import * as Actions from '../../../redux/actions'

import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { TimesIcon } from "../Common/SVGBank"
import { connect } from 'react-redux'
import { features_router, member_area_router, URL_REPLACE } from "../Common/RegExValidate"
import { generalSearchStyles } from "../Common/ComponentsStylingBank"
import { useHistory } from 'react-router-dom';
import { encryptID } from '../HotelPage/hotelServices'

export const SearchBarAutocomplete = ({ setSwitchContainer, featureSearchList, handleSelectedAutocompleteFeature, activeFeatureId, is_mobile, anchorRef, open, handleClose }) => {
    const classes = generalSearchStyles()
    const history = useHistory();

    const handleClick = (item) => {
        if (activeFeatureId === features_router.places.featureId) {
            setSwitchContainer(member_area_router.establishment)
            const country = item.countryName !== "" ? "-" + item.countryName.replace(URL_REPLACE, "-") : ""
            const url = "/establishment/" + item.name.replace(URL_REPLACE, "-") + country + "/" + item.id;
            history.push(url.toLowerCase())
        }
        if (activeFeatureId === features_router.trips.featureId) {
            setSwitchContainer(member_area_router.publicTrip)
              const encryptedId = encryptID(item.id)
            const url = "/trip/" + item.name.replace(URL_REPLACE, "-") + "/" + encryptID;
            history.push(url.toLowerCase())
        }
        if (activeFeatureId === features_router.bucket_list.featureId) {
            setSwitchContainer(member_area_router.bucketList)
            const url = "/bucketlist/" + item.name.replace(URL_REPLACE, "-") + "/" + item.id;
            history.push(url.toLowerCase())
        }

        handleSelectedAutocompleteFeature(item.name)
    }
  
    
    const getTypeResult = ()=>{
        const typeResult = featureSearchList.reduce(function (previousValue , currentValue){
            previousValue[currentValue?.type[0]?.typeName || "Any"] = previousValue[currentValue.type[0]?.typeName] || [];
            previousValue[currentValue?.type[0]?.typeName || "Any"].push(currentValue);
            return previousValue;
        }, Object.create(null));

        Object.keys(typeResult).sort().forEach(function(key) {
            var value = typeResult[key];
            delete typeResult[key];
            typeResult[key] = value;
        });
        return typeResult
    }
     

    return (
        <div className={classes.root} style={{ textAlign: "left" }}>
            <Popper anchorEl={anchorRef.current} open={open} role={undefined} className={classes.dropdown}
                placement={"bottom"}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                }} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                    >
                        <Paper>
                            <MenuList autoFocusItem={open} id="menu-list-sort-trips" >
                               
                                {activeFeatureId === features_router.places.featureId && featureSearchList !== undefined && featureSearchList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}>{features_router.places.text}</div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        <Divider light style={{ margin: "0px" }} />
                                        {Object.keys(getTypeResult()).map((element, element_idx) => {
                                            
                                            return (
                                                <div key={element_idx}>
                                                    <div style={{ display: "flex", outline: "0" }}>
                                                        <div style={{
                                                            flexGrow: "1",
                                                            paddingTop: "10px",
                                                            paddingBottom: "6px",
                                                            paddingLeft: "6px",
                                                            outline: "0px",
                                                            font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                                        }}>{element}</div>
                                                        <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}></div>
                                                    </div>
                                                    
                                                    <div key={element_idx} >
                                                    {!is_mobile && (getTypeResult()[element]).map(function(objt, index) {
                                                            return <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                                cursor: "pointer",
                                                                minHeight: is_mobile ? "0px" : "",
                                                                font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                            }} onClick={() => handleClick(objt)} key={index}>{objt.name}</MenuItem>
                                                        })}
                                                    
                                                    {is_mobile && (getTypeResult()[element]).map(function(objt, index) {
                                                            return <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                                cursor: "pointer",
                                                                minHeight: is_mobile ? "0px" : "",
                                                                font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                            }} onClick={() => handleClick(objt)} key={index}>{objt.name}</MenuItem>
                                                        })}
                                                </div> 
                                                </div>
                                            )
                                        })
                                            
                                        }                                         

                                    </div>}
                                {activeFeatureId === features_router.trips.featureId && featureSearchList !== undefined && featureSearchList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}>{features_router.trips.text}</div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        <Divider light style={{ margin: "0px" }} />
                                        {featureSearchList.map((element, element_idx) => {
                                            return (
                                                <div key={element_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.name}</MenuItem>}
                                                    {is_mobile && element_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu"} style={{
                                                        cursor: "pointer",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md BT" : "16px/16px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.name}</MenuItem>}
                                                </div>
                                            )
                                        })}
                                    </div>}

                                {activeFeatureId === features_router.bucket_list.featureId && featureSearchList !== undefined && featureSearchList.length > 0 &&
                                    <div>
                                        <div style={{ display: "flex", outline: "0", paddingBottom: "6px" }}>
                                            <div style={{
                                                flexGrow: "1",
                                                paddingTop: "10px",
                                                paddingBottom: "6px",
                                                paddingLeft: "6px",
                                                outline: "0px",
                                                font: is_mobile ? "11px / 11px Futura Md BT" : "16px / 16px Futura Md BT",
                                            }}></div>
                                            <div onClick={handleClose} style={{ marginRight: "10px", marginTop: "3px" }}><TimesIcon width={13} height={13} /> </div>
                                        </div>
                                        {featureSearchList.map((element, element_idx) => {
                                            return (
                                                <div key={element_idx} >
                                                    {!is_mobile && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu bucket-menu"} style={{
                                                        cursor: "pointer",
                                                        paddingLeft: "12px",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md LT" : "14px/14px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.name}</MenuItem>}
                                                    {is_mobile && element_idx < 4 && <MenuItem className={"btn btn-sm flex-wrap member_menu_top_btn member_menu "} style={{
                                                        cursor: "pointer", paddingLeft: "12px",
                                                        minHeight: is_mobile ? "0px" : "",
                                                        font: is_mobile ? "9px/9px Futura Md LT" : "14px/14px Futura Md BT"
                                                    }} onClick={() => handleClick(element)}>{element.name}</MenuItem>}

                                                </div>
                                            )
                                        })}
                                    </div>}

                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        activeFeatureId: state.Feature.feature.activeId,
        featureSearchList: state.Feature.feature.featureSearchList,
        is_mobile: state.Setting.is_mobile,
    }
}
const mapDispatchToProps = {
    handleSelectedAutocompleteFeature: Actions.handleSelectedAutocompleteFeature,
    setSwitchContainer: Actions.setSwitchContainer,

}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarAutocomplete)
