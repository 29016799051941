import * as Actions from '../../../../redux/actions'

import { DeleteIcon, PlusCircleIcon, PlusSignIcon, MinusSignIcon } from '../SVGBank'

import ActivityElement from "./ActivityElement"
import AddScheduleEstabishment from './AddScheduleEstablishment'
import AddEstabishment from '../TimelineWizard/AddEstabishment'
import DeleteActivityDay from '../TimelineWizard/DeleteActivityDay'
import DeleteTripEllipsis from "./DeleteTripEllipsis"
import Divider from '@material-ui/core/Divider'
import LinkDestination from "../../DestinationPage/LinkDestination"
import React from "react"
import { TripDestinationTabs } from "../TimelineWizard/TripDestinationTabs"
import { connect, useDispatch, useSelector } from "react-redux"
import { list_container_mode } from "../RegExValidate"
import { member_area_router } from "../RegExValidate"
import moment from "moment"
import { useLocation } from 'react-router-dom';
import TripScheduleHotel from './TripScheduleHotel'
import { getTimelineFlights, getTimelineHotels } from './ActiveTrip.slice'
import TripScheduleFlight from './TripScheduleFlight'

// import Flight from "../Fight"
// import Hotel from "../Hotel"
/*
trip schedule component */
const TripSchedule_ = ({ screenSize, logo_height, switch_container, tripMode, activeTrip, setTripDisplayMode, setDestinationId, lang, uuid_auth, setActiveDayInTrip, deleteTripDestinationInTrip, addDayToTripDestinationInTrip, logo_width }) => {
    const tripId = activeTrip.tripId
    const tripDuration = activeTrip.tripDuration
    const tripStartDate = activeTrip.tripStartDate
    const tripNoDate = activeTrip.tripNoDate
    const tripDestinations = activeTrip.tripDestinations
    const tripActivities = activeTrip.activities
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const setActiveDayInTripHandle = (tripDestinationId, dayInTrip, dayInTripDestination, destinationId) => {
        setActiveDayInTrip(tripDestinationId, dayInTrip, dayInTripDestination, destinationId)
        setTripDisplayMode(list_container_mode.timeline)
        setDestinationId(destinationId)
    }
    const location = useLocation()
    const isPrintPage = location && location.pathname.includes("print");
    const dispatch = useDispatch()
    const [openAddActivity, setOpenAddActivity] = React.useState({ day_index: -1, open: false })
    //console.log (`TripSchedules switch_container = ${switch_container}`)
    //console.log (`TripSchedules tripMode = ${tripMode}`)

    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const muvHotels = timelineHotels && timelineHotels.muvHotels ? timelineHotels.muvHotels : null;
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const hasSavedFlights = timelineFlights && timelineFlights.flightInfo.length > 0;


    React.useEffect(() => {
        if (tripId) {
            dispatch(getTimelineHotels({
                uuid: uuid,
                tripId: tripId
            }))
            dispatch(getTimelineFlights({
                uuid: uuid,
                tripId: tripId
            }))
        }
    }, [tripId])




    return (
        <React.Fragment>
            { switch_container === member_area_router.memberAreaTrip ?
                <div style={{ color: "#FFFFFF" }} >

                    {
                        tripDestinations !== undefined
                        && tripDestinations.length > 0
                        && tripDestinations.map((destination, index_destination) => {
                            const destinationDays = destination.spanningDays !== undefined && destination.spanningDays.split(',')
                            const lengthDestinationDays = destinationDays.length
                            //console.log(`TripSchedule destination.name ${destination.name}`)
                            //console.log(`TripSchedule lengthDestinationDays ${lengthDestinationDays}`)

                            return (
                                <div key={index_destination} style={{ backgroundColor: "#191919" }}>
                                    <div style={{ display: "flex", marginLeft: logo_width + "px", backgroundColor: "#2C2C2C", paddingLeft: "20px" }}>
                                        <div className="futura_hv_bt_45_45 padding_top_50 padding_bottom_15" style={{ color: "#FFFFFF", position: "relative", display: "inline-block", }}>
                                            {destination.name}
                                            {/*tripDestinations.length > 1 && <div style={{ position: "absolute", top: "15px", right: "-35px", cursor: "pointer" }}
                                                onClick={() => deleteTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)}>
                                                <DeleteIcon />
                                            </div>*/}


                                        </div>


                                        <div style={{ flexGrow: "1", textAlign: "right" }}>
                                            <div style={{ display: "inline-block", font: "16px/16px Futura Lt BT", color: "#19BC9B", cursor: "pointer", paddingTop: "65px" }}
                                                onClick={() => addDayToTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)} >
                                                <PlusCircleIcon width={25} height={25} style={{ marginRight: "5px", }} fill={"#19BC9B77"} />{"Add a day in " + destination.name}
                                            </div>
                                            <div style={{ display: "inline-block", font: "18px/21px Futura Md BT", color: "#19BC9B", cursor: "pointer", marginLeft: -138 }} >
                                                <DeleteTripEllipsis lang={lang} uuid={uuid} destination={destination} tripId={tripId} tripDuration={tripDuration} />
                                            </div>
                                        </div>
                                    </div>

      

                                 {timelineFlights && timelineFlights.flightInfo?.length > 0 &&
                                        timelineFlights.flightInfo.map((flight, index) => {
                                            const isReturnFlight = hasSavedFlights && timelineFlights.flightInfo.length > 1 && index === timelineFlights.flightInfo.length - 1;
                                            if (flight?.trip_destinations_id === destination?.td_id && !isReturnFlight) {
                                                return (<div key={index}>
                                                    <div style={{ backgroundColor: "#2C2C2C", marginLeft: 127, paddingTop: 40 }}>

                                                        <TripScheduleFlight flight={flight} />
                                                    </div>
                                                </div>)
                                            }
                                        })}

                                    {
                                        destination.spanningDays !== undefined
                                        && destination.spanningDays !== null
                                        && destination.spanningDays !== ""
                                        && destinationDays.map((dayInTrip, idx_day) => {
                                            const dayInTripDestination = String(idx_day + 1)
                                            const activitiesInDestinationDay = tripActivities
                                                .filter(a => a.activityDay === dayInTrip && a.tripDestinationId === destination.td_id)
                                            const lengthActivitiesInDestinationDay = activitiesInDestinationDay.length
                                            return (
                                                <div key={idx_day} style={{ backgroundColor: "#191919" }}>
                                                    {
                                                        dayInTrip !== "" && <div>
                                                            <div className="dayHeader" style={{ position: "sticky", textAlign: "center", top: Math.floor(logo_height / 2) + "px", zIndex: "2", marginLeft: Math.floor(logo_width) + "px", backgroundColor: "#2C2C2C", padding: "35px 0px 0px 40px", }}>
                                                                <div style={{ position: "relative" }}>
                                                                    <div style={{
                                                                        display: "block",
                                                                        position: "absolute",
                                                                        left: Math.floor(-(logo_width + 40)) + "px",
                                                                        top: "-25px",
                                                                        textAlign: "left",
                                                                        font: screenSize > 500 ? "18px/21px Futura Hv BT" : "11px/13px Futura Hv BT",
                                                                        letterSpacing: "0",
                                                                        color: "var(--mainGreen)",
                                                                    }}>
                                                                        {tripNoDate === "1" ? <div style={{ width: logo_height + "px", textAlign: "center", font: "20px/20px Futura Hv BT", backgroundColor: "#191919", paddingTop: "5px" }}>{"Day " + dayInTrip}</div> :
                                                                            <div style={{ width: logo_height + "px", backgroundColor: "#191919" }}>
                                                                                <div className="text-center text_main_green" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd ')}</div>
                                                                                <div className="text-center text_main_green" style={{ font: screenSize > 1024 ? "36px/40px Futura Hv BT" : "18px/20px Futura Hv BT", }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('D')}</div>
                                                                                <div className="text-center text_main_white" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('MMM')}</div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    position: "absolute",
                                                                    width: logo_width + "px",
                                                                    left: -(Math.floor(logo_width)) + "px",
                                                                    top: "-15px",
                                                                    backgroundColor: "#191919",
                                                                }} >
                                                                    <hr style={{
                                                                        width: screenSize > 500 ? "60px" : "35px",
                                                                        border: "1px solid var(--lightGraySeparator)",
                                                                        background: "var(--lightGraySeparator)",
                                                                        opacity: "1",
                                                                    }} />
                                                                </div>
                                                                <div style={{
                                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                                    border: "0px solid #707070",
                                                                    borderRadius: "30px",
                                                                    width: "420px",
                                                                    height: "60px",
                                                                    alignItems: "center",
                                                                    display: "inline-block",
                                                                    position: "relative",
                                                                }}>
                                                                    <div style={{ font: "24px/60px Futura Hv BT", color: "#707070", }}>
                                                                        {tripNoDate === "1" ? "Day " + dayInTrip : moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd, MMM Do YYYY')}
                                                                    </div>

                                                                    {lengthDestinationDays > 1 && lengthActivitiesInDestinationDay === 0 &&
                                                                        <div style={{ position: "absolute", top: "15px", left: "45px" }}>
                                                                            <DeleteActivityDay
                                                                                destination={destination}
                                                                                dayInTripDestination={dayInTripDestination}
                                                                            />
                                                                        </div>}

                                                                    <div style={{ position: "absolute", top: "15px", right: "40px" }}
                                                                        onClick={() => setOpenAddActivity({ day_index: idx_day, open: !openAddActivity.open })}>
                                                                        {(!openAddActivity.open && openAddActivity.day_index === idx_day) || openAddActivity.day_index !== idx_day ?
                                                                            <PlusSignIcon width={20} height={20} fill={"var(--mainGreen"} /> :
                                                                            <MinusSignIcon width={20} height={20} fill={"var(--mainGreen"} />
                                                                        }
                                                                    </div>

                                                                </div>

                                                                {openAddActivity.open === true && openAddActivity.day_index === idx_day && <div style={{ height: "30px" }}>
                                                                    <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: "#FFFFFF", height: "30px" }} />
                                                                </div>}

                                                                {openAddActivity.open === true && openAddActivity.day_index === idx_day && <div style={{
                                                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                                    border: "0px solid #707070",
                                                                    borderRadius: "30px",
                                                                    width: "520px",
                                                                    height: "60px",
                                                                    alignItems: "center",
                                                                    display: "inline-block",
                                                                    position: "relative",
                                                                }}>
                                                                    <div style={{ font: "20px/30px Futura Hv BT", color: "#707070", }}>
                                                                        <AddScheduleEstabishment
                                                                            background_color={""}
                                                                            activeTrip={activeTrip}
                                                                            activeTripId={tripId}
                                                                            destinationId={destination.id}
                                                                            destinationName={destination.name}
                                                                            tripDuration={tripDuration}
                                                                            activeDayInTrip={dayInTrip}
                                                                            activeDayInTripDestination={dayInTripDestination}
                                                                            activeTripDestinationId={destination.td_id}
                                                                            setOpenAddActivity={setOpenAddActivity}
                                                                            day_index={idx_day}
                                                                        />
                                                                    </div>
                                                                </div>}

                                                            </div>
                                                        </div>
                                                    }


                                                    {timelineHotels && timelineHotels.customHotels &&
                                                        timelineHotels.customHotels.map((hotel, index) => {
                                                            if (hotel?.tripDestinationId === destination?.td_id
                                                                && dayInTrip === hotel?.startDayInDestination) {
                                                                return (<div key={index} id={"hotel_" + hotel?.tripDestinationId}>

                                                                    <div style={{ backgroundColor: "#2C2C2C", marginLeft: 127, paddingTop: 40 }}>

                                                                        <TripScheduleHotel hotel={hotel} />


                                                                    </div>

                                                                </div>)
                                                            }
                                                        })}



                                                    {timelineHotels && muvHotels && muvHotels.length > 0 &&
                                                        muvHotels.map((hotel, index) => {
                                                            if (hotel?.tripDestinationId === destination?.td_id
                                                                && dayInTrip === hotel?.startDayInDestination) {
                                                                return (<div key={index} id={"hotel_" + hotel?.tripDestinationId}>

                                                                    <div style={{ backgroundColor: "#2C2C2C", marginLeft: 127, paddingTop: 40 }}>

                                                                        <TripScheduleHotel hotel={hotel} />


                                                                    </div>

                                                                </div>)
                                                            }
                                                        })}

                                                    <div style={{ paddingLeft: logo_width + "px", backgroundColor: "#191919" }}>
                                                        <div style={{ paddingLeft: "40px", backgroundColor: "#2C2C2C", }}>
                                                            {
                                                                tripActivities !== undefined
                                                                && tripActivities.length > 0
                                                                && activitiesInDestinationDay
                                                                    .map((activity, idx_activity) => {
                                                                        return (
                                                                            <ActivityElement
                                                                                key={idx_activity}
                                                                                activity={activity} />
                                                                        )
                                                                    })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }
                    <div style={{ textAlign: "right", backgroundColor: "#191919" }}>
                        <div style={{ height: "100px", marginLeft: Math.floor(logo_width) + "px", backgroundColor: "#2C2C2C" }} >
                            <div style={{ width: "400px", display: "inline-block", textAlign: "right", marginTop: "30px" }}  >
                                <TripDestinationTabs from={list_container_mode.schedule} background_color={"#2C2C2C"} />
                            </div>
                        </div>
                    </div>
                </div >
                :
                <div style={{ color: "#FFFFFF" }} >

                    {
                        tripDestinations !== undefined
                        && tripDestinations.length > 0
                        && tripDestinations.map((destination, index_destination) => {
                            const destinationDays = destination.spanningDays !== undefined && destination.spanningDays.split(',')
                            const lengthDestinationDays = destinationDays.length
                            const startDate = destinationDays ? Number(destinationDays[0]) : 0;
                            const endDateNumber = lengthDestinationDays > 1 ? Number(destinationDays[lengthDestinationDays - 1]) : 0;
                            const beginDate = tripStartDate && destinationDays && lengthDestinationDays > 1 ? moment(tripStartDate).add(startDate - 1, 'day').format('MMM DD') : moment(tripStartDate).add(startDate - 1, 'day').format('MMM DD, YYYY')

                            const endDate = tripStartDate && lengthDestinationDays > 1 && moment(tripStartDate).add(endDateNumber - 1, 'day').format('MMM DD, YYYY')
                            return (
                                <div key={index_destination} style={{ backgroundColor: "#C7C7C7" }}>
                                    <div style={{ display: "flex", marginLeft: logo_width + "px", backgroundColor: "#FFFFFF", paddingLeft: "40px" }}>
                                        <div className="futura_hv_bt_45_45 padding_top_50 padding_bottom_15" style={{ color: "#191919", position: "relative", display: "inline-block", }}>
                                            {destination.name}
                                            {isPrintPage && <span style={{
                                                font: "18px/18px Futura Lt BT",
                                                marginLeft: '30px'
                                            }}>
                                                {beginDate} {endDate && <span> - {endDate}</span>}
                                            </span>}
                                            {/*tripDestinations.length > 1 && <div style={{ position: "absolute", top: "15px", right: "-35px", cursor: "pointer" }}
                                                onClick={() => deleteTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)}>
                                                <DeleteIcon />
                                            </div>*/}
                                        </div>
                                        <div style={{ flexGrow: "1", textAlign: "right" }} class="addActivity">
                                            <div style={{ display: "inline-block", font: "16px/16px Futura Lt BT", color: "#19BC9B", textAlign: "right", paddingTop: "65px", cursor: "pointer" }}
                                                onClick={() => addDayToTripDestinationInTrip(lang, uuid, destination, tripId, tripDuration)} >
                                                <PlusCircleIcon width={25} height={25} style={{ marginRight: "5px", }} fill={"#19BC9B"} />{"Add a day in " + destination.name}
                                            </div>
                                            <div style={{ display: "inline-block", font: "18px/21px Futura Md BT", color: "#19BC9B", cursor: "pointer", marginLeft: -138 }} >
                                                <DeleteTripEllipsis lang={lang} uuid={uuid} destination={destination} tripId={tripId} tripDuration={tripDuration} />
                                            </div>
                                            <LinkDestination link_style={{
                                                color: "#19BC9B", cursor: "pointer", marginTop: "10px",
                                            }}
                                                from={"trip_schedule"}
                                                lang={lang}
                                                destination_id={destination.id}
                                                title={"Add an activity in timeline"} />
                                        </div>
                                    </div>
                                    {
                                        destination.spanningDays !== undefined
                                        && destination.spanningDays !== null
                                        && destination.spanningDays !== ""
                                        && destinationDays.map((dayInTrip, idx_day) => {
                                            const dayInTripDestination = String(idx_day + 1)
                                            const activitiesInDestinationDay = tripActivities
                                                .filter(a => a.activityDay === dayInTrip && a.tripDestinationId === destination.td_id)
                                            const lengthActivitiesInDestinationDay = activitiesInDestinationDay.length
                                            return (
                                                <div key={idx_day} style={{ backgroundColor: "#C7C7C7" }}>
                                                    {
                                                        dayInTrip !== "" &&
                                                        <div className="dayHeader" style={{ position: "sticky", textAlign: "center", top: Math.floor(logo_height / 2) + "px", zIndex: "2", marginLeft: Math.floor(logo_width) + "px", backgroundColor: "#FFFFFF", padding: "35px 0px 0px 40px", }}>
                                                            <div style={{ position: "relative" }} class="addActivity" >
                                                                <div style={{
                                                                    display: "block",
                                                                    position: "absolute",
                                                                    left: Math.floor(-(logo_width + 40)) + "px",
                                                                    top: "-25px",
                                                                    textAlign: "left",
                                                                    font: screenSize > 500 ? "18px/21px Futura Hv BT" : "11px/13px Futura Hv BT",
                                                                    letterSpacing: "0",
                                                                    color: "var(--mainGreen)",
                                                                }}>
                                                                    {tripNoDate === "1" ? <div style={{ width: logo_height + "px", textAlign: "center", font: "20px/20px Futura Hv BT", backgroundColor: "#C7C7C7", paddingTop: "5px", color: "#191919" }}>{"Day " + dayInTrip}</div> :
                                                                        <div style={{ width: logo_height + "px", backgroundColor: "#C7C7C7" }}>
                                                                            <div className="text-center text_main_green" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd ')} ad</div>
                                                                            <div className="text-center text_main_green" style={{ font: screenSize > 1024 ? "36px/40px Futura Hv BT" : "18px/20px Futura Hv BT", }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('D')}</div>
                                                                            <div className="text-center" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", color: "#191919" }}>{moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('MMM')}</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                position: "absolute",
                                                                width: logo_width + "px",
                                                                left: -(Math.floor(logo_width)) + "px",
                                                                top: "-15px",
                                                                backgroundColor: "#C7C7C7",
                                                            }} >
                                                                <hr style={{
                                                                    width: screenSize > 500 ? "60px" : "35px",
                                                                    border: "1px solid var(--lightGraySeparator)",
                                                                    background: "var(--lightGraySeparator)",
                                                                    opacity: "1",
                                                                }} />
                                                            </div>
                                                            <div style={{
                                                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                                                border: "1px solid #707070",
                                                                borderRadius: "30px",
                                                                width: "420px",
                                                                height: "60px",
                                                                alignItems: "center",
                                                                display: "inline-block",
                                                                position: "relative",
                                                            }}>
                                                                <div style={{ font: "24px/60px Futura Hv BT", color: "#707070", }}>
                                                                    {tripNoDate === "1" ? "Day " + dayInTrip : moment(tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd, MMM Do YYYY')}
                                                                </div>
                                                                {lengthDestinationDays > 1 && lengthActivitiesInDestinationDay === 0 &&
                                                                    <div style={{ position: "absolute", top: "15px", right: "15px" }}>
                                                                        <DeleteActivityDay
                                                                            destination={destination}
                                                                            dayInTripDestination={dayInTripDestination}
                                                                        />
                                                                    </div>}
                                                                <div style={{ position: "absolute", top: "10px", width: "400px", right: "-420px", color: "#19BC9B", cursor: "pointer", textAlign: "left" }}>
                                                                    <AddEstabishment background_color={""}
                                                                        activeTrip={activeTrip}
                                                                        activeTripId={tripId}
                                                                        destinationId={destination.id}
                                                                        tripDuration={tripDuration}
                                                                        activeDayInTrip={dayInTrip}
                                                                        activeDayInTripDestination={dayInTripDestination}
                                                                        activeTripDestinationId={destination.td_id}
                                                                    />

                                                                </div>

                                                                {activitiesInDestinationDay.length === 0 && idx_day !== 0
                                                                    && <div style={{ position: "absolute", top: "-40px", left: "50%" }}>
                                                                        <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: "#FFFFFF", height: "45px" }} />
                                                                    </div>}
                                                            </div>
                                                        </div>

                                                    }
                                                    <div style={{ paddingLeft: logo_width + "px", backgroundColor: "#C7C7C7" }}>
                                                        <div style={{ paddingLeft: "40px", backgroundColor: "#FFFFFF", }}>
                                                            {
                                                                tripActivities !== undefined
                                                                && tripActivities.length > 0
                                                                && activitiesInDestinationDay
                                                                    .map((activity, idx_activity) => {
                                                                        return (
                                                                            <ActivityElement
                                                                                key={idx_activity}
                                                                                activity={activity} />
                                                                        )
                                                                    })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }
                    <div style={{ textAlign: "right", backgroundColor: "#C7C7C7" }}>
                        <div style={{ height: "100px", marginLeft: Math.floor(logo_width) + "px", backgroundColor: "#FFFFFF" }} >
                            <div style={{ width: "400px", display: "inline-block", textAlign: "right", marginTop: "30px" }}  >
                                <TripDestinationTabs from={list_container_mode.schedule} background_color={""} />
                            </div>
                        </div>
                    </div>
                </div >}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({

    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,

    screenSize: state.Setting.htmlBody.bodyClientWidth,
    trip: state.Member.memberArea.selectedTrip,
    logo_width: state.Setting.appMenu.logo.width,
    logo_height: state.Setting.appMenu.logo.height,

    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,

    listDestinationId: state.Destination.destination.activeId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,

    switch_container: state.Member.switch_container,
    tripMode: state.Member.tripMode,

})

const mapDispatchToProps = {
    deleteTripDestinationInTrip: Actions.deleteTripDestinationInTrip,
    addDayToTripDestinationInTrip: Actions.addDayToTripDestinationInTrip,
    setActiveDayInTrip: Actions.setActiveDayInTrip,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setDestinationId: Actions.setDestinationId,

}

const TripSchedule = connect(mapStateToProps, mapDispatchToProps)(TripSchedule_)
export default TripSchedule

