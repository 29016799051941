import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import AreYouMuver from "./AreYouMuver/AreYouMuver"
import BlogList from "./Blog/BlogSlider"
import BookingFormSliderContainer from '../Common/BookingForm/BookingFormSliderContainer'
import BucketList from "./BucketList/BucketList"
import { Discover } from "../TripPage/Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import { HeaderMobile } from '../Header/HeaderMobile'
import Influencer from "./Influencer/Influencer.jsx"
import MuvInPress from "./MuvInPress/MuvInPress"
import { PropTypes } from 'prop-types'
import ScrollToTop from '../Common/ScrollToTop'
import StartPlanning from './StartPlanning/StartPlanning'
import SuggestedDestination from "./SuggestedDestination/suggestedDestination"
import Trending from "./Trending/Trending"
import { connect, useDispatch, useSelector } from 'react-redux'
import { TrackEvent } from '../../../utility/utility'
import WhyJoin from './WhyJoin/WhyJoin'
import AIBookingFormSliderContainer from '../Common/BookingForm/AIBookingFormSliderContainer'
import StayFormSliderContainer from '../Common/BookingForm/StayFormSliderContainer'
import { updateStayTrip } from '../FeaturePage/personalizedTrip/personalizedTrip.slice'

const StayHomeContainer_ = ({homepage,settAppMenuBackGroundColor, setAppMenuBookingButtonStatus,
	homepageMuverStyle,setBookingFormStatus, homepageMuverPress, is_mobile, homepageBucketList}) => {
	
		const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
		const dispatch = useDispatch()
	
		const handleScroll = () => {
		const rgba = Math.floor(window.pageYOffset / 4) > 99 ? 1 : Math.floor(window.pageYOffset / 4) / 100
		window.pageYOffset < 1100 && settAppMenuBackGroundColor("rgba(25,25,25, " + rgba)
		window.pageYOffset < 400 ? setAppMenuBookingButtonStatus(false) : setAppMenuBookingButtonStatus(true)
		window.pageYOffset < 400 && setBookingFormStatus(false)
	}

	React.useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		TrackEvent('STAY_HOME',"Stay Home page visit","Stay Home page")
		dispatch(updateStayTrip(true))

		return ()=>{
			window.removeEventListener("scroll", handleScroll)
		}
    }, [])


		let padding_l = "8.5"
		let padding_r = "8.5"
		let right_outline = "-18.5"
		if (!isNaN(is_mobile)) {
			padding_l = is_mobile ? "1" : "8.5"
			padding_r = is_mobile ? "1" : "8.5"
			right_outline = is_mobile ? "1" : "-18.5"
		}
		return (
			<React.Fragment>
				<div className="homePage">
					{!is_mobile ? <Header bg_color={"rgba(25,25,25, 0)"} text_color={"var(--mainWhite)"} /> : <HeaderMobile useTransparent bg_color={"var(--frameBlack)"} text_color={"var(--mainWhite)"} />}
					<StayFormSliderContainer />
					{
						homepage.suggestedDestinations !== undefined && homepage.suggestedDestinations.length > 0 && <div style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%", 
						}}>
							<SuggestedDestination />
							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" />}
						</div>

					}

					


					{
						homepage.trendingTrips && homepage.trendingTrips.length > 0 && <div className="card-section" style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%", marginTop: is_mobile ? "0px" : "150px" }}>
							<Trending />
						</div>
					}
					
					<FooterWhite />
				</div >
				<ScrollToTop />
			</React.Fragment>
		)
}

StayHomeContainer_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,

		lang: state.Setting.lang,
		homepage: state.Home.homepage,
		homepageBucketList: state.Home.homepage_bucket_list,
		homepageMuverStyle: state.Home.homepage_muver_style,
		homepageMuverPress: state.Home.homepage_muver_press,

		uuid: state.Member.authModal.uuid,

		is_mobile: state.Setting.is_mobile,


	}

}

const mapDispatchToProps = {

	setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
	settAppMenuBackGroundColor: Actions.settAppMenuBackGroundColor,
	setBookingFormStatus: Actions.setBookingFormStatus,
}
const StayHomeContainer = connect(mapStateToProps, mapDispatchToProps)(StayHomeContainer_)

export default StayHomeContainer