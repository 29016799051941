import { Rating } from '@material-ui/lab'
import React from 'react'
import BucketListName from './BucketListName'
import { image_boxing, member_area_router, URL_REPLACE } from '../RegExValidate'
import { TripDescription } from '../Trip/TripDescription'
import { Heart } from '../MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap'
import { Plus } from '../MemberActions/Plus'
import { Star } from '../MemberActions/Star'
import { Done } from '../MemberActions/Done';
import { useHistory } from "react-router-dom"

export const BucketListView = ({is_mobile,
    timelineWizardStatus,postionContainer,widthContainer,heightContainer,bucketItem,switchFeatureContainer, isMember,setSwitchContainer

}) => {

    const history = useHistory();

    function checkImage(check){
        if(check){
            return "0.5"
        }else {}
    }

    const handleClick = (item) => {
        setSwitchContainer(member_area_router.bucketList)
        const url = "/bucketlist/" + item.bucketListName.replace(URL_REPLACE, "-") + "/" + item.bucketListID;
        history.push(url.toLowerCase())
    }

    return (
        <div>
            <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                <div className={postionContainer}>
                    <div onClick={()=> handleClick(bucketItem)} className="d-inline-block" style={{ width: widthContainer, cursor:"pointer", backgroundColor: "#fb7c44"}}> 
                        <Image src={image_boxing(bucketItem.bucketDestinationPicture, widthContainer, is_mobile?"200":"300")}
                        alt="bucket" style={{ objectFit: "cover", width: widthContainer, maxHeight: is_mobile ? "200" : 300,opacity: checkImage(bucketItem?.bucketDestinationPicture?.imageName?.includes("grayBackgroundCamera"))}}
                        
                        /></div>
                    <div style={{ width: widthContainer + "px",height: 300 + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                        <div style={{ position: "relative", height: "25px" }} className={timelineWizardStatus ? "margin_top_10 box_1 padding_left_10 padding_right_20 text-right" : "margin_top_20 box_1 padding_left_10 padding_right_20 text-right"} >
                        <div style={{ position: "absolute", top: "0px", right: "140px" }}>
                                                                        <Done
                                                                              unitID={bucketItem.bucketListID}
                                                                              feature={switchFeatureContainer}
                                                                              fill={"var(--mainOrange)"} />
                                                                    </div>
                           
                            <div style={{ position: "absolute", top: "0px", right: "100px" }}>
                                <Heart
                                    unitID={bucketItem.bucketListID}
                                    feature={switchFeatureContainer}
                                    fill={"var(--mainOrange)"} />
                            </div>
                            <div style={{ position: "absolute", top: "0px", right: "60px" }}>
                                <Plus
                                    unitID={bucketItem.bucketListID}
                                    feature={switchFeatureContainer}
                                    fill={"var(--mainOrange)"} />
                            </div>
                            <div style={{ position: "absolute", top: "0px", right: "20px" }}>
                                <Star
                                    unitID={bucketItem.bucketListID}
                                    feature={switchFeatureContainer}
                                    fill={"var(--mainOrange)"} />
                            </div>
                        </div>


                        {/* <React.Fragment> */}
                        <div className="box_1 padding_left_10 padding_right_10" style={{
                            font: "14px/16px Futura Md BT",
                            color: switchFeatureContainer.color,
                        }}>
                            {bucketItem.bucketDestinationName}
                        </div>

                        {/* </React.Fragment> */}



                        <BucketListName
                            bucketName={bucketItem.bucketListName}
                            bucketId={bucketItem.bucketListID}
                            titleClass={"box_1"} titleStyle={{
                                font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/43px Futura Hv BT",
                                color: !isMember ? "var(--frameBlack)" : "#FFFFFF",
                                cursor: "pointer",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                            }} />
                        {bucketItem.starsCount !== undefined
                            && bucketItem.starsCount !== null
                            && bucketItem.starsCount !== "0.0"
                            && bucketItem.starsCount !== "0"
                            && <div className="d-flex timeline_rating_color padding_left_10 padding_right_10 btn_rounded">
                                <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(bucketItem.starsCount)} disabled={true} />
                                <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({bucketItem.starsCount})</div>
                            </div>}
                    
                        {bucketItem.bucketListDescription !== undefined && <div className={"padding_left_10 padding_right_10"}><TripDescription description={bucketItem.bucketListDescription} boxDescription={"box_3"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"57px"} /></div>}
                        {<div style={{ textAlign: "right"}}>
                            <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}
                            style={{position: is_mobile? "relative":"absolute", bottom: 0, width: "78%", zIndex: "2", overflow: 'hidden', background: 'inherit'}}>
                                {bucketItem.subTypes !== undefined && bucketItem.subTypes.length > 0 &&
                                    bucketItem.subTypes.map((subtype_element, subtype_idx) => {
                                        return <div key={subtype_idx} style={{backgroundColor: 'rgba(251, 136, 77,0.2)',cursor: "pointer"}}
                                            className="btn_rounded m-1 futura_md_bt_14_16_orange upcoming_trip_style"
                                        // onClick={() => {
                                        //     selectedSubtypes.find(val => val.id === subtype_element.subTypeID) ?
                                        //         deleteSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                        //         : addSelectedSubTypeDestination({ id: subtype_element.subTypeID, title: subtype_element.subTypeName }, lazyLoading, selectedSubtypes)
                                        // }}
                                        >
                                            {subtype_element.subTypeName}</div>
                                    })
                                }
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    )

}