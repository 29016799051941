import * as Actions from '../../../redux/actions'
import { EllipsisVIcon, TimesIcon } from "../Common/SVGBank"

import { Discover } from "../TripPage/Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { PasswordEye, PasswordTextField } from '../Common/FormComponentsBank'
import { registerPasswordUseStyles } from '../Common/ComponentsStylingBank'
import { CircularProgress } from '@material-ui/core'
import { dispatch } from 'rxjs/internal/observable/range'
import { resetPasswordToken, verifyResetToken } from '../../../redux/slices/Login.slice'

const ResetPasswordContainer_ = ({ setOpenAuthModal,is_mobile, passwordTextField, uuid, lang, getMemberActionsSets, screenSize }) => {
    let padding_l = "15.7"
    let padding_r = "15.7"
    let font_size = is_mobile ? "28" : "36"
    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "15.7"
        padding_r = is_mobile ? "1" : "15.7"
    }
    const classesPassword = registerPasswordUseStyles();
    const [loading, setLoading] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)
    const [expired, setExpired] = React.useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const parameters = new URLSearchParams(history?.location?.search);
    const token = parameters.get('token');

    React.useEffect(() => {

    }, [])



    React.useEffect(() => { ///token check
        setExpired(false);

        const checkToken = async () => {
            const data = {
                token: token
            }
            const result = await dispatch(verifyResetToken(data))
            setExpired(!result);
        }

        if (token) {
            checkToken()
        }

    }, [])



    const resetPassword = async () => {
        const password = passwordTextField?.password;
        setLoading(true)
        const data = {
            token: token,
            password: password
        }

        const result = await dispatch(resetPasswordToken(data))
        if(result){
            history.push("/")
            setOpenAuthModal(true)
        }
        setLoading(false)

    }


    return (
        <div style={{
            overflowX: "hidden",
            height: "100vh"

        }} >


            <div style={{
                position: "absolute",
                margin: "auto",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: 400,
                height: 400
            }}>

                {!expired ? <div>
                    <div style={{
                        font: "24px/24px Futura Hv BT",
                        color: "#191919",
                        paddingLeft: '30px',
                        paddingBottom: '30px',
                        flexGrow: "1",
                        textAlign: "center"
                    }}>Reset Password</div>


                    <div className="row m-0 position-relative">
                        <div className={"col-12"} style={{
                            marginTop: "10px",
                        }}>
                            <div className={classesPassword.root}>
                                <PasswordTextField
                                    // disabled={token !== undefined && token !== ""}
                                    label="Password"
                                    required={true}
                                    id="password"
                                />
                            </div>
                        </div>

                        <div className="position-absolute text-right d-inline-block"
                            style={{
                                right: 23,
                                top: '45%',
                            }}>
                            <PasswordEye />
                        </div>

                    </div>

                    <div className="row m-0 position-relative">
                        <div

                            onClick={resetPassword}
                            style={{
                                cursor: "pointer",
                                backgroundColor: "var(--mainGreen)",
                                padding: "20px",
                                width: "100%",
                                marginTop: "20px",
                                margin: 15,
                                display: "block",
                                border: "0",
                            }}>
                            <div style={{
                                textAlign: "center",
                                font: "18px/14px Futura Md BT",
                                letterSpacing: "0",
                                margin: "0",
                                color: "var(--mainWhite)",
                            }}>
                                Reset Password  {loading && <CircularProgress
                                    size={20}
                                    style={{
                                        marginLeft: "5px",
                                        marginTop:-10
                                    }}
                                    color="inherit" />}
                            </div>

                        </div>
                    </div>
                </div> :
                    <div style={{
                        font: "24px/24px Futura Hv BT",
                        color: "#191919",
                        paddingLeft: '30px',
                        paddingBottom: '30px',
                        flexGrow: "1",
                        textAlign: "center"
                    }}>Reset Password Link Expired</div>}




            </div>
            <div style={{
                position: "fixed",
                bottom: 0,
                width: "100%"
            }}>
                <FooterWhite />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    passwordTextField: state.FormBank.passwordTextField,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

})

const mapDispatchToProps = {
    getMemberActionsSets: Actions.getMemberActionsSets,
    setOpenAuthModal: Actions.setOpenAuthModal,

}

const ResetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer_)
export default ResetPasswordContainer
