import React from 'react'
import PostsUser from "./PostsUser"
import ReviewsGoogle from "./ReviewsGoogle"
import ReviewsYelp from "./ReviewsYelp"
import ReviewsPriceline from "./ReviewsPriceline"
import { connect, useDispatch, useSelector } from 'react-redux'
import { lookUpHotelReviews } from '../../BookingPage/PaymentPage/payment.thunk'
import { Virtuoso } from 'react-virtuoso'
import MuvHotelReviews from './MuvHotelReviews'

const MuvHotelEstablishmentReviews_ = ({ establishment, reviewsGoogleAndYelp, reviewsFromApi }) => {
    const [open, setOpen] = React.useState(false)

    const hasGoogleReviews = reviewsGoogleAndYelp !== null && reviewsGoogleAndYelp !== undefined &&
        reviewsGoogleAndYelp.google !== null && reviewsGoogleAndYelp.google !== undefined &&
        reviewsGoogleAndYelp.google.length > 0


  

    return (
        <div>
            <div style={{
            }}>
                <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                    <PostsUser open={open} />
                </div>
                {hasGoogleReviews &&
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        {"Google Reviews"}
                        <ReviewsGoogle reviewsGoogle={reviewsGoogleAndYelp.google} open={open} />
                    </div>}
                {(open || !hasGoogleReviews) && reviewsGoogleAndYelp !== null && reviewsGoogleAndYelp !== undefined &&
                    reviewsGoogleAndYelp.yelp !== undefined &&
                    reviewsGoogleAndYelp.yelp.length > 0 &&
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        {"Yelp Reviews"}
                        <ReviewsYelp reviewsYelp={reviewsGoogleAndYelp.yelp} />
                    </div>}
                {
                    <div style={{ font: "28px/36px Futura Hv BT", marginTop: "45px" }}>
                        <MuvHotelReviews />
                    </div>}


            </div>
            {open ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(false)}>{"less reviews"}</div>
                : (hasGoogleReviews ? <div style={{ color: "#0D3A7D", font: "18px/26px Futura Md BT", textAlign: "center", marginTop: "30px", cursor: "pointer", }} onClick={() => setOpen(true)}>{"more reviews"}</div> : "")
            }
        </div >
    )
}

const mapStateToProps = (state) => ({
    reviewsGoogleAndYelp: state.FormBank.HotelSearch.establishment.reviewsGoogleAndYelp,
    reviewsFromApi: state.FormBank.HotelSearch.establishment.reviewsFromApi,
    establishment: state.FormBank.HotelSearch.establishment,
})

const mapDispatchToProps = {

}

const MuvHotelEstablishmentReviews = connect(mapStateToProps, mapDispatchToProps)(MuvHotelEstablishmentReviews_)
export default MuvHotelEstablishmentReviews
