import * as Actions from '../../../redux/actions'

import {
    CameraRetroIcon,
    LeftArrowIcon,
    RightArrowIcon,
} from '../Common/SVGBank';
import {
    EmailAddressLoginTextField,
    EmailAddressTextField,
    FirstNameTextField,
    HandleTextField,
    HometownAirportTextField,
    HometownTextField,
    LastNameTextField,
    PasswordConfirmTextField,
    PasswordEye,
    PasswordEyeLogin,
    PasswordLoginTextField,
    PasswordTextField,
} from "../Common/FormComponentsBank"
import {
    bioAutocompleteUseStyles,
    registerHandleUseStyles,
    registerPasswordUseStyles,
    registerTextFieldUseStyles,
} from "../Common/ComponentsStylingBank"

import React from 'react'
import { connect, useDispatch, useSelector } from "react-redux"
import { landing_router } from "../Common/RegExValidate"
import { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom"
import { environment } from '../../../Environments/environment';
import { Check } from '@material-ui/icons';
import { createTripThunk, toggleAiTripLoading, updateSavedTrip } from '../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { clearTimelineTrip } from '../../../redux/actions';

const RegisterForm_ = ({
    setSwitchProfileContainer,
    handleTextField,
    screenSize,
    photoAvatar,
    switch_container,
    setProfilePhotoAvatar,
    authModal,
    uuid,
    lang,
    token = authModal.token,
    registerMemberAccount,
    emailAddressTextField,
    emailAddressLoginTextField,
    firstNameTextField,
    lastNameTextField,
    passwordTextField,
    passwordLoginTextField,
    setRouteAuthModal,
    loginMemberAccount,
    airportTextField,hometownTextField,
    alertBar,
    is_mobile
}) => {

    const history = useHistory();
    const isAiPage = history?.location?.pathname?.includes("ai")
    const [homeTown, setHomeTown] = React.useState()
    const [homeAirport, setAirport] = React.useState()


    const navigateToLanding = () => {
        history.push("/landing")
    }

    const navigateToRegister = () => {
        history.push("/register")
    }

    const navigateToLogin = () => {

        history.push(isAiPage ? "/login/ai" : "/login")
    }

    const [imageFile, setImageFile] = useState(null)
    const [captcha, setCaptcha] = useState()
    let avatarFileInput = React.useRef(null)
    let camera_top = "50px"
    let camera_size = 30
    let avatar_height = 140
    let screen_at_1200 = 1200
    let arrow_icon_size = 19
    let arrow_left_padding = "0"
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "230px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left = "10"
    let padding_l_r = "15"
    let padding_l_login = "0"
    let padding_l_20 = "20"
    let avatar_container_width = 140
    let col = "col-6"
    let eye_top = "45%"
    let eye_right = "55%"
    let eye_top_confirm = "45%"
    let eye_right_confirm = "30px"
    let link_top_register_login = "80"

    let padding_left_handle = "35"
    if (!isNaN(screenSize)) {
        padding_left_handle = screenSize < screen_at_1200 ? "10" : "35"
        camera_top = screenSize < screen_at_1200 ? "30px" : "50px"
        camera_size = screenSize < screen_at_1200 ? 20 : 30
        avatar_height = screenSize < screen_at_1200 ? 90 : 140
        avatar_container_width = screenSize < screen_at_1200 ? 90 : 140
        padding_l_r = screenSize > screen_at_1200 ? "15" : "10"
        padding_l_login = screenSize > screen_at_1200 ? "0" : "10"
        padding_l_20 = screenSize > screen_at_1200 ? "20" : "0"
        arrow_left_padding = screenSize > screen_at_1200 ? "0" : "10"
        arrow_icon_size = screenSize > screen_at_1200 ? 19 : 10
        arrow_font = screenSize > screen_at_1200 ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
        arrow_container_width = screenSize > screen_at_1200 ? "230px" : "120px"
        arrow_container_height = screenSize > screen_at_1200 ? "60px" : "30px"
        arrow_container_padding_left = screenSize > screen_at_1200 ? "35" : "10"
        col = screenSize > screen_at_1200 ? "col-6" : "col-12"
        eye_top = screenSize > screen_at_1200 ? "45%" : "42%"
        eye_right = screenSize > screen_at_1200 ? "55%" : "30px"
        eye_top_confirm = screenSize > screen_at_1200 ? "45%" : "70%"
        eye_right_confirm = screenSize > screen_at_1200 ? "30px" : "30px"
        link_top_register_login = screenSize > screen_at_1200 ? "80" : "55"
    }
    const classesTextField = registerTextFieldUseStyles();
    const classesPassword = registerPasswordUseStyles();
    const classesAutocomplete = bioAutocompleteUseStyles()
    const classHandle = registerHandleUseStyles();
    const savedPersonalTrip = useSelector(state => state.PersonalizedTrip.aiTrip.saved)
    const [creating, setCreating] = React.useState(false)
    const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
    const stayTripData = useSelector(state => state.PersonalizedTrip.stayTripData)
    const dispatch = useDispatch()

    const srcAvatar = photoAvatar.isSelected ? photoAvatar.selectedFilePath : "https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/user_avatar/armando.jpg?func=crop&w=" + avatar_height + "&h=" + avatar_height



    const handleRegister = (email, first_name, last_name, password, handle, imageFile) => {
        registerMemberAccount(email, first_name, last_name, password, handle, imageFile,airportTextField?.airportSelected,hometownTextField?.hometownSelectedPostRequired)
        setSwitchProfileContainer(landing_router.interests)
    }
    const handleLogin = (emailAddressOrUsername, password) => {
        loginMemberAccount(emailAddressOrUsername, password, true)
    }

    const onChangeCaptcha = (value) => {
        setCaptcha(value)
    }

    React.useEffect(() => {
        if (authModal && authModal?.memberStatus === "1") {
            generateTrip()
        }
    }, [authModal])



    const generateTrip = async () => {

        if (uuid && savedPersonalTrip) {
            setCreating(true)
            dispatch(toggleAiTripLoading(true))
            dispatch(clearTimelineTrip()) ///remove any active trip

            if (savedPersonalTrip?.destinations && savedPersonalTrip?.destinations?.length > 0) {
                let destinations = [...savedPersonalTrip?.destinations];
                destinations = destinations?.map((dest) => {
                    return {
                        id: dest?.id,
                        duration: dest?.duration,

                    }
                })

                const tripPayload = {
                    ...savedPersonalTrip,
                    destinations: destinations,
                    uuid: uuid
                }
                const newTrip = await dispatch(createTripThunk(tripPayload));
                const addedTrip = newTrip.payload;

                const newTripId = addedTrip?.tripID || addedTrip?.id;


                if (addedTrip && newTripId) {
                    const tripDestinationId = addedTrip?.tripDestinations &&
                        addedTrip?.tripDestinations?.length > 0 ? addedTrip?.tripDestinations[0]?.id : null;


                    dispatch(updateSavedTrip(null))
                    setTimeout(() => {
                        setCreating(false)
                        dispatch(toggleAiTripLoading(false))
                        const url = "/muver/trippage/" + newTripId
                        history.push(url.toLowerCase())
                    }, 2000);


                }
                else {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }

                setTimeout(() => {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }, 10000);
            }

        }
    }

    const [canEditProfile, setEditProfile] = React.useState(false);
    let handleRef = React.useRef(null);

    let errorInputLogin = emailAddressLoginTextField.emailAddressOrUsername === ""
        || emailAddressLoginTextField.error
        || passwordLoginTextField.password === ""
        || passwordLoginTextField.error

    let errorInputRegister = emailAddressTextField.emailAddress === ""
        || emailAddressTextField.error
        || firstNameTextField.firstName === ""
        || firstNameTextField.error
        || lastNameTextField.lastName === ""
        || lastNameTextField.error
        || passwordTextField.password === ""
        || passwordTextField.error


    return (
        <div className="ml-0 mr-0 margin_top_20">
            {authModal.route === "Login" &&
                <div>
                    <div className={"justify-content-center ml-0 mr-0 text-center"} >
                        <div className={"margin_top_20"}>
                            <div style={{
                                marginTop: "10px",
                                paddingLeft: padding_l_login + "px",
                                paddingRight: padding_l_r + "px",
                            }}>
                                <div className={classesTextField.root}>
                                    <EmailAddressLoginTextField
                                        disabled={token !== undefined && token !== ""}
                                        label="Email Address or Username"
                                        required={true}
                                        id="email_address_login"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="position-relative">
                            <div style={{
                                marginTop: "10px",
                                paddingLeft: padding_l_login + "px",
                                paddingRight: padding_l_r + "px",
                            }}>
                                <div className={classesPassword.root}>
                                    <PasswordLoginTextField
                                        disabled={token !== undefined && token !== ""}
                                        label="Password"
                                        required={true}
                                        id="password_login"
                                    />
                                </div>
                            </div>

                            <div className="position-absolute text-right d-inline-block"
                                style={{
                                    right: "8%",
                                    top: "40%",
                                }}>
                                <PasswordEyeLogin />
                            </div>
                        </div>
                    </div>
                    <div className="position-relative margin_top_20">
                        <div onClick={() => navigateToLanding()}
                            style={{
                                cursor: "pointer",
                                backgroundColor: "#E8E8E8",
                                paddingLeft: arrow_container_padding_left + "px",
                                height: arrow_container_height,
                                width: arrow_container_width,
                                marginTop: "10px",
                                display: "block",
                                position: "absolute",
                                border: "0",
                                left: arrow_left_padding + "px",
                                // padding-left: 5px;
                            }}>
                            <div style={{
                                textAlign: "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                position: "absolute",
                                top: "50%",
                                msTransform: "translateY(-50%)",
                                transform: "translateY(-50%)",
                                color: "#707070",
                            }}>
                                <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: "20px" }} />   Previous Step
                            </div>
                        </div>
                        <div
                            onClick={token !== undefined && token !== "" ? () => setSwitchProfileContainer(landing_router.interests)
                                : (errorInputLogin ? () => alertBar("warning", "Please fill Login form completely.") :
                                    () => handleLogin(
                                        emailAddressLoginTextField.emailAddressOrUsername,
                                        passwordLoginTextField.password))
                            }
                            style={{
                                cursor: errorInputLogin ? "" : "pointer",
                                backgroundColor: "var(--mainGreen)",
                                paddingLeft: arrow_container_padding_left + "px",
                                height: arrow_container_height,
                                width: arrow_container_width,
                                marginTop: "10px",
                                display: "block",
                                position: "absolute",
                                border: "0",
                                right: padding_l_r + "px",
                                // padding-left: 5px;
                            }}>
                            <div style={{
                                textAlign: "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                position: "absolute",
                                top: "50%",
                                msTransform: "translateY(-50%)",
                                transform: "translateY(-50%)",
                                color: "var(--mainWhite)",
                            }}>
                                {token !== undefined && token !== "" ? "Next Step" : "Sign In"}  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "40px" }} />
                            </div>
                        </div>
                        <div style={{
                            textAlign: "right",
                            font: arrow_font,
                            letterSpacing: "0",
                            margin: "0",
                            color: "var(--mainGreen)",
                            paddingLeft: padding_l_r + "px",
                            paddingRight: padding_l_r + "px",
                            paddingTop: link_top_register_login + "px",
                        }}>
                            {token !== undefined && token === "" && <div onClick={token !== undefined && token !== "" ? () => { } : () => navigateToRegister()} style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}>create an account</div>
                            }
                        </div>
                    </div>
                </div>

            }
            {authModal.route === "Register" &&
                <div>
                    {isAiPage && <div style={{
                        marginLeft: is_mobile ? 0 : 0,
                        textAlign: is_mobile ? "center" : "left"
                    }}>

                        <div style={{
                            marginTop: is_mobile ? 20 : 0,
                            font: "20px/22px Futura Hv BT",
                            marginBottom: 20
                        }}>
                            {switch_container === landing_router.register ? "Create your free account and generate your AI-personalized itinerary" : ""}
                        </div>
                        {switch_container === landing_router.register &&
                            <div>
                                <div style={{
                                    font: "18px/22px Futura Md BT",
                                    marginBottom: 20
                                }}>
                                    Share your travel interests only and get a lifetime of FREE personalized itineraries, handy at all times, all in one place
                                </div>
                                {/* <div style={{
                                    font: "16px/22px Futura Lt BT",
                                    marginBottom: 40
                                }} onClick={generateTrip}>
                                    <u>I prefer to get a generic itinerary without registering</u>
                                </div> */}

                            </div>}

                    </div>}



                    <div className={screenSize > screen_at_1200 ? "d-flex justify-content-center ml-0 mr-0" : "justify-content-center ml-0 mr-0 text-center"} >
                        {!isAiPage && <div className="position-relative margin_top_20 text-center d-inline-block" style={{ width: avatar_container_width, zIndex: "1" }}>
                            <div
                                className="img_fit_cover rounded-circle d-inline-block"
                                style={{
                                    width: avatar_height,
                                    height: avatar_height,
                                    backgroundImage: "url('" + srcAvatar + "')", backgroundRepeat: "no-repeat",
                                    backgroundSize: "100% 100%",
                                }}>
                            </div>
                            <div className="rounded-circle"
                                style={{
                                    width: avatar_height,
                                    height: avatar_height,
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                    position: "absolute",
                                    backgroundColor: "rgba(25, 25, 25, 0.2)",
                                    zIndex: "2",
                                }}>
                            </div>
                            <div className="position-absolute text-center" style={{ zIndex: "3", left: "0px", right: "0px", top: camera_top }}>
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept="image/*"
                                    onChange={event => {
                                        event.target.files !== undefined
                                            && event.target.files.length > 0
                                            && event.target.files[0] !== undefined
                                            && setProfilePhotoAvatar((window.URL || window.webkitURL).createObjectURL(event.target.files[0]), photoAvatar.isSelected, photoAvatar.selectedFilePath, event.target.files[0], token)
                                            && setImageFile(event.target.files[0])
                                    }}
                                    ref={fileInput => avatarFileInput = fileInput}
                                />
                                <div className="d-inline-block" onClick={() => avatarFileInput.click()}>
                                    <CameraRetroIcon width={camera_size} height={camera_size} fill={"#E8E8E8"} />
                                </div>
                            </div>
                            <div className={classHandle.root} style={{

                                position: "relative", width: "100%", textAlign: "center",

                            }}>


                                <HandleTextField
                                    label=""
                                    placeholder="@handle"
                                    required={false}
                                    id="handle"
                                    token={token}
                                    inputRef={handleRef}
                                    useFlex
                                    verifyOnly
                                />



                            </div>
                        </div>}
                        <div className={"flex_grow_1 margin_top_20 padding_left_" + padding_l_20}>
                            <div>
                                <div className={"padding_left_right_" + padding_l_r}>
                                    <div className={classesTextField.root}>
                                        <EmailAddressTextField
                                            disabled={token !== undefined && token !== ""}
                                            label="Email Address"
                                            required={true}
                                            id="email_address"
                                        />
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: "7.5px",
                                    }}>
                                        <div className={classesTextField.root}>
                                            <FirstNameTextField
                                                disabled={token !== undefined && token !== ""}
                                                label="First Name"
                                                required={true}
                                                id="first_name"
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: "7.5px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesTextField.root}>
                                            <LastNameTextField
                                                disabled={token !== undefined && token !== ""}
                                                label="Last Name"
                                                required={true}
                                                id="last_name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 position-relative">
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: "7.5px",
                                    }}>
                                        <div className={classesAutocomplete.root}>
                                            <HometownTextField
                                                label="Home destination"
                                                required={false}
                                                id="hometown"
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: "7.5px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesAutocomplete.root}>
                                            <HometownAirportTextField
                                                label="Home airport"
                                                required={false}
                                                id="hometown_airport"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 position-relative">
                                    <div className={"col-12"} style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: "7.5px",
                                    }}>
                                        <div className={classesPassword.root}>
                                            <PasswordTextField
                                                disabled={token !== undefined && token !== ""}
                                                label="Password"
                                                required={true}
                                                id="password"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className={col} style={{
                                        marginTop: "10px",
                                        paddingLeft: "7.5px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesPassword.root}>
                                            <PasswordConfirmTextField
                                                disabled={token !== undefined && token !== ""}
                                                label="Confirm"
                                                required={true}
                                                id="comfirm"
                                            />
                                        </div>
                                    </div> */}
                                    <div className="position-absolute text-right d-inline-block"
                                        style={{
                                            right: 23,
                                            top: eye_top,
                                        }}>
                                        <PasswordEye />
                                    </div>
                                    {/* <div className="position-absolute text-right d-inline-block"
                                        style={{
                                            right: eye_right_confirm,
                                            top: eye_top_confirm,
                                        }}>
                                        <PasswordEye />
                                    </div> */}
                                </div>

                                <div className={"padding_left_right_" + padding_l_r}>
                                    <div className="registerCaptcha">

                                        <ReCAPTCHA
                                            sitekey={environment.captchaKey}
                                            onChange={onChangeCaptcha}
                                        />
                                    </div>
                                </div>

                            </div >
                        </div>
                    </div>

                    <div className="position-relative margin_top_20">
                        <div onClick={() => navigateToLanding()}
                            style={{
                                cursor: "pointer",
                                backgroundColor: "#E8E8E8",
                                paddingLeft: arrow_container_padding_left + "px",
                                height: arrow_container_height,
                                width: arrow_container_width,
                                marginTop: "10px",
                                display: "block",
                                position: "absolute",
                                border: "0",
                                left: arrow_left_padding + "px",
                                // padding-left: 5px;
                            }}>
                            <div style={{
                                textAlign: "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                position: "absolute",
                                top: "50%",
                                msTransform: "translateY(-50%)",
                                transform: "translateY(-50%)",
                                color: "#707070",
                            }}>
                                <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: "20px" }} />   Previous Step
                            </div>
                        </div>



                        <Button disabled={captcha || (token !== undefined && token !== "") ? false : true}
                            onClick={token !== undefined && token !== "" ? () => setSwitchProfileContainer(landing_router.interests)
                                : (errorInputRegister ? () => alertBar("warning", "Please fill registration form completely.") :
                                    () => handleRegister(
                                        emailAddressTextField.emailAddress,
                                        firstNameTextField.firstName,
                                        lastNameTextField.lastName,
                                        passwordTextField.password,
                                        handleTextField.handle,
                                        imageFile))
                            }
                            style={{
                                cursor: errorInputRegister ? "" : "pointer",
                                backgroundColor: "var(--mainGreen)",
                                paddingLeft: arrow_container_padding_left + "px",
                                height: arrow_container_height,
                                width: arrow_container_width,
                                marginTop: "10px",
                                display: "block",
                                position: "absolute",
                                borderRadius: "0px",
                                border: "0",
                                right: padding_l_r + "px",
                                // padding-left: 5px;
                            }}>
                            <div style={{
                                textAlign: "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                position: "absolute",
                                top: "50%",
                                msTransform: "translateY(-50%)",
                                transform: "translateY(-50%)",
                                color: "var(--mainWhite)",
                            }}>
                                {token !== undefined && token !== "" ? "Next Step" : "Register"} <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "40px" }} />
                            </div>
                        </Button>
                        <div style={{
                            textAlign: "right",
                            font: arrow_font,
                            letterSpacing: "0",
                            margin: "0",
                            color: "var(--mainGreen)",
                            paddingLeft: padding_l_r + "px",
                            paddingRight: padding_l_r + "px",
                            paddingTop: link_top_register_login + "px",
                        }}>
                            {token !== undefined && token === "" && <div onClick={token !== undefined && token !== "" ? () => { } : () => navigateToLogin()} style={{
                                display: "inline-block",
                                cursor: "pointer",
                            }}>already a member? login</div>
                            }
                        </div>

                    </div>
                </div>
            }

        </div >

    );
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    photoAvatar: state.FormBank.photoAvatar,
    switch_container: state.Member.profile_container,
    handleTextField: state.FormBank.handleTextField,
    /* AUTH */
    authModal: state.Member.authModal,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    emailAddressLoginTextField: state.FormBank.emailAddressLoginTextField,
    emailAddressTextField: state.FormBank.emailAddressTextField,
    firstNameTextField: state.FormBank.firstNameTextField,
    lastNameTextField: state.FormBank.lastNameTextField,
    passwordLoginTextField: state.FormBank.passwordLoginTextField,
    passwordTextField: state.FormBank.passwordTextField,
    hometownTextField: state.FormBank.hometownTextField,
    airportTextField: state.FormBank.homeTownAirportTextField,
    is_mobile: state.Setting.is_mobile,
})
const mapDispatchToProps = {
    registerMemberAccount: Actions.registerMemberAccount,
    loginMemberAccount: Actions.loginMemberAccount,
    setRouteAuthModal: Actions.setRouteAuthModal,
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    setProfilePhotoAvatar: Actions.setProfilePhotoAvatar,
    uploadProfilePhotoAvatar: Actions.uploadProfilePhotoAvatar,
    alertBar: Actions.alertBar,
}
const RegisterForm = connect(mapStateToProps, mapDispatchToProps)(RegisterForm_);
export default RegisterForm
