export const environment = {
    googleTrackingId: "UA-110030893-1",
    apiUrl: "https://devapi.muvtravel.com/",
    prodUrl: "https://muvtravel.com",
    captchaKey : "6Lcelm0UAAAAAGNIh7hkACU0IUzCFXtwRTU33b3h",
    gtmId:"GTM-PD94HZR3",
    pricelineApiUrl: "http://api-sandbox.rezserver.com/",
    pricelineRefId: "8660",
    pricelineApiKey: "ce7272cb256e1c225bb8e55ddc64c0ff",
    blackPageUrl: "https://muv.travel/",
    hjid:3617850,
    hjsv:6,
    production: false,
    isBlackPage: false, 
}
// https://api.muvtravel.com/api/public/ ---prod