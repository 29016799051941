import * as Actions from '../../../redux/actions'
import Rating from '@material-ui/lab/Rating';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux'
import { priceline_image_boxing, loadingImage } from '../Common/RegExValidate'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { Image } from 'react-bootstrap';

const MuvBookingItems_ = ({ formIndex, booking_bundle, booking_bundles, showMultiple = true, booking_info, establishment, is_mobile, removeBundleFromHotelBooking }) => {
 

    const cartData = useSelector(state => state.HotelBooking.cart.data)
    const cartItem = cartData?.items?.length > 0 ? cartData?.items[0] : null
    const cartItemHotel = cartItem?.check_in_json?.muvHotel;
    const cartCheckInInfo = cartItem?.check_in_json;
    const cartRoomInfo = cartItem?.room_json;

    var date1 = new Date(cartCheckInInfo?.check_in);
    var date2 = new Date(cartCheckInInfo?.check_out);
    var timeDiff = Math.abs(date2.getTime() - date1.getTime());

    var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));



  
   


    const BundleItems = () => {
        return (<React.Fragment>
            <table style={{ border: "1px solid #E8E8E8", marginBottom: 15, width: "100%" }} ><tbody>

                <tr >
                    <td colSpan="2">
                        {/* {
                            false && <div onClick={() => handleRemoveRoomBooking()}
                                style={{ cursor: "pointer", paddingRight: "1rem", textAlign: "right", font: "20px/30px Futura Md BT", color: "#dadada", }}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>} */}
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <div style={{ paddingLeft: "2rem", font: "18px/22px Futura Hv BT", color: "#000000", marginBottom: "5px", marginTop: 15 }}>{cartItemHotel?.property_name}</div>
                    </td>
                </tr>

                <tr>
                    <td colSpan="2">
                        <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Hv BT" : "14px/16px Futura Hv BT", color: "#707070", marginBottom: "10px", }}>{cartRoomInfo?.title}</div>
                        {/* <div style={{ paddingLeft: "2rem", font: is_mobile ? "12px/18px Futura Lt BT" : "12px/16px Futura Lt BT", color: "#707070", marginBottom: "15px", }}>
                            {booking_bundle.is_cancellable == 1 && <span>Cancellable, </span>}
                            {booking_bundle.is_cancellable == 0 && <span>Non-Cancellable, </span>}
                            {booking_bundle.details_summary.freeRefundableFlag == 1 && <span>Refundable</span>}
                            {booking_bundle.details_summary.freeRefundableFlag == 0 && <span>Non-Refundable</span>}
                        </div> */}
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem" }} >Check-in:</div>
                    </td>
                    <td >
                        <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", textAlign: "right", color: "#000000", }}>{moment(cartCheckInInfo?.check_in).format('MMMM Do, yyyy')}</div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "12px/18px Futura Lt BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 5, paddingBottom: 12 }} >Check-out:</div>
                    </td>
                    <td >
                        <div style={{ font: is_mobile ? "12px/18px Futura Hv BT" : "12px/18px Futura Hv BT", paddingRight: "2rem", paddingTop: 5, paddingBottom: 12, textAlign: "right", color: "#000000", }}>{moment(cartCheckInInfo?.check_out).format('MMMM Do, yyyy')}</div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#000000", backgroundColor: "#F5F7F6", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Room price:</div>
                    </td>
                    <td >
                        <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{cartRoomInfo?.rates?.display_symbol}{Number(cartRoomInfo?.rates?.display_sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/night</div>
                    </td>
                </tr>


                <tr style={{ font: is_mobile ? "14px/16px Futura Md BT" : "14px/16px Futura Md BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingBottom: 10, paddingLeft: "2rem", paddingTop: 10 }} >{cartCheckInInfo?.rooms} room(s) x {numberOfNights} night(s):</div>
                    </td>
                    <td >
                        <div style={{ paddingBottom: 10, paddingRight: "2rem", paddingTop: 10, textAlign: "right", color: "#000000", }}>{cartRoomInfo?.rates?.display_symbol}{Number(cartRoomInfo?.rates?.display_sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </td>
                </tr>

                <tr style={{ font: is_mobile ? "12px/16px Futura Md BT" : "12px/16px Futura Md BT", color: "#707070", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >Taxes and fees:</div>
                    </td>
                    <td >
                        <div style={{ paddingRight: "2rem", paddingTop: 10, paddingBottom: 10, textAlign: "right", color: "#000000", }}>{cartRoomInfo?.rates?.display_symbol}{Number(cartRoomInfo?.rates?.tax).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </td>
                </tr>








                <tr style={{ font: is_mobile ? "14px/18px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#000000", backgroundColor: "#F5F7F6", }}>
                    <td >
                        <div style={{ paddingLeft: "2rem", paddingTop: 10, paddingBottom: 10 }} >TOTAL <span style={{ font: "16px/16px Futura Lt BT", }}> (Pay now):</span></div>
                    </td>
                    <td >
                        <div style={{ paddingRight: "2rem", textAlign: "right", color: "#000000", }}>{cartRoomInfo?.rates?.display_currency} {cartRoomInfo?.rates?.display_symbol}{Number(cartRoomInfo?.rates?.display_all_in_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </td>
                </tr>



            </tbody></table>


        </React.Fragment>)
    }

    return (
        <div style={{ verticalAlign: "top" }}>
            <BundleItems />
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
    booking_info: state.FormBank.BookingPriceline.booking_info,
    //booking_bundles: state.FormBank.BookingPriceline.booking_bundles
})

const mapDispatchToProps = {
    removeBundleFromHotelBooking: Actions.removeBundleFromHotelBooking
}

const MuvBookingItems = connect(mapStateToProps, mapDispatchToProps)(MuvBookingItems_)
export default MuvBookingItems
