import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { validate, validate_fields } from "../Common/RegExValidate"

import Banner from '../Common/Banner.jsx'
import HotelListingsContainer from "./HotelListingsContainer"
import Header from '../Header/Header'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "../SocialPage/MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound'
import PrivateTripEstablishment from "../Common/Trip/PrivateTripEstablishment"
import Profile from '../Common/Profile/ProfileBar.jsx'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from "../Common/RegExValidate"
import { HeaderMobile } from '../Header/HeaderMobile'
import HotelItineraryMap from '../Common/Trip/HotelItineraryMap'

const HotelListingsPage_  =({preload, is_mobile,fetchActiveTripFromCookies,setSwitchFeatureTypeContainerOfFeature,setSwitchContainer,fetchTypesAndSubtypesFeature,setPageYOffset,setAppMenuBookingButtonStatus,setTripDisplayMode, switch_container, setScreenSize, tripMode,setBodyClientSize,setAppMenu, destinaionListDisplayFormat,lang })=> {
    /* update screen size */
     /* update screen size */
     const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        setAppMenu(document.body.clientWidth)
    }

    const setDefaultTypeTag = (types) => {  //see type default
        if (types) {
            var defaultType = types.find(obj => {
                return obj.typeID === "1"
            })
            if (defaultType) {
                const type = {
                    typeID: defaultType.typeID
                }
                setSwitchFeatureTypeContainerOfFeature(1, lang, types, type)
            }
        }
    }


    /* initial component */
    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()
        setSwitchContainer(member_area_router.hotelSearch)

        setPageYOffset(0)

        setAppMenuBookingButtonStatus(true)
        setTripDisplayMode(list_container_mode.timeline)
        fetchActiveTripFromCookies("", lang, false)

        fetchTypesAndSubtypesFeature("1", lang, setDefaultTypeTag, true)

        return () => {
            window.removeEventListener("resize", updateDimensions)
        }

    }, [])



        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>
                    {preload ? <Loader
                        type="Rings"
                        color="#19BC9B"
                        className="se-pre-con"
                        height={150}
                        width={150}
                        style={{
                            backgroundColor: "#FFFFFF"
                        }} /> : ""}

                    {switch_container === member_area_router.hotelSearch &&

                        <React.Fragment>
                            {tripMode === list_container_mode.schedule && <TripComtainer />}
                            {tripMode === list_container_mode.calendar && <TripComtainer />}

                            {tripMode === list_container_mode.map && <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />}
                            {tripMode === list_container_mode.map && <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />}
                            {tripMode === list_container_mode.map && window.google !== undefined && <HotelItineraryMap />}

                            {tripMode === list_container_mode.timeline &&
                                <>

                                    {!is_mobile ? <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} /> : <HeaderMobile useTransparent bg_color={"transparent"} text_color={"var(--mainWhite)"} />}
                                </>
                            }
                            {tripMode === list_container_mode.timeline && <TimelineWizardContainer listDisplayFormat={member_area_router.booking} />}
                            {tripMode === list_container_mode.timeline && <HotelListingsContainer />}

                        </React.Fragment>
                    }

                </div>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    
}


const mapStateToProps = state => {
    return {
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        //establishmentListDisplayFormat: state.FormBank.HotelSearch.establishmentListDisplayFormat,
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,

        is_mobile: state.Setting.is_mobile,
        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
        //hotelSearchForm: state.hotelSearchForm,
    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setPageYOffset: Actions.setPageYOffset,

    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature,

}

const HotelListingsPage = connect(mapStateToProps, mapDispatchToProps)(HotelListingsPage_)
export default HotelListingsPage
