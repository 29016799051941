import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import ClipFilterType from "./ClipFilterType"
import { connect, useSelector } from 'react-redux'
import { URL_REPLACE } from '../Common/RegExValidate'
import { useHistory,useLocation } from "react-router-dom"
const ClipFilter = connect((state) => ({
    selectedSubtypes: state.FormBank.HotelSearch.selectedSubtypes,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    //establishments: state.FormBank.HotelSearch.establishments,
    establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
    copyHotelEstablishments: state.FormBank.HotelSearch.copyHotelEstablishments,
}), {
    cleanSelectedSubTypeHotels: Actions.cleanSelectedSubTypeHotels,
    clearHotelFilters:Actions.clearHotelFilters,
    getPriceLineHotels: Actions.getPriceLineHotels,
})(({
    selectedSubtypes, 
    cleanSelectedSubTypeHotels, 
    hotel_form_state, 
    switchFeatureContainer, 
    establishmentsToFilter,
    copyHotelEstablishments,
    hotelSearchForm,
    getPriceLineHotels,lazyLoading,
    clearHotelFilters
}) => {
    const history = useHistory()
    const location = useLocation()
    let  [,setState]=React.useState();

    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency =  selectedCurrency ? selectedCurrency.toUpperCase(): "CAD";



    const handleCleanSelectedSubTypeHotels = (establishmentsToFilter, hotel_form_state) => {
        const pathName = location?.pathname;
        const search = location?.search;
        const url = pathName + search;

        // clearHotelFilters(copyHotelEstablishments)
       
        cleanSelectedSubTypeHotels(copyHotelEstablishments, hotel_form_state)
        // setState({});
    

        // if(pathName && search){
        //     getPriceLineHotels(hotel_form_state, lazyLoading,fullCurrency)
        // }
       

    
    }        


    
    return (
        <div className="row margin_left_30 m-0" style={{ flexGrow: "1", paddingTop: "15px" }}>
            {
                selectedSubtypes !== undefined
                && selectedSubtypes.length > 0
                && selectedSubtypes.map((subtype, subtype_idx) => { return <ClipFilterType key={subtype_idx} element={subtype} /> })
            }
            {
                selectedSubtypes !== undefined
                && selectedSubtypes.length > 0
                && establishmentsToFilter!==undefined
                && establishmentsToFilter.length > 0
                && <div onClick={() => handleCleanSelectedSubTypeHotels(establishmentsToFilter, hotel_form_state)}
                    style={{
                        color: switchFeatureContainer.color,
                        cursor: "pointer",
                        font: "14px/18px Futura Md BT",
                        paddingTop: "10px",
                        paddingLeft: "10px",
                    }}>clear all</div>
            }
        </div>
    )
})

export default ClipFilter
