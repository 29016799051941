import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { DurationLayover, secondsToHms } from "../../../../utility/utility"
import { convertObjArray } from "./FlightManagementList"
import './FlightManagement.css';

export const FlightRecapComponent = ({ flightData, title,isReturn }) => {
    const flightDataArray = convertObjArray(flightData.flight_data)
    var windowWidth = window.screen.width < window.outerWidth ?
    window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500; 
    return (
        <Grid container style={{marginTop:0}} >
           <div style={{ paddingBottom: 20, paddingLeft: mobile? 0:50, paddingTop: 30 }}>
                <span style={{ font: "24px/24px Futura Hv BT", paddingRight: mobile?0:20, paddingLeft: 40 }}>{title} </span>
                <span style={{ font: mobile? "16px/16px Futura LT BT": "24px/24px Futura LT BT", textTransform: 'capitalize' }}>{flightData.departure.airport.code}-{flightData.arrival.airport.code} </span>
                <span style={{ font:  mobile? "12px/12px Futura LT BT": "18px/18px Futura LT BT", textTransform: 'capitalize' }}>({secondsToHms(flightData.info.duration)})</span>

            </div>
            { flightData && flightDataArray.map((flight, flightIndex) => {

                let DataComponent = [];
                const previous = flightIndex !== 0 && flightDataArray.length !== 1 ? flightDataArray[flightIndex - 1] : null;

                DataComponent.push(
                    <FlightRecapItem key={flightIndex + "flight"} index={flightIndex} flight={flight} flightData={flightData} previous={previous} />
                )

                return DataComponent;
            })}
        </Grid>

    )
}

export const FlightRecapItem = ({ flight, index, flightData, previous }) => {
    const flightDataArray = convertObjArray(flightData.flight_data)
    const departureDate = moment(flight.departure.datetime.date).format('ddd, MMM. Do');
    const arrivalDate = moment(flight.arrival.datetime.date).format('ddd, MMM. Do');

    var windowWidth = window.screen.width < window.outerWidth ?
    window.screen.width : window.outerWidth;
var mobile = windowWidth < 500;

    return (
        <Grid container spacing={0} style={{ paddingTop: 10}} >

            { previous && flightDataArray.length > 1 &&
                <LayoverComponent key={index + "layover"} flight={flight} previous={previous} />}
            
            <Grid container spacing={0} style={{ paddingLeft: mobile?30:50 }} >

            <Grid item lg={2} xs={12} md={12} style={{ paddingBottom: 10, textAlign: mobile? 'left':'center' }} >
                    <div style={{ font: "14px/14px Futura Md BT", lineHeight: 1.6 }}>  {departureDate} </div>
                    {index === flightDataArray.length - 1 && <div style={{ font: "12px/12px Futura LT BT", lineHeight: 1.6, color: 'red' }}> Lands {arrivalDate}  </div>}
                </Grid>

                <Grid item lg={2} style={{ textAlign: 'center', maxWidth: 60, paddingBottom: 10, }}  >
                    <img src={flightData.airline.logo} height={25} alt="logo" style={{}} />
                </Grid>

                <Grid item lg={7} xs={12} sm={12} md={12} style={{ textAlign: 'left', paddingBottom: 10, font: "14px/14px Futura Md BT" }}  >


                    <div style={{ lineHeight: 1.4 }}> {flight.departure.datetime.time_12h} -  {flight.arrival.datetime.time_12h}</div>
                    <div style={{ lineHeight: 1.4, marginBottom: 7 }}>  {flight.departure.airport.city} ({flight.departure.airport.code}) - {flight.arrival.airport.city} ({flight.arrival.airport.code}) </div>
                    <div style={{ lineHeight: 1.6, color: '#7F7F7F' }}> {flight.info.marketing_airline} {flight.info.flight_number} - {flight.info.aircraft_type} - {flight.info.aircraft} </div>

                </Grid>

                <Grid item lg={1} xs={12} sm={12} md={12} style={{ paddingBottom: 10 }} >
                    <div style={{ font: "14px/14px Futura Md BT", lineHeight: 1.6 }}>  {secondsToHms(flight.info.duration)} </div>
                    <div style={{ font: "14px/14px Futura Md BT", color: '#7F7F7F', lineHeight: 1.6 }}>  {flight.info.cabin_name} </div>

                </Grid>

            </Grid>
        </Grid>
    )
}

export const LayoverComponent = ({ flight, previous }) => {
    const notes = previous?.info?.notes;
    return (
        <Grid container spacing={0} style={{ background: '#F4F9FC', width: '100%', padding: 10, paddingLeft: 50, marginBottom: 20 }} >

            <Grid item lg={2} xs={12} md={12} >
            </Grid>
            <Grid item lg={2} style={{ maxWidth: 60 }}  >
            </Grid>

            <Grid item lg={7} xs={6} sm={6} md={6} style={{ textAlign: 'left', font: "14px/14px Futura LT BT" }}  >
            {notes &&
                Object.keys(notes).map(key => {
                    const val = notes[key]

                    return (
                        <p style={{ marginTop: 5,lineHeight: 1.4 , marginBottom: 5, width: "100%", font: "14px/14px Futura  Lt BT" }}> {val?.value}</p>
                    )
                })
            }
            </Grid>

            <Grid item lg={1} xs={6} sm={6} md={6} style={{ paddingLeft: 7 }} >
                <div style={{ font: "14px/14px Futura LT BT", lineHeight: 1.6 }}>  {DurationLayover(previous.arrival.datetime.date_time, flight.departure.datetime.date_time)} </div>
            </Grid>

        </Grid>
    )
}

export const FlightConfirmed = ({ pnr, booking_status }) => {
    var windowWidth = window.screen.width < window.outerWidth ?
    window.screen.width : window.outerWidth;
var mobile = windowWidth < 500;
    return (
        <div>
            <div className="flightMngCode" style={{ background: "#0D3A7D", height: "auto", color: 'white', font: mobile? "20px/20px Futura Hv BT":"30px/30px Futura Hv BT", padding: "18px 85px", marginBottom: 15 }}>
                Your booking is {booking_status}
            </div>
            <div className="flightMngCode" style={{ background: "#E8E8E8", height: "auto", color: '#000000', font: mobile? "18px/18px Futura Md BT": "24px/24px Futura Md BT", padding: "20px 85px", marginBottom: 15 }}>
                Booking Code
                <span
                    style={{ background: "#19BC9B", height: 24, margin: 84, color: 'white', font: "24px/24px Futura Hv BT", padding: "6px 15px", }}
                >{pnr}</span>
            </div>
        </div>
    )
}


export const FlightCancel = ({ cancel, voidDate }) => {
    return (
        <Grid item className="flightMngBody"   style={{ border: '1px solid #DFDFDF', marginBottom: 15, width: '100%', padding: "20px 50px" }}>
            <span
                style={{ color: '#000000', font: "20px/20px Futura Md BT" }}
            >
                Cancellation policy:
            </span>
            <span
                style={{ color: '#000000', font: "20px/20px Futura Lt BT" }}
            >
                This ticket is cancellable {voidDate? `until ${voidDate}`: ''}
               &nbsp;  <a onClick={cancel} style={{ color: "#FF0000", cursor: 'pointer', font: "18px/18px Futura Lt BT" }}>(Cancel now)</a>
            </span>

        </Grid>
    )
}

export const FlightSupport = ({ booking }) => {
    const airlineData = booking?.airline_data ? convertObjArray(booking?.airline_data) : null;

    const booking_id = booking?.booking_id;
    const pnr = booking?.pnr_locator;

    const ticketNumbers = getTicketNumbers(booking?.passengers);

    return (
        <Grid item className="flightMngBody"  style={{ marginBottom: 15, width: '100%', padding: "20px 50px" }}>

            {airlineData && airlineData.length > 0
                && airlineData.map((airline, index) => {
                    return (
                        <div key={airline?.name} style={{ paddingBottom: 20 }} >
                            <div
                                style={{ color: '#000000', font: "20px/20px Futura Md BT", paddingBottom: 30 }}
                            >
                                {airline?.name} Support:
                        </div>

                            {/* <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Contact {airline?.name} for help with the following
                                </div>

                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Check-in for your flight <br />
            Add baggage<br />
            Reserve meals <br />
            Select or change seats
                </div> */}
                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Ticket numbers: {ticketNumbers}
                            </div>
                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Confirmation number: {airline?.carrierLocator}
                            </div>

                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Frequent flyer program: {airline?.frequentFlyerProgram}
                            </div>

                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Contact number: {airline?.phoneNumber}
                            </div>
                            <div
                                style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                            >
                                Website: <a target="_blank" href={airline?.websiteUrl}>
                                    {airline?.websiteUrl} </a>
                            </div>


                        </div>
                    )
                })}

            <div style={{ paddingBottom: 20 }} >
                <div
                    style={{ color: '#000000', font: "20px/20px Futura Md BT", paddingBottom: 30 }}
                >
                    Priceline Support:
                        </div>

                <div
                    style={{ color: '#707070', font: "20px/20px Futura Lt BT", marginBottom: 10 }}
                >
                    Contact Priceline at 1-866-878-8777 if you need help managing this itinerary. <br />
                Itinerary number: {booking_id}
                </div>
            </div>

        </Grid>
    )
}



export const getTicketNumbers = (passengers) => {
    let allTickets = [];
    const passengerList = convertObjArray(passengers);
    if (passengerList && passengerList?.length > 0) {
        passengerList.forEach((passenger) => {
            const tickets = convertObjArray(passenger?.ticket_numbers);

            if (tickets && tickets?.length > 0) {
                tickets.forEach((ticket) => {
                    allTickets = [...allTickets, ticket]
                })
            }

        })
    }

    return allTickets.join();
}