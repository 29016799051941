import * as Actions from '../../../redux/actions'

import { member_area_router, validate, validate_fields } from "../Common/RegExValidate"

import Header from '../Header/Header.jsx'
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import React from 'react'
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'
import ResetPasswordContainer from './ResetPasswordContainer'

class ResetPasswordPage_ extends React.Component {

    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.props.setSwitchContainer(member_area_router.starred)
        this.updateDimensions()
        this.props.setAppMenuBookingButtonStatus(true)
    }
    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }
    render() {
        const { preload, switch_container, lang, tripMode } = this.props
        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>

                    <React.Fragment>
                        <Header bg_color={"#FFFFFF"} text_color={"var(--frameBlack)"} />
                        {/* <StarredTopBar/> */}
                        <ResetPasswordContainer />
                    </React.Fragment>


                </div >
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        switch_container: state.Member.switch_container,
        preload: state.Setting.preload,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
    }
}

const mapDispatchToProps = {

    setSwitchContainer: Actions.setSwitchContainer,
    setBodyClientSize: Actions.setBodyClientSize,
    setScreenVertical: Actions.setScreenVertical,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
}

const ResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage_)
export default ResetPasswordPage 
