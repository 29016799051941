import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { validate, validate_fields } from "../Common/RegExValidate"

import BioSection from './BioSection'
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import Onboarding from './Onboarding'
import PageNotFound from '../ErrorPage/PageNotFound.jsx'
import PersonalizationSection from './PersonalizationSection'
import ProfileSection from './ProfileSection'
import { PropTypes } from 'prop-types'
import { Redirect } from 'react-router-dom'
import RegisterSection from './RegisterSection'
import TravelLikesSection from './TravelLikesSection'
import { connect } from 'react-redux'
import { landing_router } from "../Common/RegExValidate"
import { member_area_router } from "../Common/RegExValidate"
import { useLocation } from 'react-router-dom';
import YourTastesSection from './YourTastesSection'

/*  landing page component */
 const RegisterPage_ = ({ props, setRouteAuthModal,setScreenSize,preload,setAppMenu,setBodyClientSize,lang,setSwitchProfileContainer, switch_container, screenSize,setSwitchContainer }) =>{

    const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setAppMenu(document.body.clientWidth)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
    }

    const location = useLocation();
    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()
        setSwitchProfileContainer(landing_router.register)
        setSwitchContainer(member_area_router.landing)
        if(location.pathname && location?.pathname?.includes("login") ){
           setRouteAuthModal("Login")
        }else{
            setRouteAuthModal("Register")
        }
        return () => {
            window.removeEventListener("resize", updateDimensions)
        }
    }, [location])
        let containerH = 150
        if (!isNaN(screenSize)) {
            const imageHeight = screenSize / 7
            containerH = (imageHeight > 200 ? 200 : (imageHeight < 120 ? 120 : imageHeight))
        }
        return (
            /* check validation invalide goes to page not found */
            validate(lang, validate_fields.languages) ?
                <React.Fragment>
                    {
                        false ? <Loader
                            type="Rings"
                            color="#19BC9B"
                            className="se-pre-con"
                            height={150}
                            width={150}
                            style={{ backgroundColor: "#FFFFFF" }} /> : ""

                    }
                    <div style={{ backgroundColor: "#FFFFFF", height: "auto", paddingBottom: containerH }}>


                    <React.Fragment>
                            {switch_container === landing_router.onboarding && <Onboarding />}
                            {switch_container === landing_router.register && <RegisterSection />}
                            {switch_container === landing_router.interests && <TravelLikesSection />}
                            {switch_container === landing_router.tastes && <YourTastesSection />}
                            {switch_container === landing_router.bio && <BioSection />}
                            {switch_container === landing_router.profile && <ProfileSection />}
                            {switch_container === landing_router.personalization && <PersonalizationSection />}
                        </React.Fragment>
                    </div>
                    <MemberActionsInitial />
                </React.Fragment>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }

RegisterPage_.propTypes = {
    screenSize: PropTypes.number.isRequired,
}
/* Get screenSize  */
const mapStateToProps = (state) => ({

    preload: state.Setting.preload,
    switch_container: state.Member.profile_container,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,

})

/* Set screenSize landing page conainer  */
const mapDispatchToProps = {
    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVertical: Actions.setScreenVertical,
    setBodyClientSize: Actions.setBodyClientSize,
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    setRouteAuthModal: Actions.setRouteAuthModal,
    setSwitchContainer: Actions.setSwitchContainer,
}

const RegisterPage = connect(mapStateToProps, mapDispatchToProps)(RegisterPage_)
export default RegisterPage
