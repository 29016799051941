import * as Actions from '../../../redux/actions'
import Rating from '@material-ui/lab/Rating';
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { priceline_image_boxing, loadingImage, URL_REPLACE } from '../Common/RegExValidate'
import moment from 'moment'
import AddedToTrip from '../Common/AddedToTrip'
import TagManager from 'react-gtm-module';
import {
    AngleUpIcon,
    PlusSignIcon,
    EnvelopeIcon,
    InfoCircleIcon,
    MapMarkerIcon,
    WebsiteIcon,
    RightArrowIcon,
    LeftArrowIcon
} from '../Common/SVGBank'
import CircularProgress from '@material-ui/core/CircularProgress'
import { HotelFlightSaveModal } from '../FlightsPage/RecapPage/FlightSaveModal';


import { Image } from 'react-bootstrap'

import { useHistory } from "react-router-dom"
import { saveHotelTimeline } from './PaymentPage/payment.thunk';
import { setOpenAuthModal } from '../../../redux/actions';
import { getNearByDestinationCode } from '../FlightsPage/services/Flights.thunks';
import { ALERT_BARS_HANDLE, SET_TIMELINE_WIZARD_STATUS } from '../../../redux/interfaces/types';
import { updateStayTripData } from '../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { encryptID } from '../HotelPage/hotelServices';

const BookingBasicInfo_ = ({ hideButtons, booking_info, bookingPriceline, lang, uuid, booking_bundles, establishment, is_mobile,
    addPricelineHotelToMuvDb, activeTripActivities, hotelSearchForm, timelineWizardStatus,
    isFetchingEstablishments, activeTrip, activeTripDestinationId, activeTripDay, destinationId, }) => {
    const { generalInfo, subtypes } = establishment
    const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
    const country = generalInfo?.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo?.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const city = generalInfo?.cityName !== undefined && generalInfo.cityName !== null && generalInfo.cityName !== "" ? generalInfo.cityName + ", " : ""
    const state = generalInfo?.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""
    const history = useHistory()
    const [bookingHotel, setBookHotel] = React.useState(false);
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("rate");
    const useSmop = promoCode && promoCode?.toLowerCase() === "smop";
    const isBookingPage = document?.location?.pathname?.includes("booking");

    const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentID)
    //console.log({activeTripActivities, availableDeleteActivities});


    const handleAddHotelToTripDestinationInTrip = (establishment, hotel_form_st, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        //console.log("handleAddHotelToTripDestinationInTrip hotel_form_st ",hotel_form_st);
        //console.log("handleAddHotelToTripDestinationInTrip hotelSearchForm ",hotelSearchForm);
        const lengthOfStay = moment(hotelSearchForm.check_out).diff(moment(hotelSearchForm.check_in), "days")
        const establishmentPricelineID = establishment.generalInfo.establishmentPricelineID

        console.log({
            establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
            activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
            tripDuration, destinationId, activeTripDestinationIds
        })

        addPricelineHotelToMuvDb({
            establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
            activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
            tripDuration, destinationId, activeTripDestinationIds
        })
        //fetchHotelEstablishment(hotel_id, ppn_bundle, hotelSearchForm)
        //addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)
        //document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    const imgWidth = 168
    const authModal = useSelector(state => state.Member.authModal);
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);

    const handleHotelSearchForm = () => {
        let hotel_form_state = { ...hotelSearchForm }
        console.log(hotel_form_state);
        //setHotelSearchForm(hotel_form_state);

        // open new page with params


        const encryptedId =  encryptID( hotel_form_state.city_id)
        history.push("/hotel-search?city_id=" +encryptedId 
            + "&check_in=" + hotel_form_state.check_in
            + "&check_out=" + hotel_form_state.check_out
            + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
            + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
            + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
        // http://host/hotel-search?city_id=800049480&check_in=2020-02-02&check_out=2020-02-13&room=1&guests=2  

    }
    const dispatch = useDispatch()



    React.useEffect(() => {

        // if (arrivalCode) {
        //     dispatch(getNearByDestinationCode(arrivalCode));
        // }

        //    dispatch(getFlightContractThunk())
    }, [])


    const goToDestination = () => {
        if (nearByDestination) {
            const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
            history.push(url.toLowerCase())
            ///window.open(url.toLowerCase());
        }
    }

    const isLoggedIn = () => {
        return authModal.uuid && authModal?.uuid?.length > 0;
    }

    const [showSaveModal, setSaveModal] = React.useState(false);

    const toggleSaveModal = () => {
        setSaveModal(!showSaveModal)

        if (showSaveModal) {
            setBookHotel(false)
        }
    }


    const saveBooking = () => {
        if (isLoggedIn()) {
            if (activeTripId && timelineWizardStatus) { ///timeline open
                addToTimeline(activeTripId)
            }
            else if (activeTripId && !timelineWizardStatus) {  //timeline closed
                toggleSaveModal()
            }
            else {
                addToTimeline(null)
            }

        } else {
            dispatch(setOpenAuthModal(true));
        }
    }



    const addToTimeline = async (tripId) => {

        const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0] : null;
        if (booking) {
            const saveData = {
                postData: {
                    tripId: tripId,
                    pricelineHotelId: booking_info?.hotel_id || "",
                    booking_trip_number: "",
                    booking_status: "",
                    booking_status_code: "",
                    checkIn: booking_info.check_in || "",
                    checkOut: booking_info.check_out || "",
                    displaySymbol: booking?.rate_symbol,
                    adults: hotelSearchForm?.adults,
                    displayCurrency: booking?.rate_currency,
                    displayTotalFare: booking?.rate_total
                },
                uuid: uuid || null
            }


            const response = await dispatch(saveHotelTimeline(saveData));

            //only for hotel
            if (bookingHotel) {
                pushGtmTag()
                history.push('/hotel/payment')
            }
            else {
                // const destinationID = response?.payload?.result?.destinationId;
                // const destinationName = response?.payload?.result?.destinationName;
                // const tripId = response?.payload?.payload?.result?.tripID

                ///open when saving


                // dispatch({
                //     type: SET_TIMELINE_WIZARD_STATUS,
                //     status: true
                // })

                const destination = response?.payload?.result?.targetDestination;
                const destName = destination?.partialUri || destination?.nameInline;
                const destId = destination?.destinationId;




                if (destName && destId) {
                    setTimeout(() => {
                        const url = "/destination/" + destName + "/" + 1 + "/" + destId;
                        history.push(url)
                    }, 1000);
                }
            }

            setBookHotel(false);

        }
    }

    const pushGtmTag = () => {
        const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0] : null;
        const tagManagerArgs = {
            dataLayer: {
                'js': new Date(), // current date time,
                event: 'InitiateCheckout',
                pricelineHotelId: booking_info?.hotel_id || "",
                booking_trip_number: "",
                booking_status: "",
                booking_status_code: "",
                checkIn: booking_info.check_in || "",
                checkOut: booking_info.check_out || "",
                displaySymbol: booking?.rate_symbol,
                displayCurrency: booking?.rate_currency,
                displayTotalFare: booking?.rate_total
            }
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    const navigateBooking = (destinationName, destinationID) => {
        // setBookFlight(false)
        if (destinationName && destinationID) {
            const url = "/destination/" + destinationName + "/" + 1 + "/" + destinationID;
            history.push(url)
        }


    }



    //handle book clicked
    React.useEffect(() => {
        if (bookingHotel) {
            if (isLoggedIn()) {

                if (activeTripId && timelineWizardStatus) { ///timeline open
                    addToTimeline(activeTripId)
                }
                else if (activeTripId && !timelineWizardStatus) { //timeline closed
                    toggleSaveModal()

                }
                else { ///no timeline
                    addToTimeline(null)
                }

            }
            else {
                // addToTimeline(null)
                history.push('/hotel/payment')
                pushGtmTag()
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "error",
                    message: "You must log in to save this"
                })
            }
        }
    }, [bookingHotel])

    const bookHotel = async () => {
        setBookHotel(true);
    }

    const priceDetails = booking_info?.price_details;
    const rate = booking_info?.room_data?.rate_data && booking_info?.room_data?.rate_data?.length > 0 ? booking_info?.room_data?.rate_data[0] : null
    const prepaidFees = booking_info?.price_details?.mandatory_fee_details?.breakdown?.prepaid?.breakdown ?? null;
    const postPaidFees = booking_info?.price_details?.mandatory_fee_details?.breakdown?.postpaid?.breakdown ?? null;


    const amenityLength = rate?.rate_amenity_data.length;
    const hasBreakfast = rate?.rate_amenity_data && rate?.rate_amenity_data?.includes("FREE BREAKFAST" || "Free Breakfast")
    const amenities = rate?.rate_amenity_data && hasBreakfast ? rate?.rate_amenity_data?.filter((item) => item?.toLowerCase() !== "free breakfast") : rate?.rate_amenity_data;

    const prepaidTotal = priceDetails?.mandatory_fee_details?.breakdown?.prepaid?.display_total || 0
    const prepaid = rate?.payment_type === "PREPAID"
    const postPaid = rate?.payment_type === "POSTPAID"
    const postPaidTotal = priceDetails?.mandatory_fee_details?.breakdown?.postpaid?.display_total || 0

    const cachedHotel = useSelector(state => state.HotelBooking.cachedHotel?.data);
    const useCached = (useSmop && isBookingPage) && cachedHotel; //disable
    const cachedSubtotal = cachedHotel && useCached ? cachedHotel?.display_sub_total : null;

    const prepaidCached = useCached && cachedHotel?.payment_type === "prepaid";
    const postpaidCached = useCached && cachedHotel?.payment_type !== "prepaid";
    const prepaidCachedTotal = cachedHotel && prepaidCached ? Number(cachedHotel?.display_sub_total || 0) + Number(cachedHotel?.prepaid_display_total || 0) : 0;
    const cachedTotal = cachedHotel && useCached ? cachedHotel?.prepaid_display_total ? Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.prepaid_display_total) : Number(cachedHotel?.display_sub_total) + Number(cachedHotel?.postpaid_display_total) : null;



    return (<div>
        <div style={{ border: "1px solid #E8E8E8", marginRight: is_mobile ? 0 : "25px", padding: "25px", paddingBottom: 30, }}>
            {booking_info !== undefined && booking_info !== null && booking_info.name !== undefined && booking_info.name !== undefined && booking_info.name !== null && booking_info.name !== "" && <div>
                <div style={{ float: "right", display: "inline-block" }}>
                    <div style={{ font: is_mobile ? "14px/14px Futura Lt BT" : "18px/21px Futura Lt BT", textAlign: "center" }}>{Number(booking_info.reviewsAverage).toFixed(1)}/10</div>
                    <div style={{ font: is_mobile ? "14px/14px Futura Lt BT" : "18px/21px Futura Lt BT", textAlign: "center" }}>({booking_info.rating_count} reviews)</div>
                </div>
                <div style={{ font: is_mobile ? "18px/18px Futura Hv BT" : "24px/24px Futura Hv BT", color: "#000000", marginBottom: 0, }}>{booking_info.name}
                    <span className=" timeline_rating_color padding_left_10 padding_right_10 btn_rounded" style={{ marginBottom: "5px", }}>
                        <span style={{ font: "16px/19px Futura Md BT", color: "#707070" }}><Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(booking_info.star_rating)} disabled={true} /> {"(" + booking_info.star_rating + ")"}</span>
                    </span></div>
                <div style={{ font: is_mobile ? "12px/22px Futura Md BT" : "18px/24px Futura Md BT", color: "#707070", marginBottom: "15px" }}>{booking_info.address}, {city + state + country + postalCode}</div>


                <div style={{ width: "100%", marginTop: 10 }}>
                    <div style={{ display: "inline-block", width: imgWidth, marginTop: "1rem", marginRight: "2rem", marginBottom: "1rem" }}>
                        <Image style={{ width: "100%", objectFit: "cover", objectPosition: "center", width: imgWidth, height: imgWidth }}
                            src={booking_info !== undefined && booking_info !== null ?
                                priceline_image_boxing(booking_info.estabPicture, imgWidth, imgWidth) :
                                loadingImage.src + "?func=crop&w=" + Math.floor(imgWidth) + "&h=" + Math.floor(imgWidth)}
                        />
                    </div>
                    <div style={{ display: "inline-block", minWidth: 210, width: is_mobile ? "100%" : "calc(100% - 200px)", verticalAlign: "top", marginTop: 10 }}>
                        <div style={{ borderBottom: "1px solid #E8E8E8", paddingBottom: 10, marginBottom: 10 }}>
                            <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "var(--mainBlack)", marginBottom: 10 }}>{booking_info.num_nights} night(s), {booking_info.num_rooms} room(s)</div>
                            <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "var(--mainBlack)", marginBottom: 10 }}>
                                <div style={{ width: "8rem", display: "inline-block" }} >Check-in:</div>
                                <div style={{ font: is_mobile ? "14px/18px Futura Md BT" : "20px/24px Futura Md BT", display: "inline-block", color: "var(--mainBlack)", }}>{moment(booking_info.check_in).format('ddd, MMMM Do, yyyy')}</div>
                            </div>
                            <div style={{ font: is_mobile ? "12px/18px Futura Lt BT" : "18px/24px Futura Lt BT", color: "var(--mainBlack)", }}>
                                <div style={{ width: "8rem", display: "inline-block" }} >Check-out:</div>
                                <div style={{ font: is_mobile ? "14px/18px Futura Md BT" : "20px/24px Futura Md BT", display: "inline-block", color: "var(--mainBlack)", }}>{moment(booking_info.check_out).format('ddd, MMMM Do, yyyy')}</div>
                            </div>

                        </div>

                        <div style={{ borderBottom: "1px solid #E8E8E8", paddingBottom: 10, marginBottom: 10 }}>
                            <div style={{ font: is_mobile ? "18px/22px Futura Hv BT" : "20px/32px Futura Md BT", color: "var(--mainBlack)", marginBottom: 5 }}>{booking_info.num_rooms && Number(booking_info.num_rooms) > 1 ? `Rooms(1-${Number(booking_info.num_rooms)}):` : "Room 1:"}</div>
                            <div style={{ font: is_mobile ? "18px/22px Futura Hv BT" : "18/32px Futura Md BT", color: "var(--mainBlack)", marginBottom: 10 }}>{booking_info.room_text}{booking_info?.occupancyLimit ? <span style={{ font: "18px/22px Futura Lt BT" }}> (max occupancy: {booking_info?.occupancyLimit})</span> : ""}</div>
                            <div style={{ display: "flex", font: "14px/16px Futura Lt BT", color: "var(--mainBlack)", marginBottom: 5, gap: 2 }}>
                                {hasBreakfast && <div><span>Breakfast incl., </span></div>}
                                {rate.refund_type && <div><span > {rate.refund_type === "NONE" ? "non-cancellable" : "cancellable"}, </span></div>}
                                {rate.refund_type && <div><span> {rate.refund_type === "NONE" ? "non-refundable" : "refundable"}</span></div>}
                            </div>

                        </div>

                        <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "15px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                            <div style={{}}>
                                Price ({booking_info.num_rooms} room(s) x {booking_info.num_nights} night(s)):
                            </div>

                            <div style={{ textAlign: "right" }}>
                                {booking_info.rate_symbol}{Number(useCached ? cachedSubtotal : priceDetails?.display_sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>

                        </div>

                        {Number(prepaidTotal) > 0 && !useCached && <div>
                            {(prepaidFees?.length === 1) ?
                                <div>
                                    {prepaidFees?.map((fee, index) => {
                                        return (
                                            <div key={index + "fee"} style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "3px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                                <div style={{ font: "16px/16px Futura Md BT", }}>
                                                    {fee?.name}<span style={{ font: "16px/16px Futura Lt BT", }}>:</span>
                                                </div>

                                                <div style={{ textAlign: "right" }}>
                                                    {booking_info?.rate_symbol}{Number(fee?.display_total)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>

                                            </div>)
                                    })}
                                </div> :
                                <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                    <div style={{}}>
                                        Taxes and Fees:
                                    </div>

                                    <div style={{ textAlign: "right" }}>
                                        {booking_info.rate_symbol}{Number(prepaidTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>

                                </div>}
                        </div>}


                        {useCached && cachedHotel?.prepaid_display_total &&
                            <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                <div style={{}}>
                                    Taxes and Fees {useCached && cachedHotel?.payment_type === "prepaid" && <span style={{ font: "16px/16px Futura Lt BT", }}>(Payable at the property)</span>}:
                                </div>

                                <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                    {booking_info.rate_symbol}{Number(cachedHotel?.prepaid_display_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>

                            </div>
                        }




                        {/* <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                            <div style={{}}>
                                Taxes and Fees:
                            </div>

                            <div style={{ textAlign: "right" }}>
                            {booking_info.rate_symbol}{Number(priceDetails?.display_taxes).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>

                        </div> */}

                        <div style={{ marginTop: 40, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                            <div style={{ font: "16px/16px Futura Md BT", }}>
                                TOTAL<span style={{ font: "16px/16px Futura Lt BT", }}> {prepaid || (useCached && cachedHotel?.payment_type === "prepaid") ? `(Pay now)` : '(Pay later)'}:</span>
                            </div>

                            <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                <span style={{ font: "13px/16px Futura Md BT" }}>{priceDetails?.display_currency}</span>{priceDetails?.display_symbol}{Number(prepaidCached ? prepaidCachedTotal : postpaidCached ? cachedSubtotal : prepaid ? priceDetails?.display_total : priceDetails?.display_sub_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>

                        </div>

                        {Number(postPaidTotal) > 0 && !useCached && <div>
                            {(postPaidFees?.length === 1) ?
                                <div>
                                    {postPaidFees?.map((fee, index) => {
                                        return (
                                            <div key={index + "fee"} style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "3px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                                <div style={{ font: "16px/16px Futura Md BT", }}>
                                                    {fee?.name}{prepaid && <span style={{ font: "16px/16px Futura Lt BT", }}>(Payable at the property)</span>}:
                                                </div>

                                                <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                                    {booking_info?.rate_symbol}{Number(fee?.display_total)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </div>

                                            </div>)
                                    })}
                                </div> :
                                <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                    <div style={{}}>
                                        Taxes and Fees {prepaid && <span style={{ font: "16px/16px Futura Lt BT", }}>(Payable at the property)</span>}:
                                    </div>

                                    <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                        {booking_info.rate_symbol}{Number(postPaidTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>

                                </div>}
                        </div>}


                        {useCached && cachedHotel?.postpaid_display_total &&
                            <div style={{ marginTop: 10, justifyContent: "space-between", marginBottom: "10px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                                <div style={{}}>
                                    Taxes and Fees {useCached && cachedHotel?.payment_type === "prepaid" && <span style={{ font: "16px/16px Futura Lt BT", }}>(Payable at the property)</span>}:
                                </div>

                                <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                    {booking_info.rate_symbol}{Number(cachedHotel?.postpaid_display_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>

                            </div>
                        }


                        <div style={{ marginTop: 20, justifyContent: "space-between", marginBottom: "3px", font: "16px/16px Futura Lt BT", color: "var(--mainBlack)", display: "flex" }}>
                            <div style={{ font: "16px/16px Futura Md BT", }}>
                                Your total cost <span style={{ font: "16px/16px Futura Lt BT", }}>(inclusive of all taxes and fees):</span>
                            </div>

                            <div style={{ textAlign: "right", font: "16px/16px Futura Md BT" }}>
                                <span style={{ font: "13px/16px Futura Md BT" }}>{priceDetails?.display_currency}</span>{priceDetails?.display_symbol}{Number(cachedTotal ? cachedTotal : priceDetails?.display_all_in_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </div>

                        </div>





                    </div>
                </div>




            </div>}
        </div>

        {!hideButtons && <div style={{ paddingRight: "25px", paddingTop: 35, marginBottom: 40 }}>

            {hotelSearchForm !== undefined && hotelSearchForm !== null && hotelSearchForm.city_id !== "" && <div style={{ marginBottom: 20 }}>
                <button className='recapButtons' style={{ background: is_mobile ? 'transparent' : '#DFDFDF', width: is_mobile ? "48%" : "200px" }} onClick={() => {
                    history.goBack()
                }}>
                    <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  {'Back to rooms'}
                </button>


                <div style={{ float: "right" }}>
                    <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT', width: is_mobile ? "180px" : "200px" }} onClick={bookHotel}>
                        {'Book'} <RightArrowIcon height={14} width={14} style={{ marginLeft: is_mobile ? 40 : 90 }} />
                    </button>

                </div>
                <div onClick={saveBooking}
                    style={{ textAlign: "right", cursor: "pointer", color: "#000000", font: "14px/14px Futura Lt BT", padding: "10px 10px", }}>
                    Not ready to book yet, <u>save this hotel in your trip</u></div>

            </div>}

            {/* {hotelSearchForm !== undefined && hotelSearchForm !== null && hotelSearchForm.city_id !== "" && <div onClick={() => handleHotelSearchForm()}
                style={{ cursor: "pointer", font: "14px/60px Futura Lt BT", backgroundColor: '#DFDFDF', color: "#7F7F7F", display: "inline-block", width: 202, height: 60, padding: "1px 10px 10px 0px" }} > <LeftArrowIcon width={15} height={15} fill={"#7F7F7F"} style={{ marginRight: 13, marginLeft: 20, color: "#7F7F7F" }} />Back to search results</div>}

            {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                <div style={{
                    cursor: "pointer", float: "right", display: "inline-block",
                    marginTop: is_mobile ? 27 : 0
                }}>

                    <div onClick={() => {
                        bookHotel()
                    }}
                        style={{ cursor: "pointer", marginLeft: "auto", marginBottom: 10, font: "14px/60px Futura Hv BT", textAlign: "left", backgroundColor: 'var(--mainGreen)', color: "white", width: 202, height: 60, padding: "2px 5px 10px 29px" }} >Book <RightArrowIcon width={15} height={15} style={{ marginLeft: "96px" }} /></div>

                   {<div onClick={saveBooking}
                        style={{ float: "right", cursor: "pointer", color: "#000000", font: "14px/14px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                        Not ready to book yet, <u>save this hotel in your trip</u></div>}
                </div>} */}
            <HotelFlightSaveModal navigateBooking={navigateBooking} showModal={showSaveModal} toggleModal={toggleSaveModal} addToTimeline={addToTimeline}
                type={"hotel"} />

            {timelineWizardStatus && <div style={{ textAlign: "right", fontSize: ".8rem", }}>
                {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                    <AddedToTrip
                        establishmentName={establishment.establishmentName}
                        establishmentStarRating={establishment.establishmentStarRating}
                        availableDeleteActivities={availableDeleteActivities}
                        textFont={"14px/14px Futura Hv BT"}
                        text={"Added to trip"}
                    />}
                {/* {!isFetchingEstablishments ? <React.Fragment>
                        {availableDeleteActivities.length > 0 ?
                            <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} 
                                 style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                                     <PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add hotel again</div>
                            : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} 
                                   style={{ cursor: "pointer", color: "#000000", font: "14px/14px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                                    Not ready to book yet, save this hotel in your trip <PlusSignIcon width={10} height={13} fill={"#000000"} style={{ marginLeft: "5px" }} /></div>
                        }
                    </React.Fragment> : <CircularProgress
                            disableShrink
                            size={15}
                            style={{
                                color: "#6A9EEC",
                                textAlign: "right",
                                marginTop: "15px",
                                marginRight: "5px",
                            }} />
                    } */}
            </div>}
        </div>}
    </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    bookingPriceline: state.FormBank.BookingPriceline,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
    addPricelineHotelToMuvDb: Actions.addPricelineHotelToMuvDb,

}

const BookingBasicInfo = connect(mapStateToProps, mapDispatchToProps)(BookingBasicInfo_)
export default BookingBasicInfo
