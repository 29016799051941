import * as Actions from '../../../../redux/actions';

import React, { Component } from 'react'

import MenuLanding from '../../LandingPage/MenuLanding'
import TravelInterestsForm from "./TravelInterestsForm"
import { connect } from 'react-redux'
import { landing_router } from '../RegExValidate';

class TravelInterests_ extends Component {

    render() {
        const { screenSize, switch_container, app_menu_height, is_mobile } = this.props;

        let position = "sticky"
        let text_height = "85px"
        let screen_at_1200 = 1200
        let padding_8_percent = "8.5%"

        if (!isNaN(screenSize)) {
            text_height = screenSize < screen_at_1200 ? "40px" : "85px"
            padding_8_percent = screenSize < screen_at_1200 ? "2.5%" : "8.5%"
        }
        return (
            <div style={{ padding: padding_8_percent }}>
                {screenSize >= screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        font: "30px/40px Futura Hv BT",
                    }}>
                        {switch_container === landing_router.interests ? "Define your travel personality" :
                            switch_container === landing_router.tastes ? "Share your likes and dislikes" : "Congratulations, you have successfully signed in."}
                    </div>
                    : ""
                }
                <div style={{ position: position, zIndex: "3", top: is_mobile ? app_menu_height : Math.ceil(app_menu_height / 2) }}>
                    <MenuLanding />
                </div>
                {screenSize < screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        marginTop: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        font: "18px/22px Futura Hv BT",
                    }}>
                        {switch_container === landing_router.interests ? "Define your travel personality" :
                            switch_container === landing_router.tastes ? "Share your likes and dislikes" : "Congratulations, you have successfully signed in."}
                    </div>
                    : ""
                }
                <div style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                }}>
                    <TravelInterestsForm />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
    switch_container: state.Member.profile_container,
})

const mapDispatchToProps = {
}
const TravelInterests = connect(mapStateToProps, mapDispatchToProps)(TravelInterests_)
export default TravelInterests
