import {
    RightArrowIcon,
    StarIcon,
} from "../SVGBank"

import { Image } from "react-bootstrap"
import React from 'react'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { image_boxing } from "../RegExValidate"
import { useHistory } from "react-router-dom"
import { encryptID } from "../../HotelPage/hotelServices"

export const MuvFeaturedTrip_ = ({ homepage, flightFormType }) => {
    const history = useHistory()
    const goesTripPage = (tripId, tripName) => {
           const encryptedId = encryptID(tripId)

        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
        history.push(url.toLowerCase())
    }
    return (
        <React.Fragment>
            {flightFormType !== "multi_destination" && (homepage.featuredTrips && homepage.featuredTrips.length > 0) &&
                <div className="col-md-12 featuredSection">
                    <div className={"row mt-3 hidden_1366"}>
                        <div className="col-md-6"></div>
                        <div className="col-md-6 p-0 featuredBlock">
                            <div className="pull-left featured-TextBlock">
                                <h5 className="mt-0 mb-1" style={{ font: "14px/50px Futura Md BT",color: "#000000",fontFamily:"Muli, sans-serif" }}>
                                    <StarIcon fill="var(--mainGreen)" width="15px" height="15px" style={{ marginRight: "5px" }} />
                                    müv Feature trip</h5>
                                <h5 className="dicover-landscape" id="featured_trip_heading" style={{ font: "bold 22px/30px Muli, sans-serif" }}>
                                    {homepage.featuredTrips && homepage.featuredTrips.length > 0 && homepage.featuredTrips[0].tripName ? homepage.featuredTrips[0].tripName : null}
                                </h5>
                                {homepage.featuredTrips !== undefined && homepage.featuredTrips.length > 0 && <div className="dicover-landscape text-left"
                                    onClick={() => goesTripPage(homepage.featuredTrips[0].tripID, homepage.featuredTrips[0].tripName)}
                                    style={{ cursor: "pointer", font: "14px/20px Futura Lt BT", color:  "#000000",fontFamily:"Muli, sans-serif"}}>
                                    Explore more <RightArrowIcon fill="var(--frameBlack)" style={{ marginLeft: "15px " }} />
                                </div>}
                            </div>
                            <div style={{ position: "absolute", right: "0", top: "0", }}>
                                <Image className="min_width_100 grow img_fit_cover"
                                    src={image_boxing(homepage.featuredTrips[0].tripImage, "250", "215")}
                                    alt="featuredTrips" />
                            </div>
                        </div>
                    </div>
                </div>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({

    lang: state.Setting.lang,
    homepage: state.Home.homepage,

})

const mapDispatchToProps = {

}

const MuvFeaturedTrip = connect(mapStateToProps, mapDispatchToProps)(MuvFeaturedTrip_)
export default MuvFeaturedTrip 
