import React from 'react'
import { LeftArrowIcon, RightArrowIcon } from "../../Common/SVGBank"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { Menu } from 'semantic-ui-react';

import { PackageContactForm } from './ContactForm';
import userCircle from '../../../../assets/images/user-circle.png';
import { setOpenAuthModal } from '../../../../redux/actions';
import { PaymentMethodForm } from './PaymentMethodForm';
import { Backdrop, CircularProgress, Link } from '@material-ui/core'

import { FlightExpired } from '../../FlightsPage/flightExpired';
import { PassengerInfo } from './PassengerForm';
import { saveBookingContact, setExpiredFlight } from '../../FlightsPage/services/Flights.slice';
import { bookFlightThunk } from '../../FlightsPage/services/Flights.thunks';
import { PersonalizedModalUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles';
import { bookHotelPackageThunk, bookHotelThunk } from '../../BookingPage/PaymentPage/payment.thunk';


export const PackagePaymentForm = () => {
    const history = useHistory();
    const authModal = useSelector(state => state.Member.authModal);
    const flightAdults = useSelector(state => state.Flights.flightData.numberAdult);
    const flightChildren = useSelector(state => state.Flights.flightData.numberChild)
    const savedFlight =  useSelector(state => state.Flights);
    const passengers = (Number(savedFlight?.selectedFlight?.number_of_passengers) ||Number(flightAdults) + Number(flightChildren) || 1).toString();
    const [creating, setCreating] = React.useState(false)
    const pricelineBooking = useSelector(state => state.FormBank.BookingPriceline);
    const { booking_info, booking_bundles } = pricelineBooking;
    const hotelBookingInfo = booking_bundles && booking_bundles.length > 0 ?booking_bundles : null;

    const [selectedType, setType] = React.useState(1)
    const FormTypes = ['Passenger Info', 'Contact Details', 'Payment Method'];
    const dispatch = useDispatch()
    let [passengerList, setPassengerList] = React.useState(Array(passengers).fill({}));
    let [contactInfo, setContactInfo] = React.useState({});
    let [paymentInfo, setPaymentInfo] = React.useState({});
    const classes = PersonalizedModalUseStyles();


    React.useEffect(()=>{
       if(Number(passengers) > 1){
            const newArray = Array(Number(passengers)).fill({})
            setPassengerList(newArray)
       }
    },[passengers, savedFlight])

    
    const selectType = (value) => {
        setType(value)
    }

    const signIn = () => {
        dispatch(setOpenAuthModal(true));
    }

    const previousStep = () => {
        if (selectedType === 1) {
            history.goBack()
        }
        else {
            selectedType === 2 ? setType(1) : setType(2)
        }
    }

  

    const bookHotels = async (hotelPaymentData)=>{
        await dispatch(bookHotelPackageThunk(hotelPaymentData))
    }

    const nextStep = async () => {
        if (selectedType !== 3) {
            selectedType === 1 ? setType(2) : setType(3)
        }
        else {
            setCreating(true)

            if(hotelBookingInfo && hotelBookingInfo?.length > 0){
               
             
               const hotelBookings= hotelBookingInfo?.forEach((booking)=>{
                const hotelPaymentData = {
                    guestList: passengerList,
                    contactInfo: contactInfo,
                    paymentInfo: paymentInfo,
                    bookingInfo: booking,
                    ppn_book_bundle:booking?.ppn_book_bundle,
                    promoCode: "",
                    specialRequest: ""
                }

                    bookHotels(hotelPaymentData)
                }) ///book all hotels
                
            }

            const paymentData = {
                passenger: passengerList,
                contactInfo: contactInfo,
                paymentInfo: paymentInfo,
            }
            dispatch(saveBookingContact(contactInfo))
            dispatch(bookFlightThunk(paymentData)).then((data) => {
                const payload = data.payload || "";
                if (payload && typeof payload === "string" && payload.includes("ppn_bundle")) {
                    dispatch(setExpiredFlight(true));
                }
            })

           
            
            setCreating(false)
        }
    }



    const isLoggedIn = () => {
        return authModal.uuid;
    }



    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;


    return (
        <div>
            <Backdrop className={classes.backdrop} open={creating} >
                <div style={{
                    font: "22px/18px Futura Lt BT",
                    color: "white",
                    paddingLeft: '0px',
                    marginBottom: '20px',
                    paddingTop: '20px',
                    display: 'inline-grid'
                }}>
                    Generating Trip
                </div>
                <CircularProgress color="inherit" style={{ marginLeft: 40 }} />


            </Backdrop>

            {!isLoggedIn() && <div style={{ display: 'flex' }} >
                <div style={{ flex: 1 }}>
                    <p style={{ font: '14px/14px Futura Md BT' }}> Already a member?
                    </p>
                    <p style={{ color: '#7F7F7F', paddingBottom: 20, font: '14px/14px Futura LT BT' }}> Sign in or register to save your selections and bookings to your account </p>
                </div>

                <div style={{ flex: 0.3, cursor: 'pointer' }} onClick={signIn}>
                    <p style={{ font: '14px/14px Futura Hv BT', textAlign: 'right', color: '#19BC9B' }}>
                        <img src={userCircle} style={{ height: 25, padding: 3, marginRight: 10, marginTop: -5 }} alt='profile icon' />Sign in
                    </p></div>


            </div>}



            <Menu pointing secondary style={{ marginTop: 20, paddingBottom: 35, borderBottom: "10px solid var(--lightGrayF)" }}>
                {FormTypes.map((type, index) => {
                    return (
                        <div key={index}
                            style={{
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                opacity: "1",
                                textAlign: "left",
                                height: "auto",
                                width: "100%",
                                paddingTop: "0px",
                                paddingLeft: index * 20,
                                paddingRight: "15px",
                                paddingBottom: 10,
                                color: selectedType === index + 1 ? "#19BC9B" : '#B1B1B1',
                                borderBottom: selectedType === index + 1 ? '10px solid #19BC9B' : '10px solid var(--lightGrayF)',
                                marginBottom: -48,
                                cursor: "pointer",
                            }}
                            onClick={null}>
                            <p style={{ textAlign: 'left', font: mobile ? "14px/14px Futura Md BT" : "18px/18px Futura Md BT" }}> {index + 1}. {type}</p>
                        </div>
                    )
                })}
            </Menu>

            {selectedType === 1 &&
                passengerList.map((passenger, index) => {
                    return (<PassengerInfo key={index} setPassengerList={setPassengerList} passengerList={passengerList} index={index} passenger={passenger}
                        setContactInfo={setContactInfo} nextStep={nextStep} previousStep={previousStep} />)
                })
            }
            {selectedType === 2 && <PackageContactForm contactInfo={contactInfo} setContactInfo={setContactInfo}
                nextStep={nextStep} previousStep={previousStep} />}
            {selectedType === 3 && <PaymentMethodForm paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo}
                nextStep={nextStep} previousStep={previousStep} />}


            <FlightExpired />
        </div>
    )
}