import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"
import React, { Component } from 'react'

import OtherTripsElement from "../Common/OtherTripsElement"
import { PropTypes } from "prop-types"
import Slider from 'react-slick';
import { connect } from 'react-redux'
import SubDestinationsElement from "./SubdestionationsElement";
import { Link } from "@material-ui/core";
import "./FeaturePage.css"

const SliderArrowRight = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowRightIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-40px)'
        }} />
    </button>
)



const SliderArrowLeft = ({ className, to, onClick }) => (
    <button type="button" onClick={onClick} className={`button button--text button--icon ${className}`} aria-label={to}>
        <ArrowLeftIcon className="icon" icon={to} style={{
            height: "60px",
            width: "60px",
            transform: 'translateY(-40px)'
        }} />
    </button>
)


const SubDestinations_ = ({ switchFeatureContainer, destination, subDestinations, screenSize, generalInfo }) => {

    const destinationName = generalInfo !== undefined && generalInfo !== null ? generalInfo.name : ""
    const display_element_length = screenSize > 1724 ? 5 :
        screenSize > 1500 ? 4 :
            screenSize > 1100 ? 3 :
                screenSize >= 1024 ? 2 :
                    screenSize >= 768 ? 3 :
                        screenSize > 500 ? 1 : 2
    const left_element = subDestinations && subDestinations.length < display_element_length ? "left_element" : ""
    const arrowsValue = subDestinations && subDestinations.length > display_element_length ? true : false
    let settings = {
        accessibility: false,
        dots: false,
        infinite: subDestinations.length > 5,
        arrows: arrowsValue,
        speed: 500,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        pauseOnHover: true,
        centerMode: false,
        prevArrow: <SliderArrowLeft to="prev" />,
        nextArrow: <SliderArrowRight to="next" />,
        responsive: [
            {
                breakpoint: 1724,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
        ]
    }

    const { breadcrumb } = destination
    const destinationData = {
        destination: destination?.generalInfo?.partialUri,
        // id: destination?.generalInfo?.destinationId,
        level: breadcrumb?.length || 4,
        latitude: destination?.generalInfo?.latitude,
        longitude: destination?.generalInfo?.longitude
    }

    const seeAllUrl = new URLSearchParams(destinationData).toString();
    const seeAll = `/suggested-destinations?${seeAllUrl}`


   
    return (
        <div >


            {subDestinations && subDestinations.length > 0 &&

                <div>
                    <h3 style={{font: "24px/24px Futura Hv BT", color: 'var(--frameBlack)', marginTop: 20, paddingLeft: 5, marginBottom: 20 }}> {destinationName} Destinations
                    
                    {<Link href={seeAll} style={{
                        paddingRight: 0,
                        float:'right',
                        font: "16px/19px Futura Md BT", cursor: "pointer", color: switchFeatureContainer.color
                    }}>
                        &nbsp;See All
                  </Link>}
                  </h3>

         

                    <Slider {...settings} className={left_element + " subDestinationSlider"} style={{ marginBottom: 50 }}>

                        {subDestinations.map((destination, idx_destination) => {
                            return (
                                <SubDestinationsElement destination={destination} textColor={"#707070"} key={idx_destination} />
                            )
                        })}
                    </Slider>

                </div>}
        </div>
    )
}




const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        subDestinations: state.DestinationSlice.subDestinations,
        generalInfo: state.Destination.destination.generalInfo,
        destination: state.Destination.destination,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
};


export const SubDestinations = connect(mapStateToProps, null)(SubDestinations_)


