import { Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, Link, Modal, Select } from '@material-ui/core'
import React from 'react'
import { AirportDestination, PersonalizedTripDestination } from '../../../Common/FormComponentsBank';
import { PlusSignIcon, RightArrowIcon, TimesIcon } from '../../../Common/SVGBank'
import { searchDestinationStyles, PersonalizedModalUseStyles, DurationUseStyles, TripStartDateStyles, tripAirportStyles, AiPersonalizedUseStyles, aiDestinationStyles, AIModalUseStyles } from './AiPersonalizedTripStyles';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { createTripThunk, toggleAiModal, toggleAiTripLoading, updateOneclickTripId, updateSavedTrip } from '../../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { getAirportsListThunk, getFlightsListThunk, saveFlightTrip, saveHotelTrip } from '../../../FlightsPage/services/Flights.thunks';

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { updateFlightData } from '../../../FlightsPage/services/Flights.slice';
import moment from 'moment';
import CheckBox from '../../CheckBox';
import { URL_REPLACE } from '../../RegExValidate';
import { clearTimelineTrip, getPriceLineHotels, setTripToTimeline } from '../../../../../redux/actions';
import { GenerateTripHotels } from './AiTrip.services';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';


export const AiPersonalizedTripComponent = () => {
    const [destinations, setDestinations] = React.useState([])
    const [creating, setCreating] = React.useState(false)
    const showModal = useSelector(state => state.PersonalizedTrip.aiTrip.modalOpen)
    const modalData = useSelector(state => state.PersonalizedTrip.aiTrip.data)
    const lang = useSelector(state => state.Setting.lang)
    const is_mobile = useSelector(state => state.Setting.is_mobile)
    const uuid = useSelector(state => state.Member.authModal.uuid)
    const tripId = useSelector(state => state.FormBank.TimelineWizard.activeTrip.tripId);
    const destination = useSelector(state => state.Destination.destination)
    const airportsList = useSelector(state => state.Flights.airportsList)
    const defaultSelected = { destinationId: destination?.activeId, ...destination?.generalInfo, }
    const lazyLoading = useSelector(state => state.FormBank.HotelSearch.lazyLoading)
    const hotelSearchForm = useSelector(state => state.FormBank.HotelSearch.hotel_form_state)
    const bookingForm = useSelector(state => state.FormBank.BookingForm)


    const [limit, setLimit] = React.useState(1);
    const modalDestinations = [...modalData?.destinations];
    const [flightMode, setFlightMode] = React.useState(modalDestinations&& modalDestinations?.length > 2? "multi_destination": "round_trip");
    const [cabinClass, setCabinClass] = React.useState("economy");
    const [airportFrom, setAirportFrom] = React.useState()
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const flightListData = useSelector(state => state.Flights.flightListData);
    const classes = AIModalUseStyles();
    const classesSearchBar = searchDestinationStyles()
    const aiDestinationClass = aiDestinationStyles()
    const dispatch = useDispatch()
    const classesDate = TripStartDateStyles()
    let arrow_icon_size = 15
    let arrow_font = "14px/14px Futura Md BT"
    const classesAirportBar = tripAirportStyles()
    const durationClass = DurationUseStyles()
    const aiTripClass= AiPersonalizedUseStyles()

    const [rooms, setRooms] = React.useState("1");
    const [intensity, setIntensity] = React.useState(5);
    const [tripType, setTripType] = React.useState({
        hotel: true,
        flight: true,
        itinerary: true,
    });
    const [datePicker, setDatePicker] = React.useState(false)

    const addDestination = () => {
        setLimit(prevState => {
            const newLimit = prevState + 1;
            return newLimit;
        })
    }

    const history = useHistory()
    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setDatePicker(false)
    };

    const handleSelectedAirportsFrom = (airports) => {
        if (airports !== undefined) {
            setAirportFrom(airports.length > 0 ? airports[0] : null)
        }
    }

    const handleSearchAirports = (query) => {
        dispatch(getAirportsListThunk(query))
    }


    const handleCabinClassChange = (event) => {
        const value = event.target.value;
        setCabinClass(value)
    }

    const handleIntensityChange = (event) => {
        const value = event.target.value;
        setIntensity(value)
    };

    const handleRoomsChange = (event) => {
        const value = event.target.value;
        setRooms(value)
    };



    const generateTrip = async () => {
        
        let destinations = [...modalData?.destinations];
        destinations = destinations?.map((dest) => {
            return {
                id: dest?.id,
                duration: dest?.duration,

            }
        })

        let trip = {
            density: intensity,
            destinations: destinations,
            lang: lang,
            uuid: uuid,
            tripId: tripId || "",
            tripDate: modalData?.startDate,
            isRoadTrip: modalData?.isRoadTrip,
            haveFlight: false,
            haveHotel: false,
            adults:modalData?.travellers
        }

        if (tripType.flight) {
            trip.flight_class = cabinClass;
            trip.flight_mode = flightMode;
            trip.haveFlight = true;
        }
        if (tripType.hotel) {
            trip.rooms = rooms;
            trip.haveHotel = true;
        }


        if (trip.uuid) {
            setCreating(true)
            dispatch(toggleAiTripLoading(true))
            dispatch(clearTimelineTrip()) ///remove any active trip

            const newTrip = await dispatch(createTripThunk(trip));
            const addedTrip = newTrip.payload;

            const newTripId = addedTrip?.id || addedTrip?.tripID;


            if (addedTrip && newTripId) {
                dispatch(updateOneclickTripId(newTripId))
                dispatch(setTripToTimeline(newTripId, uuid))

                const tripDestinationId = addedTrip?.tripDestinations &&
                    addedTrip?.tripDestinations?.length > 0 ? addedTrip?.tripDestinations[0]?.id : null;


                // if (tripDestinationId && tripType.flight) {
                //     await generateFlight(tripDestinationId)
                // }
                // if (tripDestinationId && tripType.hotel) {
                //     await generateHotels(tripDestinationId)
                // }
                // setCreating(false)

                clearData()
                setTimeout(() => {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                    const url = "/muver/trippage/" + newTripId
                    history.push(url.toLowerCase())
                }, 500);


            }
            else {
                setCreating(false)
                dispatch(toggleAiTripLoading(false))
            }

            setTimeout(() => {
                setCreating(false)
                dispatch(toggleAiTripLoading(false))
            }, 10000);
        }
        else {
            dispatch(updateSavedTrip(trip))
            const url = "/register/ai";
            history.push(url.toLowerCase())
        }


    }



    const changeTripType = (event) => {

        setTripType({ ...tripType, [event.target.name]: event.target.checked });

    }



    // React.useEffect(()=>{
    //     generateHotels(); 
    // },[])

    const generateHotels = async (tripDestinationId) => {
        const destinations = modalData?.destinations;
        const startDate = modalData?.startDate;
        if (destinations && destinations?.length > 1) {
            const destination = destinations[1]?.destination;
            const destinationInfo = destinations[1];
            const hotelInfo = {
                city_id: destination?.cityIdPpn,
                check_in: destinationInfo?.startDateFormat,
                check_out: moment(destinationInfo?.startDateFormat).add(Number(destinationInfo?.duration), "days").format("YYYY-MM-DD"),
                rooms: rooms,
                adults: destinationInfo?.travellers,
                where: destination?.nameInline.replace(URL_REPLACE, "_"),
                city_latitude: destination?.latitude,
                city_longitude: destination?.longitude,
                isSearching: true
            }

            const hotelResult = await GenerateTripHotels(hotelInfo, fullCurrency, 3)

            if (hotelResult) {
                const hotelId = hotelResult?.hotel_data[0]?.id;
                const postData = {
                    tripDestinationId: tripDestinationId,
                    checkIn: destinationInfo?.startDateFormat,
                    checkOut: moment(destinationInfo?.startDateFormat).add(Number(destinationInfo?.duration), "days").format("YYYY-MM-DD"),
                    bookedEmail: "",
                    pricelineHotelId: hotelId,
                    booking_trip_number: "",
                    booking_status: "",
                    booking_status_code: "",
                    uuid: uuid || "TMPUUID",
                }

                dispatch(saveHotelTrip(postData))
            }

        }
    }



    const generateFlight = async (tripDestinationId) => {
        const destinations = modalData?.destinations;
        const startDate = modalData?.startDate;
        if (destinations) {
            let flightCopy = [...destinations];
            // flightCopy.unshift({
            //     destination:modalData?.leavingFrom
            // });

            let flights_selected = []

            if (flightMode === "round_trip") {
                flights_selected.push({
                    airportFrom: flightCopy[0],
                    airportTo: flightCopy[1]?.destination,
                    departureDateFormat: startDate || null,
                    departureDate: startDate || null,
                })
                flights_selected.push({
                    airportFrom: flightCopy[1]?.destination,
                    airportTo: flightCopy[0],
                    departureDateFormat: moment(startDate).add(Number(modalData?.duration), 'days')?.format('YYYY-MM-DD') || null,
                    departureDate: moment(startDate).add(Number(modalData?.duration), 'days')?.format('YYYY-MM-DD') || null,
                })
            }
            if (flightMode === "one_way") {
                flights_selected.push({
                    airportFrom: flightCopy[0],
                    airportTo: flightCopy[1]?.destination,
                    departureDateFormat: startDate || null,
                    departureDate: startDate || null,
                })
            }
            if (flightMode === "multi_destination") {
                flightCopy.map((f, index) => {
                    const from = f?.destination;
                    const nextIndex = index === flightCopy?.length - 1 ? 0 : index + 1
                    const to = flightCopy[nextIndex]?.destination;

                    if (index === 0) {
                        flights_selected.push({
                            airportFrom: f,
                            airportTo: flightCopy[nextIndex],
                            departureDateFormat: startDate || null,
                            departureDate: startDate || null,
                        })
                    }
                    else {
                        const prevIndex = flightCopy[index - 1]
                        flights_selected.push({
                            airportFrom: from,
                            airportTo: to,
                            departureDateFormat: moment(prevIndex?.startDateFormat).add(Number(modalData?.duration), 'days')?.format('YYYY-MM-DD') || null,
                            departureDate: moment(prevIndex?.startDateFormat).add(Number(modalData?.duration), 'days')?.format('YYYY-MM-DD') || null,
                        })
                    }
                })
            }



            const dataToBookingFlights = {
                flightMode: flightMode,
                cabinClass: cabinClass,
                numberAdult: modalData?.travellers,
                numberChild: 0,
                flights: flights_selected,
                currency: fullCurrency,
                limit: 3

            }



            if (flights_selected.length > 0) {
                await dispatch(updateFlightData(dataToBookingFlights))
                const flightListThunk = await dispatch(getFlightsListThunk(dataToBookingFlights))


                try {
                    ///save flight to trip
                    const flightList = flightListThunk?.payload;


                    if (flightList) {
                        let airports = [];
                        const priceDetails = flightList?.itinerary_data?.itinerary_0?.price_details;
                        const airline = flightList?.itinerary_data?.itinerary_0?.slice_data[0]?.airline;
                        const duration = flightList?.itinerary_data?.itinerary_0?.slice_data[0]?.info?.duration;
                        const arrival = flightList?.itinerary_data?.itinerary_0?.slice_data[0]?.arrival;
                        const departure = flightList?.itinerary_data?.itinerary_0?.slice_data[0]?.departure;


                        const airportData = {
                            airline_logo: airline?.logo || "",
                            departure_airport_code: departure?.airport?.code || "",
                            arrival_airport_code: arrival?.airport?.code || "",
                            departure_date: departure?.datetime?.date || "",
                            arrival_date: arrival?.datetime?.date || "",
                            departure_time: departure?.datetime?.time_24h || "",
                            arrival_time: arrival?.datetime?.time_24h || "",
                            flight_duration: duration || ""
                        }
                        airports.push(airportData)
                        const postData = {
                            tripDestinationId: tripDestinationId,
                            airports: airports,
                            displaySymbol: priceDetails?.display_symbol,
                            displayCurrency: priceDetails?.display_currency,
                            displayTotalFare: priceDetails?.display_total_fare,
                            uuid: uuid || "TMPUUID",
                        }

                        dispatch(saveFlightTrip(postData))
                    }

                }
                catch {

                }

            }
        }



    }

    const hasAllValues = () => {
        return intensity && destinations;
    }

    const handleFlightMode = (mode) => {
        setFlightMode(mode)
    }

    const clearData = () => {
        setLimit(1)
        setIntensity(null)
        setDestinations([])
        setSelectedDate(null)
        dispatch(toggleAiModal())
    }




    React.useEffect(() => {
        if (modalData?.leavingFrom) {
            const leaving = modalData?.leavingFrom;
            const prefill = {
                airport: "",
                city: leaving?.name,
                iata: leaving?.destination?.iata || leaving?.iata || "NOIATA",
                cityid_ppn: leaving?.destination?.cityIdPpn || leaving?.cityIdPpn
            }
            setAirportFrom(prefill)
        }
    }, [modalData])


    // const showRoundTrip = modalData?.destinations  &&  (modalData?.destinations?.length < 3 || (modalData?.destinations?.length > 2 && modalData?.destinations[0]?.destinationId === modalData?.destinations[modalData?.destinations?.length-1]?.destinationId));
    const showRoundTrip = modalData?.destinations  &&  (modalData?.destinations?.length < 3 || (modalData?.destinations?.length > 2 && modalData?.destinations[1]?.destinationId === modalData?.destinations[modalData?.destinations?.length-1]?.destinationId));

    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={clearData}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: is_mobile? '45%':'50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'

                }} className={classes.paper}>

                    <Backdrop className={classes.backdrop} open={creating} >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div className="d-flex">
                        <div style={{
                             font: is_mobile?"16px/18px Futura Md BT": "24px/18px Futura Md BT",
                            color: "black",
                            paddingLeft: '0px',
                            paddingBottom: '5px',
                            paddingTop: is_mobile? '10px':'15px',
                            width: "90%",
                            marginRight: "5%",
                            lineHeight: is_mobile?'22px':'32px'
                        }}>
                            Itinerary suggestion for your trip to: {modalData?.destinationText}
                        </div>

                        <div
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={clearData}><TimesIcon style={{ marginTop: "15px" }} width={'20'} height={'20'} /></div>

                    </div>

                    {/* <div style={{
                      font: is_mobile?"16px/18px Futura Md BT": "24px/18px Futura Md BT",
                          color: "black",
                        paddingLeft: '0px',
                        paddingBottom: '5px',
                        paddingTop: '5px',
                    }}>
                        {modalData?.destinationText}
                    </div> */}

                    {modalData?.startDateFormat && <div style={{
                        font: "14px/18px Futura Lt BT",
                        color: "var(--mainGrey)",
                        paddingLeft: '0px',
                        paddingBottom: '5px',
                        paddingTop: '2px',
                    }}>
                        Starting on {modalData?.startDateFormat} for {modalData?.duration} days - {modalData?.travellers} travelers
                    </div>}


                    <div style={{
                        font: "18px/18px Futura Md BT",
                        color: "#191919",
                        paddingLeft: '0px',
                        paddingBottom: '0px',
                        paddingTop: is_mobile?0:'20px',
                        display: "inline-block"
                    }}>

                        {/* <FormControlLabel

                            control={
                                <Checkbox name="itinerary"
                                    checked={tripType.itinerary} onChange={changeTripType} color={"#191919"} style={{ color: "#191919", }} />}
                            label={<span style={{
                                font: "18px/18px Futura Lt BT",
                                color: "#191919",
                            }}>
                                {"Itinerary"}
                            </span>}
                        /> */}



                        {!modalData?.isRoadTrip && <FormControlLabel
                            control={
                                <Checkbox name="flight"
                                    checked={tripType.flight} onChange={changeTripType}
                                    color={"#191919"} style={{ color: "#191919", background: 'white' }}
                                    icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}
                                    checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}
                                />
                            }
                            label={<span style={{
                                font: is_mobile?"14px/18px Futura Md BT": "18px/18px Futura Md BT",
                                color: "black",
                            }}>
                                {"Flight(s)"}
                            </span>}
                        />}

                        <FormControlLabel
                            control={
                                <Checkbox name="hotel"
                                    checked={tripType.hotel}
                                    onChange={changeTripType}
                                    color={"#191919"} style={{ color: "#191919", background: 'white' }}
                                    icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}
                                    checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#000000' }} />}

                                />}
                            label={<span style={{
                                font: is_mobile?"14px/18px Futura Md BT": "18px/18px Futura Md BT",
                                color: "black",
                            }}>
                                {"Hotel(s)"}
                            </span>}
                        />


                    </div>

                    <div style={{
                             font: is_mobile?"16px/18px Futura Md BT": "24px/18px Futura Md BT",
                          color: "black",
                        paddingLeft: '0px',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                    }}>
                        Itinerary
                    </div>


                    <div className="row m-0">
                        <div className={"col-12 col-md-8"} style={{
                            marginTop: is_mobile? 10: "20px",
                            paddingLeft: "0px",
                            paddingRight: "7.5px",
                        }}>
                            <FormControl className={aiTripClass.formControl}>
                                <InputLabel htmlFor="age-native-simple">Intensity</InputLabel>
                                <Select
                                    native
                                    value={intensity}
                                    onChange={handleIntensityChange}
                                    inputProps={{
                                        name: 'intensity',
                                        id: 'intensity',
                                    }}
                                    style={{color:"#B1B1B1"}}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={3}>Relaxed (2-3 activities/day)</option>
                                    <option value={5}>Average (4-5 activities/day)</option>
                                    <option value={7}>Intense (6-7 activities/day)</option>
                                </Select>
                            </FormControl>
                        </div>
                    </div>


                    {(tripType.flight && !modalData?.isRoadTrip) &&
                        <div>
                            <div style={{
                                font: is_mobile?"16px/18px Futura Md BT": "24px/18px Futura Md BT",
                                color: "black",
                                paddingLeft: '0px',
                                paddingBottom: '0px',
                                paddingTop: '20px',
                            }}>
                                Flight
                            </div>

                            <div className="row m-0 p-0 background-img-row">
                                <div className="root-selection text-left col-12 mt-1 mt-md-3 p-0">
                                   
                                    <label className="radio-inline radio-1" onClick={() => handleFlightMode('one_way')}>
                                        <input onChange={(event) => { handleFlightMode(event.target.value) }} value='one_way' type="radio" name="optradio" checked={flightMode === 'one_way'} />One-way
                                        <span className="checkmark"></span>
                                    </label>
                                    {showRoundTrip &&<label className="radio-inline radio-2" onClick={() => handleFlightMode('round_trip')}>
                                        <input onChange={(event) => { handleFlightMode(event.target.value) }} value='round_trip' type="radio" name="optradio" checked={flightMode === 'round_trip'} />Round-trip
                                        <span className="checkmark"></span>
                                    </label>}
                                    {modalData?.destinations && modalData?.destinations?.length > 2
                                        && modalData?.destinations[1]?.destinationId
                                        && <label className="radio-inline radio-3" onClick={() => handleFlightMode('multi_destination')}>
                                            <input onChange={(event) => { handleFlightMode(event.target.value) }} value='multi_destination' type="radio" name="optradio" checked={flightMode === 'multi_destination'} />Multi-destination
                                            <span className="checkmark"></span>
                                        </label>}
                                </div>
                            </div>

                            <div className="row m-0">
                                <div className={"col-12 col-md-8"} style={{
                                    marginTop: "10px",
                                    paddingLeft: "0px",
                                }}>
                                    <div className={aiDestinationClass.root}>
                                        <AirportDestination label={"Leaving from"}
                                            required={true} defaultSelected={airportFrom}
                                            id="search_airport_from" setAirport={setAirportFrom} />
                                    </div>

                                </div>
                                <div className={"col-12 col-md-4"} style={{
                                    marginTop: "10px",
                                    paddingRight: "0px",
                                }}>

                                    <FormControl className={aiTripClass.formControl}>
                                        {/* <InputLabel htmlFor="duration">Duration</InputLabel> */}
                                        <Select
                                            native
                                            value={cabinClass}
                                            onChange={handleCabinClassChange}
                                            inputProps={{
                                                name: 'duration',
                                                id: 'duration',
                                            }}
                                        >
                                            <option aria-label="None" value={null} />
                                            <option value="economy">Economy</option>
                                            <option value="premium">Premium Economy</option>
                                            <option value="business">Business</option>
                                            <option value="first">First</option>

                                        </Select>
                                    </FormControl>

                                </div>
                            </div>
                        </div>}

                    {tripType.hotel &&
                        <div>

                            <div style={{
                                         font: is_mobile?"16px/18px Futura Md BT": "24px/18px Futura Md BT",
                                   color: "black",
                                paddingLeft: '0px',
                                paddingBottom: '0px',
                                paddingTop: '20px',
                            }}>
                                Hotel
                            </div>


                            <div className={"col-8"} style={{
                                marginTop: "20px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                            }}>

                                <FormControl className={aiTripClass.formControl}>
                                    <Select
                                        native
                                        value={rooms}
                                        onChange={handleRoomsChange}
                                        inputProps={{
                                            name: 'rooms',
                                            id: 'rooms',
                                        }}
                                        placeholder="Rooms"
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">1 Room</option>
                                        <option value="2">2 Rooms</option>
                                        <option value="3">3 Rooms</option>
                                        <option value="4">4 Rooms</option>
                                        <option value="5">5 Rooms</option>
                                        <option value="6">6 Rooms</option>
                                        <option value="7">7 Rooms</option>
                                        <option value="8">8 Rooms</option>
                                        <option value="9">9 Rooms</option>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>}


                    <div className="m-0">


                        <button onClick={() => hasAllValues() && generateTrip()}
                            style={{
                                cursor: "pointer",
                                backgroundColor: !hasAllValues() ? "var(--lightGraySeparator)" : "var(--mainGreen)",
                                padding: "0px 3px 0px 14px",
                                height: 44,
                                width: is_mobile? "100%": 161,
                                marginTop: is_mobile ?10:"40px",
                                display: "block",
                                border: "0",
                                float: is_mobile ?'none' :"right"
                            }}>
                            <div style={{
                                textAlign:is_mobile?"center": "left",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                color: "var(--mainWhite)",
                            }}>
                                {uuid ? "Create my trip" : "Personalize"}  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "15px" }} />
                            </div>
                        </button>
                    </div>

                </div>

            </Modal>

        </React.Fragment>
    )
}