import { useDispatch, useSelector, connect } from "react-redux";
import React, { useEffect } from 'react'
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { RightArrowIcon } from "../Common/SVGBank";
import moment from "moment";
import { landing_router, URL_REPLACE } from "../Common/RegExValidate";

import { getHomeDetails, setTimeLineStatus } from "../../../redux/actions";
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../redux/actions'
import TagManager from 'react-gtm-module';
import { resetFlightPage, updateFlightData } from "../FlightsPage/services/Flights.slice";
import { createTripThunk } from "../FeaturePage/personalizedTrip/personalizedTrip.slice";
import { PersonalizedModalUseStyles } from "../Common/BookingForm/AiPersonalizedTrip/AiPersonalizedTripStyles";
import { HotelFlightSaveModal } from "../FlightsPage/RecapPage/FlightSaveModal";
import { getNearByAirport, saveHotelTimeline } from "../BookingPage/PaymentPage/payment.thunk";

const CongratulationsHotel_ = ({ uuid, booking_bundles, registerMemberAccount, setSwitchProfileContainer }) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const authModal = useSelector(state => state.Member.authModal);
    const pricelineBooking = useSelector(state => state.FormBank.BookingPriceline);
    const { booking_info } = pricelineBooking;
    const checkin = booking_info && booking_info.check_in ? moment(booking_info.check_in).format('MMM Do') : null;
    const checkout = booking_info && booking_info.check_out ? moment(booking_info.check_out).format('MMM Do') : null;
    const hotelName = booking_info && booking_info.name ? booking_info.name : null;
    const bookingData = useSelector(state => state.HotelBooking.bookingData);
    const bookingInfo = useSelector(state => state.HotelBooking.bookingInfo);
    const hotelBooking = useSelector(state => state.HotelBooking);
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const stayTrip = useSelector(state => state.PersonalizedTrip.stayTrip)
    const useAiHotel = useSelector(state => state.PersonalizedTrip.useAiHotel)
    const flightSuccess = useSelector(state => state.Flights.bookingSuccess);
    const hotelSuccess = useSelector(state => state.HotelBooking.bookingSuccess);
    const firstName = authModal.firstName || '';
    const arrivalCity = booking_info?.cityName || "";
    const bookingNumber = bookingData?.book_data?.itinerary.id || "";
    const geo = bookingData?.contract_data?.hotel_data.geo || null;
    const [home, setHome] = React.useState();
    const [creating, setCreating] = React.useState(false)
    const bookingId = bookingNumber;
    const email = bookingData?.book_data?.itinerary_details?.customer?.email;
    const backUpPhoto = bookingData?.contract_data?.hotel_data[0]?.photo_data[0] ?? "https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png?func=crop&w=88&h=88"
    const imageUrl = bookingData?.contract_data?.room_data?.photos?.photo_data?.photo_0 || backUpPhoto;
    const lang = useSelector(state => state.Setting.lang)
    const is_mobile = useSelector(state => state.Setting.is_mobile)

    const canViewDetails = bookingId && email && imageUrl;
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";

    const destinations = activeTrip.tripDestinations;
    const currDestination = destinations[0];
    const activeTripId = activeTrip?.tripId;
    const tripDestinations = activeTrip?.tripDestinations;
    const classes = PersonalizedModalUseStyles();
    const tripId = useSelector(state => state.FormBank.TimelineWizard.activeTrip.tripId);

    useEffect(() => {
        if (geo) {
            dispatch(getNearByAirport({
                lat: geo.latitude, lng: geo.longitude
            }));
        }
    }, [geo]);


    useEffect(() => {
        pushGtmTag()
    }, []);


    React.useEffect(() => {
        getHomeDetails().then(data => {
            setHome(data)
        })
    }, [uuid])

    const goToTrip = () => {
        const url = "/muver/trippage/" + activeTripId
        history.push(url.toLowerCase())
    }




    const pushGtmTag = () => {
        const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0] : null;
        const tagManagerArgs = {
            dataLayer: {
                'js': new Date(), // current date time,
                event: 'Purchase',
                pricelineHotelId: booking_info?.hotel_id || "",
                checkIn: booking_info.check_in || "",
                checkOut: booking_info.check_out || "",
                displaySymbol: booking?.rate_symbol,
                displayCurrency: booking?.rate_currency,
                displayTotalFare: booking?.rate_total
            }
        }

        TagManager.dataLayer(tagManagerArgs);
    }

    const goToDestination = () => {
        const dest = currDestination ?? nearByDestination;
        if (dest) {
            const url = "/destination/" + dest?.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + dest?.destId;
            dispatch(setTimeLineStatus(true))
            history.push(url.toLowerCase());
        }
    }

    const goToFlight = () => {
        // const url = "/flight/search";
        // window.open(url.toLowerCase());

        openFlightBooking()
        // setBookingFormStatus(true)
        // setBookingFormActiveTab('flight')
    }

    const generateAi = async (tripId) => {

        setCreating(true)
        const tripObj = {
            density: 5,
            destinations: destinations,
            lang: lang,
            uuid: uuid || "TMPUUID",
            tripId: tripId,
            haveHotel: false,
            tripDate:booking_info.check_in || activeTrip?.tripStartDate,
            hideTimeline:true

        }
        const newTrip = await dispatch(createTripThunk(tripObj));
        const addedTrip = newTrip.payload;
        const newTripId = addedTrip?.id || addedTrip?.tripID;

        if (addedTrip) {
         

            await saveBookedHotel(newTripId)
            setCreating(false)
            if (newTripId) {
                const url = "/muver/trippage/" + newTripId
                history.push(url.toLowerCase())
            }
        }
        else {
            setCreating(false)
        }

        setTimeout(() => {
            setCreating(false)
        }, 8000);

    }

    const saveBookedHotel = async(newTripId)=>{
        const guests = booking_info && booking_info.num_rooms ? Number(booking_info.num_rooms) : 1;
        const booking = booking_bundles && booking_bundles.length > 0 ? booking_bundles[0]: null;
        const book_data = bookingData?.book_data;

        const saveData = {
            postData: {
                tripId: newTripId || "",
                pricelineHotelId: booking_info?.hotel_id || "",
                booking_trip_number: book_data?.itinerary.id || "",
                booking_status: book_data?.itinerary.status || "",
                booking_status_code: book_data?.itinerary.code || "",
                checkIn: booking_info.check_in || "",
                checkOut: booking_info.check_out || "",
                bookedEmail: bookingInfo?.email,
                displaySymbol: booking?.rate_symbol,
                displayCurrency: booking?.rate_currency,
                displayTotalFare: booking?.rate_total,
                adults:guests
            },
            uuid: uuid || ""
        }
        await dispatch(saveHotelTimeline(saveData))
    }
    const openFlightBooking = () => {
        try {
            const validStartDate = moment(activeTrip?.tripStartDate)?.isValid();

            if (tripDestinations && tripDestinations?.length > 0) {
                let destinations = [...tripDestinations]
                let prevDestination;
                const homeAirport = home?.homeAirport;
                let flights = []
                let flightMode = "one_way"

                if (destinations?.length === 1 || destinations[0]?.id === currDestination?.id) { //first destination or only destination
                    flightMode = destinations?.length === 1 ? "round_trip" : "one_way";
                    flights = [
                        {
                            "airportFrom": {
                                "city": homeAirport?.airportCity ?? "",
                                "latitude": homeAirport?.airportLatitude ?? "",
                                "city_code": homeAirport?.airportCode ?? "",
                                "type": "main",
                                "iata": homeAirport?.airportCode ?? "",
                                "airport": "All Airports"
                            },
                            "airportTo": {
                                "city": currDestination?.name + " " + currDestination?.countryName ?? "",
                                "latitude": currDestination?.latitude ?? "",
                                "city_code": currDestination?.iata ?? "",
                                "type": "main",
                                "iata": currDestination?.iata ?? "",
                                "airport": "All Airports"
                            },
                            "departureDateFormat": activeTrip?.tripStartDate && validStartDate ? new Date(moment(activeTrip?.tripStartDate)) : new Date(moment()),
                            "departureDate": activeTrip?.tripStartDate && validStartDate ? new Date(moment(activeTrip?.tripStartDate)) : new Date(moment()),
                            "availableSelectDate": new Date().toDateString(),
                            "positionId": "d-0"
                        }]

                    if (flightMode === "round_trip") {
                        flights.push({
                            "airportFrom": {
                                "city": currDestination?.name + " " + currDestination?.countryName ?? "",
                                "latitude": currDestination?.latitude ?? "",
                                "city_code": currDestination?.iata ?? "",
                                "type": "main",
                                "iata": currDestination?.iata ?? "",
                                "airport": "All Airports"
                            },
                            "airportTo": {
                                "city": homeAirport?.airportCity ?? "",
                                "latitude": homeAirport?.airportLatitude ?? "",
                                "city_code": homeAirport?.airportCode ?? "",
                                "type": "main",
                                "iata": homeAirport?.airportCode ?? "",
                                "airport": "All Airports"
                            },
                            "departureDateFormat": activeTrip?.tripStartDate && validStartDate ? new Date(moment(activeTrip?.tripStartDate).add(Number(activeTrip?.tripDuration) - 1, "days")) : new Date(moment()),
                            "departureDate": activeTrip?.tripStartDate && validStartDate ? new Date(moment(activeTrip?.tripStartDate).add(Number(activeTrip?.tripDuration) - 1, "days")) : new Date(moment()),
                            "availableSelectDate": new Date().toDateString(),
                            "positionId": "d-1"
                        })
                    }
                }
                else {
                    flightMode = "one_way";
                    destinations.forEach((dest, index) => {
                        if (dest?.id === currDestination?.id) {
                            prevDestination = destinations[index - 1];
                        }
                    })
                    if (prevDestination) {
                        flights = [
                            {
                                "airportFrom": {
                                    "city": prevDestination?.name + " " + prevDestination?.countryName ?? "",
                                    "latitude": prevDestination?.latitude ?? "",
                                    "city_code": prevDestination?.iata ?? "",
                                    "type": "main",
                                    "iata": prevDestination?.iata ?? "",
                                    "airport": "All Airports"
                                },
                                "airportTo": {
                                    "city": currDestination?.name + " " + currDestination?.countryName ?? "",
                                    "latitude": currDestination?.latitude ?? "",
                                    "city_code": currDestination?.iata ?? "",
                                    "type": "main",
                                    "iata": currDestination?.iata ?? "",
                                    "airport": "All Airports"
                                },
                                "departureDateFormat": validStartDate && currDestination?.startingDayInTrip ?
                                    new Date(moment(activeTrip?.tripStartDate).add(Number(currDestination?.startingDayInTrip), "days")) : new Date(moment()),
                                "departureDate": validStartDate && currDestination?.startingDayInTrip ? new Date(moment(activeTrip?.tripStartDate).add(Number(currDestination?.startingDayInTrip), "days").format("YYYY-MM-DD")) : new Date(moment().format("YYYY-MM-DD 00:00")),
                                "availableSelectDate": new Date().toDateString(),
                                "positionId": "d-0"
                            }]


                    }
                }

                let dataToBookingFlights = {
                    flightMode: flightMode,
                    cabinClass: 'economy',
                    numberAdult: 1,
                    numberChild: '0',
                    flights: flights,
                    currency: fullCurrency

                }

                // dispatch(updateFlightData(dataToBookingFlights))

            
                const flightDates = [...flights]?.map((flight) => {
                    const formatDate = moment.parseZone(flight?.departureDate)?.format("YYYY-MM-DD")?.toString()?.split("-");
                    const dateString = `${formatDate[2]}${formatDate[0] < 10 ? "0" + formatDate[0] : formatDate[0]}${formatDate[1] < 10 ? "0" + formatDate[1] : formatDate[1]}`
                    return `${flight?.airportFrom?.iata}-${flight?.airportTo?.iata}-${formatDate?.join('') ?? ""}`
                })
    

                let flightUrl = `/flight/search?flightMode=${flightMode}&cabinClass=${dataToBookingFlights?.cabinClass || 'economy'}&numberAdult=${dataToBookingFlights?.numberAdult}&numberChild=${dataToBookingFlights?.numberChild || 0}&flights=${flightDates?.toString()}`;
                dispatch(resetFlightPage())
                history.push(flightUrl)

            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const generateItinerary = () => {
        const url = "/register/ai";
        const pass = "A&da143(sdwYUa"
        const emailInfo = bookingInfo?.email;
        const first_name = bookingInfo?.name_first;
        const last_name = bookingInfo?.name_last;
        registerMemberAccount(emailInfo, first_name, last_name, pass, "", null)
        history.push(url.toLowerCase());

        setTimeout(() => {
            setSwitchProfileContainer(landing_router.interests)
        }, 300);
    }



    const goToBooking = () => {

        if (canViewDetails) {
            const url = `/hotel/booking_details?booking_id=${bookingId}&email=${email}&imageUrl=${imageUrl}`;
            window.open(url);
        }
    }


    const [showSaveModal, setSaveModal] = React.useState(false);
    const toggleSaveModal = () => {
        setSaveModal(!showSaveModal)
    }

    const addToTimeline = async (tripId) => {
        generateAi(tripId)
    }



    return (
        <Grid container style={{ padding: 20 }}>
            <Backdrop className={classes.backdrop} open={creating} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid item  >
                <div>
                    <p style={{ font: '24px/24px Futura Hv BT' }}> Congratulations {firstName},</p>
                </div>

                <div>
                    <p style={{ marginTop: 20, font: '20px/20px Futura Md BT', lineHeight: '30px' }}>Your booking for {hotelName} from {checkin} to {checkout} is complete.</p>


                    {bookingNumber && <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>Your booking reference number is {bookingNumber}.</p>}

                    <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>You will soon receive a confirmation email with all your booking information.</p>

                    <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>For any information or help with your booking, please call our customer service center at <a href="tel:+1-877-477-7441" type="tel">1-877-477-7441</a>.</p>

                    <p style={{ marginTop: 20, font: '20px/20px Futura MD BT', lineHeight: '30px' }}>You can safely continue planning your trip.</p>
                </div>

            </Grid>

            <Grid item style={{ marginTop: 60, width: '100%' }} >
                <div >

                    {canViewDetails && <button className='recapButtons' style={{ background: '#DFDFDF' }} onClick={goToBooking}>
                        See Booking Details
                    </button>}


                    {flightSuccess && hotelSuccess ?
                        <div style={{ float: "right", textAlign: "right" }}>
                            <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={goToTrip}>
                                <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Go to trip  </span>
                                <RightArrowIcon height={14} width={14} />
                            </button>
                        </div>


                        : <div style={{ float: "right", textAlign: "right" }}>
                            {stayTrip ?
                                <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={generateItinerary}>
                                    <span style={{ paddingRight: 0, font: '14px/14px Futura Hv BT' }}>  Generate an itinerary  </span>
                                    <RightArrowIcon height={14} width={14} />
                                </button> :
                                <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={goToFlight}>
                                    <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Find a Flight  </span>
                                    <RightArrowIcon height={14} width={14} />
                                </button>}
                            {stayTrip ?
                                <div onClick={goToFlight} style={{ marginTop: 10, font: '14px/14px Futura Hv BT', cursor: "pointer", }}>
                                    <p><u>Find a flight</u></p>
                                </div>
                                : <div onClick={goToDestination} style={{ marginTop: 10, font: '14px/14px Futura Hv BT', cursor: "pointer", }}>
                                    <p>Skip to  <u> explore things to see & do in {arrivalCity}  </u></p>
                                </div>}
                        </div>}



                </div>


            </Grid>
            {(useAiHotel && arrivalCity) ? <button className='recapButtons' style={{ marginTop: 30, width: "100%", background: "var(--mainRed)", color: 'white', font: '14px/14px Futura Hv BT' }} onClick={()=>{
                // setSaveModal(true)
                addToTimeline(tripId)
            }}>
                <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Generate a free Ai-Personalized Itinerary for {arrivalCity}  </span>

            </button> : ""}

            <HotelFlightSaveModal showModal={showSaveModal} toggleModal={toggleSaveModal} addToTimeline={addToTimeline}
                type={"hotel"} />
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        uuid: state.Member.authModal.uuid,
        booking_info: state.FormBank.BookingPriceline.booking_info,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    }
}



const mapDispatchToProps = {
    registerMemberAccount: Actions.registerMemberAccount,
    loginMemberAccount: Actions.loginMemberAccount,
    setRouteAuthModal: Actions.setRouteAuthModal,
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    setProfilePhotoAvatar: Actions.setProfilePhotoAvatar,
    uploadProfilePhotoAvatar: Actions.uploadProfilePhotoAvatar,
    alertBar: Actions.alertBar,
}
const CongratulationsHotel = connect(mapStateToProps, mapDispatchToProps)(CongratulationsHotel_);
export default CongratulationsHotel