import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { ALERT_BARS_HANDLE, PostAsyncHelper } from "../../../../redux/interfaces/types";
import { environment } from "../../../../Environments/environment";

export const retreiveCart = createAsyncThunk(
    'Hotel/retreiveCart',
    async (data, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        try {
            const url = `${environment.apiUrl}muv-meta-engine/cart/retreiveCart`;
         
          
            const response = await  PostAsyncHelper(url,data);
            const results  = response?.data?.result;
            // dispatch({
            //     type: ALERT_BARS_HANDLE,
            //     message_type: "success",
            //     message: ""
            // })

            return results;
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: err
            })
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)

export const createCheckout = createAsyncThunk(
    'Hotel/createCheckout',
    async (data, thunkAPI) => {
        const dispatch = thunkAPI.dispatch;

        try {
            const url = `${environment.apiUrl}muv-meta-engine/payment/createCheckout`;
         
          
            const response = await  PostAsyncHelper(url,data);
            const results  = response?.data?.result;
            // dispatch({
            //     type: ALERT_BARS_HANDLE,
            //     message_type: "success",
            //     message: ""
            // })

            return results;
        }
        catch (err) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: err
            })
            return thunkAPI.rejectWithValue(err.response.data)
        }

    }
)

