import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { PackagePaymentForm } from '../PaymentPage/PaymentForm';



import { FlightSearchComponent } from '../../FlightsPage/FlightsSearch';
import { CongratulationsBookedFlight } from '../../FlightsPage/RecapPage/FlightSharedComponents';
import { FlightTotalComponent } from '../../FlightsPage/RecapPage/FlightTotalComponent';
import { clearFlightBooking } from '../../FlightsPage/services/Flights.slice';
import BookingItems from '../../BookingPage/BookingItems';
import { clearHotelBooking } from '../../../../redux/actions';
import CongratulationsHotel from '../../BookingPage/PaymentPage/BookingSuccess';



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const PackagePaymentContainer_ = ({booking_bundles, lang, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus }) => {
    const params = useParams()
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch()
    const Flights = useSelector(state => state.Flights);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const selectedCurrencySybmol = useSelector(state => state.Setting.currency.selected.symbol);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const flightContract = useSelector(state => state.Flights.flightContract);
    const priceDetails = flightContract?.itinerary_data?.price_details;


    const flightTotal =  priceDetails?.display_total_fare.toFixed(2);
    const hotelTotal = booking_bundles && booking_bundles?.length > 0 ? booking_bundles?.reduce((acc , val)=>{
        return val?.rate_total? acc + val?.rate_total:0;
     } ,0): 0
    const totalCart  =flightTotal && hotelTotal? (Number(flightTotal)+Number(hotelTotal))?.toFixed(2):0;


    let width;
    let marginLeft;
    let marginRight;
    React.useEffect(() => {
        return () => {
            dispatch(clearFlightBooking())
            dispatch(clearHotelBooking())
        }
    }, [])

    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '90%' : '67%';
        marginLeft = is_mobile ? '5%' : timelineWizardStatus ? '31%' : '15.5%';
        marginRight = is_mobile ? '5%': 0;
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
   
    const loading = useSelector(state => state.Flights.loading);
    const flightSuccess = useSelector(state => state.Flights.bookingSuccess);
    const hotelSuccess =  useSelector(state => state.HotelBooking.bookingSuccess);



    return (
        <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
            }}>
               <div>
                <div id="back_to_top_flights"></div>

                {flightSuccess && <div>
                        <FlightSearchComponent bodyClientWidth={bodyClientWidth} />
                    </div>}

                <Grid container className="flightRecap">

                    <Grid item md={8} sm={12} xs={12} className="flightRecapBody paymentPage" >
                         
                
                    {flightSuccess &&
                        <CongratulationsBookedFlight/>}
                        {hotelSuccess && <CongratulationsHotel />}
                        {!flightSuccess && !hotelSuccess && <PackagePaymentForm/>}

                    </Grid>

                    {/* spacing */}
                    <Grid item xs={false} sm={false} md={1} style={{ maxWidth: 40 }}>
                    </Grid>


                    {!is_mobile &&<Grid item md sm={12} xs={12} >
                    {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                        <div>
                             <div style={{ font: "18px/16px Futura Md BT", padding:"15px 30px",background:"#F5F7F6" }} >STAY</div>
                            <div style={{
                                width: "100%",
                                margin: is_mobile? "0 auto": "0",
                                display:is_mobile?"block":"inline-block",
                            }}>
                                 {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                                        booking_bundles.map((bundle, bidx) => {
                                            if (bundle) {
                                                return (
                                                    <div style={{ marginBottom: 15 }} key={bidx+"cart"}  >
                                                       <BookingItems booking_bundles={booking_bundles}
                                                       booking_bundle={bundle} />
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                            </div></div>}
                        <div style={{ font: "18px/16px Futura Md BT", padding:"15px 30px",background:"#F5F7F6" }} >FLIGHT</div>    
                        <FlightTotalComponent/>

                        {totalCart ? <div style={{ padding: "30px 30px",color: "#f8f8f8", background: "#2c2c2c" ,marginTop:-10 }}>
                            <p style={{ font: "14px/0px Futura Md BT", float: 'left', }}>   TOTAL TRIP COST </p>
                            <p style={{ font: "14px/0px Futura Md BT", float: 'right', }}>
                                {fullCurrency}{selectedCurrencySybmol} {totalCart}
                                {/* .<sup style={{ fontSize: '50%', top: '-0.7em' }}>{111}</sup> */}
                                </p>
                        </div>:""}
                    </Grid>}


                </Grid>

                </div>

               
                <Backdrop className={classes.backdrop} open={loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>


            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const PackagePaymentContainer = connect(mapStateToProps, mapDispatchToProps)(PackagePaymentContainer_)
export default PackagePaymentContainer
