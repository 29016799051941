import { makeStyles, Slider, withStyles } from '@material-ui/core';



export const FlightsSlider = withStyles({
    root: {
        color: '#19BC9B',
        height: 4,
        padding: '13px 0',
        width: '95%',
        marginLeft: 10
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '3px solid #19BC9B',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#eebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none !important',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {
        boxShadow: 'none !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none !important',
        }
    },
    focusVisible: {
        boxShadow: 'none !important',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none !important',
        }
    },
    track: {
    height: 7,
    marginTop: -2
},
    rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
},
    valueLabel: {

}
}) (Slider);


export const flightTextField = makeStyles(theme => ({
    root: {
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "80px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#f44336'
            
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
            textAlign:"left"
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))


export const flightTextArea = makeStyles(theme => ({
    root: {
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
        },
        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: "10px",
        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
            textAlign:"left"
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1"
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))