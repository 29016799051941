import * as Actions from '../../../redux/actions'

import { Element, Link } from 'react-scroll'

import About from './About'
import Breadcrumb from './Breadcrumb'
import ReviewContainer from "./ReviewContainer"
//import EstablishmentReviews from '../Common/Establishment/EstablishmentReviews'
import { Menu } from 'semantic-ui-react'
import React from 'react'
import TopBarSlider from './TopBarSlider'
import { connect } from 'react-redux'
import BucketListBasicInfo from './BucketListBasicInfo';
import { feature_color } from '../Common/RegExValidate';

const BucketListPageContainer_ = ({ props,  setBucketListId, switchFeatureContainer,is_mobile, bucketlist, logo_height, app_menu_height, lang, fetchBucketList }) => {
    const [menu, setMenu] = React.useState("about")
    /* fetch bucket list item */
    React.useEffect(() => {
        if (props.match.params.bucketItemID !== undefined &&
            props.match.params.bucketItemID !== null &&
            props.match.params.bucketItemID !== "") {
            fetchBucketList(props.match.params.bucketItemID, lang)
            // fetchPhotoGalleryEstablishment(props.match.params.establishmentID, lang)
            // fetchExperiencesEstablishment(props.match.params.establishmentID, lang)
            // fetchNeighborhoodEstablishment(props.match.params.establishmentID, lang)
            // fetchGoogleAndYelpReviewsEstablishment(props.match.params.establishmentID, lang)
            setBucketListId(props.match.params.bucketItemID)
        }
    }, [props.match.params.bucketItemID])
    const breadcrumb =  bucketlist?.breadcrumb;
    const generalInfo = bucketlist?.generalInfo;
    const sliderImages = bucketlist?.sliderImages;
    const color = feature_color[3].color;
    let padding_l = 15
    let padding_r = 15

    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "15"
        padding_r = is_mobile ? "1" : "15"
    }
    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90


    return (
        <React.Fragment>

            <div style={{ position: "relative",backgroundColor: "#FFFFFF" }}>
                {sliderImages !== undefined && <TopBarSlider />}

                {breadcrumb !== undefined && breadcrumb.length > 0 && <Breadcrumb />}
                <div className="bucketContainer">
                <div style={{
                    position: "sticky", zIndex: "3",
                    top: is_mobile ? logo_height : headerHeight,
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                }}>
                    <Menu className="bucketMenu"  pointing secondary style={{ position: "relative", backgroundColor: "#FFFFFF", borderBottom: "10px solid #F8F8F8", paddingTop: "60px",marginBottom: 20 }}>
                        {
                            <Link to="about" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                font: "18px/18px Futura Md BT",
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                fontWeight: "bold",
                                opacity: "1",
                                textAlign: "center",
                                height: "40px",
                                padding: "0px 20px",
                                width: "200px",
                                position: "absolute",
                                bottom: "-10px",
                                color: menu === "about" ? color : "#191919",
                                cursor: menu === "about" ? "" : "pointer",
                                borderBottom: menu === "about" ? "10px solid" + color : "10px solid #F8F8F8",
                            }} ><p style={{
                                verticalAlign: "middle"
                            }} onClick={() => setMenu("about")}
                            > About</p></Link>
                        }
                        {
                            <Link to="reviews" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                font: "18px/18px Futura Md BT",
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                fontWeight: "bold",
                                opacity: "1",
                                textAlign: "center",
                                height: "40px",
                                width: "200px",
                                position: "absolute",
                                bottom: "-10px",
                                left: "200px",
                                padding: "0px 20px",
                                color: menu === "reviews" ? color : "#191919",
                                cursor: menu === "reviews" ? "" : "pointer",
                                borderBottom: menu === "reviews" ? "10px solid"  + color  : "10px solid #F8F8F8",
                            }} ><p style={{
                                verticalAlign: "middle"
                            }} onClick={() => setMenu("reviews")}>Reviews</p></Link>
                        }
                          {/* {
                          <Link to="offers" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                font: "18px/18px Futura Md BT",
                                letterSpacing: "0",
                                backgroundColor: "initial",
                                fontWeight: "bold",
                                opacity: "1",
                                textAlign: "center",
                                height: "40px",
                                width: "200px",
                                position: "absolute",
                                bottom: "-10px",
                                left: "400px",
                                padding: "0px 20px",
                                color: menu === "contact" ? color : "#191919",
                                cursor: menu === "contact" ? "" : "pointer",
                                borderBottom: menu === "contact" ? "10px solid"  + color  : "10px solid #F8F8F8",
                            }} ><p style={{
                                verticalAlign: "middle"
                            }} onClick={() => setMenu("experiences")}>Offers</p></Link>
                        } */}
                       
                       
                    </Menu>
                </div>
                {/* {generalInfo !== undefined && generalInfo !== null && <div style={{
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                    marginTop: "20px"
                }}>

                    <BucketListBasicInfo />
               

                </div>} */}
                {generalInfo !== undefined && generalInfo !== null && generalInfo.about !== null && generalInfo.about !== "" && <div style={{
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                    marginTop: '50px',
                    marginBottom: '50px'
                }}>
                    <Element name="about" className="element">
                        <About />
                        <hr className="line_separator_w margin_top_50 margin_bottom_50" />
                    </Element>
                </div>} 

                {<div style={{
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                }}>
                    <Element name="reviews" className="element">
                        <ReviewContainer/>
                        {/* <hr className="line_separator_w margin_top_50 margin_bottom_50" /> */}
                    </Element>
                </div>}

                <style dangerouslySetInnerHTML={{
                __html: `
                   *::-webkit-scrollbar-thumb {
                    background-color: var(--lightGraySeparator);
                    border-radius: 20px;
                    border: 3px solid ${switchFeatureContainer.color};
                  }
                `}} />
                </div>
            </div>

        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        logo_height: state.Setting.appMenu.logo.height,
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        bucketlist: state.Destination.bucketlist,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}

const mapDispatchToProps = {
    fetchBucketList: Actions.fetchBucketList,
    setBucketListId: Actions.setBucketListId,
}

const BucketListPageContainer = connect(mapStateToProps, mapDispatchToProps)(BucketListPageContainer_)
export default BucketListPageContainer
