import { environment } from "../../Environments/environment"
import {
    FETCH_PUBLIC_TRIP_COST,
    FETCH_TRIP_SUCCESS,
    GetAsyncHelper,
    SET_FEATURE_FORMAT,
    SET_LOADER,
} from "../interfaces/types"

/*  get trip  */
export const getPublicTrip = (id, lang) => async dispatch => {
    const feature = {
        featureId: "3",
        slug: "trips",
        title: "Trips",
        color:"#65D4D5",
        disable: "0",
    }
    dispatch({
        type: SET_FEATURE_FORMAT,
        featureSelected: feature
    })

    GetAsyncHelper(environment.apiUrl +`trip/` + id).then(response => {

        if (response !== undefined && response.statusText === "OK") {
            dispatch({
                type: FETCH_TRIP_SUCCESS,
                payload: response.data,
                tripId: response.data.tripID,
            })
      
            if (response.data.tripCost !== undefined) {
                dispatch({
                    type: FETCH_PUBLIC_TRIP_COST,
                    cost: response.data.tripCost
                })
            }
            dispatch({
                type: SET_LOADER,
                payload: false
            })
        }
    })
}


/*  get trip share  */
export const getPublicTripShare = (id, lang) => async dispatch => {
    const feature = {
        featureId: "3",
        slug: "trips",
        title: "Trips",
        color:"#65D4D5",
        disable: "0",
    }
    dispatch({
        type: SET_FEATURE_FORMAT,
        featureSelected: feature
    })

    GetAsyncHelper(environment.apiUrl +`getTripIdByShareToken?tripToken=` + id).then(response => {

        if (response !== undefined && response.statusText === "OK") {
            const tripID = response?.data?.result?.id;

            if(tripID){

                GetAsyncHelper(environment.apiUrl +`trip/` + tripID).then(response => {

                    if (response !== undefined && response.statusText === "OK") {
                        dispatch({
                            type: FETCH_TRIP_SUCCESS,
                            payload: response.data,
                            tripId: response.data.tripID,
                        })
                        if (response.data.tripCost !== undefined) {
                            dispatch({
                                type: FETCH_PUBLIC_TRIP_COST,
                                cost: response.data.tripCost
                            })
                        }
                        dispatch({
                            type: SET_LOADER,
                            payload: false
                        })
                    }
                })
            }
            else{
                window.location.href = '/'
            }
        }
    })
}

