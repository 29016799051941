import './HometownMap.css';

import * as Actions from '../../../redux/actions';

import {
  Col,
  Row
} from 'react-bootstrap'
import { GoogleMap, withGoogleMap, } from "react-google-maps"
import { compose, withProps } from "recompose"

import { PropTypes } from "prop-types"
import React from 'react';
import { connect } from 'react-redux'
import mapStyles from "../TripPage/mapStyles"
import { generateMapAPIKey } from '../../../utility/utility';

/* wrap map high order component */
const WrappedMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withGoogleMap
)((props) =>
  /* map setting */
  <GoogleMap
    defaultZoom={11}
    defaultCenter={props.mapCenter}
    defaultOptions={{
      controlSize: 20,
      mapTypeControl: false,
      fullscreenControl: false,
      minZoom: 4,
      maxZoom: 18,
      styles: mapStyles
    }}
  >
  </GoogleMap>
)

/* home map component */
const HowntownMap_ = ({ screenSize, member, }) => {

  const [open, setOpen] = React.useState(false)
  let homeDestination = member.muver.homeDestination
  let home = member.home
  let containerH = 600;
  if (screenSize !== undefined) {
    containerH = screenSize / 5;
    containerH = containerH < 320 ? 320 : (containerH > 380 ? 380 : containerH);
  }
  return (
    <React.Fragment>
      <div className="mt-5 mb-5 home_margin_right_50">
        <Row>
          <Col sm={12} md={12} lg={6} xl={5} className="padding_125rem_lr_mobile">
            <div className=" futura_hv_bt_24_30_white margin_bottom_30">Hometown</div>
            <div className="futura_lt_bt_24_30_white margin_left_30 margin_bottom_10">{homeDestination.city + ', ' + homeDestination.state + ', ' + homeDestination.country}</div>
            <div className={"futura_lt_bt_16_18_grey margin_left_30 text-justify"} style={{overflowY:'auto',height:266,lineHeight:1.2}}
             dangerouslySetInnerHTML={{ __html: open ? homeDestination.about : `${homeDestination.about.substring(0, 630)}`}}></div>
            {homeDestination.about !== undefined && homeDestination.about !== "" && homeDestination.about.length >  630 &&
                <div className="futura_hv_bt_16_19_green margin_bottom_20 text-right" onClick={() => setOpen(!open)}>{open ? "less" : "more"}</div>
            }
          </Col>
          <Col sm={12} md={12} lg={6} xl={7} id="google_map_wrap" style={{ height: containerH }}>
            <WrappedMap
              mapCenter={{
                lat: parseFloat(homeDestination.homeLatitude),
                lng: parseFloat(homeDestination.homeLongitude)
              }}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}


HowntownMap_.propTypes = {
  member: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return {
    member: state.Member.socialMember.member,
    screenSize: state.Setting.htmlBody.bodyClientWidth
  }
};

const mapDispatchToProps = {
};
const HowntownMap = connect(mapStateToProps, mapDispatchToProps)(HowntownMap_)
export default HowntownMap;
