import {
    CLEAN_SELECTED_SUBTYPES_DESTINATION,
    DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER,
    FETCH_API_ERROR_DESTINATION,
    FETCH_API_ERROR_ESTABLISHMENT,
    FETCH_BREADCRUMB_DESTINATION,
    FETCH_BREADCRUMB_ESTABLISHMENT,
    FETCH_ESTABLISMENT_SETS_DESTINATION,
    FETCH_BUCKET_LIST_DESTINATION,
    FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION,
    FETCH_EXPERIENCES_ESTABLISHMENT,
    FETCH_NEIGHBORHOOD_ESTABLISHMENT,
    FETCH_DESTINATION_SEARCH_LIST_FEATURE,
    FETCH_FEATURES_DESTINATION,
    FETCH_FEATURE_SUBTYPES_DESTINATION,
    FETCH_FEATURE_TYPES_DESTINATION,
    FETCH_GENERAL_INFO_DESTINATION,
    FETCH_GENERAL_INFO_ESTABLISHMENT,
    FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT,
    FETCH_BREADCRUMB_BUCKETLIST ,
     FETCH_API_ERROR_BUCKETLIST ,
    FETCH_REVIEWS_BUCKETLIST ,
    FETCH_REVIEWS_AVERAGE_RATING_BUCKETLIST ,
    SET_BUCKETLIST_ID_BUCKETLIST,
    FETCH_SLIDER_IMAGES_BUCKETLIST,
    FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION,
    FETCH_GOOGLE_AND_YELP_REVIEWS_BUCKETLIST ,
    SET_USER_REVIEWS_RATINGS_BUCKETLIST,
    SET_USER_REVIEWS_RATINGS_ESTABLISHMENT,
     FETCH_GENERAL_INFO_BUCKETLIST,
    FETCH_SUBTYPES_BUCKETLIST,
    FETCH_INFLUENCERS_DESTINATION,
    FETCH_NESTED_IDS_DESTINATION,
    FETCH_REVIEWS_AVERAGE_RATING_ESTABLISHMENT,
    FETCH_REVIEWS_ESTABLISHMENT,
    FETCH_SLIDER_IMAGES_DESTINATION,
    FETCH_SLIDER_IMAGES_ESTABLISHMENT,
    FETCH_SORTING_OPTION_DESTINATION,
    FETCH_SUBTYPES_ESTABLISHMENT,
    FETCH_SUGGESTEDTRIPS_DESTINATION,
    FETCH_TRIP_SETS_DESTINATION,
    FETCH_TRIP_SETS_LAZYLOADING_DESTINATION,
    GetAsyncHelper,
    PostAsyncHelper,
    SET_DESTINATION_ID_DESTINATION,
    SET_ESTABLISHMENT_ID_ESTABLISHMENT,
    SET_FEATURE_FORMAT,
    SET_LIST_DISPLAY_FORMAT_DESTINATION,
    SET_LOADER,
    SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
    UPDATE_LAZYLOADING_DESTINATION,
    UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
    UPDATE_LAZYLOADING_LIMIT_DESTINATION,
    UPDATE_LAZYLOADING_OFFSET_DESTINATION,
    UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
    UPDATE_SEARCH_QUERY_DESTINATION,
    UPDATE_SELECTED_SUBTYPES_DESTINATION,
} from "../interfaces/types"

import Axios from 'axios';
import {
    LAZYLOADING_ESTABLISHMENTS_LIMIT,
} from "../../Components/WebContent/Common/RegExValidate"
import {
    features_router
} from "../../Components/WebContent/Common/RegExValidate"
import { environment } from "../../Environments/environment";

export const handleGoupeSubTypeDestination = (subtypesGroup, lazyLoading, selectedSubtypes) => dispatch => {

    const intersections = selectedSubtypes.filter(subtype => subtypesGroup.indexOf(subtype) !== -1)
    const difference = selectedSubtypes.filter(subtype => subtypesGroup.indexOf(subtype) === -1)
    // merge two or more arrays. This method does not change the existing arrays, but instead returns a new array
    let subtypes = intersections.length === 0 ?
        selectedSubtypes.concat(subtypesGroup) : difference
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: subtypes,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                if (responseEstablishments.data.results.length < 5) {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: true,
                    })
                } else {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: false,
                    })
                }
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
    dispatch({
        type: UPDATE_SELECTED_SUBTYPES_DESTINATION,
        subtypes: subtypes
    })

}

export const cleanSelectedSubTypeDestination = (lazyLoading) => dispatch => {

    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: [],
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })
                if (responseEstablishments.data.results.length < 5) {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: true,
                    })
                } else {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: false,
                    })
                }
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
    dispatch({
        type: CLEAN_SELECTED_SUBTYPES_DESTINATION,
    })
}
export const deleteSelectedSubTypeDestination = (subtype, lazyLoading, selectedSubtypes) => dispatch => {
    let subtypes = selectedSubtypes.filter(val => val.id !== subtype.id)
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: subtypes,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
    dispatch({
        type: UPDATE_SELECTED_SUBTYPES_DESTINATION,
        subtypes: subtypes
    })
}
export const addSelectedSubTypeDestination = (subtype, lazyLoading, selectedSubtypes) => dispatch => {

    let subtypes = selectedSubtypes.slice()
    subtypes.push(subtype)
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: subtypes,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
    dispatch({
        type: UPDATE_SELECTED_SUBTYPES_DESTINATION,
        subtypes: subtypes
    })

}
export const setSwitchFeatureTypeContainerOfDestination = (id, lang, feature, type) => dispatch => {
    dispatch({
        type: DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER,
        typeSelected: type
    })
    /*  get feature's type's subtype */
    GetAsyncHelper(environment.apiUrl + 'destination/getfeaturesubtypes/' + lang + '/' + id + '?featureid=' + feature.featureId + '&typeID=' + type.typeID).then(responseSubtypes => {
        if (responseSubtypes.statusText === "OK") {
            dispatch({
                type: FETCH_FEATURE_SUBTYPES_DESTINATION,
                subtypes: responseSubtypes.data
            })

        } else {
            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
        }
    })

    let obj = {
        featureID: feature.featureId,
        typeID: type.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: [],
        sortingOrder: {
            title: feature.sorting.title,
            code: feature.sorting.code,
        },
        sortingDirection: "",
        searchquery: "",
    }
    let api_url = environment.apiUrl +'destination/getlistoffeatures/' + lang + '/' + id

    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    dispatch({
        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
        no_progress: true,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                feature.featureId === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                feature.featureId === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                feature.featureId === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
            }
        })
    dispatch({
        type: CLEAN_SELECTED_SUBTYPES_DESTINATION,
    })
}
/*  get destination  */
export const setSwitchDestinationFeatureContainer = (id, lang, feature) => dispatch => {

    dispatch({
        type: SET_FEATURE_FORMAT,
        featureSelected: feature
    })
    const sortingOptionsRequests = {
        featureId: feature.feature_id,
    }
    PostAsyncHelper(environment.apiUrl +'featurelist/getFeatureSortingOptions/' + lang, sortingOptionsRequests).then(responseSortingOptions => {
        if (responseSortingOptions !== undefined && responseSortingOptions.statusText !== "OK") {
            throw Error(responseSortingOptions.statusText);
        }
        dispatch({
            type: FETCH_SORTING_OPTION_DESTINATION,
            sorting_options: responseSortingOptions.data.results,
        })
        /*  get first feature's types */
        GetAsyncHelper(environment.apiUrl +'destination/getfeaturetypes/' + lang + '/' + id + '?featureID=' + feature.feature_id).then(responseTypes => {
            if (responseTypes.statusText === "OK") {
                dispatch({
                    type: FETCH_FEATURE_TYPES_DESTINATION,
                    types: responseTypes.data.types
                })
                if (responseTypes.data.types.length > 0 && responseTypes.data.types[0] !== undefined) {
                    dispatch({
                        type: DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER,
                        typeSelected: responseTypes.data.types[0]
                    })
                    /*  get feature's type's subtype */
                    GetAsyncHelper(environment.apiUrl +'destination/getfeaturesubtypes/' + lang + '/' + id + '?featureid=' + feature.feature_id + '&typeID=' + responseTypes.data.types[0].typeID).then(responseSubtypes => {
                        if (responseSubtypes.statusText === "OK") {
                            dispatch({
                                type: FETCH_FEATURE_SUBTYPES_DESTINATION,
                                subtypes: responseSubtypes.data
                            })
                        } else {
                            dispatch({
                                type: FETCH_API_ERROR_DESTINATION,
                                api_error: true
                            })
                        }
                    })

                    let obj = {
                        featureID: feature.feature_id,
                        typeID: responseTypes.data.types[0].typeID,
                        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
                        offset: 0,
                        subtype: [],
                        sortingOrder: {
                            title: feature.sorting? feature.sorting.title : null,
                            code: feature.sorting? feature.sorting.code : null,
                        },
                        sortingDirection: "",
                        searchquery: "",
                    }
                    let api_url = environment.apiUrl +'destination/getlistoffeatures/' + lang + '/' + id

                    dispatch({
                        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
                        api_url: api_url,
                        requests: obj,
                    })
                      dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: true,
                    })
                    PostAsyncHelper(api_url, obj)
                        .then(responseEstablishments => {
                            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                                feature.feature_id === features_router.places.featureId && dispatch({
                                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                                    result_sets_establishments: responseEstablishments.data.results,
                                })
                                feature.feature_id === features_router.trips.featureId && dispatch({
                                    type: FETCH_TRIP_SETS_DESTINATION,
                                    result_sets_trips: responseEstablishments.data.results,
                                })
                                feature.feature_id === features_router.bucket_list.featureId && dispatch({
                                    type: FETCH_BUCKET_LIST_DESTINATION,
                                    result_sets_bucketlist: responseEstablishments.data.results,
                                })
                                dispatch({
                                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                                    no_progress: false,
                                })
                            } else {
                                dispatch({
                                    type: FETCH_API_ERROR_DESTINATION,
                                    api_error: true
                                })
                                dispatch({
                                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                                    no_progress: false,
                                })
                            }
                        })

                }

            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
    })

    dispatch({
        type: CLEAN_SELECTED_SUBTYPES_DESTINATION,
    })

}
export const fetchDestination = (id, lang) => async dispatch => {

    GetAsyncHelper(environment.apiUrl +'destination/main/' + lang + '/' + id).then(responseGeneral => {
        if (responseGeneral === undefined || responseGeneral.statusText !== "OK") {
            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
        } else {
            dispatch({
                type: FETCH_GENERAL_INFO_DESTINATION,
                general_info: responseGeneral.data.result.generalInfo,
            })
            dispatch({
                type: FETCH_BREADCRUMB_DESTINATION,
                breadcrumb: responseGeneral.data.result.destinationTree,
            })

            dispatch({
                type: FETCH_NESTED_IDS_DESTINATION,
                nested_ids: responseGeneral.data.result.destinationNestedIds,
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
        }
    })
}
export const fetchEstablishmentInfluencers = (id, lang) => async dispatch => {
    GetAsyncHelper(environment.apiUrl +'destination/getinfluencers/' + lang + '/' + id).then(influencersResponse => {
        if (influencersResponse !== undefined && influencersResponse.statusText === "OK") {
            dispatch({
                type: FETCH_INFLUENCERS_DESTINATION,
                influencers: influencersResponse.data.influencers,
            })
        } else {
            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
        }
    })

}
export const fetchEstablishmentSortingOptions = (lang) => async dispatch => {

    const sortingOptionsRequests = {
        featureId: 1,
    }
    PostAsyncHelper(environment.apiUrl +'featurelist/getFeatureSortingOptions/' + lang, sortingOptionsRequests).then(responseSortingOptions => {
        if (responseSortingOptions !== undefined && responseSortingOptions.statusText === "OK") {
            dispatch({
                type: FETCH_SORTING_OPTION_DESTINATION,
                sorting_options: responseSortingOptions.data.results,
            })
        }
    })
}

export const fetchEstablishmentSuggestedTrips = (id, lang) => async dispatch => {
    GetAsyncHelper(environment.apiUrl +'destination/suggestedtrips/' + lang + '/' + id + "?limit=10").then(suggestedTripsResponse => {
        if (suggestedTripsResponse === undefined && suggestedTripsResponse.statusText !== "OK") {
            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
        } else {

            dispatch({
                type: FETCH_SUGGESTEDTRIPS_DESTINATION,
                suggested_trips: suggestedTripsResponse === undefined ? suggestedTripsResponse.data.results : [],
            })
        }
    })
}

/*  get destination photo gallery */
export const fetchDestinationPhotoGallery = (id, lang,limit) => async dispatch => {
    let url = environment.apiUrl +'destination/photogallery/' + lang + '/' + id + "?photogallery";

    if(limit){
        url = environment.apiUrl +'destination/photogallery/' + lang + '/' + id + "?limit="+limit
    }

    GetAsyncHelper(url).then(photoGalleryResponse => {
        if (photoGalleryResponse === undefined || photoGalleryResponse.statusText !== "OK") {
            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
        } else {

            dispatch({
                type: FETCH_SLIDER_IMAGES_DESTINATION,
                slider_images: photoGalleryResponse.data.results,
            })
        }
    })
}
/*  get establishments */
export const setLazyloadingIsDestination = (isFetching) => async dispatch => {
    dispatch({
        type: UPDATE_LAZYLOADING_DESTINATION,
        is_fetching: isFetching,
    })
}
export const fetchEstablishmentsLazyloading = (lazyLoading) => async dispatch => {
    const offset = lazyLoading.requests.limit + lazyLoading.requests.offset
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: offset,
        subtype: lazyLoading.requests.subtype,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }
    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_OFFSET_DESTINATION,
        offset: offset,
    })
    lazyLoading.requests.limit !== LAZYLOADING_ESTABLISHMENTS_LIMIT && dispatch({
        type: UPDATE_LAZYLOADING_LIMIT_DESTINATION,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
    })
    // const onDownloadProgressConfig = (progressEvent) => {
    //     const percentage = Math.round(
    //         (progressEvent.loaded * 100) / progressEvent.total
    //     );
    //     dispatch({
    //         type: UPDATE_LAZYLOADING_PROGRESS_DESTINATION,
    //         progress: percentage,
    //     })
    // }
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                if (responseEstablishments.data.results.length < 5) {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: true,
                    })
                } else {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: false,
                    })
                }
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_LAZYLOADING_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })

                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
                
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })
            }
            dispatch({
                type: UPDATE_LAZYLOADING_DESTINATION,
                is_fetching: false,
            })
        
           
        })
}
/*  get destination feature */
export const fetchDestinationFeaturesAndTypes = (id, lang) => async dispatch => {

    dispatch({
        type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
        is_fetching_establishments: true,
    })
    GetAsyncHelper(environment.apiUrl +'destination/getavailablefeatures/' + lang + '/' + id).then(featureResponse => {
        if (featureResponse === undefined || featureResponse.statusText !== "OK") {

            dispatch({
                type: FETCH_API_ERROR_DESTINATION,
                api_error: true
            })
            dispatch({
                type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
                is_fetching_establishments: false,
            })
        } else {
            dispatch({
                type: FETCH_FEATURES_DESTINATION,
                features: featureResponse.data.features,
            })
            dispatch({
                type: SET_LOADER,
                payload: false
            })
            if (featureResponse.data.features.length > 0 && featureResponse.data.features[0] !== undefined) {
                dispatch({
                    type: SET_FEATURE_FORMAT,
                    featureSelected: featureResponse.data.features[0]
                })
                /*  get first feature's types */
                GetAsyncHelper(environment.apiUrl +'destination/getfeaturetypes/' + lang + '/' + id + '?featureID=' + featureResponse.data.features[0].feature_id).then(responseTypes => {
                    if (responseTypes.statusText === "OK") {
                        dispatch({
                            type: FETCH_FEATURE_TYPES_DESTINATION,
                            types: responseTypes.data.types
                        })
                        if (responseTypes.data.types.length > 0 && responseTypes.data.types[0] !== undefined) {
                            dispatch({
                                type: DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER,
                                typeSelected: responseTypes.data.types[0]
                            })
                            /*  get first feature's type's subtype */
                            GetAsyncHelper(environment.apiUrl +'destination/getfeaturesubtypes/' + lang + '/' + id + '?featureid=' + featureResponse.data.features[0].feature_id + '&typeID=' + responseTypes.data.types[0].typeID).then(responseSubtypes => {
                                if (responseSubtypes.statusText === "OK") {
                                    dispatch({
                                        type: FETCH_FEATURE_SUBTYPES_DESTINATION,
                                        subtypes: responseSubtypes.data
                                    })

                                } else {
                                    dispatch({
                                        type: FETCH_API_ERROR_DESTINATION,
                                        api_error: true
                                    })
                                }
                            })
                            dispatch({
                                type: UPDATE_SEARCH_QUERY_DESTINATION,
                                search_query: ""
                            })
                            let obj = {
                                featureID: featureResponse.data.features[0].feature_id,
                                typeID: responseTypes.data.types[0].typeID,
                                limit: 20,
                                offset: 0,
                                subtype: [],
                                sortingOrder: {
                                    title: featureResponse.data.features[0].sorting.title,
                                    code: featureResponse.data.features[0].sorting.code,
                                },
                                sortingDirection: "",
                                searchquery: "",
                            }
                            let api_url = environment.apiUrl +'destination/getlistoffeatures/' + lang + '/' + id
                            dispatch({
                                type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
                                api_url: api_url,
                                requests: obj,
                            })
                            dispatch({
                                type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                                no_progress: true,
                            })
                            PostAsyncHelper(api_url, obj)
                                .then(responseEstablishments => {
                                    if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                                        featureResponse.data.features.length > 0 && featureResponse.data.features[0].feature_id === features_router.places.featureId && dispatch({
                                            type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                                            result_sets_establishments: responseEstablishments.data.results,
                                        })
                                        featureResponse.data.features.length > 0 && featureResponse.data.features[0].feature_id === features_router.trips.featureId && dispatch({
                                            type: FETCH_TRIP_SETS_DESTINATION,
                                            result_sets_trips: responseEstablishments.data.results,
                                        })
                                        featureResponse.data.features.length > 0 && featureResponse.data.features[0].feature_id === features_router.bucket_list.featureId && dispatch({
                                            type: FETCH_BUCKET_LIST_DESTINATION,
                                            result_sets_bucketlist: responseEstablishments.data.results,
                                        })
                                        dispatch({
                                            type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
                                            is_fetching_establishments: false,
                                        })
                                        dispatch({
                                            type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                                            no_progress: false,
                                        })

                                    } else {
                                        dispatch({
                                            type: FETCH_API_ERROR_DESTINATION,
                                            api_error: true
                                        })
                                    }
                                })

                        }
                        dispatch({
                            type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
                            is_fetching_establishments: false,
                        })
                    } else {
                        dispatch({
                            type: FETCH_API_ERROR_DESTINATION,
                            api_error: true
                        })
                        dispatch({
                            type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                            no_progress: false,
                        })
                        dispatch({
                            type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
                            is_fetching_establishments: false,
                        })
                    }
                })

            } else {
                dispatch({
                    type: SET_FEATURE_FORMAT,
                    featureSelected: null,
                })
                dispatch({
                    type: FETCH_FEATURE_TYPES_DESTINATION,
                    types: [],
                })
                let obj = {
                    featureID: 0,
                    typeID: 0,
                    limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
                    offset: 0,
                    subtype: [],
                    sortingOrder: {
                        title: "",
                        code: ""
                    },
                    sortingDirection: "",
                    searchquery: "",
                }
                let api_url = ""
                dispatch({
                    type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
                    api_url: api_url,
                    requests: obj,
                })
                dispatch({
                    type: FETCH_FEATURE_SUBTYPES_DESTINATION,
                    subtypes: [],
                })
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: true,
                })
                dispatch({
                    type: SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
                    is_fetching_establishments: false,
                })
            }
        }
    })
    dispatch({
        type: CLEAN_SELECTED_SUBTYPES_DESTINATION,
    })
}

export const setSearchQueryDestination = (query, lazyLoading) => async dispatch => {

    dispatch({
        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
        no_progress: true,
    })
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: lazyLoading.requests.subtype,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: query,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {


                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })      
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })   
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })

              
                
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })


            }
        })
}

export const setSearchAutocompleteDestination = (query, lazyLoading) => async dispatch => {

    dispatch({
        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
        no_progress: true,
    })
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: lazyLoading.requests.subtype,
        sortingOrder: lazyLoading.requests.sortingOrder,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: query,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {

              
                lazyLoading.requests.featureID && dispatch({
                    type: FETCH_DESTINATION_SEARCH_LIST_FEATURE,
                    destination_search_list: responseEstablishments.data.results,
                })

              
                dispatch({
                    type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                    no_progress: false,
                })

              
                
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
                dispatch({
                    type: FETCH_DESTINATION_SEARCH_LIST_FEATURE,
                    destination_search_list: [],
                })

            }
        })
}


export const cleanSearchQueryDestination = () => async dispatch => {
    dispatch({
        type: FETCH_DESTINATION_SEARCH_LIST_FEATURE,
        destination_search_list: [],
    })
}


export const setSortingOptionsDestination = (selectedOption, lazyLoading) => async dispatch => {
    let obj = {
        featureID: lazyLoading.requests.featureID,
        typeID: lazyLoading.requests.typeID,
        limit: LAZYLOADING_ESTABLISHMENTS_LIMIT,
        offset: 0,
        subtype: lazyLoading.requests.subtype,
        sortingOrder: selectedOption,
        sortingDirection: lazyLoading.requests.sortingDirection,
        searchquery: lazyLoading.requests.searchquery,
    }

    let api_url = lazyLoading.url
    dispatch({
        type: UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION,
        api_url: api_url,
        requests: obj,
    })
    PostAsyncHelper(api_url, obj)
        .then(responseEstablishments => {
            if (responseEstablishments !== undefined && responseEstablishments.statusText === "OK") {
                lazyLoading.requests.featureID === features_router.places.featureId && dispatch({
                    type: FETCH_ESTABLISMENT_SETS_DESTINATION,
                    result_sets_establishments: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.trips.featureId && dispatch({
                    type: FETCH_TRIP_SETS_DESTINATION,
                    result_sets_trips: responseEstablishments.data.results,
                })
                lazyLoading.requests.featureID === features_router.bucket_list.featureId && dispatch({
                    type: FETCH_BUCKET_LIST_DESTINATION,
                    result_sets_bucketlist: responseEstablishments.data.results,
                })   
                if (responseEstablishments.data.results.length < 5) {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: true,
                    })
                } else {
                    dispatch({
                        type: UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION,
                        no_progress: false,
                    })
                }
            } else {
                dispatch({
                    type: FETCH_API_ERROR_DESTINATION,
                    api_error: true
                })
            }
        })
}


export const setSwitchDestinationListContainer = (container_format) => dispatch => {
    dispatch({
        type: SET_LIST_DISPLAY_FORMAT_DESTINATION,
        container_format: container_format
    })
}
export const setDestinationId = (id) => dispatch => {
    dispatch({
        type: SET_DESTINATION_ID_DESTINATION,
        active_id: id
    })
}

export const fetchPhotoGalleryEstablishment = (id, lang,limit) => async dispatch => {
    let url = environment.apiUrl +'establishment/gallery/' + lang + '/' + id ;

    if(limit){
        url = environment.apiUrl +'establishment/gallery/' + lang + '/' + id + "?limit="+limit
    }
    GetAsyncHelper(url).then(responseEstablishmentInfo => {
        if (responseEstablishmentInfo !== undefined && responseEstablishmentInfo.statusText === "OK") {
            dispatch({
                type: FETCH_SLIDER_IMAGES_ESTABLISHMENT,
                slider_images: responseEstablishmentInfo.data.results,
            })

        } else {
            dispatch({
                type: FETCH_API_ERROR_ESTABLISHMENT,
                api_error: true
            })
        }
    })
}
export const fetchGoogleAndYelpReviewsEstablishment = (id, lang) => async dispatch => {
    GetAsyncHelper(environment.apiUrl +"establishment/getReviews/" + lang + "/" + id).then(responseGoogleAndYelpReviews => {
        if (responseGoogleAndYelpReviews !== undefined && responseGoogleAndYelpReviews.statusText === "OK") {
            dispatch({
                type: FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT,
                reviews: responseGoogleAndYelpReviews.data,
            })

        } else {
            dispatch({
                type: FETCH_API_ERROR_ESTABLISHMENT,
                api_error: true
            })
            dispatch({
                type: FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT,
                reviews: [],
            })
        }
    })
}

export const setUserReviewsRatingsDestination = (reviewObj) => async dispatch => {
    console.log(reviewObj.featureId)
    let review_post_url = environment.apiUrl +`simpleRating/addReview/${reviewObj.lang}/${reviewObj.uuid}`
             PostAsyncHelper(review_post_url, reviewObj)
                .then(responseReview => {
                    if (responseReview !== undefined && responseReview.statusText === "OK") {

                       const api_url_reviews = environment.apiUrl +"simpleRating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + reviewObj.featureId + "&featureUnitId=" + reviewObj.featureUnitId
                       GetAsyncHelper(api_url_reviews)
                           .then(responseBucketlistReviews => {
                               if (responseBucketlistReviews !== undefined && responseBucketlistReviews.statusText === "OK") {
                                   if(reviewObj.featureId === 4){   //bucketlist
                                    dispatch({
                                        type: FETCH_REVIEWS_BUCKETLIST,
                                        reviews: responseBucketlistReviews.data.result,
                                    })
                                   }
                                   if(reviewObj.featureId === 1){  //establishment
                                    dispatch({
                                        type: FETCH_REVIEWS_ESTABLISHMENT,
                                        reviews: responseBucketlistReviews.data.result,
                                    })
                                   
                                   }
                                 
                               } else {
                                   dispatch({
                                       type: FETCH_API_ERROR_BUCKETLIST,
                                       api_error: true
                                   })
                               }
                           })

                     }})
}


export const setUserReviewsRatingsEstablishment = (reviewObj) => async dispatch => {
    const url = environment.apiUrl +"rating/getMuvRatingTypes/" + reviewObj.lang + "?featureId=" + reviewObj.featureId+ "&featureTypeId=" + reviewObj.featureTypeId

    GetAsyncHelper(url).then(responseRating => {
        if (responseRating !== undefined && responseRating.statusText === "OK") {
             let ratingTypes = responseRating.data? responseRating.data.result : []
             const {featureId, featureTypeId} = reviewObj
             let ratingTypeId = 0 

             for (let rating of ratingTypes ) {
                 if ( parseInt(rating.featureId) === featureId &&
                      parseInt(rating.featureTypeId) ===featureTypeId &&
                      parseInt(rating.isOverall) === 1 ) {
                      ratingTypeId = parseInt(rating.id)
                 } 
             }
             let reviewPostObj = {...reviewObj, ratings: [{id: ratingTypeId, score: reviewObj.rating*2}]}
             delete reviewPostObj.rating
    
             let review_post_url = environment.apiUrl +`rating/addReview/${reviewPostObj.lang}/${reviewPostObj.uuid}`
             PostAsyncHelper(review_post_url, reviewPostObj)
                .then(responseReview => {
                    if (responseReview !== undefined && responseReview.statusText === "OK") {
                        //console.log ("reviews posted succesfully")
                        //console.log ("fetching reviews")
                        const review_url = environment.apiUrl +"rating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + reviewPostObj.featureId + "&featureTypeId=" + reviewPostObj.featureTypeId + "&featureUnitId=" + reviewPostObj.featureUnitId
                        GetAsyncHelper(review_url).then(responseReview => {
                        //console.log ("fetched reviews successfully")
                        dispatch({
                                type: FETCH_REVIEWS_ESTABLISHMENT,
                                reviews: responseReview.data.result,
                            })
                         })          
                     }})
          }})
}

export const fetchExperiencesEstablishment = (id, lang) => async dispatch => {
    try {
        const url = environment.apiUrl +'establishment/relatedexperiences/' + lang + '/' + id
        const response = await Axios.get(url)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_EXPERIENCES_ESTABLISHMENT,
            experiences: response.data.experiences,
        })
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_ESTABLISHMENT,
            api_error: true
        })
    }
}

export const fetchNeighborhoodEstablishment = (id, lang) => async dispatch => {
    try {
        const url = environment.apiUrl +'establishment/main/' + lang + '/' + id
        const response = await Axios.get(url)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_NEIGHBORHOOD_ESTABLISHMENT,
            neighborhood: response.data.neighborhood,
        })
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_ESTABLISHMENT,
            api_error: true
        })
    }
}

export const setEstablishmentId = (id) => dispatch => {
    dispatch({
        type: SET_ESTABLISHMENT_ID_ESTABLISHMENT,
        active_id: id
    })
}
export const setBucketListId = (id) => dispatch => {
    dispatch({
        type: SET_BUCKETLIST_ID_BUCKETLIST,
        active_id: id
    })
}



/*export const fetchReviewsEstablishment = (featureId, featureTypeId, featureUnitId) => async dispatch => {
    try {
        console.log ("fetchReviewsEstablishment")
        const url = "https://devapi.muvtravel.com/api/public/rating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + featureId + "&featureTypeId=" + featureTypeId + "&featureUnitId=" + featureUnitId
        //const url ="https://devapi.muvtravel.com/api/public/rating/getFeatureUnitAverageRating/en?featureId=" + featureId + "&featureUnitId=" + featureUnitId
        //const url = "https://devapi.muvtravel.com/api/public/rating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + featureId + "&featureTypeId=" + featureTypeId + "&featureUnitId=" + featureUnitId
        const response = await Axios.get(url)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        console.log (response.data.result)
        dispatch({
            type: FETCH_REVIEWS_ESTABLISHMENT,
            reviews: response.data.result,
        })
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_ESTABLISHMENT,
            api_error: true
        })
    }
}*/

export const fetchEstablishment = (id, lang) => async dispatch => {
    try {
        const feature = {
            featureId: "1",
            slug: "places",
            title: "Places",
            color: "#19BC9B",
            disable: "0",
        }
        dispatch({
            type: SET_FEATURE_FORMAT,
            featureSelected: feature
        })
        const response = await Axios.get(environment.apiUrl +'establishment/main/' + lang + '/' + id)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_GENERAL_INFO_ESTABLISHMENT,
            general_info: response.data.generalInfo,
        })
        dispatch({
            type: FETCH_BREADCRUMB_ESTABLISHMENT,
            breadcrumb: response.data.destinationData,
        })
        dispatch({
            type: FETCH_SUBTYPES_ESTABLISHMENT,
            subtypes: response.data.subtypes,
        })
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        let obj_reviews = {
            featureId: features_router.places.featureId,
            featureTypeId: response.data.generalInfo.typeId,
            featureUnitId: id,
        }

    
        const api_url_reviews = environment.apiUrl +"simpleRating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + obj_reviews.featureId + "&featureUnitId=" + obj_reviews.featureUnitId
        GetAsyncHelper(api_url_reviews)
            .then(responseBucketlistReviews => {
                if (responseBucketlistReviews !== undefined && responseBucketlistReviews.statusText === "OK") {
                    dispatch({
                        type: FETCH_REVIEWS_ESTABLISHMENT,
                        reviews: responseBucketlistReviews.data.result,
                    })
                } else {
                    dispatch({
                        type: FETCH_API_ERROR_ESTABLISHMENT,
                        api_error: true
                    })
                    dispatch({
                        type: FETCH_REVIEWS_ESTABLISHMENT,
                        reviews: null,
                    })
                }
            })

        
    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_ESTABLISHMENT,
            api_error: true
        })
    }
}

export const fetchBucketList = (id, lang) => async dispatch => {
    try {
        const feature = {
            id: "4",
            slug: "bucketlist",
            title: "Bucket List",
            color: "#fb884d",
            disable: "0",
        }
        dispatch({
            type: SET_FEATURE_FORMAT,
            featureSelected: feature 
        })
        const response = await Axios.get(environment.apiUrl +'bucketlist/main/' + lang + '/' + id)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_SLIDER_IMAGES_BUCKETLIST,
            slider_images: response.data.result.images,
        })
        dispatch({
            type: FETCH_GENERAL_INFO_BUCKETLIST,
            general_info: response.data.result.generalInfo,
        })
        dispatch({
            type: FETCH_BREADCRUMB_BUCKETLIST,
            breadcrumb: response.data.result.destinationData,
        })
        dispatch({
            type: FETCH_SUBTYPES_BUCKETLIST,
            subtypes: response.data.result.subtypes,
        })
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        let obj_reviews = {
            featureId: features_router.bucket_list.featureId,
            featureTypeId: response.data.result.generalInfo.typeId,
            featureUnitId: id,
        }
        const api_url_reviews = environment.apiUrl +"simpleRating/getMuvReviewsAndRatingsforFeatureUnit/en?featureId=" + obj_reviews.featureId + "&featureUnitId=" + obj_reviews.featureUnitId
        GetAsyncHelper(api_url_reviews)
            .then(responseBucketlistReviews => {
                if (responseBucketlistReviews !== undefined && responseBucketlistReviews.statusText === "OK") {
                    dispatch({
                        type: FETCH_REVIEWS_BUCKETLIST,
                        reviews: responseBucketlistReviews.data.result,
                    })
                } else {
                    dispatch({
                        type: FETCH_API_ERROR_BUCKETLIST,
                        api_error: true
                    })
                    dispatch({
                        type: FETCH_REVIEWS_BUCKETLIST,
                        reviews: null,
                    })
                }
            })

    } catch (error) {
        dispatch({
            type: FETCH_API_ERROR_BUCKETLIST,
            api_error: true
        })
    }
}


export const clearFeatureFormat = () => async dispatch => {
        const feature = {
            color: "#19BC9B",
            disable: "",
            featureId: "",
            slug: "",
            sorting: null,
            title: ""
        }
        dispatch({
            type: SET_FEATURE_FORMAT,
            featureSelected: feature 
        })
    }
