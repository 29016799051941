
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import Axios from 'axios';
import { environment } from '../../Environments/environment';
import { ALERT_BARS_HANDLE, GetAsyncHelper, PostAsyncHelper } from '../interfaces/types';

;


const initialState = {
  loading: false,
}

export const resetPasswordToken = createAsyncThunk(
  'LoginSlice/resetPasswordToken',
  async (query, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {

      const params = query;
      let apiURL = environment.apiUrl + `reset-password-do-reset`;
      const response = await PostAsyncHelper(apiURL, params);
      if (response && response.statusText === "OK") {
        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Password reset success!"
        })
        return response?.data?.result;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Failed to reset password"
      })


    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Failed to reset password"
      })
      return null;
    }
  }
)


export const verifyResetToken = createAsyncThunk(
  'LoginSlice/verifyResetToken',
  async (query, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {

      const params = query;
      let apiURL = environment.apiUrl + `reset-password-validate-token`;
      const response = await PostAsyncHelper(apiURL, params);
      if (response && response.statusText === "OK") {
  
        return response?.data?.result;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Reset password link expired, Please request a new link"
      })


    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Reset password link expired, Please request a new link"
      })
      return null;
    }
  }
)


export const resetPasswordSlice = createAsyncThunk(
  'LoginSlice/resetPasswordSlice',
  async (query, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {

      const params = query;
      let apiURL = environment.apiUrl + `request-reset-password-link`;
      const response = await PostAsyncHelper(apiURL, params);
      if (response && response.statusText === "OK") {

        dispatch({
          type: ALERT_BARS_HANDLE,
          message_type: "success",
          message: "Reset Password Email Sent!"
        })
        return response?.data?.result;
      }
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Failed to reset password"
      })

    }
    catch {
      dispatch({
        type: ALERT_BARS_HANDLE,
        message_type: "error",
        message: "Failed to reset password"
      })
      return null;
    }
  }
)

export const LoginSlice = createSlice({
  name: 'LoginSlice',
  initialState: initialState,
  reducers: {
    // updateSubDestinations: (state, action) => {
    // },
  },
  extraReducers: {
    [resetPasswordSlice.pending]: (state, action) => {
    },
    [resetPasswordSlice.fulfilled]: (state, action) => {

    },
  },
})

export const { } = LoginSlice.actions

export const LoginSliceReducer = LoginSlice.reducer;