import * as Actions from '../../../redux/actions'

import { ListIcon, ThIcon } from "../Common/SVGBank"
import { MinusSignIcon, PlusSignIcon } from "../Common/SVGBank"
import React, { Component } from 'react'

import FeatureTypeIcon from '../Common/FeatureTypeIcon'
import { connect } from 'react-redux'
import { list_container_mode, features_router } from '../Common/RegExValidate'
import { features } from 'process'

const Types_ = ({subtypes,isDestinationPage, feature, selectedTypes, selectedSubtypes, setSwitchFeatureTypeContainerOfFeature, isMobile,
    activeFeatureId, switchFeatureContainer, lang, setSwitchFeatureListContainer }) => {
    const [extender, setExtender] = React.useState(false)
    const typeOfFeatureDefaultDisplay = 6
    const isAllArray = feature.types.filter(t => t.isChecked)
    if (!isNaN(isMobile)) {
    }
    const allFeatures = JSON.stringify(feature.types);

    const featureTypes = feature?.types && feature?.types?.length > 0 ? [...feature?.types]:null;

    const checkSubtype = (type) => {
        return selectedSubtypes.find(val => val.type_id === type.typeID)
    }

    let typeList = isDestinationPage ? featureTypes?.filter(e => {
        return subtypes?.some(item => item?.id === e?.typeID); // take the ! out and you're done
     }): featureTypes;
    



    const checkAll = () => {
        var result = selectedTypes.filter((e1) => {
            return selectedSubtypes.some((e2) => {
                return e1.typeID === e2.type_id;          // assumes unique id
            });
        })
        return selectedTypes.length === typeList?.length && !(result.length > 0);
    }


    return (
        <div className="d-flex margin_top_20 featureTypes">
            {isMobile ?
                <div  style={{
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace:"nowrap"
                }}>
                    {feature !== undefined
                        && typeList !== undefined
                        && typeList?.length > 0
                        &&
                        <div>
                            <div className="featureType"
                                onClick={() => setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, typeList)}
                                style={{
                                    backgroundColor: "var(--mainWhite)",
                                    color: checkAll() ? switchFeatureContainer.color : "var(--frameBlack)",
                                    border: checkAll() ? "1px solid" + switchFeatureContainer.color : "1px solid #E8E8E8",
                                    height: "30px",
                                    display: "inline-flex",
                                    borderRadius: "25px",
                                    flexWrap: "wrap",
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    padding: ".375rem .75rem",
                                    marginRight: "6px",
                                }}>
                                <div className="featureTypeName" style={{
                                    font: "16px/18px Futura Lt BT",
                                    color: checkAll() ? switchFeatureContainer.color : "var(--frameBlack)",
                                }}>{"All"}</div>
                            </div>

                            {typeList?.map((type, type_index) => {
                                return (
                                    <div
                                        className="featureType"
                                        key={type_index}
                                        onClick={() => setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, typeList, type)}
                                        style={{
                                            backgroundColor: "var(--mainWhite)",
                                            color: type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "var(--frameBlack)",
                                            border: type.isChecked || checkSubtype(type) ? "1px solid" + switchFeatureContainer.color : "1px solid #E8E8E8",
                                            height: "30px",
                                            display: "inline-flex",
                                            borderRadius: "25px",
                                            flexWrap: "wrap",
                                            cursor: "pointer",
                                            verticalAlign: "middle",
                                            padding: ".375rem .75rem",
                                            marginRight: "6px",
                                        }}>
                                        <FeatureTypeIcon
                                            icon={type.icon}
                                            fill={type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "#707070"}
                                            style={{
                                                width: "15px",
                                                height: "16px",
                                                marginLeft: "5px",
                                                marginRight: "10px",
                                            }} />
                                        <div className="featureTypeName" style={{
                                            font: "16px/18px Futura Lt BT",
                                            color: type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "var(--frameBlack)",
                                        }}>{type.typeName}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                </div> :


                <div style={{ flexGrow: "1" }}>
                    {feature !== undefined
                        && typeList !== undefined
                        && typeList?.length > 0
                        && <div className="featureType"
                            onClick={() => setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, typeList)}
                            style={{
                                backgroundColor: "var(--mainWhite)",
                                color: checkAll() ? switchFeatureContainer.color : "var(--frameBlack)",
                                border: checkAll() ? "1px solid" + switchFeatureContainer.color : "1px solid #E8E8E8",
                                height: "30px",
                                display: "inline-flex",
                                borderRadius: "25px",
                                flexWrap: "wrap",
                                cursor: "pointer",
                                verticalAlign: "middle",
                                padding: ".375rem .75rem",
                                marginRight: "6px",
                            }}>
                            <div className="featureTypeName" style={{
                                font: "16px/18px Futura Lt BT",
                                color: checkAll() ? switchFeatureContainer.color : "var(--frameBlack)",
                            }}>{"All"}</div>
                        </div>}
                    {feature !== undefined
                        && typeList !== undefined
                        && typeList?.length > 0
                        && typeList?.map((type, type_index) => {
                            return (
                                (type_index < typeOfFeatureDefaultDisplay || extender) &&
                                <div
                                    className="featureType"
                                    key={type_index}
                                    onClick={() => setSwitchFeatureTypeContainerOfFeature(activeFeatureId, lang, typeList, type)}
                                    style={{
                                        backgroundColor: "var(--mainWhite)",
                                        color: type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "var(--frameBlack)",
                                        border: type.isChecked || checkSubtype(type) ? "1px solid" + switchFeatureContainer.color : "1px solid #E8E8E8",
                                        height: "30px",
                                        display: "inline-flex",
                                        borderRadius: "25px",
                                        flexWrap: "wrap",
                                        cursor: "pointer",
                                        verticalAlign: "middle",
                                        padding: ".375rem .75rem",
                                        marginRight: "6px",
                                    }}>
                                    <FeatureTypeIcon
                                        icon={type.icon}
                                        fill={type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "#707070"}
                                        style={{
                                            width: "15px",
                                            height: "16px",
                                            marginLeft: "5px",
                                            marginRight: "10px",
                                        }} />
                                    <div className="featureTypeName" style={{
                                        font: "16px/18px Futura Lt BT",
                                        color: type.isChecked || checkSubtype(type) ? switchFeatureContainer.color : "var(--frameBlack)",
                                    }}>{type.typeName}</div>
                                </div>
                            )
                        })
                    }
                    {typeList?.length > typeOfFeatureDefaultDisplay && !extender && <div onClick={() => setExtender(true)} style={{ display: "inline-block", }}><PlusSignIcon fill={switchFeatureContainer.color} /></div>}
                    {typeList?.length > typeOfFeatureDefaultDisplay && extender && <div onClick={() => setExtender(false)} style={{ display: "inline-block", }}><MinusSignIcon fill={switchFeatureContainer.color} /></div>}
                </div>}

            <div className="featureDisplay" >
                <div onClick={() => setSwitchFeatureListContainer(list_container_mode.list)}>
                    <ListIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                        fill={feature.featureListDisplayFormat === list_container_mode.list ? switchFeatureContainer.color : "var(--lightGray)"} />
                </div>
                <div onClick={() => setSwitchFeatureListContainer(list_container_mode.grid)}>
                    <ThIcon style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
                        fill={feature.featureListDisplayFormat === list_container_mode.grid ? switchFeatureContainer.color : "var(--lightGray)"} />
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        feature: state.Feature.feature,
        isMobile: state.Setting.is_mobile,
        activeFeatureId: state.Feature.feature.activeId,
        lang: state.Setting.lang,
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        subtypes: state.Feature.feature.subtypes,
        selectedTypes: state.Feature.feature.loading.selectedTypes,
        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}
const mapDispatchToProps = {
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    setSwitchFeatureListContainer: Actions.setSwitchFeatureListContainer,
}

const Types = connect(mapStateToProps, mapDispatchToProps)(Types_)
export default Types
