import * as Actions from '../../../../redux/actions'

import { CalendarDayDuotoneIcon, EllipsisVIcon, ListULIcon, MapDuotoneIcon, PrintIcon, TimelineIcon, TimesIcon } from "../SVGBank"

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import CopyTripDialog from "../CopyTripDialog"
import DeleteDialog from "../DeleteDialog"
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ModifyTripDateDialog from "../ModifyTripDateDialog"
import ModifyTripNameDialog from "../ModifyTripNameDialog"
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import ShareTripDialog from "../ShareTripDialog"
import { connect } from 'react-redux'
import { list_container_mode } from '../RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from '../RegExValidate'

/*  inject css to menu component */
const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  button_text: {
    color: "var(--mainGreen)",
    textAlign: "left",
    font: "16px/22px Futura Md BT",
    letterSpacing: "0",
    padding: "5px",
    '&:focus': {
      border: "0",
      outline: "0px"
    }
  },
  positionZIndex: style_props => ({
    zIndex: "34",
    top: "-30px !important",
    left: "-45px !important",
  }),
  paddingLeft30: {
    paddingLeft: "30px"
  },
  backgroundSelection: {
    '&:hover': {
      backgroundColor: "var(--mainGreen)"
    },
    minHeight: "10px"
  },
  paper: {
    marginRight: theme.spacing(2),
  },

  hr: {

    width: "65%",
    height: "1px",
    display: "block",
    position: "relative",
    marginRight: "10%",
    marginTop: "5px",
    marginLeft: "25%",
    marginBottom: "5px",
    border: "1px dashed rgba(0,0,0,.1)",
  },
  timesDiv: {
    marginRight: "10px",
    marginLeft: "115px",
    display: "inline-block",
    textAlign: "right",
  },
  hidden: {
    zIndex: -1,
    top: "-5000px !important",
  }

}))
/* menu list composition function component 

param  trip object

*/
const TripEllipsis_ = ({ trip, toggle_ellipsis_close_disable, setSelectedTripInformation, setTripToTimeline, screenSize, setSwitchContainer, setTripDisplayMode, uuid }) => {


  const style_props = { screenSize: Math.min(screenSize, document.body.clientWidth) }

  const classes = useStyles(style_props)
  const [open, setOpen] = React.useState(false)

  const [openTripAction, setOpenTripAction] = React.useState(false)
  const [openTripInformation, setOpenTripInformation] = React.useState(true)


  const anchorRef = React.useRef(null)
  const handleToggle = (event) => {
    event.preventDefault()
    setOpen(prevOpen => !prevOpen)
    setSelectedTripInformation(trip)
    setTripToTimeline(trip.tripID, uuid)
  }
  const handleClickTripAction = () => {
    setOpenTripAction(!openTripAction)
    setOpenTripInformation(false)
  }
  const handleClickTripInformation = () => {
    setOpenTripInformation(!openTripInformation)
    setOpenTripAction(false)
  }
  const handleClose = event => {
    if (!toggle_ellipsis_close_disable) {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }
      setOpen(false)
    }
  }

  const handleTripInformation = (from) => {
    setSwitchContainer(member_area_router.memberAreaTrip)
    setTripDisplayMode(from)
    setOpen(false)
  }
  const handlePrintTripAction = () => {
    window.open(`http://dev.muvtravel.com/trip/schedule_print/${trip.tripID}`)
  }

  const tripNoDate = trip.noDate
  return (
    <div className={classes.root}>

      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu_ellipsis' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.button_text}
          style={{ zIndex: "33" }}
        >
          {screenSize > 1024 ? <EllipsisVIcon /> : <EllipsisVIcon width='4' height='25' />}
        </Button>

        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            className={toggle_ellipsis_close_disable ? classes.hidden : classes.positionZIndex}
            transition
            disablePortal
            placement={'bottom'}
            modifiers={{
              flip: {
                enabled: false,
              },
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement }}
                {...({ timeout: 1000 })}
              >
                <Paper>
                  <MenuList
                    autoFocusItem={false}
                    disableListWrap={true}
                    dense={false}
                    id="menu_ellipsis" >
                    <div className={classes.timesDiv} onClick={handleClose}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>

                    <div>
                      <MenuItem className={classes.backgroundSelection} onClick={handleClickTripInformation}>{openTripInformation ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Mode"} </MenuItem>
                      <Collapse in={openTripInformation} timeout="auto" unmountOnExit>
                        <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.schedule)} ><ListULIcon />{list_container_mode.schedule}</MenuItem>
                        {tripNoDate === "0" && <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.calendar)}><CalendarDayDuotoneIcon />{list_container_mode.calendar}</MenuItem>}
                        <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.map)}><MapDuotoneIcon style={{ marginRight: "10px" }}
                          fill={"#707070"}
                          width={"20"}
                          height={"20"} />{list_container_mode.map}</MenuItem>
                        <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.timeline)}><TimelineIcon style={{ marginRight: "10px" }}
                          fill={"#707070"}
                          width={"20"}
                          height={"20"} />{list_container_mode.timeline}</MenuItem>
                      </Collapse>
                    </div>

                    <div className={classes.hr} ></div>
                    <MenuItem className={classes.backgroundSelection} onClick={handleClickTripAction}>{openTripAction ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Action"} </MenuItem>
                    <Collapse in={openTripAction} timeout="auto" unmountOnExit>
                      <MenuItem className={classes.backgroundSelection} ><ModifyTripDateDialog from={"Start Date"} inline={false} /></MenuItem>
                      <MenuItem className={classes.backgroundSelection} ><ModifyTripNameDialog from={"Trip Name"} inline={false} /></MenuItem>
                      <MenuItem className={classes.backgroundSelection} ><CopyTripDialog from={"Copy Trip"} inline={false} /></MenuItem>
                      <MenuItem className={classes.backgroundSelection} onClick={() => handlePrintTripAction()} ><PrintIcon />{"Print Trip"}</MenuItem>
                      <MenuItem className={classes.backgroundSelection} ><ShareTripDialog from={"Share Trip"} inline={false} /></MenuItem>
                    </Collapse>
                    <div className={classes.hr}></div>
                    <MenuItem className={classes.backgroundSelection} ><DeleteDialog  from={"Delete Trip"} setOpenTripEllipsis={setOpen} /></MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>

        </ClickAwayListener>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    is_mobile: state.Setting.is_mobile,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    uuid: state.Member.authModal.uuid,
    logo_height: state.Setting.appMenu.logo.height,
    lang: state.Setting.lang,

    toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,

  }
}

const mapDispatchToProps = {
  setSwitchListContainerMember: Actions.setSwitchListContainerMember,
  setTripToTimeline: Actions.setTripToTimeline,

  setSwitchContainer: Actions.setSwitchContainer,
  setTripDisplayMode: Actions.setTripDisplayMode,
  setSelectedTripInformation: Actions.setSelectedTripInformation,
}


const TripEllipsis = connect(mapStateToProps, mapDispatchToProps)(TripEllipsis_)
export default TripEllipsis