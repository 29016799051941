import * as Actions from '../../../../redux/actions'

import {
    BirthdayDatePiker,
    CultureTextField,
    EducationTextField,
    FacebookTextField,
    FirstNameTextField,
    FluencyTextField,
    GenderTextField,
    HometownAirportTextField,
    HometownTextField,
    InstagramTextField,
    LanguageTextField,
    LastNameTextField,
    OrientationTextField,
    PinterestTextField,
    ProfessionTextField,
    ReligionTextField,
    SnapchatTextField,
    TwitterTextField,
} from "../FormComponentsBank"
import { FacebookIcon, InstagramIcon, LeftArrowIcon, PinterestIcon, RightArrowIcon, SnapchatIcon, TwitterIcon } from '../SVGBank'
import {
    bioAutocompleteUseStyles,
    dropdownUseStyles,
    multiLineTextFieldUseStyles,
    multipltDropdownUseStyles,
} from "../ComponentsStylingBank"

import Clip from "../Clip"
import React from 'react'
import { connect, useSelector,useDispatch } from 'react-redux'
import { landing_router } from "../RegExValidate"
import { member_area_router } from "../../Common/RegExValidate"
import { useHistory } from 'react-router-dom';
import { createTripThunk, toggleAiTripLoading, updateSavedTrip } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice'
import { clearTimelineTrip } from '../../../../redux/actions'


const ProfileForm_ = ({ screenSize, languages, setSwitchProfileContainer, switch_container, uuid, lang, fetchProfileSelectedSets }) => {

    const [fetchedProfileSelected, setFetchProfileSelected] = React.useState(false)
    if (!fetchedProfileSelected) {
        setFetchProfileSelected(true)
        fetchProfileSelectedSets(uuid, lang)
    }
    const history = useHistory()
    const dispatch = useDispatch()
    const classesMultiLineTextField = multiLineTextFieldUseStyles()
    const classesDropdown = dropdownUseStyles()
    const savedPersonalTrip = useSelector(state => state.PersonalizedTrip.aiTrip.saved)
    const [creating, setCreating] = React.useState(false)
    const classesMultipleDropdown = multipltDropdownUseStyles()
    const classesAutocomplete = bioAutocompleteUseStyles()
    let screen_at_1200 = 1200
    let padding_l_r = "15"
    let col = "col-6"
    let arrow_container_padding_left_next = "15"
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "230px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left = "10"
    let previousStepMarginRight = "10px"
    let col_icon = 'col-1'
    let add_social_account_font = "24px/40px Futura Md BT"
    let notification_font = "14px/21px Futura Lt BT"
    let notification_margin_top = "140px"
    if (!isNaN(screenSize)) {
        padding_l_r = screenSize > screen_at_1200 ? "0" : "10"
        col = screenSize > screen_at_1200 ? "col-6" : "col-12"
        col_icon = screenSize > screen_at_1200 ? "col-1" : "col-2"
        arrow_icon_size = screenSize > screen_at_1200 ? 19 : 10
        arrow_font = screenSize > screen_at_1200 ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
        add_social_account_font = screenSize > screen_at_1200 ? "24px/40px Futura Md BT" : "18px/22px Futura Md BT"
        notification_font = screenSize > screen_at_1200 ? "14px/21px Futura Lt BT" : "10px/15px Futura Lt BT"
        notification_margin_top = screenSize > screen_at_1200 ? "140px" : "80px"
        arrow_container_width = screenSize > screen_at_1200 ? "230px" : "120px"
        arrow_container_height = screenSize > screen_at_1200 ? "60px" : "30px"
        arrow_container_padding_left = screenSize > screen_at_1200 ? "35" : "10"
        previousStepMarginRight = screenSize > screen_at_1200 ? "10px" : "20px"
        arrow_container_padding_left_next = screenSize > screen_at_1200 ? "15" : "7"

    }

    const generateTrip = async () => {

        if (uuid) {
            setCreating(true)
            dispatch(toggleAiTripLoading(true))
            dispatch(clearTimelineTrip()) ///remove any active trip

            if (savedPersonalTrip?.destinations && savedPersonalTrip?.destinations?.length > 0) {
                let destinations = [...savedPersonalTrip?.destinations];
                destinations = destinations?.map((dest) => {
                    return {
                        id: dest?.id,
                        duration: dest?.duration,

                    }
                })

                const tripPayload = {
                    ...savedPersonalTrip,
                    destinations: destinations,
                    uuid:uuid
                }
                const newTrip = await dispatch(createTripThunk(tripPayload));
                const addedTrip = newTrip.payload;

                const newTripId =  addedTrip?.tripID ||  addedTrip?.id;

                if (addedTrip && newTripId) {
                    const tripDestinationId = addedTrip?.tripDestinations &&
                        addedTrip?.tripDestinations?.length > 0 ? addedTrip?.tripDestinations[0]?.id : null;

                    if (tripDestinationId) {
                        // await generateFlight(tripDestinationId)
                    }
                    // setCreating(false)

                    dispatch(updateSavedTrip(null))
                    setTimeout(() => {
                        setCreating(false)
                        dispatch(toggleAiTripLoading(false))
                        const url = "/muver/trippage/" + newTripId
                        history.push(url.toLowerCase())
                    }, 2000);


                }
                else {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }

                setTimeout(() => {
                    setCreating(false)
                    dispatch(toggleAiTripLoading(false))
                }, 10000);
            }

        }
    }


    return (
        <div className="ml-0 mr-0 margin_top_20">
            <div className="row m-0 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <FirstNameTextField
                            label="First Name"
                            required={true}
                            id="first_name"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <LastNameTextField
                            label="Last Name"
                            required={false}
                            id="last_name"
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <HometownTextField
                            label="Hometown"
                            required={true}
                            id="hometown"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <HometownAirportTextField
                            label="Closest Airport"
                            required={false}
                            id="hometown_airport"
                        />
                    </div>
                </div>
            </div>
            <div className="row m-0 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesDropdown.root}>
                        <BirthdayDatePiker
                            label="Date of Birth"
                            id="birthday"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className={classesDropdown.root}>
                        <GenderTextField
                            label="Gender"
                            required={false}
                            id="gender"
                        />
                    </div>
                </div>
            </div>
            <div className="row ml-0 mr-0 margin_top_10 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesDropdown.root}>
                        <OrientationTextField
                            label="Orientation"
                            required={false}
                            id="orientation"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className={classesDropdown.root}>
                        <ReligionTextField
                            label="Religion"
                            required={false}
                            id="religion"
                        />
                    </div>
                </div>
            </div>
            <div className="row ml-0 mr-0 margin_top_10 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesMultipleDropdown.root}>
                        <ProfessionTextField
                            label="Profession(s)"
                            required={false}
                            id="profesion"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className={classesDropdown.root}>
                        <EducationTextField
                            label="Education"
                            required={false}
                            id="education"
                        />
                    </div>
                </div>
            </div>
            <div className="row ml-0 mr-0 margin_top_10 position-relative">
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: padding_l_r + "px",
                    paddingRight: "7.5px",
                }}>
                    <div className={classesMultipleDropdown.root}>
                        <CultureTextField
                            label="Culture(s)"
                            required={false}
                            id="culture"
                        />
                    </div>
                </div>
                <div className={col} style={{
                    marginTop: "10px",
                    paddingLeft: "7.5px",
                    paddingRight: padding_l_r + "px",
                }}>
                    <div className="row m-0 p-0">
                        <div style={{ width: "50%", paddingRight: "5px" }}>
                            <div className={classesDropdown.root}>
                                <LanguageTextField
                                    label="Language(s)"
                                    required={false}
                                    id="language"
                                />
                            </div>
                        </div>
                        <div style={{ width: "50%", paddingLeft: "5px" }}>
                            <div className={classesDropdown.root}>
                                <FluencyTextField
                                    label="Fluency"
                                    required={false}
                                    id="fluency"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row m-0 p-0">
                        {languages !== undefined && languages.map((language, idx) => { return <Clip key={idx} element={language} /> })}
                    </div>
                </div>
            </div>


            <div style={{
                marginTop: "30px",
                marginButtom: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                font: add_social_account_font,
                color: switch_container === member_area_router.personalInfo ? "#EFEFEF" : "#191919"
            }}>
                Add your social accounts.
            </div>
            <div className="margin_top_20 row ml-0 mr-0"
                style={{
                    paddingLeft: padding_l_r + "px",
                    paddingRight: padding_l_r + "px",
                }}>
                <div className={col_icon}>
                    <InstagramIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                </div>
                <div className="col-6">
                    <div className={classesMultiLineTextField.root}>
                        <InstagramTextField label=""
                            required={false}
                            id="instagram" />
                    </div>
                </div>
            </div>
            <div className="margin_top_20 row ml-0 mr-0"
                style={{
                    paddingLeft: padding_l_r + "px",
                    paddingRight: padding_l_r + "px",
                }}>
                <div className={col_icon}>
                    <FacebookIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                </div>
                <div className="col-6">
                    <div className={classesMultiLineTextField.root}>
                        <FacebookTextField label=""
                            required={false}
                            id="facebook" />
                    </div>
                </div>
            </div>
            <div className="margin_top_20 row ml-0 mr-0"
                style={{
                    paddingLeft: padding_l_r + "px",
                    paddingRight: padding_l_r + "px",
                }}>

                <div className={col_icon}>
                    <TwitterIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                </div>
                <div className="col-6">
                    <div className={classesMultiLineTextField.root}>
                        <TwitterTextField label=""
                            required={false}
                            id="twitter" />
                    </div>
                </div>
            </div>
            <div className="margin_top_20 row ml-0 mr-0"
                style={{
                    paddingLeft: padding_l_r + "px",
                    paddingRight: padding_l_r + "px",
                }}>
                <div className={col_icon}>
                    <PinterestIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                </div>
                <div className="col-6">
                    <div className={classesMultiLineTextField.root}>
                        <PinterestTextField label=""
                            required={false}
                            id="pinterest" />
                    </div>
                </div>
            </div>
            <div className="margin_top_20 row ml-0 mr-0"
                style={{
                    paddingLeft: padding_l_r + "px",
                    paddingRight: padding_l_r + "px",
                }}>
                <div className={col_icon}>
                    <SnapchatIcon style={{ marginTop: "15px", marginLeft: "15px" }} />
                </div>
                <div className="col-6">
                    <div className={classesMultiLineTextField.root}>
                        <SnapchatTextField label=""
                            required={false}
                            id="snapchat" />
                    </div>
                </div>
            </div>
            <div className="position-relative margin_top_20">
                <div onClick={() => setSwitchProfileContainer(landing_router.bio)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#E8E8E8",
                        paddingLeft: arrow_container_padding_left + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        left: "0px",
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "#707070",
                    }}>
                        <LeftArrowIcon width={arrow_icon_size} height={arrow_icon_size} fill={"#707070"} style={{ marginRight: previousStepMarginRight }} />   Previous Step
                    </div>
                </div>
                <div onClick={() => setSwitchProfileContainer(landing_router.personalization)}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "var(--mainGreen)",
                        paddingLeft: switch_container !== member_area_router.personalInfo ? arrow_container_padding_left_next + "px" : arrow_container_padding_left + "px",
                        height: arrow_container_height,
                        width: arrow_container_width,
                        marginTop: "10px",
                        display: "block",
                        position: "absolute",
                        border: "0",
                        right: "0px",
                    }}>
                    <div style={{
                        textAlign: "left",
                        font: arrow_font,
                        letterSpacing: "0",
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        msTransform: "translateY(-50%)",
                        transform: "translateY(-50%)",
                        color: "var(--mainWhite)",
                    }}>
                        {switch_container !== member_area_router.personalInfo ? "Give us more details" : "Next Step"} <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: switch_container !== member_area_router.personalInfo ? "10px" : "40px" }} />
                    </div>
                </div>
            </div>

            {savedPersonalTrip && <div style={{
                    cursor: "pointer",
                    paddingLeft: arrow_container_padding_left_next + "px",
                    marginTop: "100px",
                    display: "block",
                    color: "var(--mainGreen)",
                    border: "0",
                    textAlign:"right"
                    // padding-left: 5px;
                }}>
                  
                        <p href="#" onClick={generateTrip}>{creating? "Generating trip..." : "Skip to my suggested trip"}</p> 
                   
                </div>}

            <div style={{
                height: "50px",
                marginTop: notification_margin_top,
                marginButtom: "10px",
                background: "rgba(25, 188, 155,.14)",
                position: "relative",
            }}>
                <div style={{
                    color: "#19BC9B",
                    font: notification_font,
                    margin: "0",
                    position: "absolute",
                    left: "1%",
                    top: "50%",
                    msTransform: "translateY(-50%)",
                    transform: "translateY(-50%)",
                    opacity: "1.0",
                }}>
                    SAFE &amp; SECURE: Your information will only be used to match you with like-minded people and personalized experiences
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    languages: state.FormBank.languageTextField.languageFluency,
    switch_container: state.Member.switch_container,

    uuid: state.Member.authModal.uuid,
    lang: state.Setting.lang,
})

const mapDispatchToProps = {
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,
    fetchProfileSelectedSets: Actions.fetchProfileSelectedSets,
}

const ProfileForm = connect(mapStateToProps, mapDispatchToProps)(ProfileForm_)
export default ProfileForm