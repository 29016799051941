import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Grid } from "@material-ui/core"
import moment from "moment"


import './TripSection.css';
import { AirplaneComponent } from '../FlightsPage/flightsComponents'
import { secondsToHms, tConv24 } from '../../../utility/utility';
import { resetFlightPage, updateFlightData } from '../FlightsPage/services/Flights.slice';
import { checkFlightAvailable, getFlightsListThunk } from '../FlightsPage/services/Flights.thunks';
import { useHistory, useLocation } from "react-router-dom"
import MuverTripFlightItem from './MuverTripFlightItem';

const MuverTripFlight_ = ({ destinationFlights,sliceIndex, tripDestinations, currentDestIndex, flight, isMobile, bodyClientWidth, logo_width }) => {
    const departureDate = flight?.departure_date ? moment(flight.departure_date).format('ddd MMM D') : "";
    const arrivalDate = flight?.arrival_date ? moment(flight.arrival_date).format('MMM Do') : "";
    const booked = flight?.booked_booking_status === "ACCEPTED" || flight?.booked_booking_status_code === "ACCEPTED";
    const email = flight?.booked_email;
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const { uuid } = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const bookingId = flight?.priceline_trip_number || flight?.booking_id;
    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2
    const marginLeft = isMobile ? 0 : 150;
    const history = useHistory()
    const dispatch = useDispatch()
    const [flightAvailable, setAvailable] = React.useState(true)
    const [checkingFlight, setChecking] = React.useState(false)
    const [initialCheck, setInitialCheck] = React.useState(false)


    React.useEffect(() => {


        if (flight) {
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;

            const isAvailable = async () => {
                const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
                setAvailable(res)
                setInitialCheck(true)
            }
            if (ppnId) {
                isAvailable()
            }


        }
    }, [flight, fullCurrency])




    const goToflightDetails = () => {
        history.push(`/flight/booking_details?booking_id=${bookingId}&email=${email}`)
    }


    const isAvailable = async () => {
        const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
            flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;


        setChecking(true)
        const res = await checkFlightAvailable(ppnId, fullCurrency, dispatch)
        setAvailable(res)
        setChecking(false)
    }


    const searchFlight = async () => {
        let available = false;

        if (!flightAvailable && initialCheck) {
            available = await isAvailable();
        }

        if (available || (flightAvailable && initialCheck)) {
            const ppnId = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.ppn_contract_bundle;
            history.push(`/package?bundleId=${ppnId}`)

        }
        else {
            let flights = []

            if (flight?.search_type === "round_trip" && destinationFlights && destinationFlights?.length > 1) {
                destinationFlights.forEach((flight, index) => {
                    flights.push({
                        "airportFrom": {
                            "city": flight?.departure_city,
                            "latitude": "",
                            "city_code": flight?.departure_iata,
                            "type": "main",
                            "iata": flight?.departure_iata,
                            "airport": "All Airports"
                        },
                        "airportTo": {
                            "city": flight?.arrival_city,
                            "city_code": flight?.arrival_iata,
                            "type": "main",
                            "iata": flight?.arrival_iata,
                            "airport": "All Airports"
                        },
                        "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "availableSelectDate": new Date().toDateString(),
                        "positionId": "d-" + index
                    })
                })
            }
            else {
                flights = [
                    {
                        "airportFrom": {
                            "city": flight?.departure_city,
                            "latitude": "",
                            "city_code": flight?.departure_iata,
                            "type": "main",
                            "iata": flight?.departure_iata,
                            "airport": "All Airports"
                        },
                        "airportTo": {
                            "city": flight?.arrival_city,
                            "city_code": flight?.arrival_iata,
                            "type": "main",
                            "iata": flight?.arrival_iata,
                            "airport": "All Airports"
                        },
                        "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                        "availableSelectDate": new Date().toDateString(),
                        "positionId": "d-0"
                    }]

            }

            const flightMode = flight?.search_type && destinationFlights?.length === 1 ? "round_trip" : 'one_way';
            const numPassengers = flight?.flight_json?.itinerary_data?.length > 0 &&
                flight?.flight_json?.itinerary_data[0]?.price_details?.number_of_passengers;
            const dataToBookingFlights = {
                flightMode: flightMode,
                cabinClass: 'economy',
                numberAdult: numPassengers,
                numberChild: '0',
                flights: flights,
                currency: fullCurrency

            }

            const flightDates = [...flights]?.map((flight) => {
                const formatDate = moment.parseZone(flight?.departureDate)?.format("YYYY-MM-DD")?.toString()?.split("-");
                const dateString = `${formatDate[2]}${formatDate[0] < 10 ? "0" + formatDate[0] : formatDate[0]}${formatDate[1] < 10 ? "0" + formatDate[1] : formatDate[1]}`
                return `${flight?.airportFrom?.iata}-${flight?.airportTo?.iata}-${formatDate?.join('') ?? ""}`
            })


            let flightUrl = `/flight/search?flightMode=${flightMode}&cabinClass=${flight?.cabinClass || 'economy'}&numberAdult=${numPassengers}&numberChild=${flight?.numberChild || 0}&flights=${flightDates?.toString()}`;
            // dispatch(updateFlightData(dataToBookingFlights))
            dispatch(resetFlightPage())
            // dispatch(getFlightsListThunk(dataToBookingFlights))

            history.push(flightUrl)
        }

    }


    const stopsFlight = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? Number(flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.info.connection_count) : 0;
    let notesArray = [];
    const stopNotes = flight?.flight_json?.itinerary_data && flight?.flight_json?.itinerary_data[0]?.slice_data ? flight?.flight_json?.itinerary_data[0]?.slice_data[0]?.flight_data?.map((flight) => flight?.info?.notes) : null
    const notesData = stopNotes && stopNotes?.length > 0 ? stopNotes.forEach((notes) => {
        notesArray = notesArray?.concat(notes)
    }) : null;
  
    notesArray = notesArray?.map((note) => secondsToHms(note?.duration));
        notesArray = notesArray.filter(n => n);
    const durationText = notesArray && notesArray?.length > 0 ? notesArray?.length > 1? notesArray?.join(" & "):notesArray : null
    const priceDifference = flight?.price_display_total_fare && flightAvailable?.itinerary_data?.price_details?.display_total_fare ? Number(flightAvailable?.itinerary_data?.price_details?.display_total_fare - flight?.price_display_total_fare)?.toFixed(2) : null;

    const flightPerTicket = flight?.flight_json?.itinerary_data ? flight?.flight_json.itinerary_data[0]?.price_details?.display_total_fare_per_ticket : null;

    const confirmationNumber = flight?.booking_number || flight?.priceline_trip_number;


    return (
        <div style={{
            position: "relative",
            margin: "0px",
            background: "#0D3A7D1A",
            color: "#000000",
            // border: "1px solid #707070",
            width: isMobile ? "100%" : "81%",
            marginLeft: marginLeft,
            marginTop: 20,
            marginBottom: 20,
            padding: 30
        }} className="muverFlightItem">
            <MuverTripFlightItem flight={flight} sliceValue={sliceIndex}/>
           
        </div>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,

    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}

const MuverTripFlight = connect(mapStateToProps, mapDispatchToProps)(MuverTripFlight_)
export default MuverTripFlight
