import * as Actions from '../../../../redux/actions';

import React, { Component } from 'react'

import MenuLanding from '../../LandingPage/MenuLanding'
import TravelInterestsForm from "./TravelInterestsForm"
import { connect } from 'react-redux'
import { landing_router } from '../RegExValidate';
import YourTastesForm from './YourTastesForm';
import PersonalizationForm from './PersonalizationForm';

class YourTastes_ extends Component {

    render() {
        const { screenSize, switch_container, app_menu_height, is_mobile } = this.props;

        let position = "sticky"
        let text_height = "85px"
        let screen_at_1200 = 1200
        let padding_8_percent = "8.5%"

        if (!isNaN(screenSize)) {
            text_height = screenSize < screen_at_1200 ? "40px" : "85px"
            padding_8_percent = screenSize < screen_at_1200 ? "2.5%" : "8.5%"
        }
        return (
            <div style={{ padding: padding_8_percent }}>
                {screenSize >= screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        font: "30px/40px Futura Hv BT",
                    }}>
                        Share your likes and dislikes
                    </div>
                    : ""
                }
                <div style={{ position: position, zIndex: "3", top: is_mobile ? app_menu_height : Math.ceil(app_menu_height / 2) }}>
                    <MenuLanding />
                </div>
                {screenSize < screen_at_1200 ?
                    <div style={{
                        height: text_height,
                        marginTop: "20px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        font: "18px/22px Futura Hv BT",
                    }}>
                        {switch_container === landing_router.interests ? "Define your travel personality" :
                            switch_container === landing_router.tastes ? "Share your likes and dislikes" : "Congratulations, you have successfully signed in."}
                    </div>
                    : ""
                }
                <div style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                }}>
                    <div
                        style={{
                            font: "18px/40px Futura Hv BT",
                            color: "#000000",
                            letterSpacing: "0",
                            textAlign: "left",
                            marginTop: "20px",
                        }}
                    >
                        Rate these travel categories and never miss out again on places you will most likely enjoy
                        <div
                            style={{
                                font: "14px/40px Futura Lt BT",
                                color: "#707070",
                                letterSpacing: "0",
                                textAlign: "left",
                            }}
                        >
                            Expand each section with the right arrows to rate subcategories and get even more accurate recommendations
                        </div>
                    </div>
                    <PersonalizationForm />
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    is_mobile: state.Setting.is_mobile,
    switch_container: state.Member.profile_container,
})

const mapDispatchToProps = {
}
const YourTastes = connect(mapStateToProps, mapDispatchToProps)(YourTastes_)
export default YourTastes
