import * as Actions from '../../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from '../RegExValidate'
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices'

const EstablishmentName_ = ({ establishmentId, pricelineId, setHotelEstablishmentId, establishmentName, setSwitchContainer, 
                divClass = "", establishmentCity = "", establishmentState = "", establishmentCountry = "", titleClass, titleStyle, lang, 
                hotel_form_state, ppn_bundle }) => {
  const history = useHistory()
  const city = establishmentCity !== null && establishmentCity !== "" ? "_" + establishmentCity.replace(URL_REPLACE, "_") : ""
  const state = establishmentState !== null && establishmentState !== "" ? "_" + establishmentState.replace(URL_REPLACE, "_") : ""
  const country = establishmentCountry !== null && establishmentCountry !== "" ? "_" + establishmentCountry.replace(URL_REPLACE, "_") : ""
  const handleClick = () => {
    setHotelEstablishmentId(pricelineId)
    setSwitchContainer(member_area_router.establishment)
    const encryptedId =  encryptID(pricelineId)
    history.push("/hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + encryptedId
              + "?city_id=" + hotel_form_state.city_id 
              + "&check_in=" + hotel_form_state.check_in 
              + "&check_out=" +hotel_form_state.check_out 
              + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1) 
              + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
              + "&city_lat=" + (hotel_form_state.city_latitude)
              + "&city_lng=" + (hotel_form_state.city_longitude)
              + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
              + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
  }
  
  return (
    <div style={{ display: "inline-block" }} className={divClass}>
      <div style={titleStyle} className={titleClass} onClick={() => handleClick()}>
        {establishmentName}
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  lang: state.Setting.lang,
  hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
  setSwitchContainer: Actions.setSwitchContainer,
  setHotelEstablishmentId: Actions.setHotelEstablishmentId,
}

const EstablishmentName = connect(mapStateToProps, mapDispatchToProps)(EstablishmentName_)
export default EstablishmentName