import * as Actions from '../../../redux/actions'
import { EllipsisVIcon, TimesIcon } from "../Common/SVGBank"

import { Discover } from "../TripPage/Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import MemberActionContainer from "../Common/MemberActions/MemberActionContainer"
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"

const StarredContainer_ = ({ is_mobile, uuid, lang, getMemberActionsSets, screenSize }) => {
    let padding_l = "15.7"
    let padding_r = "15.7"
    let font_size = is_mobile? "28":"36"
    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : "15.7"
        padding_r = is_mobile ? "1" : "15.7"
    }
    const actionType = "star"
    const [loaded, setLoaded] = React.useState(false)
    const history = useHistory()

    React.useEffect(() => {
        if (!loaded && uuid !== undefined && uuid !== null && uuid !== "") {
            setLoaded(true)
            getMemberActionsSets(uuid, lang, actionType)
        }
    }, [uuid])



    const goBack =()=>{
       history.push('/')
    }
    return (
        <div style={{
            marginTop: 15,
            overflowX:"hidden"
        }} > 
            <div onClick={goBack}  
                style={is_mobile? 
                    {height: "40px",zIndex:10,position:'', width: "100%" ,textAlign: "right", font:"14px/18px Futura Md BT", color:"#000000", marginTop:"-2px",
                paddingRight:15}
                :{height: "40px", width: "40px" ,float: "right", textAlign: "right", font:"15px/18px Futura Md BT", color:"#000000", marginTop:"80px", paddingRight: "200px"}} >
            <TimesIcon style={{ marginTop: "5px" }} width={is_mobile? '30':'40'} height={is_mobile? '30':'40'}/>
            
            </div>
            <div 
            className={`padding_125rem_lr_mobile`}
                style={{
                    paddingBottom: "0px",
                    color: "#000000",
                    font: font_size + "px/70px Futura Hv BT",
                    paddingLeft: padding_l + "%",
                    paddingRight: padding_r + "%",
                    paddingTop:is_mobile? 10:50
                }}
            >My saved items
            </div>
            

            {loaded && <div style={{
                width:is_mobile? "100%": "75%",
                marginLeft: is_mobile? "2%" : "14%",
                marginRight:is_mobile? "0%" : "0%",
                // margin: "20px"
                paddingLeft:is_mobile? padding_l + "%" : "0%",
                // paddingRight: padding_r + "%",
            }}>
                <MemberActionContainer actionType={actionType} />
            </div>}


            { is_mobile?<div></div> : <div style={{
                paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                background: "#efefef",
                marginTop: is_mobile ? "50px" : "150px",
            }}>

                <Discover />
            </div>}

            <FooterWhite />
        </div>
    )
}

const mapStateToProps = (state) => ({
    screenSize: state.Setting.htmlBody.bodyClientWidth,

    is_mobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

})

const mapDispatchToProps = {
    getMemberActionsSets: Actions.getMemberActionsSets,

}

const StarredContainer = connect(mapStateToProps, mapDispatchToProps)(StarredContainer_)
export default StarredContainer
