
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import Axios from 'axios';
import { environment } from '../../../../Environments/environment';
import { GetAsyncHelper, PostAsyncHelper } from '../../../../redux/interfaces/types';


const initialState = {
    isRoadTrip: false,
    trip: {},
    destinationNearByPlaces: [],
    destinationNearByDestinations: [],
    destinationPlacesCoordinates:[],
    suggestedDestinationsMap: [],
    suggestedCategoriesMap:[],
    timelineHotels:null,
    timelineFlights: null,
    bookingDestination:null
}

const compressCoords = (data) =>{
    let newData = [];
    if(data){
        const length = data.length;
        const  postSize = 44;
     
        if(length > postSize){
             const divider = Math.floor(length / postSize);
               newData = data.filter((val,index) => index % divider === 0  )
        }
    }
    return newData;
}

export const getDestinationNearbyPlaces = createAsyncThunk(
    'ActiveTrip/getDestinationNearbyPlaces',
    async (query, thunkAPI) => {
      try{

        const coords = JSON.parse(JSON.stringify(compressCoords(query.coords)));
        const dispatch = thunkAPI.dispatch;
        dispatch(updateDestinationCoords(coords)) ///add coordinates to redux
        let apiURL = environment.apiUrl + "muvsearch/searchNearbyPlacesByCoordinatesAndRadius/en";
        const point = {
            limit: 50,
            radius:25,
            coordinates: coords,
            typeIds: query.selectedTypes
        }
        const response = await PostAsyncHelper(apiURL, point);
       if(response && response.data ){
           return response.data;
       }
      }
      catch{
        return null;
      }
    }
)


export const getSuggestedDestinationCategory = createAsyncThunk(
  'ActiveTrip/getSuggestedDestinationCategory',
  async (query, thunkAPI) => {
    try{

      let apiURL = environment.apiUrl + "muvsearch/getDestinationCategoryOptions";
      const point = {
        limit: 50,
        coordinates: query.mapBounds,
        level: query?.level || 4
    }
    const response = await PostAsyncHelper(apiURL, point);
     if(response && response?.data?.result ){
      return response?.data?.result;
     }
    }
    catch(error){
      return null;
    }
  }
)

export const getSuggestedDestinationsMap = createAsyncThunk(
  'ActiveTrip/getSuggestedDestinationsMap',
  async (query, thunkAPI) => {
    try{

      let apiURL = environment.apiUrl + "muvsearch/getDestinationsListingInPolygon";
      const point = {
          limit: 50,
          coordinates: query.mapBounds,
          categories: query?.categories,
          level: query?.level || 4
      }
      const response = await PostAsyncHelper(apiURL, point);
     if(response && response?.data?.result ){
      // const destinations  = query.destinations || [];
      // let nearBy = response?.data?.result.filter(e => {
      //     return !destinations.some(item => item.name === e.name);
      //  });
      return response?.data?.result;
     }
    }
    catch(error){
      return null;
    }
  }
)


export const getDestinationNearbyDestinations = createAsyncThunk(
    'ActiveTrip/getDestinationNearbyDestinations',
    async (query, thunkAPI) => {
      try{

        const coords = JSON.parse(JSON.stringify(query.coords));
        let apiURL = environment.apiUrl + "muvsearch/searchDestinationsInPolygon/en";
        const point = {
            // limit: 400,
            coordinates: query.mapBounds,
            size: query?.size
            // level: 4
        }
        const joinValues = query?.join;
        const {ActiveTrip} = thunkAPI.getState();
        let nearByDest = [...ActiveTrip?.destinationNearByDestinations]

       
        const response = await PostAsyncHelper(apiURL, point);
       if(response && response?.data?.result ){

        let destinationResponse =response?.data?.result;
        ///second search
        if(joinValues){
          destinationResponse = nearByDest.concat(destinationResponse);
        }

        // const destinations  = query.destinations || [];
        // let nearBy = destinationResponse?.filter(e => {
        //     return !destinations.some(item => item.name === e.name);
        //  });
        return destinationResponse;
       }
      }
      catch{
        return null;
      }
    }
)


export const setTripStartingPoint = createAsyncThunk(
    'ActiveTrip/setTripStartingPoint',
    async (query, thunkAPI) => {
      try{

        const dispatch = thunkAPI.dispatch;
        let apiURL = environment.apiUrl + `memberarea/trip/setTripStartingPoint/en/${query.uuid}`;
        const trip = query.trip;
        const startingPoint = query.startingPoint;
        
        const destination = {
            tripId: trip.tripID,
            name:startingPoint.name,
            latitude:startingPoint.latitude,
            longitude:startingPoint.longitude,
            cityName: startingPoint.name,
            stateName:startingPoint.stateName,
            countryName:startingPoint.countryName,
            description:startingPoint.uri,
            muvDestinationId:startingPoint.destinationId,
            googlePlacesId:startingPoint.googlePlacesId,
        }
        const response = await PostAsyncHelper(apiURL, destination);
       if(response && response.data ){

           return response.data;
       }
      }
      catch{
        return null;
      }
    }
)

export const getTimelineHotels = createAsyncThunk(
    'ActiveTrip/getTimelineHotels',
    async (query, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      try {
  
        const uuid = query.uuid;
        const tripId = query.tripId;
        let apiURL = environment.apiUrl + `timeline/getTripHotelsInfo/en/${uuid}?tripId=${tripId}`;
  
        const response = await GetAsyncHelper(apiURL);
  
        if (response && response.statusText === "OK") {
          return response?.data?.result;
        }
  
      }
      catch {
       
        return null;
      }
    }
  )


export const getTimelineFlights = createAsyncThunk(
    'ActiveTrip/getTimelineFlights',
    async (query, thunkAPI) => {
      const dispatch = thunkAPI.dispatch;
      try {
  
        const uuid = query.uuid;
        const tripId = query.tripId;
        let apiURL = environment.apiUrl + `timeline/getTripFlightsInfo/en/${uuid}?tripId=${tripId}`;
  
        const response = await GetAsyncHelper(apiURL);
  
        if (response && response.statusText === "OK") {
          return response?.data?.result;
        }
  
      }
      catch {
       
        return null;
      }
    }
  )



export const lookUpSavedHotel = createAsyncThunk(
  'ActiveTrip/lookUpSavedHotel',
  async (query, thunkAPI) => {
      const lat = query?.lat;
      const lng = query?.lng;
      const check_in = query?.check_in;
      const check_out = query?.check_out;

      try {
          const response = await Axios.request({
              url: environment.apiUrl + 'proxy/dispatchRequest',
              method: 'get',
              params: {
                "proxyData[format]": "json2",
                "proxyData[latitude]":lat,
                "proxyData[longitude]":lng,
                "proxyData[radius]":25,       
                "proxyData[check_in]":check_in,
                "proxyData[check_out]":check_out,
                "proxyData[sort_by]":"lp",  // lowest price
                  proxyApiMethod: "GET",
                  proxyApiProperty: "hotel",
                  proxyApiEndpoint: "getExpress.Availability"
              },
          })

          if (response.data.ProxyResult["getHotelExpress.Availability"]) {
              const data = response.data.ProxyResult["getHotelExpress.Availability"].results;
              return data;
          }
      }
      catch (err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }

  }
)




export const ActiveTripSlice = createSlice({
    name: 'ActiveTrip',
    initialState: initialState,
    reducers: {
        updateRoadTrip: (state, action) => {
            state.isRoadTrip = action.payload;
        },
        updateActiveTrip: (state, action) => {
            state.trip = action.payload;
        },
        updateDestinationCoords: (state, action) => {
            state.destinationPlacesCoordinates = action.payload;
        },
        updateSuggestedDestinationsMap: (state, action) => {
          state.suggestedDestinationsMap = action.payload;
      },
      updateSuggestedCategoriesMap: (state, action) => {
        state.suggestedCategoriesMap = action.payload;
    },
        
    updateBookingDestination: (state, action) => {
      state.bookingDestination = action.payload;
  },
    },
    extraReducers: {
        [getDestinationNearbyPlaces.pending]: (state, action) => {
        },
        [getDestinationNearbyPlaces.fulfilled]: (state, action) => {
            state.destinationNearByPlaces = action.payload;
        },
        [getDestinationNearbyDestinations.pending]: (state, action) => {
        },
        [getDestinationNearbyDestinations.fulfilled]: (state, action) => {
            state.destinationNearByDestinations = action.payload;
        },
        
        [getTimelineHotels.pending]: (state, action) => {
          state.timelineHotels = null;
      },
        [getTimelineHotels.fulfilled]: (state, action) => {
            state.timelineHotels = action.payload;
        },
        [getTimelineFlights.pending]: (state, action) => {
          state.timelineFlights = null;
      },
        [getTimelineFlights.fulfilled]: (state, action) => {
            state.timelineFlights = action.payload;
        },
        [getSuggestedDestinationsMap.pending]: (state, action) => {
        },
        [getSuggestedDestinationsMap.fulfilled]: (state, action) => {
            state.suggestedDestinationsMap = action.payload;
        },
        [getSuggestedDestinationCategory.pending]: (state, action) => {
        },
        [getSuggestedDestinationCategory.fulfilled]: (state, action) => {
            state.suggestedCategoriesMap = action.payload;
        },
    },
})

export const {updateBookingDestination, updateSuggestedDestinationsMap, updateRoadTrip,updateActiveTrip,updateDestinationCoords } = ActiveTripSlice.actions

export const ActiveTripReducer = ActiveTripSlice.reducer;
