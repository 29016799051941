import { Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, Link, makeStyles, Modal, Select, TextField } from '@material-ui/core'
import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"

import moment from 'moment';
import { RightArrowIcon, TimesIcon } from '../Common/SVGBank';
import { AIModalUseStyles } from '../Common/BookingForm/AiPersonalizedTrip/AiPersonalizedTripStyles';
import { flightTextField } from '../FlightsPage/flightStyles';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-number-input'
import './PhoneStyle.css'
import { loginWithToken, setOpenAuthModal } from '../../../redux/actions';
import { GenerateOTP, VerifyOTP } from './OTPRegistration.services';
import { ALERT_BARS_HANDLE } from '../../../redux/interfaces/types';


export const OTPRegistrationModal = () => {

    const lang = useSelector(state => state.Setting.lang)
    const is_mobile = useSelector(state => state.Setting.is_mobile)
    const uuid = useSelector(state => state.Member.authModal.uuid)
    const tripId = useSelector(state => state.FormBank.TimelineWizard.activeTrip.tripId);
    const destination = useSelector(state => state.Destination.destination)
    const classes = OTPModalUseStyles();
    const [showModal, setShowModal] = React.useState(false)
    const [creating, setCreating] = React.useState(false)
    const [messageSent, setMessageSent] = React.useState(false)
    const dispatch = useDispatch()
    const [otp, setOtp] = React.useState("")
    const [messageType, setMessageType] = React.useState("email")
    const [contactInfo, setContactInfo] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
    })
    const [sessionId, setSessionId] = React.useState()
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("PROMO-CODE" || "promo-code");

    const useOTP = promoCode && promoCode?.toLowerCase() === "otp";

    const establishment = useSelector(state => state.FormBank.HotelSearch.establishment);
    const hotelRooms = establishment?.generalInfo?.room_data?.room_data;

    const closeModal = () => {
        setShowModal(false)
    }


    const signIn = () => {
        dispatch(setOpenAuthModal(true))
    }


    const handleMessageType = (type) => {
        setMessageType(type)
    }


    const updateValue = (field, value) => {
        const contact = { ...contactInfo };
        contact[field] = value;
        setContactInfo(contact)
    };

    const onChangeOtp = (val) => {
        setOtp(val)

        if (val?.length === 6) {
            verifyOTPCode(val)
        }
    }

    const verifyOTPCode = async (otp) => {
        setCreating(true)
        let obj = {
            sessionID: sessionId,
            otp: otp,
            first_name: contactInfo?.first_name ?? "",
            last_name: contactInfo?.last_name ?? "",
            phone: contactInfo?.phone ?? ""
        }
        const res = await VerifyOTP(obj)

        const token = res?.result?.userToken
        if (token) {
            dispatch(loginWithToken(token))

            setTimeout(() => {
                closeModal()
            }, 800);
        }
        else {
            setOtp("")
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Failed to verify OTP"
            })
        }
        setCreating(false)
    }


    const classesAutocomplete = OTPTextField()

    const setPhone = (val) => {
        updateValue('phone', val)
    }


    const sendRegistration = (type) => {
        if (type === "email") {
            sendEmailCode()
        }
        if (type === "sms") {

        }
        setMessageSent(true)
    }

    const sendEmailCode = async () => {
        setCreating(true)
        const res = await GenerateOTP(contactInfo?.email);
        const sessionId = res?.result?.otp_unique_session_id;

        if (sessionId) {
            setSessionId(sessionId ?? null);
        }
        else {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "error",
                message: "Failed to send email"
            })
        }


        setCreating(false)

    }

    const sendSmsCode = () => {

    }


    const isLoggedIn = () => {
        return uuid && uuid.length > 0
    }

 

    React.useEffect(() => {
        if ( uuid && uuid.length > 0) {
            closeModal()
        }
    }, [uuid]) //signs in close modal


    const allowSendEmail = !!(contactInfo?.email && contactInfo?.first_name && contactInfo?.last_name);


    React.useEffect(() => {
        if (hotelRooms && hotelRooms?.length > 0) {
            let hasSmop = false;
            hotelRooms.forEach((room) => {
                const rates = room?.rate_data || [];

                rates.forEach((rate) => {
                    if (rate?.rate_type === "SMOP") {
                        hasSmop = true;
                    }
                })
            })

            if (hasSmop && !isLoggedIn() && useOTP) {
                setShowModal(true)
            }
        }
    }, [hotelRooms])


    return (
        <React.Fragment>
            <Modal
                open={showModal}
                onClose={() => { }}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: is_mobile ? '50%' : '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'

                }} className={classes.paper}>

                    <Backdrop className={classes.backdrop} open={creating} >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div className="d-flex">
                        <div style={{
                            font: is_mobile ? "20px/18px Futura Md BT" : "24px/18px Futura Md BT",
                            color: "black",
                            paddingLeft: '0px',
                            paddingBottom: '5px',
                            paddingTop: is_mobile ? '10px' : '15px',
                            width: "90%",
                            marginRight: "5%",
                            lineHeight: is_mobile ? '22px' : '32px'
                        }}>
                            Verify your identity
                        </div>

                        {/* <div
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={closeModal}><TimesIcon style={{ marginTop: "15px" }} width={'20'} height={'20'} /></div> */}

                    </div>


                    <div style={{
                        font: "16px/18px Futura Lt BT",
                        color: "var(--mainGrey)",
                        paddingLeft: '0px',
                        paddingBottom: '5px',
                        paddingTop: '15px',
                    }}>
                        We need to verify you are not a robot to show you our exclusive müver prices
                    </div>

                    {/* <div style={{ display: "flex" }} className="row m-0 p-0 background-img-row" >
                        <div style={{
                            font: "16px/18px Futura Lt BT",
                            color: "var(--mainGrey)",
                            paddingLeft: '0px',
                            paddingBottom: '15px',
                            paddingTop: '15px',
                            width: "40%"
                        }}>
                            Get code via:
                        </div>
                        <div style={{ paddingTop: 10, width: "60%" }}>
                            <label style={{ width: "50%",  color: "var(--mainGrey)",font: "18px/18px Futura Lt BT", }} className="radio-inline radio-1" onClick={() => handleMessageType('email')}>
                                <input onChange={(event) => { handleMessageType(event.target.value) }} value='email' type="radio" name="optradio" checked={messageType === 'email'} />email
                                <span className="checkmark"></span>
                            </label>

                            <label style={{ width: "50%",    color: "var(--mainGrey)", font: "18px/18px Futura Lt BT", }} className="radio-inline radio-1" onClick={() => handleMessageType('sms')}>
                                <input onChange={(event) => { handleMessageType(event.target.value) }} value='sms' type="radio" name="optradio" checked={messageType === 'sms'} />sms
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div> */}

                    {messageType === "email" && <div>

                        <div className="row">
                            <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                                marginTop: "20px", paddingRight: 0
                            }}>

                                <div className={classesAutocomplete.root}>

                                    <TextField
                                        placeholder='First Name'
                                        required={true}
                                        id='First Name'
                                        value={contactInfo?.first_name}
                                        onChange={(event) => updateValue('first_name', event.target.value)}
                                    />
                                </div>
                            </div>


                            <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                                marginTop: "20px",
                            }}>

                                <div className={classesAutocomplete.root}>
                                    <TextField
                                        placeholder='Last Name'
                                        id='Last Name'
                                        required={true}
                                        value={contactInfo.last_name}
                                        onChange={(event) => updateValue('last_name', event.target.value)}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="row">
                            <div className={'col-9'} style={{
                                marginTop: "20px", paddingRight: 0
                            }}>
                                <div className={classesAutocomplete.root}>
                                    <TextField
                                        placeholder='Email'
                                        size="small"
                                        required={true}
                                        type='email'
                                        id='Email'
                                        value={contactInfo.email}
                                        onChange={(event) => updateValue('email', event.target.value)}
                                    />
                                </div>

                            </div>


                            <div className={'col-3'} style={{
                                marginTop: "20px",
                            }}>
                                <button disabled={!allowSendEmail} onClick={() => sendRegistration("email")}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: false ? "var(--lightGraySeparator)" : "var(--mainGreen)",
                                        padding: "0px 3px 0px 14px",
                                        height: 50,
                                        width: "100%",
                                        outline: 0,
                                        border: 0,
                                        opacity: !allowSendEmail ? 0.4 : 1,
                                        borderRadius: 10
                                    }}>
                                    <div style={{
                                        textAlign: is_mobile ? "center" : "left",
                                        font: "14px/14px Futura Md BT",
                                        letterSpacing: "0",
                                        margin: "0",
                                        color: "var(--mainWhite)",
                                    }} >
                                        Send  <RightArrowIcon width={15} height={15} style={{ marginLeft: "15px" }} />
                                    </div>
                                </button>

                            </div>
                        </div>
                    </div>}

                    {messageType === "sms" && <div className="row"
                        style={{ margin: 0, marginTop: 30, marginRight: -15 }}>
                        <div className={'col-9'} style={{
                            paddingRight: 0,
                            border: "2px solid #E8E8E8",
                            borderRadius: 10,
                            height: 50,
                            paddingLeft: 20,
                            paddingTop: 5
                        }}>
                            <PhoneInput
                                withCountryCallingCode
                                international
                                defaultCountry="CA"
                                placeholder="Enter phone number"
                                value={contactInfo.phone}
                                onChange={setPhone} />

                        </div>


                        <div className={'col-3'} style={{

                        }}>
                            <button onClick={() => sendRegistration("phone")}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: false ? "var(--lightGraySeparator)" : "var(--mainGreen)",
                                    padding: "0px 3px 0px 14px",
                                    height: 50,
                                    width: "100%",
                                    border: 0,
                                    borderRadius: 10
                                }}>
                                <div style={{
                                    textAlign: is_mobile ? "center" : "left",
                                    font: "14px/14px Futura Md BT",
                                    letterSpacing: "0",
                                    margin: "0",
                                    color: "var(--mainWhite)",
                                }} >
                                    Send  <RightArrowIcon width={15} height={15} style={{ marginLeft: "15px" }} />
                                </div>
                            </button>

                        </div>
                    </div>}







                    {messageSent && <div >
                        {<div style={{
                            font: "16px/18px Futura Lt BT",
                            color: "var(--mainGrey)",
                            paddingLeft: '0px',
                            paddingBottom: '5px',
                            paddingTop: '35px',
                        }}>
                            Please enter the code we sent to: <div style={{
                                font: "16px/18px Futura Md BT",
                                color: "var(--mainGrey)", marginTop: 10
                            }}>{contactInfo?.email}</div>
                        </div>}

                        {<div style={{
                            font: "16px/18px Futura Lt BT",
                            color: "var(--mainGrey)",
                            paddingLeft: '0px',
                            paddingBottom: '5px',
                            paddingTop: '20px',
                        }}>
                            Please look in your spam folder if you don't see it in your inbox
                        </div>}



                        <div style={{ marginTop: 20,maxWidth:"100vw" }}>
                            <OtpInput
                                value={otp}
                                onChange={onChangeOtp}
                                numInputs={6}
                                renderSeparator={null}
                                shouldAutoFocus

                                inputStyle={{
                                    width: "100%",
                                    height:  is_mobile?50: 70,
                                    border: otp?.length === 6 ? "2px solid #19BC9B" : "2px solid #CECECE",
                                    borderRadius: 7,
                                    marginRight: is_mobile?10:20
                                }}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>

                    </div>}

                    <div style={{ marginTop: 40, display: "flex", justifyContent: "space-between" }}>
                        <div onClick={signIn} style={{ cursor: "pointer", font: "16px/20px Futura Md BT", color: "#19bc9b" }}>
                            Login or register
                        </div>

                        {allowSendEmail && <div onClick={() => sendRegistration(messageType)} style={{ textAlign: "right", cursor: "pointer", font: "16px/20px Futura Md BT", color: "#707070" }}>
                            Resend code
                        </div>}

                    </div>

                    <div style={{
                        font: "14px/18px Futura Lt BT",
                        color: "var(--mainGrey)",
                        paddingBottom: '10px',
                        paddingTop: '30px',
                    }}>

                        By clicking on send, you agree to our <a href={'/terms-and-conditions'}
                            style={{ color: "#19bc9b" }}>terms and conditions</a> and to receive communication from müv.

                    </div>


                </div>

            </Modal>

        </React.Fragment>
    )
}


const OTPModalUseStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 620,
        height: "auto",
        maxHeight: 860,
        overflow: "auto",
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: "-webkit-focus-ring-color auto 0px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxHeight: "none",
            height: "auto",
            paddingBottom: "130px"
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 100,
        color: '#fff',
    },
}))


const OTPTextField = makeStyles(theme => ({
    root: {
        '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:nth-child(2)': {
            display: "none",
        },
        '& .MuiTextField-root': {
            width: "100%",
            border: "2px solid #E8E8E8",
            font: "14px/24px Futura Md BT",
            padding: "10px 10px 10px 10px",
            height: "50px",
            margin: "0px",
            backgroundColor: "#FFFFFF",
            borderRadius: 10
        },

        '& .MuiFormLabel-root': {
            font: "14px/24px Futura Md BT",
            color: "#B1B1B1",
            padding: 10,
            marginTop: -10

        },
        '& .MuiFormLabel-root.Mui-error': {
            color: '#f44336'

        },
        '& .MuiInputBase-input': {
            font: "18px/24px Futura Md BT",
            color: "#707070",
            textAlign: "left",
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "#B1B1B1",
            marginTop: -2
        },
        '& .MuiInput-underline:before': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:after': {
            transition: 0,
            borderBottom: 0,
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
        },

    },
}))