import * as Actions from '../../../../redux/actions'

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { list_container_mode, URL_REPLACE } from '../../Common/RegExValidate'
import { member_area_router } from '../../Common/RegExValidate'
import {
    useHistory
} from "react-router-dom"
import { toggleOneclick } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice'
import moment from 'moment'
import { resetFlightPage, updateFlightData } from '../../FlightsPage/services/Flights.slice'
import { getFlightsListThunk } from '../../FlightsPage/services/Flights.thunks'
import { encryptID } from '../../HotelPage/hotelServices'

const LinkDestination_ = ({ from = "", link_style, setSwitchContainer, setTripDisplayMode, text, setPageYOffset, trip_id = "" }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const oneclick = useSelector(state => state.PersonalizedTrip.oneclick);
    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";



    const bookTrip = async () => {

        let hotel = oneclick?.hotel;
        let flight = timelineFlights;

        if(timelineHotels?.customHotels && timelineHotels?.customHotels?.length > 0){
            hotel = timelineHotels?.customHotels[0]
        }

        if(timelineHotels?.muvHotels && timelineHotels?.muvHotels?.length > 0){
            hotel = timelineHotels?.muvHotels[0]
        }

        if(timelineFlights&& timelineFlights?.flightInfo?.length > 0){
            flight = timelineFlights?.flightInfo[0]
        }


        if (hotel) {
            dispatch(toggleOneclick(true))
            const city = hotel?.cityName ? hotel.cityName + ', ' : ""
            const state = hotel?.stateName ? hotel.stateName + ', ' : ""
            const country = hotel?.countryName ? hotel.countryName : ""
            const where = city + state + country;

            let url = ""
            if (hotel?.pricelineCityId) {
                const encryptedId =  encryptID( hotel?.pricelineHotelId)
                url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                    + "?city_id=" + hotel?.pricelineCityId
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))

                if (hotel?.ppn_bundle) {
                    url += "&ppn_bundle=" + hotel?.ppn_bundle
                }
            }
            else {

                url = "/hotel-search?city_id=" + null
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
            }


            history.push(url.toLowerCase());
        }
        else if(flight){
            searchFlight(flight)
        }

    }


    const searchFlight = (flight) => {
        const flights = [
            {
                "airportFrom": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.departure_city,
                    "latitude": "",
                    "city_code": flight?.departure_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.departure_iata,
                    "airport": "All Airports"
                },
                "airportTo": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.arrival_city,
                    "latitude": "",
                    "city_code": flight?.arrival_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.arrival_iata,
                    "airport": "All Airports"
                },
                "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "availableSelectDate": new Date().toDateString(),
                "positionId": "d-0"
            }]
        const dataToBookingFlights = {
            flightMode: 'one_way' ,
            cabinClass: 'economy',
            numberAdult: '1',
            numberChild: '0',
            flights: flights,
            currency: fullCurrency

        }
      

        dispatch(updateFlightData(dataToBookingFlights))
        dispatch(resetFlightPage())
        dispatch(getFlightsListThunk(dataToBookingFlights))

        history.push('/flight/search')
    }

    const goesToBookingPage = () => {
        trip_id === "" ? history.push("/booking") : history.push("/booking-" + trip_id)
        setPageYOffset(0)
        setTripDisplayMode(list_container_mode.timeline)

        setSwitchContainer(member_area_router.booking)
    }


    return (
        <div style={link_style} onClick={bookTrip}>
            {text}
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setSwitchContainer: Actions.setSwitchContainer,
}

const LinkDestination = connect(mapStateToProps, mapDispatchToProps)(LinkDestination_)
export default LinkDestination
