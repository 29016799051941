import "./FavoritesContainer.css"

import * as Actions from '../../../../redux/actions'

import MemberActionContainer from "../../Common/MemberActions/MemberActionContainer"
import MenuMember from "../../Common/MenuMember.jsx"
import React from 'react'
import ScrollToTop from "../../Common/ScrollToTop"
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom"
import { member_area_router, URL_REPLACE } from '../../Common/RegExValidate'
import MuverBucketList from '../../../../assets/images/muverBucketList.png';
import MuverFavs from '../../../../assets/images/muverFavs.png';

/* favorites container component */
const FavoritesContainer_ = ({member,memberActions,screenSize, uuid, lang, app_menu_height, is_mobile, logo_height, getMemberActionsSets, setSwitchContainer }) => {
    const history = useHistory()

    const actionType = "favorite"
    const [loaded, setLoaded] = React.useState(false)
    const homeDest = member?.homeDestination;
    const [loading, setLoading] = React.useState(false);
    if (!loaded) {
        setLoaded(true)
        setLoading(true)
        getMemberActionsSets(uuid, lang, actionType)
    }
    React.useEffect(() => {
        getMemberActionsSets(uuid, lang, actionType)
   },[uuid, lang, getMemberActionsSets])


   React.useEffect(() => {
    if (!(memberActions && memberActions.length > 0)) {
        setLoading(false)
    }
    setTimeout(() => {
        setLoading(false)
    }, 15000);
}, [memberActions])



    let margin_left = 0
    let margin_right = 0
    let padding_left = 0
    let padding_right = 0
    let position = "sticky"
    let top_H = 180
    let font_size = "50"
    if (!isNaN(screenSize)) {
        padding_left = screenSize <= 768 ? "4px" : "40px"
        padding_right = screenSize <= 768 ? "4px" : "40px"
        font_size = screenSize <= 768 ? 25 : 50
        margin_left = screenSize > 1200 ? (screenSize / 100 * 5) + 430 : (screenSize <= 768 ? 0 : (screenSize / 100 * 4) + 350)
        margin_right = (screenSize <= 768) ? 0 : (screenSize / 100 * 8.5)
    }

    const handleBackPage = () => {
        setSwitchContainer(member_area_router.starred)
        history.push("/starred")
    }
    const goToDestination = () => {
        if (homeDest) {
            const url = "/destination/" + homeDest?.destinationName?.replace(URL_REPLACE, "-") + "/" + 1 + "/" + homeDest?.destinationId;
            history.push(url.toLowerCase());
        }
    }


    return (
        <div style={{ top: top_H, position: "relative", marginLeft: margin_left, marginRight: margin_right, backgroundColor: "#191919" }} className="padding_top_20">
            <div style={{
                position: position, zIndex: "3",
                top: is_mobile ? 0 : (-Math.ceil(-app_menu_height / 2))
            }}>
                <div className="padding_top_10 padding_left_20 padding_right_20" style={{ backgroundColor: "#191919" }}>
                    <MenuMember />
                    {history.location.pathname=="/starred" && 
                        <React.Fragment>
                            <Button variant="outline-success" onClick={() => handleBackPage()} style={{ boxShadow: "0 0 0 0", position: "absolute", right: 20, top: 10 }} className="profile_btn_invite">Back</Button>
                        </React.Fragment>
                    }
                </div>
            </div>
            <div className="padding_top_50 padding_125rem_lr_mobile"
                style={{
                    paddingLeft: padding_left,
                    paddingRight: padding_right,
                    color: "var(--mainWhite)",
                    font: font_size + "px/72px Futura Hv BT",
                }}
            >My Favorites</div>
                 <div 
                style={{
                    paddingLeft: padding_left,
                    paddingRight: padding_right,
                }}
            >
                  <MemberActionContainer actionType={actionType} />
            </div>
            {
                <div style={{padding:"30px",cursor:"pointer"}}>
                <img onClick={goToDestination} src={MuverFavs} height={200} alt="tripIcons" />
                </div>

            }

            <ScrollToTop />
        </div>
    )

}

const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        memberActions: state.MemberActions.memberActionSets.memberActions,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        uuid: state.Member.authModal.uuid,
        member: state.Member.memberArea.memberMuver,
        logo_height: state.Setting.appMenu.logo.height,
    }
}

const mapDispatchToProps = {
    getMemberActionsSets: Actions.getMemberActionsSets,
    setSwitchContainer: Actions.setSwitchContainer,
}

const FavoritesContainer = connect(mapStateToProps, mapDispatchToProps)(FavoritesContainer_)
export default FavoritesContainer

