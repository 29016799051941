import { FormControl, InputLabel, Select, TextField } from '@material-ui/core'
import React from 'react'
import { creditCardType } from '../../../../utility/utility'
import { DurationUseStyles } from '../../FeaturePage/personalizedTrip/personalizedTripStyles'

import { countriesList, monthList } from './DataBank'
import { LeftArrowIcon, RightArrowIcon } from '../../Common/SVGBank';
import { flightTextField } from '../../FlightsPage/flightStyles'


export const PaymentMethodForm = ({ paymentInfo, setPaymentInfo, nextStep, previousStep }) => {


    const classesAutocomplete = flightTextField()
    let payment = { ...paymentInfo };

    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        payment[field] = value;
        setPaymentInfo(payment)
    }

    const [stateList, setStates] = React.useState();

    const durationClass = DurationUseStyles()

    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const yearList = range(currentYear, currentYear + 10, +1);

    const isDisabled = () => {
        const disabled = payment.cc_name && payment.cc_number && payment.cc_code && payment.cc_exp_mo && payment.cc_exp_yr
            && payment.address && payment.country_code && payment.city && payment.postal_code;
        return disabled ? false : true;
    }


    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;

    return (
        <div>

            <p style={{ font: '20px/20px Futura Hv BT', marginTop: 30 }}> Promo code </p>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>
                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Promo code'
                            placeholder='Please Enter Promo code'
                            id='Promo code'
                            defaultValue={payment.promo}
                            onChange={(event) => updateValue('promo', event.target.value)}
                        />
                    </div>
                </div>
            </div>


            <p style={{ font: '20px/20px Futura Hv BT', marginTop: 30 }}> Payment method </p>



            <div className="row">
                <div className='col-12' style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.cc_name ? "1px solid red" : "" }}>
                        <TextField
                            label='Cardholder Name'
                            required={true}
                            id='Cardholder  Name'
                            defaultValue={payment.cc_name}
                            onChange={(event) => updateValue('cc_name', event.target.value)}
                        />
                    </div>
                </div>

            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.cc_number ? "1px solid red" : "" }}>
                        <TextField
                            label='Credit/Debit Card Number'
                            required={true}
                            id='Credit/Debit Card Number'
                            defaultValue={payment.cc_number}
                            onChange={(event) => {
                                updateValue('cc_number', event.target.value)
                                updateValue('cc_type', creditCardType(event.target.value))
                            }}
                        />
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-3'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.cc_code ? "1px solid red" : "" }}>
                        <TextField
                            label='Security Code'
                            required={true}
                            id='Security Code'
                            defaultValue={payment.cc_code}
                            onChange={(event) => updateValue('cc_code', event.target.value)}
                        />
                    </div>
                </div>
            </div>



            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>
                    <FormControl className={durationClass.formControl} style={{ width: '100%', border: !payment.cc_exp_mo ? "1px solid red" : "", }}>
                        <InputLabel htmlFor="Expiration Month">
                            Expiration Month
                        </InputLabel>
                        <Select
                            native
                            required={true}

                            defaultValue={payment.cc_exp_mo}
                            onChange={(event) => updateValue('cc_exp_mo', event.target.value)}
                            inputProps={{
                                name: 'Expiration Month',
                                id: 'Expiration Month',
                            }}
                        >
                            <option aria-label="None" value="" />

                            {monthList.map((val, index) => {
                                return (<option key={index} value={index + 1}>{val}</option>)
                            })}

                        </Select>
                    </FormControl>

                </div>
                <div className={mobile ? 'col-12' : 'col-3'} style={{
                    marginTop: "20px",
                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%', border: !payment.cc_exp_yr ? "1px solid red" : "", }}>
                        <InputLabel htmlFor="Expiration Year">
                            Expiration Year
                        </InputLabel>
                        <Select
                            native
                            required={true}

                            defaultValue={payment.cc_exp_yr}
                            onChange={(event) => updateValue('cc_exp_yr', event.target.value)}
                            inputProps={{
                                name: 'Expiration Year',
                                id: 'Expiration Year',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {yearList && yearList.map((val, index) => {
                                return (<option key={index} value={val}>{val}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>



            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.address ? "1px solid red" : "" }}>
                        <TextField
                            label='Billing Address 1'
                            required={true}
                            id='Billing Address 1'
                            defaultValue={payment.address}
                            onChange={(event) => updateValue('address', event.target.value)}
                        />
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Billing Address 2'
                            id='Billing Address 2'
                            defaultValue={payment.address}
                            onChange={(event) => updateValue('address', event.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0,

                }}>

                    <FormControl className={durationClass.formControl} style={{ width: '100%', border: !payment.country_code ? "1px solid red" : "", }}>
                        <InputLabel htmlFor="Country">
                            Country
                        </InputLabel>
                        <Select
                            native
                            required={true}

                            defaultValue={payment.country_code}
                            onChange={(event) => {
                                const country = JSON.parse(event.target.value);
                                if (country) {
                                    updateValue('country_code', country.code);
                                    setStates(country.province_codes)
                                }
                            }
                            }
                            inputProps={{
                                name: 'Country',
                                id: 'Country',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {countriesList.map((val, index) => {
                                return (<option key={index} value={JSON.stringify(val)}>{val.name}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>
                    <FormControl className={durationClass.formControl} style={{ width: '100%' }}>
                        <InputLabel htmlFor="state">
                            State/Province
                        </InputLabel>
                        <Select
                            native
                            defaultValue={payment.state_code}
                            onChange={(event) => updateValue('state_code', event.target.value)}
                            inputProps={{
                                name: 'state',
                                id: 'state',
                            }}
                        >
                            <option aria-label="None" value="" />
                            {stateList && Object.keys(stateList).map((keyName, index) => {
                                return (<option key={index} value={stateList[keyName]}>{keyName}</option>)
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>


            <div className="row">
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.city ? "1px solid red" : "" }}>
                        <TextField
                            label='City'
                            required={true}
                            id='City'
                            defaultValue={payment.city}
                            onChange={(event) => updateValue('city', event.target.value)}
                        />
                    </div>
                </div>
                <div className={mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root} style={{ border: !payment.postal_code ? "1px solid red" : "" }}>
                        <TextField
                            label='Postal Code'
                            required={true}
                            id='Postal Code'
                            defaultValue={payment.postal_code}
                            onChange={(event) => updateValue('postal_code', event.target.value)}
                        />
                    </div>
                </div>
            </div>


            <div style={{ padding: '16px 35px', marginTop: 30, marginBottom: 30, borderLeft: '3px solid #19BC9B', background: '#19BC9B33', color: '#19BC9B', font: '14px/14px Futura Lt BT' }}>

                <p style={{ lineHeight: 1.5 }}> SAFE AND SECURE BILLING Your credit card transaction is protect by an secure SSL encrypted transaction. </p>
            </div>

            <div>
                <div style={{ marginBottom: 20 }}>
                    <button className='recapButtons' style={{ background:mobile? 'transparent': '#DFDFDF' }} onClick={previousStep}>
                        <LeftArrowIcon fill={'#7F7F7F'} height={14} width={14} style={{ marginRight: 10 }} />  Previous step
                     </button>


                    <div style={{ float: "right" }} className="nextBtn">
                        <button disabled={isDisabled()} onClick={nextStep} className='recapButtons' style={{ opacity: isDisabled() ? 0.4 : 1, background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} >
                            Book <RightArrowIcon height={14} width={14} style={{ marginLeft: 90 }} />
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}