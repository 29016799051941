import { environment } from "../../../../Environments/environment";
import { PostAsyncHelper } from "../../../../redux/interfaces/types";

export const GetAiResponse = async(message)=>{
    let obj = {
        userContent:message??""
    }

    let api_url = `${environment.apiUrl}muv-ai/prompt`
    const response  =  await PostAsyncHelper(api_url, obj)
    const results = response?.data

    return results || null;
}

export const GetAiSuggestions = async(data)=>{
    const message = data?.message;
    const member_id = data?.member_id;
    const prompt_session_id = data?.prompt_session_id

    let obj = {
        userContent:message??"",
        uuid: member_id,
        prompt_session_id,
        start_over: data?.start_over || null,
        muvSystemInfo:{
            entity:data?.entity || null,
            entity_id:data?.entity_id || null,
        }
    }

    let api_url = `${environment.apiUrl}muv-ai/chitchatPrompAndCoordinates`

    
    // let api_url = `${environment.apiUrl}muv-ai/workWithPrompAndCoordinates`
    const response  =  await PostAsyncHelper(api_url, obj)
    const results = response?.data

    return results || null;
}

