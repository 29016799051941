import "./MuverTrip.css"

import * as Actions from '../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect, useDispatch } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import { getBookingFromText } from "./BookingUpload.services"
import { CircularProgress } from "@material-ui/core"
import { saveHotel, saveHotelTimeline } from "../BookingPage/PaymentPage/payment.thunk"
import { environment } from "../../../Environments/environment"
import { TimesIcon } from "../Common/SVGBank"
import { Grid } from "@material-ui/core"



/* top bar component */
const BookingUpload_ = ({ uuid, setOpenTimeline, getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [postContent, setPostContent] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [showBox, setBox] = React.useState(false);


    const getBooking = async () => {
        setSubmitting(true)
        const result = await getBookingFromText({
            uuid: uuid,
            userContent: postContent
        })
        if (result) {
            await addToTimeline(result)
        }

        setSubmitting(false)
    }




    const addToTimeline = async (booking) => {

        if (booking) {
            const saveData = {
                postData: {
                    tripId: activeTrip?.tripId || "",
                    pricelineHotelId: booking?.hotel_id || "",
                    booking_trip_number: booking?.confirmation_number || "",
                    booking_status: "BOOKED",
                    bookedEmail: booking?.email || "",
                    booking_status_code: booking?.reference_number || "",
                    checkIn: booking.check_in || "",
                    checkOut: booking.check_out || "",
                    displaySymbol: booking?.rate_symbol || "$",
                    adults: booking?.number_of_guests || 1,
                    displayCurrency: booking?.rate_currency || "US",
                    displayTotalFare: booking?.rate_total || ""
                },
                uuid: uuid || null
            }


            const result = await dispatch(saveHotel(saveData));
            if (result?.payload) {
                setPostContent("")
            }


        }

    }
    function extractText(event) {
        const file = event.target.files[0]

    }


    if (environment.production) {
        return null
    }

    return (
        <div>
            {!showBox && <div style={{
                font: "18px/16px Futura Md BT",
                width: '100%',
                textAlign: "right",
                color: "var(--mainBlue)",
                cursor: "pointer",
                paddingBottom: 10,
            }} onClick={() => setBox(true)}>
                Add the confirmation email of a booked flight or accommodation
            </div>}

            {showBox && <Grid container className="" style={{
                background: "lightgrey",
                padding: 30
            }}  >

                {/* <Grid item xs={12} lg={6}>

                    <div style={{
                        font: "18px/16px Futura Md BT",
                    }}>
                       Upload your Hotel or Flight booking confirmation pdf

                   
                    </div>

                   <div>
                   <input type="file" accept="application/pdf" onChange={extractText}/>
                   </div>

                    <div style={{
                        font: "18px/16px Futura Lt BT",
                    }}>
                      Drag pdf file to upload or click browse file to upload

                   
                    </div>
                </Grid> */}


                <Grid item xs={12} lg={12}>

                    <div style={{
                        font: "18px/16px Futura Md BT",
                    }}>
                        Copy and paste email confirmation text here

                        <span onClick={() => setBox(false)}>
                            <TimesIcon

                                style={{ marginTop: 0, float: "right", cursor: "pointer" }} width={'24'} height={'24'} />
                        </span>
                    </div>

                    <textarea className="uploadBookingArea" style={{
                        background: "white",
                        border: "none",
                        resize: "none",
                        padding: 40,
                        width: "100%",
                        marginTop: 20,
                        borderRadius: 20,
                        font: "16px/16px Futura Lt BT",
                    }} rows="10" onChange={e => setPostContent(e.target.value)}
                        placeholder="Copy and Paste Here"
                        value={postContent}>

                    </textarea>

                    <div style={{ width: "100%", textAlign: "right" }}>

                        <Button onClick={getBooking} style={{ font: "16px/16px Futura Md BT", borderRadius: 15, marginTop: 15, width: "150px", height: "40px", color: "white", backgroundColor: "var(--mainBlue)" }}>
                            {submitting ? <CircularProgress size={14} color="inherit" /> : <span style={{ font: "16px/16px Futura Md BT" }}>SUBMIT</span>}</Button>

                    </div>
                </Grid>
            </Grid>}
        </div>

    )
}


const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        uuid: state.Member.authModal.uuid,
        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const BookingUpload = connect(mapStateToProps, mapDispatchToProps)(BookingUpload_)
