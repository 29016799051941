import * as Actions from '../../../redux/actions'

import {
    AccountIcon,
    RightArrowIcon,
    TimesIcon,
} from './SVGBank'
import {
    EmailAddressLoginTextField,
    EmailAddressTextField,
    FirstNameTextField,
    LastNameTextField,
    PasswordConfirmTextField,
    PasswordEye,
    PasswordEyeLogin,
    PasswordLoginTextField,
    PasswordTextField,
} from "./FormComponentsBank"
import { useParams,useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
    loginModalUseStyles,
    modalMobileUseStyles,
    registerModalUseStyles,
    registerPasswordUseStyles,
    registerTextFieldUseStyles,
} from "./ComponentsStylingBank"
import Backdrop from '@material-ui/core/Backdrop'
import MenuListMemberArea from "./MenuListMemberArea"
import Modal from '@material-ui/core/Modal'
import React from 'react'
import { connect, useDispatch } from "react-redux"
import ReCAPTCHA from "react-google-recaptcha";

import { useState } from 'react';
import { environment } from '../../../Environments/environment'
import { resetPasswordSlice } from '../../../redux/slices/Login.slice';
import { CircularProgress } from '@material-ui/core';


export const Authorization = connect((state) => ({
    logo_width: state.Setting.appMenu.logo.width,
    screenSize: state.Setting.htmlBody.bodyClientWidth,
    is_mobile: state.Setting.is_mobile,
    authModal: state.Member.authModal,
    trip: state.PublicTrip.trip,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    uuidPrivate: state.Member.authModal.uuid,
    emailAddressLoginTextField: state.FormBank.emailAddressLoginTextField,
    emailAddressTextField: state.FormBank.emailAddressTextField,
    firstNameTextField: state.FormBank.firstNameTextField,
    lastNameTextField: state.FormBank.lastNameTextField,
    passwordLoginTextField: state.FormBank.passwordLoginTextField,
    passwordTextField: state.FormBank.passwordTextField,
    textColor: state.Setting.appMenu.textColor,
}), {
    setRouteAuthModal: Actions.setRouteAuthModal,
    setOpenAuthModal: Actions.setOpenAuthModal,
    registerMemberAccount: Actions.registerMemberAccount,
    loginMemberAccount: Actions.loginMemberAccount,
    tokenLogin: Actions.tokenLogin,
    loginWithToken: Actions.loginWithToken,
    setTimeLineStatus: Actions.setTimeLineStatus
})(({
    is_mobile,
    authModal,
    setOpenAuthModal,
    registerMemberAccount,
    emailAddressTextField,
    emailAddressLoginTextField,
    firstNameTextField,
    lastNameTextField,
    passwordTextField,
    passwordLoginTextField,
    setRouteAuthModal,
    loginMemberAccount,
    tokenLogin, uuidPrivate,
    trip,
    fetch = !authModal.cookiesExsit && tokenLogin(),
    font,
    fontColor,
    loginWithToken,
    logo_width,
    textColor,
    setTimeLineStatus,
    activeTrip
}) => {
    const classesLaptopLogin = loginModalUseStyles();
    const classesLaptopRegister = registerModalUseStyles();
    const classesMobile = modalMobileUseStyles();
    const classesTextField = registerTextFieldUseStyles();
    const classesPassword = registerPasswordUseStyles();
    const [captcha, setCaptcha] = useState()
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const dispatch = useDispatch()
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    let doLogin = query.get('doLogin');
    let memberToken = query.get('memberToken');
    const isHotelPage = document.location?.pathname?.includes("hotel");

    const history = useHistory()
    const isMuverPage = history?.location?.pathname?.includes("muver")

    let errorInput = emailAddressTextField.emailAddress === ""
        || emailAddressTextField.error
        || firstNameTextField.firstName === ""
        || firstNameTextField.error
        || lastNameTextField.lastName === ""
        || lastNameTextField.error
        || passwordTextField.password === ""
        || passwordTextField.error
        || passwordTextField.passwordConfirmed === ""
        || passwordTextField.passwordConfirmedError
    let arrow_icon_size = 19
    let arrow_font = "18px/16px Futura Md BT"
    let arrow_container_width = "175px"
    let arrow_container_height = "60px"
    let arrow_container_padding_left = "10"
    let padding_l_r = "15"
    let col = "col-6"
    let eye_top = "45%"
    let eye_right = "55%"
    let eye_top_confirm = "45%"
    let eye_right_confirm = "30px"
    let translateY = "translateY(75px)"
    let classes = classesLaptopLogin
    if (!isNaN(is_mobile)) {
        padding_l_r = !is_mobile ? "15" : "10"
        arrow_icon_size = !is_mobile ? 19 : 10
        arrow_font = !is_mobile ? "18px/16px Futura Md BT" : "9px/8px Futura Md BT"
        arrow_container_width = !is_mobile ? "175px" : "120px"
        arrow_container_height = !is_mobile ? "60px" : "30px"
        arrow_container_padding_left = !is_mobile ? "12" : "10"
        col = !is_mobile ? "col-6" : "col-12"
        eye_top = !is_mobile ? "45%" : "20%"
        eye_right = !is_mobile ? "55%" : "30px"
        translateY = !is_mobile ? "translateY(75px)" : "translateY(45px)"
        eye_top_confirm = !is_mobile ? "45%" : "70%"
        eye_right_confirm = !is_mobile ? "30px" : "30px"
        classes = !is_mobile ? (authModal.route === "Login" ? classesLaptopLogin : classesLaptopRegister) : classesMobile
    }
    let fullName = (authModal.firstName !== ("" || null) && authModal.lastName !== ("" || null)) ? authModal?.firstName?.charAt().toUpperCase() + authModal.firstName.substring(1) + ' ' + authModal.lastName.charAt().toUpperCase() + authModal.lastName.substring(1) : ""



    const onChangeCaptcha = (value) => {
        setCaptcha(value)
    }


    const isLoggedIn = () => {
        return authModal.uuid && authModal.uuid !== ""
    }
    const allowClose = (!isMuverPage && !isLoggedIn());

    const resetPassword = async () => {
        setLoading(true)
        const data = {
            email: emailAddressLoginTextField.emailAddressOrUsername
        }
        const result = await dispatch(resetPasswordSlice(data))
        setLoading(false)
        if (result) {
            setOpenAuthModal(false)
        }

    }



    React.useEffect(() => {
        if (activeTrip && activeTrip?.tripId) {

            if(authModal?.uuid && !isHotelPage){
                setTimeout(() => {
                    setTimeLineStatus(true);
                }, 500);
            }
          
        }
    }, [authModal.uuid]); ////for opening timeline after login


    React.useEffect(() => {
        if(doLogin === "true" && memberToken){
            loginWithToken(memberToken)
         
        }
    }, []) ///for forced token login

    return (
        <div>
            {!isLoggedIn() ?
                <div onClick={() => setOpenAuthModal(true)}>
                    {is_mobile && <div style={{ outline: "0" }}><AccountIcon fill={textColor} /></div>}
                    <div style={{
                        width: is_mobile ? logo_width : "100px",
                        textAlign: is_mobile ? "center" : "left",
                        font: font,
                        letterSpacing: "0",
                        color: fontColor,
                        cursor: "pointer",
                        paddingLeft: is_mobile ? "0px" : "16px",
                    }} >Join/Login</div>
                </div>
                :
                <div>
                    <MenuListMemberArea fullName={fullName} font={font} fontColor={fontColor} />
                </div>
            }
            <Modal
                open={authModal.open}
                onClose={allowClose ? () => setOpenAuthModal(false): ()=>{}}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                {authModal.route === "Login" ?
                    <div style={{
                        top: '50%',
                        height: "auto",
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: is_mobile ? '93%' : 'auto',
                        paddingBottom: is_mobile ? '20%' : '7%'

                    }} className={classes.paper}>
                        <div className="d-flex">
                            <div style={{
                                font: "24px/24px Futura Hv BT",
                                color: "#191919",
                                paddingLeft: '30px',
                                paddingBottom: '30px',
                                paddingTop: '15px',
                                flexGrow: "1",
                            }}>Sign In</div>
                            {allowClose && <div
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpenAuthModal(false)}><TimesIcon style={{ marginTop: "15px" }} width={'30'} height={'30'} /></div>}
                        </div>
                        <div>
                            <div className={"padding_left_right_" + padding_l_r}>
                                <div className={classesTextField.root}>
                                    <EmailAddressLoginTextField
                                        label="Email Address or Username"
                                        required={true}
                                        id="email_address_login"
                                    />
                                </div>
                            </div>

                            <div className="position-relative">
                                <div style={{
                                    marginTop: "10px",
                                    paddingLeft: padding_l_r + "px",
                                    paddingRight: padding_l_r + "px",
                                }}>
                                    <div className={classesPassword.root}>
                                        <PasswordLoginTextField
                                            label="Password"
                                            required={true}
                                            id="password_login"
                                        />
                                    </div>
                                </div>

                                <div className="position-absolute text-right d-inline-block"
                                    style={{
                                        right: "8%",
                                        top: "40%",
                                    }}>
                                    <PasswordEyeLogin />
                                </div>

                            </div>
                        </div>
                        <div className="position-relative margin_top_20">
                            <div style={{
                                textAlign: "right",
                                font: arrow_font,
                                letterSpacing: "0",
                                margin: "0",
                                cursor: "pointer",
                                color: "#191919",
                                paddingLeft: padding_l_r + "px",
                                paddingRight: padding_l_r + "px",
                            }}
                                onClick={() => setRouteAuthModal("reset")}>Forgot password? </div>
                        </div>
                        <div className="position-relative margin_top_20">
                            <div
                                onClick={
                                    () => loginMemberAccount(
                                        emailAddressLoginTextField.emailAddressOrUsername,
                                        passwordLoginTextField.password)}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: "var(--mainGreen)",
                                    paddingLeft: arrow_container_padding_left + "px",
                                    height: arrow_container_height,
                                    width: arrow_container_width,
                                    marginTop: "10px",
                                    display: "block",
                                    position: "absolute",
                                    border: "0",
                                    right: padding_l_r + "px",
                                }}>
                                <div style={{
                                    textAlign: "left",
                                    font: arrow_font,
                                    letterSpacing: "0",
                                    margin: "0",
                                    position: "absolute",
                                    top: "50%",
                                    msTransform: "translateY(-50%)",
                                    transform: "translateY(-50%)",
                                    color: "var(--mainWhite)",
                                }}>
                                    Sign in  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "50px" }} />
                                </div>

                            </div>
                        </div>
                        <div style={{
                            textAlign: "right",
                            font: arrow_font,
                            letterSpacing: "0",
                            margin: "0",
                            cursor: "pointer",
                            msTransform: translateY,
                            transform: translateY,
                            color: "var(--mainGreen)",
                            paddingLeft: padding_l_r + "px",
                            paddingRight: padding_l_r + "px",
                        }}
                            onClick={() => setRouteAuthModal("Register")}>sign up</div>
                    </div>
                    : authModal.route === "Register" ?
                        <div style={{
                            top: '50%',
                            height: "auto",
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: is_mobile ? '93%' : 'auto',

                        }} className={classes.paper}>
                            <div className="d-flex">
                                <div style={{
                                    font: "24px/24px Futura Hv BT",
                                    color: "#191919",
                                    paddingLeft: '30px',
                                    paddingBottom: '30px',
                                    paddingTop: '15px',
                                    flexGrow: "1",
                                }}>Sign Up</div>
                                <div
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setOpenAuthModal(false)}><TimesIcon style={{ marginTop: "15px" }} width={'30'} height={'30'} /></div>
                            </div>

                            <div>
                                <div className={"padding_left_right_" + padding_l_r}>
                                    <div className={classesTextField.root}>
                                        <EmailAddressTextField
                                            label="Email Address"
                                            required={true}
                                            id="email_address"
                                        />
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: "7.5px",
                                    }}>
                                        <div className={classesTextField.root}>
                                            <FirstNameTextField
                                                label="First Name"
                                                required={true}
                                                id="first_name"
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-6"} style={{
                                        marginTop: "10px",
                                        paddingLeft: "7.5px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesTextField.root}>
                                            <LastNameTextField
                                                label="Last Name"
                                                required={true}
                                                id="last_name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0 position-relative">
                                    <div className={col} style={{
                                        marginTop: "10px",
                                        paddingLeft: padding_l_r + "px",
                                        paddingRight: "7.5px",
                                    }}>
                                        <div className={classesPassword.root}>
                                            <PasswordTextField
                                                label="Password"
                                                required={true}
                                                id="password"
                                            />
                                        </div>
                                    </div>
                                    <div className={col} style={{
                                        marginTop: "10px",
                                        paddingLeft: "7.5px",
                                        paddingRight: padding_l_r + "px",
                                    }}>
                                        <div className={classesPassword.root}>
                                            <PasswordConfirmTextField
                                                label="Confirm"
                                                required={true}
                                                id="comfirm"
                                            />
                                        </div>
                                    </div>
                                    <div className="position-absolute text-right d-inline-block"
                                        style={{
                                            right: eye_right,
                                            top: eye_top,
                                        }}>
                                        <PasswordEye />
                                    </div>
                                    <div className="position-absolute text-right d-inline-block"
                                        style={{
                                            right: eye_right_confirm,
                                            top: eye_top_confirm,
                                        }}>
                                        <PasswordEye />
                                    </div>
                                </div>

                                <div className={"padding_left_right_" + padding_l_r}>
                                    <div className="registerCaptcha">
                                        <ReCAPTCHA
                                            sitekey={environment.captchaKey}
                                            onChange={onChangeCaptcha}
                                        />

                                    </div>
                                </div>

                            </div>

                            <div className="position-relative margin_top_20" style={{ float: "right" }}>
                                <div
                                    onClick={
                                        !errorInput && captcha ?
                                            () => registerMemberAccount(
                                                emailAddressTextField.emailAddress,
                                                firstNameTextField.firstName,
                                                lastNameTextField.lastName,
                                                passwordTextField.password)
                                            : () => { }}
                                    style={{
                                        cursor: !errorInput && captcha ? "pointer" : "",
                                        backgroundColor: !errorInput && captcha ? "var(--mainGreen)" : "var(--lightGraySeparator)",
                                        paddingLeft: arrow_container_padding_left + "px",
                                        height: arrow_container_height,
                                        width: arrow_container_width,
                                        marginTop: "10px",
                                        display: "block",
                                        borderRadius: "0px",
                                        border: "0",
                                        right: padding_l_r + "px",
                                    }}>
                                    <div style={{
                                        textAlign: "left",
                                        font: arrow_font,
                                        letterSpacing: "0",
                                        margin: "0",
                                        position: "absolute",
                                        top: "50%",
                                        msTransform: "translateY(-50%)",
                                        transform: "translateY(-50%)",
                                        color: "var(--mainWhite)",
                                    }}>
                                        Sign up  <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "50px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : authModal?.route === "reset" ?
                            <div style={{
                                top: '50%',
                                height: "auto",
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: is_mobile ? '93%' : 'auto',
                                minWidth: 400,
                                paddingBottom: 40
                            }} className={classes.paper}>
                                <div className="d-flex">
                                    <div style={{
                                        font: "24px/24px Futura Hv BT",
                                        color: "#191919",
                                        paddingLeft: '20px',
                                        paddingBottom: '30px',
                                        paddingTop: '15px',
                                        flexGrow: "1",
                                    }}>Password Reset</div>
                                    <div
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setOpenAuthModal(false)}><TimesIcon style={{ marginTop: "15px" }} width={'30'} height={'30'} /></div>

                                </div>
                                <div>
                                    <div className={"padding_left_right_" + padding_l_r}>
                                        <div className={classesTextField.root}>
                                            <EmailAddressLoginTextField
                                                label="Email Address or Username"
                                                required={true}
                                                id="email_address_login"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className={"padding_left_right_" + padding_l_r + " position-relative margin_top_20"}>
                                    <div

                                        onClick={resetPassword}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor: "var(--mainGreen)",
                                            padding: "15px",
                                            width: "fit-content",
                                            marginTop: "10px",
                                            display: "block",
                                            float: "right",
                                            border: "0",
                                            right: padding_l_r + "px",
                                        }}>
                                        <div style={{
                                            textAlign: "center",
                                            font: "14px/14px Futura Md BT",
                                            letterSpacing: "0",
                                            margin: "0",
                                            color: "var(--mainWhite)",
                                        }}>
                                            Reset Password  {loading ? <CircularProgress
                                                size={20}
                                                style={{
                                                    marginLeft: "50px"
                                                }}
                                                color="inherit" /> : <RightArrowIcon width={arrow_icon_size} height={arrow_icon_size} style={{ marginLeft: "50px" }} />}
                                        </div>

                                    </div>
                                </div>

                            </div> :
                            ""}



            </Modal>
        </div>

    )
})
