import Rating from '@material-ui/lab/Rating'
import React from 'react'
import ReviewsDescription from "./ReviewsDescription"
import { connect } from 'react-redux'
import moment from 'moment'
import { Element, Link } from 'react-scroll'


const ReviewsPricelineFeature_ = ({ reviewsPriceline, setMenu, goodReview }) => {

    return (

        <div style={{ marginBottom: "2rem" }}>
            {goodReview ?


                <div >

                    <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                        <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                            <ReviewsDescription setMenu={setMenu} description={goodReview} boxDescription={"box_3"} fontDescription={"13px/22px Futura Lt BT"} colorDescription={"#191919"} />
                            <Link to="reviews" spy={true} smooth={true} duration={1000} offset={-125} style={{ font: "14px/16px Futura Hv BT", color: "var(--mainGreen)", cursor: "pointer" }} >
                                <p style={{
                                    verticalAlign: "middle",
                                    textAlign: "right", marginRight: 10,
                                }} onClick={() => setMenu("reviews")}
                                >See more reviews</p></Link>
                        </div>
                    </div>

                </div>

                : reviewsPriceline !== null && reviewsPriceline.length > 0 && reviewsPriceline.map((review, review_index) => {
                    //const priceline_review_1_date = review.priceline_review_1_date !== undefined ? moment(review.priceline_review_1_date, 'YYYY-MM-DD').format("MMM Do[,] YYYY") : ""
                    //const priceline_review_1_rating = review.priceline_review_1_rating !== undefined ? review.priceline_review_1_rating : ""
                    const priceline_review_1_text = review.priceline_review_1_text !== undefined ? "“" + review.priceline_review_1_text + "”" : ""
                    //const priceline_review_1_user = review.priceline_review_1_user !== undefined ? review.priceline_review_1_user : ""
                    return (
                        <div key={review_index} >
                            {priceline_review_1_text !== null && priceline_review_1_text !== "" &&
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "30px" }}>
                                    <div style={{ flexGrow: "1", flexWrap: "wrap" }}>
                                        <ReviewsDescription setMenu={setMenu} description={priceline_review_1_text} boxDescription={"box_3"} fontDescription={"13px/22px Futura Lt BT"} colorDescription={"#191919"} />
                                        <Link to="reviews" spy={true} smooth={true} duration={1000} offset={-125} style={{ font: "14px/16px Futura Hv BT", color: "var(--mainGreen)", cursor: "pointer" }} >
                                            <p style={{
                                                verticalAlign: "middle",
                                                textAlign: "right", marginRight: 10,
                                            }} onClick={() => setMenu("reviews")}
                                            >See more reviews</p></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const ReviewsPricelineFeature = connect(mapStateToProps, mapDispatchToProps)(ReviewsPricelineFeature_)
export default ReviewsPricelineFeature 
