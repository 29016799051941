
import { Grid } from "@material-ui/core"
import React from 'react'
import { useLocation,useHistory } from 'react-router-dom'

import { FlightRecapComponent } from "./FlightRecapComponent"
import { useSelector } from "react-redux"
import { Modal } from 'react-bootstrap'


export const FlightRecapList = () => {
    let location = useLocation()
    const history = useHistory()
    const pathName = location?.pathname;
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const selectedReturnFlight = useSelector(state => state.Flights.selectedReturnFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    const flightContract = useSelector(state => state.Flights.flightContract);
    const selectedFlight = selectedFlightMode === ('round_trip' || 'roundTrip') && selectedReturnFlight ? selectedReturnFlight : selectedDeparture;
    const importantInfo = flightContract?.itinerary_data?.important_info_data;
    const baggageInfo = flightContract?.itinerary_data?.popup_data?.baggage_fees?.paragraph_data;
    const fareRules = flightContract?.itinerary_data?.fare_rules_data;
    const passportData = flightContract?.itinerary_data?.popup_data?.valid_passport;

  


    const [show, setShow] = React.useState(false);

    const handleClose = () =>{
        history.replace(pathName)
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const showFare = () => {
        setModalInfo({
            title: "Fare rules",
            value: fareRules
        })
        handleShow()
    }

    const showPassport = () => {
        setModalInfo({
            title: "Valid Passport",
            value: passportData?.paragraph_data
        })
        handleShow()
    }
    const [modalInfo, setModalInfo] = React.useState({
        title: "",
        value: null
    });



    React.useEffect(() => {
        const hash = location?.hash;
        if (hash && hash === "#fare_data") {
            showFare()
        }
        if (hash && hash === "#valid_passport") {
            showPassport()
        }

    }, [location])

    return (
        <div>
            <Modal show={show} onHide={handleClose} style={{
                maxWidth: '80% !Important', top: "12%"
            }}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalInfo.title}</Modal.Title>
                </Modal.Header>

                {modalInfo && modalInfo?.value && <Modal.Body
                    style={{
                        height: '600px',
                        overflowY: 'auto'
                    }}>
                    {
                        Object.keys(modalInfo?.value).map((key) => {
                            let value = modalInfo?.value[key];
                            const title = value?.title;
                            if (value?.text) {
                                value = value?.text;
                            }
                            return (
                                <div key={key} style={{ paddingTop: 10 }} >
                                    {title && title && <div style={{ font: "18px/16px Futura Md BT", lineHeight: 1.3, marginBottom: 3 }} >{title}</div>}
                                    <div style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, }} dangerouslySetInnerHTML={{ __html: value }}></div>
                                </div>
                            )
                        })}
                </Modal.Body>}


            </Modal>

            <Grid container>
                {/* /outgoing */}
                {selectedDeparture && selectedDeparture.slice_data &&
                    <Grid item style={{ border: '1px solid #DFDFDF', marginBottom: 30, width: '100%' }}>
                        {selectedDeparture.slice_data.map((slice, sliceIndex) => {
                            return (
                                <FlightRecapComponent flightData={slice} key={sliceIndex + "recap"} title="Outgoing" />
                            )
                        })}
                    </Grid>}




                {/* // return component */}
                {selectedReturnFlight && selectedFlightMode === 'round_trip' && selectedReturnFlight.slice_data &&
                    <Grid item style={{ border: '1px solid #DFDFDF', marginBottom: 30, width: '100%' }}>
                        {selectedReturnFlight.slice_data.map((slice, sliceIndex) => {
                            return (
                                <FlightRecapComponent flightData={slice} key={sliceIndex + "recap"} title="Return" />
                            )
                        })}
                    </Grid>}



            </Grid>

            {selectedFlight && <div style={{
                padding: '20px 60px', border: '1px solid #DFDFDF', marginTop: -15, marginBottom: 15
            }}>
                <div style={{ font: "24px/24px Futura Md BT", }}>Cancellation policy:</div>

                <div style={{ font: "16px/16px Futura Lt BT", paddingTop: 30 }}>This ticket is cancellable within 24hrs from booking. Check your flight booking to see how to cancel</div>
            </div>}

            {baggageInfo && <div style={{
                padding: '20px 60px', border: '1px solid #DFDFDF', marginBottom: 15
            }}>
                <div style={{ font: "24px/24px Futura Md BT", paddingBottom: 20 }}>Baggage policy:</div>

                {
                    Object.keys(baggageInfo).map((key) => {
                        const text = baggageInfo[key];

                        return (
                            <div key={key} >
                                <div style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, paddingTop: 10 }}>{text}</div>
                            </div>
                        )
                    })}
            </div>}

            {importantInfo && <div style={{
                padding: '20px 60px', border: '1px solid #DFDFDF', marginBottom: 15
            }}>
                <div style={{ font: "24px/24px Futura Md BT", }}>Important information:</div>

                {
                    Object.keys(importantInfo).map((key) => {
                        const paragraph = importantInfo[key]?.paragraph_data;

                        return (
                            <div key={key} style={{ paddingTop: 20 }}>
                                {paragraph && Object.keys(paragraph).map(paragraphKey => {
                                    let text = paragraph[paragraphKey];
                                    const matchValue = text.match(/#.*[START]+#/) ? text.match(/#.*[START]+#/)[0] : null
                                    const isFare = matchValue ? matchValue.includes("fare") : false;
                                    const isPassport = matchValue ? matchValue.includes("passport") : false;

                                    const link = isFare ? '#fare_data' : isPassport ? '#valid_passport' : '';

                                    if (text) {
                                        text = text.replace(/#.*[START]+#/, `<a href="pageLink" style="color:steelblue">`)
                                        text = text.replace(/#.*[END]+#/, "</a>")
                                        text = text.replace("pageLink", link)
                                    }

                                    return (
                                        <div key={paragraphKey}>
                                            <div dangerouslySetInnerHTML={{ __html: text }} style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, paddingTop: 10 }}></div>
                                        </div>
                                    )
                                })}
                            </div>
                        )



                    })}

            </div>}


            {selectedFlight && <div style={{
                font: "16px/16px Futura Md BT", background: '#19BC9B33', color: "#19BC9B", padding: '20px 0px', paddingLeft: 60, margin: '20px 0px', border: '0px solid #DFDFDF'
            }}> You'll be able to select seats on the airline's website after you book</div>
            }




        </div>
    )
}