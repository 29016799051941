import "./TripImageBar.css"

import * as Actions from '../../../redux/actions'

import {
    TripNameTextField,
    TripStartDateTextField
} from '../Common/FormComponentsBank'
import { image_boxing, loadingImage } from "../Common/RegExValidate"

import { CameraSolidIcon } from "../Common/SVGBank"
import CircularProgress from '@material-ui/core/CircularProgress'
import HandleName from "../Common/Profile/HandleName"
import { Image } from "react-bootstrap"
import PropTypes from "prop-types"
import ReCalculateTripCostTimelineWizard from '../Common/TimelineWizard/ReCalculateTripCostTimelineWizard'
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { encryptID } from "../HotelPage/hotelServices"

/* top bar component */
const TripImageBar_ = ({ getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, setTripToTimeline, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const goesTripPage = (tripId, tripName) => {
        const encryptedId = encryptID(tripId)
        const url = "/trip/" + tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
        history.push(url.toLowerCase())
        getPublicTrip(tripId, lang)
    }

    /*  set selected trip */
    const tripMemberLinkHandler = (uuid, id) => {
        const url = "/muver/trip/"+id;
        history.push(url.toLowerCase())
    }
    const [imageFileInput, setImageFileInput] = React.useState(null)

    let topInnerImageHeight = ""
    let topImageHeight = ""
    if (!isNaN(screenSize)) {
        topInnerImageHeight = screenSize / 9 + 'px'
        topImageHeight = screenSize < 500 ? screenSize / 1.8 : screenSize / 2.5
    }
    const trip = from === "trip_private" ? trip_private : trip_public
    const uuid = from === "trip_private" ? uuidPrivate : uuidPublic
    let containerH = 500
    let imageH = 120
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
        imageH = topInnerImageHeight
    }

    //  trip.nav.map(trip => ()=>console.log(trip))
    // trip.nav.map(x=>console.log (x.tripId))
    let nav_previous = ""
    let nav_next = ""
    let nav_previous_name = ""
    let nav_next_name = ""
    if (trip.nav !== undefined && trip.nav.length > 0) {
        nav_previous = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[0] !== undefined && trip.nav[0] !== null && trip.nav[0].tripID !== undefined ? trip.nav[0].tripID : ""
        nav_next = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[1] !== undefined && trip.nav[1] !== null && trip.nav[1].tripID !== undefined ? trip.nav[1].tripID : ""
        nav_previous_name = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[0] !== undefined && trip.nav[0] !== null && trip.nav[0].tripName !== undefined ? trip.nav[0].tripName : ""
        nav_next_name = trip.nav !== undefined && trip.nav.length > 0 && trip.nav[1] !== undefined && trip.nav[1] !== null && trip.nav[1].tripName !== undefined ? trip.nav[1].tripName : ""
    }
    const tripHeaderImage = from === "trip_private" ? activeTrip.tripImage : trip_public.tripHeaderImage
    const srcTripImage = tripHeaderImage !== undefined && tripHeaderImage !== null ? image_boxing(tripHeaderImage, screenSize, topImageHeight) : loadingImage.src
    const display_letters = screenSize > 1024 ? 29 : 20
    const display_letters_owner = screenSize > 1024 ? 29 : 10
    return (
        <div className="position-relative z_index_1">
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <Image
                src={srcTripImage}
                alt="trip image" width={screenSize} height={topImageHeight} />}
            {tripHeaderImage !== undefined && tripHeaderImage !== null && <div className="mask_block" style={{ height: containerH }}></div>}
            {from !== "trip_private" && tripHeaderImage !== undefined &&
                <div className="z_index_3 mask_avatar_btn text-center">
                    <Image className="rounded-circle" src={trip.ownerAvatar !== undefined ? image_boxing(trip.ownerAvatar, imageH, imageH, "muvimages/user_avatar/armando.jpg") : ""} alt="Avatar" style={{ width: imageH, height: imageH }} />
                    {trip.ownerHandle !== undefined && trip.ownerHandle !== "" && <HandleName muverHandle={trip.ownerHandle} styleHandle={{ cursor: "pointer" }} classNameHandle={"futura_md_bt_26_10_white margin_top_20_avatar text-center"} />}
                </div>}
            {from !== "trip_private" ?
                <div className="z_index_3 mask_title_btn">
                    <div className="futura_hv_bt_60_60_white box_1 text-center" style={{ height: "100px" }} >{trip.tripName !== undefined && trip.tripName.length > display_letters + 1 ? trip.tripName.substr(0, display_letters) + "..." : trip.tripName}</div>
                </div>
                :
                <React.Fragment>
                    <div style={{
                        top: "43%",
                        left: "8.5%",
                        position: "absolute",
                        zIndex: "4",
                    }}>
                        <div style={{
                            marginLeft: logo_width + "px",
                        }}>
                            {activeTrip !== undefined && activeTrip.tripName !== undefined
                                && <TripNameTextField inputStyle={{
                                    font: "45px/45px Futura Md BT",
                                    color: "#FFFFFF",
                                    width: "68vw",
                                }} />}
                            {activeTrip !== undefined && activeTrip.tripStartDate !== undefined
                                && <TripStartDateTextField
                                    isShortFormat={true}
                                    inputStyle={{
                                        font: "30px/56px Futura Lt BT",
                                        color: "#FFFFFF",
                                        width: "500px",
                                    }} />}
                            {activeTrip !== undefined && activeTrip.tripCost !== undefined && activeTrip.tripCost !== null
                                && <div style={{ marginTop: "30px" }}>
                                    <span style={{ font: "16px/19px Futura Lt BT", color: "#FFFFFF" }}>Cost if booked today:</span>
                                    <div style={{ position: "relative", display: 'inline-block', }}>
                                        {!activeTrip.tripCost.fetching
                                            && activeTrip.tripCost !== undefined
                                            && activeTrip.tripCost !== undefined
                                            && activeTrip.tripCost.cost !== undefined
                                            && activeTrip.tripCost.cost !== null ?
                                            <span style={{ font: "16px/19px Futura Md BT", color: activeTrip.tripCost.fetching ? "#6A9EEC" : "#FFFFFF", marginLeft: "5px" }}>{activeTrip.tripCost.cost.currency_symbol + " " + activeTrip.tripCost.cost.cost}</span>
                                            :
                                            <CircularProgress
                                                disableShrink
                                                size={15}
                                                style={{
                                                    color: "#6A9EEC",
                                                    marginLeft: "5px"
                                                }} />
                                        }
                                        <div style={{ position: "absolute", top: "-5px", right: "-20px", cursor: "pointer" }} >
                                            <ReCalculateTripCostTimelineWizard />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{
                        top: "38%",
                        left: "0px",
                        right: "0px",
                        textAlign: "center",
                        position: "absolute",
                        zIndex: "3",

                    }}>
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={event => {
                                event.target.files !== undefined
                                    && event.target.files.length > 0
                                    && event.target.files[0] !== undefined
                                    && uploadTripImage((window.URL || window.webkitURL).createObjectURL(event.target.files[0]), activeTrip.tripId, tripImage.selectedFilePath, event.target.files, uuidPrivate, lang)
                            }}
                            ref={fileInput => setImageFileInput(fileInput)}
                        />
                        <div style={{ display: "inline-block" }} onClick={() => imageFileInput.click()}>
                            <CameraSolidIcon />
                            <div style={{ font: "24px/29px Futura Md BT", color: "#C7C7C7" }}>
                                Upload your banner
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }

            {nav_previous !== "" && switch_container === member_area_router.memberAreaTrip &&
                <div className="z_index_3 mask_left_btn">
                    {from === "trip_private" ?
                        <div onClick={() => tripMemberLinkHandler(uuid, nav_previous)} style={{ cursor: "pointer" }} >
                            <div className="futura_lt_bt_18_24_white text-right">Previous</div>
                            <hr className="line_under" />
                        </div>
                        :

                        <div onClick={() => goesTripPage(nav_previous, nav_previous_name)} style={{ cursor: "pointer" }} >
                            <div className="futura_lt_bt_18_24_white text-right">Previous</div>
                            <hr className="line_under" />
                        </div>
                    }
                </div>}
            {nav_next !== "" && switch_container === member_area_router.memberAreaTrip &&
                <div className="z_index_3 mask_right_btn">

                    {from === "trip_private" ? <div onClick={() => tripMemberLinkHandler(uuid, nav_next)} style={{ cursor: "pointer" }} >
                        <div className="futura_lt_bt_18_24_white text-left">Next</div>
                        <hr className="line_under" />
                    </div>
                        :
                        <div onClick={() => goesTripPage(nav_next, nav_next_name)} style={{ cursor: "pointer" }} >
                            <div className="futura_lt_bt_18_24_white text-left">Next</div>
                            <hr className="line_under" />
                        </div>
                    }
                </div>}
        </div>
    )
}

TripImageBar_.propTypes = {
    trip_public: PropTypes.object.isRequired,
    trip_private: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,

        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const TripImageBar = connect(mapStateToProps, mapDispatchToProps)(TripImageBar_)
