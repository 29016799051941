import * as Actions from '../../../../redux/actions'

import {
	BusIcon,
	FlightShapeIcon,
	HotelDuotoneIcon,
	MapDuotoneIcon,
	SubwayIcon,
} from "../SVGBank"

import PriceLineBookingFormElement from './PriceLineBookingFormElement'
import { PropTypes } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'

const BookingForm_ = ({ activeTab, setBookingFormActiveTab, is_mobile }) => {


	return (
		<div>
			<div className="col-md-12">
				<div className="row tabSelection">
				<div className={activeTab === 'planNewTrip' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('planNewTrip')}>
						{is_mobile &&  <FontAwesomeIcon icon={faRoute} fill={activeTab === 'planNewTrip' ? 'var(--mainGreen)' : 'white'}
							 width={15} height={15} style={{ marginRight: "0px", marginBottom: "0px" }}   />}
						<h4>
							{!is_mobile && 
							 <FontAwesomeIcon icon={faRoute} fill={activeTab === 'planNewTrip' ? 'var(--mainGreen)' : 'white'}
							 width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }}   />}

							{is_mobile ? "Plan a trip" : "Plan a trip"}
						</h4>
					</div>
					<div className={activeTab === 'plan a trip' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('plan a trip')}>
						{is_mobile && <MapDuotoneIcon width={19} height={15} style={{ marginRight: "0px", marginTop: "0px" }} fill={activeTab === 'plan a trip' ? 'var(--mainGreen)' : 'white'} />}
						<h4>
							{!is_mobile && <MapDuotoneIcon width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }} fill={activeTab === 'plan a trip' ? 'var(--mainGreen)' : 'white'} />}
							{is_mobile ? "Explore" : "Explore"}
						</h4>
					</div>
					
					<div className={activeTab === 'hotel' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('hotel')}>
						{is_mobile && <HotelDuotoneIcon width={19} height={15} style={{ marginRight: "0px", marginTop: "0px" }} fill={activeTab === 'hotel' ? 'var(--mainGreen)' : 'white'} />}
						<h4>
							{!is_mobile && <HotelDuotoneIcon width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }} fill={activeTab === 'hotel' ? 'var(--mainGreen)' : 'white'} />}
							{"Hotel"}
						</h4>
					</div>
					<div className={activeTab === 'flight' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('flight')}>
						{is_mobile && <FlightShapeIcon width={19} height={15} style={{ marginRight: "0px", marginTop: "0px" }} fill={activeTab === 'flight' ? 'var(--mainGreen)' : 'white'} />}
						<h4>
							{!is_mobile && <FlightShapeIcon width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }} fill={activeTab === 'flight' ? 'var(--mainGreen)' : 'white'} />}
							{"Flight"}
						</h4>
					</div>
					{/* <div className={activeTab === 'train' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('train')}>
						{is_mobile && <SubwayIcon width={19} height={15} style={{ marginRight: "0px", marginTop: "0px" }} fill={activeTab === 'train' ? 'var(--mainGreen)' : 'white'} />}
						<h4>
							{!is_mobile && <SubwayIcon width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }} fill={activeTab === 'train' ? 'var(--mainGreen)' : 'white'} />}
							{"Train"}
						</h4>
					</div>
					<div className={activeTab === 'bus' ? "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual activeTab" : "col-2 col-xs-2 col-sm-2 col-md-2 p-0 tabs-manual"} style={{ display: is_mobile ? "block" : "flex" }} onClick={() => setBookingFormActiveTab('bus')}>
						{is_mobile && <BusIcon width={19} height={15} style={{ marginRight: "0px", marginTop: "0px" }} fill={activeTab === 'bus' ? 'var(--mainGreen)' : 'white'} />}
						<h4>
							{!is_mobile && <BusIcon width={19} height={15} style={{ marginRight: "5px", marginBottom: "2px" }} fill={activeTab === 'bus' ? 'var(--mainGreen)' : 'white'} />}
							{"Bus"}
						</h4>
					</div> */}
					
				</div>
			</div>

			<div className="col-md-12 p-0">
				<PriceLineBookingFormElement />
			</div>

		</div >
	)

}

BookingForm_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,

		activeTab: state.FormBank.BookingForm.activeTab,

		uuid: state.Member.authModal.uuid,
		is_mobile: state.Setting.is_mobile,
	}

}

const mapDispatchToProps = {
	setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}
const BookingForm = connect(mapStateToProps, mapDispatchToProps)(BookingForm_)

export default BookingForm