import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Grid } from "@material-ui/core"
import moment from "moment"
import { Image } from "react-bootstrap"
import { Rating } from "@material-ui/lab"
import EstablishmentName from '../Common/HotelEstablishment/EstablishmentName'
import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import { URL_REPLACE } from '../Common/RegExValidate'
import { getPriceLineHotels } from '../../../redux/actions'
import { searchHotelByNameThunk } from '../BookingPage/PaymentPage/payment.thunk'
import { useHistory, useLocation } from "react-router-dom"
import { encryptID } from '../HotelPage/hotelServices'

const MuverTripHotel_ = ({ hotelSearchForm, lazyLoading, hotel, isMobile, bodyClientWidth, logo_width }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isSearching = hotelSearchForm.isSearching !== undefined ? hotelSearchForm.isSearching : true;
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.73) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2

    const imageUrl = hotel?.images && hotel?.images?.length > 0 ? hotel?.images[0].full_url : "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
    const thumbnailImg = hotel?.room_json?.thumbnail ? hotel?.room_json?.thumbnail?.slice(2): "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
    const checkIn = hotel.check_in ? moment(hotel.check_in).format('ddd MMM DD') : "";
    const checkOut = hotel.check_out ? moment(hotel.check_out).format('ddd MMM DD') : "";
    
    const bookingId = hotel?.booking_trip_number;
    const booked = hotel?.booking_status === "Confirmed";
    const marginLeft = isMobile ? 0 : 150;


    const isMatching = function (str1, str2) {
        return new RegExp("\\b(" + str1.match(/\w+/g).join('|') + ")\\b", "gi").test(str2);
    };



    const searchHotels = () => {
        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        let url = ""
        const encryptedId =  encryptID(hotel?.pricelineCityId)
        url = "/hotel-search?city_id=" + encryptedId
            + "&check_in=" + hotel?.check_in
            + "&check_out=" + hotel?.check_out
            + "&rooms=" + 1
            + "&guests=" + 1
            + "&city_lat=" + hotel?.latitude
            + "&city_lng=" + hotel?.longitude
            + "&where=" + (where.replace(URL_REPLACE, "_"))



        history.push(url.toLowerCase())

    }

    const bookNow = async () => {
        ///setTimeLineStatus(true);
        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        const city_id = hotel?.pricelineCityId;
        const check_in = hotel?.check_in;
        const check_out = hotel?.check_out;
        const rooms = 1;
        const adults = 1;
        const featuredHotels = hotel?.pricelineHotelId;
        const city_latitude = hotel?.latitude;
        const city_longitude = hotel?.longitude;

        const getData = await getPriceLineHotels({ city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude, isSearching }, lazyLoading, fullCurrency, featuredHotels)     // FormBankAction  [fetchPricelineHotels]

        const params = {
            check_in: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            check_out: hotel?.activityDuration ? moment(new Date(), 'YYYY-MM-DD').add(parseInt(hotel?.activityDuration, 10), 'days').format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            lat: hotel?.activityLatitude,
            lng: hotel?.activityLongitude,
        }

        let url = ""
        if (hotel?.pricelineCityId) {
            const encryptedId =  encryptID( hotel?.pricelineHotelId)
            url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                + "?city_id=" + hotel?.pricelineCityId
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))

            if (hotel?.ppn_bundle) {
                url += "&ppn_bundle=" + hotel?.ppn_bundle
            }
        }
        else {

            url = "/hotel-search?city_id=" + null
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))
        }


        history.push(url.toLowerCase())
    }


    const goToDetails = ()=>{
        const images = hotel?.images && hotel.images?.length > 0 ?hotel?.images[0] :null;
        const imgUrl = images? images?.full_url :null;
        history.push(`/hotel/booking_details?booking_id=${hotel?.booking_trip_number}&email=${hotel?.booking_email}&imageUrl=${imgUrl}`)
    }

    const height = !booked? 370: 335;
    return (
        <div style={{
            display: "flex",
            position: "relative",
            margin: "0px",
            color: "#000000",
            width: isMobile ? "100%" : "81%",
            marginLeft: marginLeft,
        }}>
            <div>
                <div className={"margin_top_20 margin_bottom_20"}>
                    <div style={{ display: isMobile ? "block" : "flex", background:"#F5F7F6", }}>
                        <div className="d-inline-block" style={{
                            width: "100%", maxWidth: 560,
                            backgroundColor: "#e8e8e8"
                        }}>
                            <img style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: "100%", maxHeight: height }}
                                alt="establishment" src={imageUrl}   onError={({ currentTarget }) => {
                                    const thumbnailImg = hotel?.room_json?.thumbnail ? "http://"+ hotel?.room_json?.thumbnail?.slice(2)?.replace("150","300"): "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=thumbnailImg;
                                  }}
                                />

                        </div>



                        <div style={{ width: "100%", height: height, maxHeight: height, border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", position: "relative" }}>



                            <div style={{paddingLeft: "10px"}}>
                            <div style={{ float: "right", padding: 20, textAlign: "right", marginTop:-20 }}>
                                {!booked && <div
                                    style={{
                                        color: 'white',
                                        marginLeft: 15,
                                        borderRadius: 15,
                                        padding: 10,
                                        background: "#E63155",
                                        font: "14px/15px Futura Lt BT",
                                        width: "fit-content"
                                    }}>
                                    Best place to stay
                                </div>}

                                {!booked && <div onClick={searchHotels}
                                    style={{
                                        color: "#E63155",

                                        float: "right",
                                        cursor: "pointer",
                                        textAlign: "right",
                                        font: "14px/15px Futura Lt BT",
                                    }}>
                                    see other available hotels
                                </div>}

                                {booked && <div 
                                    style={{
                                        // color: "#E63155",

                                        float: "right",
                                        cursor: "pointer",
                                        textAlign: "right",
                                        font: "24px/15px Futura Md BT",
                                    }}>
                                    BOOKED
                                </div>}


                            </div>

                            {hotel?.neighborhood && <div className="box_1 padding_left_10 padding_right_10" style={{
                                font: "14px/16px Futura Md BT",
                                color: "var(--mainGreen)",
                                marginTop: 16,
                            }}>
                                {hotel?.neighborhood ? JSON.parse(hotel?.neighborhood)?.name : ""}
                            </div>}
                            {hotel?.distance && <div className="box_1 padding_left_10 padding_right_10" style={{
                                font: "12px/14px Futura Lt BT",
                                color: "var(--mainGrey)",
                                marginTop: 20,
                                marginBottom: 1,
                            }}>
                                {hotel?.distance ? Number(hotel?.distance).toFixed(1) : ''} km from city center
                            </div>}
                            <EstablishmentName
                                establishmentName={hotel?.name}
                                establishmentCity={hotel?.cityName}
                                establishmentState={hotel?.stateName}
                                establishmentCountry={hotel?.countryName}
                                establishmentId={hotel?.pricelineHotelId}
                                pricelineId={hotel?.pricelineHotelId}

                                ppn_bundle={hotel?.ppn_bundle}

                                titleClass={"box_1"} titleStyle={{
                                    font: "28px/43px Futura Hv BT",
                                    color: "var(--frameBlack)",
                                    cursor: "pointer",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                }} />

                            {hotel?.starRating &&
                                <div className="d-flex padding_left_10 padding_right_10 btn_rounded" style={{ paddingTop: 0 }}>
                                    <div className="d-flex timeline_rating_color" style={{ width: "50%" }}>
                                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(hotel?.starRating)} disabled={true} />
                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{ color: "#C7C7C7" }}>({hotel?.starRating})</div>

                                    </div>
                                    {hotel?.guestScore && <div style={{ width: "50%" }}>
                                        <div className="padding_left_10 padding_right_10" style={{ textAlign: "right", font: "12px/15px Futura Lt BT", color: "var(--mainBlack)" }} >Guest rating: <div style={{ font: "16px/15px Futura Md BT", display: "inline-block" }}>{hotel?.guestScore ? Number(hotel?.guestScore)?.toFixed(1) : ''}/</div><div style={{ font: "12px/15px Futura Md BT", display: "inline-block" }}>10</div></div>
                                    </div>}
                                </div>}

                            {hotel?.description !== undefined &&
                                <div className={"padding_left_10 padding_right_10"} style={{}}>
                                    <EstablishmentDescription description={hotel?.description}
                                        boxDescription={"box_3"}
                                        fontDescription={"14px/19px Futura Lt BT"}
                                        colorDescription={"var(--mainGrey)"}
                                        textLength={280}
                                        height={'100px'}
                                        bottom={"20px"}
                                        maxHeight={'100px'} />
                                </div>}

                                </div>

                                {booked &&<div style={{background:"#777777",color:"white",display:"flex",
                            justifyContent: "space-between", position:"absolute",bottom:0,width:"100%",
                            padding: "11px 22px",    font: isMobile?"10px / 19px Futura Lt BT":  "14px / 19px Futura Lt BT"}}>
                                {checkIn && <div style={{}}>
                                    Check In:
                                    <div style={{ font: isMobile?"10px / 19px Futura Md BT":  "14px / 19px Futura Md BT"}}> {checkIn}</div>
                                </div>}

                                {checkOut && <div style={{}}>
                                    Check Out:
                                    <div style={{ font: isMobile?"10px / 19px Futura Md BT":  "14px / 19px Futura Md BT"}}> {checkOut}</div>
                                </div>}

                                {hotel?.cost && <div style={{}}>
                                    Total Cost:
                                    <div style={{ font: isMobile?"10px / 19px Futura Md BT":  "14px / 19px Futura Md BT"}}> {hotel?.display_symbol}{hotel?.display_currency}{hotel?.cost}</div>
                                </div>}

                                {hotel?.booking_trip_number && <div style={{}}>
                                Confirmation#:
                                    <div style={{ font: isMobile?"10px / 19px Futura Md BT":  "14px / 19px Futura Md BT"}}> {hotel?.booking_trip_number} <span onClick={goToDetails} style={{color:"#F5F7F6",cursor:"pointer", font: "12px / 19px Futura Md BT"}}> details</span> </div>
                                </div>}

                                </div>}


                            {!booked && <div>
                                <div className={"padding_left_10 padding_right_10"} style={{ marginBottom: "0px" }}>
                                    <div className="box_1 padding_left_10 padding_right_10" style={{
                                        font: "12px/14px Futura Md BT",
                                        color: "var(--mainBlack)", textAlign: "right"
                                    }}>from</div>
                                    <div className="box_1 padding_left_10 padding_right_10" style={{
                                        font: "22px/24px Futura Md BT",
                                        color: "var(--mainBlack)", textAlign: "right"
                                    }}><div style={{ display: "inline-block", font: "12px/14px Futura Md BT", marginBottom: "0px" }}>{hotel?.display_currency}</div><div style={{ display: "inline-block" }}>{hotel?.display_symbol}{Math.round(hotel?.cost)}</div></div>
                                </div>


                                <div
                                    style={{
                                        font: "24px/15px Futura Md BT",
                                        marginBottom: 20,
                                        marginRight: 20,
                                        float: "right",
                                        textAlign: "right",
                                    }}>
                                    {hotel?.price_display_currency && <span style={{
                                        font: "14px/15px Futura Lt BT",
                                    }}>{hotel?.price_display_currency} </span>}{hotel?.price_display_symbol}{hotel?.price_display_total_fare}

                                    <div onClick={bookNow}
                                        style={{
                                            color: "#19BC9B",
                                            cursor: "pointer",
                                            marginTop: 5,
                                            paddingBottom: 10,
                                            font: "14px/15px Futura Lt BT",
                                        }}>
                                        Book this hotel
                                    </div>
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => ({
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    logo_width: state.Setting.appMenu.logo.width,
    isMobile: state.Setting.is_mobile,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    logo_height: state.Setting.appMenu.logo.height,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    HotelSearch: state.FormBank.HotelSearch,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switch_container: state.Member.switch_container,
})

const mapDispatchToProps = {
}

const MuverTripHotel = connect(mapStateToProps, mapDispatchToProps)(MuverTripHotel_)
export default MuverTripHotel
