import * as Actions from '../../../../redux/actions'

import {
    AddressMapIcon,
    CalendarDuotoneIcon,
    TimesIcon,
} from "../SVGBank"

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React from 'react'
import { connect } from 'react-redux'

const PlanTripDestinaitonElement_ = ({index,error, switch_container,setStartingPoint, destination, setDestinationsData, removeAddedDestination, lengthDestiantions, is_mobile, lang, getDestinationListTopBar, searchListDestinations, activeTrip, isRoadTrip}) => {
    const [startDate, setStartDate] = React.useState(destination.startDate)
    const [selectedDestination, setSelected] = React.useState()


    const handleChangeDuration = (event) => {
        setDestinationsData(destination.destinationId, destination.startDate, event.target.value, destination.positionId, destination.availableSelectDate,selectedDestination)
    }
    const handleChangeStartDate = (date) => {
        setStartDate(date)
        setDestinationsData(destination.destinationId, date, destination.duration, destination.positionId, destination.availableSelectDate,selectedDestination)
    }
    const handleChangeDestiantion = (destinations_autocomplete) => {
        if(index === 0 && isRoadTrip ){
            setStartingPoint(destinations_autocomplete[0]);
           }
        const destination_id = destinations_autocomplete[0] !== undefined ? destinations_autocomplete[0].destinationId : "";
        const selected = destinations_autocomplete && destinations_autocomplete?.length > 0 ?destinations_autocomplete[0]:null;
        setSelected(selected)
        setDestinationsData(destination_id, destination.startDate, destination.duration, destination.positionId, destination.availableSelectDate,selected)
    }

    const handleSearchDestinations = (query) => {
        //console.log(`PlanTripFormDestinationElement handleSearchDestinations isRoadTrip =${isRoadTrip}`)
        getDestinationListTopBar(query, lang, isRoadTrip)
    }
    React.useEffect(() => {
        setStartDate(destination.startDate)
    }, [destination.startDate])
    var indexArray = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];


    let placeholder = isRoadTrip && index === 0? 'Leaving from': `Choose your ${indexArray[index]} destination`; 
    if(isRoadTrip){
       placeholder =  index === 0 ?   'Leaving from' :  `Choose your ${indexArray[index-1]} destination`;
    }
    else{
        placeholder = `Choose your ${indexArray[index]} destination`;
    }
    //console.log(`PlanTripFormDestinationElement switch_container = ${switch_container}`)
    //console.log(`PlanTripFormDestinationElement tripMode = ${tripMode}`)
    //console.log(`PlanTripFormDestinationElement isRoadTrip = ${isRoadTrip}`)
    
    return (
        <div className="row m-0 p-0 w-100">
            <div className={(isRoadTrip && index !== 0) ? "col-12 col-md-10 mt-3 p-0 position-relative":
            !isRoadTrip && lengthDestiantions === 1?  "col-12 col-md-12 mt-3 p-0 position-relative": 
            "col-12 col-md-8 mt-3 p-0 position-relative"}>
                {lengthDestiantions > 1 && <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: "1",
                        top: "18px",
                        left: "-20px",
                    }}
                    onClick={() => removeAddedDestination(destination)}><TimesIcon width={'15'} height={'15'} />
                </div>}

                <div className="position-relative">
                    <AsyncTypeahead
                        id="planTrip-typeahead"
                        labelKey={(option) =>
                            option.stateName !== undefined && option.stateName !== "" ?
                                `${option.name}, ${option.stateName}, ${option.countryName}`
                                : `${option.name}, ${option.countryName}`}
                        onChange={val => handleChangeDestiantion(val)}
                        options={searchListDestinations}
                        placeholder={placeholder}
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchDestinations(query)}
                        renderMenuItemChildren={(option) => (
                            option.stateName !== undefined && option.stateName !== "" ?
                                <div key={option.id} user={option} >{option.name}, {option.stateName}, {option.countryName}</div> :
                                <div key={option.id} user={option} >{option.name}, {option.countryName}</div>
                        )}
                        isLoading={false}
                        size={'large'}
                        delay={100}
                        clearButton={true}
                        style={isRoadTrip && lengthDestiantions === 1 && error ? {border:'1px solid red', borderRadius: 8}:null}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <AddressMapIcon width={15} height={15} /> 
                    </div>
                </div>
            </div>
            {((!isRoadTrip && lengthDestiantions !== 1) ||  (isRoadTrip && index === 0)  ) && <div className={ !(isRoadTrip && index === 0) ? "col-6 col-md-2 mt-3 p-0": "col-6 col-md-4 mt-3 p-0"}>
                <div className="position-relative" 
                      style={isRoadTrip && lengthDestiantions === 1   && error? {border:'1px solid red', borderRadius: 8}:null}>
                    <DatePicker
                        selected={startDate}
                        onChange={date => handleChangeStartDate(date)}
                        placeholderText="Start date (Option)"
                        dateFormat="yyyy/MM/dd"
                        minDate={destination.availableSelectDate}
                        className="form-control" 
                  />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>}
            {( lengthDestiantions !== 1 &&  !(isRoadTrip && index === 0)) && <div className="col-6 col-md-2 mt-3 p-0">
                <select className="form-control pl-3 duration" defaultValue={destination.duration} onChange={event => handleChangeDuration(event)}>
                    <option value="undefined" >Duration</option>
                    <option value="1" >1 day</option>
                    <option value="2" >2 days</option>
                    <option value="3" >3 days</option>
                    <option value="4" >4 days</option>
                    <option value="5" >5 days</option>
                    <option value="6" >6 days</option>
                    <option value="7" >7 days</option>
                    <option value="8" >8 days</option>
                    <option value="9" >9 days</option>
                    <option value="10" >10 days</option>
                    <option value="11" >11 days</option>
                    <option value="12" >12 days</option>
                    <option value="13" >13 days</option>
                    <option value="14" >14 days</option>
                    <option value="15" >15 days</option>
                    <option value="16" >16 days</option>
                    <option value="17" >17 days</option>
                    <option value="18" >18 days</option>
                    <option value="19" >19 days</option>
                    <option value="20" >20 days</option>
                    <option value="21" >21 days</option>
                    <option value="22" >22 days</option>
                    <option value="23" >23 days</option>
                    <option value="24" >24 days</option>
                    <option value="25" >25 days</option>
                    <option value="26" >26 days</option>
                    <option value="27" >27 days</option>
                    <option value="28" >28 days</option>
                    <option value="29" >29 days</option>
                    <option value="30" >30 days</option>
                    <option value="31" >31 days</option>
                    <option value="32" >32 days</option>
                    <option value="33" >33 days</option>
                    <option value="34" >34 days</option>
                    <option value="35" >35 days</option>
                    <option value="36" >36 days</option>
                    <option value="37" >37 days</option>
                    <option value="38" >38 days</option>
                    <option value="39" >39 days</option>
                    <option value="40" >40 days</option>
                    <option value="41" >41 days</option>
                    <option value="42" >42 days</option>
                    <option value="43" >43 days</option>
                    <option value="44" >44 days</option>
                    <option value="45" >45 days</option>
                    <option value="46" >46 days</option>
                    <option value="47" >47 days</option>
                    <option value="48" >48 days</option>
                    <option value="49" >49 days</option>
                    <option value="50" >50 days</option>
                    <option value="51" >51 days</option>
                    <option value="52" >52 days</option>
                    <option value="53" >53 days</option>
                    <option value="54" >54 days</option>
                    <option value="55" >55 days</option>
                    <option value="56" >56 days</option>
                    <option value="57" >57 days</option>
                    <option value="58" >58 days</option>
                    <option value="59" >59 days</option>
                    <option value="60" >60 days</option>
                </select>
            </div>}

        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
    searchListDestinations: state.Home.search_list_destinations,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switch_container: state.Member.switch_container,
    //tripMode: state.Member.tripMode,
})

const mapDispatchToProps = {
    getDestinationListTopBar: Actions.getDestinationListTopBar,
}

const PlanTripDestinaitonElement = connect(mapStateToProps, mapDispatchToProps)(PlanTripDestinaitonElement_)
export default PlanTripDestinaitonElement
