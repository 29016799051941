import {
    BedIcon,
    CameraIcon,
    ConciergeBellIcon,
    GlassMartiniIcon,
    ShoppingBagIcon,
    SkiingIcon,
    SpaIcon,
    TheaterMasksIcon,
} from "../Common/SVGBank"

// const lang = {
//     'en' : 'English',
//     'es' : 'Español',
//     'fr' : 'Français',
//     'ja' : '日本語',
//     'ru' : 'Pусский',
//     'zh' : '中文',
// };

/* menus filed list 
    key : name
    value : display 
*/
export const menus_fields_auth_link = [
    {
        name: "trips",
        display: "Trips"
    },
    {
        name: "bucket-list",
        display: "Bucket List"
    },
    {
        name: "favorites",
        display: "Favorites"
    },
    {
        name: "muv-tribe",
        display: "müvTribe"
    },
    {
        name: "personal-info",
        display: "Personal info"
    },
]
export const menus_fields_member_area_link = [
    {
        name: "trips",
        display: "Trips"
    },
    {
        name: "bucket-list",
        display: "Bucket List"
    },
    {
        name: "favorites",
        display: "Favorites"
    },
    {
        name: "muv-tribe",
        display: "müvTribe"
    },
]

/* landing page route */
export const time_format = {
    hours_12: "h:mm A",
    hours_24: "H:mm",
}

/* landing page route */
export const landing_router = {
    onboarding: "Onboarding",
    register: "Register",
    interests: "Interests",
    bio: "Bio",
    profile: "Profile",
    personalization: "Personalization",
    destinationFeature: "destination_feature",
    tastes:"tastes"
}
export const profile_router = {
    profile: "Profile",
    interests: "Interests",
    bio: "Bio",
    personalization: "Personalization",
}

/* destination page format */
export const list_container_mode = {
    itinerary: "Itinerary",
    schedule: "Schedule",
    list: "List", // Schedule seconde level list
    grid: "Grid", // Schedule seconde level grid
    calendar: "Calendar",
    map: "Map",
    timeline: "Timeline",
    mobileTimeline: "MobileTimeline",
}

export const list_memeber_action_mode = {
    addToTrip: "Add to trip",
    mergeToTrip: "Merge to trip",
    addToBucketList: "Add to Bucket List",
    addToFavorites: "Add to Favorites",
    removeFromList: "Remove from List",
}

export const member_area_router = {
    social: "social",
    destination: "destination",
    establishment: "establishment",
    publicTrip: "public_trip",
    trips: "trips",
    home: "home",
    feature: "feature",
    bucketList: "bucket-list",
    favorites: "favorites",
    muvTribe: "muv-tribe",
    memberAreaTrip: "member-area-trip",
    personalInfo: "personal-info",
    landing: "Landing",
    starred: "Starred",
    booking: "Booking",
    flightSearch: 'flight-search',
    hotelSearch: "hotel_search",
    hotelBooking: "hotel_booking",

}
export const types_router = {
    see: { typeId: "1", title: "See" },
    shop: { typeId: "2", title: "Shop" },
    eat: { typeId: "3", title: "Eat" },
    stay: { typeId: "4", title: "Stay" },
    drink: { typeId: "5", title: "Drink" },
    play: { typeId: "6", title: "Play" },
    relax: { typeId: "7", title: "Relax" },
    watch: { typeId: "8", title: "Watch" },
}
export const features_router = {
    places: { featureId: "1", text: "Places" },
    events: { featureId: "2", text: "Events" },
    trips: { featureId: "3", text: "Trips" },
    bucket_list: { featureId: "4", text: "Bucket List" },
    experiences: { featureId: "5", text: "Experiences" },
    services: { featureId: "6", text: "Services" },
}
export const feature_color = [
    { featureId: "1", text: "Places", color: "#19BC9B" },
    { featureId: "2", text: "Events", color: "#ED6A5F" },
    { featureId: "3", text: "Trips", color: "#65D4D5" },
    { featureId: "4", text: "Bucket List", color: "#FB884D" },
    { featureId: "5", text: "Experiences", color: "#0B91D8" },
]

/* landing page route */
export const image_cloud_aliases = {
    "muvimages_homepage_slider_images": {
        alias: "_home_slider_",
        url: "https://storage.googleapis.com/muvimages/muvimages"
    },
    "muvimages_establishments": {
        alias: "_muvestab_",
        alias_2: "_user_uploaded_",
        url: "https://storage.googleapis.com/muvimages/establishments"
    },
    "muvimages_destinations": {
        alias: "_muvdest_",
        url: "https://storage.googleapis.com/muvimages/destinations"
    },
    "muvimages_user_avatar": {
        alias: "_useravatar_",
        url: "https://storage.googleapis.com/muvimages/user_avatar"
    },
    "muvimages_user_uploaded": {
        alias: "_user_uploaded_",
        url: "https://storage.googleapis.com/muvimages/user_uploaded"
    },
    "muvimages_muv_assets": {
        alias: "_dev_",
        url: "https://storage.googleapis.com/muvimages/muv_assets"
    },
    "muvimages_": {
        alias: "_images_",
        url: "https://storage.googleapis.com/muvimages"
    },
    "muvimages_bucketlist": {
        alias: "_bucket_list_",
        url: "https://storage.googleapis.com/muvimages/bucketlist"
    },
    "muvimages_feature_images": {
        alias: "_featureimages_",
        url: "https://storage.googleapis.com/muvimages/feature_images"
    },
    "_": {
        alias: "_muv_",
        url: "https://storage.googleapis.com"
    },
}


export const image_boxing = (obj, imageW, imageH) => {
    let srcImage = ""
    if (typeof obj === "object" && obj !== undefined && obj !== "" && image_cloud_aliases[obj && obj.bucketName + "_" + obj.folderName] !== undefined) {
        srcImage = "https://amhqajbken.cloudimg.io/v7/" + image_cloud_aliases[obj && obj.bucketName + "_" + obj.folderName].alias + "/" + obj.imageName + "?func=crop&w=" + Math.floor(imageW) + "&h=" + Math.floor(imageH)
    } 
    else if (typeof obj === "object" && obj !== undefined && obj?.publicAccess === "1") {
        let folder =obj?.folderName;
         folder = folder?.replace("user_uploaded","_user_uploaded_")
        srcImage = "https://amhqajbken.cloudimg.io/v7/" + folder+ "/"+ obj.imageName + "?func=crop&w=" + Math.floor(imageW) + "&h=" + Math.floor(imageH)
    } 
    else {
        srcImage = "https://amhqajbken.cloudimg.io/v7/_dev_/commom/loading.gif?func=crop&w=" + Math.floor(imageW) + "&h=" + Math.floor(imageH)
    }
    return srcImage
}


export const priceline_image_boxing = (obj, imageW, imageH) => {
    let srcImage = ""
    if (typeof obj === "object" && obj !== undefined && obj !== "") {
        srcImage = obj?.imageName && !obj?.imageName?.includes("k=")? obj.imageName?.split('?')[0]: obj.imageName
    } else {
        srcImage = "https://amhqajbken.cloudimg.io/v7/_dev_/commom/loading.gif?func=crop&w=" + Math.floor(imageW) + "&h=" + Math.floor(imageH)
    }
   
    return srcImage
}

export const generateHQHotelImages = (obj) => {
    let srcImage = obj ? obj.imageName : null;
    if (srcImage) {
        const parts = srcImage.split('/');
        const id = parts && parts[5];
        const path = parts && parts[4];
       
        let newUrl = srcImage;
        if(id?.length === 10){
            const path1 = path.substring(0, 5)
            const path2 = path.substring(5, 6)
            const path3 = path.substring(6, 7)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/${path1}/${path2}/${path3}/${id}/master_${id}`
        }
        if(id?.length === 9){
            const path1 = path.substring(0, 4)
            const path2 = path.substring(4, 5)
            const path3 = path.substring(5, 6)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/${path1}/${path2}/${path3}/${id}/master_${id}`
        }
        if(id?.length === 8){
            const path1 = path.substring(0, 3)
            const path2 = path.substring(3, 4)
            const path3 = path.substring(4, 5)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/${path1}/${path2}/${path3}/${id}/master_${id}`
        }
        if(id?.length === 7){
            const path1 = path.substring(0, 2)
            const path2 = path.substring(2, 3)
            const path3 = path.substring(3, 4)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/${path1}/${path2}/${path3}/${id}/master_${id}`
        }
        if(id?.length === 6){
            const path1 = path.substring(0, 1)
            const path2 = path.substring(1, 2)
            const path3 = path.substring(2, 3)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/${path1}/${path2}/${path3}/${id}/master_${id}`
        }
        if(id?.length === 5){
            const path1 = path.substring(0, 1)
            const path2 = path.substring(1, 2)
            newUrl = `https://mobileimg.pclncdn.com/htlimg/master/0/${path1}/${path2}/${id}/master_${id}`
        }

        return newUrl;
    }

    return srcImage;
}


export const loadingImage = {
    bucketName: "muvimages",
    description: "Pictures",
    folderName: "muv_assets",
    imageName: "commom/loading.gif",
    src: "https://amhqajbken.cloudimg.io/v7/_dev_/commom/loading.gif"
}

export const defaultEstablishmentImage = {
    bucketName: "muvimages",
    description: "Pictures",
    folderName: "destinations",
    imageName: "656_New_York_City/New_York_City_176.jpg",
}

export const defaultBucketListImage = {
    description: "Pictures",
    bucketName: "muvimages",
    folderName: "homepage_slider_images",
    imageName: "Banff.jpg"
}


/* regular expression list */
let visa = /^4[0-9]{12}(?:[0-9]{3,4})?$/
let visa_local = /^4[19658][7684][0785][04278][128579](?:[0-9]{10})$/
let mastercard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
let mastercard_local = /^(?:5[13]99|55[35][19]|514[36])(?:11|4[10]|23|83|88)(?:[0-9]{10})$/
let verve = /^(?:50[067][180]|6500)(?:[0-9]{15})$/
let american_exp = /^3[47](?:[0-9]{13})$/
let diners_club = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
let maestro = /^(5899|5018|5020|5038|6304|6703|6708|6759|676[1-3])[06][19](?:[0-9]{10})$/
let discover = /^6(?:011|4[4-9]3|222|5[0-9]{2})[0-9]{12}$/
let laser = /^(6706|6771|6709)[0-9]{11,15}$/
let hipercard = /^(384100|384140|384160|606282|637095|637568|60(?!11))/
let jcb = /^(?:2131|1800|35\d{3})\d{11}$/

const ccValid = visa || visa_local ||mastercard ||mastercard_local||verve||american_exp||diners_club||maestro||discover
||laser||hipercard||jcb;



export const validate_fields = {
    username: /^[a-z\d]{5,12}$/i,
    phoneNumber:/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,64}))$/,
    handle: /^[a-z0-9]{5,20}$/,
    signature: /.{1,20}/,
    first_name: /^[a-zA-Z]{1,}$/,
    trip_name: /.{2,}/,
    last_name: /^[a-zA-Z]{1,}$/,
    password: /^((?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$)|(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
    disallow_comma: /^[^,]+$/,
    airport_query: /.{1,}/,
    hometown_query: /.{1,}/,
    destination_visited_query: /.{1,}/,
    destination_query: /.{1,}/,
    establishment_query: /.{1,}/,
    creditCard:ccValid,
    cvv:/^[0-9]{3,4}$/,
    search: /.{0,}/,
    general_search: /.{0,}/,
    destination_visited_search: /.{1,}/,
    trip_start_date: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    languages: /(^en$)|(^es$)|(^fr$)|(^ja$)|(^ru$)|(^zh$)/,
}

export const type_icon_components = {
    "BedIcon": BedIcon,
    "CameraIcon": CameraIcon,
    "ConciergeBellIcon": ConciergeBellIcon,
    "GlassMartiniIcon": GlassMartiniIcon,
    "ShoppingBagIcon": ShoppingBagIcon,
    "SkiingIcon": SkiingIcon,
    "SpaIcon": SpaIcon,
    "TheaterMasksIcon": TheaterMasksIcon,
}


/* 
    * Check the validate information 
    *
    * @param field : an value you want to check
    * @param regex : select a filed from validate_fields fomula
    * @return return ture is valid , flase is invalid
    *  
*/

export  function isValidPostalCode(postalCode, countryCode) {
    let postalCodeRegex;
    switch (countryCode) {
        case "US":
            postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
            break;
        case "CA":
            postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
            break;
        default:
            postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(postalCode);
}

export const validateCardNumber = number => {
    //Check if the number contains only numeric value  
    //and is of between 13 to 19 digits
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(number)){
        return false;
    }
  
    return luhnCheck(number);
}


export const luhnCheck = val => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }
  
    //Check if it is divisible by 10 or not.
    return (checksum % 10) == 0;
}

export const validate = (field, regex) => {
    return regex.test(field);
}

export const calcDistance = (lat1, lon1, lat2, lon2, unit) => {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;  // default is miles
        if (unit == "K") { dist = dist * 1.609344 }   // km
        if (unit == "N") { dist = dist * 0.8684 }     // nautical miles
        return dist;
    }
}


export const DELAY_FIVE_SECOND = 5000
export const DELAY_THERE_SECOND = 3000
export const DELAY_ONE_SECOND = 1000
export const DELAY_ZERO_SECOND = 100
export const DELAY_REAL_ZERO_SECOND = 10
export const DELAY_HALF_SECOND = 500
export const DELAY_QUARTER_SECOND = 250
export const PASSWORD_TEXT_FILED_TYPE_DISPLAY = "text"
export const PASSWORD_TEXT_FILED_TYPE_HIDDEN = "password"
export const MAX_ACTIVITY_IN_DAY = 23  //activities 
export const IMAGE_CLOUD_MAX = 12  //image cloud 



export const LAZYDISPLAY_INIT_LIMIT = 4          // render 4 results initially    
export const LAZYDISPLAY_INCREMENT = 10          // increment display of results by 10
export const LAZYDISPLAY_SHOW_ALL_AFTER = 100    // display all results after 100

export const PRICELINE_API_DEFAULT_SORT_BY = "sr"
export const PRICELINE_API_DEFAULT_SORT_TITLE = "Rating"
export const LAZYLOADING_HOTELS_INIT_LIMIT = 100          // API LIMIT RESULTS INITIALLY TO 4 
export const LAZYLOADING_ESTABLISHMENTS_LIMIT = 15
export const LAZYLOADING_HOTELS_LIMIT = 14
export const LAZYLOADING_HOTELS_INCREMENT = 10
export const LAZYLOADING_HOTELS_MAX = 100


// export const GOOGLE_API_KEY = "AIzaSyAISiIkhXEH3VWpqxrNOSQkp35TRhLgE64"  
// export const GOOGLE_API_KEY = "AIzaSyCKC5A3xikd9UBAaSzZlGSn-ong46ehYak"  
// export const GOOGLE_API_KEY = "AIzaSyDLHHjl7qzCPSAUbwQ6WbipmWjmYYqaw0E"  
export const GOOGLE_API_KEY = "AIzaSyD9h4FNtG7tLos_cSfhehgL1w8XSLWfDFY"
export const GET_ESTABLISHMENTS_NEAR_BY_MAP_CENTER = 30
export const GET_ESTABLISHMENTS_WITHIN = 30 // 30 MILES
export const DEFAULT_MAP_ZOOM = 12

export const URL_REPLACE = /\\|\/|\?|\@|\.|\s|-/g




/** PriceLine API Sandbox */
export const PRICELINE_SERVER = "https://api-sandbox.rezserver.com";
export const PRICELINE_REF_ID = "8660";
export const PRICELINE_API_KEY = "ce7272cb256e1c225bb8e55ddc64c0ff";






export const click_lazy_loading_feature_list = {
    // places 1
    // See_1
    "1": {
        defaultOffset: 8,
        limit: 8,
    },
    //Shop_2
    "2": {
        defaultOffset: 8,
        limit: 8,
    },
    //Eat_3
    "3": {
        defaultOffset: 8,
        limit: 8,
    },
    //Stay_4
    "4": {
        defaultOffset: 8,
        limit: 8,
    },
    //Drink_5
    "5": {
        defaultOffset: 8,
        limit: 8,
    },
    //Play_6
    "6": {
        defaultOffset: 8,
        limit: 8,
    },
    //Relax_7
    "7": {
        defaultOffset: 8,
        limit: 8,
    },
    //Watch_8
    "8": {
        defaultOffset: 8,
        limit: 8,
    },
    //trip 3
    // Adventure & Wildnerness_100
    "100": {
        defaultOffset: 8,
        limit: 8,
    },
    //City_101
    "101": {
        defaultOffset: 8,
        limit: 8,
    },
    //Day Trips & Excursions_102
    "102": {
        defaultOffset: 8,
        limit: 8,
    },
    //Festivals & Events_103
    "103": {
        defaultOffset: 8,
        limit: 8,
    },
    //Food & Wine 104
    "104": {
        defaultOffset: 8,
        limit: 8,
    },
    // Outdoors & Nature
    "105": {
        defaultOffset: 8,
        limit: 8,
    },
    // Road Trips
    "106": {
        defaultOffset: 8,
        limit: 8,
    },
    // Special Interest
    "107": {
        defaultOffset: 8,
        limit: 8,
    },
    // Sports
    "108": {
        defaultOffset: 8,
        limit: 8,
    },
    // Sun & Beach
    "109": {
        defaultOffset: 8,
        limit: 8,
    },
    // wellness
    "110": {
        defaultOffset: 8,
        limit: 8,
    },
    // art & culture
    "94": {
        defaultOffset: 8,
        limit: 8,
    },
    // Food & Drink
    "95": {
        defaultOffset: 8,
        limit: 8,
    },
    // Liftstyle & entertaiment
    "96": {
        defaultOffset: 8,
        limit: 8,
    },
    // nature
    "97": {
        defaultOffset: 8,
        limit: 8,
    },
    // sports
    "98": {
        defaultOffset: 8,
        limit: 8,
    },
    // Wellness
    "99": {
        defaultOffset: 8,
        limit: 8,
    },
    // actions
    "86": {
        defaultOffset: 8,
        limit: 8,
    },
    // Events
    "87": {
        defaultOffset: 8,
        limit: 8,
    },
    // Experiences
    "88": {
        defaultOffset: 8,
        limit: 8,
    },
    // Food & Drink
    "89": {
        defaultOffset: 8,
        limit: 8,
    },
    // Journeys
    "90": {
        defaultOffset: 8,
        limit: 8,
    },
    // Learning
    "91": {
        defaultOffset: 8,
        limit: 8,
    },
    // Places
    "92": {
        defaultOffset: 8,
        limit: 8,
    },
    // Stays
    "93": {
        defaultOffset: 8,
        limit: 8,
    }

}
export const check_box_trip_status = {
    private: "0",
    public: "1",
    disable: "2",
}
// url handle constant
