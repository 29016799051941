import {
  CLEAN_SELECTED_DESTINATIONS_FEATURE,
  CLEAN_SELECTED_SUBTYPES_FEATURE,
  FETCH_API_ERROR_FEATURE,
  FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  FETCH_FEATURE_SEARCH_LIST_FEATURE,
  FETCH_FEATURE_SUBTYPES_FEATURE,
  FETCH_FEATURE_TYPES_FEATURE,
  FETCH_RELATIVE_DESTINATIONS_FEATURE,
  FETCH_SORTING_OPTION_FEATURE,
  GetAsyncHelper,
  MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE,
  PostAsyncHelper,
  SELECTED_FEATURE_SEARCH_FEATURE,
  SELECTED_SORTING_OPTION_FEATURE,
  SET_FEATURE_FORMAT,
  SET_FEATURE_ID_FEATURE,
  SET_LIST_DISPLAY_FORMAT_FEATURE,
  SET_LOADER,
  SET_LOADING_FEATURE_LIST_OFFSET_FEATURE,
  SET_TOP_IMAGE_FEATURE,
  UPDATE_SELECTED_DESTINATIONS_FEATURE,
  UPDATE_SELECTED_SUBTYPES_FEATURE,
  UPDATE_SELECTED_TYPES_FEATURE,
} from "../interfaces/types";

import { click_lazy_loading_feature_list } from "../../Components/WebContent/Common/RegExValidate";
import { environment } from "../../Environments/environment";

export const setFeatureId = (id) => (dispatch) => {
  dispatch({
    type: SET_FEATURE_ID_FEATURE,
    active_id: String(id),
  });
};

export const fetchSortingOptionsByFeature = (id, lang) => async (dispatch) => {
  const sortingOptionsRequests = {
    featureId: id,
  };
  PostAsyncHelper(
    environment.apiUrl +"featurelist/getFeatureSortingOptions/" +
      lang,
    sortingOptionsRequests
  ).then((responseSortingOptions) => {
    if (
      responseSortingOptions !== undefined &&
      responseSortingOptions.statusText !== "OK"
    ) {
      throw Error(responseSortingOptions.statusText);
    }
    dispatch({
      type: FETCH_SORTING_OPTION_FEATURE,
      sorting_options: responseSortingOptions.data.results,
    });
  });
};

export const fetchDestinationFilterByFeature =
  (id, lang) => async (dispatch) => {
    GetAsyncHelper(
      environment.apiUrl +"featurelist/getFeatureRelatedDestinations/" +
        lang +
        "?featureId=" +
        id +
        "&offset=0"
    ).then((responseRelativeDestinations) => {
      if (
        responseRelativeDestinations !== undefined &&
        responseRelativeDestinations.statusText !== "OK"
      ) {
        throw Error(responseRelativeDestinations.statusText);
      }
      dispatch({
        type: FETCH_RELATIVE_DESTINATIONS_FEATURE,
        destinations: responseRelativeDestinations.data.result,
      });
    });
  };

export const fetchTypesAndSubtypesFeature =
  (id, lang, setDefaultType = null, isDestination=null) =>
  async (dispatch, getState) => {
    let url =
    environment.apiUrl +"featurelist/getfeaturetypes/" +
      lang +
      "?";
    // if(destinationID){
    //     url = 'https://devapi.muvtravel.com/api/public/featurelist/getfeaturetypes/' + lang + "?featureID=" + id
    //     + "&destinationId="+destinationID;
    // }
    const selectedDestinations = getState().Feature.feature
      ? getState().Feature.feature.loading.selectedDestinations
      : [];
    let obj_feature_list = {
      featureID: id,
      destinationIds: selectedDestinations,
    };
    PostAsyncHelper(url, obj_feature_list).then((responseTypes) => {
      if (responseTypes !== undefined && responseTypes.statusText !== "OK") {
        // throw Error(responseTypes.statusText);
      }
      if (responseTypes && responseTypes?.data?.types) {
        const types = responseTypes.data.types;
        const typesCopy = [];
        types.map((t) => {
          typesCopy.push({
            typeID: t.typeID,
          });
        });
        dispatch({
          type: UPDATE_SELECTED_TYPES_FEATURE,
          types: typesCopy,
        });
        types.map((t) =>
          Object.assign(t, {
            isChecked: false,
            clickLazyLoading: {
              limit: click_lazy_loading_feature_list[t.typeID].limit,
              offset: click_lazy_loading_feature_list[t.typeID].defaultOffset,
            },
          })
        );
        dispatch({
          type: FETCH_FEATURE_TYPES_FEATURE,
          types: types,
        });

        if (setDefaultType) {
          setDefaultType(types); //feature page set type
        }

        const destinations = selectedDestinations
          ? selectedDestinations.map((a) => a.id)
          : [];
        let obj_subtype = {
          featureID: id,
          multipleType: typesCopy,
          destinationIds: destinations,
        };
        let api_url_subtype =
        environment.apiUrl +"featurelist/getfeaturesubtypes/" +
          lang +
          "?getfeaturesubtypes";
        /*  get feature's type's subtypes */
        PostAsyncHelper(api_url_subtype, obj_subtype).then(
          (responseSubtypes) => {
            if (responseSubtypes.statusText === "OK") {
              const subtypesResponse = responseSubtypes.data;
              // let subtypesResult = []
              // // if(subtypesResponse){
              // //     subtypesResponse.map(parent_subtype => {
              // //         subtypesResult = subtypesResult.concat([...parent_subtype.subtypes])
              // //     })
              // // }
              dispatch({
                type: FETCH_FEATURE_SUBTYPES_FEATURE,
                subtypes: subtypesResponse,
              });
            } else {
              dispatch({
                type: FETCH_API_ERROR_FEATURE,
                api_error: true,
              });
            }
          }
        );
      }

      GetAsyncHelper(
        environment.apiUrl +"featurelist/getFeatureInfoByFeatureId/" +
          lang +
          "?featureId=" +
          id
      ).then((responseFeature) => {
        if (
          responseFeature !== undefined &&
          responseFeature.statusText !== "OK"
        ) {
          // throw Error(responseFeature?.statusText);
        }
        let featureObject = responseFeature?.data?.result;
        dispatch({
          type: SET_FEATURE_FORMAT,
          featureSelected: featureObject,
        });
        dispatch({
          type: SET_TOP_IMAGE_FEATURE,
          top_image: featureObject.image,
        });

        let selected = [] 
        selected.push({code: "Ranking",title: "Ranking"}) //default ranking for places
        dispatch({
          type: SELECTED_SORTING_OPTION_FEATURE,
          selected_sorting_option: isDestination && id === '1'? selected: featureObject.sorting,
        });
        dispatch({
          type: SET_LOADER,
          payload: false,
        });
      });
    });
  };

export const setSwitchFeatureListContainer =
  (container_format) => (dispatch) => {
    dispatch({
      type: SET_LIST_DISPLAY_FORMAT_FEATURE,
      container_format: container_format,
    });
  };

export const setSwitchFeatureTypeContainerOfFeature =
  (activeFeatureId, lang, types, type = null) =>
  async (dispatch) => {
    const typesCopy = [];
    let typesCopyChecked = [];
    if (type === null) {
      // all types
      types.map((t) => {
        typesCopy.push({
          typeID: t.typeID,
          typeName: t.typeName,
          icon: t.icon,
          offset: t.offset,
          isChecked: t.typeID === false,
          clickLazyLoading: t.clickLazyLoading,
        });
      });
      typesCopyChecked = [...typesCopy];
  
    } else {
      types.map((t) => {
        typesCopy.push({
          typeID: t.typeID,
          typeName: t.typeName,
          icon: t.icon,
          offset: t.offset,
          isChecked:
            t.typeID === type.typeID || t.typeID === type.id
              ? !t.isChecked
              : t.isChecked,
          clickLazyLoading: t.clickLazyLoading,
        });
      });
      typesCopyChecked = typesCopy.filter((t) => t.isChecked);
      // typesCopyChecked = typesCopyChecked.length === 0 ? [...typesCopy] : typesCopyChecked;
    }
    const typeCopyShort = [];
    typesCopyChecked.map((t) => {
     if(t.isChecked){
      typeCopyShort.push({
        typeID: t.typeID,
      });
     }
    });

    dispatch({
      type: UPDATE_SELECTED_TYPES_FEATURE,
      types: typeCopyShort,
    });
    dispatch({
      type: FETCH_FEATURE_TYPES_FEATURE,
      types: typesCopy,
    });
    dispatch({
      type: UPDATE_SELECTED_SUBTYPES_FEATURE,
      subtypes: [],
    });
  };

export const addSelectedSubTypeFeature =
  (subtype, selectedSubtypes) => async (dispatch) => {
    let subtypes = selectedSubtypes.slice();
    subtypes.push(subtype);
    dispatch({
      type: UPDATE_SELECTED_SUBTYPES_FEATURE,
      subtypes: subtypes,
    });
  };

export const deleteSelectedSubTypeFeature =
  (subtype, selectedSubtypes) => async (dispatch) => {
    let subtypes = selectedSubtypes.filter((val) => val.id !== subtype.id);

    dispatch({
      type: UPDATE_SELECTED_SUBTYPES_FEATURE,
      subtypes: subtypes,
    });
  };

export const addSelectedDestinationsFeature =
  (id, selectedDestinations) => async (dispatch) => {
    const destinationFormat = {
      id: id,
    };
    let destinationsMerge = selectedDestinations.slice();
    destinationsMerge.push(destinationFormat);
    dispatch({
      type: UPDATE_SELECTED_DESTINATIONS_FEATURE,
      destinations: destinationsMerge,
    });
  };

export const deleteSelectedDestinationsFeature =
  (destination, selectedDestinations) => async (dispatch) => {
    let destinationsFilter = selectedDestinations.filter(
      (val) => val.id !== destination.destinationId
    );
    dispatch({
      type: UPDATE_SELECTED_DESTINATIONS_FEATURE,
      destinations: destinationsFilter,
    });
  };

export const cleanSelectedDestinationsFeature = () => async (dispatch) => {
  dispatch({
    type: CLEAN_SELECTED_DESTINATIONS_FEATURE,
  });
};

export const handleGoupeSubTypeFeature =
  (subtypesGroup, selectedSubtypes) => async (dispatch) => {
    const intersections = selectedSubtypes.filter(
      (subtype) => subtypesGroup.indexOf(subtype) !== -1
    );
    const difference = selectedSubtypes.filter(
      (subtype) => subtypesGroup.indexOf(subtype) === -1
    );

    let subtypes =
      intersections.length === 0
        ? selectedSubtypes.concat(subtypesGroup)
        : difference;
    dispatch({
      type: UPDATE_SELECTED_SUBTYPES_FEATURE,
      subtypes: subtypes,
    });
  };

export const cleanSelectedSubTypeFeature = () => async (dispatch) => {
  dispatch({
    type: CLEAN_SELECTED_SUBTYPES_FEATURE,
  });
};

export const setSortingOptionsFeature =
  (selectedOption) => async (dispatch) => {
    dispatch({
      type: SELECTED_SORTING_OPTION_FEATURE,
      selected_sorting_option: selectedOption,
    });
  };

export const setSearchQueryFeature =
  (search, activeFeatureId, lang, destinations) => async (dispatch) => {
    if (search !== undefined && search !== null && search !== "") {
      let obj_feature_search = {
        featureId: activeFeatureId,
      };
      if(destinations){
        obj_feature_search['destinations'] = destinations;
      }
      let api_url_feature_search =
      environment.apiUrl +"muvsearch/featureSearch/" +
        search +
        "/" +
        lang;
      PostAsyncHelper(api_url_feature_search, obj_feature_search).then(
        (responseFeatureSearchList) => {
          if (
            responseFeatureSearchList !== undefined &&
            responseFeatureSearchList.statusText === "OK"
          ) {
            dispatch({
              type: FETCH_FEATURE_SEARCH_LIST_FEATURE,
              feature_search_list:
                responseFeatureSearchList.data.results.searchResults,
            });
          } else {
            dispatch({
              type: FETCH_API_ERROR_FEATURE,
              api_error: true,
            });
          }
        }
      );
    } else {
      dispatch({
        type: SELECTED_FEATURE_SEARCH_FEATURE,
        search_query: search,
      });
      dispatch({
        type: FETCH_FEATURE_SEARCH_LIST_FEATURE,
        feature_search_list: [],
      });
    }
  };

export const cleanSearchQueryFeature =
  (search, activeFeatureId, lang) => async (dispatch) => {
    dispatch({
      type: FETCH_FEATURE_SEARCH_LIST_FEATURE,
      feature_search_list: [],
    });
  };

export const handleSelectedAutocompleteFeature =
  (search_query) => async (dispatch) => {
    dispatch({
      type: SELECTED_FEATURE_SEARCH_FEATURE,
      search_query: search_query,
    });

    dispatch({
      type: FETCH_FEATURE_SEARCH_LIST_FEATURE,
      feature_search_list: [],
    });
  };

export const loadingFeatureListFeature =
  (activeFeatureId, loading, lang) => async (dispatch) => {
    let obj_feature_list = {
      featureID: activeFeatureId,
      typeIds: loading.selectedTypes,
      sortingOrder: loading.selectedSortingOption,
      offset: 0,
      limit: 8,
      sortingDirection: "",
      subtype: loading.selectedSubtypes,
      searchquery: loading.searchQuery,
      destinations: loading.selectedDestinations,
    };
    let api_url_feature_list =
    environment.apiUrl +"featurelist/main/" +
      lang +
      "?featurelist";
    /*  get feature's type's subtypes */
    PostAsyncHelper(api_url_feature_list, obj_feature_list).then(
      (responseFeatureList) => {
        if (
          responseFeatureList !== undefined &&
          responseFeatureList.statusText === "OK"
        ) {
          dispatch({
            type: FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE,
            feature_list: responseFeatureList.data.result,
          });
        } else {
          dispatch({
            type: FETCH_API_ERROR_FEATURE,
            api_error: true,
          });
        }
      }
    );

    const destinations = loading.selectedDestinations
      ? loading.selectedDestinations.map((a) => a.id)
      : [];
    let obj_subtype = {
      featureID: activeFeatureId,
      multipleType: loading.selectedTypes,
      destinationIds: destinations,
    };
    let api_url_subtype =
    environment.apiUrl +"featurelist/getfeaturesubtypes/" +
      lang +
      "?getfeaturesubtypes";
    /*  get feature's type's subtypes */
    PostAsyncHelper(api_url_subtype, obj_subtype).then((responseSubtypes) => {
      if (responseSubtypes.statusText === "OK") {
        const subtypesResponse = responseSubtypes.data;
        dispatch({
          type: FETCH_FEATURE_SUBTYPES_FEATURE,
          subtypes: subtypesResponse,
        });
      } else {
        dispatch({
          type: FETCH_API_ERROR_FEATURE,
          api_error: true,
        });
      }
    });
  };

export const loadMoreAllFeatureList =
  (activeFeatureId, loading, lang, offset) => async (dispatch) => {
    const limit = 8;
    const defaultOffset = offset === 0 ? 8 : offset;
    let obj_feature_list = {
      featureID: activeFeatureId,
      typeIds: loading.selectedTypes,
      sortingOrder: loading.selectedSortingOption,
      offset: defaultOffset,
      limit: limit,
      sortingDirection: "",
      subtype: loading.selectedSubtypes,
      searchquery: loading.searchQuery,
      destinations: loading.selectedDestinations,
    };
    let api_url_feature_list =
    environment.apiUrl +"featurelist/main/" +
      lang +
      "?featurelist";
    /*  get feature's type's subtypes */
    PostAsyncHelper(api_url_feature_list, obj_feature_list).then(
      (responseFeatureList) => {
        if (
          responseFeatureList !== undefined &&
          responseFeatureList.statusText === "OK"
        ) {
          dispatch({
            type: MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE,
            data: {
              feature_list: responseFeatureList.data.result,
              offset: limit + Number(defaultOffset),
            },
          });
        } else {
          dispatch({
            type: FETCH_API_ERROR_FEATURE,
            api_error: true,
          });
        }
      }
    );
  };

export const setSearchFeatureListFeature =
  (query, activeFeatureId, loading, lang) => async (dispatch) => {
    let obj_feature_list = {
      featureID: activeFeatureId,
      typeIds: loading.selectedTypes,
      sortingOrder: loading.selectedSortingOption,
      offset: 0,
      limit: 8,
      sortingDirection: "",
      subtype: loading.selectedSubtypes,
      searchquery: query,
      destinations: loading.selectedDestinations,
    };

    let api_url_feature_list =
    environment.apiUrl +"featurelist/main/" +
      lang +
      "?featurelist";
    /*  get feature's type's subtypes */
    PostAsyncHelper(api_url_feature_list, obj_feature_list).then(
      (responseFeatureList) => {
        if (
          responseFeatureList !== undefined &&
          responseFeatureList.statusText === "OK"
        ) {
          dispatch({
            type: FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE,
            feature_list: responseFeatureList.data.result,
          });
        } else {
          dispatch({
            type: FETCH_API_ERROR_FEATURE,
            api_error: true,
          });
        }
      }
    );
  };

export const loadingFeatureListMoreFeature =
  (activeFeatureId, loading, lang, typeClicked) => async (dispatch) => {
    const typeId = [
      {
        typeID: typeClicked.typeID,
      },
    ];
    if (typeClicked !== undefined) {
      let obj_feature_list = {
        featureID: activeFeatureId,
        typeIds: typeId,
        sortingOrder: loading.selectedSortingOption,
        offset: typeClicked.clickLazyLoading.offset,
        limit: typeClicked.clickLazyLoading.limit,
        sortingDirection: "",
        subtype: loading.selectedSubtypes,
        searchquery: loading.searchQuery,
        destinations: loading.selectedDestinations,
      };
      let api_url_feature_list =
      environment.apiUrl +"featurelist/main/" +
        lang +
        "?featurelist";
      /*  get feature's type's subtypes */
      PostAsyncHelper(api_url_feature_list, obj_feature_list).then(
        (responseFeatureList) => {
          if (
            responseFeatureList !== undefined &&
            responseFeatureList.statusText === "OK"
          ) {
            if (responseFeatureList.data.result[0] !== undefined) {
              const establishments =
                responseFeatureList.data.result[0].features;
              dispatch({
                type: MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE,
                type_id: typeClicked.typeID,
                establishments: establishments,
              });
            }
            dispatch({
              type: SET_LOADING_FEATURE_LIST_OFFSET_FEATURE,
              type_id: typeClicked.typeID,
              offset:
                Number(typeClicked.clickLazyLoading.offset) +
                Number(typeClicked.clickLazyLoading.limit),
            });
          } else {
            dispatch({
              type: FETCH_API_ERROR_FEATURE,
              api_error: true,
            });
          }
        }
      );
    }
  };

export const loadingFeatureListLessFeature =
  (activeFeatureId, loading, lang, typeClicked) => async (dispatch) => {
    const typeId = [
      {
        typeID: typeClicked.typeID,
      },
    ];
    if (typeClicked !== undefined) {
      let obj_feature_list = {
        featureID: activeFeatureId,
        typeIds: typeId,
        sortingOrder: loading.selectedSortingOption,
        offset: 0,
        limit: 8,
        sortingDirection: "",
        subtype: loading.selectedSubtypes,
        searchquery: loading.searchQuery,
        destinations: loading.selectedDestinations,
      };
      let api_url_feature_list =
      environment.apiUrl +"featurelist/main/" +
        lang +
        "?featurelist";
      /*  get feature's type's subtypes */
      PostAsyncHelper(api_url_feature_list, obj_feature_list).then(
        (responseFeatureList) => {
          if (
            responseFeatureList !== undefined &&
            responseFeatureList.statusText === "OK"
          ) {
            if (responseFeatureList.data.result[0] !== undefined) {
              const establishments =
                responseFeatureList.data.result[0].features;
              dispatch({
                type: CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE,
                type_id: typeClicked.typeID,
                establishments: establishments,
              });
            }
            dispatch({
              type: SET_LOADING_FEATURE_LIST_OFFSET_FEATURE,
              type_id: typeClicked.typeID,
              offset: 0,
            });
          } else {
            dispatch({
              type: FETCH_API_ERROR_FEATURE,
              api_error: true,
            });
          }
        }
      );
    }
  };
