import moment from 'moment';
import React, { useState, useEffect, memo } from 'react';
import Typewriter from './TypeWriter';
import AIWriter from "react-aiwriter";
import Typist from 'react-typist';
import defaultAvatar from '../../../../assets/images/defaultAvatar.png';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveHotelTimeline, savePlaceTimeline } from '../../BookingPage/PaymentPage/payment.thunk';
import Loader from 'react-loader-spinner'
import { AIModalUseStyles } from '../BookingForm/AiPersonalizedTrip/AiPersonalizedTripStyles';
import { Backdrop } from '@material-ui/core'
import { saveFlightTimeline } from '../../FlightsPage/services/Flights.thunks';
import { AiTooltip } from '../MemberActions/tooltipActions';
import { useHistory  } from 'react-router-dom';


const AiResponses = ({ createTrip, creating, tripDestination, scrollToBottom, chatResponses, searching, showResults, is_mobile, listRef }) => {
  const [typing, setTyping] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const uuid = useSelector(state => state.Member.authModal.uuid)
  const lang = useSelector(state => state.Setting.lang)
  const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
  const activeTripId = activeTrip?.tripId;
  const history = useHistory()

  const classes = AIModalUseStyles();

  const dispatch = useDispatch()

  const navigateUrl= (url)=>{
    history.push(url)
  }

  const savePlace = React.useCallback(async (establishmentId) => {
    setSaving(true)
    const saveData = {
      postData: {
        tripId: activeTripId,
        uuid: uuid,
        tripDestinationId: tripDestination?.muv_destination?.id,
        dayInDestination: 1,
        placeId: establishmentId,
      },
      lang: lang,
      uuid: uuid || null
    }
    await dispatch(savePlaceTimeline(saveData));
    setSaving(false)

  }, [uuid])

  const saveHotel = React.useCallback(async (hotelId, checkIn, checkOut) => {
    setSaving(true)
    const saveData = {
      postData: {
        tripId: activeTripId,
        pricelineHotelId: hotelId || "",
        booking_trip_number: "",
        booking_status: "",
        booking_status_code: "",
        checkIn: checkIn || "",
        checkOut: checkOut || ""
      },
      uuid: uuid || null
    }
    await dispatch(saveHotelTimeline(saveData));
    setSaving(false)

  }, [uuid])

  const saveFlight = React.useCallback(async (numberAdult, displaySymbol, displayCurrency,
    airports, displayTotalFare) => {
    setSaving(true)
    const saveData = {
      postData: {
        tripId: activeTripId,
        booking_trip_number: "",
        booking_status: "",
        booking_status_code: "",
        airports: airports || [],
        displaySymbol: displaySymbol,
        displayCurrency: displayCurrency,
        displayTotalFare: displayTotalFare,
        adults: numberAdult,
      },
      uuid: uuid || null
    }
    await dispatch(saveFlightTimeline(saveData));
    setSaving(false)

  }, [uuid])

  const MessageBox = (message) => {

    const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
    const messageText = message?.text;
    const places = message?.places;
    const hotels = message?.hotels;
    const flights = message?.flights;
    const data = message?.data
    const fullJson = message?.fullJson;
    const url = message?.url ? message?.url.split('.com').pop():null;

    let responseComponent = <></>;

    if (messageText) {
      responseComponent = messageText
    }

    if (places) { ///places type
      responseComponent = (<div>
        <div> {url ? <a onClick={()=>{navigateUrl(url)}} style={{ cursor: "pointer",  font: "16px/22px Futura Md BT", textDecoration: "underline", color: "var(--mainGrey)", }} rel="noopener noreferrer"  alt="">{message?.name}:</a> : `${message?.name}:`} {url ? <span onClick={() => {
          savePlace(fullJson?.id);
        }} style={{}}>
          <AiTooltip title="Add to trip" placement="top-start">
            <FontAwesomeIcon size="sm" icon={faCirclePlus} style={{ cursor: "pointer", color: 'var(--mainGreen)', position: "absolute", marginTop: -12, marginLeft: -15 }} />
          </AiTooltip>
        </span> : ""} {message?.description || ""}
        </div>
      </div>
      )
    }


    if (hotels) { ///hotels type
      const hotelId = message?.fullJson?.hotelid_ppn;
      const checkIn = message?.fullJson?.check_in;
      const checkOut = message?.fullJson?.check_out;

      const url = message?.url ? message?.url.split('.com').pop():null;

      responseComponent = (<div style={{
        font: "16px/22px Futura Lt BT",
      }}>
        <span style={{
          font: "16px/22px Futura Md BT",
        }}>- {message?.name} ({message?.star_rating ?? ""} stars) </span>  {url ? <span onClick={() => {
          saveHotel(hotelId, checkIn, checkOut);
        }} style={{}}>
          <AiTooltip title="Add to trip" placement="top-start">
            <FontAwesomeIcon size="sm" icon={faCirclePlus} style={{ cursor: "pointer", color: 'var(--mainGreen)', position: "absolute", marginTop: -12, marginLeft: -15 }} />
          </AiTooltip>
        </span> : ""} {url ? <a  onClick={()=>{navigateUrl(url)}} style={{  cursor: "pointer", font: "16px/22px Futura Md BT", textDecoration: "underline", color: "var(--mainGrey)", }} rel="noopener noreferrer" alt=""> {message?.price ? <span>{` `}{message?.price}</span> : ""}</a> : `$$`} {`${message?.guest_rating ? " - guest rating: " + message?.guest_rating : ""}`}

        <div>{message?.description}</div>
      </div>
      )
    }



    if (flights) { ///flights type

      const flightPrice = data?.flightCost && data?.flightCost?.cost ? `${data?.flightCost?.currency_symbol}${data?.flightCost?.cost}` : null
      const flightText = `${data?.origin_city}-${data?.destination_city} on ${moment(data?.flight_date).format('MMM Do')}`
      const flightDate = data?.flight_date ? moment(data?.flight_date).format('MMM Do') : "";
      const url = data?.url ? data?.url.split('.com').pop():null;
      const airline = data?.flightCost?.flight_full_data?.slice_data[0]?.airline?.name;

      const airports = data?.flightCost?.flight_full_data?.slice_data
        ? data?.flightCost?.flight_full_data?.slice_data.map((data) => {
          return {
            airline_logo: data.airline.logo || "",
            departure_airport_code: data.departure.airport.code || "",
            arrival_airport_code: data.arrival.airport.code || "",
            departure_date: data.departure.datetime.date || "",
            arrival_date: data.arrival.datetime.date || "",
            departure_time: data.departure.datetime.time_24h || "",
            arrival_time: data.arrival.datetime.time_24h || "",
            flight_duration: data.info.duration || ""
          }
        }) : [];

      responseComponent = (<div style={{
        font: "16px/22px Futura Lt BT",
        // display:"flex",
        marginTop: 5, marginBottom: 10,
      }}>

        <div style={{
          font: "16px/22px Futura Lt BT",
          display: "block",
          color: "var(--mainGrey)",
        }}>
          The cheapest flight you can get for these dates is with {url && <a onClick={()=>{navigateUrl(url)}} style={{ cursor: "pointer",  font: "16px/22px Futura Md BT", textDecoration: "underline", color: "var(--mainGrey)", }} rel="noopener noreferrer"  alt=""> {airline ?? ""} at {flightPrice}</a>}   {url && <span onClick={() => {
            saveFlight(data?.number_of_passengers,
              data?.flightCost?.currency_symbol,
              data?.flightCost?.currency, airports, data?.flightCost?.cost);
          }} style={{}}>
            <AiTooltip title="Add to trip" placement="top-start">
              <FontAwesomeIcon size="sm" icon={faCirclePlus} style={{ cursor: "pointer", color: 'var(--mainGreen)', position: "absolute", marginTop: -12, marginLeft: -15 }} />
            </AiTooltip>

          </span>} leaving {data?.origin_city} on {flightDate} arriving in {data?.destination_city}

        </div>
      </div>
      )
    }
    return responseComponent;
  }


  const ResponseWrapper = ({ children, message }) => {
    return (<div style={{ display: "flex", font: "16px/22px Futura Md BT", textAlign: "left", marginBottom: "10px", color: "var(--mainGrey)", }}>
      {message?.bot ? <img src={'https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'}
        style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="muvBot" /> : ""}
      {message?.isUser ? <img src={message?.sender || defaultAvatar}
        style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="sender" /> : ""}
      <div style={{
        font: message?.isUser ? "16px/22px Futura Md BT" : "16px/22px Futura Lt BT",
        whiteSpace: "pre-line",
        marginTop: 5,
        paddingLeft: 0
      }}>

        {children}
      </div>
    </div>)
  }
  const [currentTextCounter, setCurrentTextCounter] = useState(0);


  const lineTyped = () => {
    setTyping(true)
    if (scrollToBottom) {
      scrollToBottom()

    }
  }

  const onTypingDone = () => {
    setTyping(false)
  }


  React.useEffect(() => {

    if (chatResponses?.length > 0) {
      setCurrentTextCounter(chatResponses?.length)
    }

  }, [chatResponses])





  return (
    <div>



      {showResults && <div style={{
        background: "white",
        borderRadius: 20,
        width: is_mobile ? "90%" : "70%",
        marginLeft: is_mobile ? "5%" : "16%",
        bottom: 60,
        position: "absolute",
        padding: 40,
        paddingLeft: is_mobile ? 20 : 50,
        transition: "all .6s ease-in",
        border: '1px solid lightgrey',
        font: "18px/20px Futura Lt BT",
        maxHeight: is_mobile ? 400 : 600,
        overflowY: "auto",
      }}>




        {chatResponses && chatResponses?.length > 0 && <div>
          {chatResponses.map((responseArray, index) => {

            if ((chatResponses?.length === index + 1)) { //new messages
              ///user messages no animation

              const NewResponses =
                responseArray?.map((message, txtIndex) => {

                  if (!message?.isUser) {
                    return (<div key={JSON.stringify(message) + txtIndex}>
                      <ResponseWrapper
                        children={MessageBox(message)} message={message} />

                    </div>)
                  }
                  return ""

                })



              return (
                <React.Fragment>

                  {/* render user messages without animation */}
                  <div>
                    {
                      responseArray?.map((message, txtIndex) => {
                        if (message?.isUser) {
                          return <div key={message + txtIndex}>
                            <ResponseWrapper
                              children={MessageBox(message)} message={message} />
                          </div>

                        }

                      })
                    }
                  </div>


                  {/* render other messages with animation */}
                  <Typist key={currentTextCounter}
                    cursor={{
                      show: false
                    }} avgTypingDelay={20}
                    onLineTyped={lineTyped}
                    onTypingDone={onTypingDone}>

                    {NewResponses}

                  </Typist>
                </React.Fragment>
              )
            }

            ///older messages
            return responseArray?.map((message, txtIndex) => {
              return <div key={message + txtIndex}>
                <ResponseWrapper
                  children={MessageBox(message)} message={message} />
              </div>
            })


          })}

          {searching ?
            <div style={{
              font: "16px/22px Futura Md BT",
              color: "var(--mainGrey)",
              margin: "10px 0px",
              display: "flex"
            }}>
              <img src={'https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'}
                style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="muvBot" />
              <span style={{ paddingTop: 5, font: "16px/22px Futura Md BT", }} className="loadingAnimate"> Looking for best results</span>
            </div>
            : ""}


        </div>}

        {/* end of list */}
        <div ref={listRef} style={{ height: 30 }} />

        {tripDestination && !typing && <div style={{
          position: "sticky",
          bottom: 0,
          textAlign: "right",
        }}>
          <button className='recapButtons' style={{
            height: 40,
            borderRadius: 10,
            background: '#E52256', color: 'white', font: '14px/14px Futura Lt BT',
          }} onClick={createTrip}>
            {creating ? <CircularProgress
              size={20}
              style={{
                color: "white"
              }} /> : "Generate itinerary from results"}
          </button>
        </div>}

      </div>}



      <Backdrop className={classes.backdrop} open={saving} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div >
  )
};


export default AiResponses;