import * as Actions from '../../../../redux/actions'

import {
    DELAY_ZERO_SECOND,
    validate,
    validate_fields,
} from "../RegExValidate"
import { SearchIcon, TimesIcon } from "../SVGBank"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { GeneralSearchBarAttachment } from './GeneralSearchBarAttachment'
import Grow from '@material-ui/core/Grow'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from "react-redux"
import { makeStyles } from '@material-ui/core/styles'
import { TrackEvent } from '../../../../utility/utility'

const searchStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "20px",
        position: 'relative',
        marginLeft: 0,

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        height: '36px',
        width: "100%",
    },
    searchSquare: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "0px",
        position: 'relative',
        marginLeft: 0,

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        height: '36px',
        width: "100%",
    },
    inputRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Md BT",
    },
    inputInput: {
        padding: theme.spacing(1, 0, 0.5, 2),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '420px',
        },
        [theme.breakpoints.only('md')]: {
            width: '250px',
        },
        [theme.breakpoints.only('lg')]: {
            width: '230px',
        },
    },
}));

const searchStyleMobile = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "20px",
        position: 'relative',
        marginLeft: 0,

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        height: '36px',
        width: "100%",
    },
    searchSquare: {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #B1B1B1",
        borderRadius: "0px",
        position: 'relative',
        marginLeft: 0,

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        height: '36px',
        width: "100%",
    },
    inputRoot: {
        color: 'var(--bgBlack)',
        font: "16px/16px Futura Md BT",
        width: "100%",
    },
    inputInput: {
        padding: theme.spacing(1, 0, 0.5, 2),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '420px',
        },
        [theme.breakpoints.only('md')]: {
            width: '250px',
        },
        [theme.breakpoints.only('lg')]: {
            width: '230px',
        },
    },
}));
export const GeneralSearchBar = connect((state) => ({
    generalSearch: state.FormBank.generalSearch,
    textColor: state.Setting.appMenu.textColor,
    is_mobile: state.Setting.is_mobile,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,
}), {
    fetchGeneralSearch: Actions.fetchGeneralSearch,
    clearGeneralSearch: Actions.clearGeneralSearch,
    setGeneralSearchSearcherId: Actions.setGeneralSearchSearcherId,
},
)(({
    is_mobile,
    textColor,
    generalSearch,
    clearGeneralSearch,
    useTransparent,
    fetchGeneralSearch,
    setGeneralSearchSearcherId,
    bodyClientWidth,
    whiteBackground=false,
    destinationOnly=false
}) => {
    const classes = searchStyles();
    const classesMobile = searchStyleMobile();

    const [query, setQuery] = React.useState(generalSearch.query)

    const delay = (() => {
        let timer = 0;
        return (callback, ms) => {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();
    const updateState = (search, error) => {
        if (!error) {
            search !== undefined && search !== "" ? fetchGeneralSearch(search) : clearGeneralSearch()
            if(search){
                TrackEvent('SEARCH',"Top search",search)
            }
            console.log(search)
            setQuery(search)
        }
    }
    const handleChangeHandleTextField = event => {
        let search = event.target.value
 
        let ownSearcher = "destinations_places"
        if (whiteBackground && destinationOnly ) {
            ownSearcher = "destinations_only"
        } 
        let openSearcher = generalSearch.searcherId

     
        console.log(openSearcher,'search')

        if (ownSearcher === openSearcher ) {
            console.log("delay (updateState ())")
            delay(function () {
                updateState(search, !validate(String(search).trim(), validate_fields.general_search))
            }, DELAY_ZERO_SECOND);
        } else if (inputDisabled) {
            setGeneralSearchSearcherId(ownSearcher)
        }
    };

    const [openSearch, setOpenSearch] = React.useState(true);
    const [inputDisabled, setInputDisabled] = React.useState(false);

    const handleToggleSearch = () => {
        setOpenSearch(prevOpen => !prevOpen);

        let openSearcher = generalSearch.searcherId
        let ownSearcher = "destinations_places"
        if (whiteBackground && destinationOnly ) {
            ownSearcher  = "destinations_only"
        } 

        if (openSearcher === "" || openSearcher !== ownSearcher) {
             setGeneralSearchSearcherId(ownSearcher)
        } 
    };

    const anchorRef = React.useRef(null);

    const handleClose = event => {
        console.log("handleClose()")
        setQuery("")
        if (anchorRef?.current && anchorRef?.current.contains(event?.target)) {
            return;
        }

        setOpenSearch(false);
        clearGeneralSearch()

    };
    /* search anchor ref */

    const anchorRefSearch = React.useRef(null)
    const [openSearchMobile, setOpenSearchMobile] = React.useState(false);

    const handleToggleSearchMobile = () => {
        setQuery("")
        setOpenSearchMobile(prevOpen => !prevOpen)
        clearGeneralSearch()
    }

    const handleCloseSearchMobile = event => {
        if (anchorRefSearch.current && anchorRefSearch.current.contains(event.target)) {
            return
        }

        setOpenSearchMobile(false)
        clearGeneralSearch()
        setQuery("")
    }

    const closeMe = () => {
        console.log("closeMe()")
        setOpenSearch(false);
        clearGeneralSearch()
        setOpenSearchMobile(false)
        setQuery("")
        handleToggleSearch()
    }

    React.useEffect(() => {
        let ownId = "destinations_places"
        if (whiteBackground && destinationOnly ) {
            ownId = "destinations_only"
        } 
        let openSearcher = generalSearch.searcherId
        if (openSearcher !== "" && openSearcher !== ownId) {
            setInputDisabled(true)
            setOpenSearch(true);
            clearGeneralSearch()
            setQuery("")
        } else {
            setInputDisabled(false)
        }
    }, [generalSearch.searcherId]) 


    React.useEffect(() => {
        handleToggleSearch()
    },[])


    const setMobileSearchLeft = (clientWidth) => {
        let left = 0
        if(500 > clientWidth){
            left = (-(clientWidth - 120))
        }else{
            left = (-(clientWidth - 200))
        }
        return left 
    }



    function setMobileSearchWidth(clientWidth){ 
        return clientWidth * 0.7 + "px"
    }
  

    return (
        <div>
            {!is_mobile && (openSearch ?
                <div onClick={() => handleToggleSearch()}>
                    <div style={{
                        paddingTop: "7px",
                        cursor: "pointer",
                    }}>
                        <SearchIcon width={25} height={20} 
                            fill={ whiteBackground? "var(--darkBlack)" : textColor} />
                    </div>
                </div>
                : <ClickAwayListener onClickAway={handleClose}>
                    <div style={{ position: "relative" }}>
                        <div className={destinationOnly?classes.searchSquare:classes.search}>
                            <InputBase
                                ref={anchorRef}
                                placeholder={destinationOnly ? "Search destinations": "Search"}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={handleChangeHandleTextField}
                                value={query}
                            />
                        </div>
                        <div style={{
                            cursor: "pointer",
                            right: "10px",
                            bottom: "10px",
                            position: 'absolute',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                            >
                            
                            {query && <span style={{paddingRight:10, cursor: "pointer",}} onClick={handleClose}>  <TimesIcon width={13} height={13} /></span>}
                            <SearchIcon width={13} height={13} />
                        </div>
                        {generalSearch.open && !inputDisabled && <GeneralSearchBarAttachment destinationOnly ={destinationOnly} 
                                    anchorRef={anchorRef} handleClose={handleClose} />}

                    </div>
                </ClickAwayListener>
            )}
            {is_mobile && <div>
                <div onClick={() => handleToggleSearchMobile()} ref={anchorRefSearch}>
                    <div style={{ outline: "0"}}><SearchIcon fill={useTransparent? "var(--mainWhite)":textColor} width={29} height={26} /></div>
                    <div style={{
                        font: "8px/28px Futura Lt BT",
                        color: textColor,
                        outline: "0",
                    }}></div>
                </div>
                <ClickAwayListener onClickAway={handleCloseSearchMobile}>
                    {is_mobile &&
                        <Popper open={openSearchMobile} anchorEl={anchorRefSearch.current} role={undefined} className={classes.zIndex}
                            placement={bodyClientWidth > 500 ? "bottom-start" : "right-start"}
                            modifiers={{
                                flip: {
                                    enabled: false,
                                },
                            }}
                            style={{
                                position:"absolute",
                                        top:1 
                            }}
                            transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right',}}
                                >
                                    <Paper style={{
                                        borderRadius: "20px",
                                        width: setMobileSearchWidth(bodyClientWidth),
                                        position:"absolute",
                                        top:bodyClientWidth < 500 ? -5 : -30,
                                        left:setMobileSearchLeft(bodyClientWidth)
                                    }}>

                                        <div style={{ position: "relative" }}>
                                            <div className={classesMobile.search}>

                                                <InputBase
                                                    ref={anchorRef}
                                                    placeholder="Search"
                                                    classes={{
                                                        root: classesMobile.inputRoot,
                                                        input: classesMobile.inputInput,
                                                    }}
                                                    inputProps={{ 'aria-label': 'search' }}
                                                    onChange={handleChangeHandleTextField}
                                                    value={query}
                                                />
                                            </div>
                                            <div style={{
                                                cursor: "pointer",
                                                right: "10px",
                                                bottom: "10px",
                                                position: 'absolute',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                                onClick={() => closeMe()}>
                                                <TimesIcon width={13} height={13} />
                                            </div>
                                            {generalSearch.open && <GeneralSearchBarAttachment 
                                                         destinationOnly ={destinationOnly} anchorRef={anchorRef} handleClose={handleClose} />}
                                        </div>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>}
                </ClickAwayListener>
            </div>
            }
        </div>
    );
})
