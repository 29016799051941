import * as Actions from '../../../../redux/actions'

import {
	BusIcon,
	FlightShapeIcon,
	HotelDuotoneIcon,
	MapDuotoneIcon,
	SubwayIcon,
} from "../SVGBank"

import PriceLineBookingFormElement from './PriceLineBookingFormElement'
import { PropTypes } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'

const AIBookingForm_ = ({ activeTab, setBookingFormActiveTab, is_mobile }) => {


	React.useEffect(()=>{
		setBookingFormActiveTab('planNewTrip')
	},[])

	return (
		<div>
			
			<div className="col-md-12 p-0">
				<PriceLineBookingFormElement />
			</div>

		</div >
	)

}

AIBookingForm_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,

		activeTab: state.FormBank.BookingForm.activeTab,

		uuid: state.Member.authModal.uuid,
		is_mobile: state.Setting.is_mobile,
	}

}

const mapDispatchToProps = {
	setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}
const AIBookingForm = connect(mapStateToProps, mapDispatchToProps)(AIBookingForm_)

export default AIBookingForm